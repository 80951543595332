import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import * as AvatarPrimitive from '@radix-ui/react-avatar';

import { cn } from '@spektr/style-utils';

import { AvatarFallback } from './AvatarFallback';
import { AvatarImage } from './AvatarImage';

export type AvatarProps = ComponentPropsWithoutRef<
  typeof AvatarPrimitive.Root
> & {
  firstName: string;
  lastName: string;
  src?: string;
  fallbackClass?: string;
};

export const Avatar = forwardRef<
  ElementRef<typeof AvatarPrimitive.Root>,
  AvatarProps
>(({ className, firstName, lastName, src, fallbackClass, ...props }, ref) => {
  const fallback =
    firstName && lastName
      ? `${firstName[0]}${lastName[0]}`
      : firstName
        ? firstName[0]
        : lastName
          ? lastName[0]
          : '?';

  return (
    <AvatarPrimitive.Root
      ref={ref}
      className={cn(
        'relative flex h-8 w-8 shrink-0 overflow-hidden rounded-full',
        className
      )}
      {...props}
    >
      <AvatarImage src={src} />
      <AvatarFallback className={fallbackClass}>{fallback}</AvatarFallback>
    </AvatarPrimitive.Root>
  );
});
Avatar.displayName = AvatarPrimitive.Root.displayName;
