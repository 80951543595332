import { Process } from '@spektr/shared/types';
import { getExistingSource } from '@spektr/shared/utils';

export const isProcessAvailableForConnection = (
  process: Process,
  sourceProcess?: Process,
  acceptsOnlyLoopLinks = false
) => {
  if (sourceProcess === undefined) {
    return false;
  }

  if (process.id === sourceProcess.id) {
    return false;
  }

  if (process.type === 'loop') {
    return true;
  }

  if (acceptsOnlyLoopLinks) {
    return false;
  }

  if (process.type === 'onboarding') {
    return false;
  }

  const source = getExistingSource(process);

  return source === undefined;
};
