import { ServiceFieldClient } from '@spektr/client/types';
import { SpektrFieldKey } from '@spektr/shared/types';

/**
 * @description
 *    When a "main" spektrField is selected (e.g. has_sanctions),
 *  all it's subfields (e.g. has_sanctions_fields.*) should be
 *  selected as well.
 *
 * @param fields - ServiceFieldClient[]
 * @param selectedFields  - SpektrFieldKey[]
 * @returns Record<string, boolean>
 */
export function mapSelectedFields(
  fields: ServiceFieldClient[],
  selectedFields: SpektrFieldKey[]
) {
  const fieldsMap = fields.reduce<Record<string, boolean>>((acc, field) => {
    const fieldStartingKey = field.key.split('_fields.').shift() as string;
    acc[field.key] = selectedFields.includes(fieldStartingKey);

    return acc;
  }, {});

  return fieldsMap;
}
