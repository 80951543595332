import { Suspense } from 'react';
import axios from 'axios';

import { Banner, SpektrErrorBoundary } from '@spektr/client/components';

import { CustomerConnectionsGraph } from '../containers/CustomerConnectionsGraph';

export const CustomerConnections = () => {
  return (
    <SpektrErrorBoundary
      fallbackRender={({ error }) => {
        let errorMsg = 'An error occurred while retrieving the connections.';
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            errorMsg = 'There are no connections at the moment.';
          } else if (error.message) {
            errorMsg = error.message;
          }
        }

        return (
          <Banner type="error" iconName="TriangleAlert" message={errorMsg} />
        );
      }}
    >
      <Suspense fallback={<div />}>
        <CustomerConnectionsGraph />
      </Suspense>
    </SpektrErrorBoundary>
  );
};
