//TODO:  [ST-1237] Label and help are just here for backwards compatibility, they should be removed when the label and help are moved to a localization file and retrieved by the field key
import { SpektrFieldWithInfo } from '@spektr/shared/types';

import { commonSpektrFields } from '../commonSpektrFields/commonSpektrFields';

const calculatedFields: {
  [key: string]: Omit<SpektrFieldWithInfo, 'sourceId'>;
} = {
  isSold: {
    key: 'is_sold',
    type: 'boolean',
    source: 'bodacc',
    label: 'Company is sold or transferred',
    help: 'Get information about the sale of the company or how its ownership got transferred.',
  },
  isRegistered: {
    key: 'is_registered',
    type: 'boolean',
    source: 'bodacc',
    label: 'Company is registered',
    help: 'Get information about the registration of the company.',
  },
  hasBranch: {
    key: 'has_branch',
    type: 'boolean',
    source: 'bodacc',
    label: 'Company has branch',
    help: 'Get information about the branches of the company.',
  },
  hasChanges: {
    key: 'has_changes',
    type: 'boolean',
    source: 'bodacc',
    label: 'Company has changes',
    help: 'Get information about the changes in the company.',
  },
  isDeregistered: {
    key: 'is_deregistered',
    type: 'boolean',
    source: 'bodacc',
    label: 'Company is deregistered',
    help: 'Get information about the deregistration of the company.',
  },
  inCollectiveProceedings: {
    key: 'in_collective_proceedings',
    type: 'boolean',
    source: 'bodacc',
    label: 'Company is in collective proceedings',
    help: 'Get information about the potential bankruptcy processes the company has been involved in.',
  },
  inConciliationProceedings: {
    key: 'in_conciliation_proceedings',
    type: 'boolean',
    source: 'bodacc',
    label: 'Company is in conciliation proceedings',
    help: 'Get information about the agreement reached between the company facing financial difficulties and its creditors.',
  },
  bodaccServiceFailure: {
    key: 'bodacc_service_failure',
    type: 'boolean',
    source: 'bodacc',
    label: 'Bodacc service call failed',
  },
};

const isSoldFields: {
  [key: string]: Omit<SpektrFieldWithInfo, 'sourceId'>;
} = {
  soldPublicationDate: {
    key: 'is_sold_fields.dateparution',
    type: 'string',
    source: 'bodacc',
    label: 'is_sold_fields.dateparution',
    help: 'Date of publication.',
    commonSpektrFieldKeys: [commonSpektrFields.soldPublicationDate.key],
  },
  soldDescriptiveFamily: {
    key: 'is_sold_fields.familleavis_lib',
    type: 'string',
    source: 'bodacc',
    label: 'is_sold_fields.familleavis_lib',
    help: 'Descriptive family of the notice.',
  },
  soldCompanyName: {
    key: 'is_sold_fields.commercant',
    type: 'string',
    source: 'bodacc',
    label: 'is_sold_fields.commercant',
    help: 'Name of the merchant or individual involved.',
    commonSpektrFieldKeys: [commonSpektrFields.soldCompanyName.key],
  },
  soldPersonType: {
    key: 'is_sold_fields.listepersonnes.typePersonne',
    type: 'string',
    source: 'bodacc',
    label: 'is_sold_fields.listepersonnes.typePersonne',
    help: 'Type of the person (e.g., physical person or legal entity).',
  },
};

const isRegisteredFields: {
  [key: string]: Omit<SpektrFieldWithInfo, 'sourceId'>;
} = {
  registeredPublicationDate: {
    key: 'is_registered_fields.dateparution',
    type: 'string',
    source: 'bodacc',
    label: 'is_registered_fields.dateparution',
    help: 'Date of publication',
    commonSpektrFieldKeys: [commonSpektrFields.registeredPublicationDate.key],
  },
  registeredDescriptiveFamily: {
    key: 'is_registered_fields.familleavis_lib',
    type: 'string',
    source: 'bodacc',
    label: 'is_registered_fields.familleavis_lib',
    help: 'Descriptive family of the notice.',
  },
  registeredCompanyName: {
    key: 'is_registered_fields.commercant',
    type: 'string',
    source: 'bodacc',
    label: 'is_registered_fields.commercant',
    help: 'Name of the merchant or individual involved.',
    commonSpektrFieldKeys: [commonSpektrFields.registeredCompanyName.key],
  },
  registeredPersonType: {
    key: 'is_registered_fields.listepersonnes.typePersonne',
    type: 'string',
    source: 'bodacc',
    label: 'is_registered_fields.listepersonnes.typePersonne',
    help: 'Type of the person (e.g., physical person or legal entity).',
  },
};

const hasBranchFields: {
  [key: string]: Omit<SpektrFieldWithInfo, 'sourceId'>;
} = {
  branchPublicationDate: {
    key: 'has_branch_fields.dateparution',
    type: 'string',
    source: 'bodacc',
    label: 'has_branch_fields.dateparution',
    help: 'Date of publication.',
    commonSpektrFieldKeys: [commonSpektrFields.branchPublicationDate.key],
  },
  branchDescriptiveFamily: {
    key: 'has_branch_fields.familleavis_lib',
    type: 'string',
    source: 'bodacc',
    label: 'has_branch_fields.familleavis_lib',
    help: 'Descriptive family of the notice.',
  },
  branchCompanyName: {
    key: 'has_branch_fields.commercant',
    type: 'string',
    source: 'bodacc',
    label: 'has_branch_fields.commercant',
    help: 'Name of the merchant or individual involved.',
    commonSpektrFieldKeys: [commonSpektrFields.branchCompanyName.key],
  },
  branchPersonType: {
    key: 'has_branch_fields.listepersonnes.typePersonne',
    type: 'string',
    source: 'bodacc',
    label: 'has_branch_fields.listepersonnes.typePersonne',
    help: 'Type of the person (e.g., physical person or legal entity).',
  },
};

const hasChangesFields: {
  [key: string]: Omit<SpektrFieldWithInfo, 'sourceId'>;
} = {
  changesPublicationDate: {
    key: 'has_changes_fields.dateparution',
    type: 'string',
    source: 'bodacc',
    label: 'has_changes_fields.dateparution',
    help: 'Date of publication.',
    commonSpektrFieldKeys: [commonSpektrFields.changesPublicationDate.key],
  },
  changesDescriptiveFamily: {
    key: 'has_changes_fields.familleavis_lib',
    type: 'string',
    source: 'bodacc',
    label: 'has_changes_fields.familleavis_lib',
    help: 'Descriptive family of the notice.',
  },
  changesCompanyName: {
    key: 'has_changes_fields.commercant',
    type: 'string',
    source: 'bodacc',
    label: 'has_changes_fields.commercant',
    help: 'Name of the merchant or individual involved.',
    commonSpektrFieldKeys: [commonSpektrFields.changesCompanyName.key],
  },
  changesPersonType: {
    key: 'has_changes_fields.listepersonnes.typePersonne',
    type: 'string',
    source: 'bodacc',
    label: 'has_changes_fields.listepersonnes.typePersonne',
    help: 'Type of the person (e.g., physical person or legal entity).',
  },
  changesDescriptiveModifications: {
    key: 'has_changes_fields.modificationsgenerales.descriptif',
    type: 'string',
    source: 'bodacc',
    label: 'has_changes_fields.modificationsgenerales.descriptif',
    help: 'Description of the general modifications.',
  },
};

const isDeregisteredFields: {
  [key: string]: Omit<SpektrFieldWithInfo, 'sourceId'>;
} = {
  deregisteredPublicationDate: {
    key: 'is_deregistered_fields.dateparution',
    type: 'string',
    source: 'bodacc',
    label: 'is_deregistered_fields.dateparution',
    help: 'Date of publication.',
    commonSpektrFieldKeys: [commonSpektrFields.deregisteredPublicationDate.key],
  },
  deregisteredDescriptiveFamily: {
    key: 'is_deregistered_fields.familleavis_lib',
    type: 'string',
    source: 'bodacc',
    label: 'is_deregistered_fields.familleavis_lib',
    help: 'Descriptive family of the notice.',
  },
  deregisteredCompanyName: {
    key: 'is_deregistered_fields.commercant',
    type: 'string',
    source: 'bodacc',
    label: 'is_deregistered_fields.commercant',
    help: 'Name of the merchant or individual involved.',
    commonSpektrFieldKeys: [commonSpektrFields.deregisteredCompanyName.key],
  },
  deregisteredPersonType: {
    key: 'is_deregistered_fields.listepersonnes.typePersonne',
    type: 'string',
    source: 'bodacc',
    label: 'is_deregistered_fields.listepersonnes.typePersonne',
    help: 'Type of the person (e.g., physical person or legal entity).',
  },
};

const inCollectiveProceedingsFields: {
  [key: string]: Omit<SpektrFieldWithInfo, 'sourceId'>;
} = {
  collectiveProceedingsPublicationDate: {
    key: 'in_collective_proceedings_fields.dateparution',
    type: 'string',
    source: 'bodacc',
    label: 'in_collective_proceedings_fields.dateparution',
    help: 'Date of publication.',
    commonSpektrFieldKeys: [
      commonSpektrFields.collectiveProceedingsPublicationDate.key,
    ],
  },
  collectiveProceedingsDescriptiveFamily: {
    key: 'in_collective_proceedings_fields.familleavis_lib',
    type: 'string',
    source: 'bodacc',
    label: 'in_collective_proceedings_fields.familleavis_lib',
    help: 'Descriptive family of the notice.',
  },
  collectiveProceedingsCompanyName: {
    key: 'in_collective_proceedings_fields.commercant',
    type: 'string',
    source: 'bodacc',
    label: 'in_collective_proceedings_fields.commercant',
    help: 'Name of the merchant or individual involved.',
    commonSpektrFieldKeys: [
      commonSpektrFields.collectiveProceedingsCompanyName.key,
    ],
  },
  collectiveProceedingsPersonType: {
    key: 'in_collective_proceedings_fields.listepersonnes.typePersonne',
    type: 'string',
    source: 'bodacc',
    label: 'in_collective_proceedings_fields.listepersonnes.typePersonne',
    help: 'Type of the person (e.g., physical person or legal entity).',
  },
};

const inConciliationProceedingsFields: {
  [key: string]: Omit<SpektrFieldWithInfo, 'sourceId'>;
} = {
  conciliationPublicationDate: {
    key: 'in_conciliation_proceedings_fields.dateparution',
    type: 'string',
    source: 'bodacc',
    label: 'in_conciliation_proceedings_fields.dateparution',
    help: 'Date of publication.',
    commonSpektrFieldKeys: [commonSpektrFields.conciliationPublicationDate.key],
  },
  conciliationDescriptiveFamily: {
    key: 'in_conciliation_proceedings_fields.familleavis_lib',
    type: 'string',
    source: 'bodacc',
    label: 'in_conciliation_proceedings_fields.familleavis_lib',
    help: 'Descriptive family of the notice.',
  },
  conciliationCompanyName: {
    key: 'in_conciliation_proceedings_fields.commercant',
    type: 'string',
    source: 'bodacc',
    label: 'in_conciliation_proceedings_fields.commercant',
    help: 'Name of the merchant or individual involved.',
    commonSpektrFieldKeys: [commonSpektrFields.conciliationCompanyName.key],
  },
  conciliationPersonType: {
    key: 'in_conciliation_proceedings_fields.listepersonnes.typePersonne',
    type: 'string',
    source: 'bodacc',
    label: 'in_conciliation_proceedings_fields.listepersonnes.typePersonne',
    help: 'Type of the person (e.g., physical person or legal entity).',
  },
};

export const bodaccSpektrFields = [
  ...Object.values(calculatedFields),
  ...Object.values(isSoldFields),
  ...Object.values(isRegisteredFields),
  ...Object.values(hasBranchFields),
  ...Object.values(hasChangesFields),
  ...Object.values(isDeregisteredFields),
  ...Object.values(inCollectiveProceedingsFields),
  ...Object.values(inConciliationProceedingsFields),
];
