import { cn } from '@spektr/style-utils';

type SkeletonProps = {
  className?: string;
};

export const Skeleton = ({ className }: SkeletonProps) => (
  <div
    className={cn(
      'bg-color-bg-skeleton',
      'h-5 w-14',
      'rounded',
      'animate-pulse',
      className
    )}
  />
);
