import { TimelineEventsPaginated } from '@spektr/shared/validators';

import { InsightsApiClient } from '../client';
import { CUSTOMERS_KEYS } from '../queryKeys';
import { CustomerFilters } from '../types/Filters';

export function getAllCustomers(filters: CustomerFilters) {
  const arrayQueries: { [key: string]: string } = {};

  if (filters.datasetIds && filters.datasetIds.length > 0) {
    arrayQueries['datasetIds'] = filters.datasetIds.toString();
  }
  if (filters.status && filters.status.length > 0) {
    arrayQueries['status'] = filters.status.toString();
  }
  if (filters.tags && filters.tags.length > 0) {
    arrayQueries['tags'] = filters.tags.toString();
  }
  if (filters.alertPresence && filters.alertPresence.length > 0) {
    arrayQueries['alertPresence'] = filters.alertPresence.toString();
  }
  if (filters.alertType && filters.alertType.length > 0) {
    arrayQueries['alertType'] = filters.alertType.toString();
  }

  return {
    queryKey: CUSTOMERS_KEYS.ALL(filters),
    queryFn: () =>
      InsightsApiClient.getClient().getAllClientRecords({
        queries: {
          page: filters.page ?? 1,
          limit: filters.pageLimit ?? 10,
          searchQuery: filters?.searchQuery,
          isUbo: filters.isUbo,
          createdAtStart: filters.createdAtStart,
          createdAtEnd: filters.createdAtEnd,
          updatedAtStart: filters.updatedAtStart,
          updatedAtEnd: filters.updatedAtEnd,
          ...arrayQueries,
        },
      }),
  };
}

export function getCustomerDetails(customerId: string) {
  return {
    queryKey: CUSTOMERS_KEYS.BY_ID(customerId),
    queryFn: () =>
      InsightsApiClient.getClient().getClientRecordDetails({
        params: {
          clientRecordId: customerId,
        },
      }),
  };
}

export function getCustomerTimeline(customerId: string, limit = 10, page = 1) {
  return {
    queryKey: CUSTOMERS_KEYS.TIMELINE(customerId, page, limit),
    queryFn: ({ pageParam }: { pageParam: number }) =>
      InsightsApiClient.getClient().getClientRecordTimeline({
        params: {
          clientRecordId: customerId,
        },
        queries: {
          page: pageParam,
          limit,
        },
      }),
    initialPageParam: page,
    getNextPageParam: (lastPage: TimelineEventsPaginated) => lastPage.nextPage,
  };
}

export function getCustomerDocuments(customerId: string) {
  return {
    queryKey: CUSTOMERS_KEYS.DOCUMENTS(customerId),
    queryFn: () =>
      InsightsApiClient.getClient().getClientRecordDocuments({
        params: {
          clientRecordId: customerId,
        },
      }),
  };
}

export function getCustomerOwnersAndControllers(customerId: string) {
  return {
    queryKey: CUSTOMERS_KEYS.OWNERS_AND_CONTROLLERS(customerId),
    queryFn: () =>
      InsightsApiClient.getClient().getClientRecordOwnersAndControllers({
        params: {
          clientRecordId: customerId,
        },
      }),
  };
}
