import { ButtonWithTooltip } from '@spektr/client/components';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { cn } from '@spektr/style-utils';

import { SettingItem } from '../SettingItem';
import { StatusCheck } from '../StatusCheck';
import { useProcessScheduleContext } from '../../providers';
import { ProcessTrigerStartDate } from '../ProcessTriggerStartDate';

export type PublishVersionItemProps = {
  description: string;
  isDisabled?: boolean;
  hasUnreleasedChanges?: boolean;
  title: string;
  disabledReason: string;
  onClick: () => void;
};

export const PublishVersionItem = ({
  title,
  description,
  hasUnreleasedChanges = false,
  isDisabled = false,
  disabledReason,
  onClick,
}: PublishVersionItemProps) => {
  const { isMonitoringProcess } = useProcessScheduleContext();
  const { processTriggers } = useFeatureFlags();

  return (
    <SettingItem
      title={title}
      description={description}
      className={cn(
        processTriggers && isMonitoringProcess && 'flex-col items-start'
      )}
      childrenClassName={cn(
        processTriggers &&
          isMonitoringProcess &&
          'flex w-full items-end justify-between ml-0 mt-3'
      )}
    >
      {processTriggers && isMonitoringProcess && <ProcessTrigerStartDate />}
      <div className="ml-auto flex items-center gap-4">
        {hasUnreleasedChanges && (
          <StatusCheck animated color="red" label="Unreleased changes" />
        )}
        <ButtonWithTooltip
          color="cyan"
          disabled={isDisabled || !!disabledReason}
          onClick={onClick}
          showTooltip={!!disabledReason}
          tooltipProps={{
            content: disabledReason,
          }}
        >
          Publish version
        </ButtonWithTooltip>
      </div>
    </SettingItem>
  );
};
