import { useTranslation } from 'react-i18next';

import { getAlertTypeBasedOnService } from '../../utils';
import { ClientActivityLogItem } from '../../types';
import { useNavigateToHit } from '../../hooks';

import { HitStatus } from '../HitStatus';
import { ServiceIcon } from '../ServiceIcon';
import { ActivityItemHeader } from '../ActivityItemHeader';

export type ActivityLogItemProps = {
  data: ClientActivityLogItem;
};

export const ActivityLogItem = ({ data }: ActivityLogItemProps) => {
  const navigateToHit = useNavigateToHit();
  const { t } = useTranslation('cases');
  const hitType = getAlertTypeBasedOnService(data.vendorDetails.service);

  const key = data.vendorDetails.field
    ? `${data.vendorDetails.service}.${data.vendorDetails.field}`
    : data.vendorDetails.service;

  const handleClick = () => {
    navigateToHit(data.alertId, data.hitId);
  };

  return (
    <button
      className="hover:bg-color-bg-button-default/20 flex w-full flex-col gap-3 border-b p-6 last:border-b-0"
      onClick={handleClick}
    >
      <ActivityItemHeader
        firstName={data.user.firstName}
        lastName={data.user.lastName}
        email={data.user.username}
        date={data.createdAt}
      />
      <div className="flex flex-row gap-3">
        <div className="flex-nowrap text-nowrap">
          <HitStatus hitType={hitType} decision={data.status} />
        </div>
        <div className="flex flex-row flex-nowrap items-start gap-2 text-left">
          <ServiceIcon
            className="mt-0.5"
            service={data.vendorDetails.service}
          />
          <span className="text-sm">{t(`alerts.field.${key}`)}</span>
        </div>
      </div>
      {data?.reason && (
        <div>
          <span className="text-sm">{data.reason}</span>
        </div>
      )}
    </button>
  );
};
