import { Suspense, useContext } from 'react';

import { ErrorPage } from '@spektr/shared/components';
import {
  CasesRootLayout,
  CustomersSkeletonTable,
  SpektrErrorBoundary,
} from '@spektr/client/components';
import { handleAxiosError } from '@spektr/client/utils';
import {
  CasesCustomersDashboard,
  CasesNavigation,
} from '@spektr/model-builder/containers';

import { AuthContext } from '../../../auth/auth-context';

export const CustomersIndexPage = () => {
  const columnsToDisplay = ['customer', 'tags', 'unresolvedAlerts', 'status'];
  const { user } = useContext(AuthContext);

  return (
    <CasesRootLayout>
      <SpektrErrorBoundary
        fallbackRender={({ error }) => (
          <ErrorPage
            title={handleAxiosError(
              error,
              'An error has occured while retrieving the customers!'
            )}
          />
        )}
      >
        <CasesNavigation />

        <Suspense
          fallback={<CustomersSkeletonTable columns={columnsToDisplay} />}
        >
          <CasesCustomersDashboard
            userId={user?.sub || ''}
            columnsToDisplay={columnsToDisplay}
          />
        </Suspense>
      </SpektrErrorBoundary>
    </CasesRootLayout>
  );
};
