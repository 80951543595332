import { AlertField } from '@spektr/client/types';
import { Process } from '@spektr/shared/types';

import { AlertFieldSchema } from '@spektr/shared/validators';

export const getFieldsFromAlerts = (
  alerts: AlertFieldSchema[],
  processes: Process[]
) => {
  return alerts.reduce((acc: AlertField[], alert) => {
    const process = processes.find((process) => process.id === alert.processId);
    const nodeType = process?.nodes.get(alert.nodeId)?.nodeType;
    const fields = Object.keys(alert.fields)
      .filter((field) => alert.fields[field])
      .map((field) => ({
        name: field,
        processId: alert.processId,
        nodeId: alert.nodeId,
        nodeType,
      }));
    return [...acc, ...fields];
  }, []);
};
