import { Suspense } from 'react';

import {
  CasesSectionError,
  SpektrErrorBoundary,
} from '@spektr/client/components';
import { handleAxiosError } from '@spektr/client/utils';

import { useAlertDetails } from '../providers';

import { AlertActivity } from '../containers/AlertActivity';

import { SkeletonActivityLog } from '../components/SkeletonActivityLog';

export const AlertActivityLog = () => {
  const { alert } = useAlertDetails();

  if (!alert) {
    return null;
  }

  return (
    <SpektrErrorBoundary
      fallbackRender={({ error }) => (
        <CasesSectionError
          error={handleAxiosError(
            error,
            'An error has occurred while retrieving the activity log!'
          )}
        />
      )}
    >
      <Suspense fallback={<SkeletonActivityLog />}>
        <AlertActivity alert={alert} />
      </Suspense>
    </SpektrErrorBoundary>
  );
};
