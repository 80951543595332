import { cva } from 'class-variance-authority';
import { CircleHelp, Store, User } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import {
  ClientRecordStatus,
  type ClientRecordType,
} from '@spektr/shared/validators';

export namespace CaseIcon {
  export type Props = {
    className?: string;
    status?: ClientRecordStatus;
    type: ClientRecordType;
  };
}

export const CaseIcon = ({
  className,
  status = 'pending',
  type,
}: CaseIcon.Props) => {
  let CaseIcon = CircleHelp;

  if (type === 'company') {
    CaseIcon = Store;
  } else if (type === 'individual') {
    CaseIcon = User;
  }

  return (
    <div className={cn(variants({ status }), className)}>
      <CaseIcon className="h-4 w-4" />
    </div>
  );
};

const variants = cva(
  [
    'flex shrink-0 items-center justify-center',
    'h-9 w-9',
    'rounded-md border',
    'bg-color-bg-accent/20',
  ],
  {
    variants: {
      status: {
        approved: ['border-color-border-success/50', 'text-color-green'],
        pending: 'border-color-border-process-builder-item',
        rejected: ['border-color-border-error/50', 'text-color-red'],
      },
    },
  }
);
