import { FileCheck } from 'lucide-react';

export const EmptyState = () => {
  return (
    <div className="flex flex-col items-center gap-6 py-10">
      <FileCheck className="stroke-color-text-subtext h-4 w-4" />
      <div className="flex flex-col gap-2 text-center">
        <p className="text-sm">Add alert from services and loops</p>
        <p className="text-color-text-subtext text-xs">
          Choose an alert trigger from a service and assign a team member
        </p>
      </div>
    </div>
  );
};
