import { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Button } from '@spektr/client/components';

import { useAlertDetails } from '../providers';
import { useUpdateAlert } from '../hooks';

import { AlertDescription } from '../components/AlertDescription/AlertDescription';
import { AlertDialogHeader } from '../components/AlertDialogHeader/AlertDialogHeader';
import { AlertTabs } from '../components/AlertTabs/AlertTabs';
import { ResolveAlertDialog } from '../components/ResolveAlertDialog';
import { AlertNavigation } from '../components/AlertNavigation';

type AlertContentProps = {
  onClose: () => void;
};

export const AlertContent = ({ onClose }: AlertContentProps) => {
  const { spektrId: customerId } = useParams();
  const [showResolveAlertDialog, setShowResolveAlertDialog] = useState(false);
  const { alert, getUpdatedTasks } = useAlertDetails();
  const updateAlert = useUpdateAlert(
    alert?.id ?? '',
    alert?.datasetId ?? '',
    alert?.spektrId ?? ''
  );

  const isManualAlert = (alert?.tasks && alert.tasks.length > 0) ?? false;

  const handleSave = async () => {
    if (!alert) {
      return;
    }

    if (isManualAlert) {
      const tasks = getUpdatedTasks();
      const areAllTasksCompleted = tasks?.every((task) => task.isCompleted);

      await updateAlert.mutateAsync({
        status: areAllTasksCompleted ? 'resolved' : 'in_progress',
        tasks,
      });

      onClose();
    } else {
      setShowResolveAlertDialog(true);
    }
  };

  const handleResolve = async (reason?: string) => {
    await updateAlert.mutateAsync({
      status: 'resolved',
      reason,
    });
    onClose();
  };

  if (!alert) {
    return null;
  }

  const isResolveDisabled =
    alert.status === 'resolved' || updateAlert.isPending;

  return (
    <div className="flex flex-col gap-4">
      <AlertDialogHeader>
        <div className="flex items-center gap-5">
          <AlertNavigation
            prevId={customerId ? alert.nextCustomerAlertId : alert.prevId}
            nextId={customerId ? alert.prevCustomerAlertId : alert.nextId}
          />
          <Button
            color={isManualAlert ? 'primary' : 'cyan'}
            variant={isManualAlert ? 'contained' : 'outlined'}
            onClick={handleSave}
            disabled={isResolveDisabled}
          >
            {isManualAlert ? 'Save' : 'Resolve alert'}
          </Button>
        </div>
      </AlertDialogHeader>
      <AlertDescription
        createdDate={alert.createdAt}
        name={alert.name}
        status={alert.status}
        // TODO: @Alex - add missing props when supported by api
        // user={{
        //   firstName: 'John',
        //   lastName: 'Doe',
        // }}
        // aiInfo="You can probably check if client isn't black listed in a country"
      />
      <AlertTabs alertId={alert.id} isManualAlert={isManualAlert} />
      <Outlet />
      {showResolveAlertDialog && (
        <ResolveAlertDialog
          onResolve={handleResolve}
          onCancel={() => setShowResolveAlertDialog(false)}
          areActionsDisabled={isResolveDisabled}
        />
      )}
    </div>
  );
};
