import { ComponentProps } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { X } from 'lucide-react';

import { cn } from '@spektr/style-utils';

export const DialogClose = ({
  className,
  ...props
}: ComponentProps<typeof DialogPrimitive.Close>) => (
  <DialogPrimitive.Close
    className={cn(
      'rounded-sm',
      'ring-offset-color-bg-primary',
      'opacity-70 transition-opacity',
      'hover:opacity-100',
      'disabled:pointer-events-none',
      'dark:focus:ring-ring focus:ring-color-border-secondary focus:outline-none focus:ring-2 focus:ring-offset-2',
      'data-[state=open]:bg-color-bg-accent data-[state=open]:text-color-text-dialog--opened',
      className
    )}
    data-cy="dialog-close-btn"
    {...props}
  >
    <X className="stroke-color-stroke-secondary h-5 w-5" />
    <span className="sr-only">Close</span>
  </DialogPrimitive.Close>
);
