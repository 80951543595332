import { useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { Plus } from 'lucide-react';

import { EntitiesField } from '@spektr/moonraker-types';

import { useFormEngine } from '../../../../hooks/useFormEngine';

import { type DraggableItem, type DraggableItemType } from '../../../../types';

import { DropArea } from '../DropArea';
import { CanvasField } from '../CanvasField';
import { CanvasFieldFactory } from '../CanvasFieldFactory';

export type BlockContentProps = {
  field: EntitiesField;
};

export const BlockContent = ({ field }: BlockContentProps) => {
  const {
    state,
    selectFieldId,
    dropField,
    cloneField,
    deleteField,
    reorderFields,
  } = useFormEngine();
  const [_, fieldId] = state.selectedFieldsId ?? [];

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ['widget', 'primitive'],
    drop: (widget: DraggableItem, monitor) => {
      const itemType = monitor.getItemType() as DraggableItemType;

      dropField(itemType, widget, field.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;

  const orderedFields = useMemo(
    () => field.form.order.map((id) => field.form.fields[id]),
    [field.form.fields, field.form.order]
  );

  return (
    <div className="flex w-full flex-col gap-3">
      {orderedFields.map((innerField, index) => (
        <CanvasField
          key={innerField.id}
          field={innerField}
          parentId={field.id}
          index={index}
          isSelected={fieldId === innerField.id}
          level={2}
          onClone={() => cloneField(field.id, innerField.id)}
          onClick={() => null}
          onDoubleClick={() => selectFieldId(field.id, innerField.id)}
          onDelete={() => deleteField(field.id, innerField.id)}
          onReorder={reorderFields}
        >
          <CanvasFieldFactory field={innerField} />
        </CanvasField>
      ))}
      <DropArea
        ref={drop}
        isActive={isActive}
        canDrop={canDrop}
        canvasLength={field.form.order.length}
      />
      <div className="text-color-text-subtext flex flex-row items-center gap-1">
        <Plus className="h-3.5 w-3.5" />
        <span className="text-xs font-medium">
          {field.attributes.buttonText}
        </span>
      </div>
    </div>
  );
};
