import { useRef } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  getAllowedSpektrFieldsQuery,
  getProcessByIdQuery,
} from '@spektr/client/services';

import { toast, ScrollArea } from '@spektr/client/components';
import {
  CalculationNodeFormDialogContent,
  CalculationNodeProvider,
  parseCalculation,
} from '@spektr/shared/components';

import { updateCalculationNodeSchema } from '@spektr/shared/validators';

import {
  type CalculationNode,
  type NodeUpdateInput,
} from '@spektr/shared/types';

import { DialogEditableTitle } from '../../components/DialogEditableTitle';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { withDialogWrapper } from '../WithDialogWrapper';

import { CalculationFooter } from './components/CalculationFooter';
import { CalculationSkeleton } from './components/CalculationSkeleton';

export namespace CalculationContent {
  export type Props = BaseDialogProps<CalculationNode>;
}

const CalculationContent = ({
  isPendingUpdate,
  node,
  nodeId,
  processId,
  onUpdate,
}: CalculationContent.Props) => {
  const { data: process } = useSuspenseQuery(getProcessByIdQuery(processId));
  const { data: allowedSpektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, nodeId)
  );

  const titleRef = useRef<HTMLInputElement>(null);

  const handleSave = async (data: NodeUpdateInput) => {
    const title = titleRef?.current?.value ?? node.title;
    const parsedData = updateCalculationNodeSchema.parse({ ...data, title });

    await onUpdate(parsedData);

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });
  };

  const handleInputBlur = (title: string) => {
    onUpdate({
      ...node,
      title,
    });
  };

  return (
    <CalculationNodeProvider
      initial={parseCalculation(node)}
      processType={process.type}
    >
      <DialogEditableTitle
        titleRef={titleRef}
        initialTitle={node.title}
        onBlur={handleInputBlur}
      />
      <ScrollArea className="flex max-h-[70vh] flex-col overflow-y-auto px-2">
        <CalculationNodeFormDialogContent
          spektrFields={allowedSpektrFields ?? []}
        />
      </ScrollArea>
      <CalculationFooter
        isPendingUpdate={isPendingUpdate}
        onUpdate={handleSave}
      />
    </CalculationNodeProvider>
  );
};

export const CalculationDialog = withDialogWrapper(
  CalculationContent,
  <CalculationSkeleton />
);
