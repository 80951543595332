import { format } from 'date-fns';
import {
  CircleX,
  FilePen,
  FilePlus,
  ListVideo,
  Share2,
  SquareActivity,
  Trash2,
} from 'lucide-react';
import { useMemo } from 'react';

import { SHORT_DATE_TIME_FORMAT } from '@spektr/client/constants';
import { cn } from '@spektr/style-utils';

import {
  Button,
  CardWithContextMenu,
  CardWithContextMenuProps,
} from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';

import { RBAC } from '@spektr/shared/rbac';
import { DatasetResponse } from '@spektr/shared/types';
import { useFeatureFlags } from '@spektr/platform-hooks';

export type DatasetCardProps = {
  dataset: DatasetResponse;
  onClick?: () => void;
  onActionClick?: CardWithContextMenuProps['onActionClick'];
};

export const DatasetCard = ({
  dataset,
  onClick,
  onActionClick,
}: DatasetCardProps) => {
  const header = (
    <div>
      <div
        className={cn(
          'flex shrink-0 items-center justify-center',
          'h-6 w-6',
          'rounded-md',
          'bg-color-yellow/20'
        )}
      >
        <FilePlus className="stroke-color-yellow h-3 w-3" />
      </div>
    </div>
  );
  const footer = (
    <span>{`Added: ${format(dataset.createdAt, SHORT_DATE_TIME_FORMAT)}`}</span>
  );

  const isScanInProgress =
    dataset.meta?.scan === 'idle' || dataset.meta?.scan === 'processing';
  const isScanFailed = dataset.meta?.scan === 'failed';
  const includedDatasetFields =
    dataset?.fields.filter((field) => field.isIncluded).length ?? 0;
  const isDatasetExtracted = !!dataset.fieldId;
  const btnLabel = isScanInProgress ? 'Extracting...' : 'Extract fields';
  const btnCy = isScanInProgress
    ? `Extracting-${dataset.name}`
    : `Extract-fields-${dataset.name}`;
  const { hasPermission } = usePermissionsContext();
  const canEdit = hasPermission(RBAC.ACTIONS.DATASET.UPDATE);

  const { processRuns } = useFeatureFlags();

  const actions = useMemo<CardWithContextMenuProps['actions']>(
    () => [
      {
        type: 'item',
        label: 'Inspect data',
        icon: <SquareActivity className="h-3 w-3" />,
        value: 'inspect',
        variant: 'default',
        disabled: !isDatasetExtracted,
      },
      {
        type: 'separator',
        value: 'separator',
      },
      {
        type: 'item',
        label: 'View related processes',
        icon: <Share2 className="h-3 w-3" />,
        value: 'processes',
        variant: 'default',
        disabled: !isDatasetExtracted,
      },
      {
        type: 'separator',
        value: 'separator-1',
      },
      {
        type: 'item',
        label: 'See runs',
        icon: <ListVideo className="h-3 w-3" />,
        value: 'process-runs',
        variant: 'default',
        disabled: !isDatasetExtracted || !processRuns,
      },
      {
        type: 'separator',
        value: 'separator-1',
      },
      {
        type: 'item',
        label: 'Rename this dataset',
        icon: <FilePen className="h-3 w-3" />,
        value: 'rename',
        variant: 'default',
        disabled: !canEdit,
      },
      {
        type: 'separator',
        value: 'separator-2',
      },
      {
        type: 'item',
        label: 'Delete this dataset',
        icon: <Trash2 className="h-3 w-3" />,
        value: 'delete',
        variant: 'danger',
        disabled: !canEdit,
      },
    ],
    [isDatasetExtracted, processRuns, canEdit]
  );

  return (
    <CardWithContextMenu
      actions={actions}
      className="w-full"
      header={header}
      footer={footer}
      onActionClick={onActionClick}
      name={dataset.name}
      onClick={onClick}
      disabled={
        dataset.meta.scan === 'failed' ||
        dataset.meta.scan === 'idle' ||
        dataset.meta.scan === 'processing'
      }
    >
      <div className="flex h-full flex-col gap-3">
        <p className="line-clamp-1 overflow-hidden text-ellipsis break-all leading-none">
          {dataset.name}
        </p>
        {isScanFailed ? (
          <div className="mt-auto flex h-[36px] flex-row items-center gap-3">
            <div className="bg-color-red/20 flex h-8 w-8 items-center justify-center rounded-md">
              <CircleX className="text-color-red h-4 w-4" />
            </div>
            <div className="flex flex-col">
              <span className="text-color-red text-sm font-semibold">
                Error while extracting fields
              </span>
              <span className="text-color-text-subtext text-xs">
                Please try again
              </span>
            </div>
          </div>
        ) : (
          <div className="mt-auto flex gap-7">
            {isDatasetExtracted || dataset.meta.scan === 'skipped' ? (
              <div className="flex flex-col">
                <span className="text-sm font-semibold">
                  {includedDatasetFields}
                </span>
                <span className="text-color-text-subtext text-xs font-semibold">
                  Fields extracted
                </span>
              </div>
            ) : (
              <Button
                className="font-semibold"
                disabled={isScanInProgress || !canEdit}
                color="yellow"
                onClick={onClick}
                data-cy={btnCy}
              >
                {btnLabel}
              </Button>
            )}
            <div className="flex flex-col">
              <span className="text-sm font-semibold">
                {dataset.fields.length}
              </span>
              <span className="text-color-text-subtext text-xs font-semibold">
                Fields available
              </span>
            </div>
          </div>
        )}
      </div>
    </CardWithContextMenu>
  );
};
