import { ReactNode } from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

export type NotificationBadgeProps = VariantProps<typeof positionVariants> &
  VariantProps<typeof badgeVariant> & {
    badgeContent: number;
    children: ReactNode;
    max?: number;
  };

export const NotificationBadge = ({
  anchorOrigin,
  badgeContent,
  children,
  color,
  max = 99,
}: NotificationBadgeProps) => {
  const displayedValue = badgeContent > max ? `${max}+` : badgeContent;
  const needsToBeRound = badgeContent < 9;

  return (
    <div className="relative inline-flex">
      <div className={cn(positionVariants({ anchorOrigin }))}>
        <div className={cn(badgeVariant({ color, needsToBeRound }))}>
          {displayedValue}
        </div>
      </div>
      {children}
    </div>
  );
};

const positionVariants = cva('absolute', {
  variants: {
    anchorOrigin: {
      'top-left': 'top-0 left-0',
      'top-right': 'top-0 right-0',
      'bottom-left': 'bottom-0 left-0',
      'bottom-right': 'bottom-0 right-0',
    },
  },
  defaultVariants: {
    anchorOrigin: 'top-right',
  },
});
const badgeVariant = cva(
  [
    'flex items-center justify-center shrink-0',
    'h-4 px-1',
    'rounded-full',
    'text-[10px] font-semibold leading-none',
  ],
  {
    variants: {
      needsToBeRound: {
        true: 'w-4',
        false: 'w-auto',
      },
      color: {
        red: 'bg-color-red dark:text-color-text-primary text-color-text-secondary',
        cyan: 'bg-color-cyan dark:text-color-text-primary text-color-text-secondary',
      },
    },
    defaultVariants: {
      needsToBeRound: true,
      color: 'red',
    },
  }
);
