import { Link } from 'react-router-dom';

import { AlertResolvedEventData, UserSchema } from '@spektr/shared/validators';
import { casesCustomerAlertDetailsUrl } from '@spektr/shared/utils';

import { TextTruncate } from '@spektr/client/components';

import { CustomerTimelineCell } from '../CustomerTimelineCell';
import { CustomerTimelineDateCell } from '../CustomerTimelineDateCell';

import { UserInfo } from './UserInfo';

export type AlertResolvedEventProps = {
  event: AlertResolvedEventData;
  users: UserSchema[];
};

export const AlertResolvedEvent = ({
  event,
  users,
}: AlertResolvedEventProps) => {
  const user = users.find((u) => u.id === event.eventData.userId);

  return (
    <>
      <CustomerTimelineCell
        iconName="CircleCheck"
        iconColor="green"
        className="col-span-4"
        label="Alert Resolved"
        value={
          <Link
            to={casesCustomerAlertDetailsUrl(
              event.spektrId,
              event.eventData.alertId
            )}
          >
            <TextTruncate
              className="line-clamp-1"
              text={event.eventData.alertName ?? '-'}
            />
          </Link>
        }
      />
      <CustomerTimelineCell
        className="col-span-4"
        label="Solved by"
        value={<UserInfo user={user} />}
      />
      <CustomerTimelineDateCell date={event.createdAt} />
    </>
  );
};
