import { CalendarIcon, Clock, Combine, Type } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { alertStatuses, alertTypes } from '@spektr/shared/validators';
import { CasesStatus } from '@spektr/client/components';

import { CasesFilterConfiguration } from '../../types';

export const useGetAlertsFiltersConfigurations = (): {
  [filterIdentifier: string]: CasesFilterConfiguration;
} => {
  const { t } = useTranslation('cases');

  return {
    status: {
      icon: <Combine />,
      label: t(`alerts.filters.status`),
      selectionType: 'multiple',
      format: 'dropdown',
      values: alertStatuses.map((status) => ({
        key: status,
        labelNode: (
          <CasesStatus status={status} className="h-[22px] max-w-fit text-xs" />
        ),
      })),
    },
    alertType: {
      icon: <Type />,
      label: t(`alerts.filters.alertType`),
      selectionType: 'multiple',
      format: 'dropdown',
      values: alertTypes.map((alertType) => ({
        key: alertType,
        labelNode: <span>{t(`alertTypes.${alertType}`)}</span>,
      })),
    },
    age: {
      icon: <Clock />,
      label: t(`alerts.filters.age`),
      selectionType: 'single',
      format: 'input',
      conflictingFilters: ['createdAtStart'],
    },
    createdAtStart: {
      icon: <CalendarIcon />,
      label: t(`alerts.filters.createdAtStart`),
      selectionType: 'single',
      format: 'date',
      conflictingFilters: ['age'],
    },
    updatedAtStart: {
      icon: <CalendarIcon />,
      label: t(`alerts.filters.updatedAtStart`),
      selectionType: 'single',
      format: 'date',
    },
  };
};
