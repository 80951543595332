import { useRef, useState } from 'react';

import { Button, ButtonWithTooltip, Input } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

import { RiskMatrixData, useValidateMatrix } from '../../RiskMatrix';

import { RiskMatrixTable } from './RiskMatrixTable';

type RiskMatrixDialogContentProps = {
  title?: string;
  data?: RiskMatrixData;
  onSubmit: (matrixName: string, data: RiskMatrixData) => void;
  isSubmitting: boolean;
  isUpdate?: boolean;
};

export const RiskMatrixDialogContent = ({
  title = '',
  data,
  onSubmit,
  isSubmitting,
  isUpdate = false,
}: RiskMatrixDialogContentProps) => {
  const [name, updateName] = useState(title);
  const [columns, updateColumns] = useState<string[]>(data?.headers || ['']);
  const [scores, updateScores] = useState<string[]>(data?.scores || []);
  const [rows, updateRows] = useState<string[][]>(data?.values || []);
  const [firstColumn, setFirstColumn] = useState('');
  const [secondColumn, setSecondColumn] = useState('');
  const [score, setScore] = useState('');
  const valueInputRef = useRef<HTMLInputElement>(null);

  const handleAddToMatrix = () => {
    if (columns.length === 2) {
      updateRows((prevRows) => [...prevRows, [firstColumn, secondColumn]]);
      setSecondColumn('');
    } else {
      updateRows((prevRows) => [...prevRows, [firstColumn]]);
    }
    updateScores((prevScores) => [...prevScores, score]);
    setFirstColumn('');
    setScore('');
  };

  const handleAddColumn = () => {
    updateColumns((prevColumns) => [...prevColumns, '']);
    updateRows((prevRows) => prevRows.map((row) => [...row, '']));
  };

  const handleRemoveColumn = () => {
    updateColumns((prevColumns) => {
      const newColumns = prevColumns.slice(0, -1);
      return newColumns;
    });
    updateRows((prevRows) => {
      const newRows = prevRows.map((row) => row.slice(0, -1));
      return newRows;
    });
  };

  const handleColumnNameChange = (index: number, value: string) => {
    updateColumns((prevColumns) => {
      const newColumns = [...prevColumns];
      newColumns[index] = value;
      return newColumns;
    });
  };
  const areFieldsFilled =
    firstColumn && score && (columns.length === 1 || secondColumn);

  const disabledReason = useValidateMatrix(name, columns, rows, scores);

  return (
    <div className="text-color-text-dialog-default flex flex-col">
      <div className="flex items-center">
        <Input
          className="mb-2 w-auto text-ellipsis border-0 hover:border focus:border active:border"
          placeholder="Enter Matrix name"
          value={name}
          onChange={(e) => updateName(e.target.value)}
          data-cy="riskMatrixDialog-name-input"
        />
        {columns.length === 1 && (
          <Button
            className="ml-auto h-auto p-0"
            variant="text"
            color="white"
            onClick={handleAddColumn}
          >
            Add another column
          </Button>
        )}
      </div>
      <div
        className={cn(
          'relative flex flex-col',
          'h-[433px] w-full',
          'overflow-hidden rounded-md',
          'border'
        )}
      >
        <RiskMatrixTable
          headers={columns}
          rows={rows}
          scores={scores}
          onAddRowBelow={() => valueInputRef.current?.focus()}
          onColumnNameChange={handleColumnNameChange}
          onColumnRemove={handleRemoveColumn}
          updateRows={updateRows}
          updateScores={updateScores}
        />
        <div
          className={cn(
            'flex items-center gap-3',
            'mt-auto p-2.5',
            'bg-color-bg-table-header'
          )}
        >
          <Input
            value={firstColumn}
            className="text-xs"
            placeholder="Enter a value"
            onChange={(e) => setFirstColumn(e.target.value)}
            ref={valueInputRef}
            data-cy="riskMatrixDialog-col1-input"
          />
          {columns.length === 2 && (
            <Input
              value={secondColumn}
              className="text-xs"
              placeholder="Enter a value"
              onChange={(e) => setSecondColumn(e.target.value)}
              data-cy="riskMatrixDialog-col2-input"
            />
          )}
          <Input
            className="w-28 text-xs"
            value={score}
            type="number"
            placeholder="Enter a score"
            onKeyDown={(e) => {
              if (e.key === '.' || e.key === 'e') e.preventDefault();
            }}
            onChange={(e) => setScore(e.target.value)}
            data-cy="riskMatrixDialog-score-input"
          />
          <Button
            color="cyan"
            variant="outlined"
            className="w-[201px]"
            disabled={!areFieldsFilled}
            onClick={handleAddToMatrix}
            data-cy="riskMatrixDialog-addRow-button"
          >
            Add row to matrix
          </Button>
        </div>
      </div>
      <ButtonWithTooltip
        className="mt-4 w-[174px] self-end"
        size="lg"
        color="cyan"
        disabled={!!disabledReason || isSubmitting}
        onClick={() =>
          onSubmit(name, { headers: columns, values: rows, scores })
        }
        showTooltip={!!disabledReason}
        tooltipProps={{
          content: disabledReason,
          side: 'top',
          sideOffset: 6,
        }}
        data-cy="riskMatrixDialog-submit-button"
      >
        {isSubmitting
          ? `${isUpdate ? 'Updating' : 'Creating'} Matrix...`
          : `${isUpdate ? 'Update' : 'Create'} Matrix`}
      </ButtonWithTooltip>
    </div>
  );
};
