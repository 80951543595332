import { icons } from 'lucide-react';

import { ClientRecordType } from '@spektr/shared/validators';
import { cn } from '@spektr/style-utils';

type CustomerIconProps = {
  type?: ClientRecordType;
  className?: string;
};

export const CustomerIcon = ({ type, className }: CustomerIconProps) => {
  const Icon =
    icons[
      type === 'company'
        ? 'Store'
        : type === 'individual'
          ? 'User'
          : 'CircleHelp'
    ];

  return <Icon className={cn('h-3.5 w-3.5', className)} />;
};
