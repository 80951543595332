import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  ServiceCatalogFilterStates,
  serviceCatalogFilterStatesEnum,
  serviceCatalogStates,
} from '../types/ServiceCatalogStateFilter';

export const useServiceCatalogFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setStateFilter = useCallback(
    (state: string) => {
      sessionStorage.setItem('serviceCatalogStateFilter', state);
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        state,
      });
    },
    [searchParams, setSearchParams]
  );

  const savedStateFilter = sessionStorage.getItem('serviceCatalogStateFilter');
  let paramStateFilter = searchParams.get('state');
  let stateFilter: ServiceCatalogFilterStates = 'all';

  if (!!savedStateFilter && savedStateFilter !== paramStateFilter) {
    paramStateFilter = savedStateFilter;
  }

  if (paramStateFilter) {
    const parsed = serviceCatalogFilterStatesEnum.safeParse(paramStateFilter);

    if (parsed.success) {
      stateFilter = parsed.data;
    }
  }

  const api = useMemo(
    () => ({
      states: serviceCatalogStates,
      stateFilter: stateFilter,
      setStateFilter,
    }),
    [setStateFilter, stateFilter]
  );

  return api;
};
