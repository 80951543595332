import { z } from 'zod';

export const indicatorValueTypes = ['score', 'service'] as const;
export const IndicatorValueTypes = z.enum(indicatorValueTypes);
export type IndicatorValueType = z.infer<typeof IndicatorValueTypes>;

export const servicesIndicatorTypes = [
  'has_id_verification',
  'has_business_verification',
  'is_pep',
  'has_sanctions',
  'has_adverse_media',
] as const;
export const ServicesIndicatorTypes = z.enum(servicesIndicatorTypes);
export type ServicesIndicatorType = z.infer<typeof ServicesIndicatorTypes>;

export const scoreIndicatorTypes = ['value', 'fraud', 'risk'] as const;
export const ScoreIndicatorTypes = z.enum(scoreIndicatorTypes);
export type ScoreIndicatorType = z.infer<typeof ScoreIndicatorTypes>;

export const indicatorTypes = [
  ...scoreIndicatorTypes,
  ...servicesIndicatorTypes,
] as const;
export const IndicatorTypes = z.enum(indicatorTypes);
export type IndicatorType = z.infer<typeof IndicatorTypes>;

export const indicatorStates = [
  'unknown',
  'cleared',
  'notCleared',
  'unclear',
] as const;
export const IndicatorStates = z.enum(indicatorStates);
export type IndicatorState = z.infer<typeof IndicatorStates>;

export const Indicator = z.object({
  state: z.enum(indicatorStates),
  processName: z.string().optional(),
  value: z.number().optional(),
  processId: z.string().optional(),
  processType: z.string().optional(),
  createdAt: z.number().optional(),
});

export type Indicator = z.infer<typeof Indicator>;

export const IndicatorsSchema = z.record(z.enum(indicatorTypes), Indicator);

export type Indicators = z.infer<typeof IndicatorsSchema>;
