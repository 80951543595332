import { cva } from 'class-variance-authority';

export const cardVariants = cva(
  'relative flex flex-col sm:flex-row items-start sm:items-center gap-2 h-full p-4 bg-color-bg-primary rounded-md border transition-colors',
  {
    variants: {
      variant: {
        risk: 'hover:bg-color-yellow/5 hover:border-color-yellow active:bg-color-yellow/30',
        score:
          'hover:bg-color-red/5 hover:border-color-red active:bg-color-red/30',
        monitoring:
          'hover:bg-color-green/5 hover:border-color-green active:bg-color-green/30',
        onboarding:
          'hover:bg-color-blue/5 hover:border-color-blue active:bg-color-blue/30',
        enrichment:
          'hover:bg-color-cyan/5 hover:border-color-cyan active:bg-color-cyan/30',
        fraud:
          'hover:bg-spektr-zinc-600/5 hover:border-spektr-zinc-600 active:bg-spektr-zinc-600/30',
      },
      disabled: {
        true: 'pointer-events-none user-select-none hover-events-none focus-events-none active-events-none',
        false: '',
      },
    },
    defaultVariants: {
      disabled: false,
    },
  }
);

export const iconBackgroundVariants = cva(
  'flex items-center justify-center mr-3 h-10 w-10 flex-shrink-0 rounded p-1',
  {
    variants: {
      variant: {
        risk: 'bg-color-yellow/20',
        score: 'bg-color-red/20',
        monitoring: 'bg-color-green/20',
        onboarding: 'bg-color-blue/20',
        enrichment: 'bg-color-cyan/20',
        fraud: 'bg-spektr-zinc-600/20',
      },
    },
  }
);
