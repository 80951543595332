import { HTMLAttributes, ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

export type PageTitleProps = HTMLAttributes<HTMLHeadingElement> & {
  children: ReactNode;
};

export const PageTitle = ({
  children,
  className,
  ...props
}: PageTitleProps) => (
  <h1
    className={cn('text-[32px]', 'font-medium', 'leading-[32px]', className)}
    {...props}
  >
    {children}
  </h1>
);
