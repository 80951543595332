import { Task } from '@spektr/server/dbconnect';

import { cn } from '@spektr/style-utils';
import { Checkbox } from '@spektr/client/components';

type ManualReviewChecklistProps = {
  tasks: Task[];
  selectedTasks: string[];
  disabled?: boolean;
  onTaskSelection: (taskId: string) => void;
};

export const ManualReviewChecklist = ({
  tasks,
  selectedTasks,
  disabled = false,
  onTaskSelection,
}: ManualReviewChecklistProps) => {
  const isTaskSelected = (taskId: string) => selectedTasks.includes(taskId);

  return (
    <div className="flex flex-col gap-2">
      {tasks.map((task) => (
        <div
          className={cn(
            'flex items-start gap-4 p-5',
            'rounded-md border',
            'bg-color-bg-card-default'
          )}
          key={task.id}
        >
          <Checkbox
            checked={isTaskSelected(task.id)}
            disabled={disabled}
            onCheckedChange={() => onTaskSelection(task.id)}
            aria-label={`manual-review-task-${task.title}`}
          />
          <div
            className={cn(
              'flex flex-col gap-1.5',

              'text-sm font-medium'
            )}
          >
            <p>{task.title}</p>
            <p className="text-color-text-subtext">{task.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
