import { Handle, type HandleProps } from 'reactflow';
import { cva } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

import { ClientRecordStatus } from '@spektr/shared/validators';

export namespace StatefulHandle {
  export type Props = HandleProps & {
    className?: string;
    status: ClientRecordStatus;
  };
}

export const StatefulHandle = ({
  className,
  status,
  ...props
}: StatefulHandle.Props) => (
  <Handle {...props} className={cn(variants({ status, className }))} />
);

const variants = cva(['h-2 w-2', 'border'], {
  variants: {
    status: {
      pending: [
        'border-color-border-process-builder-item',
        'bg-color-bg-process-builder-item',
      ],
      approved: ['border-color-border-success', 'bg-color-bg-primary'],
      rejected: ['border-color-border-error', 'bg-color-bg-primary'],
    },
  },
});
