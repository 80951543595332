import { z } from 'zod';

import { makeApi, makeEndpoint } from '@zodios/core';

import { timelineEventsPaginatedSchema } from '../events';

import { paginatedParameters } from '../pagination';

import {
  clientRecordDetailsSchema,
  Documents,
  ClientRecordOwnersAndControllers,
  clientRecordsListResponseSchema,
  ClientRecordUpdate,
  ClientRecordUploadResponse,
} from './clientRecord';

const getAllClientRecords = makeEndpoint({
  method: 'get',
  path: '/clientRecords',
  alias: 'getAllClientRecords',
  description: 'Get the list of all clientRecords',
  parameters: [
    {
      name: 'limit',
      type: 'Query',
      schema: z.number(),
    },
    {
      name: 'page',
      type: 'Query',
      schema: z.number(),
    },
    {
      name: 'searchQuery',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'datasetIds',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'status',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'tags',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'alertPresence',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'alertType',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'isUbo',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'createdAtStart',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'createdAtEnd',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'updatedAtStart',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'updatedAtEnd',
      type: 'Query',
      schema: z.string().optional(),
    },
  ],
  response: clientRecordsListResponseSchema,
});

const getClientRecordDetails = makeEndpoint({
  method: 'get',
  path: '/clientRecords/:clientRecordId',
  alias: 'getClientRecordDetails',
  description: 'Get a specific clientRecord',
  parameters: [
    {
      name: 'clientRecordId',
      type: 'Path',
      schema: z.string(),
    },
  ],
  response: clientRecordDetailsSchema,
});

const deleteClientRecordById = makeEndpoint({
  method: 'delete',
  path: '/clientRecords/:clientRecordId',
  alias: 'deleteClientRecordById',
  description: 'Delete a specific clientRecord',
  response: z.union([z.void(), z.string().nullable()]),
});

const updateClientRecordById = makeEndpoint({
  method: 'patch',
  path: '/clientRecords/:clientRecordId',
  alias: 'updateClientRecordById',
  description: 'Update a specific clientRecord',
  parameters: [
    {
      name: 'clientRecordId',
      type: 'Path',
      schema: z.string(),
    },
    {
      name: 'clientRecord',
      type: 'Body',
      schema: ClientRecordUpdate,
    },
  ],
  response: z.union([z.void(), z.string().nullable()]),
});

const getClientRecordTimeline = makeEndpoint({
  method: 'get',
  path: '/clientRecords/:clientRecordId/timeline',
  alias: 'getClientRecordTimeline',
  parameters: paginatedParameters,
  response: timelineEventsPaginatedSchema,
});

const getClientRecordDocuments = makeEndpoint({
  method: 'get',
  path: '/clientRecords/:clientRecordId/docs',
  alias: 'getClientRecordDocuments',
  description: 'Get documents of a specific clientRecord',
  parameters: [
    {
      name: 'clientRecordId',
      type: 'Path',
      schema: z.string(),
    },
  ],
  response: Documents,
});

const getClientRecordOwnersAndControllers = makeEndpoint({
  method: 'get',
  path: '/clientRecords/:clientRecordId/owners',
  alias: 'getClientRecordOwnersAndControllers',
  description: 'Get owners and controllers of a specific clientRecord',
  parameters: [
    {
      name: 'clientRecordId',
      type: 'Path',
      schema: z.string(),
    },
  ],
  response: ClientRecordOwnersAndControllers,
});

const uploadClientRecordDocument = makeEndpoint({
  method: 'patch',
  path: '/clientRecords/:clientRecordId/docs',
  alias: 'uploadClientRecordDocument',
  description: 'Upload a document for a specific clientRecord',
  parameters: [
    {
      name: 'clientRecordId',
      type: 'Path',
      schema: z.string(),
    },
    {
      name: 'fileName',
      type: 'Body',
      schema: z.string(),
    },
  ],
  response: ClientRecordUploadResponse,
});

const deleteClientRecordDocument = makeEndpoint({
  method: 'delete',
  path: '/clientRecords/:clientRecordId/docs/:docKey',
  alias: 'deleteClientRecordDocument',
  description: 'Delete a document of a specific clientRecord',
  parameters: [
    {
      name: 'clientRecordId',
      type: 'Path',
      schema: z.string(),
    },
    {
      name: 'docKey',
      type: 'Path',
      schema: z.string(),
    },
  ],
  response: z.void(),
});

const downloadClientRecordProfile = makeEndpoint({
  method: 'get',
  path: '/clientRecords/:clientRecordId/profile',
  alias: 'downloadClientRecordProfile',
  description: 'Download profile of a specific clientRecord',
  parameters: [
    {
      name: 'clientRecordId',
      type: 'Path',
      schema: z.string(),
    },
  ],
  response: z.string(),
});

export const clientRecordsApi = makeApi([
  getAllClientRecords,
  getClientRecordDetails,
  deleteClientRecordById,
  updateClientRecordById,
  getClientRecordTimeline,
  getClientRecordDocuments,
  getClientRecordOwnersAndControllers,
  uploadClientRecordDocument,
  deleteClientRecordDocument,
  downloadClientRecordProfile,
]);
