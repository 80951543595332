import { ListChecks } from 'lucide-react';

import { IconBox } from '@spektr/client/components';

export type ManualReviewNodeProps = {
  title: string;
};

export const ManualReviewNode = ({ title }: ManualReviewNodeProps) => {
  return (
    <>
      <IconBox
        size="lg"
        color="red"
        renderIcon={({ className }) => <ListChecks className={className} />}
      />
      <div className="flex flex-col">
        <h3 className="text-color-text-node line-clamp-1 text-sm font-medium">
          {title}
        </h3>
        <span className="text-color-text-node-subtext text-xs">
          Manual review
        </span>
      </div>
    </>
  );
};
