import { ComboboxItem, ComboboxItemProps } from '@spektr/client/components';

type DatasetFieldProps = {
  value: ComboboxItemProps['value'];
  icon: React.ReactNode;
  label: string;
};

export const SpektrFieldComboBoxItem = ({
  value,
  label,
  icon,
}: DatasetFieldProps) => {
  return (
    <ComboboxItem value={value}>
      <div className="pointer-events-none flex items-center justify-between gap-1">
        <p className="overflow-hidden text-ellipsis">{label}</p>
        {icon}
      </div>
    </ComboboxItem>
  );
};
