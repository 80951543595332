import { Skeleton } from '@spektr/client/components';

import { CustomerTimelineList } from './CustomerTimelineList';
import { CustomerTimelineRow } from './CustomerTimelineRow';

export const SkeletonTimelineView = () => {
  return (
    <CustomerTimelineList>
      {new Array(5).fill(0).map((_, index) => (
        <CustomerTimelineRow key={index} isLast={index === 4}>
          <div className="col-span-4 flex items-center gap-6">
            <Skeleton className="h-9 w-9 shrink-0" />
            <div className="flex flex-1 flex-col gap-1">
              <Skeleton className="h-4 w-auto max-w-16" />
              <Skeleton className="h-5 w-auto max-w-44" />
            </div>
          </div>
          <div className="col-span-2 flex flex-col gap-1">
            <Skeleton className="h-4 w-auto max-w-16" />
            <Skeleton className="h-5 w-auto max-w-44" />
          </div>
          <div className="col-span-2 flex flex-col gap-1">
            <Skeleton className="h-4 w-auto max-w-16" />
            <Skeleton className="h-5 w-auto max-w-44" />
          </div>
          <div className="col-span-2 flex flex-col gap-1">
            <Skeleton className="h-4 w-auto max-w-16" />
            <Skeleton className="h-5 w-auto max-w-44" />
          </div>
          <div className="col-span-2 col-start-11 flex items-center">
            <Skeleton className="ml-auto h-4 w-28" />
          </div>
        </CustomerTimelineRow>
      ))}
    </CustomerTimelineList>
  );
};
