import { ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

export type DashboardHeaderProps = {
  children?: ReactNode | ReactNode[];
  className?: string;
};

export const DashboardHeader = ({
  children,
  className,
}: DashboardHeaderProps) => {
  return (
    <div
      className={cn(
        'dark:bg-color-bg-primary bg-color-white',
        'flex shrink-0 items-center',
        'box-content',
        'h-header px-4',
        'border-b',
        className
      )}
    >
      {children}
    </div>
  );
};
