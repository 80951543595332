import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { Copy, CopyPlus, Inbox, SquarePen, Trash2 } from 'lucide-react';
import { MouseEvent, useMemo, useState } from 'react';

import {
  AlertDialog,
  DropdownOption,
  ProcessCard,
  toast,
} from '@spektr/client/components';
import {
  ProcessApiClient,
  getLiveProcessVersions,
  getProcessesQuery,
  getProcessesQueryKey,
} from '@spektr/client/services';
import { cn } from '@spektr/style-utils';

import { loopDetailsUrl } from '@spektr/shared/utils';

import { RenameProcessDialog } from '../../../RenameProcessDialog';
import { getNumberOfSources } from '../../utils';

export type LoopsListProps = {
  status: string;
};

const ACTIONS: DropdownOption[] = [
  {
    type: 'item',
    label: 'Rename loop',
    icon: <SquarePen className="h-4 w-4" />,
    value: 'rename',
  },
  {
    type: 'separator',
    value: 'separator',
  },
  {
    type: 'item',
    label: 'Duplicate loop',
    icon: <CopyPlus className="h-4 w-4" />,
    value: 'duplicate',
  },
  {
    type: 'separator',
    value: 'separator-2',
  },
  {
    type: 'item',
    label: 'Delete loop',
    icon: <Trash2 className="h-4 w-4" />,
    value: 'delete',
    variant: 'danger',
  },
];

export const LoopsList = ({ status }: LoopsListProps) => {
  const queryClient = useQueryClient();
  const [confirmDeleteProcessId, setConfirmDeleteProcessId] = useState<
    string | undefined
  >(undefined);
  const [renameProcessId, setRenameProcessId] = useState<string | undefined>(
    undefined
  );
  const { data: allLoops = [] } = useSuspenseQuery(
    getProcessesQuery({ types: ['loop'] })
  );
  const { data: liveProcessVersions } = useSuspenseQuery(
    getLiveProcessVersions()
  );

  const loops = useMemo(() => {
    return allLoops
      .filter((loop) => {
        const isLive = liveProcessVersions?.some(
          (liveVersion) => liveVersion.processId === loop.id
        );

        if (status === 'live') return isLive;

        if (status === 'not-live') return !isLive;

        return true; // default to 'all'
      })
      .sort((a, b) => {
        return b.createdAt - a.createdAt;
      });
  }, [allLoops, liveProcessVersions, status]);

  const deleteProcess = useMutation({
    mutationFn: (id: string) =>
      ProcessApiClient.getClient().deleteProcessById(undefined, {
        params: { id },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getProcessesQueryKey() });
      setConfirmDeleteProcessId(undefined);
    },
  });

  const updateProcess = useMutation({
    mutationFn: ({ processId, name }: { processId: string; name: string }) =>
      ProcessApiClient.getClient().updateProcessById(
        {
          name,
        },
        {
          params: {
            id: processId,
          },
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getProcessesQueryKey() });
      setRenameProcessId(undefined);
      toast.success({
        title: 'Successfully updated',
        description: 'The process was updated.',
      });
    },
  });

  const handleConfirmDelete = async () => {
    if (confirmDeleteProcessId) {
      await deleteProcess.mutateAsync(confirmDeleteProcessId);
    }
  };

  const duplicateProcess = useMutation({
    mutationFn: (processId: string) =>
      ProcessApiClient.getClient().duplicateProcess(undefined, {
        params: {
          id: processId,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getProcessesQueryKey() });
    },
  });

  const onActionClick =
    (processId: string) => (action: string, ev: MouseEvent) => {
      ev.stopPropagation();

      switch (action) {
        case 'rename':
          setRenameProcessId(processId);
          break;
        case 'delete':
          setConfirmDeleteProcessId(processId);
          break;
        case 'duplicate':
          duplicateProcess.mutateAsync(processId);
          break;
      }
    };

  if (loops.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center gap-2 p-8">
        <Inbox className="stroke-color-red h-8 w-8" />
        <span className="text-color-text-error-boundry">
          No loops found. Create a new loop to get started.
        </span>
      </div>
    );
  }
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      {loops.map((loop) => (
        <ProcessCard
          actions={ACTIONS}
          key={loop.id}
          route={loopDetailsUrl(loop.id)}
          routeState={{ processType: loop.type }}
          name={loop.name}
          type={loop.type}
          isLive={liveProcessVersions?.some(
            (version) => version.processId === loop.id
          )}
          updatedAt={loop.updatedAt}
          onActionClick={onActionClick(loop.id)}
        >
          <div className="align-center flex items-center gap-2">
            <div
              className={cn(
                'h-5 w-5 shrink-0',
                'flex items-center justify-center',
                'bg-color-yellow/20',
                'rounded'
              )}
            >
              <Copy className="stroke-color-yellow h-3" />
            </div>
            <div className="flex items-center">
              <p>
                <span className="font-medium">
                  {getNumberOfSources(loop)} sources{' '}
                </span>
                <span className="text-color-text-subtext">
                  added to this loop
                </span>
              </p>
            </div>
          </div>
        </ProcessCard>
      ))}

      <AlertDialog
        open={!!confirmDeleteProcessId}
        title="Are you sure?"
        paragraph="You will loose unsaved changes not published in a loop version."
        onCancel={() => setConfirmDeleteProcessId(undefined)}
        cancel="Cancel"
        onConfirm={handleConfirmDelete}
        confirm="Delete"
        confirmDataCy="Yes-delete"
      />
      <RenameProcessDialog
        buttonLabel="Rename loop"
        buttonColor="red"
        process={loops?.find((loop) => loop.id === renameProcessId)}
        open={!!renameProcessId}
        title="Rename loop"
        onClose={() => {
          setRenameProcessId(undefined);
        }}
        onSave={(name: string) => {
          if (!renameProcessId) {
            return;
          }
          updateProcess.mutateAsync({ processId: renameProcessId, name });
        }}
      />
    </div>
  );
};
