import { ReactNode, createContext, useContext } from 'react';

import {
  SpektrField,
  SpektrFieldOperator,
  spektrOperators,
  StrictOmit,
  WithOptional,
} from '@spektr/shared/types';

import {
  SpektrFieldType,
  SpektrFieldTypedKey,
} from '@spektr/shared/validators';

import {
  IncompletePredicate,
  IncompleteRuleGroup,
  addParentToRightmostInnerGroup,
  addParentToRightmostRuleInInnerGroup,
  deleteInPredicateById,
  getPredicateById,
  updateBooleanOperatorsBetweenGroups,
  updateBooleanOperatorsInInnerGroup,
  updateInPredicateById,
} from '../tree-helpers';

export type RightOperandInputType = 'text' | 'date-picker' | 'none' | 'range';

export type RuleGroupProviderProps = {
  group: IncompleteRuleGroup;
  spektrFields: SpektrField[];
  icon?: (ruleId: string, innerGroupId: string) => ReactNode;
  onChange?: (group: IncompleteRuleGroup | undefined) => void;
};

const IncompleteRuleGroupContext = createContext(
  {} as IncompleteRuleGroupContextType
);

export const useIncompleteRuleGroup = () =>
  useContext(IncompleteRuleGroupContext);

export type IncompleteRuleGroupContextType = {
  group: IncompleteRuleGroup;
  spektrFields: SpektrField[];
  icon?: (ruleId: string, innerGroupId: string) => ReactNode;
  addCondition: (groupId: string) => void;
  addInnerGroup: () => void;
  getPredicate: (ruleId: string) => IncompletePredicate | undefined;
  getRightOperandInputType: (
    ruleId: string,
    operatorKey: string | undefined
  ) => RightOperandInputType;
  getPossibleOperatorsForRule: (ruleId: string) => SpektrFieldOperator[];
  removePredicate: (predicateId: string) => void;
  setOperatorBetweenGroups: (operator: string) => void;
  setOperatorWithinGroup: (groupId: string, operator: string) => void;
  setPredicate: (
    predicateId: string,
    changes: Partial<StrictOmit<IncompletePredicate, 'id'>>
  ) => void;
  updateGroup: (
    changes: Partial<StrictOmit<IncompleteRuleGroup, 'id' | 'rule'>>
  ) => void;
};

export const RuleGroupProvider = ({
  group,
  spektrFields,
  onChange,
  icon,
  children,
}: RuleGroupProviderProps & { children: ReactNode }) => {
  const addCondition = (innerGroupId: string) => {
    if (!group) return;
    const innerGroup = getPredicateById(group.rule, innerGroupId);

    if (!innerGroup) return;

    const updatedInnerGroup = addParentToRightmostRuleInInnerGroup(
      group.rule,
      innerGroupId
    );

    onChange?.({ ...group, rule: updatedInnerGroup });
  };

  const addInnerGroup = () => {
    if (!group) return;
    const updated = addParentToRightmostInnerGroup(group.rule);

    onChange?.({ ...group, rule: updated });
  };

  const getPossibleOperatorsForRule = (
    ruleId: string
  ): SpektrFieldOperator[] => {
    const left = getLeftOperand(ruleId);

    if (!left) return [];

    let operators = spektrOperators.filter(
      (operator) => operator.type === left.type
    );

    if (operators.length === 0) operators = spektrOperators;

    return operators;
  };

  const getRightOperandInputType = (
    ruleId: string,
    operatorKey?: string
  ): RightOperandInputType => {
    const rule = getPredicate(ruleId);
    if (!rule) return 'none';

    const left = getLeftOperand(rule);

    if (!left) return 'none';
    if (!left.type) {
      switch (rule.operator) {
        case 'not_equals':
        case 'equals':
        case 'greater_than':
        case 'less_than':
          return 'text';
        case 'is_after':
        case 'is_before':
          return 'date-picker';
        case 'between':
        case 'outside':
          return 'range';
        case 'is_empty':
        case 'is_not_empty':
          return 'none';
        default:
          return 'none';
      }
    }

    if (rule.operator === 'is_empty') return 'none';
    if (rule.operator === 'is_not_empty') return 'none';

    if (operatorKey === 'between' || operatorKey === 'outside') return 'range';

    switch (left.type) {
      case 'string':
      case 'number':
        return 'text';
      case 'boolean':
        return 'none';
      case 'date':
        return 'date-picker';
      case 'country':
        return 'text';
      case 'file':
        return 'none';
      case 'matrix':
        return 'none';
      default:
        return 'none';
    }
  };

  const removePredicate = (predicateId: string) => {
    if (!group) return;
    const updatedPredicate = deleteInPredicateById(group.rule, predicateId);

    if (!updatedPredicate) return onChange?.(undefined); // delete segment if predicate is deleted

    onChange?.({ ...group, rule: updatedPredicate });
  };

  const setOperatorBetweenGroups = (operator: string) => {
    if (!group) return;

    const updatedGroup = updateBooleanOperatorsBetweenGroups(
      group.rule,
      operator
    );

    onChange?.({ ...group, rule: updatedGroup });
  };

  const setPredicate = (
    predicateId: string,
    changes: Partial<StrictOmit<IncompletePredicate, 'id'>>
  ) => {
    if (!group) return;
    const updatedPredicate = updateInPredicateById(
      group.rule,
      predicateId,
      changes
    );

    onChange?.({ ...group, rule: updatedPredicate });
  };

  const setOperatorWithinGroup = (innerGroupId: string, operator: string) => {
    if (!group) return;

    const innerGroup = getPredicateById(group.rule, innerGroupId);
    if (!innerGroup) return;

    const updatedInnerGroup = updateBooleanOperatorsInInnerGroup(
      group.rule,
      innerGroupId,
      operator
    );

    onChange?.({ ...group, rule: updatedInnerGroup });
  };

  const getLeftOperand = (
    ruleOrId: string | IncompletePredicate
  ): WithOptional<SpektrFieldTypedKey, 'type'> | undefined => {
    const rule =
      typeof ruleOrId === 'string' ? getPredicate(ruleOrId) : ruleOrId;

    if (!rule) return undefined;

    const field = spektrFields.find((field) => field.key === rule.left);

    if (field) {
      return field;
    } else if (typeof rule.left === 'string') {
      return {
        key: rule.left,
        type: rule.type as SpektrFieldType,
      };
    } else {
      return undefined;
    }
  };

  const getPredicate = (ruleId: string) => {
    if (!group) return undefined;
    else return getPredicateById(group.rule, ruleId);
  };

  const updateGroup = (
    changes: Partial<StrictOmit<IncompleteRuleGroup, 'id' | 'rule'>>
  ) => {
    if (!group) return;
    onChange?.({ ...group, ...changes });
  };

  return (
    <IncompleteRuleGroupContext.Provider
      value={{
        group,
        icon,
        spektrFields,
        addCondition,
        addInnerGroup,
        getPossibleOperatorsForRule,
        getPredicate,
        getRightOperandInputType,
        removePredicate,
        setOperatorBetweenGroups,
        setOperatorWithinGroup,
        setPredicate,
        updateGroup,
      }}
    >
      {children}
    </IncompleteRuleGroupContext.Provider>
  );
};
