import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { HitWithVendorDetails } from '@spektr/shared/validators';

import { getAlertHitById } from '@spektr/client/services';

import { computeHitDetails } from '../utils';
import { useAlertAndHitIdParam, useRenderDetailsTable } from '../hooks';
import { BasicDetailsTable } from '../components/BasicDetailsTable';

export const HitData = () => {
  const { alertId, hitId } = useAlertAndHitIdParam();
  const { renderColumn, renderValue } = useRenderDetailsTable();

  const { data: hit } = useSuspenseQuery(getAlertHitById(alertId, hitId));
  const details = useMemo(
    () => computeHitDetails(hit as HitWithVendorDetails),
    [hit]
  );

  const hasDetails =
    details.length > 0 && details.some((table) => !isEmpty(table));

  return (
    <div className="flex flex-col gap-4">
      {hasDetails ? (
        details.map((table, index) => (
          <BasicDetailsTable
            key={index}
            data={table}
            columnClassName="w-[320px]"
            renderColumn={renderColumn}
            renderValue={renderValue}
          />
        ))
      ) : (
        <div>No additional information for this hit.</div>
      )}
    </div>
  );
};
