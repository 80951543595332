import { useCallback, useMemo, useState } from 'react';

import { getValidPercentage } from '@spektr/shared/utils';

export const usePercentageInput = (
  initialValue: string,
  onChange?: (value: string) => void
) => {
  const [percentageValue, updatePercentage] = useState(initialValue);

  const getPercentage = (input: string) => {
    const validPercentage = getValidPercentage(input);

    if (validPercentage.length > 1 && validPercentage.startsWith('0')) {
      return validPercentage.slice(1);
    }

    return validPercentage ? validPercentage : '0';
  };

  const handlePercentageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value || '';
      const percentage = getPercentage(input);

      if (percentage) {
        updatePercentage(percentage);
        onChange?.(percentage);
      }
    },
    [onChange]
  );

  return useMemo(
    () => ({
      percentageValue,
      handlePercentageChange,
    }),
    [percentageValue, handlePercentageChange]
  );
};
