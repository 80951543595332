import { useForm } from 'react-hook-form';

import { Button, Input, Label } from '@spektr/client/components';
import { FileUploader } from '@spektr/model-builder/components';

type UploadDocumentFormValues = {
  description: string;
  file?: File;
};

type DocumentUploadProps = {
  maxSizeInBytes?: number;
  onUpload: () => void;
};

export const DocumentUpload = ({
  maxSizeInBytes,
  onUpload,
}: DocumentUploadProps) => {
  const { register, setValue, formState, watch } =
    useForm<UploadDocumentFormValues>();
  const file = watch('file');

  const handleUpload = () => {
    onUpload();
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="mb-4 flex flex-col gap-2">
        <Label
          htmlFor="document-description"
          className="text-color-text-subtext"
        >
          Description
        </Label>
        <Input
          autoFocus
          required
          id="document-description"
          {...register('description', { required: true })}
        />
      </div>

      {/* TODO: @cristina - when implementing endpoint, add accepted file types */}
      <FileUploader
        className="h-36 [&>svg]:mb-5 [&>svg]:h-6 [&>svg]:w-6"
        currentFile={file}
        maxSize={maxSizeInBytes}
        iconName="CloudUpload"
        variant="cyan"
        onDropAccepted={([acceptedFile]) => {
          setValue('file', acceptedFile);
        }}
        onRemove={() => setValue('file', undefined)}
      />

      <div className="ml-auto flex flex-row gap-4">
        <Button
          disabled={!formState.isValid || !file}
          variant="text"
          onClick={handleUpload}
        >
          Upload document
        </Button>
      </div>
    </div>
  );
};
