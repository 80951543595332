import { useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Button, Input, Textarea } from '@spektr/client/components';

import { Task } from '@spektr/shared/validators';

type TaskCreationBlockProps = {
  onAddTask: (task: Task) => void;
};

export const TaskCreationBlock = ({ onAddTask }: TaskCreationBlockProps) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const handleAddTask = () => {
    onAddTask({
      id: uuid(),
      title,
      description,
    });
    setTitle('');
    setDescription('');
  };

  return (
    <div className="bg-color-bg-card-default dark:bg-color-bg-card-accent flex flex-col gap-4 rounded-md p-4">
      <p className="text-sm font-medium">
        Add items to your checklist that will show up in case management
      </p>
      <Input
        value={title}
        onChange={(event) => setTitle(event.target.value)}
        placeholder="Task title"
      />
      <Textarea
        className="text-sm"
        value={description}
        onChange={(event) => setDescription(event.target.value)}
        placeholder="Task description"
      />
      <Button
        className="ml-auto"
        onClick={handleAddTask}
        disabled={!title.trim() || !description.trim()}
      >
        Add task
      </Button>
    </div>
  );
};
