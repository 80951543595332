export const SETTINGS_URL = '/settings';
export const SETTINGS_WORKSPACE_URL = 'workspace';
export const SETTINGS_DEVELOPER_URL = 'developer';
export const SETTINGS_CUSTOM_DOMAIN_URL = 'custom-domain';
export const SETTINGS_BRANDING_URL = 'branding';

export const SETTINGS_WORKSPACE_FULL_URL = `${SETTINGS_URL}/${SETTINGS_WORKSPACE_URL}`;
export const SETTINGS_DEVELOPER_FULL_URL = `${SETTINGS_URL}/${SETTINGS_DEVELOPER_URL}`;
export const SETTINGS_CUSTOM_DOMAIN_FULL_URL = `${SETTINGS_URL}/${SETTINGS_CUSTOM_DOMAIN_URL}`;
export const SETTINGS_BRANDING_FULL_URL = `${SETTINGS_URL}/${SETTINGS_BRANDING_URL}`;
