import { Trash2 } from 'lucide-react';

import { DialogFooter, Skeleton } from '@spektr/client/components';

import { useFeatureFlags } from '@spektr/platform-hooks';

export const ContentSkeleton = () => {
  const { newServiceConfig } = useFeatureFlags();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <p className="text-color-text-subtext flex items-center gap-1 text-xs font-medium">
            Map attributes from your dataset
          </p>

          <div className="flex max-h-[280px] flex-col overflow-y-auto rounded-md border">
            <div className="flex h-[52px] shrink-0 items-center justify-start border-b px-4 text-left transition-colors last:border-b-0">
              <div className="text-color-text-subtext grid w-full grid-cols-[105px_auto_1fr_auto] items-center gap-3 text-xs">
                <span className="text-color-text-primary">Company Number</span>
                <span className="capitalize">string</span>
                <div className="ml-auto">
                  <Skeleton className="h-7 w-52" />
                </div>
              </div>
            </div>

            <div className="flex h-[52px] shrink-0 items-center justify-start border-b px-4 text-left transition-colors last:border-b-0">
              <div className="text-color-text-subtext grid w-full grid-cols-[105px_auto_1fr_auto] items-center gap-3 text-xs">
                <span className="text-color-text-primary">
                  Jurisdiction code
                </span>
                <span className="capitalize">string</span>
                <div className="ml-auto">
                  <Skeleton className="h-7 w-52" />
                </div>
              </div>
            </div>

            <div className="flex h-[52px] shrink-0 items-center justify-start border-b px-4 text-left transition-colors last:border-b-0">
              <div className="text-color-text-subtext grid w-full grid-cols-[105px_auto_1fr_auto] items-center gap-3 text-xs">
                <span className="text-color-text-primary">Company Name</span>
                <span className="capitalize">string</span>
                <div className="ml-auto">
                  <Skeleton className="h-7 w-52" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {!newServiceConfig ? (
          <div className="flex max-h-[280px] flex-col overflow-y-auto rounded-md border">
            <div className="flex h-[52px] shrink-0 items-center justify-start border-b px-4 text-left transition-colors last:border-b-0">
              <span className="text-color-text-primary flex items-center gap-2 whitespace-normal text-xs">
                Select frequency for trigger
              </span>
              <div className="ml-auto flex items-center gap-2">
                <Skeleton className="h-7 w-40" />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-3">
            <p>Monitoring of fields</p>
            <div className="flex flex-col rounded-md border">
              <div className="flex items-center gap-4 border-b p-4">
                <Skeleton className="h-9 w-36" />
                <Skeleton className="h-9 w-28" />
                <Trash2 className="stroke-color-text-subtext ml-auto h-4 w-4" />
              </div>
              <div className="flex items-center gap-6 p-4">
                <Skeleton className="h-9 flex-1" />
                <Skeleton className="h-9 flex-1" />
                <Skeleton className="h-9 flex-1" />
              </div>
            </div>
            <div className="flex flex-col rounded-md border">
              <div className="flex items-center gap-4 border-b p-4">
                <Skeleton className="h-9 w-36" />
                <Skeleton className="h-9 w-28" />
                <Trash2 className="stroke-color-text-subtext ml-auto h-4 w-4" />
              </div>
              <div className="flex items-center gap-6 p-4">
                <Skeleton className="h-9 flex-1" />
                <Skeleton className="h-9 flex-1" />
                <Skeleton className="h-9 flex-1" />
              </div>
            </div>
          </div>
        )}
      </div>

      <DialogFooter>
        <Skeleton className="h-9 w-36" />
      </DialogFooter>
    </div>
  );
};
