import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { produce } from 'immer';
import { uniqueId } from 'lodash';

import { useDebouncedSearch } from '@spektr/platform-hooks';

import { DatasetResponse } from '@spektr/shared/types';

import { ColumnItemType, DragItem } from '../types';

import { useRecordsContext } from './RecordsContextProvider';

type ColumnsContextType = {
  columns: ColumnItemType[];
  searchColumnValue: string;
  columnFilter: string;
  canDrag: boolean;
  setSearchColumnValue: (value: string) => void;
  handleItemVisibilityChange: (id: string, isHidden: boolean) => void;
  handleItemMove: (
    item: DragItem,
    newIndex: number,
    newHiddenValue: boolean
  ) => void;
};

export const ColumnsContext = createContext<ColumnsContextType>({
  columns: [],
  searchColumnValue: '',
  columnFilter: '',
  canDrag: false,
  setSearchColumnValue: () => null,
  handleItemVisibilityChange: () => null,
  handleItemMove: () => null,
});

export const useColumnsContext = () => useContext(ColumnsContext);

type ColumnsProviderProps = {
  dataset: DatasetResponse | undefined;
  children: React.ReactNode;
};

export const ColumnsProvider = ({
  dataset,
  children,
}: ColumnsProviderProps) => {
  const { visibleColumns, updateColumnVisibility } = useRecordsContext();
  const columnItems = useMemo(
    () =>
      dataset?.fields
        .sort((a, b) => {
          const aIndex = visibleColumns.indexOf(a.name);
          const bIndex = visibleColumns.indexOf(b.name);

          if (aIndex === -1) return 1;
          if (bIndex === -1) return -1;
          return aIndex - bIndex;
        })
        .map((field) => ({
          id: uniqueId(`${field.name}-`),
          name: field.name,
          isHidden: !visibleColumns.includes(field.name),
        })) ?? [],
    [dataset?.fields, visibleColumns]
  );
  const [columns, updateColumns] = useState(columnItems);
  const { searchValue, debouncedValue, setSearchValue } = useDebouncedSearch();

  const handleItemVisibilityChange = useCallback(
    (name: string, isHidden: boolean) => {
      updateColumns(
        produce(columns, (draft) => {
          const item = draft.find((column) => column.name === name);
          if (item) {
            item.isHidden = isHidden;
            const index = draft.findIndex((column) => column.name === name);
            draft.splice(index, 1);
            const firstHiddenIndex = draft.findIndex(
              (column) => column.isHidden
            );
            draft.splice(firstHiddenIndex, 0, item);
          }
        })
      );
      updateColumnVisibility(name, isHidden);
    },
    [columns, updateColumnVisibility]
  );

  const handleItemMove = useCallback(
    (item: DragItem, newIndex: number, newHiddenValue: boolean) => {
      updateColumns(
        produce(columns, (draft) => {
          const indexOfItem = draft.findIndex(
            (column) => column.name === item.name
          );
          draft.splice(indexOfItem, 1);
          draft.splice(newIndex, 0, {
            ...item,
            isHidden: newHiddenValue,
          });
        })
      );

      updateColumnVisibility(item.name, newHiddenValue, newIndex);
    },
    [columns, updateColumnVisibility]
  );

  const providedValue = useMemo(
    () => ({
      columns,
      searchColumnValue: searchValue,
      columnFilter: debouncedValue,
      canDrag: searchValue.length === 0,
      setSearchColumnValue: setSearchValue,
      handleItemVisibilityChange,
      handleItemMove,
    }),
    [
      columns,
      searchValue,
      debouncedValue,
      setSearchValue,
      handleItemMove,
      handleItemVisibilityChange,
    ]
  );

  return (
    <ColumnsContext.Provider value={providedValue}>
      {children}
    </ColumnsContext.Provider>
  );
};
