import { cn } from '@spektr/style-utils';
import { TableHead } from '@spektr/client/components';

type SettingsTableHeadProps = {
  children: React.ReactNode;
  className?: string;
};

export const SettingsTableHead = ({
  children,
  className,
}: SettingsTableHeadProps) => {
  return (
    <TableHead
      className={cn(
        'text-color-text-table-header p-4 text-xs font-medium',
        className
      )}
    >
      {children}
    </TableHead>
  );
};
