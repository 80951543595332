import { useMemo, useState } from 'react';

import {
  HitDecision,
  HitResolvedDecision,
  UserSchema,
} from '@spektr/shared/validators';

export const useHitResolutionReason = (
  decision: HitDecision | HitResolvedDecision,
  users: UserSchema[]
) => {
  const resolvedDecision = decision as HitResolvedDecision;
  const [reason, updateReason] = useState<string>('');
  const user = useMemo(
    () =>
      users?.find((u) => u.id === resolvedDecision.userId) ?? {
        id: '',
        username: '',
        status: 'verified' as const,
        firstName: 'spektr',
        lastName: 'automation',
      },
    [users, resolvedDecision]
  );

  return useMemo(
    () => ({
      reason: resolvedDecision.reason || reason,
      updateReason,
      user,
      resolvedAt: resolvedDecision.createdAt,
    }),
    [reason, updateReason, user, resolvedDecision]
  );
};
