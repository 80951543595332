import z from 'zod';

import { unixTimestampSchema } from '../utils';

export const domainAliasStatuses = ['active', 'pending', 'deleted'] as const;
export const DomainAliasStatus = z.enum(domainAliasStatuses);
export type DomainAliasStatus = z.infer<typeof DomainAliasStatus>;

export const ResourceRecord = z.strictObject({
  name: z.string().min(1),
  type: z.string().min(1),
  value: z.string().min(1),
});
export type ResourceRecord = z.infer<typeof ResourceRecord>;

export const DomainAliasSchema = z.object({
  id: z.string(),
  workspaceId: z.string().min(1),
  domainName: z.string().min(1),
  status: DomainAliasStatus,
  resourceRecords: z.array(ResourceRecord),
  createdAt: unixTimestampSchema,
  updatedAt: unixTimestampSchema,
  distributionId: z.string().optional(),
  certificateArn: z.string().optional(),
});

export type DomainAliasSchema = z.infer<typeof DomainAliasSchema>;

// *.example.com
const domainNamePart = z
  .string()
  .min(1)
  .max(63)
  .regex(/^[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i);

export const DomainAliasCreateSchema = z.object({
  domainName: z
    .string()
    .min(1)
    .max(1024)
    .refine((val) => {
      const domainParts = val.split('.');
      if (domainParts.some((part) => part.length === 0)) {
        return false;
      }
      return domainParts.every(
        (part) => domainNamePart.safeParse(part).success
      );
    }, 'Invalid domain format'),
});
export type DomainAliasCreateSchema = z.infer<typeof DomainAliasCreateSchema>;

export const DomainAliasUpdateSchema = z.object({
  status: DomainAliasStatus,
  resourceRecords: z.array(ResourceRecord),
  distributionId: z.string().optional(),
  certificateArn: z.string().optional(),
});

export type DomainAliasUpdateSchema = z.infer<typeof DomainAliasUpdateSchema>;

export const DomainAliasDto = z.strictObject({
  domainName: z.string().min(1),
  status: DomainAliasStatus,
  resourceRecords: z.array(ResourceRecord),
  distributionId: z.string().optional(),
  certificateArn: z.string().optional(),
});
export type DomainAliasDto = z.infer<typeof DomainAliasDto>;
