import { z } from 'zod';

import { objectIdSchema } from '../common';

export const ExecutionInputSchema = z.strictObject({
  processId: objectIdSchema,
  spektrIds: z.array(objectIdSchema),
});
export type ExecutionInputSchema = z.infer<typeof ExecutionInputSchema>;

export const ExecutionResponseSchema = z.strictObject({
  data: z.array(objectIdSchema),
});
export type ExecutionResponseSchema = z.infer<typeof ExecutionResponseSchema>;
