import { z } from 'zod';

export const serviceCatalogStates = [
  'all',
  'configured',
  'notConfigured',
] as const;

export const serviceCatalogFilterStatesEnum = z.enum(serviceCatalogStates);
export type ServiceCatalogFilterStates = (typeof serviceCatalogStates)[number];
