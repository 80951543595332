import { Trash } from 'lucide-react';

import { Button, Checkbox } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

import { Task } from '@spektr/shared/validators';

type TaskItemProps = {
  task: Task;
  deleteTask: (taskId: string) => void;
};

export const TaskItem = ({ task, deleteTask }: TaskItemProps) => {
  return (
    <div className="bg-color-bg-card-default dark:bg-color-bg-card-accent mr-3 flex items-start gap-4 rounded-md p-5">
      <Checkbox disabled />
      <div className="flex flex-col text-sm font-medium">
        <span>{task.title}</span>
        <span className="text-color-text-subtext">{task.description}</span>
      </div>
      <Button
        variant="text"
        className={cn(
          'h-3.5 w-3.5',
          'ml-auto shrink-0 p-0',
          'text-color-text-subtext hover:text-color-red'
        )}
        onClick={() => deleteTask(task.id)}
      >
        <Trash className="" />
      </Button>
    </div>
  );
};
