import { useState } from 'react';
import { Columns3 } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { Popover } from '@spektr/client/components';

import { ColumnsProvider, useRecordsContext } from '../providers';

import { ColumnsContainer } from './ColumnsContainer';

export const ColumnsDropdown = () => {
  const { selectedDataset } = useRecordsContext();
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  return (
    <Popover
      open={isPopoverOpen}
      side="bottom"
      align="end"
      onOpenChange={setPopoverOpen}
      trigger={
        <div
          className={cn(
            'flex h-9 items-center gap-2 px-4 py-2.5',
            'rounded-md border',
            'text-color-text-subtext text-sm font-medium'
          )}
        >
          <Columns3 className="h-4 w-4" />
          Columns
        </div>
      }
    >
      <ColumnsProvider dataset={selectedDataset}>
        <ColumnsContainer />
      </ColumnsProvider>
    </Popover>
  );
};
