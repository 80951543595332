import { DialogFooter, Skeleton } from '@spektr/client/components';

export const CalculationSkeleton = () => (
  <div className="flex flex-col gap-4 px-2">
    <section className="flex flex-col gap-4">
      <div className="flex h-12 flex-row items-center justify-between">
        <Skeleton className="h-6 w-80" />
        <Skeleton className="h-6 w-16" />
      </div>
      <div className="rounded-radius flex flex-col border">
        <div className="bg-color-bg-card-accent flex h-[52px] flex-row items-center justify-between p-3">
          <Skeleton className="h-6 w-80" />
          <Skeleton className="h-6 w-16" />
        </div>
        <div className="flex flex-col gap-3 px-3 py-4">
          <Skeleton className="h-7 w-full" />
        </div>
      </div>
      <div className="rounded-radius flex flex-col border">
        <div className="bg-color-bg-card-accent flex h-[52px] flex-row items-center justify-between p-3">
          <Skeleton className="h-6 w-80" />
          <Skeleton className="h-6 w-16" />
        </div>
        <div className="flex flex-col gap-3 px-3 py-4">
          <Skeleton className="h-7 w-full" />
        </div>
      </div>
    </section>
    <div className="rounded-radius flex w-full justify-center border border-dashed py-5">
      <Skeleton className="h-8 w-28" />
    </div>
    <div className="grid grid-cols-2 gap-4">
      <div className="rounded-radius flex flex-row items-center gap-6 border border-dashed px-6 py-4">
        <Skeleton className="h-5 w-5" />
        <div className="flex grow flex-col gap-1.5">
          <Skeleton className="h-5 w-20" />
          <div className="flex flex-col gap-1">
            <Skeleton className="h-5 w-[70%]" />
            <Skeleton className="h-5 w-1/2" />
          </div>
        </div>
      </div>
      <div className="rounded-radius flex flex-row items-center gap-6 border border-dashed px-6 py-4">
        <Skeleton className="h-5 w-5" />
        <div className="flex grow flex-col gap-1.5">
          <Skeleton className="h-5 w-20" />
          <div className="flex flex-col gap-1">
            <Skeleton className="h-5 w-[70%]" />
            <Skeleton className="h-5 w-1/2" />
          </div>
        </div>
      </div>
    </div>
    <DialogFooter className="pt-6">
      <Skeleton className="h-9 w-16" />
    </DialogFooter>
  </div>
);
