import { useState } from 'react';
import { Trash } from 'lucide-react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Input,
  Label,
  Select,
} from '@spektr/client/components';
import { cn } from '@spektr/style-utils';
import { type AlertField } from '@spektr/client/types';

import { useServiceAlertNodeContext } from '../providers/ServiceAlertNodeProvider';

import { getUpdatedFields } from '../utils/';

import { AlertFieldsDropdown } from './AlertFieldsDropdown';

type ServiceAlertProps = {
  name?: string;
  field?: string;
  index: number;
};

export const ServiceAlert = ({
  name = 'New Alert',
  field,
  index,
}: ServiceAlertProps) => {
  const { users, allFields, updateAlert, removeAlert } =
    useServiceAlertNodeContext();
  const [alertName, updateAlertName] = useState(name);
  const [assignedUser, setAssignedUser] = useState('');

  const userOptions = users.map((user) => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`,
  }));

  const handleAssigneeChange = (value: string) => {
    setAssignedUser(value);
    // TODO: @Alex - enable after api supports user assignment
    // updateAlert(index, assignedUser: value);
  };

  const handleFieldChange = (field: AlertField) => {
    const fields = getUpdatedFields(allFields, field.name, field.nodeType);
    updateAlert(index, {
      fields,
      processId: field.processId,
      nodeId: field.nodeId,
    });
  };

  return (
    <Accordion
      collapsible
      defaultValue={`group-${index}`}
      type="single"
      className="w-full overflow-y-auto"
    >
      <AccordionItem
        value={`group-${index}`}
        className="flex flex-col gap-3 border-none"
      >
        <div className="relative">
          <AccordionTrigger
            className="px-0 py-2 hover:no-underline"
            iconClassName="text-color-text-icon-primary z-[1]"
          />
          <Input
            dimension="small"
            className={cn(
              'w-80 rounded-md bg-transparent px-2',
              'absolute left-6 top-1/2 -translate-y-1/2 transform',
              'text-color-text-input text-sm',
              'border-transparent hover:border focus:border',
              'hover:border-color-border-input focus:border-color-border-input--focus'
            )}
            value={alertName}
            onChange={(event) => updateAlertName(event.target.value)}
            onBlur={() => updateAlert(index, { name: alertName })}
          />
          <Button
            variant="text"
            className={cn(
              'p-0',
              'absolute right-0 top-1/2 -translate-y-1/2 transform',
              'text-color-text-subtext hover:text-color-red'
            )}
            onClick={() => removeAlert(index)}
          >
            <Trash className="h-3 w-3" />
          </Button>
        </div>
        <AccordionContent>
          <div className="bg-color-bg-card-default flex flex-col gap-4 rounded-md border p-4">
            <div className="flex flex-col gap-2">
              <Label>Alert trigger</Label>
              <AlertFieldsDropdown
                value={field}
                onFieldChange={handleFieldChange}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Label>Assignee</Label>
              <Select
                value={assignedUser}
                options={userOptions}
                onValueChange={handleAssigneeChange}
                placeholder="Assign team members"
                // TODO: @Alex - enable after api supports user assignment
                disabled
              />
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
