import { v4 as uuid } from 'uuid';

import { type Field } from '@spektr/moonraker-types';

import { type Primitive } from '../types';

import { createConfig, createUiConfig } from './creators';
import { labelToFieldKey } from './labelToFieldKey';

export function createFieldFromPrimitive(field: Primitive): Field {
  switch (field.type) {
    case 'entity':
      return createEntityField(field);
    case 'input':
      return createInputField(field.id);
    case 'checkbox':
    case 'radio':
      return createCheckboxField(field);

    case 'file':
      return createFileField(field);

    case 'title':
      return createTitleField();
    case 'paragraph':
      return createParagraphField();
    case 'dropdown':
      return createDropdownField();

    case 'divider':
      return createDividerField();

    default:
      throw Error('Unknown field type.');
  }
}

function createEntityField(_field: Primitive): Field {
  return {
    id: uuid(),
    type: 'entity',
    config: createConfig('entity', true),
    ui: createUiConfig(),
    isStructured: true,
    validation: [],
    attributes: {
      label: 'Entity label',
      buttonText: 'Press to add an entity',
    },
    form: {
      fields: {},
      order: [],
    },
  };
}

function createInputField(type = 'text'): Field {
  const label = 'Input label';
  return {
    id: uuid(),
    type: 'input',
    config: createConfig(labelToFieldKey(label)),
    ui: createUiConfig(),
    isStructured: false,
    attributes: {
      type: type,
      label,
      placeholder: 'Input placeholder',
    },
    validation: [],
  };
}

function createCheckboxField(field: Primitive): Field {
  const label = field.type === 'checkbox' ? 'Checkbox label' : 'Radio label';
  return {
    id: uuid(),
    type: field.type === 'checkbox' ? 'checkbox' : 'radio',
    config: createConfig(labelToFieldKey(label)),
    ui: createUiConfig(),
    isStructured: false,
    attributes: {
      label: label,
      options: ['Option 1', 'Option 2', 'Option 3'],
    },
    validation: [],
  };
}

function createDropdownField(): Field {
  const label = 'Dropdown label';
  return {
    id: uuid(),
    type: 'select',
    config: createConfig(labelToFieldKey(label)),
    ui: createUiConfig(),
    isStructured: false,
    attributes: {
      label: label,
      placeholder: 'Select ...',
      helperText: 'Select an option from the dropdown',
      options: [
        {
          value: 'Option 1',
          label: 'Option 1',
        },
        {
          value: 'Option 2',
          label: 'Option 2',
        },
        {
          value: 'Option 3',
          label: 'Option 3',
        },
      ],
    },
    validation: [],
  };
}

function createFileField(_field: Primitive): Field {
  const label = 'File label';
  return {
    id: uuid(),
    type: 'file',
    config: createConfig(labelToFieldKey(label)),
    ui: createUiConfig(),
    isStructured: false,
    attributes: {
      label: label,
      placeholder: 'Select or upload image',
      fileType: '',
    },
    validation: [],
  };
}

function createTitleField(): Field {
  return {
    id: uuid(),
    type: 'title',
    ui: createUiConfig(),
    attributes: {
      content: 'Title',
    },
  };
}

function createParagraphField(): Field {
  return {
    id: uuid(),
    type: 'paragraph',
    ui: createUiConfig(),
    attributes: {
      content:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas egestas quam vitae felis aliquet, et blandit lorem hendrerit. Aliquam ultrices accumsan odio, quis tempor massa hendrerit ut.',
    },
  };
}

function createDividerField(): Field {
  return {
    id: uuid(),
    type: 'divider',
    ui: createUiConfig(),
  };
}
