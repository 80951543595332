import { Copy } from 'lucide-react';

import { IconButton, Input, Tooltip } from '@spektr/client/components';

import { type ResourceRecord } from '@spektr/shared/validators';

export namespace DnsRecordItem {
  export type Props = {
    lastCopiedValue?: string;
    record: ResourceRecord;
    onCopyToClipboard: (value: string) => void;
  };
}

export const DnsRecordItem = ({
  lastCopiedValue,
  record,
  onCopyToClipboard,
}: DnsRecordItem.Props) => {
  return (
    <>
      <div className="col-span-1 flex items-center text-xs font-semibold">
        {record.type}
      </div>
      <div className="col-span-2 text-xs font-semibold">
        <Input
          aria-label="Record name"
          disabled
          value={record.name}
          endIcon={
            <Tooltip
              content={
                lastCopiedValue === record.name ? 'Copied' : 'Copy to clipboard'
              }
              delayDuration={200}
            >
              <IconButton
                className="p-0"
                disabled={lastCopiedValue === record.name}
                variant="text"
                size="sm"
                onClick={() => onCopyToClipboard(record.name)}
              >
                <Copy className="h-3 w-3" />
              </IconButton>
            </Tooltip>
          }
        />
      </div>
      <div className="col-span-2 text-xs font-semibold">
        <Input
          aria-label="Record value"
          disabled
          value={record.value}
          endIcon={
            <Tooltip
              content={
                lastCopiedValue === record.value
                  ? 'Copied'
                  : 'Copy to clipboard'
              }
              delayDuration={200}
            >
              <IconButton
                className="p-0"
                disabled={lastCopiedValue === record.value}
                variant="text"
                size="sm"
                onClick={() => onCopyToClipboard(record.value)}
              >
                <Copy className="h-3 w-3" />
              </IconButton>
            </Tooltip>
          }
        />
      </div>
    </>
  );
};
