import { generatePath } from 'react-router-dom';
import { z } from 'zod';

export const CASES_URL = '/cases';

export const CASES_ALERTS = 'alerts';
export const CASES_ALERT = ':alertId';
export const CASES_ALERT_HIT = ':hitId';
export const CASES_COMMENTS = 'comments';
export const CASES_ALERT_CONFIG = 'config';
export const CASES_ALERT_ACTIVITY = 'activity';
export const CASES_CUSTOMERS = 'customers';
export const CASES_CUSTOMER_PARAM = ':spektrId';
export const CASES_CUSTOMER_DOCUMENTS = 'documents';
export const CASES_CUSTOMER_OWNERSHIP = 'ownership';
export const CASES_CUSTOMER_CONNECTIONS = 'connections';

export const CASES_ALERTS_FULL_URL = `${CASES_URL}/${CASES_ALERTS}`;
export const CASES_ALERT_FULL_URL = `${CASES_ALERTS_FULL_URL}/${CASES_ALERT}`;
export const CASES_ALERT_HIT_FULL_URL = `${CASES_ALERT_FULL_URL}/${CASES_ALERT_HIT}`;
export const CASES_ALERT_HIT_COMMENTS_FULL_URL = `${CASES_ALERT_HIT_FULL_URL}/${CASES_COMMENTS}`;
export const CASES_ALERT_CONFIG_FULL_URL = `${CASES_ALERT_FULL_URL}/${CASES_ALERT_CONFIG}`;
export const CASES_ALERT_ACTIVITY_FULL_URL = `${CASES_ALERT_FULL_URL}/${CASES_ALERT_ACTIVITY}`;

export const CASES_CUSTOMERS_FULL_URL = `${CASES_URL}/${CASES_CUSTOMERS}`;
export const CASES_CUSTOMER_DETAILS_FULL_URL = `${CASES_URL}/${CASES_CUSTOMERS}/${CASES_CUSTOMER_PARAM}`;
export const CASES_CUSTOMER_ALERTS_FULL_URL = `${CASES_CUSTOMER_DETAILS_FULL_URL}/${CASES_ALERTS}`;
export const CASES_CUSTOMER_ALERT_FULL_URL = `${CASES_CUSTOMER_ALERTS_FULL_URL}/${CASES_ALERT}`;
export const CASES_CUSTOMER_ALERT_ACTIVITY_FULL_URL = `${CASES_CUSTOMER_ALERT_FULL_URL}/${CASES_ALERT_ACTIVITY}`;
export const CASES_CUSTOMER_ALERT_HIT_FULL_URL = `${CASES_CUSTOMER_ALERT_FULL_URL}/${CASES_ALERT_HIT}`;
export const CASES_CUSTOMER_ALERT_HIT_COMMENTS_FULL_URL = `${CASES_CUSTOMER_ALERT_HIT_FULL_URL}/${CASES_COMMENTS}`;
export const CASES_CUSTOMER_DOCUMENTS_FULL_URL = `${CASES_CUSTOMER_DETAILS_FULL_URL}/${CASES_CUSTOMER_DOCUMENTS}`;
export const CASES_CUSTOMER_OWNERSHIP_FULL_URL = `${CASES_CUSTOMER_DETAILS_FULL_URL}/${CASES_CUSTOMER_OWNERSHIP}`;
export const CASES_CUSTOMER_CONNECTIONS_FULL_URL = `${CASES_CUSTOMER_DETAILS_FULL_URL}/${CASES_CUSTOMER_CONNECTIONS}`;
export const CASES_CUSTOMER_COMMENTS_FULL_URL = `${CASES_CUSTOMER_DETAILS_FULL_URL}/${CASES_COMMENTS}`;

export const casesAlertsParamsSchema = z.object({
  alertId: z.string(),
});

export const casesAlertHitsParamsSchema = z.object({
  alertId: z.string(),
  hitId: z.string(),
});

export const casesCustomerParamsSchema = z.object({
  spektrId: z.string(),
});

export const casesAlertUrl = (alertId: string) =>
  generatePath(CASES_ALERT_FULL_URL, {
    alertId,
  });

export const casesAlertActivityUrl = (alertId: string) =>
  generatePath(CASES_ALERT_ACTIVITY_FULL_URL, {
    alertId,
  });

export function casesCustomerDetailsUrl(spektrId: string) {
  return generatePath(CASES_CUSTOMER_DETAILS_FULL_URL, { spektrId });
}

export function casesCustomerAlertDetailsUrl(
  spektrId: string,
  alertId: string
) {
  return generatePath(CASES_CUSTOMER_ALERT_FULL_URL, { spektrId, alertId });
}

export function casesCustomerAlertActivityUrl(
  spektrId: string,
  alertId: string
) {
  return generatePath(CASES_CUSTOMER_ALERT_ACTIVITY_FULL_URL, {
    spektrId,
    alertId,
  });
}

export function casesCustomerAlertHitUrl(
  spektrId: string,
  alertId: string,
  hitId: string
) {
  return generatePath(CASES_CUSTOMER_ALERT_HIT_FULL_URL, {
    spektrId,
    alertId,
    hitId,
  });
}

export function casesCustomerAlertHitCommentsUrl(
  spektrId: string,
  alertId: string,
  hitId: string
) {
  return generatePath(CASES_CUSTOMER_ALERT_HIT_COMMENTS_FULL_URL, {
    spektrId,
    alertId,
    hitId,
  });
}

export function casesCustomerAlertsUrl(spektrId: string) {
  return generatePath(CASES_CUSTOMER_ALERTS_FULL_URL, { spektrId });
}

export function casesCustomerDocumentsUrl(spektrId: string) {
  return generatePath(CASES_CUSTOMER_DOCUMENTS_FULL_URL, { spektrId });
}

export function casesCustomerOwnershipUrl(spektrId: string) {
  return generatePath(CASES_CUSTOMER_OWNERSHIP_FULL_URL, { spektrId });
}

export function casesCustomerConnectionsUrl(spektrId: string) {
  return generatePath(CASES_CUSTOMER_CONNECTIONS_FULL_URL, { spektrId });
}

export function casesCustomerCommentsUrl(spektrId: string) {
  return generatePath(CASES_CUSTOMER_COMMENTS_FULL_URL, { spektrId });
}

export function casesAlertHitDetailsUrl(alertId: string, hitId: string) {
  return generatePath(CASES_ALERT_HIT_FULL_URL, {
    alertId,
    hitId,
  });
}

export function casesAlertHitCommentsUrl(alertId: string, hitId: string) {
  return generatePath(CASES_ALERT_HIT_COMMENTS_FULL_URL, {
    alertId,
    hitId,
  });
}
