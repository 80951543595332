import { createContext, useMemo, useState } from 'react';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { Process } from '@spektr/shared/types';
import { ProcessTrigger } from '@spektr/shared/validators';

import {
  getLiveProcessVersionByProcessId,
  getProcessByIdQuery,
  getProcessesQueryKey,
  getTriggersForProcessQuery,
  getTriggersForProcessQueryKey,
  ProcessApiClient,
} from '@spektr/client/services';
import { useFeatureFlags, useTriggersOperations } from '@spektr/platform-hooks';

type ProcessScheduleContextProps = {
  process: Process | null;
  triggers: ProcessTrigger[];
  startDate: number | undefined;
  setStartDate: (date: number) => void;
  updateProcessTrigger: () => void;
  canRunNow: boolean;
  hasLiveVersion: boolean;
};

export const ProcessScheduleContext =
  createContext<ProcessScheduleContextProps>({
    process: null,
    triggers: [],
    startDate: undefined,
    setStartDate: () => null,
    updateProcessTrigger: () => null,
    canRunNow: false,
    hasLiveVersion: false,
  });

type ProcessScheduleProviderProps = {
  processId: string;
  children: React.ReactNode;
};

const todayAtMidday = new Date().setUTCHours(12, 0, 0, 0);
const defaultStartDate = new Date(todayAtMidday).setUTCDate(
  new Date(todayAtMidday).getDate() + 1
);

export const ProcessScheduleProvider = ({
  processId,
  children,
}: ProcessScheduleProviderProps) => {
  const queryClient = useQueryClient();
  const { processTriggers } = useFeatureFlags();

  const { data: process } = useSuspenseQuery(getProcessByIdQuery(processId));
  const { data: triggers } = useSuspenseQuery(
    getTriggersForProcessQuery(processId)
  );
  const { data: liveVersion } = useSuspenseQuery(
    getLiveProcessVersionByProcessId(processId)
  );
  const { updateTrigger } = useTriggersOperations();

  const [startDate, setStartDate] = useState<number | undefined>(
    process.startDate || defaultStartDate
  );

  const handleSetStartDate = (startDate: number) => {
    updateProcessStartDate.mutateAsync(startDate);
    setStartDate(startDate);
  };

  const updateProcessTrigger = async () => {
    const trigger = triggers[0];

    if (!trigger) {
      return;
    }

    await updateTrigger({
      triggerId: trigger.id,
      frequency: trigger?.frequency || 'monthly',
      startDate: startDate || setStartDateTime(Date.now()),
      interval: trigger?.interval || 1,
    });
    await queryClient.invalidateQueries({
      queryKey: getTriggersForProcessQueryKey(processId),
    });
  };

  const updateProcessStartDate = useMutation({
    mutationFn: (startDate?: number) =>
      ProcessApiClient.getClient().updateProcessById(
        {
          startDate,
        },
        {
          params: {
            id: processId,
          },
        }
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: getProcessesQueryKey() });
    },
  });

  const canRunNow = useMemo(() => {
    if (!process) {
      return false;
    }

    if (!['risk', 'monitoring'].includes(process.type)) {
      return false;
    }

    if (process.type === 'monitoring' && !processTriggers) {
      return false;
    }

    return true;
  }, [process, processTriggers]);

  const setStartDateTime = (startDate: number) => {
    const dateAtMidday = new Date(startDate).setUTCHours(12, 0, 0, 0);
    return new Date(dateAtMidday).setUTCDate(
      new Date(dateAtMidday).getDate() + 1
    );
  };

  return (
    <ProcessScheduleContext.Provider
      value={{
        process,
        triggers,
        startDate,
        setStartDate: handleSetStartDate,
        updateProcessTrigger,
        canRunNow,
        hasLiveVersion: !!liveVersion.id,
      }}
    >
      {children}
    </ProcessScheduleContext.Provider>
  );
};
