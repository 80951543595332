import { CheckIcon } from 'lucide-react';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import { Item, ItemIndicator, ItemText } from '@radix-ui/react-select';

import { cn } from '@spektr/style-utils';

export type SelectItemProps = ComponentPropsWithoutRef<typeof Item>;

export const SelectItem = forwardRef<ElementRef<typeof Item>, SelectItemProps>(
  ({ className, children, ...props }, ref) => (
    <Item
      ref={ref}
      className={cn(
        'relative flex w-full items-center py-1.5 pl-2 pr-8',
        'rounded-sm',
        'text-sm',
        'cursor-pointer select-none outline-none',
        'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        'focus:bg-color-bg-select-item--focus focus:text-color-text-select-item--focus',
        className
      )}
      {...props}
    >
      <span className="absolute right-2 flex h-3.5 w-3.5 items-center justify-center">
        <ItemIndicator>
          <CheckIcon className="h-4 w-4" />
        </ItemIndicator>
      </span>
      <ItemText>{children}</ItemText>
    </Item>
  )
);
