import { useLocation } from 'react-router-dom';

import { createNodeFactory } from '@spektr/client/utils';

import { useCreateNode } from '@spektr/shared/hooks';

import { DrawerItemType } from '../types/DrawerItemType';

export function useCreateNodeFactory(
  processId: string,
  onSuccess: (response: any) => void
) {
  const location = useLocation();
  const createNode = useCreateNode(processId);

  const handleClick = (item: DrawerItemType) => {
    const bodyInput = createNodeFactory(
      item.type,
      {
        predecessorId: location.state?.predecessorNode?.id,
        successorId: location.state?.successorNode?.id,
        edgeType: location.state?.edgeType,
      },
      item.metadata
    );

    createNode
      .mutateAsync(bodyInput)
      .then((response) => {
        onSuccess(response.id);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return handleClick;
}
