import { Goal, RefreshCw, SearchIcon, UserRoundCheck } from 'lucide-react';

import { ProcessType } from '@spektr/shared/validators';

import { CalculatorIcon } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

type ProcessIconProps = {
  processType: ProcessType;
  className?: string;
};

export const ProcessIcon = ({ processType, className }: ProcessIconProps) => {
  const iconClassName = cn('h-3.5 w-3.5 shrink-0', className);

  switch (processType) {
    case 'risk':
      return (
        <CalculatorIcon
          className={cn('stroke-color-cyan stroke-1.8', iconClassName)}
        />
      );
    case 'monitoring':
      return (
        <SearchIcon className={cn('stroke-color-yellow', iconClassName)} />
      );
    case 'onboarding':
      return (
        <UserRoundCheck className={cn('stroke-color-blue', iconClassName)} />
      );
    case 'score':
      return <Goal className={cn('stroke-color-blue', iconClassName)} />;
    case 'loop':
      return <RefreshCw className={cn('stroke-color-red', iconClassName)} />;
    default:
      return null;
  }
};
