import { ChevronDown, Trash } from 'lucide-react';

import { Label, Skeleton } from '@spektr/client/components';

export const SkeletonServiceAlertList = () => {
  return (
    <div className="flex flex-col gap-6">
      <div className="bg-color-bg-card-default flex flex-col gap-4 rounded-md border p-4">
        <div className="flex items-center gap-3">
          <ChevronDown className="text-color-text-icon-primary h-4 w-4" />
          <Skeleton className="h-5 w-40" />
          <Trash className="text-color-text-subtext ml-auto h-3 w-3" />
        </div>
        <div className="flex flex-col gap-2">
          <Label>Alert trigger</Label>
          <Skeleton className="h-9 w-full" />
        </div>
        <div className="flex flex-col gap-2">
          <Label>Assignee</Label>
          <Skeleton className="h-9 w-full" />
        </div>
      </div>
    </div>
  );
};
