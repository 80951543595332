import { useTranslation } from 'react-i18next';
import { differenceInDays, differenceInHours, isToday } from 'date-fns';
import { SquareCheckBig } from 'lucide-react';

import { cn } from '@spektr/style-utils';
import { ALERT_AGE_THRESHOLD } from '@spektr/client/constants';

type AlertAgeProps = {
  createdAt: number;
  isResolved: boolean;
};

export const AlertAge = ({ createdAt, isResolved }: AlertAgeProps) => {
  const { t } = useTranslation('common');
  const createdAtDate = new Date(createdAt);
  let age = 0;

  if (!isToday(createdAtDate)) {
    if (differenceInHours(new Date(), createdAtDate) > 24) {
      age = differenceInDays(new Date(), createdAtDate);
    } else {
      age = 1;
    }
  }

  const message =
    age === 0 ? t('timestamps.today') : t('timestamps.days', { count: age });

  return (
    <div className="flex items-center justify-center">
      {isResolved ? (
        <SquareCheckBig className="stroke-color-text-subtext h-5 w-5" />
      ) : (
        <p
          className={cn(
            'text-sm font-medium',
            'text-color-cyan',
            age > ALERT_AGE_THRESHOLD.WARNING && 'text-color-yellow',
            age > ALERT_AGE_THRESHOLD.DANGER && 'text-color-red'
          )}
        >
          {message}
        </p>
      )}
    </div>
  );
};
