import { formatDistanceToNow } from 'date-fns';
import { Search } from 'lucide-react';

import { IconWithBackground } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

export type TagTypeProps = {
  createdAt: number;
  updatedAt?: number;
  sourceProcessId?: string;
  sourceProcessName?: string;
  className?: string;
};

export const TagInfo = ({
  createdAt,
  updatedAt,
  sourceProcessName,
  className,
}: TagTypeProps) => {
  return (
    <div className={cn('flex flex-col gap-2', className)}>
      <p className="text-color-text-subtext text-sm font-medium">
        {formatDistanceToNow(updatedAt ?? createdAt, { addSuffix: true })}
      </p>
      <div className="flex items-center gap-2.5">
        <IconWithBackground className="h-5 w-5">
          <Search className="h-2.5 w-2.5" />
        </IconWithBackground>
        <p className="text-sm font-medium">{sourceProcessName}</p>
      </div>
    </div>
  );
};
