import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Plus } from 'lucide-react';

import { SectionWithTitle } from '@spektr/model-builder/components';

import { getTeamMembersQuery } from '@spektr/client/services';

import { Button } from '@spektr/client/components';

import { useMonitorsContext } from '../../providers/Monitors';

import { FieldMonitor } from '../../components/FieldMonitor';

type FieldsMonitoringProps = {
  serviceName: string;
};

export const FieldsMonitoring = ({ serviceName }: FieldsMonitoringProps) => {
  const {
    monitors,
    spektrFields,
    serviceFields,
    createMonitor,
    updateMonitor,
    updateDecision,
    removeMonitor,
  } = useMonitorsContext();
  const { data: users } = useSuspenseQuery(getTeamMembersQuery());
  const { t } = useTranslation('process');

  const userOptions = useMemo(
    () =>
      users.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })) ?? [],
    [users]
  );

  return (
    <SectionWithTitle title={t('fieldsMonitoring.sectionTitle')}>
      {monitors.length > 0 ? (
        monitors.map((monitor, index) => (
          <FieldMonitor
            key={`${monitor.rule.id}-${index}`}
            monitor={monitor}
            serviceName={serviceName}
            serviceFields={serviceFields}
            spektrFields={spektrFields}
            userOptions={userOptions}
            onUpdate={updateMonitor}
            onUpdateDecision={updateDecision}
            onRemove={() => removeMonitor(monitor.rule.id ?? '')}
          />
        ))
      ) : (
        <div className="text-color-text-badge rounded-md border p-4 text-center text-sm">
          {t('fieldsMonitoring.emptyStateMessage', { service: serviceName })}
        </div>
      )}
      <Button
        variant="text"
        fullWidth
        className="flex items-center gap-4"
        onClick={createMonitor}
      >
        <Plus className="h-4 w-4" />
        {t(
          `fieldsMonitoring.${monitors.length > 0 ? 'addAnotherField' : 'addField'}`
        )}
      </Button>
    </SectionWithTitle>
  );
};
