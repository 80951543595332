import { Suspense } from 'react';

import { cn } from '@spektr/style-utils';

import { ContentSection, SpektrErrorBoundary } from '@spektr/client/components';
import { handleAxiosError } from '@spektr/client/utils';

import { DomainForm } from './containers/DomainForm';

import { DomainSkeleton } from './components/DomainSkeleton';
import { DomainApiError } from './components/DomainApiError';

export const CustomDomain = () => {
  return (
    <ContentSection
      title="Custom domain"
      description="Set up your custom domains"
    >
      <SpektrErrorBoundary
        fallbackRender={({ error }) => (
          <div
            className={cn(
              'flex w-full flex-col',
              'max-w-[610px]',
              'rounded-md border'
            )}
          >
            <DomainApiError
              errorMsg={handleAxiosError(error, 'No domain alias found')}
            />
          </div>
        )}
      >
        <Suspense
          fallback={
            <div
              className={cn(
                'flex w-full flex-col',
                'max-w-[610px]',
                'rounded-md border'
              )}
            >
              <DomainSkeleton />
            </div>
          }
        >
          <DomainForm />
        </Suspense>
      </SpektrErrorBoundary>
    </ContentSection>
  );
};
