import { cn } from '@spektr/style-utils';

import type { FileField } from '@spektr/moonraker-types';

export type FileContentProps = {
  field: FileField;
};

export const FileContent = ({ field }: FileContentProps) => {
  return (
    <div className="flex w-full flex-col gap-2">
      <div
        className={cn(
          'flex items-center justify-center',
          'min-h-9 w-full p-3',
          'rounded-md border border-dashed',
          'text-color-text-subtext text-xs font-medium'
        )}
      >
        {field.attributes.placeholder}
      </div>
      <span
        className={cn(
          'h-0',
          'text-color-text-subtext',
          'text-xs font-medium',
          'transition-all',
          { 'h-4': !!field.attributes?.helperText }
        )}
      >
        {field.attributes?.helperText}
      </span>
    </div>
  );
};
