import { memo, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Handle, Position } from 'reactflow';

import {
  getRootNode,
  hasAdditionalBranches,
  loopNodeDetails,
  loopSheetUrl,
  processBuilderSheetUrl,
  processBuilderUrl,
} from '@spektr/shared/utils';
import { RBAC } from '@spektr/shared/rbac';
import { useDeleteNode } from '@spektr/shared/hooks';

import { AlertDialog } from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';
import { cn } from '@spektr/style-utils';

import {
  ProcessNode,
  isFormNodeType,
  isAlertNodeType,
  isCalculationNodeType,
  isReturningProcessNodeType,
  isMonitoringDatasetNodeType,
  isRouterNodeType,
  isServiceNodeType,
  isSlackNodeType,
  isOnboardingProcessSourceNodeType,
  isManualReviewNodeType,
  isAiAmlAlert,
  isCustomerStatusNodeType,
} from '@spektr/shared/types';

import { NewFallbackButton } from './NewFallbackButton';

import { RouterNode } from './RouterNode';
import { CalculationNode } from './CalculationNode';
import { ServiceNode } from './ServiceNode';
import { FormActionNode } from './FormActionNode';

import { GraphNodeProps } from './types';
import { NODE } from './constants';

import { SlackNode } from './SlackNode';
import { NodeDeleteButton } from './NodeDeleteButton';
import { AlertNode } from './AlertNode';
import { ProcessSettingsNode } from './ProcessSettings';
import { ReturningProcessNode } from './ReturningProcessNode';
import { MonitoringDatasetNode } from './MonitoringDatasetNode';
import { ManualReviewNode } from './ManualReviewNode';
import { AiAmlAlertNode } from './AiAmlAlertNode';
import { AdditionalBranches } from './AdditionalBranches';
import { SetCustomerStatusNode } from './SetCustomerStatusNode';

type BasicNodeProps = GraphNodeProps<ProcessNode>;

export const BasicNode = memo(({ data }: BasicNodeProps) => {
  const { node, process } = data;
  const navigate = useNavigate();
  const deleteNode = useDeleteNode(process.id, node.id);
  const [pendingDelete, setPendingDelete] = useState(false);
  const { hasPermission } = usePermissionsContext();
  const canDeleteNode = hasPermission(RBAC.ACTIONS.NODE.DELETE);

  const handleDeleteNode = async () => {
    await deleteNode.mutateAsync();
    setPendingDelete(false);
  };

  const handleAddFallbackNode = () => {
    const href =
      data.process.type === 'loop'
        ? loopSheetUrl(data.process.id)
        : processBuilderSheetUrl(data.process.id);

    // Adding a new node as a fallback always follows this pattern:
    // before:
    // A → B → C
    // after adding B1 as fallback of B:
    // A → B → C
    //     ↓   ↑
    //    B1 → ↑
    // thus B's successor is still C. process-api will also set C as B1's successor automatically.
    navigate(href, {
      state: {
        predecessorNode: data.node,
        edgeType: 'fallback',
      },
    });
  };

  const isRootNode = getRootNode(process)?.id === node.id;

  const nodeContent = useMemo(() => {
    if (isRouterNodeType(node)) {
      return <RouterNode title={node.title} />;
    }

    if (isSlackNodeType(node)) {
      return <SlackNode title={node.title} />;
    }

    if (isCalculationNodeType(node)) {
      return <CalculationNode title={node.title} />;
    }

    if (isReturningProcessNodeType(node)) {
      return <ReturningProcessNode title={node.title} />;
    }

    if (isCustomerStatusNodeType(node)) {
      return <SetCustomerStatusNode title={node.title} fields={node.fields} />;
    }

    if (isMonitoringDatasetNodeType(node)) {
      return <MonitoringDatasetNode title={node.title} />;
    }

    if (isOnboardingProcessSourceNodeType(node)) {
      return <ProcessSettingsNode />;
    }

    if (isServiceNodeType(node)) {
      return <ServiceNode serviceType={node.nodeType} />;
    }

    if (isFormNodeType(node)) {
      return <FormActionNode nodeTitle={node.title} />;
    }

    if (isAlertNodeType(node)) {
      return <AlertNode title={node.title} />;
    }

    if (isManualReviewNodeType(node)) {
      return <ManualReviewNode title={node.title} />;
    }

    if (isAiAmlAlert(node)) {
      return <AiAmlAlertNode title={node.title} />;
    }

    return null;
  }, [node]);

  const href =
    data.process.type === 'loop'
      ? loopNodeDetails(process.id, node.id)
      : processBuilderUrl(process.id, node.id);

  const nodeHasAdditionalBranches = hasAdditionalBranches(
    process.type,
    node.nodeType,
    node.adj
  );

  return (
    <>
      <div
        className={cn(
          'group',
          'relative',
          'flex min-h-0 flex-col gap-4 rounded-lg',
          'cursor-pointer duration-200 hover:shadow-lg',
          !isAiAmlAlert(data.node) &&
            'bg-color-bg-process-builder-item hover:border-color-blue',
          !isAiAmlAlert(data.node) && 'border p-3',
          nodeHasAdditionalBranches && 'rounded-b-none'
        )}
        style={{
          height: NODE.HEIGHT,
          width: NODE.WIDTH,
        }}
        data-cy={`${node.nodeType}-node`}
      >
        <Handle
          type="target"
          id={node.id}
          position={Position.Left}
          className={cn(
            isRootNode
              ? 'opacity-0'
              : cn(
                  'h-2 w-2',
                  'border-color-border-process-builder-item border',
                  'bg-color-bg-process-builder-item'
                )
          )}
        />

        <Link
          to={href}
          className="flex h-full w-full flex-row items-center gap-3"
          onClick={(e) => {
            if (isCustomerStatusNodeType(node)) {
              e.preventDefault(); // customer status nodes are not clickable
            }
          }}
        >
          {nodeContent}
        </Link>

        {nodeHasAdditionalBranches && (
          <AdditionalBranches
            processId={process.id}
            processType={process.type}
            node={node}
          />
        )}

        <NodeDeleteButton
          disabled={!canDeleteNode}
          onClick={canDeleteNode ? () => setPendingDelete(true) : undefined}
        />
        <NewFallbackButton
          className={cn(nodeHasAdditionalBranches && '-bottom-8')}
          onClick={handleAddFallbackNode}
        />

        <Handle
          type="source"
          id={node.id}
          position={Position.Right}
          className={cn(
            'h-2 w-2',
            'border-color-border-process-builder-item border',
            'bg-color-bg-process-builder-item'
          )}
        />
      </div>
      <AlertDialog
        open={!!pendingDelete}
        title="Are you sure?"
        paragraph="You will permanently delete this step from the process."
        onCancel={() => setPendingDelete(false)}
        cancel="Cancel"
        onConfirm={handleDeleteNode}
        confirm="Yes, delete"
        confirmDataCy="Yes-delete"
        disableButtons={deleteNode.isPending}
      />
    </>
  );
});
