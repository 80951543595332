import { format } from 'date-fns';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  HoverCard,
  ProcessIcon,
  Status,
  TableCell,
  TableRow,
} from '@spektr/client/components';
import { FULL_DATE_TIME_FORMAT } from '@spektr/client/constants';
import {
  getStatusColor,
  getStatusText,
  isExecutionFinished,
  isExecutionSuccessful,
} from '@spektr/client/utils';
import { Process } from '@spektr/shared/types';
import { casesCustomerDetailsUrl, mapToArray } from '@spektr/shared/utils';
import { ExecutionContextWithDatasetSchema } from '@spektr/shared/validators';
import { cn } from '@spektr/style-utils';

const baseRowCellClasses = 'px-4 border-b';

export type ExecutionsTableRowProps = {
  row: ExecutionContextWithDatasetSchema;
  isFirstRow: boolean;
  isLastRow: boolean;
  currentProcess?: Process;
};

export const ExecutionsTableRow = ({
  row,
  isFirstRow,
  isLastRow,
  currentProcess,
}: ExecutionsTableRowProps) => {
  const navigate = useNavigate();

  const handleClickRow = useCallback(
    (id: string) => {
      navigate(casesCustomerDetailsUrl(id));
    },
    [navigate]
  );

  const getSplitScoreList = (
    row: ExecutionContextWithDatasetSchema,
    currentProcess: Process
  ) => {
    const scoreNodesList = row.path.filter((node) => node.data?.score);
    if (scoreNodesList.length === 0 || !currentProcess) {
      return [];
    }

    const scoreList: [string, number][] = [];
    const nodes = mapToArray(currentProcess.nodes);
    scoreNodesList.forEach((scoreNode, index) => {
      const title =
        nodes[index] && 'title' in nodes[index] ? nodes[index].title : '';

      const score =
        index !== 0
          ? +(scoreNode.data?.score || 0) -
            +(scoreNodesList[index - 1].data?.score || 0)
          : +(scoreNode.data?.score || 0);

      if (title) {
        scoreList.push([title, score]);
      }
    });

    return scoreList;
  };

  const name = row.dataset.fieldId
    ? (row.data[row.dataset.fieldId]?.toString() ?? '')
    : 'Unknown';

  const scoreList = getSplitScoreList(row, currentProcess as Process);

  return (
    <TableRow
      className="hover:bg-color-bg-table-row--hover cursor-pointer border-b"
      onClick={() => handleClickRow(row.spektrId)}
    >
      <TableCell
        className={cn(baseRowCellClasses, 'border-x', {
          'rounded-bl-md': isLastRow,
          'w-72': isFirstRow,
        })}
      >
        <div className="flex items-center gap-3">
          <Avatar firstName={name} lastName="" />
          <div>
            <p className="text-color-text-primary max-w-56 truncate text-sm">
              {name}
            </p>
            <p className="text-color-text-subtext text-xs">
              Part of{' '}
              <span className="text-color-text-primary">
                {row.dataset.name}
              </span>
            </p>
          </div>
        </div>
      </TableCell>
      <TableCell className={baseRowCellClasses}>
        <div className="flex items-center gap-3">
          <ProcessIcon processType={currentProcess?.type} />
          <p className="text-color-text-primary text-sm font-medium">
            {row.process.name}
          </p>
        </div>
      </TableCell>
      <TableCell className={baseRowCellClasses}>
        <p className="text-color-text-subtext text-xs font-medium">
          {format(row.createdAt, FULL_DATE_TIME_FORMAT)}
        </p>
      </TableCell>
      <TableCell className={baseRowCellClasses}>
        {isExecutionFinished(row) ? (
          <p className="text-color-text-subtext text-xs font-medium">
            {format(row.updatedAt, FULL_DATE_TIME_FORMAT)}
          </p>
        ) : undefined}
      </TableCell>
      <TableCell className={baseRowCellClasses}>
        <div
          className={cn(
            'color-text-primary text-xs font-medium',
            scoreList.length > 0 && 'cursor-pointer'
          )}
        >
          {scoreList.length === 0 && <span>{row.data.score}</span>}
          {scoreList.length > 0 && (
            <HoverCard
              className="flex flex-col gap-1 p-2"
              side="right"
              trigger={<span>{row.data.score}</span>}
            >
              {scoreList.map((scoreNode) => (
                <div className="flex gap-1" key={scoreNode[0]}>
                  <p>{scoreNode[0] + ': '}</p>
                  <p>{scoreNode[1]}</p>
                </div>
              ))}
            </HoverCard>
          )}
        </div>
      </TableCell>
      <TableCell className={baseRowCellClasses}>
        {isExecutionSuccessful(row) ? (
          <p className="text-color-text-primary text-xs font-medium">
            {row.process.output === 'unknown' ? 'Finished' : row.process.output}
          </p>
        ) : undefined}
      </TableCell>
      <TableCell
        className={cn(baseRowCellClasses, 'border-r', {
          'rounded-br-md': isLastRow,
        })}
      >
        <Status color={getStatusColor(row)} label={getStatusText(row)} />
      </TableCell>
    </TableRow>
  );
};
