import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';

i18next
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    lng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    ns: ['common', 'fields', 'cases', 'vendors', 'process', 'moonraker-form'],
    defaultNS: 'common',
    fallbackLng: ['en'],
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend(
          (language: string, namespace: string) =>
            import(`../../assets/locales/${language}/${namespace}.json`)
        ),
      ],
      backendOptions: [
        {
          loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
  });
