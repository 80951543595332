import { Label, Skeleton } from '@spektr/client/components';

import { ContentHeader } from '../ContentHeader';

export const DomainSkeleton = () => {
  return (
    <div className="flex flex-col gap-4 p-6">
      <ContentHeader
        title="Use my own custom domain for forms"
        subtitle="You can set up your own domain below"
      />
      <div className="flex items-end gap-3">
        <div className="flex flex-col gap-2.5">
          <Label htmlFor="input-domain">Custom domain name</Label>
          <Skeleton className="h-8 w-[380px]" />
        </div>
        <Skeleton className="h-8 w-[112px]" />
      </div>
    </div>
  );
};
