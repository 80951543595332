import { forwardRef, ReactNode } from 'react';

import { Spinner } from '../Spinner';

import { Button, ButtonProps } from './Button';

export namespace AsyncButton {
  export type Props = Omit<ButtonProps, 'children'> & {
    isPending?: boolean;
    pendingLabel?: ReactNode;
    label?: ReactNode;
  };
}

export const AsyncButton = forwardRef<HTMLButtonElement, AsyncButton.Props>(
  (
    { isPending = false, pendingLabel = 'Saving...', label = 'Save', ...props },
    ref
  ) => (
    <Button {...props} ref={ref} disabled={props.disabled || isPending}>
      {isPending ? (
        <div className="flex items-center gap-2">
          <Spinner className="h-4 w-4" />
          {pendingLabel}
        </div>
      ) : (
        label
      )}
    </Button>
  )
);
