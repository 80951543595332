import type { FieldConfig, FieldUiConfig } from '@spektr/moonraker-types';

export function createConfig(
  spektrDataField = '',
  prefill = false
): FieldConfig {
  return {
    spektrDataField,
    prefillDefaultValue: prefill,
  };
}

export function createUiConfig(): FieldUiConfig {
  return {
    style: {
      width: '100%',
    },
  };
}
