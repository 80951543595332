import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { getAlertById, getCustomerDetails } from '@spektr/client/services';
import {
  AlertBaseWithHits,
  ClientRecordDetails,
} from '@spektr/shared/validators';

type AlertDetailsProviderProps = {
  alertId: string;
  context: 'global' | 'customer';
  children: React.ReactNode;
};

type AlertDetailsContextType = {
  alert: AlertBaseWithHits | null;
  selectedTasks: string[];
  customerName: string;
  handleTaskSelection: (taskId: string) => void;
  getUpdatedTasks: () => AlertBaseWithHits['tasks'];
};

export const AlertDetailsContext = createContext<AlertDetailsContextType>({
  alert: null,
  selectedTasks: [],
  customerName: '',
  handleTaskSelection: () => null,
  getUpdatedTasks: () => [],
});

export const useAlertDetails = () => {
  return useContext(AlertDetailsContext);
};

export const AlertDetailsProvider = ({
  alertId,
  context,
  children,
}: AlertDetailsProviderProps) => {
  const { data: alert } = useSuspenseQuery({
    ...getAlertById(alertId, context),
    refetchInterval: (alert) =>
      // long-poll for the alert as long as any of the hits are pending spektr-ai
      alert.state.data?.hits.some(
        (hit) => hit.spektrAIDetails?.status === 'pending'
      )
        ? 2000
        : false,
  });
  const { data: customer } = useQuery({
    ...getCustomerDetails(alert.spektrId),
    placeholderData: {
      name: '',
    } as ClientRecordDetails,
    meta: { skipGlobalErrorHandling: true },
  });

  const defaultSelectedTasks =
    alert?.tasks?.filter((task) => task.isCompleted).map((task) => task.id) ??
    [];
  const [selectedTasks, setSelectedTasks] =
    useState<string[]>(defaultSelectedTasks);

  const handleTaskSelection = (taskId: string) => {
    setSelectedTasks((prev) => {
      if (prev.includes(taskId)) {
        return prev.filter((id) => id !== taskId);
      }
      return [...prev, taskId];
    });
  };

  const getUpdatedTasks = useCallback(() => {
    return (
      alert?.tasks?.map((task) => ({
        ...task,
        isCompleted: task.isCompleted || selectedTasks.includes(task.id),
      })) ?? []
    );
  }, [alert, selectedTasks]);

  const value = useMemo(
    () => ({
      alert,
      selectedTasks,
      getUpdatedTasks,
      handleTaskSelection,
      customerName: customer?.name ?? 'Deleted',
    }),
    [alert, selectedTasks, customer, getUpdatedTasks]
  );

  return (
    <AlertDetailsContext.Provider value={value}>
      {children}
    </AlertDetailsContext.Provider>
  );
};
