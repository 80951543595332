import { useRef } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { getAllowedSpektrFieldsQuery } from '@spektr/client/services';

import { toast, ScrollArea } from '@spektr/client/components';
import {
  parseRouter,
  RouterNodeFormDialogContent,
  RouterNodeProvider,
} from '@spektr/shared/components';

import { updateRouterNodeSchema } from '@spektr/shared/validators';

import { type RouterNode, type NodeUpdateInput } from '@spektr/shared/types';

import { DialogEditableTitle } from '../../components/DialogEditableTitle';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { withDialogWrapper } from '../WithDialogWrapper';

import { RouterFooter } from './components/RouterFooter';
import { RouterSkeleton } from './components/RouterSkeleton';

export namespace RouterContent {
  export type Props = BaseDialogProps<RouterNode>;
}

const RouterContent = ({
  isPendingUpdate,
  node,
  nodeId,
  processId,
  onUpdate,
}: RouterContent.Props) => {
  const { data: allowedSpektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, nodeId)
  );

  const titleRef = useRef<HTMLInputElement>(null);

  const handleInputBlur = (title: string) => {
    onUpdate({
      nodeType: node.nodeType,
      groups: node.groups,
      title,
    });
  };

  const handleSave = async (data: NodeUpdateInput) => {
    const parsedData = updateRouterNodeSchema.parse(data);

    await onUpdate({
      ...parsedData,
      title: titleRef?.current?.value ?? node.title,
      nodeType: node.nodeType,
    });

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });
  };

  return (
    <RouterNodeProvider initial={parseRouter(node)}>
      <DialogEditableTitle
        titleRef={titleRef}
        initialTitle={node.title}
        onBlur={handleInputBlur}
      />
      <ScrollArea className="flex max-h-[70vh] flex-col overflow-y-auto px-2">
        <RouterNodeFormDialogContent spektrFields={allowedSpektrFields ?? []} />
      </ScrollArea>
      <RouterFooter isPendingUpdate={isPendingUpdate} onUpdate={handleSave} />
    </RouterNodeProvider>
  );
};

export const RouterDialog = withDialogWrapper(
  RouterContent,
  <RouterSkeleton />
);
