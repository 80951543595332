import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ServerCrash } from 'lucide-react';

import {
  ProcessApiClient,
  getRiskMatricesQueryKey,
} from '@spektr/client/services';
import { SpektrErrorBoundary } from '@spektr/client/components';

import {
  MATRIX_DASHBOARD_FULL_URL,
  matrixDetailsParamsSchema,
} from '@spektr/shared/utils';
import { RiskMatrixUpdate } from '@spektr/shared/validators';
import { useParsedParams } from '@spektr/shared/hooks';

import { RiskMatrixDialog, RiskMatrixTableSkeleton } from '../../components';
import { RiskMatrixData } from '../../../RiskMatrix';

import { UpdateRiskMatrixContainer } from './UpdateRiskMatrixContainer';

export const UpdateRiskMatrixDialog = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { matrixId } = useParsedParams(matrixDetailsParamsSchema);

  const handleClose = () => navigate(MATRIX_DASHBOARD_FULL_URL);

  const updateRiskMatrix = useMutation({
    mutationFn: (riskMatrix: RiskMatrixUpdate) =>
      ProcessApiClient.getClient().updateRiskMatrixById(riskMatrix, {
        params: {
          id: matrixId,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getRiskMatricesQueryKey(),
      });
    },
  });

  const handleUpdate = async (name: string, data: RiskMatrixData) => {
    await updateRiskMatrix.mutateAsync({
      title: name,
      columns: [...data.headers, 'Score'],
      rows: data.values.map((row, index) => [...row, data.scores[index]]),
    });
    handleClose();
  };

  return (
    <RiskMatrixDialog isUpdate={true} onClose={handleClose}>
      <SpektrErrorBoundary
        fallbackRender={() => (
          <div className="flex h-[400px] flex-col items-center justify-center gap-2 p-8">
            <ServerCrash className="stroke-color-red h-8 w-8" />
            <span className="text-color-text-error-boundry">
              There was an error while loading the matrix.
            </span>
          </div>
        )}
      >
        <Suspense fallback={<RiskMatrixTableSkeleton />}>
          <UpdateRiskMatrixContainer
            id={matrixId}
            handleUpdate={handleUpdate}
            isUpdating={updateRiskMatrix.isPending}
          />
        </Suspense>
      </SpektrErrorBoundary>
    </RiskMatrixDialog>
  );
};
