import { Suspense } from 'react';

import { ErrorPage } from '@spektr/shared/components';
import { SpektrErrorBoundary, Spinner } from '@spektr/client/components';
import { handleAxiosError } from '@spektr/client/utils';

import { LoopDetails } from './LoopDetails';

export const LoopDetailsPage = () => {
  return (
    <SpektrErrorBoundary
      fallbackRender={(error) => (
        <ErrorPage
          title={handleAxiosError(
            error,
            'Something went wrong when loading the loop details.'
          )}
        />
      )}
    >
      <Suspense
        fallback={
          <div className="flex h-full w-full flex-col justify-between">
            <div className="relative flex flex-grow items-center justify-center">
              <Spinner className="h-8 w-8 opacity-60" />
            </div>
          </div>
        }
      >
        <LoopDetails />
      </Suspense>
    </SpektrErrorBoundary>
  );
};
