import { queryOptions } from '@tanstack/react-query';

import { UsersApiClient } from '../client';
import {
  getSpektrAiUserQueryKey,
  getTeamMembersQueryKey,
  USERS_KEYS,
} from '../queryKeys';

export function getTeamMembersQuery(customAttr?: string[]) {
  return queryOptions({
    queryKey: getTeamMembersQueryKey(customAttr),
    queryFn: () =>
      UsersApiClient.getClient().getUsers({
        queries: {
          customAttr: customAttr ? customAttr.join(',') : undefined,
        },
      }),
  });
}

export function getDefaultBrandingStyle() {
  return queryOptions({
    queryKey: USERS_KEYS.BRANDING_THEME(),
    queryFn: () => UsersApiClient.getClient().getBrandingFormsDefault(),
  });
}

export function getSpektrAiUserQuery() {
  return queryOptions({
    queryKey: getSpektrAiUserQueryKey(),
    queryFn: () => UsersApiClient.getClient().getSpektrAiUser(),
  });
}
