import { getAppEnv } from '@spektr/shared/utils';

/**
 * @description
 *    In development, it returns the URL of the development environment.
 *  Otherwise it returns the origin of the current window.
 *
 * @returns The public static URL of the application.
 */
export function getPublicStaticUrl() {
  const env = getAppEnv();

  if (env === 'development') {
    return 'https://platform.dev.inspektr.space/';
  }

  return window.location.origin;
}
