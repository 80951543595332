import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { LOOPS_URL, loopDetailsUrl } from '@spektr/shared/utils';
import { RBAC } from '@spektr/shared/rbac';

import {
  ProcessApiClient,
  getProcessesQuery,
  getProcessesQueryKey,
} from '@spektr/client/services';
import { usePermissionsContext } from '@spektr/client/providers';
import { BasicDialog, Button } from '@spektr/client/components';

import { ProcessOutcomes } from '@spektr/model-builder/components';

import { ProcessSource } from '@spektr/shared/types';

import { LoopName } from './components/LoopName';

export const CreateLoopDialog = () => {
  const { hasPermission } = usePermissionsContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [step, setStep] = useState<'name' | 'outcomes' | 'fields'>('name');
  const [name, setName] = useState<string>('');
  const [selectedSources, updateSelectedSources] = useState<ProcessSource[]>(
    []
  );

  const { data: processes } = useQuery(
    getProcessesQuery({
      types: ['risk', 'score', 'monitoring', 'onboarding', 'enrichment'],
    })
  );

  const createLoopMutation = useMutation({
    mutationFn: async (sources: ProcessSource[]) => {
      const process = await ProcessApiClient.getClient().createProcess({
        type: 'loop',
        name,
      });

      await ProcessApiClient.getClient().createNode(
        {
          nodeType: 'loopSource',
          sources,
          channelSettings: [{ type: 'email', mapping: {}, messageBody: [] }],
        },
        {
          params: { processId: process.id },
        }
      );

      return process;
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: getProcessesQueryKey() });
      await queryClient.resetQueries({ queryKey: getProcessesQueryKey() });
      navigate(loopDetailsUrl(data.id));
    },
  });

  const handleChangeLoopName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleClose = () => {
    navigate(LOOPS_URL);
  };

  const handleCreateLoop = (selectedSources: ProcessSource[]) => {
    createLoopMutation.mutate(selectedSources);
  };

  if (step === 'name') {
    return (
      <BasicDialog
        defaultOpen
        title="Add a new loop"
        onClose={handleClose}
        dialogContentDataCy="add-new-loop-dialog"
        className="!w-[350px]"
      >
        <LoopName
          actionLabel="Continue"
          value={name}
          onChangeName={handleChangeLoopName}
          onClick={() => setStep('outcomes')}
        />
      </BasicDialog>
    );
  }

  return (
    <BasicDialog
      defaultOpen
      title="Choose source processes"
      description={
        <p>
          Choose the loop source among the branches of existing processes. More
          than one branch can be selected as the loop source, this way a loop
          can be the resolution of multiple use cases.
        </p>
      }
      onClose={handleClose}
      dialogContentDataCy="add-new-loop-choose-sources-dialog"
    >
      <ProcessOutcomes
        key={processes?.map((process) => process.id).join(',')}
        processes={processes ?? []}
        onUpdateSelection={updateSelectedSources}
      />
      <Button
        disabled={
          !selectedSources.length ||
          !hasPermission(RBAC.ACTIONS.PROCESS.CREATE) ||
          createLoopMutation.isPending
        }
        className="mt-4"
        color="red"
        fullWidth
        onClick={() => handleCreateLoop(selectedSources)}
      >
        Create loop
      </Button>
    </BasicDialog>
  );
};
