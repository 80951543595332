import { useCasesFilters } from '../../providers';

import { DateCasesFilter } from './DateCasesFilter';
import { DropdownCasesFilter } from './DropdownCasesFilter';
import { InputCasesFilter } from './InputCasesFilter';

type CasesFilterProps = {
  identifier: string;
  onFilterChange: (filterIdentifier: string, filterValue: string) => void;
};

export const CasesFilter = ({
  identifier,
  onFilterChange,
}: CasesFilterProps) => {
  const {
    configurations,
    filters,
    filtersWithNoDivider,
    getAppliedValue,
    getAppliedDropdownFilterValues,
    getFiltersDropdownOptions,
  } = useCasesFilters();
  const filtersDropdownOptions = getFiltersDropdownOptions(filters);

  switch (configurations[identifier].format) {
    case 'dropdown':
      return (
        <DropdownCasesFilter
          key={'filter-' + identifier}
          identifier={identifier}
          configuration={configurations[identifier]}
          dropdownOptions={filtersDropdownOptions[identifier]}
          appliedFilterValues={getAppliedDropdownFilterValues(identifier)}
          filtersWithNoDivider={filtersWithNoDivider}
          handleChange={onFilterChange}
        />
      );
    case 'date':
      return (
        <DateCasesFilter
          key={'filter-' + identifier}
          identifier={identifier}
          configuration={configurations[identifier]}
          appliedValue={getAppliedValue(identifier).toString()}
          handleChange={onFilterChange}
        />
      );
    case 'input':
      return (
        <InputCasesFilter
          key={'filter-' + identifier}
          identifier={identifier}
          configuration={configurations[identifier]}
          appliedValue={getAppliedValue(identifier).toString()}
          handleChange={onFilterChange}
        />
      );
    default:
      return null;
  }
};
