import { z } from 'zod';

import { datasetOrigins, DatasetScanableOrigin } from '../dataset';

export const scanRequestSchema = z.object({
  datasetId: z.string().min(1),
  datasetKey: z.string().min(1),
  datasetOrigin: DatasetScanableOrigin,
  workspaceId: z.string().optional(),
});

export const createRequestSchema = z.object({
  datasetId: z.string().min(1),
  datasetKey: z.string().min(1),
  datasetOrigin: DatasetScanableOrigin,
  workspaceId: z.string().optional(),
  fieldNames: z.array(z.string()),
  fieldTypes: z.array(z.string()),
});

export const executeRequestSchema = z.object({
  processId: z.string().min(1),
  workspaceId: z.string().min(1),
  versionId: z.string().min(1),
  datasetId: z.string().min(1),
  datasetKey: z.string().min(1),
  datasetOrigin: z.enum(datasetOrigins),
  processRunId: z.string().optional(),
  fieldNames: z.array(z.string()),
  fieldTypes: z.array(z.string()),
});
