import { z } from 'zod';

import { MoonrakerForm } from '@spektr/moonraker-types';

import { SpektrData } from '../spektrfield';

import { Form_Deprecated } from '../formBuilder';
import { objectIdSchema, predicateSchema } from '../common';
import { channelSettingsSchema } from '../loopChannel';

import { edgeSchema, routerEdgeSchema } from './edgeSchema';
import { createNodeBaseSchema } from './createNodeBaseSchema';

import {
  AiAmlAlertNode,
  CreateAiAmlAlertNode,
  UpdateAiAmlAlertNode,
} from './aiAmlAlert';

export const serviceNodeTypes = [
  'openCorporatesMonitoring',
  'openCorporatesRisk',
  'complyAdvantageKyb',
  'complyAdvantageKyc',
  'kyckr',
  'bodacc',
  'veriff',
  'veriffIdv',
  'mitId',
  'virk',
] as const;

export const nodeTypes = [
  'router',
  'calculation',
  'slack',
  'filter',
  'outcome',
  'loopSource',
  'onboardingProcessSource',
  'form',
  'alert',
  'returningProcess',
  'monitoringDataset',
  'mitId',
  'manualReview',
  'aiAmlAlert',
  'customerStatus',
  ...serviceNodeTypes,
] as const;

export const endClientFacingNodeTypes = [
  'form',
  'mitId',
  'veriff',
  'veriffIdv',
] as const;
export const EndClientFacingNodeType = z.enum(endClientFacingNodeTypes);
export type EndClientFacingNodeType = z.infer<typeof EndClientFacingNodeType>;

export const NodeServiceType = z.enum(serviceNodeTypes);
export type NodeServiceType = z.infer<typeof NodeServiceType>;

export const NodeType = z.enum(nodeTypes);
export type NodeType = z.infer<typeof NodeType>;

export const filterNodeSourceTypes = ['process', 'source'] as const;
export const filterNodeSourceTypeSchema = z.enum(filterNodeSourceTypes);

/**
 * ROULE GROUP SCHEMAS
 */

export const RuleGroup = z.object({
  id: z.string().optional(),
  title: z.string().min(1),
  score: z.coerce.number().optional(),
  rule: predicateSchema,
});
export type RuleGroup = z.infer<typeof RuleGroup>;

/**
 * SEGMENT SCHEMAS
 */

export const SegmentInputSchema = z.object({
  title: z.string().min(1),
  weight: z.coerce.number().min(0).max(100),
  groups: z.array(RuleGroup),
  id: objectIdSchema.optional(),
});
export type SegmentInputSchema = z.infer<typeof SegmentInputSchema>;

export const SegmentSchema = SegmentInputSchema.merge(
  z.object({
    id: objectIdSchema,
  })
);
export type SegmentSchema = z.infer<typeof SegmentSchema>;

/**
 * ROUTER NODE SCHEMAS
 */

export const RouterNodeInputSchema = z.strictObject({
  nodeType: z.literal('router'),
  title: z.string().min(1),
  groups: z.array(RuleGroup),
});

export type RouterNodeInput = z.infer<typeof RouterNodeInputSchema>;

export const updateRouterNodeSchema = RouterNodeInputSchema;

export const routerNodeSchema = RouterNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(routerEdgeSchema),
  })
);

export const createRouterNodeSchema =
  RouterNodeInputSchema.merge(createNodeBaseSchema);

/**
 * CALCULATION NODE SCHEMAS
 */

export const CalculationNodeInputSchema = z.object({
  nodeType: z.literal('calculation'),
  title: z.string().min(1),
  segments: z.array(SegmentInputSchema),
});
export type CalculationNodeInputSchema = z.infer<
  typeof CalculationNodeInputSchema
>;

export const updateCalculationNodeSchema = CalculationNodeInputSchema;

export const createCalculationNodeSchema =
  CalculationNodeInputSchema.merge(createNodeBaseSchema);

export const calculationNodeSchema = CalculationNodeInputSchema.merge(
  z.object({
    id: objectIdSchema,
    segments: z.array(SegmentSchema),
    adj: z.array(edgeSchema),
  })
);

/**
 * SLACK NODE SCHEMAS
 */
export const SlackNodeInputSchema = z.object({
  nodeType: z.literal('slack'),
  title: z.string(),
  entityName: z.string().optional(),
  channelId: z.string().optional(),
  botUserOauthToken: z.string().optional(),
  customMessage: z.string().optional(),
});
export type SlackNodeInputSchema = z.infer<typeof SlackNodeInputSchema>;

export const updateSlackNodeSchema = SlackNodeInputSchema;

export const createSlackNodeSchema =
  SlackNodeInputSchema.merge(createNodeBaseSchema);

export const slackNodeSchema = SlackNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
  })
);

/**
 * CUSTOMER STATUS NODE SCHEMAS
 */
export const CustomerStatusNodeInputSchema = z.object({
  nodeType: z.literal('customerStatus'),
  title: z.string().min(1),
  fields: z.record(z.string(), z.boolean()),
});

export type CustomerStatusNodeInputSchema = z.infer<
  typeof CustomerStatusNodeInputSchema
>;

export const createCustomerStatusNodeSchema =
  CustomerStatusNodeInputSchema.merge(createNodeBaseSchema);

export const customerStatusNodeSchema = CustomerStatusNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);

export type CustomerStatusNode = z.infer<typeof customerStatusNodeSchema>;

/**
 * ALERT NODE SCHEMAS
 */
export const AlertFieldSchema = z.strictObject({
  processId: objectIdSchema,
  nodeId: objectIdSchema,
  fields: z.record(z.string(), z.boolean()),
  name: z.string().optional(),
});
export type AlertFieldSchema = z.infer<typeof AlertFieldSchema>;

export const AlertNodeInputSchema = z.object({
  nodeType: z.literal('alert'),
  title: z.string(),
  alerts: z.array(AlertFieldSchema),
});
export type AlertNodeInputSchema = z.infer<typeof AlertNodeInputSchema>;
export type AlertNodeFields = AlertNodeInputSchema;

export const updateAlertNodeSchema = AlertNodeInputSchema;

export const createAlertNodeSchema =
  AlertNodeInputSchema.merge(createNodeBaseSchema);

export const alertNodeSchema = AlertNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
  })
);

/**
 * MANUAL REVIEW NODE SCHEMAS
 */

export const TasksSchema = z.strictObject({
  id: z.string(),
  title: z.string(),
  description: z.string(),
});

export type Task = z.infer<typeof TasksSchema>;

export const ManualReviewNodeInputSchema = z.strictObject({
  nodeType: z.literal('manualReview'),
  title: z.string().min(1),
  tasks: z.array(TasksSchema).optional(),
});

export type ManualReviewNodeInputSchema = z.infer<
  typeof ManualReviewNodeInputSchema
>;

export const updateManualReviewNodeSchema = ManualReviewNodeInputSchema;

export const createManualReviewNodeSchema =
  ManualReviewNodeInputSchema.merge(createNodeBaseSchema);

export const manualReviewNodeSchema = ManualReviewNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
  })
);

/**
 * FILTER NODE SCHEMAS
 */
export const filterNodeSourceProcessInputSchema = z.strictObject({
  type: z.literal('process'),
  processId: objectIdSchema,
  outcomeId: objectIdSchema,
  filter: RuleGroup.optional(),
});

export const filterNodeSourceDataInputSchema = z.strictObject({
  type: z.literal('source'),
  sourceId: objectIdSchema,
  filter: RuleGroup.optional(),
});

export const filterNodeSourceInputSchema = z.discriminatedUnion('type', [
  filterNodeSourceProcessInputSchema,
  filterNodeSourceDataInputSchema,
]);

export const filterNodeInputSchema = z.strictObject({
  nodeType: z.literal('filter'),
  source: filterNodeSourceInputSchema.optional(),
});

export const filterNodeSchema = filterNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
  })
);

export const createFilterNodeSchema = filterNodeInputSchema;

export const updateFilterNodeSchema = filterNodeInputSchema.required();

/**
 * OUTCOME NODE SCHEMAS
 */
export const outcomeNodeInputSchema = z.strictObject({
  nodeType: z.literal('outcome'),
  route: z
    .strictObject({
      routerId: objectIdSchema,
      ruleGroupId: objectIdSchema,
    })
    .optional(),
  tagIds: z.array(z.string()).optional(),
});

export const outcomeNodeSchema = outcomeNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema).length(0),
    tagIds: z.array(z.string()).optional(),
  })
);

export const createOutcomeNodeSchema =
  outcomeNodeInputSchema.merge(createNodeBaseSchema);

export const updateOutcomeNodeSchema = outcomeNodeInputSchema;

/**
 * VENDOR NODE SCHEMAS
 */

export const ServiceNodeMonitor = z.object({
  rule: RuleGroup,
  decision: z.discriminatedUnion('type', [
    z.object({ type: z.literal('ignore') }),
    z.object({ type: z.literal('accept') }),
    z.object({ type: z.literal('escalate'), assigneeId: z.string() }),
  ]),
});
export type ServiceNodeMonitor = z.infer<typeof ServiceNodeMonitor>;

export type VendorMappingAliases<T extends object> = Readonly<
  Required<{
    [key in keyof T]: string[];
  }>
>;

function toOptionalString(
  input: number | string | undefined
): string | undefined {
  return input !== undefined ? String(input) : undefined;
}

export const OpenCorporatesMappingStrict = z.object({
  companyName: z.string().optional(),
  companyNumber: z // TODO(ST-2596):
    .union([z.string(), z.number()])
    .optional()
    .transform(toOptionalString),
  countryCode: z.string(),
});
export type OpenCorporatesMappingStrict = z.infer<
  typeof OpenCorporatesMappingStrict
>;

export const OpenCorporatesMappingLoose = OpenCorporatesMappingStrict.partial();
export type OpenCorporatesMappingLoose = z.infer<
  typeof OpenCorporatesMappingLoose
>;

export const openCorporatesMappingAliases: VendorMappingAliases<OpenCorporatesMappingLoose> =
  {
    companyName: ['company_name', 'company name', 'corporations.name'],
    companyNumber: [
      'company_number',
      'company number',
      'corporations.registrationNumber',
    ],
    countryCode: ['country_code', 'country code', 'country_of_incorporation'],
  };

export const OpenCorporatesMonitoringNodeInput = z.strictObject({
  nodeType: z.literal('openCorporatesMonitoring'),
  title: z.string().min(1),
  mapping: OpenCorporatesMappingLoose.optional(),
  monitors: z.array(ServiceNodeMonitor).default([]),
});

export const OpenCorporatesMonitoringNode =
  OpenCorporatesMonitoringNodeInput.merge(
    z.strictObject({
      id: objectIdSchema,
      adj: z.array(edgeSchema),
      fields: z.record(z.string(), z.boolean()),
    })
  );
export type OpenCorporatesMonitoringNode = z.infer<
  typeof OpenCorporatesMonitoringNode
>;

export const CreateOpenCorporatesMonitoringNode =
  OpenCorporatesMonitoringNodeInput.merge(createNodeBaseSchema);
export type CreateOpenCorporatesMonitoringNode = z.infer<
  typeof CreateOpenCorporatesMonitoringNode
>;

export const UpdateOpenCorporatesMonitoringNode =
  OpenCorporatesMonitoringNodeInput.merge(
    z.strictObject({
      fields: z.record(z.string(), z.boolean()),
      mapping: OpenCorporatesMappingLoose.optional(),
    })
  );

const OpenCorporatesRiskNodeInput = z.strictObject({
  nodeType: z.literal('openCorporatesRisk'),
  title: z.string(),
  mapping: OpenCorporatesMappingLoose.optional(),
  monitors: z.array(ServiceNodeMonitor).default([]),
});

export const CreateOpenCorporatesRiskNode =
  OpenCorporatesRiskNodeInput.merge(createNodeBaseSchema);
export type CreateOpenCorporatesRiskNode = z.infer<
  typeof CreateOpenCorporatesRiskNode
>;

export const UpdateOpenCorporatesRiskNode = OpenCorporatesRiskNodeInput.merge(
  z.strictObject({
    fields: z.record(z.string(), z.boolean()),
    mapping: OpenCorporatesMappingLoose.optional(),
  })
);

export const OpenCorporatesRiskNode = OpenCorporatesRiskNodeInput.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type OpenCorporatesRiskNode = z.infer<typeof OpenCorporatesRiskNode>;

export const ComplyAdvantageFuziness = z.number().min(0).max(1);

export const ComplyAdvantageKybMappingStrict = z.object({
  companyName: z.string(),
  companyNumber: z // TODO(ST-2596):
    .union([z.string(), z.number()])
    .optional()
    .transform(toOptionalString),
  countryCode: z.string().optional(),
});
export type ComplyAdvantageKybMappingStrict = z.infer<
  typeof ComplyAdvantageKybMappingStrict
>;

export const ComplyAdvantageKybMappingLoose =
  ComplyAdvantageKybMappingStrict.partial();
export type ComplyAdvantageKybMappingLoose = z.infer<
  typeof ComplyAdvantageKybMappingLoose
>;

export const complyAdvantageKybMappingAliases: VendorMappingAliases<ComplyAdvantageKybMappingLoose> =
  {
    companyName: ['company_name', 'company name', 'corporations.name'],
    companyNumber: [
      'company_number',
      'company number',
      'corporations.registrationNumber',
    ],
    countryCode: ['country_code', 'country code', 'country_of_incorporation'],
  };

export const ComplyAdvantageKycMappingStrict = z
  .object({
    firstName: z.string().optional(),
    middleName: z.string().optional(),
    lastName: z.string().optional(),
    fullName: z.string().optional(),
    dateOfBirth: z.string().optional(),
  })
  .refine(
    ({ firstName, lastName, fullName }) =>
      (firstName !== undefined && lastName !== undefined) ||
      fullName !== undefined,
    {
      message:
        'Properties firstName and lastName or property fullName must be defined',
    }
  );

export type ComplyAdvantageKycMappingStrict = z.infer<
  typeof ComplyAdvantageKycMappingStrict
>;

export const ComplyAdvantageKycMappingLoose = ComplyAdvantageKycMappingStrict;
export type ComplyAdvantageKycMappingLoose = z.infer<
  typeof ComplyAdvantageKycMappingLoose
>;

export const complyAdvantageKycMappingAliases: VendorMappingAliases<ComplyAdvantageKycMappingLoose> =
  {
    firstName: ['firstName', 'first_name', 'first name'],
    middleName: ['middleName', 'middle_name', 'middle name'],
    lastName: ['lastName', 'last_name', 'last name'],
    dateOfBirth: ['dateOfBirth', 'date_of_birth', 'date of birth'],
    fullName: ['fullName', 'full_name', 'full name', 'individuals.name'],
  };

export const KyckrMappingStrict = z.object({
  companyName: z.string().optional(),
  companyNumber: z // TODO(ST-2596):
    .union([z.string(), z.number()])
    .optional()
    .transform(toOptionalString),
  countryCode: z.string().min(2),
  maxCreditCost: z.string().optional(),
  uboThreshold: z.string().optional(),
  maxLayers: z.string().optional(),
});
export type KyckrMappingStrict = z.infer<typeof KyckrMappingStrict>;

export const KyckrMappingLoose = KyckrMappingStrict.partial();
export type KyckrMappingLoose = z.infer<typeof KyckrMappingLoose>;

export const KyckrMappingAliases: VendorMappingAliases<KyckrMappingLoose> = {
  companyName: ['company_name', 'company name', 'corporations.name'],
  companyNumber: [
    'company_number',
    'company number',
    'corporations.registrationNumber',
  ],
  countryCode: ['country_code', 'country code', 'country_of_incorporation'],
  maxCreditCost: [],
  maxLayers: [],
  uboThreshold: [],
};

export const VeriffMappingStrict = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
});
export type VeriffMappingStrict = z.infer<typeof VeriffMappingStrict>;

export const VeriffMappingLoose = VeriffMappingStrict.partial();
export type VeriffMappingLoose = z.infer<typeof VeriffMappingLoose>;

export const veriffMappingAliases: VendorMappingAliases<VeriffMappingLoose> = {
  firstName: ['firstName', 'first_name', 'first name'],
  lastName: ['lastName', 'last_name', 'last name'],
};

const VirkMappingStrict = z.strictObject({
  cvrNumber: z.string().min(1).optional(),
});
export type VirkMappingStrict = z.infer<typeof VirkMappingStrict>;

export const VirkMappingLoose = VirkMappingStrict.partial();
export type VirkMappingLoose = z.infer<typeof VirkMappingLoose>;
export const virkMappingAliases: VendorMappingAliases<VirkMappingLoose> = {
  cvrNumber: [
    'cvrNumber',
    'cvr_number',
    'cvr number',
    'company_number',
    'company number',
    'companyNumber',
  ],
};

const returningProcessMapping = z.strictObject({
  processId: z.string().optional(),
});
export type ReturningProcessMapping = z.infer<typeof returningProcessMapping>;

const customerStatusMapping = z.strictObject({
  status: z.union([z.literal('approved'), z.literal('rejected')]).optional(),
});
export type CustomerStatusMapping = z.infer<typeof customerStatusMapping>;

const datasetMonitoringMapping = z.strictObject({});
export type DatasetMonitoringMapping = z.infer<typeof datasetMonitoringMapping>;

export const ComplyAdvantageKybNodeInput = z.strictObject({
  nodeType: z.literal('complyAdvantageKyb'),
  title: z.string().min(1),
  fuzziness: ComplyAdvantageFuziness.default(0.7),
  mapping: ComplyAdvantageKybMappingLoose.optional(),
  monitors: z.array(ServiceNodeMonitor).default([]),
});
export type ComplyAdvantageKybNodeInput = z.infer<
  typeof ComplyAdvantageKybNodeInput
>;

export const ComplyAdvantageKybNode = ComplyAdvantageKybNodeInput.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type ComplyAdvantageKybNode = z.infer<typeof ComplyAdvantageKybNode>;

export const CreateComplyAdvantageKybNode =
  ComplyAdvantageKybNodeInput.merge(createNodeBaseSchema);
export type CreateComplyAdvantageKybNode = z.infer<
  typeof CreateComplyAdvantageKybNode
>;

export const UpdateComplyAdvantageKybNode = ComplyAdvantageKybNodeInput.merge(
  z.strictObject({
    fuzziness: ComplyAdvantageFuziness,
    fields: z.record(z.string(), z.boolean()),
    mapping: ComplyAdvantageKybMappingLoose.optional(),
  })
);
export type UpdateComplyAdvantageKybNode = z.infer<
  typeof UpdateComplyAdvantageKybNode
>;

export const ComplyAdvantageKycNodeInput = z.strictObject({
  nodeType: z.literal('complyAdvantageKyc'),
  title: z.string().min(1),
  fuzziness: z.number().min(0).max(1).default(0.7),
  mapping: ComplyAdvantageKycMappingLoose.optional(),
  monitors: z.array(ServiceNodeMonitor).default([]),
});
export type ComplyAdvantageKycNodeInput = z.infer<
  typeof ComplyAdvantageKycNodeInput
>;

export const ComplyAdvantageKycNode = ComplyAdvantageKycNodeInput.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type ComplyAdvantageKycNode = z.infer<typeof ComplyAdvantageKycNode>;

export const CreateComplyAdvantageKycNode =
  ComplyAdvantageKycNodeInput.merge(createNodeBaseSchema);
export type CreateComplyAdvantageKycNode = z.infer<
  typeof CreateComplyAdvantageKycNode
>;

export const UpdateComplyAdvantageKycNode = ComplyAdvantageKycNodeInput.merge(
  z.strictObject({
    fuzziness: ComplyAdvantageFuziness,
    fields: z.record(z.string(), z.boolean()),
    mapping: ComplyAdvantageKycMappingLoose.optional(),
  })
);
export type UpdateComplyAdvantageKycNode = z.infer<
  typeof UpdateComplyAdvantageKycNode
>;

export const KyckrNodeInput = z.strictObject({
  nodeType: z.literal('kyckr'),
  title: z.string().min(1),
  mapping: KyckrMappingLoose.optional(),
  monitors: z.array(ServiceNodeMonitor).default([]),
});

export const KyckrNode = KyckrNodeInput.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type KyckrNode = z.infer<typeof KyckrNode>;

export const CreateKyckrNode = KyckrNodeInput.merge(createNodeBaseSchema);
export type CreateKyckrNode = z.infer<typeof CreateKyckrNode>;

export const UpdateKyckrNode = KyckrNodeInput.merge(
  z.strictObject({
    fields: z.record(z.string(), z.boolean()),
    mapping: KyckrMappingLoose.optional(),
  })
);

export const VirkNodeInput = z.strictObject({
  nodeType: z.literal('virk'),
  title: z.string().min(1),
  mapping: VirkMappingStrict.optional(),
});

export const VirkNode = VirkNodeInput.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type VirkNode = z.infer<typeof VirkNode>;

export const CreateVirkNode = VirkNodeInput.merge(createNodeBaseSchema);
export type CreateVirkNode = z.infer<typeof CreateVirkNode>;

export const UpdateVirkNode = VirkNodeInput.merge(
  z.strictObject({
    fields: z.record(z.string(), z.boolean()),
    mapping: VirkMappingStrict,
  })
);

export const VeriffNodeInput = z.strictObject({
  nodeType: z.literal('veriff'),
  title: z.string().min(1),
  mapping: VeriffMappingLoose.optional(),
});
export type VeriffNodeInput = z.infer<typeof VeriffNodeInput>;

export const VeriffNode = VeriffNodeInput.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type VeriffNode = z.infer<typeof VeriffNode>;

export const CreateVeriffNode = VeriffNodeInput.merge(createNodeBaseSchema);
export type CreateVeriffNode = z.infer<typeof CreateVeriffNode>;

export const UpdateVeriffNode = VeriffNodeInput.merge(
  z.strictObject({
    mapping: VeriffMappingLoose.optional(),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type UpdateVeriffNode = z.infer<typeof UpdateVeriffNode>;

export const VeriffIdvMappingStrict = z.object({
  file: z.string().optional(),
});
export type VeriffIdvMappingStrict = z.infer<typeof VeriffIdvMappingStrict>;

export const VeriffIdvMappingLoose = VeriffIdvMappingStrict.partial();
export type VeriffIdvMappingLoose = z.infer<typeof VeriffIdvMappingLoose>;

export const VeriffIdvNodeInput = z.strictObject({
  nodeType: z.literal('veriffIdv'),
  title: z.string().min(1),
  mapping: VeriffIdvMappingLoose.optional(),
});
export type VeriffIdvNodeInput = z.infer<typeof VeriffIdvNodeInput>;

export const VeriffIdvNode = VeriffIdvNodeInput.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type VeriffIdvNode = z.infer<typeof VeriffIdvNode>;

export const CreateVeriffIdvNode =
  VeriffIdvNodeInput.merge(createNodeBaseSchema);
export type CreateVeriffIdvNode = z.infer<typeof CreateVeriffIdvNode>;

export const UpdateVeriffIdvNode = VeriffIdvNodeInput.merge(
  z.strictObject({
    fields: z.record(z.string(), z.boolean()),
    mapping: VeriffIdvMappingLoose.optional(),
  })
);

export const veriffIdvMappingAliases: VendorMappingAliases<VeriffIdvMappingLoose> =
  {
    file: ['file'],
  };

export const returningProcessNodeInputSchema = z.strictObject({
  nodeType: z.literal('returningProcess'),
  title: z.string().min(1),
  processId: objectIdSchema.optional(),
});
export type ReturningProcessNodeInputSchema = z.infer<
  typeof returningProcessNodeInputSchema
>;

export const returningProcessNodeSchema = returningProcessNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    processId: objectIdSchema.optional(),
    fields: z.record(z.string(), z.boolean()),
  })
);

export const createReturningProcessNodeSchema =
  returningProcessNodeInputSchema.merge(createNodeBaseSchema);

export const updateReturningProcessNodeSchema = returningProcessNodeInputSchema;

export const monitoringDatasetNodeInputSchema = z.strictObject({
  nodeType: z.literal('monitoringDataset'),
  title: z.string().min(1),
});
export type MonitoringDatasetNodeInputSchema = z.infer<
  typeof monitoringDatasetNodeInputSchema
>;

export const monitoringDatasetNodeSchema =
  monitoringDatasetNodeInputSchema.merge(
    z.strictObject({
      id: objectIdSchema,
      adj: z.array(edgeSchema),
      fields: z.record(z.string(), z.boolean()),
    })
  );

export type MonitoringDatasetNode = z.infer<typeof monitoringDatasetNodeSchema>;

export const createMonitoringDatasetNodeSchema =
  monitoringDatasetNodeInputSchema.merge(createNodeBaseSchema);

export const updateMonitoringDatasetNodeSchema =
  monitoringDatasetNodeInputSchema;

export const BodaccMappingStrict = z.object({
  companyName: z.string().optional(),
  siretNumber: z.string().min(1),
});
export type BodaccMappingStrict = z.infer<typeof BodaccMappingStrict>;

export const BodaccMappingLoose = BodaccMappingStrict.partial();
export type BodaccMappingLoose = z.infer<typeof BodaccMappingLoose>;

export const bodaccMappingAliases: VendorMappingAliases<BodaccMappingLoose> = {
  companyName: ['company_name', 'company name', 'corporations.name'],
  siretNumber: [
    'siretNumber',
    'siret_number',
    'siret number',
    'siret',
    'sirenNumber',
    'siren_number',
    'siren number',
    'siren',
    'corporations.registrationNumber',
  ],
};

const BodaccNodeInput = z.strictObject({
  nodeType: z.literal('bodacc'),
  title: z.string().min(1),
  fuzziness: z.number().min(0).max(1).default(0.7),
  mapping: BodaccMappingLoose.optional(),
});

export const BodaccNode = BodaccNodeInput.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);
export type BodaccNode = z.infer<typeof BodaccNode>;

export const CreateBodaccNode = BodaccNodeInput.merge(createNodeBaseSchema);
export type CreateBodaccNode = z.infer<typeof CreateBodaccNode>;

export const UpdateBodaccNode = BodaccNodeInput.merge(
  z.strictObject({
    fields: z.record(z.string(), z.boolean()),
    mapping: BodaccMappingLoose.optional(),
  })
);
export type UpdateBodaccNode = z.infer<typeof UpdateBodaccNode>;

/**
 * PROCESS LINK NODE SCHEMAS
 */

export const processLinkSourceSchema = z.strictObject({
  processId: objectIdSchema,
  outcomeId: objectIdSchema,
});

export const processLinkSchema = z.strictObject({
  source: processLinkSourceSchema,
  processes: z.array(objectIdSchema),
});

const processSourceServiceSchema = z.strictObject({
  field: z.string(),
  nodeType: NodeServiceType,
});

export const processSourceSchema = processLinkSourceSchema.merge(
  z.strictObject({
    service: processSourceServiceSchema.optional(),
  })
);

/**
 * LOOP SOURCE NODE SCHEMAS
 */

const loopSourceNodeInputSchema = z.strictObject({
  nodeType: z.literal('loopSource'),
  sources: z.array(processSourceSchema),
  channelSettings: z.array(channelSettingsSchema).optional(),
});

export const createLoopSourceNodeSchema =
  loopSourceNodeInputSchema.merge(createNodeBaseSchema);

export const updateLoopSourceNodeSchema = loopSourceNodeInputSchema;

export const loopSourceExtendedSchema = processLinkSourceSchema.merge(
  z.strictObject({
    service: processSourceServiceSchema
      .merge(
        z.strictObject({
          originProcessId: z.string().optional(),
        })
      )
      .optional(),
  })
);

export const loopSourceNodeSchema = z.strictObject({
  id: objectIdSchema,
  nodeType: z.literal('loopSource'),
  sources: z.array(loopSourceExtendedSchema),
  adj: z.array(edgeSchema),
  channelSettings: z.array(channelSettingsSchema).nullish(),
});

/**
 * ONBOARDING PROCESS SOURCE NODE SCHEMAS
 */

const onboardingProcessSourceNodeInputSchema = z.strictObject({
  nodeType: z.literal('onboardingProcessSource'),
  sources: z.array(processSourceSchema).length(1),
  channelSettings: z.array(channelSettingsSchema).optional(),
});

export const createOnboardingProcessSourceNodeSchema =
  onboardingProcessSourceNodeInputSchema.merge(createNodeBaseSchema);

export const updateOnboardingProcessSourceNodeSchema =
  onboardingProcessSourceNodeInputSchema;

export const onboardingProcessSourceExtendedSchema =
  processLinkSourceSchema.merge(
    z.strictObject({
      service: processSourceServiceSchema
        .merge(
          z.strictObject({
            originProcessId: z.string().optional(),
          })
        )
        .optional(),
    })
  );

export const onboardingProcessSourceNodeSchema = z.strictObject({
  id: objectIdSchema,
  nodeType: z.literal('onboardingProcessSource'),
  sources: z.array(onboardingProcessSourceExtendedSchema),
  adj: z.array(edgeSchema),
  channelSettings: z.array(channelSettingsSchema).optional(),
});

/**
 * MITID NODE SCHEMAs
 */

const mitIdMapping = z.object({});
export type MitIdMapping = z.infer<typeof mitIdMapping>;

const mitIdNodeInputSchema = z.strictObject({
  nodeType: z.literal('mitId'),
  title: z.string().min(1),
  mapping: mitIdMapping.optional(),
  configuration: z.record(z.string(), z.string()).optional(),
});

export const mitIdNodeSchema = mitIdNodeInputSchema.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
    fields: z.record(z.string(), z.boolean()),
  })
);

export const createMitIdNodeSchema =
  mitIdNodeInputSchema.merge(createNodeBaseSchema);

export const updateMitIdNodeSchema = mitIdNodeInputSchema.merge(
  z.strictObject({
    fields: z.record(z.string(), z.boolean()),
    configuration: z.record(z.string(), z.string()).optional(),
  })
);

export type MitIdNode = z.infer<typeof mitIdNodeSchema>;

/**
 * FORM NODE SCHEMAS
 */

export const ActionTicketPresignedPostSchema = z.strictObject({
  url: z.string(),
  fields: z.record(z.string(), z.string()),
  key: z.string(),
  filename: z.string(),
});
export type ActionTicketPresignedPostSchema = z.infer<
  typeof ActionTicketPresignedPostSchema
>;

export const FormSubmissionSchema = z.record(
  z.string(),
  z.union([
    // TODO: replace these with SpektrFieldTypedValue (separate PR?). This would allow us to fix the error in ActionTicketConsumerService#redeemTokenForSubmission
    z.string(),
    z.number(),
    z.boolean(),
    z.record(z.string(), z.string()),
    ActionTicketPresignedPostSchema,
  ])
);
export type FormSubmissionSchema = z.infer<typeof FormSubmissionSchema>;

export const FormNodeInput = z.object({
  nodeType: z.literal('form'),
  title: z.string().default(''),
  form: Form_Deprecated,
  moonrakerForm: MoonrakerForm.optional(),
});

export const CreateFormNode = FormNodeInput.merge(createNodeBaseSchema);
export type CreateFormNode = z.infer<typeof CreateFormNode>;

export const UpdateFormNode = FormNodeInput;
export type UpdateFormNode = z.infer<typeof UpdateFormNode>;

export const FormNode = CreateFormNode.merge(
  z.strictObject({
    id: objectIdSchema,
    adj: z.array(edgeSchema),
  })
);
export type FormNode = z.infer<typeof FormNode>;

export const processNodeSchema = z.discriminatedUnion('nodeType', [
  calculationNodeSchema,
  slackNodeSchema,
  routerNodeSchema,
  filterNodeSchema,
  outcomeNodeSchema,
  OpenCorporatesRiskNode,
  ComplyAdvantageKybNode,
  ComplyAdvantageKycNode,
  KyckrNode,
  VeriffNode,
  VeriffIdvNode,
  monitoringDatasetNodeSchema,
  returningProcessNodeSchema,
  loopSourceNodeSchema,
  onboardingProcessSourceNodeSchema,
  OpenCorporatesMonitoringNode,
  FormNode,
  BodaccNode,
  alertNodeSchema,
  mitIdNodeSchema,
  manualReviewNodeSchema,
  AiAmlAlertNode,
  customerStatusNodeSchema,
  VirkNode,
]);

export const createNodeSchema = z.discriminatedUnion('nodeType', [
  createCalculationNodeSchema,
  createSlackNodeSchema,
  createRouterNodeSchema,
  createFilterNodeSchema,
  createOutcomeNodeSchema,
  CreateOpenCorporatesRiskNode,
  CreateComplyAdvantageKybNode,
  CreateComplyAdvantageKycNode,
  CreateKyckrNode,
  CreateVeriffNode,
  CreateVeriffIdvNode,
  createMonitoringDatasetNodeSchema,
  createReturningProcessNodeSchema,
  CreateBodaccNode,
  createLoopSourceNodeSchema,
  createOnboardingProcessSourceNodeSchema,
  CreateOpenCorporatesMonitoringNode,
  CreateFormNode,
  createAlertNodeSchema,
  createMitIdNodeSchema,
  createManualReviewNodeSchema,
  CreateAiAmlAlertNode,
  createCustomerStatusNodeSchema,
  CreateVirkNode,
]);

export const updateNodeSchema = z.discriminatedUnion('nodeType', [
  updateCalculationNodeSchema,
  updateSlackNodeSchema,
  updateRouterNodeSchema,
  updateFilterNodeSchema,
  UpdateOpenCorporatesRiskNode,
  UpdateComplyAdvantageKybNode,
  UpdateComplyAdvantageKycNode,
  UpdateKyckrNode,
  UpdateVeriffNode,
  UpdateVeriffIdvNode,
  updateMonitoringDatasetNodeSchema,
  updateReturningProcessNodeSchema,
  UpdateBodaccNode,
  updateOutcomeNodeSchema,
  updateLoopSourceNodeSchema,
  updateOnboardingProcessSourceNodeSchema,
  UpdateOpenCorporatesMonitoringNode,
  UpdateFormNode,
  updateAlertNodeSchema,
  updateMitIdNodeSchema,
  updateManualReviewNodeSchema,
  UpdateAiAmlAlertNode,
  UpdateVirkNode,
]);

/**
 * AI ACTION CONFIG NODE SCHEMAS
 */
export const FieldsSuggestionsInput = z.object({
  fields: z.array(z.string()),
  subset: z.array(z.string()),
});
export type FieldsSuggestionsInput = z.infer<typeof FieldsSuggestionsInput>;

/**
 * NodeOutput Schema
 */

export const NodeOutput = z.object({
  nodeId: objectIdSchema,
  nodeType: NodeType,
  processId: objectIdSchema,
  createdAt: z.number(),
  updatedAt: z.number(), // TODO: delete, path is immutable append-only so no updates ever occur
  data: SpektrData.optional(),
  nextNodeId: objectIdSchema.optional(), // for routing
  shouldTerminate: z.boolean().optional(), // for filtering // TODO: Replace the use of 'shouldTerminate' with 'nextNodeId: null'?
  ticketId: objectIdSchema.optional(), // for 'form' // TODO: combine/replace with token?
  oldValues: SpektrData.optional(), // for 'form'
  vendorSearchId: objectIdSchema.optional(), // for async vendor integrations
  token: z.string().optional(), // for 'form'
  mitIdCode: z.string().optional(), // for mitId
  mitIdNonce: z.string().optional(), // for mitId
  queryParameters: z.record(z.string(), z.string()).optional(), // for mitId
  redirectUrl: z.string().optional(), // for veriff
  vendorError: z.boolean().optional(), // for veriff & mitId when they return an error on an API request we make
  shouldNotifyEndClient: z.boolean().optional(), // for any runner that has to send and email/sms/api notification to the end client
  alertId: z.string().optional(),
  error: z.any().optional(),
});
export type NodeOutput = z.infer<typeof NodeOutput>;

export type ConfigurableNode = MitIdNode;
