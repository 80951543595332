import { z } from 'zod';

import { objectIdSchema } from '../common';
import { unixTimestampSchema } from '../utils';

export const triggerFrequency = ['day', 'week', 'month'] as const;
export const triggerFrequencySchema = z.enum(triggerFrequency);

export const triggerCreateInputSchema = z
  .strictObject({
    processId: objectIdSchema,
    frequency: triggerFrequencySchema,
    startDate: unixTimestampSchema,
    interval: z.number(),
    nodeId: objectIdSchema.optional(),
    fields: z.array(z.string()).optional(),
  })
  .refine(
    (data) => {
      // Ensure nodeId and fields are both set together or both omitted
      return (
        (data.nodeId !== undefined && data.fields !== undefined) ||
        (data.nodeId === undefined && data.fields === undefined)
      );
    },
    {
      message: 'nodeId and fields must be set together or both omitted',
    }
  );

export const triggerUpdateInputSchema = z.strictObject({
  frequency: triggerFrequencySchema,
  startDate: unixTimestampSchema,
  interval: z.number(),
  fields: z.array(z.string()).optional(),
});

export const triggerBaseSchema = z.strictObject({
  processId: objectIdSchema,
  frequency: triggerFrequencySchema,
  interval: z.number(),
  startDate: unixTimestampSchema,
  nextTriggerTimestamp: unixTimestampSchema,
});

export const triggerResponseSchema = z.strictObject({
  processId: objectIdSchema,
  id: objectIdSchema,
  frequency: triggerFrequencySchema,
  interval: z.number(),
  startDate: unixTimestampSchema,
  nextTriggerTimestamp: unixTimestampSchema,
  nodeId: objectIdSchema.optional(),
  fields: z.array(z.string()).optional(),
});

export type ProcessTrigger = z.infer<typeof triggerResponseSchema>;
