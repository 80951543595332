import { useCallback } from 'react';
import { ChevronDown, OctagonX, Sparkles } from 'lucide-react';
import { Icon } from '@radix-ui/react-select';

import { cn } from '@spektr/style-utils';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@spektr/client/components';

import { type AdverseMediaAuthorityLevels } from '@spektr/shared/validators';

type AuthorityItem = {
  value: AdverseMediaAuthorityLevels;
  label: string;
  description: string;
  enabled: boolean;
};

const options: AuthorityItem[] = [
  {
    value: 'nothing',
    label: 'Do nothing',
    description: 'spektrAI will not do any actions on the given alert',
    enabled: true,
  },
  {
    value: 'suggestion',
    label: 'Suggest solution',
    description:
      'Let spektrAI go through the given alert case and suggest potential outcomes',
    enabled: true,
  },
  {
    value: 'solve_hits',
    label: 'Solve hits',
    description:
      'Grant spektrAI permission to automatically and autonomously solve individual hits',
    enabled: true,
  },
  {
    value: 'solve_alerts',
    label: 'Solve alerts (Coming soon)',
    description:
      'Grant spektrAI permission to automatically and autonomously solve alerts',
    enabled: false,
  },
];

export type AuthorityDropdownProps = {
  disabled?: boolean;
  placeholder?: string;
  value?: AdverseMediaAuthorityLevels;
  onChange: (value: AdverseMediaAuthorityLevels) => void;
};
export const AuthorityDropdown = ({
  disabled,
  placeholder = 'Select an action',
  value,
  onChange,
}: AuthorityDropdownProps) => {
  const renderOption = useCallback(
    (option: AuthorityItem) => {
      const Icon = option.value === 'nothing' ? OctagonX : Sparkles;
      return (
        <DropdownMenuItem
          key={option.value}
          disabled={!option.enabled}
          onClick={() => onChange(option.value)}
        >
          <div className="flex flex-row items-center gap-3">
            <Icon className="h-4 w-4 shrink-0" />
            <div className="flex flex-col gap-0.5">
              <span className="text-sm font-medium">{option.label}</span>
              <span className="text-xs text-gray-500">
                {option.description}
              </span>
            </div>
          </div>
        </DropdownMenuItem>
      );
    },
    [onChange]
  );

  const selectedOption = options.find((option) => option.value === value);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <button
          className={cn(
            'flex w-full items-center gap-2',
            'h-9 w-[240px] px-3 py-2',
            'border-color-border-select rounded-md border',
            'bg-color-bg-select-trigger',
            'text-sm font-medium',
            selectedOption
              ? 'text-color-text-select-trigger'
              : 'text-color-text-placeholder',
            'ring-offset-background',
            'focus-visible:outline-none focus-visible:ring-0',
            'disabled:cursor-not-allowed',
            'disabled:bg-color-bg-select-trigger--disabled disabled:text-color-text-select-trigger--disabled disabled:opacity-50'
          )}
          disabled={disabled}
        >
          <span className="line-clamp-1">
            {selectedOption ? selectedOption.label : placeholder}
          </span>
          <Icon asChild className="ml-auto shrink-0">
            <ChevronDown className="text-color-text-icon-secondary h-4 w-4" />
          </Icon>
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-[306px]"
        onClick={(e) => e.stopPropagation()}
      >
        {options.map(renderOption)}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
