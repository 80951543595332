import { Outlet } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';

import { getAllAlerts } from '@spektr/client/services';
import { AlertsTable, PaginationCombobox } from '@spektr/client/components';

import { useComputeAlertsTableData } from '@spektr/platform-hooks';

import { Alert } from '@spektr/shared/validators';

import { useNavigateToAlert } from '../../AlertDetails/hooks';
import { useCasesFilters } from '../../providers/CasesFiltersProvider';
import { AlertsListFilters } from '../types';

export const AlertsList = () => {
  const navigateToAlert = useNavigateToAlert();
  const { columnsToDisplay, filters, setFilter } = useCasesFilters();

  const { data: alerts } = useSuspenseQuery({
    ...getAllAlerts({
      page: filters.page,
      limit: filters.limit,
      searchQuery: filters.searchQuery,
      ...(filters as AlertsListFilters),
    }),
    refetchInterval: 10000,
  });

  const tableAlerts = useComputeAlertsTableData(alerts.docs as Alert[]);

  const handleRowClick = (id: string) => {
    navigateToAlert(id);
  };

  return (
    <div className="flex h-full flex-col items-end gap-4">
      <AlertsTable
        columns={columnsToDisplay}
        alerts={tableAlerts}
        onRowClick={handleRowClick}
      />

      <PaginationCombobox
        currentPage={filters.page}
        totalPages={alerts.totalPages}
        pageLimit={filters.limit}
        alignment="end"
        onChangeLimit={(value) => setFilter('limit', value)}
        onChangePage={(value) => setFilter('page', value)}
      />

      <Outlet />
    </div>
  );
};
