import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';
import { ChevronDown } from 'lucide-react';

import { CASES_CUSTOMERS_FULL_URL } from '@spektr/shared/utils';
import {
  ClientRecordStatus,
  indicatorValueTypes,
  TagUpdate,
} from '@spektr/shared/validators';

import {
  useUpdateCustomer,
  useDeleteCustomer,
  useUpdateCustomerTag,
} from '@spektr/platform-hooks';

import { cn } from '@spektr/style-utils';
import { getTagsQuery } from '@spektr/client/services';
import {
  AlertDialog,
  DropdownMenuComboBox,
  DropdownOption,
  StatusWithIcon,
  Tag,
  TagsGroup,
} from '@spektr/client/components';

import {
  getCustomerStatusIcon,
  getCustomerStatusVariant,
} from '../../../CasesCustomersDashboard/constants';

import { useCustomerDetails } from '../../providers/CustomerDetailsProvider';
import { useGetCustomerIndicators, useGetStatusOptions } from '../../hooks';

import {
  CustomerIndicator,
  CustomerTopbarActionsList,
  CustomerTopbarInfo,
} from '../../components';
import { UploadDocumentDialog } from '../../../UploadDocument';

export const CustomerDetailsTopbar = () => {
  const navigate = useNavigate();
  const indicators = useGetCustomerIndicators();
  const { data: tags } = useSuspenseQuery(getTagsQuery());
  const { customer, toggleSidebar } = useCustomerDetails();
  const [showDeleteCustomerDialog, setShowDeleteCustomer] = useState(false);
  const [showUploadDocumentDialog, setShowUploadDocumentDialog] =
    useState(false);
  const deleteCustomer = useDeleteCustomer(() => {
    setShowDeleteCustomer(false);
    navigate(CASES_CUSTOMERS_FULL_URL);
  });
  const updateCustomer = useUpdateCustomer(customer.spektrId);
  const updateTag = useUpdateCustomerTag(customer.spektrId);

  const tagActions: DropdownOption[] = useMemo(
    () =>
      tags
        .filter(
          (tag) => !tag.isDeleted || customer.tags.some((t) => t.id === tag.id)
        )
        .map((availableTag) => ({
          label: (
            <Tag
              label={availableTag.label}
              color={availableTag.color}
              isDeleted={availableTag.isDeleted}
            />
          ),
          value: availableTag.id,
          type: 'item',
          selected: customer.tags.some((tag) => tag.id === availableTag.id),
        })),
    [tags, customer.tags]
  );

  const otherIndicators = useMemo(
    () => indicators.filter((ind) => ind.type !== indicatorValueTypes[1]),
    [indicators]
  );

  const serviceIndicators = useMemo(
    () => indicators.filter((ind) => ind.type === indicatorValueTypes[1]),
    [indicators]
  );

  const handleStatusSelect = async (status: ClientRecordStatus) => {
    await updateCustomer.mutateAsync({ status });
  };

  const [statuses, onStatusClick] = useGetStatusOptions(
    customer.status,
    handleStatusSelect
  );

  const handleDeleteCustomer = async () => {
    await deleteCustomer.mutateAsync(customer.spektrId);
  };

  const handleTagUpdate = async (tagId: string) => {
    const tagInput: TagUpdate = {
      tagId,
      action: customer.tags.some((tag) => tag.id === tagId) ? 'remove' : 'add',
    };

    await updateTag.mutateAsync(tagInput);
  };

  return (
    <div
      className={cn(
        'bg-color-bg-card-default',
        'border-color-border-primary rounded-md border'
      )}
    >
      <div className="border-color-border-primary flex flex-col gap-4 border-b p-6 lg:flex-row lg:justify-between">
        <div className="flex justify-between gap-2">
          <CustomerTopbarInfo customer={customer} />
          <TagsGroup
            customerTags={customer.tags}
            onEdit={handleTagUpdate}
            customerName={customer.name}
            tagActions={tagActions}
          />
        </div>
        <div className="flex justify-between gap-6">
          <DropdownMenuComboBox
            trigger={
              <StatusWithIcon
                className="h-9 px-3 py-0.5"
                color={getCustomerStatusVariant(customer.status)}
                icon={getCustomerStatusIcon(customer.status)}
                endIcon={<ChevronDown className="h-3 w-3" />}
                label={customer.status || 'No Status'}
              />
            }
            options={statuses}
            onClick={onStatusClick}
          />

          <CustomerTopbarActionsList
            onList={() => toggleSidebar(true)}
            onDelete={() => setShowDeleteCustomer(true)}
            onDocumentUpload={() => setShowUploadDocumentDialog(true)}
          />
        </div>
      </div>

      <div className="flex h-[172px] flex-col items-center lg:h-[86px] lg:flex-row">
        <div className="flex h-1/2 w-full flex-row lg:h-full lg:w-auto">
          {otherIndicators.map((indicator, index) => (
            <CustomerIndicator
              key={`${indicator.value}-${index}`}
              className={cn(
                'border-color-border-primary border-b border-r lg:border-b-transparent',
                index === otherIndicators.length - 1
                  ? 'lg:border-r-color-border-primary border-r-transparent'
                  : ''
              )}
              indicator={indicator}
            />
          ))}
        </div>
        <div className="flex h-1/2 w-full flex-row lg:h-full">
          {serviceIndicators.map((indicator, index) => (
            <CustomerIndicator
              key={`${indicator.value}-${index}`}
              className={cn(
                'border-color-border-primary border-r',
                index === serviceIndicators.length - 1
                  ? 'border-r-transparent'
                  : ''
              )}
              indicator={indicator}
            />
          ))}
        </div>
      </div>

      <AlertDialog
        open={!!showDeleteCustomerDialog}
        title="Delete customer"
        paragraph="Are you sure you want to delete this customer? You can not revert this action and it will cascade."
        onCancel={() => setShowDeleteCustomer(false)}
        cancel="Cancel"
        onConfirm={handleDeleteCustomer}
        confirm="Delete"
        disableButtons={deleteCustomer.isPending}
      />

      <UploadDocumentDialog
        open={!!showUploadDocumentDialog}
        onCancel={() => setShowUploadDocumentDialog(false)}
        onUpload={() => setShowUploadDocumentDialog(false)}
      />
    </div>
  );
};
