export namespace ContentHeader {
  export type Props = {
    title: string;
    subtitle: string;
  };
}

export const ContentHeader = ({ title, subtitle }: ContentHeader.Props) => {
  return (
    <div className="flex grow flex-col">
      <h1 className="text-color-text-primary text-base font-medium">{title}</h1>
      <p className="text-color-text-subtext text-sm">{subtitle}</p>
    </div>
  );
};
