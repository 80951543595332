import { ReactNode } from 'react';

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@spektr/client/components';

import { cn } from '@spektr/style-utils';

export type ConfigSegmentProps = {
  children: ReactNode | ReactNode[];
  name: string;
  title: string;
};

export const ConfigSegment = ({
  children,
  name,
  title,
}: ConfigSegmentProps) => {
  return (
    <AccordionItem className="grow" value={name}>
      <AccordionTrigger
        className={cn(
          'text-color-text-primary text-sm font-medium',
          'border-color-border-input border-t',
          'bg-transparent'
        )}
        iconPosition="right"
      >
        {title}
      </AccordionTrigger>
      <AccordionContent className="h-full w-full">{children}</AccordionContent>
    </AccordionItem>
  );
};
