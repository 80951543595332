import { cn } from '@spektr/style-utils';

type MatrixButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const MatrixButton = ({ className, ...props }: MatrixButtonProps) => (
  <button
    className={cn(
      'flex items-center',
      'h-10 w-full px-3',
      'text-left text-sm font-medium',
      'hover:bg-color-bg-button-secondary/30',
      className
    )}
    {...props}
  />
);
