import { AlertFilters } from '../types/Filters';

export const ALERTS_KEYS = {
  ALL: (filters?: AlertFilters) => {
    const key = ['alerts', 'list'] as const;
    return filters ? ([...key, filters] as const) : key;
  },
  ALL_BY_CUSTOMER: (customerId: string, filters?: AlertFilters) => {
    const key = ['alerts', 'list', customerId] as const;
    return filters ? ([...key, filters] as const) : key;
  },
  BY_ID: (alertId: string) => ['alerts', 'details', alertId] as const,
  HIT_BY_ID: (alertId: string, hitId: string) =>
    ['alerts', 'details', alertId, 'hits', hitId] as const,
};
