import { ServiceFieldClient } from '@spektr/client/types';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@spektr/client/components';

import { cn } from '@spektr/style-utils';

import { SelectableField } from '../SelectableField';

type CollapsibleFieldsGroupProps = {
  id: string;
  label: string;
  fields: ServiceFieldClient[];
  onFieldSelectionChange: (
    isGroup: boolean
  ) => (fieldKey: string, value: boolean) => void;
};

export const CollapsibleFieldsGroup = ({
  id,
  label,
  fields,
  onFieldSelectionChange,
}: CollapsibleFieldsGroupProps) => {
  const allFieldsChecked = fields.every((field) => field.value);
  const someFieldsChecked = fields.some((field) => field.value);

  const groupCheckState = allFieldsChecked
    ? true
    : someFieldsChecked
      ? 'indeterminate'
      : false;

  return (
    <Accordion
      collapsible
      defaultValue={`group-${id}`}
      type="single"
      className="w-full"
    >
      <AccordionItem value={`group-${id}`} className="relative border-none">
        <AccordionTrigger iconPosition="right" className="p-4" />
        <SelectableField
          className="absolute left-4 top-4"
          id={id}
          label={label}
          checked={groupCheckState}
          onChange={onFieldSelectionChange(true)}
        />
        <AccordionContent>
          <div className="flex flex-col rounded-md border">
            {fields.map((field, index) => (
              <SelectableField
                key={field.key}
                id={field.key}
                className={cn('p-4', {
                  'border-b': index < fields.length - 1,
                })}
                label={field.label ?? field.key}
                help={field.help}
                onChange={onFieldSelectionChange(false)}
                checked={field.value}
              />
            ))}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
