import { ListTodo, Users } from 'lucide-react';

import {
  NotificationBadge,
  Tabbar,
  TabbarItem,
} from '@spektr/client/components';
import {
  CASES_ALERTS_FULL_URL,
  CASES_CUSTOMERS_FULL_URL,
} from '@spektr/shared/utils';

type CasesTabNavigationProps = {
  pendingAlerts?: number;
};

export const CasesTabNavigation = ({
  pendingAlerts = 0,
}: CasesTabNavigationProps) => {
  return (
    <div className="mb-4">
      <Tabbar>
        <TabbarItem
          icon={<Users className="h-4 w-4" />}
          to={CASES_CUSTOMERS_FULL_URL}
        >
          Customers
        </TabbarItem>
        {pendingAlerts > 0 ? (
          <NotificationBadge badgeContent={pendingAlerts}>
            <TabbarItem
              icon={<ListTodo className="h-4 w-4" />}
              to={CASES_ALERTS_FULL_URL}
            >
              Alerts
            </TabbarItem>
          </NotificationBadge>
        ) : (
          <TabbarItem
            icon={<ListTodo className="h-4 w-4" />}
            to={CASES_ALERTS_FULL_URL}
          >
            Alerts
          </TabbarItem>
        )}
      </Tabbar>
    </div>
  );
};
