import {
  ArrowUpDown,
  ListChecks,
  Search,
  SearchCode,
  ServerCog,
  Siren,
  Sparkles,
} from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { assertUnreachable } from '@spektr/shared/utils';

import {
  CalculatorIcon,
  IconWithBackground,
  RouterIcon,
  SlackMinimalIcon,
} from '@spektr/client/components';

import { type SupportedNodes } from '../types/SupportedNodes';

export function useNodeIcon(nodeType: SupportedNodes) {
  switch (nodeType) {
    case 'router':
      return <RouterIcon className="m-0 mr-2 h-6 w-6 p-1.5" />;

    case 'calculation':
      return (
        <IconWithBackground className={cn('mr-2 h-6 w-6', 'bg-color-cyan/20')}>
          <CalculatorIcon className="stroke-color-cyan stroke-1.8 h-4 w-4" />
        </IconWithBackground>
      );

    case 'alert':
      return (
        <IconWithBackground className={cn('mr-2 h-6 w-6', 'bg-color-pink/20')}>
          <Siren className="stroke-color-pink h-4 w-4" />
        </IconWithBackground>
      );

    case 'manualReview':
      return (
        <IconWithBackground className={cn('mr-2 h-6 w-6', 'bg-color-red/20')}>
          <ListChecks className="text-color-red" />
        </IconWithBackground>
      );

    case 'returningProcess':
      return (
        <IconWithBackground
          className={cn('mr-2 h-6 w-6', 'bg-color-cyan/10 p-1')}
        >
          <ArrowUpDown className="stroke-color-cyan h-4 w-4" />
        </IconWithBackground>
      );

    case 'monitoringDataset':
      return (
        <IconWithBackground
          className={cn('mr-2 h-6 w-6', 'bg-color-cyan/10 p-1')}
        >
          <SearchCode className="stroke-color-cyan h-4 w-4" />
        </IconWithBackground>
      );

    case 'aiAmlAlert':
      return (
        <IconWithBackground
          className={cn('mr-2 h-6 w-6', 'bg-color-purple/20')}
        >
          <Sparkles className="text-color-purple h-4 w-4" />
        </IconWithBackground>
      );

    case 'slack':
      return <SlackMinimalIcon className="mr-2 h-4 w-4" />;

    case 'onboardingProcessSource':
      return <ServerCog className="h-4 w-4" />;

    case 'form':
      return null;

    case 'openCorporatesMonitoring':
    case 'openCorporatesRisk':
    case 'complyAdvantageKyb':
    case 'complyAdvantageKyc':
    case 'kyckr':
    case 'bodacc':
    case 'mitId':
    case 'veriff':
    case 'veriffIdv':
    case 'virk':
      return <Search className="text-color-text-icon-primary mr-3 h-5 w-5" />;

    default:
      assertUnreachable(nodeType);
  }
}
