import { ChangeEvent } from 'react';
import { useDebounce } from 'react-use';

import { Search } from 'lucide-react';

import { Input } from '@spektr/client/components';
import { EXECUTIONS_KEYS } from '@spektr/client/services';
import { useQueryClient } from '@tanstack/react-query';
import { usePaginationOptions } from '@spektr/platform-hooks';

type RunsFilterProps = {
  searchValue: string;
  setSearchValue: (value: string) => void;
};

export const RunsFilter = ({
  searchValue,
  setSearchValue,
}: RunsFilterProps) => {
  const queryClient = useQueryClient();
  const { searchParams } = usePaginationOptions();

  const selectedDatasetId = searchParams.get('datasetId') ?? undefined;

  const handleUpdateSearchParams = (search = '') => {
    if (search === searchValue) return;

    setSearchValue(search);

    // Clean up the cache for the previous searches
    if (selectedDatasetId) {
      queryClient.removeQueries({
        queryKey: EXECUTIONS_KEYS.ALL(selectedDatasetId, {
          searchQuery: searchValue,
        }),
      });
    }
  };

  useDebounce(
    () => {
      handleUpdateSearchParams(searchValue);
    },
    500,
    [searchValue]
  );

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <div className="w-full max-w-[189px]">
      <Input
        value={searchValue}
        placeholder="Search for customer"
        startIcon={
          <Search className="text-color-text-icon-secondary h-4 w-4" />
        }
        onChange={handleChangeSearch}
      />
    </div>
  );
};
