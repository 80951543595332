import { useCallback } from 'react';
import { useMeasure } from 'react-use';

import {
  ScrollArea,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@spektr/client/components';

import { ExecutionContextWithDatasetSchema } from '@spektr/shared/validators';
import { Process } from '@spektr/shared/types';
import { cn } from '@spektr/style-utils';

import { ExecutionsTableRow } from '../ExecutionsTableRow';

const baseThClasses = 'text-xs text-color-text-table-header p-3';

export type ExecutionsTableProps = {
  executions: ExecutionContextWithDatasetSchema[];
  processes: Process[];
};

export const ExecutionsTable = ({
  executions = [],
  processes,
}: ExecutionsTableProps) => {
  const [ref, size] = useMeasure<HTMLDivElement>();

  const renderRow = useCallback(
    (row: ExecutionContextWithDatasetSchema, index: number) => {
      const isFirstRow = index === 0;
      const isLastRow = index === executions.length - 1;

      const currentProcess = processes.find(
        (process) => process.id === row.processId
      );

      return (
        <ExecutionsTableRow
          key={row.id}
          row={row}
          isFirstRow={isFirstRow}
          isLastRow={isLastRow}
          currentProcess={currentProcess}
        />
      );
    },
    [executions.length, processes]
  );

  return (
    <div ref={ref} className="relative h-full w-full overflow-hidden">
      <ScrollArea
        style={{
          height: size.height,
        }}
      >
        <Table className="border-separate">
          <TableHeader>
            <TableRow className="rounded-tl-md rounded-tr-md hover:bg-inherit">
              <TableHead className={cn('rounded-tl-md border', baseThClasses)}>
                Customer
              </TableHead>
              <TableHead className={cn(baseThClasses, 'border-b border-t')}>
                Latest run
              </TableHead>
              <TableHead className={cn(baseThClasses, 'border-b border-t')}>
                Run started at
              </TableHead>
              <TableHead className={cn(baseThClasses, 'border-b border-t')}>
                Run finished at
              </TableHead>
              <TableHead className={cn(baseThClasses, 'border-b border-t')}>
                Score
              </TableHead>
              <TableHead className={cn(baseThClasses, 'border-b border-t')}>
                Output
              </TableHead>
              <TableHead
                className={cn('rounded-tr-md border border-l-0', baseThClasses)}
              >
                Status
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {executions.length > 0 ? (
              executions.map(renderRow)
            ) : (
              <TableRow>
                <TableCell
                  colSpan={7}
                  className="rounded-bl-md rounded-br-md border-x border-b text-center"
                >
                  No executions found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ScrollArea>
    </div>
  );
};
