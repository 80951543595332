import { IconBox, SpektrDataflowIcon } from '@spektr/client/components';

export type RouterNodeProps = {
  title: string;
};

export const RouterNode = ({ title }: RouterNodeProps) => {
  return (
    <>
      <IconBox
        size="lg"
        color="red"
        renderIcon={({ className }) => (
          <SpektrDataflowIcon className={className} />
        )}
      />
      <div className="flex flex-col">
        <h3 className="text-color-text-node line-clamp-1 text-sm font-medium">
          {title}
        </h3>
        <span className="text-color-text-node-subtext text-xs">Routing</span>
      </div>
    </>
  );
};
