import { z } from 'zod';

import { rgbaColor } from '@spektr/branding-types';

import { objectIdSchema } from '../common';

export const tagCreateSchema = z.object({
  label: z.string().min(1),
  color: rgbaColor,
});
export type TagCreate = z.infer<typeof tagCreateSchema>;

export const tagSchema = tagCreateSchema.merge(
  z.object({
    id: objectIdSchema,
    isDeleted: z.boolean().optional().default(false),
    createdAt: z.number(),
    updatedAt: z.number(),
  })
);

export type Tag = z.infer<typeof tagSchema>;

export const ClientRecordTag = z.object({
  tagId: objectIdSchema,
  sourceProcessId: z.string().optional(), //tags that are set manually do not have a sourceProcessId
  createdAt: z.number(),
  updatedAt: z.number(),
});
export type ClientRecordTag = z.infer<typeof ClientRecordTag>;

export const TagUpdate = z.object({
  tagId: z.string().min(1),
  action: z.union([z.literal('add'), z.literal('remove')]),
});

export type TagUpdate = z.infer<typeof TagUpdate>;
