import { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { icons } from 'lucide-react';

import {
  getSourcesFromRootNode,
  shouldProcessHaveChannelSettings,
} from '@spektr/shared/utils';

import {
  ButtonWithTooltip,
  DialogClose,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@spektr/client/components';

import {
  type ApiKeySchema,
  type ChannelSettings,
} from '@spektr/shared/validators';
import {
  type ProcessSource,
  type Process,
  type SpektrField,
} from '@spektr/shared/types';

import { apiToFormValues } from './utils/apiToFormValues';
import { formValuesToApi } from './utils/formValuesToApi';

import { SourceTabView } from './containers/SourceTabView';
import { ChannelTabView } from './containers/ChannelTabView';

import { TabNavigation } from './components/TabNavigation';

import { type SettingsTab } from './types/SettingsTab';
import {
  ChannelSettingsValidationSchema,
  type ChannelSettingsFormValues,
} from './types/ChannelSettingsFormValues';

export type SourceSettingsProps = {
  apiKeys: ApiKeySchema[];
  defaultTab?: SettingsTab;
  channelSettings?: ChannelSettings;
  isUpdatePending?: boolean;
  hasUpdatePermission?: boolean;
  nodeTitle: string;
  nodeIcon: keyof typeof icons;
  process: Process;
  processes: Process[];
  spektrFields: SpektrField[];
  onClickUpdate: (
    sources: ProcessSource[],
    settings: ChannelSettings | null
  ) => void;
  onClose: () => void;
};

export const SourceSettings = ({
  apiKeys,
  defaultTab = 'source',
  channelSettings,
  isUpdatePending = false,
  hasUpdatePermission = false,
  nodeTitle,
  nodeIcon,
  process,
  processes,
  spektrFields,
  onClickUpdate,
  onClose,
}: SourceSettingsProps) => {
  const { t } = useTranslation('');
  const Icon = icons[nodeIcon];

  const defaultSettings = useMemo(
    () => apiToFormValues(channelSettings),
    [channelSettings]
  );
  const disableChannel = !shouldProcessHaveChannelSettings(process);

  const formInstance = useForm<ChannelSettingsFormValues>({
    defaultValues: defaultSettings,
    resolver: !disableChannel
      ? zodResolver(ChannelSettingsValidationSchema)
      : undefined,
  });

  const [settingsTab, setSettingsTab] = useState<SettingsTab>(defaultTab);
  const [selectedSources, updateSelectedSources] = useState<ProcessSource[]>(
    getSourcesFromRootNode(process)
  );

  const handleClickUpdate = () => {
    const settings = !disableChannel
      ? formValuesToApi(formInstance.getValues())
      : null;

    onClickUpdate(selectedSources, settings);
  };

  const isGenerallyDisabled =
    isUpdatePending ||
    !formInstance.formState.isValid ||
    !selectedSources.length;

  const disabledReason = useMemo(() => {
    if (!hasUpdatePermission) {
      return t('common.errors.notAllowed');
    }

    if (selectedSources.length === 0) {
      return 'Please select at least one source';
    }

    if (!formInstance.formState.isValid && !disableChannel) {
      return 'Please fill in the channel settings';
    }

    if (process.type === 'onboarding' && selectedSources.length > 1) {
      return 'Onboarding process can have only one source';
    }

    return '';
  }, [
    hasUpdatePermission,
    selectedSources.length,
    formInstance.formState.isValid,
    process.type,
    disableChannel,
    t,
  ]);

  return (
    <div>
      <DialogHeader className="flex flex-col gap-4 space-y-0">
        <div className="flex items-center gap-2">
          <Icon className="h-4 w-4" />
          <DialogTitle className="text-color-text-dialog-title">
            {nodeTitle}
          </DialogTitle>
          <div className="ml-auto flex flex-row gap-4">
            <DialogClose onClick={onClose} />
          </div>
        </div>
        <TabNavigation
          activeTab={settingsTab}
          isChannelDisabled={disableChannel}
          onChangeTab={setSettingsTab}
        />
      </DialogHeader>
      <SourceTabView
        activeTab={settingsTab}
        process={process}
        processes={processes}
        onUpdateSelection={updateSelectedSources}
      />
      <ChannelTabView
        activeTab={settingsTab}
        apiKeys={apiKeys}
        formInstance={formInstance}
        spektrFields={spektrFields}
      />

      <DialogFooter>
        <ButtonWithTooltip
          fullWidth
          color="red"
          className="mt-4"
          disabled={isGenerallyDisabled || !!disabledReason}
          showTooltip={!!disabledReason}
          tooltipProps={{
            content: disabledReason,
          }}
          onClick={handleClickUpdate}
        >
          Update node
        </ButtonWithTooltip>
      </DialogFooter>
    </div>
  );
};
