import { Suspense } from 'react';

import { HitComments } from '../containers/HitComments';
import { SkeletonCommentsList } from '../../components';

export const AlertHitComments = () => {
  return (
    <Suspense fallback={<SkeletonCommentsList />}>
      <HitComments />
    </Suspense>
  );
};
