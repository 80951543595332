import { BasicDialog } from '@spektr/client/components';

import { DocumentUpload } from './DocumentUpload';

type UploadDocumentDialogProps = {
  open: boolean;
  onCancel: () => void;
  onUpload: () => void;
};

export const UploadDocumentDialog = ({
  open,
  onCancel,
  onUpload,
}: UploadDocumentDialogProps) => {
  return (
    <BasicDialog
      className="rounded-2xl border-0"
      titleClassName="font-medium text-base"
      key={`upload-document-${open}`}
      defaultOpen={open}
      title={'Upload document'}
      onClose={onCancel}
    >
      <DocumentUpload onUpload={onUpload} />
    </BasicDialog>
  );
};
