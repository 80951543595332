import { useNavigate, useParams } from 'react-router-dom';

import {
  casesAlertUrl,
  casesCustomerAlertDetailsUrl,
} from '@spektr/shared/utils';

export const useNavigateToAlert = () => {
  const { spektrId } = useParams();
  const navigate = useNavigate();

  return (alertId: string) => {
    if (spektrId) {
      navigate(casesCustomerAlertDetailsUrl(spektrId, alertId));
    } else {
      navigate(casesAlertUrl(alertId));
    }
  };
};
