import { type ServiceFieldClient } from '@spektr/client/types';
import {
  kyckrFields,
  type KyckrOutcome,
  type SpektrFieldKey,
} from '@spektr/shared/types';

export function getSelectedServiceFields(
  fields: ServiceFieldClient[],
  selectedKyckrFields: KyckrOutcome[]
): SpektrFieldKey[] {
  if (selectedKyckrFields.length === 0) {
    return [];
  }

  return fields
    .filter((field) => {
      const kyckrField = kyckrFields.find(
        (spektrField) => spektrField.key === field.key
      );

      if (!kyckrField?.availableIn) {
        return true;
      }

      return kyckrField?.availableIn?.some((outcome) =>
        selectedKyckrFields.includes(outcome)
      );
    })
    .map((field) => field.key);
}
