import {
  CasesTableHeader,
  CasesTableHeadCell,
} from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

const headCellClassName = 'col-span-2 flex w-full items-center truncate';

type DocumentsTableHeaderProps = {
  className?: string;
  columns: string[];
};

export const CustomerDocumentsTableHeader = ({
  className,
  columns,
}: DocumentsTableHeaderProps) => {
  return (
    <CasesTableHeader rowClassName={className}>
      {columns.includes('description') && (
        <CasesTableHeadCell
          className={cn('!col-span-4 px-0', headCellClassName)}
        >
          Description
        </CasesTableHeadCell>
      )}
      {columns.includes('size') && (
        <CasesTableHeadCell className={headCellClassName}>
          Size
        </CasesTableHeadCell>
      )}
      {columns.includes('creationDate') && (
        <CasesTableHeadCell className={headCellClassName}>
          Creation date
        </CasesTableHeadCell>
      )}
      {columns.includes('expiryDate') && (
        <CasesTableHeadCell className={headCellClassName}>
          Expiry date
        </CasesTableHeadCell>
      )}
      {columns.includes('actions') && (
        <CasesTableHeadCell className="col-span-4"></CasesTableHeadCell>
      )}
    </CasesTableHeader>
  );
};
