import {
  ComboboxItemProps,
  iconBoxVariants,
  SpektrDatasetIcon,
} from '@spektr/client/components';

import { cn } from '@spektr/style-utils';

import { SpektrFieldComboBoxItem } from '../SpektrfieldCombobox/SpektrFieldComboBoxItem';

type DatasetFieldProps = {
  value: ComboboxItemProps['value'];
  label: string;
};

export const DatasetField = ({ value, label }: DatasetFieldProps) => {
  return (
    <SpektrFieldComboBoxItem
      label={label}
      value={value}
      icon={
        <SpektrDatasetIcon
          className={cn(
            iconBoxVariants({ variant: 'yellow' }),
            'h-5 w-5 shrink-0 p-1'
          )}
        />
      }
    />
  );
};
