import { cn } from '@spektr/style-utils';

import { TableCell, TableCellProps } from '../../Table';

type CasesTableCellProps = TableCellProps & {
  isFirst?: boolean;
  isLast?: boolean;
};

export const CasesTableCell = ({
  isFirst = false,
  isLast = false,
  className,
  children,
  ...props
}: CasesTableCellProps) => {
  return (
    <TableCell
      className={cn(
        'border-y px-3 py-4 text-sm leading-tight',
        isFirst && 'rounded-l-md border-l',
        isLast && 'rounded-r-md border-r',
        className
      )}
      {...props}
    >
      {children}
    </TableCell>
  );
};
