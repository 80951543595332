import { cn } from '@spektr/style-utils';
import { Skeleton } from '@spektr/client/components';

import { CustomerOwnershipCenteredCell } from './CustomerOwnershipCenteredCell';

export const SkeletonCustomerOwnershipRow = () => {
  return (
    <div className="flex flex-col gap-2">
      <div
        className={cn(
          'grid items-center gap-4',
          'px-6 py-3',
          'rounded-md border',
          'bg-color-bg-card-default'
        )}
        style={{
          gridTemplateColumns: 'repeat(14, minmax(0, 1fr))',
        }}
      >
        <div className="col-span-3 flex items-center gap-5">
          <Skeleton className="bg-color-bg-foreground h-9 w-9 shrink-0 rounded-full" />
          <div className="flex flex-col gap-1">
            <Skeleton className="w-32" />
            <Skeleton className="w-24" />
          </div>
        </div>
        <div className="col-span-4 flex flex-col gap-2">
          <Skeleton className="w-24" />
          <Skeleton className="w-32" />
        </div>
        <CustomerOwnershipCenteredCell>
          <Skeleton className="w-16" />
          <Skeleton className="w-32" />
        </CustomerOwnershipCenteredCell>
        <CustomerOwnershipCenteredCell>
          <Skeleton className="w-16" />
          <Skeleton className="w-24" />
        </CustomerOwnershipCenteredCell>
        <CustomerOwnershipCenteredCell>
          <Skeleton className="w-16" />
          <Skeleton className="w-28" />
        </CustomerOwnershipCenteredCell>
        <Skeleton className="ml-auto h-9 w-9 rounded-md" />
      </div>
    </div>
  );
};
