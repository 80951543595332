import { FormProvider, useForm } from 'react-hook-form';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  getAllowedSpektrFieldsQuery,
  getProcessByIdQuery,
} from '@spektr/client/services';

import { type KyckrNode } from '@spektr/shared/validators';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { MonitorsProvider } from '../../providers/Monitors';
import { EnrichedServiceFieldsProvider } from '../../providers/EnrichedServiceFields';
import { useServiceFields } from '../../hooks/useServiceFields';
import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { withDialogWrapper } from '../WithDialogWrapper';

import { FieldsMonitoringContent } from './containers/FieldsMonitoringContent/FieldsMonitoringContent';
import { FieldsEnrichmentContent } from './containers/FieldsEnrichmentContent';
import { LegacyContent } from './containers/LegacyContent';
import { ContentSkeleton } from './components/ContentSkeleton';

export namespace KyckrContent {
  export type Props = BaseDialogProps<KyckrNode>;
}

const KyckrContent = ({
  isPendingUpdate,
  node,
  processId,
  onUpdate,
}: KyckrContent.Props) => {
  const { newServiceConfig } = useFeatureFlags();

  const { data: process } = useSuspenseQuery(getProcessByIdQuery(processId));
  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, node.id)
  );

  const formInstance = useForm<Record<string, string>>({
    defaultValues: node?.mapping ?? {},
    mode: 'onChange',
  });

  const { fields } = useServiceFields(node);

  return (
    <FormProvider {...formInstance}>
      {newServiceConfig ? (
        ['onboarding', 'loop'].includes(process.type) ? (
          <EnrichedServiceFieldsProvider node={node}>
            <FieldsEnrichmentContent
              node={node}
              spektrFields={spektrFields}
              isValidForm={formInstance.formState.isValid}
              isPendingUpdate={isPendingUpdate}
              onUpdate={onUpdate}
              getMapping={formInstance.getValues}
            />
          </EnrichedServiceFieldsProvider>
        ) : (
          <MonitorsProvider
            node={node}
            serviceFields={fields}
            spektrFields={spektrFields}
          >
            <FieldsMonitoringContent
              node={node}
              spektrFields={spektrFields}
              isValidForm={formInstance.formState.isValid}
              isPendingUpdate={isPendingUpdate}
              onUpdate={onUpdate}
              getMapping={formInstance.getValues}
            />
          </MonitorsProvider>
        )
      ) : (
        <LegacyContent
          node={node}
          spektrFields={spektrFields}
          isValidForm={formInstance.formState.isValid}
          isPendingUpdate={isPendingUpdate}
          onUpdate={onUpdate}
          getMapping={formInstance.getValues}
        />
      )}
    </FormProvider>
  );
};

export const KyckrDialog = withDialogWrapper(
  KyckrContent,
  <ContentSkeleton />,
  {
    className: 'max-w-[700px]',
  }
);
