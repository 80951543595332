import ReactFlow, {
  Background,
  EdgeTypes,
  NodeTypes,
  useEdgesState,
  useNodesState,
  Viewport,
  type Edge,
  type Node,
} from 'reactflow';

import { useGraphLayout } from '../../hooks/useGraphLayout';

import { type EdgeData } from '../../types/EdgeData';
import { type NodeData } from '../../types/NodeData';

import { BasicNode } from '../BasicNode';
import { EdgeWithLabel } from '../EdgeWithLabel';
import { SkeletonNode } from '../SkeletonNode';
import { SkeletonEdge } from '../SkeletonEdge';

const nodeTypes: NodeTypes = {
  basicNode: BasicNode,
  skeletonNode: SkeletonNode,
};

const edgeTypes: EdgeTypes = {
  edgeWithLabel: EdgeWithLabel,
  skeletonEdge: SkeletonEdge,
};

export namespace ConnectionsGraph {
  export type Props = {
    spektrId: string;
    defaultViewPort: Viewport | undefined;
    initialNodes: Node<NodeData>[];
    initialEdges: Edge<EdgeData>[];
    onMoveEnd: (viewport: Viewport) => void;
  };
}

export const ConnectionsGraph = ({
  spektrId,
  defaultViewPort,
  initialEdges,
  initialNodes,
  onMoveEnd,
}: ConnectionsGraph.Props) => {
  const [nodes, _setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, _setEdges, onEdgesChange] = useEdgesState(initialEdges);

  useGraphLayout(spektrId);

  if (!defaultViewPort) return null; // dont render until we have a default viewport

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      className="border-color-border-primary rounded-md border"
      proOptions={{ account: 'paid-pro', hideAttribution: true }}
      defaultViewport={defaultViewPort}
      minZoom={0.2}
      nodesDraggable={false}
      nodesConnectable={false}
      zoomOnDoubleClick={false}
      deleteKeyCode={null}
      onMoveEnd={(_event, viewPort) => onMoveEnd(viewPort)}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
    >
      <Background className="bg-color-bg-primary" />
    </ReactFlow>
  );
};
