import { useEffect, useState } from 'react';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { Check } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import {
  CUSTOM_DOMAIN_KEYS,
  UsersApiClient,
  getDomainSettingsQuery,
} from '@spektr/client/services';

import { Banner, Button, Spinner } from '@spektr/client/components';

import { DomainAliasCreateSchema } from '@spektr/shared/validators';

import { useCreateAliasDomain } from '../../hooks/useCreateAliasDomain';

import { ContentHeader } from '../../components/ContentHeader';
import { SetUpDomain } from '../../components/SetUpDomain';
import { DnsRecords } from '../../components/DnsRecords';

const REFETCH_INTERVAL = 5000;
const TIMEOUT_INTERVAL = 60000;

export const DomainForm = () => {
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [domainInputError, setDomainInputError] = useState<string | undefined>(
    undefined
  );

  const queryClient = useQueryClient();
  const { data, refetch } = useSuspenseQuery(
    getDomainSettingsQuery(shouldRefetch, REFETCH_INTERVAL)
  );
  const createDomainMutation = useCreateAliasDomain();
  const deleteDomainMutation = useMutation({
    mutationFn: () => UsersApiClient.getClient().deleteDomainAlias(undefined),
    onSettled() {
      queryClient.invalidateQueries({
        queryKey: CUSTOM_DOMAIN_KEYS.ALL(),
      });
    },
  });
  const verifyDomainMutation = useMutation({
    mutationFn: () => UsersApiClient.getClient().verifyDomainAlias(undefined),
    onMutate() {
      refetch();
      setShouldRefetch(true);
    },
  });

  /**
   *    This effect acts as a timer to refetch the domain settings
   * if the verify action was triggered by the user. The `shouldRefetch`
   * flag will be set to false when the timeout is reached.
   */
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (shouldRefetch) {
        refetch();
      }
    }, REFETCH_INTERVAL);

    return () => clearInterval(intervalId);
  }, [shouldRefetch, refetch]);

  const handleCreateDomain = (domainName: string) => {
    const parsed = DomainAliasCreateSchema.safeParse({ domainName });

    if (!parsed.success) {
      setDomainInputError(parsed.error.errors[0].message);
    } else {
      createDomainMutation.mutateAsync(domainName);
    }
  };

  const handleDeleteDomain = () => {
    deleteDomainMutation.mutateAsync();
  };

  const handleVerifyDomain = () => {
    verifyDomainMutation.mutate();

    const timeoutId = setTimeout(() => {
      setShouldRefetch(false);
    }, TIMEOUT_INTERVAL);

    return () => clearTimeout(timeoutId);
  };

  const hasRecords = data ? data.resourceRecords?.length > 0 : false;

  return (
    <div
      className={cn(
        'flex w-full flex-col',
        'max-w-[610px]',
        'rounded-md border',
        { 'divide-y': hasRecords }
      )}
    >
      <div className="flex flex-col">
        <div className={cn('flex flex-col gap-6', 'p-6')}>
          <ContentHeader
            title="Use my own custom domain for forms"
            subtitle="You can set up your own domain below"
          />
          <SetUpDomain
            domain={data?.domainName}
            error={domainInputError}
            isPending={
              createDomainMutation.isPending ||
              deleteDomainMutation.isPending ||
              data?.status === 'pending'
            }
            onChangeDomain={() => setDomainInputError(undefined)}
            onSetDomain={handleCreateDomain}
            onRemoveDomain={handleDeleteDomain}
          />
        </div>
        <div
          className={cn('h-auto', 'overflow-hidden', 'transition-all', {
            'h-0': !data || (data && data.status !== 'pending'),
          })}
        >
          <Banner
            className="rounded-none"
            dismissible
            iconName="CircleAlert"
            message="We are generating DNS records for your domain. This may take a few minutes..."
            size="small"
            type="info"
          />
        </div>
      </div>
      <div
        className={cn('h-auto', 'overflow-hidden', 'transition-all', {
          'h-0': !hasRecords,
        })}
      >
        <div className={cn('flex flex-col gap-6', 'p-6')}>
          <ContentHeader
            title="DNS Records"
            subtitle="Please enter below details into your DNS settings"
          />
          <DnsRecords records={data?.resourceRecords ?? []} />

          {data?.status === 'active' && (
            <div
              className={cn(
                'flex flex-row items-center gap-3',
                'ml-auto',
                'text-color-cyan'
              )}
            >
              <Check className="h-3.5 w-3.5" />
              <span className="text-sm font-semibold">
                DNS Records are verified
              </span>
            </div>
          )}
          {data?.status === 'pending' && (
            <Button
              className="ml-auto"
              color="cyan"
              disabled={verifyDomainMutation.isPending || shouldRefetch}
              onClick={handleVerifyDomain}
            >
              {shouldRefetch ? (
                <div className="flex flex-row items-center gap-2.5">
                  <Spinner size="sm" />
                  <span>Verifying...</span>
                </div>
              ) : (
                'Verify and start using domain'
              )}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
