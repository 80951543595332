import { useMutation, useQueryClient } from '@tanstack/react-query';

import { TagCreate } from '@spektr/shared/validators';

import { TAGS_KEYS, ProcessApiClient } from '@spektr/client/services';
import { toast } from '@spektr/client/components';

export const useCreateTag = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (tag: TagCreate) => {
      return ProcessApiClient.getClient().createWorkspaceTag(tag);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: TAGS_KEYS.ALL,
      });

      toast.success({
        title: 'Tag created successfully!',
        description: '',
      });
    },
  });

  return mutation;
};
