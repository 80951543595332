import { Skeleton } from '@spektr/client/components';

export const SkeletonActivityLog = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="rounded-md border">
        <div className="flex w-full flex-col gap-3 border-b p-6 last:border-b-0">
          <div className="flex w-full flex-row items-start gap-3">
            <Skeleton className="h-8 w-8 rounded-full" />
            <div className="flex flex-col items-start gap-1">
              <Skeleton className="h-4 w-24" />
              <Skeleton className="h-3 w-16" />
            </div>
            <div className="ml-auto">
              <Skeleton className="h-3 w-20" />
            </div>
          </div>
          <div className="flex flex-row gap-3">
            <Skeleton className="h-4 w-24" />
            <Skeleton className="h-4 w-32" />
          </div>
          <div>
            <Skeleton className="h-4 w-48" />
          </div>
        </div>
        <div className="flex w-full flex-col gap-3 border-b p-6 last:border-b-0">
          <div className="flex w-full flex-row items-start gap-3">
            <Skeleton className="h-8 w-8 rounded-full" />
            <div className="flex flex-col items-start gap-1">
              <Skeleton className="h-4 w-24" />
              <Skeleton className="h-3 w-16" />
            </div>
            <div className="ml-auto">
              <Skeleton className="h-3 w-20" />
            </div>
          </div>
          <div className="flex flex-row gap-3">
            <Skeleton className="h-4 w-24" />
            <Skeleton className="h-4 w-32" />
          </div>
          <div>
            <Skeleton className="h-4 w-48" />
          </div>
        </div>
      </div>
    </div>
  );
};
