import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  ReactNode,
} from 'react';

import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from '@spektr/style-utils';

export type SwitchProps = ComponentPropsWithoutRef<
  typeof SwitchPrimitives.Root
> & {
  icon?: ReactNode;
  thumbClassName?: string;
};

export const Switch = forwardRef<
  ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(({ className, icon, thumbClassName, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      'peer',
      'inline-flex h-5 w-9 shrink-0 items-center',
      'rounded-full border-2 border-transparent',
      'cursor-pointer',
      'transition-colors',
      'disabled:cursor-not-allowed disabled:opacity-50',
      'focus-visible:outline-none',
      'focus-visible:ring-1 focus-visible:ring-current',
      'focus-visible:ring-offset-1 focus-visible:ring-offset-current',
      'data-[state=checked]:bg-color-bg-switch--checked',
      'data-[state=unchecked]:bg-color-bg-switch--unchecked',
      className
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        'block h-4 w-4',
        'rounded-full',
        'pointer-events-none ring-0',
        'transition-transform',
        'data-[state=checked]:translate-x-4',
        'data-[state=unchecked]:translate-x-0',
        'bg-color-bg-switch-thumb',
        thumbClassName
      )}
    >
      {icon}
    </SwitchPrimitives.Thumb>
  </SwitchPrimitives.Root>
));
