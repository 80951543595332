import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { XIcon } from 'lucide-react';

import { useDebouncedSearch } from '@spektr/platform-hooks';

import { cn } from '@spektr/style-utils';

import { IconButton, Input, Select } from '@spektr/client/components';

import { useRecordsContext } from '../providers';

export const DatasetFilters = () => {
  const { selectedDataset } = useRecordsContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchValueParam = searchParams.get('searchValue') ?? '';
  const fieldParam = searchParams.get('searchField') ?? '';

  const { searchValue, setSearchValue } = useDebouncedSearch(
    searchValueParam,
    (value) => {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        searchField: datasetField,
        searchValue: value,
      });
    },
    500
  );
  const [datasetField, setDatasetField] = useState(fieldParam);

  const handleChangeField = (field: string) => {
    setDatasetField(field);
    setSearchValue('');
  };

  const handleChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleClickClear = () => {
    setDatasetField('');
    setSearchValue('');

    searchParams.delete('searchValue');
    searchParams.delete('searchField');
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: '1',
    });
  };

  const datasetFields = useMemo(() => {
    if (!selectedDataset) return [];

    return Object.values(selectedDataset.fields).map((field) => ({
      label: field.name,
      value: field.name,
    }));
  }, [selectedDataset]);

  useEffect(() => {
    setDatasetField(fieldParam);
    setSearchValue(searchValueParam);
  }, [searchValueParam, fieldParam, setSearchValue]);

  return (
    <div className="relative flex flex-row items-center">
      <Select
        className="rounded-r-none border border-r-0"
        placeholder="Select field"
        options={datasetFields}
        value={datasetField}
        onValueChange={handleChangeField}
      />
      <Input
        disabled={!datasetField}
        className={cn(
          'min-w-20 pr-8',
          'rounded-l-none',
          'focus-visible:outline-none focus-visible:ring-0'
        )}
        placeholder="Filter dataset"
        value={searchValue}
        onChange={handleChangeValue}
      />
      {datasetField && searchValue && (
        <IconButton
          className="absolute right-0 border-0"
          variant="text"
          size="sm"
          onClick={handleClickClear}
        >
          <XIcon className="h-4 w-4" />
        </IconButton>
      )}
    </div>
  );
};
