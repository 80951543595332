import { Link } from 'react-router-dom';
import { MoveRight } from 'lucide-react';

import { cn } from '@spektr/style-utils';

type PageCardProps = {
  pageName: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  url: string;
  disabled?: boolean;
};

export const PageCard = ({
  pageName,
  title,
  description,
  icon,
  url,
  disabled,
}: PageCardProps) => {
  return (
    <Link className={cn(disabled && 'pointer-events-none opacity-50')} to={url}>
      <div
        className={cn(
          'hover:bg-color-bg-card--hover flex flex-col items-start justify-between',
          'bg-color-bg-card-default',
          'border-color-border-primary rounded-md border',
          'h-[224px] p-4 pr-2.5'
        )}
      >
        {icon}
        <div className="mt-4 gap-2">
          <h2 className="text-color-text-primary text-lg font-medium">
            {title}
          </h2>
          <p className="text-color-text-subtext max-w-xs text-sm">
            {description}
          </p>
        </div>

        <div
          className={cn(
            'mt-auto flex items-center gap-4',
            'text-sm font-medium'
          )}
        >
          <span>{`Go to ${pageName} `}</span>
          <MoveRight className="h-4 w-4 stroke-2" />
        </div>
      </div>
    </Link>
  );
};
