import { Suspense, useContext } from 'react';

import {
  CasesRootLayout,
  SkeletonAlertsTable,
  SpektrErrorBoundary,
} from '@spektr/client/components';
import {
  AlertsDashboard,
  CasesNavigation,
} from '@spektr/model-builder/containers';
import { ErrorPage } from '@spektr/shared/components';
import { handleAxiosError } from '@spektr/client/utils';

import { AuthContext } from '../../../auth/auth-context';

export const AlertsIndexPage = () => {
  const columnsToDisplay = [
    'date',
    'alert',
    'customer',
    'alertType',
    'spektrAI',
    'status',
  ];
  const { user } = useContext(AuthContext);

  return (
    <CasesRootLayout>
      <SpektrErrorBoundary
        fallbackRender={({ error }) => (
          <ErrorPage
            title={handleAxiosError(
              error,
              'An error has occurred while retrieving the alerts!'
            )}
          />
        )}
      >
        <CasesNavigation />

        <Suspense fallback={<SkeletonAlertsTable columns={columnsToDisplay} />}>
          <AlertsDashboard
            userId={user?.sub || ''}
            columnsToDisplay={columnsToDisplay}
          />
        </Suspense>
      </SpektrErrorBoundary>
    </CasesRootLayout>
  );
};
