import { InputHTMLAttributes, useState } from 'react';
import { RgbaColorPicker } from 'react-colorful';

import {
  fromRgbaObject,
  isRgbaString,
  toRgbaObject,
} from '@spektr/style-utils';
import { Input, Popover } from '@spektr/client/components';

import { HelperTextTooltip } from '../../HelperTextTooltip';

export type ColorFieldProps = InputHTMLAttributes<HTMLDivElement> & {
  label: string;
  helperText?: string;
  value: string;
  onChange: (value: string) => void;
};

export const ColorField = ({
  label,
  helperText,
  value,
  name,
  onChange,
}: ColorFieldProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col gap-2 px-3">
      <div className="flex flex-row gap-2">
        <label
          className="text-color-text-subtext text-xs font-medium"
          htmlFor={name}
        >
          {label}
        </label>
        {helperText && <HelperTextTooltip helperText={helperText} />}
      </div>
      <Input
        id={name}
        value={value}
        error={!isRgbaString(value)}
        endIcon={
          <Popover
            className="w-fit border-0 bg-transparent"
            align="end"
            alignOffset={0}
            side="bottom"
            sideOffset={10}
            triggerProps={{
              asChild: true,
            }}
            open={open}
            trigger={
              <button
                className="border-color-border-secondary h-5 w-5 shrink-0 rounded-[4px] border"
                style={{
                  backgroundColor: value,
                }}
              />
            }
            onOpenChange={setOpen}
          >
            <RgbaColorPicker
              color={toRgbaObject(value)}
              onChange={(color) => onChange(fromRgbaObject(color))}
            />
          </Popover>
        }
        onChange={(ev) => onChange(ev.target.value)}
      />
    </div>
  );
};
