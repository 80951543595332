import { cn } from '@spektr/style-utils';

export const transUnionContained = cn(
  'bg-color-transUnion',
  'text-color-text-button-contained-vendor',
  'hover:bg-color-transUnion',
  'active:bg-color-transUnion'
);
export const transUnionOutlined = cn(
  transUnionContained,
  'border-color-transUnion',
  'text-color-transUnion',
  'hover:bg-color-transUnion/10',
  'active:bg-color-transUnion/20',
  'disabled:border-color-transUnion/50'
);
export const transUnionText = cn('text-color-transUnion');
