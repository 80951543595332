import { ReactNode } from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

export type IconBoxVariants = VariantProps<typeof boxVariants>;

export type IconBoxProps = IconBoxVariants & {
  className?: string;
  renderIcon: (props: { className: string }) => ReactNode;
};

// TODO: @Alex - this will probably remove BoxIcon component
export const IconBox = ({
  className,
  size,
  color,
  renderIcon,
}: IconBoxProps) => {
  return (
    <div className={cn(boxVariants({ size, color, className }))}>
      {renderIcon({
        className: cn(iconVariants({ color })),
      })}
    </div>
  );
};

const boxVariants = cva(
  [
    'flex items-center justify-center',
    'h-5 w-5',
    'border rounded-md border-color-border-primary',
    'bg-color-bg-accent',
  ],
  {
    variants: {
      size: {
        sm: 'h-4 w-4 p-0.5',
        md: 'h-5 w-5 p-1',
        lg: 'h-9 w-9 p-2',
      },
      color: {
        cyan: 'bg-color-cyan/20 border-color-cyan',
        green: 'bg-color-green/20 border-color-green',
        gray: 'bg-gray-400/20 border-gray-400',
        pink: 'bg-color-pink/20 border-color-pink',
        purple: 'bg-color-purple/20 border-color-purple',
        red: 'bg-color-red/20 border-color-red',
        // Services
        bodacc: 'bg-color-bodacc/20 border-color-bodacc',
        complyAdvantage:
          'bg-color-complyAdvantage/20 border-color-complyAdvantage',
        kyckr: 'bg-color-kyckr/20 border-color-kyckr',
        mitId: 'bg-color-mitId/20 border-color-mitId',
        openCorporates:
          'bg-color-openCorporates/20 border-color-openCorporates',
        veriff: 'bg-color-veriff/20 border-color-veriff',
        veriffIdv: 'bg-color-veriffIdv/20 border-color-veriffIdv',
        virk: 'bg-color-virk/20 border-color-virk',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
);

const iconVariants = cva('stroke-color-text-primary', {
  variants: {
    color: {
      cyan: 'stroke-color-cyan',
      green: 'stroke-color-green',
      gray: 'stroke-gray-400',
      pink: 'stroke-color-pink',
      purple: 'stroke-color-purple',
      red: 'stroke-color-red',
      // Services
      bodacc: '',
      complyAdvantage: 'fill-color-complyAdvantage',
      kyckr: '',
      mitId: '',
      openCorporates: '',
      veriff: '',
      veriffIdv: '',
      virk: '',
    },
  },
});
