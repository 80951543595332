import { SpektrField } from '@spektr/shared/types';
import { type ServiceFieldClient } from '@spektr/client/types';

import { FieldMapping } from '../../components/FieldMapping';

export namespace ServiceFieldsWithFrequencyList {
  export type Props = {
    fields: ServiceFieldClient[];
    spektrFields: SpektrField[];
  };
}

export const ServiceFieldsWithFrequencyList = ({
  spektrFields,
}: ServiceFieldsWithFrequencyList.Props) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="my-2 flex flex-col gap-4 rounded-md border p-4">
        <FieldMapping spektrFields={spektrFields} />
      </div>
    </div>
  );
};
