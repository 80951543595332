import { SpektrFieldWithInfo } from '../../goldenRecord';
import { commonSpektrFields } from '../commonSpektrFields/commonSpektrFields';

export const mitIdCprNumberFields: Array<
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = [
  {
    key: 'mitId.cprNumberIdentifier',
    label: 'CPR Number',
    type: 'string',
    source: 'mitId',
    commonSpektrFieldKeys: [commonSpektrFields.cprNumberIdentifier.key],
  },
];
export const mitIdForCitizenBaseFields: Array<
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = [
  {
    key: 'mitId.is_authentication_successful',
    label: 'Is Authentication Successful',
    type: 'boolean',
    source: 'mitId',
  },
  {
    key: 'mitId.birthdate',
    label: 'Birthdate',
    type: 'date',
    source: 'mitId',
    commonSpektrFieldKeys: [commonSpektrFields.dateOfBirth.key],
  },
  {
    key: 'mitId.name',
    label: 'Name',
    type: 'string',
    source: 'mitId',
    commonSpektrFieldKeys: [commonSpektrFields.fullName.key],
  },
  {
    key: 'mitId.country',
    label: 'Country',
    type: 'country',
    source: 'mitId',
    commonSpektrFieldKeys: [commonSpektrFields.country.key],
  },
];
export const mitIdForCitizenAddressFields: Array<
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = [
  {
    key: 'mitId.address.common_name',
    label: 'Common Name',
    type: 'string',
    source: 'mitId',
    commonSpektrFieldKeys: [commonSpektrFields.fullAddress.key],
  },
  {
    key: 'mitId.address.street_address',
    label: 'Street Address',
    type: 'string',
    source: 'mitId',
    commonSpektrFieldKeys: [commonSpektrFields.streetName.key],
  },
  {
    key: 'mitId.address.postal_code',
    label: 'Postal Code',
    type: 'string',
    source: 'mitId',
    commonSpektrFieldKeys: [commonSpektrFields.postcode.key],
  },
  {
    key: 'mitId.address.city',
    label: 'City',
    type: 'string',
    source: 'mitId',
    commonSpektrFieldKeys: [commonSpektrFields.city.key],
  },
  {
    key: 'mitId.address.locality',
    label: 'Locality',
    type: 'string',
    source: 'mitId',
    commonSpektrFieldKeys: [commonSpektrFields.municipality.key],
  },
  {
    key: 'mitId.address.region',
    label: 'Region',
    type: 'string',
    source: 'mitId',
    commonSpektrFieldKeys: [commonSpektrFields.region.key],
  },
  {
    key: 'mitId.address.country',
    label: 'Country',
    type: 'country',
    source: 'mitId',
    commonSpektrFieldKeys: [commonSpektrFields.country.key],
  },
];

export const otherCalculatedFields: Array<
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = [
  {
    key: 'mitId_service_failure',
    type: 'boolean',
    source: 'mitId',
    label: 'MitId service call failed',
  },
];

export const mitIdSpektrFields = [
  ...mitIdForCitizenBaseFields,
  ...mitIdCprNumberFields,
  ...mitIdForCitizenAddressFields,
  ...otherCalculatedFields,
];
