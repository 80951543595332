import { SquarePen, Trash2 } from 'lucide-react';

import { DropdownOption } from '@spektr/client/components';

import { useDeleteComment } from './useDeleteComment';

const CommentAlerts: DropdownOption[] = [
  {
    type: 'item',
    label: 'Delete this comment',
    icon: <Trash2 className="h-3 w-3" />,
    value: 'delete',
    variant: 'danger',
  },
  {
    type: 'item',
    label: 'Edit comment',
    icon: <SquarePen className="h-3 w-3" />,
    value: 'edit',
  },
];

export const useCommentAlerts = (
  commentId: string,
  onEditCommentAlertClick: () => void,
  onDeleteCommentAlertClick: () => void,
  commentParentId?: string
) => {
  const deleteHitCommentMutation = useDeleteComment(commentParentId);

  const onAlertClick = async (value: string) => {
    if (value === 'delete') {
      await deleteHitCommentMutation.mutateAsync(commentId);
      onDeleteCommentAlertClick();
    } else if (value === 'edit') {
      onEditCommentAlertClick();
    }
  };

  return {
    alerts: CommentAlerts,
    onAlertClick,
  };
};
