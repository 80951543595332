import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import * as TabsPrimitive from '@radix-ui/react-tabs';

import { cn } from '@spektr/style-utils';
import { cva, VariantProps } from 'class-variance-authority';

export const TabsList = forwardRef<
  ElementRef<typeof TabsPrimitive.List>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.List> &
    VariantProps<typeof tabsListVariants>
>(({ className, utility, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(tabsListVariants({ utility }), className)}
    {...props}
  />
));

export const tabsListVariants = cva(
  [
    'flex flex-row items-center justify-center',
    'h-full w-full',
    'rounded-md',
    'overflow-hidden',
  ],
  {
    variants: {
      utility: {
        navigation: 'gap-4 bg-color-bg-tab-trigger p-1',
        filters: 'border',
      },
    },
    defaultVariants: {
      utility: 'navigation',
    },
  }
);
