import { useEffect, useRef, useState } from 'react';

import { useSuspenseQuery } from '@tanstack/react-query';

import { Button, Input, Label } from '@spektr/client/components';
import { getVendorSettingsByVendor } from '@spektr/client/services';

import {
  VendorName,
  vendorSecretSchemaMapping,
} from '@spektr/shared/validators';

import { useTranslation } from 'react-i18next';

import {
  useCreateVendorSettings,
  useUpdateVendorSettings,
} from '../../ConnectionHub/hooks/mutations';

export type ServiceDialogContentProps = {
  serviceName: string;
  onCancel: () => void;
};

export const ServiceDialogContent = ({
  serviceName,
  onCancel,
}: ServiceDialogContentProps) => {
  const { t } = useTranslation('vendors');
  const [hasAllValues, setHasAllValues] = useState<boolean>(false);
  const [inputKeys, setInputKeys] = useState<string[]>([]);
  const inputRefs = useRef<Record<string, HTMLInputElement | null>>({});

  useEffect(() => {
    if (serviceName) {
      const keys = getSecretKey(serviceName as VendorName);
      setInputKeys(keys);

      const refs: Record<string, HTMLInputElement | null> = {};
      keys.forEach((key) => {
        refs[key] = null;
      });
      inputRefs.current = refs;
    }
  }, [serviceName]);

  const { data: vendorSettings } = useSuspenseQuery(
    getVendorSettingsByVendor(serviceName)
  );

  const createVendorSettingsMutation = useCreateVendorSettings();
  const updateVendorSettingsMutation = useUpdateVendorSettings();

  const handleOnResolve = () => {
    const secret = Object.keys(inputRefs.current).reduce(
      (acc, key) => {
        acc[key] = inputRefs.current[key]?.value || '';
        return acc;
      },
      {} as Record<string, string>
    );

    if (!vendorSettings.vendor) {
      handleOnCreate(secret);
      return;
    }

    handleOnUpdate(secret);
  };

  const handleOnCreate = async (secret: Record<string, string>) => {
    await createVendorSettingsMutation.mutateAsync({
      vendor: serviceName,
      secret,
    });
  };

  const handleOnUpdate = async (secret: Record<string, string>) => {
    await updateVendorSettingsMutation.mutateAsync({
      body: { secret },
      vendor: serviceName,
    });
  };

  const handleOnDelete = async () => {
    await updateVendorSettingsMutation.mutateAsync({
      body: { secret: {} },
      vendor: serviceName,
    });
  };

  const getSecretKey = (vendor: VendorName) => {
    return Object.keys(vendorSecretSchemaMapping[vendor]._def.shape());
  };

  const handleInputChange = () => {
    setHasAllValues(
      Object.values(inputRefs.current).every((ref) => ref?.value)
    );
  };

  return (
    <>
      <p className="text-color-text-subtext mb-6 text-sm font-normal leading-5">
        We offer the ability for you to connect to this service with your own
        contract. Please add your information below.
      </p>
      {serviceName && (
        <div className="flex flex-col gap-2">
          {inputKeys.map((key) => (
            <div key={key}>
              <Label className="mb-1.5 text-xs" htmlFor={key}>
                {t(`setup.inputLabels.${key}`)}
              </Label>
              <Input
                name={key}
                ref={(ref) => {
                  if (ref) {
                    inputRefs.current[key] = ref;
                  }
                }}
                type="text"
                placeholder={`Enter ${t(`setup.inputLabels.${key}`)}`}
                defaultValue={
                  vendorSettings.secret
                    ? vendorSettings.secret[
                        key as keyof typeof vendorSettings.secret
                      ]
                    : ''
                }
                onChange={handleInputChange}
              />
            </div>
          ))}
        </div>
      )}

      <div className="mt-4 flex justify-between gap-2">
        {vendorSettings.secret && Object.keys(vendorSettings.secret) && (
          <span className="text-color-red text-sm" data-cy="deleteButton">
            <Button
              variant="text"
              color="red"
              className="px-1"
              onClick={() => handleOnDelete()}
            >
              Delete configuration
            </Button>
          </span>
        )}

        <div className="ml-auto flex gap-2">
          <span data-cy="cancelButton">
            <Button variant="text" onClick={() => onCancel()}>
              Cancel
            </Button>
          </span>
          <span data-cy="setupButton">
            <Button onClick={() => handleOnResolve()} disabled={!hasAllValues}>
              {!vendorSettings.secret ||
              !Object.keys(vendorSettings.secret || {}).length
                ? 'Set up'
                : 'Edit set up'}
            </Button>
          </span>
        </div>
      </div>
    </>
  );
};
