import { z } from 'zod';

export const baseVariantColors = [
  'primary',
  'secondary',
  'cyan',
  'success',
  'yellow',
  'red',
  'black',
  'blue',
  'white',
  'alert',
  'icon',
] as const;
export const BaseVariantColors = z.enum(baseVariantColors);
export type BaseVariantColor = z.infer<typeof BaseVariantColors>;

export type BaseVariantProps = {
  color?: BaseVariantColor;
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'default' | 'sm' | 'lg';
};
