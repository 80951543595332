import { Box, Download, PlaySquareIcon, UploadCloud } from 'lucide-react';

import {
  Button,
  CasesCustomerActions,
  IconButton,
  IconWithBackground,
} from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

const iconButtonClassName = 'h-9 w-9 !px-2';

type CustomerTopbarActionsListProps = {
  onList?: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
  onDocumentUpload?: () => void;
};

export const CustomerTopbarActionsList = ({
  onList,
  onDelete,
  onUpdate,
  onDocumentUpload,
}: CustomerTopbarActionsListProps) => {
  return (
    <div className="flex items-center gap-2">
      {/* TODO: When enabling the button, change className to 'hidden sm:flex' */}
      <Button
        className="hidden w-36 p-2 font-medium"
        color="icon"
        startIcon={
          <IconWithBackground className="bg-color-cyan/20 text-color-cyan h-5 w-5 p-0">
            <Box className="h-2.5 w-2.5" />
          </IconWithBackground>
        }
      >
        <span className="flex w-full items-center justify-between">
          Fetch PII
          <PlaySquareIcon className="h-4 w-4" />
        </span>
      </Button>
      {/* TODO: When enabling the button, change className to 'hidden sm:flex' */}
      <IconButton color="icon" className={cn('hidden', iconButtonClassName)}>
        <Download className="h-4 w-4" />
      </IconButton>
      <IconButton
        color="icon"
        // TODO: @Cristina - add this back after api is working
        // TODO: When enabling the button, change className to 'hidden sm:flex'
        className={cn(iconButtonClassName, 'hidden')}
        onClick={onDocumentUpload}
      >
        <UploadCloud className="h-4 w-4" />
      </IconButton>
      <CasesCustomerActions
        onList={onList}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
    </div>
  );
};
