import { ElementRef, ReactNode, forwardRef } from 'react';
import { VariantProps } from 'class-variance-authority';
import {
  SelectProps as SelectRootProps,
  Root,
  Value,
} from '@radix-ui/react-select';

import { cn } from '@spektr/style-utils';

import {
  SelectTrigger,
  SelectTriggerProps,
  SelectContent,
  SelectItem,
} from './components';

import { selectVariants } from './variants';

export type SelectOption = { value: string; label: string; disabled?: boolean };

export type SelectProps = SelectRootProps &
  VariantProps<typeof selectVariants> & {
    className?: string;
    selectTriggerProps?: SelectTriggerProps & {
      'data-testid'?: string;
    };
    contentClassName?: string;
    defaultValue?: string;
    emptyLabel?: string;
    placeholder?: string;
    options: SelectOption[];
    icon?: ReactNode;
  };

export const Select = forwardRef<ElementRef<typeof Root>, SelectProps>(
  (
    {
      className,
      selectTriggerProps,
      contentClassName,
      defaultValue,
      disabled = false,
      emptyLabel = 'No options available',
      name,
      options,
      placeholder,
      size,
      icon,
      ...props
    },
    ref
  ) => {
    return (
      <Root {...props} disabled={disabled} defaultValue={defaultValue}>
        <SelectTrigger
          name={name}
          icon={icon}
          className={cn(
            'whitespace-nowrap',
            {
              'text-color-text-select-trigger--disabled': !props.value,
            },
            className
          )}
          size={size}
          ref={ref}
          data-cy="select-trigger-data-cy"
          {...selectTriggerProps}
        >
          <Value placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className={contentClassName}>
          {options.length > 0 ? (
            options.map((option) => (
              <SelectItem
                key={option.value}
                value={option.value}
                disabled={option?.disabled}
                data-cy={`select-item-${option.label}-data-cy`}
              >
                {option.label}
              </SelectItem>
            ))
          ) : (
            <SelectItem disabled value="empty">
              {emptyLabel}
            </SelectItem>
          )}
        </SelectContent>
      </Root>
    );
  }
);
