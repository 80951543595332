import { ChevronDown } from 'lucide-react';
import { DialogFooter, Skeleton } from '@spektr/client/components';

export const ContentSkeleton = () => (
  <div className="flex flex-col gap-4">
    <div className="my-2 flex flex-col gap-4 rounded-md border p-4">
      <div className="flex flex-col gap-4">
        <p className="text-color-text-subtext flex items-center gap-1 text-xs font-medium">
          Map attributes from your dataset
        </p>

        <div className="flex max-h-[280px] flex-col overflow-y-auto rounded-md border">
          <div className="flex h-[52px] shrink-0 items-center justify-start border-b px-4 text-left transition-colors last:border-b-0">
            <div className="text-color-text-subtext grid w-full grid-cols-[105px_auto_1fr_auto] items-center gap-3 text-xs">
              <span className="text-color-text-primary">CVR Number</span>
              <span className="capitalize">string</span>
              <div className="ml-auto">
                <Skeleton className="h-7 w-52" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col text-sm">
        <div className="flex items-center gap-2.5">
          <p className="text-color-text-subtext">
            Select fields to enrich your customer profile
          </p>
          <Skeleton className="ml-auto h-9 w-52" />
        </div>
        <div className="flex flex-col">
          <div className="flex items-center gap-3 p-4">
            <Skeleton className="h-3.5 w-3.5" />
            <Skeleton className="w-36" />
            <ChevronDown className="ml-auto h-4 w-4" />
          </div>
          <div className="flex flex-col rounded-md border">
            <div className="flex items-center gap-3 p-4">
              <Skeleton className="h-3.5 w-3.5" />
              <Skeleton className="w-36" />
            </div>
            <div className="flex items-center gap-3 border-t p-4">
              <Skeleton className="h-3.5 w-3.5" />
              <Skeleton className="w-36" />
            </div>
            <div className="flex items-center gap-3 border-t p-4">
              <Skeleton className="h-3.5 w-3.5" />
              <Skeleton className="w-36" />
            </div>
            <div className="flex items-center gap-3 border-t p-4">
              <Skeleton className="h-3.5 w-3.5" />
              <Skeleton className="w-36" />
            </div>
            <div className="flex items-center gap-3 border-t p-4">
              <Skeleton className="h-3.5 w-3.5" />
              <Skeleton className="w-36" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <DialogFooter>
      <Skeleton className="h-9 w-36" />
    </DialogFooter>
  </div>
);
