import { format } from 'date-fns';

import { UserSchema } from '@spektr/shared/validators';

import { cn } from '@spektr/style-utils';
import { FULL_DATE_TIME_FORMAT } from '@spektr/client/constants';

import {
  Avatar,
  Textarea,
  TextareaProps,
  Tooltip,
} from '@spektr/client/components';

type HitResolutionCommentInputProps = TextareaProps & {
  resolvedAt: number | undefined;
  user: UserSchema | undefined;
  onUpdateComment: (comment: string) => void;
};

export const HitResolutionComment = ({
  resolvedAt,
  user,
  className,
  onUpdateComment,
  ...props
}: HitResolutionCommentInputProps) => {
  const isResolved = !!resolvedAt;

  return (
    <div className="relative w-full">
      <Textarea
        {...props}
        className={cn(
          'h-9 max-h-36 min-h-9 py-2',
          'disabled:text-color-text-textarea--disabled disabled:opacity-100',
          'dark:bg-color-bg-primary bg-color-bg-accent',
          !isResolved && 'resize-y',
          className
        )}
        placeholder={isResolved ? '' : 'Add a comment'}
        onChange={(event) => onUpdateComment(event.target.value)}
        disabled={isResolved}
      />
      {isResolved && (
        <Tooltip
          delayDuration={0}
          content={
            <div className="flex flex-col text-base">
              <span>{`${user?.firstName} ${user?.lastName}`}</span>
              <span className="text-color-text-subtext text-xs">
                {format(resolvedAt, FULL_DATE_TIME_FORMAT)}
              </span>
            </div>
          }
          tooltipTriggerProps={{ asChild: true }}
        >
          <Avatar
            className="absolute right-2 top-1/2 h-5 w-5 -translate-y-1/2 cursor-help"
            fallbackClass="text-[8px]"
            firstName={user?.firstName || ''}
            lastName={user?.lastName || ''}
          />
        </Tooltip>
      )}
    </div>
  );
};
