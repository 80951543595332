import { VendorServiceName } from '@spektr/client/types';

import { ServiceVariantProps } from '../../types';

const VENDOR_VARIANT_MAP: Record<
  VendorServiceName,
  ServiceVariantProps['color']
> = {
  bodacc: 'bodacc',
  companiesHouse: 'companiesHouse',
  complyAdvantage: 'complyAdvantage',
  creditSafe: 'creditSafe',
  csiWatchdog: 'white',
  dowJones: 'dowJones',
  dunBradstreet: 'dunBradstreet',
  experian: 'white',
  finScan: 'white',
  kyckr: 'kyckr',
  lexisNexis: 'lexisNexis',
  lseg: 'white',
  moody: 'white',
  openCorporates: 'openCorporates',
  transUnion: 'transUnion',
  veriff: 'veriff',
  veriffIdv: 'veriffIdv',
  mitId: 'mitId',
  virk: 'virk',
};

export const getServiceButtonColor = (serviceName: VendorServiceName) => {
  return VENDOR_VARIANT_MAP[serviceName];
};
