import { NodeType } from '@spektr/shared/validators';

import { type AlertField } from '@spektr/client/types';

export const getUpdatedFields = (
  alertFields: AlertField[],
  fieldName: string,
  nodeType?: NodeType
) => {
  return alertFields.reduce((acc, field) => {
    const fieldKeys = field.name.split('_fields.');
    if (fieldKeys[0] === fieldName && nodeType === field.nodeType) {
      return { ...acc, [field.name]: true };
    }

    return acc;
  }, {});
};
