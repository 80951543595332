import { HTMLAttributes } from 'react';

export const VirkIcon = (props: HTMLAttributes<SVGElement>) => (
  <svg
    width="150"
    height="50"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.68377 0C9.79704 0 10.6991 0.95361 10.6991 2.13033C10.6991 3.30611 9.79704 4.26006 8.68377 4.26006C7.57074 4.26006 6.66846 3.30611 6.66846 2.13033C6.66846 0.95361 7.57074 0 8.68377 0Z"
      fill="#0059B3"
    />
    <path
      d="M4.02035 7.99178C4.02035 9.1657 3.1197 10.1182 2.00961 10.1182C0.899522 10.1182 0 9.1657 0 7.99178C0 6.81967 0.899901 5.86829 2.00961 5.86829C3.12008 5.86829 4.02035 6.82004 4.02035 7.99178Z"
      fill="#0059B3"
    />
    <path
      d="M10.7237 7.40136C10.7237 8.60371 9.80387 9.57391 8.6683 9.57391C7.53036 9.57391 6.61035 8.60371 6.61035 7.40136C6.61035 6.20173 7.5307 5.2262 8.6683 5.2262C9.80387 5.2262 10.7237 6.20173 10.7237 7.40136Z"
      fill="#0059B3"
    />
    <path
      d="M17.3778 7.97654C17.3778 9.18112 16.4514 10.1578 15.311 10.1578C14.1725 10.1578 13.2466 9.18078 13.2466 7.97654C13.2466 6.77086 14.1725 5.79272 15.311 5.79272C16.4514 5.79272 17.3778 6.77086 17.3778 7.97654Z"
      fill="#0059B3"
    />
    <path
      d="M10.7198 12.4934C10.7198 13.6937 9.80091 14.6682 8.66443 14.6682C7.52702 14.6682 6.60645 13.6937 6.60645 12.4934C6.60645 11.2932 7.5274 10.3186 8.66443 10.3186C9.80091 10.3186 10.7198 11.2932 10.7198 12.4934Z"
      fill="#0059B3"
    />
    <path
      d="M15.7329 12.7949C15.7329 14.0146 14.7983 14.9999 13.6457 14.9999C12.4964 14.9999 11.561 14.0146 11.561 12.7949C11.561 11.5779 12.4964 10.5917 13.6457 10.5917C14.7983 10.5917 15.7329 11.5783 15.7329 12.7949Z"
      fill="#0059B3"
    />
    <path
      d="M3.69035 10.6783C4.79364 10.6783 5.68832 11.6236 5.68832 12.7896C5.68832 13.9562 4.79364 14.9011 3.69035 14.9011C2.5871 14.9011 1.69238 13.9562 1.69238 12.7896C1.69238 11.6236 2.5871 10.6783 3.69035 10.6783Z"
      fill="#0059B3"
    />
  </svg>
);
