import { useMemo } from 'react';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';

import { getCustomerTimeline } from '@spektr/client/services';

import { useParsedParams } from '@spektr/shared/hooks';
import { casesCustomerParamsSchema } from '@spektr/shared/utils';

export const useGetTimeline = (limit = 10) => {
  const { spektrId: customerId } = useParsedParams(casesCustomerParamsSchema);
  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
    useSuspenseInfiniteQuery(getCustomerTimeline(customerId, limit));

  const timeline = useMemo(
    () => data?.pages.flatMap((page) => page.docs) ?? [],
    [data]
  );

  return useMemo(
    () => ({
      timeline,
      hasNextPage,
      fetchNextPage,
      isFetching,
      isFetchingNextPage,
    }),
    [timeline, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage]
  );
};
