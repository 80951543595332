import { cn } from '@spektr/style-utils';

import { TableHead, TableHeadProps } from '../../Table';

export const CasesTableHeadCell = ({
  className,
  children,
  ...props
}: TableHeadProps) => {
  return (
    <TableHead
      className={cn(
        'px-3 py-0',
        'text-color-text-table-header text-xs',
        className
      )}
      {...props}
    >
      {children}
    </TableHead>
  );
};
