import {
  ComponentPropsWithoutRef,
  ElementRef,
  ReactNode,
  forwardRef,
} from 'react';
import { Root, Indicator } from '@radix-ui/react-checkbox';
import { Check, Minus } from 'lucide-react';

import { cn } from '@spektr/style-utils';

type CheckboxProps = ComponentPropsWithoutRef<typeof Root> & {
  label?: ReactNode;
};

export const Checkbox = forwardRef<ElementRef<typeof Root>, CheckboxProps>(
  ({ className, label, ...props }, ref) => (
    <div
      className={cn(
        'flex items-center gap-2',
        'text-sm',
        'text-color-text-checkbox',
        'cursor-pointer',
        className
      )}
    >
      <Root
        ref={ref}
        className={cn(
          'bg-color-bg-checkbox-default',
          'border-color-border-input rounded border',
          'peer h-5 w-5 shrink-0 shadow transition',
          'hover:bg-color-bg-checkbox--hover',
          'focus-visible:ring-ring focus-visible:outline-none focus-visible:ring-1',
          'disabled:bg-color-bg-checkbox-default disabled:cursor-not-allowed disabled:opacity-50',
          'data-[state=checked]:text-color-text-checkbox--checked',
          'transition-colors',
          className
        )}
        {...props}
      >
        <Indicator
          className={cn('flex items-center justify-center text-current')}
        >
          {props.checked === 'indeterminate' ? (
            <Minus className="h-3 w-3" />
          ) : (
            <Check className="h-3 w-3" />
          )}
        </Indicator>
      </Root>
      {label && (
        <label className="cursor-pointer" htmlFor={props.id}>
          {label}
        </label>
      )}
    </div>
  )
);
