import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertCircle, Check, CircleAlert, Trash2 } from 'lucide-react';

import { ServiceFieldClient } from '@spektr/client/types';
import {
  Button,
  Select,
  SelectOption,
  SpektrfieldCombobox,
} from '@spektr/client/components';

import { SpektrField } from '@spektr/shared/types';

import { cn } from '@spektr/style-utils';

import { ServiceMonitor } from '../../types/ServiceMonitor';

type FieldMonitorProps = {
  monitor: ServiceMonitor;
  serviceFields: ServiceFieldClient[];
  serviceName: string;
  spektrFields: SpektrField[];
  userOptions: SelectOption[];
  onUpdate: (id: string, left: string, right?: string) => void;
  onUpdateDecision: (
    id: string,
    decision: 'accept' | 'escalate' | 'ignore',
    assignee?: string
  ) => void;
  onRemove: () => void;
};

export const FieldMonitor = ({
  monitor,
  serviceFields,
  serviceName,
  spektrFields,
  userOptions,
  onUpdate,
  onUpdateDecision,
  onRemove,
}: FieldMonitorProps) => {
  const { t } = useTranslation('process');
  const id = monitor.rule.id ?? '';
  const serviceField = String(monitor.rule.rule.left);
  const upstreamField =
    monitor.rule.rule.right === false
      ? undefined
      : String(monitor.rule.rule.right);
  const comparisonType =
    !serviceField || upstreamField === undefined
      ? undefined
      : serviceField === upstreamField
        ? 'has_changed'
        : 'is_different';
  const isDifferent = comparisonType === 'is_different';
  const decision = monitor.decision.type;
  const assignee =
    monitor?.decision.type === 'escalate'
      ? monitor.decision.assigneeId
      : undefined;

  const serviceFieldOptions = useMemo(
    () =>
      serviceFields.map((field) => ({
        value: field.key,
        label: field.label ?? '',
      })),
    [serviceFields]
  );

  const changeComparisonType = (value: 'has_changed' | 'is_different') => {
    onUpdate(id, serviceField, value === 'has_changed' ? serviceField : '');
  };

  const handleUpdateServiceField = (value: string) => {
    onUpdate(id, value, !isDifferent ? value : upstreamField);
  };

  return (
    <div className="flex flex-col rounded-md border">
      <div className="flex flex-col gap-2 border-b p-4">
        <div className="flex items-center gap-3">
          <Select
            className="w-56"
            value={serviceField}
            options={serviceFieldOptions}
            onValueChange={handleUpdateServiceField}
            placeholder={t('fieldsMonitoring.selectServiceFieldPlaceholder', {
              service: serviceName,
            })}
          />
          <Select
            className={
              !comparisonType ? 'w-52' : isDifferent ? 'flex-1' : 'w-32'
            }
            disabled={!serviceField}
            value={comparisonType}
            options={[
              {
                value: 'has_changed',
                label: t('fieldsMonitoring.operator.has_changed'),
              },
              {
                value: 'is_different',
                label: t('fieldsMonitoring.operator.is_different'),
              },
            ]}
            onValueChange={changeComparisonType}
            placeholder={t('fieldsMonitoring.selectOperatorSelectPlaceholder')}
          />
          {isDifferent && (
            <SpektrfieldCombobox
              defaultValue={upstreamField}
              fields={spektrFields}
              onChange={(value) => onUpdate(id, serviceField, value)}
              inputProps={{
                placeholder: t(
                  'fieldsMonitoring.selectDatasetFieldPlaceholder'
                ),
                dimension: 'default',
                error: monitor.error?.field === 'right',
              }}
              triggerProps={{ className: 'w-56' }}
            />
          )}
          <Button
            variant="text"
            className="hover:text-color-red ml-auto px-0"
            onClick={onRemove}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
        <p className="text-color-red text-sm">{monitor.error?.message}</p>
      </div>
      <div className="flex items-center gap-6 p-4">
        <Button
          color={decision === 'accept' ? 'success' : 'secondary'}
          variant="outlined"
          className={cn(
            'flex flex-1 items-center gap-2 !transition-none',
            decision !== 'accept' &&
              'text-color-text-subtext border-color-border-secondary dark:border-color-border-button-secondary'
          )}
          disabled={!comparisonType}
          onClick={() => onUpdateDecision(id, 'accept')}
        >
          <Check className="h-3 w-3" />
          {t('fieldsMonitoring.decision.accept')}
        </Button>
        <Button
          color={decision === 'escalate' ? 'red' : 'secondary'}
          variant="outlined"
          className={cn(
            'flex flex-1 items-center gap-2 !transition-none',
            decision !== 'escalate' &&
              'text-color-text-subtext border-color-border-secondary dark:border-color-border-button-secondary'
          )}
          disabled={!comparisonType}
          onClick={() => onUpdateDecision(id, 'escalate')}
        >
          <AlertCircle className="h-3 w-3" />
          {t('fieldsMonitoring.decision.escalate')}
        </Button>
        <Button
          color="secondary"
          variant="outlined"
          className={cn(
            'flex-1',
            decision !== 'ignore'
              ? 'text-color-text-subtext hover:text-color-text-button-outlined-white border-0'
              : 'border-color-border-button-secondary dark:border-color-border-button-white'
          )}
          disabled={!comparisonType}
          onClick={() => onUpdateDecision(id, 'ignore')}
        >
          {t('fieldsMonitoring.decision.ignore')}
        </Button>
      </div>
      {decision === 'escalate' && (
        <div
          className={cn(
            'flex items-center justify-between gap-14',
            'm-4 mt-0 p-4',
            'rounded-md border border-dashed'
          )}
        >
          <div className="flex items-center gap-1.5 text-sm">
            <CircleAlert className="stroke-color-red h-3 w-3" />
            {t('fieldsMonitoring.assignEscalatedAlert')}
          </div>
          <Select
            className="flex-1"
            value={assignee}
            options={userOptions}
            onValueChange={(value) => onUpdateDecision(id, decision, value)}
            placeholder={t('fieldsMonitoring.selectAssigneePlaceholder')}
          />
        </div>
      )}
    </div>
  );
};
