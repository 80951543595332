import { cn } from '@spektr/style-utils';

import { Tooltip, TooltipProps } from '../Tooltip';

export type TextTruncateProps = Omit<TooltipProps, 'content' | 'children'> & {
  text: string;
  className?: string;
  showTooltip?: boolean;
};

export const TextTruncate = ({
  text,
  className,
  side = 'top',
  align = 'center',
  showTooltip = true,
}: TextTruncateProps) => {
  const textClassName = cn('overflow-hidden text-ellipsis w-full', className);
  return showTooltip ? (
    <Tooltip
      content={text}
      side={side}
      align={align}
      contentClassName={cn('bg-color-bg-tooltip break-words')}
      tooltipTriggerProps={{
        asChild: true,
      }}
    >
      <span className={textClassName}>{text}</span>
    </Tooltip>
  ) : (
    <span className={textClassName}>{text}</span>
  );
};
