import { ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

import { useIncompleteRuleGroup } from '../providers';

import { RuleGroupContent } from './ruleGroupContent';

export const RuleGroupHeader = ({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) => (
  <div
    className={cn(
      'bg-color-bg-card-accent flex justify-between p-3',
      className
    )}
  >
    {children}
  </div>
);

export const RuleGroupCard = ({ children }: { children: ReactNode }) => (
  <section className="rounded-radius flex flex-col border">{children}</section>
);

export const RuleGroupBody = () => {
  const { group, icon } = useIncompleteRuleGroup();

  return <RuleGroupContent group={group} icon={icon} />;
};
