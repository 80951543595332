import {
  ClientRecordStatusChangeEventData,
  UserSchema,
} from '@spektr/shared/validators';
import { Process } from '@spektr/shared/types';

import { StatusWithIcon, TextTruncate } from '@spektr/client/components';

import { CustomerTimelineCell } from '../CustomerTimelineCell';
import { CustomerTimelineDateCell } from '../CustomerTimelineDateCell';

import {
  getCustomerStatusIcon,
  getCustomerStatusVariant,
} from '../../../CasesCustomersDashboard/constants';

import { ChangedDataCell } from './ChangedDataCell';

export type CustomerStatusChangeEventProps = {
  event: ClientRecordStatusChangeEventData;
  users: UserSchema[];
  processes: Process[];
};

export const CustomerStatusChangeEvent = ({
  event,
  users,
  processes,
}: CustomerStatusChangeEventProps) => {
  const user = users.find((u) => u.id === event.eventData.userId);
  const process = processes.find((p) => p.id === event.eventData.processId);

  return (
    <>
      <CustomerTimelineCell
        iconName="Tags"
        label="Status changed from"
        className="col-span-4"
        value={
          <StatusWithIcon
            className="mt-1"
            color={getCustomerStatusVariant(event.eventData.oldStatus)}
            icon={getCustomerStatusIcon(event.eventData.oldStatus)}
            label={event.eventData.oldStatus || 'No Status'}
          />
        }
      />
      <CustomerTimelineCell
        label="Status changed to"
        value={
          <StatusWithIcon
            className="mt-1"
            color={getCustomerStatusVariant(event.eventData.newStatus)}
            icon={getCustomerStatusIcon(event.eventData.newStatus)}
            label={event.eventData.newStatus || 'No Status'}
          />
        }
      />
      <CustomerTimelineCell
        label="Customer"
        value={
          <TextTruncate
            text={event.eventData.customerName}
            className="line-clamp-1"
          />
        }
      />

      <ChangedDataCell user={user} process={process} />
      <CustomerTimelineDateCell date={event.createdAt} />
    </>
  );
};
