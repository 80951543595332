import { Skeleton } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

export const SkeletonList = () => (
  <div className={cn('flex flex-col', 'rounded-md border')}>
    <div
      className={cn(
        'flex items-center gap-3 p-3',
        'border-b',
        'first:rounded-t-md',
        'last:rounded-b-md last:border-b-0'
      )}
    >
      <Skeleton className="h-8 w-8 rounded" />
      <div className="flex flex-col gap-1">
        <Skeleton className="h-5 w-32" />
        <Skeleton className="h-4 w-16" />
      </div>
    </div>
    <div
      className={cn(
        'flex items-center gap-3 p-3',
        'border-b',
        'first:rounded-t-md',
        'last:rounded-b-md last:border-b-0'
      )}
    >
      <Skeleton className="h-8 w-8 rounded" />
      <div className="flex flex-col gap-1">
        <Skeleton className="h-5 w-32" />
        <Skeleton className="h-4 w-16" />
      </div>
    </div>
    <div
      className={cn(
        'flex items-center gap-3 p-3',
        'border-b',
        'first:rounded-t-md',
        'last:rounded-b-md last:border-b-0'
      )}
    >
      <Skeleton className="h-8 w-8 rounded" />
      <div className="flex flex-col gap-1">
        <Skeleton className="h-5 w-32" />
        <Skeleton className="h-4 w-16" />
      </div>
    </div>
    <div
      className={cn(
        'flex items-center gap-3 p-3',
        'border-b',
        'first:rounded-t-md',
        'last:rounded-b-md last:border-b-0'
      )}
    >
      <Skeleton className="h-8 w-8 rounded" />
      <div className="flex flex-col gap-1">
        <Skeleton className="h-5 w-32" />
        <Skeleton className="h-4 w-16" />
      </div>
    </div>
  </div>
);
