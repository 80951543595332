import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { cn } from '@spektr/style-utils';
import { NODE } from '@spektr/shared/components';
import { processBuilderSheetUrl } from '@spektr/shared/utils';
import { ProcessNode } from '@spektr/shared/types';
import { GraphNodeProps } from 'libs/shared/components/src/lib/nodes/types';

export const VirtualRootNode = memo(({ data }: GraphNodeProps<ProcessNode>) => {
  const navigate = useNavigate();

  const handleClick = () => navigate(processBuilderSheetUrl(data.process.id));

  return (
    <div
      className={cn(
        'flex flex-col gap-4 p-3',
        `h-[${NODE.HEIGHT}px] w-[${NODE.WIDTH}px]`,
        'rounded-lg border-2 dark:border',
        'bg-color-bg-accent',
        'cursor-pointer duration-200',
        'hover:bg-color-bg-accent/70 hover:shadow-lg'
      )}
      onClick={handleClick}
      data-cy="virtual-node"
    >
      <div className="flex h-full items-center gap-2">
        <CursorInSquareIcon />
        <h3 className="text-color-text-primary text-sm font-medium">
          Add your first step
        </h3>
      </div>
    </div>
  );
});

const CursorInSquareIcon = () => (
  <svg
    className="bg-color-stroke-default/20 rounded-md border-0"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ProcessImages">
      <rect id="Rectangle 4" width="40" height="40" rx="6" />
      <g id="mouse-pointer-square-dashed">
        <path
          className="stroke-color-text-primary"
          id="Vector"
          d="M14.75 13.25C14.3522 13.25 13.9706 13.408 13.6893 13.6893C13.408 13.9706 13.25 14.3522 13.25 14.75M25.25 13.25C25.6478 13.25 26.0294 13.408 26.3107 13.6893C26.592 13.9706 26.75 14.3522 26.75 14.75M14.75 26.75C14.3522 26.75 13.9706 26.592 13.6893 26.3107C13.408 26.0294 13.25 25.6478 13.25 25.25M17.75 13.25H18.5M17.75 26.75H19.25M21.5 13.25H22.25M13.25 17.75V18.5M26.75 17.75V19.25M13.25 21.5V22.25M20 20L23 27.5L24.275 24.275L27.5 23L20 20Z"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
  </svg>
);
