import { CalendarIcon, Combine, Dot, Filter, Type } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { CasesStatus, SpektrDatasetIcon, Tag } from '@spektr/client/components';
import { useGetDatasets } from '@spektr/platform-hooks';

import {
  alertPresence,
  alertTypes,
  ClientRecordStatus,
  clientRecordStatuses,
} from '@spektr/shared/validators';

import { useSuspenseQuery } from '@tanstack/react-query';
import { getTagsQuery } from '@spektr/client/services';

import { CasesFilterConfiguration } from '../../types';

export const useGetCustomersFiltersConfigurations = (): {
  [filterIdentifier: string]: CasesFilterConfiguration;
} => {
  const { t } = useTranslation('cases');
  const datasets = useGetDatasets();
  const { data: tags } = useSuspenseQuery(getTagsQuery());

  return {
    datasetIds: {
      icon: <SpektrDatasetIcon />,
      label: t(`customers.filters.datasetIds`),
      selectionType: 'multiple',
      format: 'dropdown',
      values: datasets.map((dataset) => ({
        key: dataset.id,
        labelNode: <span>{dataset.name}</span>,
      })),
    },
    status: {
      icon: <Combine />,
      label: t(`customers.filters.status`),
      selectionType: 'multiple',
      format: 'dropdown',
      values: clientRecordStatuses.map((status: ClientRecordStatus) => ({
        key: status,
        labelNode: (
          <CasesStatus status={status} className="h-[22px] max-w-fit text-xs" />
        ),
      })),
    },
    tags: {
      icon: <Dot />,
      label: t(`customers.filters.tags`),
      selectionType: 'multiple',
      format: 'dropdown',
      values: tags.map((tag) => ({
        key: tag.id,
        labelNode: (
          <Tag label={tag.label} color={tag.color} isDeleted={tag.isDeleted} />
        ),
      })),
    },
    alertPresence: {
      icon: <Filter />,
      label: t(`customers.filters.alertPresence`),
      selectionType: 'single',
      format: 'dropdown',
      values: alertPresence.map((presence) => ({
        key: presence,
        labelNode: <span>{t(`customers.alertPresence.${presence}`)}</span>,
      })),
    },
    alertType: {
      icon: <Type />,
      label: t(`customers.filters.alertType`),
      selectionType: 'multiple',
      format: 'dropdown',
      values: alertTypes.map((alertType) => ({
        key: alertType,
        labelNode: <span>{t(`alertTypes.${alertType}`)}</span>,
      })),
    },
    isUbo: {
      icon: <SpektrDatasetIcon />,
      label: t(`customers.filters.isUbo`),
      selectionType: 'single',
      format: 'dropdown',
      values: ['true', 'false'].map((isUbo) => ({
        key: isUbo,
        labelNode: <span>{t(`customers.ubo.${isUbo}`)}</span>,
      })),
    },
    createdAtStart: {
      icon: <CalendarIcon />,
      label: t(`customers.filters.createdAtStart`),
      selectionType: 'single',
      format: 'date',
    },
    updatedAtStart: {
      icon: <CalendarIcon />,
      label: t(`customers.filters.updatedAtStart`),
      selectionType: 'single',
      format: 'date',
    },
  };
};
