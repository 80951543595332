import { ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

type CustomerOwnershipCenteredCellProps = {
  children: ReactNode;
};

export const CustomerOwnershipCenteredCell = ({
  children,
}: CustomerOwnershipCenteredCellProps) => {
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center',
        'col-span-2 h-[58px] pl-4',
        'border-l'
      )}
    >
      <div className="flex flex-col gap-1">{children}</div>
    </div>
  );
};
