import { useMemo } from 'react';
import { useMutation, useSuspenseQuery } from '@tanstack/react-query';

import {
  UserSettingsCreateSchema,
  UserSettingsDeliveryMethods,
  UserSettingsMaxRateLimitUnits,
} from '@spektr/shared/validators';
import {
  UsersApiClient,
  getUserSettingsQueryKey,
} from '@spektr/client/services';

import { ExportMethodForm } from '../../components/ExportMethodForm';
import { UserSettings } from '../../types';

type UpdateExportMethodProps = {
  workspaceId?: string;
};

export const UpdateExportMethod = ({
  workspaceId,
}: UpdateExportMethodProps) => {
  const { data } = useSuspenseQuery({
    queryKey: getUserSettingsQueryKey(),
    queryFn: async () => {
      return await UsersApiClient.getClient().getUserSettingsInWorkspace();
    },
    meta: { skipGlobalErrorHandling: true },
  });

  const updateSettings = useMutation({
    mutationFn: async (data: UserSettingsCreateSchema) => {
      await UsersApiClient.getClient().createUserSettings(data);
    },
  });

  const handleCreate = async ({
    deliveryMethod,
    numberOfCalls,
    interval,
    httpsEndpoint,
    apiKey,
    slackChannelId,
    slackToken,
  }: UserSettings) => {
    if (!workspaceId) return;

    const exportInfo = {
      workspaceId,
      deliveryMethod,
      maxRateLimit: Number(numberOfCalls),
      maxRateLimitUnit: interval,
    };

    if (deliveryMethod === 'httpsPush') {
      Object.assign(exportInfo, {
        httpsPushSettings: {
          httpsEndpoint,
          apiKey,
        },
      });
    } else if (deliveryMethod === 'slack') {
      Object.assign(exportInfo, {
        slackSettings: {
          token: slackToken,
          channelId: slackChannelId,
        },
      });
    }

    await updateSettings.mutateAsync(exportInfo);
  };

  const userSettings = useMemo(
    () => ({
      numberOfCalls: data?.maxRateLimit.toString(),
      interval: data?.maxRateLimitUnit as UserSettingsMaxRateLimitUnits,
      deliveryMethod: data?.deliveryMethod as UserSettingsDeliveryMethods,
      apiKey: data?.httpsPushSettings?.apiKey,
      httpsEndpoint: data?.httpsPushSettings?.httpsEndpoint,
      slackToken: data?.slackSettings?.token,
      slackChannelId: data?.slackSettings?.channelId,
      spektrIps: data?.spektrIps,
    }),
    [data]
  );

  return (
    <ExportMethodForm
      onSubmit={handleCreate}
      isSubmitDisabled={updateSettings.isPending}
      data={userSettings}
      isUpdate={true}
    />
  );
};
