import { Skeleton } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

export type SettingItemSkeletonProps = {
  className?: string;
};

export const SettingItemSkeleton = ({
  className,
}: SettingItemSkeletonProps) => (
  <div
    className={cn(
      'flex flex-row items-center py-3',
      'text-color-text-dialog-default',
      className
    )}
  >
    <div className="flex flex-col gap-1">
      <Skeleton className="h-4 w-40" />
      <Skeleton className="h-3 w-64" />
    </div>
    <div className="ml-auto">
      <Skeleton className="h-8 w-32" />
    </div>
  </div>
);
