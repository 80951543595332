import { useMutation, useQueryClient } from '@tanstack/react-query';

import { invalidateMultipleQueries } from '@spektr/client/utils';

import {
  ProcessApiClient,
  getProcessLinksByIdQueryKey,
  getProcessQueryKey,
} from '@spektr/client/services';

import { NodeCreateInput } from '@spektr/shared/types';

export function useCreateNode(processId: string) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (node: NodeCreateInput) => {
      return ProcessApiClient.getClient().createNode(node, {
        params: { processId },
      });
    },
    onSuccess: async (_, variables) => {
      const queriesToInvalidate = [{ queryKey: getProcessQueryKey(processId) }];

      if (
        variables.nodeType === 'filter' &&
        variables?.source?.type === 'process'
      ) {
        queriesToInvalidate.push({
          queryKey: getProcessLinksByIdQueryKey(variables.source.processId),
        });
      }

      await invalidateMultipleQueries(queryClient, queriesToInvalidate);
    },
  });

  return mutation;
}
