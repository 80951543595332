import {
  Field,
  isEntitiesField,
  isWritableField,
} from '@spektr/moonraker-types';

export const areCustomDataFieldsSet = (fields: Field[]) =>
  fields.every((field) => {
    if (isWritableField(field)) {
      if (isEntitiesField(field)) {
        if (!field.config.prefillDefaultValue) {
          return true;
        }
        if (field.isStructured && !field.config.spektrDataField) {
          return false;
        }
        if (!field.isStructured && !field.config.prefillSpektrDataField) {
          return false;
        }

        return Object.values(field.form.fields).every((f) => {
          if (isWritableField(f)) {
            if (!f.config.prefillDefaultValue) {
              return true;
            }

            if (f.isStructured && !f.config.spektrDataField) {
              return false;
            }
            if (!f.isStructured && !f.config.prefillSpektrDataField) {
              return false;
            }

            return true;
          }
          return true;
        });
      }
      if (!field.config.prefillDefaultValue) {
        return true;
      }
      if (field.isStructured && !field.config.spektrDataField) {
        return false;
      }
      if (!field.isStructured && !field.config.prefillSpektrDataField) {
        return false;
      }
      return true;
    }
    return true;
  });
