import { useCallback } from 'react';

import { TableBody } from '../../Table';

import { CasesEmptyTableState, CasesTable } from '../CasesTable';

import { TableAlertData } from './TableAlertData';
import { AlertsTableRow } from './AlertsTableRow';
import { AlertsTableHeader } from './AlertsTableHeader';

export type AlertsTableProps = {
  alerts: TableAlertData[];
  columns?: string[];
  onRowClick: (id: string) => void;
};

export const AlertsTable = ({
  alerts,
  columns = ['date', 'alert', 'customer', 'spektrAI', 'status', 'actions'],
  onRowClick,
}: AlertsTableProps) => {
  const renderRow = useCallback(
    (row: TableAlertData, index: number) => {
      return (
        <AlertsTableRow
          key={index}
          alert={row}
          columns={columns}
          actions={row.actions}
          onActionClick={row.onActionClick}
          onRowClick={() => onRowClick(row.id)}
        />
      );
    },
    [columns, onRowClick]
  );

  return (
    <CasesTable>
      <AlertsTableHeader columns={columns} />
      <TableBody>
        {alerts.length > 0 ? (
          alerts.map(renderRow)
        ) : (
          <CasesEmptyTableState
            columnCount={columns.length}
            message="No alerts found"
          />
        )}
      </TableBody>
    </CasesTable>
  );
};
