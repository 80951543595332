import { useSuspenseQuery } from '@tanstack/react-query';

import { useParsedParams } from '@spektr/shared/hooks';
import { casesCustomerParamsSchema } from '@spektr/shared/utils';

import { cn } from '@spektr/style-utils';
import { getCustomerOwnersAndControllers } from '@spektr/client/services';

import { CustomerOwnershipBlock } from '../../components';

export const OwnershipList = () => {
  const { spektrId: customerId } = useParsedParams(casesCustomerParamsSchema);
  const { data } = useSuspenseQuery(
    getCustomerOwnersAndControllers(customerId)
  );
  const { owners, repsAndControllers } = data;

  return (
    <div className="flex h-full flex-col gap-4 overflow-y-auto">
      {/* TODO: @Alex - add this back when we get the error in the endpoint */}
      {/* {error && (
        <Banner type="error" iconName="TriangleAlert" message={error} />
      )} */}

      {!owners.length && !repsAndControllers.length && (
        <div
          className={cn(
            'flex items-center justify-center',
            'bg-color-bg-card-default',
            'border-color-border-primary rounded-md border p-6'
          )}
        >
          <p className="text-color-text-subtext text-sm">
            No ownership data available.
          </p>
        </div>
      )}
      {owners.length > 0 && (
        <CustomerOwnershipBlock label="Owners" ownershipList={owners} />
      )}
      {repsAndControllers.length > 0 && (
        <CustomerOwnershipBlock
          label="Controllers & Representatives"
          ownershipList={repsAndControllers}
        />
      )}
    </div>
  );
};
