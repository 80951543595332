import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { NotificationBadge } from '@spektr/client/components';
import { getProcessDependenciesQuery } from '@spektr/client/services';

import { FieldMappingButton } from './FieldMappingButton';

interface FieldMappingButtonWithBadgeProps {
  processId: string;
  destination: string;
}

export const FieldMappingButtonWithBadge = ({
  processId,
  destination,
}: FieldMappingButtonWithBadgeProps) => {
  const {
    data: { dependencies },
  } = useSuspenseQuery(getProcessDependenciesQuery(processId));

  const unmappedCustomFields = useMemo(() => {
    return dependencies.filter((dependency) => dependency.mapping === null)
      .length;
  }, [dependencies]);
  if (unmappedCustomFields > 0) {
    return (
      <NotificationBadge badgeContent={unmappedCustomFields}>
        <FieldMappingButton destination={destination} />
      </NotificationBadge>
    );
  }
  return <FieldMappingButton destination={destination} />;
};
