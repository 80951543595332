import { z } from 'zod';

import { PaginationFields } from '../pagination';
import { ClientRecordStatuses } from '../clientRecord';

export const timelineEventTypes = [
  'process_run',
  'alert_resolved',
  'alert_escalated',
  'new_document_added',
  'new_owner_created',
  'new_controller_created',
  'indicator_change',
  'customer_tag_add',
  'customer_tag_remove',
  'customer_status_change',
] as const;

export const Event = z.enum([...timelineEventTypes]);
export type Event = z.infer<typeof Event>;

export const eventBaseSchema = z.object({
  workspaceId: z.string(),
  spektrId: z.string(),
  type: Event,
  createdAt: z.number(),
});

export const processRunEventDataResponse = eventBaseSchema.merge(
  z.object({
    eventData: z.object({
      processType: z.string(),
      processName: z.string(),
      processId: z.string(),
      score: z.number(),
      contextId: z.string(),
      tags: z.array(z.object({ color: z.string(), label: z.string() })),
      processRunId: z.string().optional(),
    }),
  })
);
export type ProcessRunEventData = z.infer<typeof processRunEventDataResponse>;

export const clientRecordTagAddEventDataResponse = eventBaseSchema.merge(
  z.object({
    eventData: z.object({
      tag: z.object({ color: z.string(), label: z.string() }),
      action: z.literal('add'),
      userId: z.string().optional(),
      processId: z.string().optional(),
    }),
  })
);

export type ClientRecordTagAddEventData = z.infer<
  typeof clientRecordTagAddEventDataResponse
>;

export const clientRecordTagRemoveEventDataResponse = eventBaseSchema.merge(
  z.object({
    eventData: z.object({
      tag: z.object({ color: z.string(), label: z.string() }),
      action: z.literal('remove'),
      userId: z.string().optional(),
      processId: z.string().optional(),
    }),
  })
);

export type ClientRecordTagRemoveEventData = z.infer<
  typeof clientRecordTagRemoveEventDataResponse
>;

export const clientRecordStatusChangeEventDataResponse = eventBaseSchema.merge(
  z.object({
    eventData: z.object({
      oldStatus: z.optional(ClientRecordStatuses),
      newStatus: ClientRecordStatuses,
      customerName: z.string(),
      userId: z.string().optional(),
      processId: z.string().optional(),
      processName: z.string().optional(),
    }),
  })
);

export type ClientRecordStatusChangeEventData = z.infer<
  typeof clientRecordStatusChangeEventDataResponse
>;

export const alertResolvedEventDataResponse = eventBaseSchema.merge(
  z.object({
    eventData: z.object({
      processId: z.string(),
      contextId: z.string(),
      userId: z.string().optional(), //TODO: actions will be resolved by spektrAI at some point
      alertId: z.string(),
      alertName: z.string().optional(),
    }),
  })
);
export type AlertResolvedEventData = z.infer<
  typeof alertResolvedEventDataResponse
>;

export const timelineEvent = z.union([
  processRunEventDataResponse,
  alertResolvedEventDataResponse,
  clientRecordTagAddEventDataResponse,
  clientRecordTagRemoveEventDataResponse,
  clientRecordStatusChangeEventDataResponse,
]);

export type TimelineEvent = z.infer<typeof timelineEvent>;

export const timelineEventsPaginatedSchema = PaginationFields.merge(
  z.object({
    docs: z.array(timelineEvent),
  })
);

export type TimelineEventsPaginated = z.infer<
  typeof timelineEventsPaginatedSchema
>;
