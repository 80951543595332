import { useMemo } from 'react';
import { ListChecks, Siren } from 'lucide-react';

import { IconWithBackground } from '@spektr/client/components';

import { NodeType, ProcessType } from '@spektr/shared/validators';

import { DrawerItemType } from '../types/DrawerItemType';
import { getAvailableItemsForProcess } from '../utils/getAvailableItemsForProcess';

export const useAlerts = (processType?: ProcessType): DrawerItemType[] => {
  return useMemo(() => {
    const alerts: DrawerItemType[] = [
      {
        type: NodeType.enum.alert,
        title: 'Service Alert',
        variant: 'alert',
        icon: (
          <IconWithBackground className="bg-color-pink/20 h-10 w-10">
            <Siren className="stroke-color-pink h-5 w-5" />
          </IconWithBackground>
        ),
        description:
          'Configure alerts you want to receive when conditions are met.',
      },
      {
        type: NodeType.enum.manualReview,
        title: 'Manual Review',
        variant: 'red',
        icon: (
          <IconWithBackground className="bg-color-red/20">
            <ListChecks className="stroke-color-red h-6 w-6" />
          </IconWithBackground>
        ),
        description:
          'Create a manual review step with checklists to be crossed of in case management',
      },
    ];

    return getAvailableItemsForProcess(alerts, processType);
  }, [processType]);
};
