import { DividerVerticalIcon } from '@radix-ui/react-icons';

import {
  Button,
  DropdownMenuComboBox,
  DropdownOption,
} from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

import { CasesFilterConfiguration } from '../../types';

import { RemoveCasesFilterButton } from './RemoveCasesFilterButton';

const FILTER_OPTIONS_LIMIT = 2;

export type DropdownCasesFilterProps = {
  identifier: string;
  configuration: CasesFilterConfiguration;
  dropdownOptions: DropdownOption[];
  appliedFilterValues: React.ReactNode[];
  filtersWithNoDivider?: string[];
  handleChange: (filterIdentifier: string, filterValue: string) => void;
};

export const DropdownCasesFilter = ({
  identifier,
  dropdownOptions,
  appliedFilterValues,
  filtersWithNoDivider,
  configuration,
  handleChange,
}: DropdownCasesFilterProps) => {
  return (
    <div className="flex items-center">
      <DropdownMenuComboBox
        align="start"
        side="bottom"
        sideOffset={8}
        options={dropdownOptions}
        contentClassName={cn(
          'bg-color-bg-dropdown-secondary-content',
          'w-full min-w-60 max-w-80 max-h-[500px]',
          'shadow-md'
        )}
        itemClassName={cn(
          'hover:no-underline',
          'hover:bg-color-bg-dropdown-sub-trigger--focus/80 ',
          'focus:bg-color-bg-dropdown-sub-trigger--focus/80',
          'dark:focus:bg-color-bg-dropdown-sub-trigger--focus/40',
          'data-[state=open]:bg-color-bg-dropdown-sub-trigger--focus/80'
        )}
        trigger={
          <Button
            variant="contained"
            color="icon"
            className="text-color-text-subtext rounded-e-none border-r-0 text-sm font-medium"
            startIconClassName={cn(
              '[&_svg]:text-color-text-subtext [&_svg]:!mr-0',
              '[&_svg]:h-[18px] [&_svg]:w-[18px]'
            )}
            startIcon={configuration.icon}
          >
            <div className="flex items-center gap-2">
              {configuration.label + ':'}
              {appliedFilterValues
                ?.slice(0, FILTER_OPTIONS_LIMIT)
                .map((value, index) => (
                  <div
                    key={index}
                    className="text-color-text-primary flex items-center"
                  >
                    <div className="w-full max-w-80">{value}</div>
                    {configuration.selectionType === 'multiple' &&
                      !filtersWithNoDivider?.includes(identifier) &&
                      index !== FILTER_OPTIONS_LIMIT - 1 &&
                      index !== appliedFilterValues.length - 1 && (
                        <DividerVerticalIcon className="text-color-text-subtext h-5" />
                      )}
                  </div>
                ))}

              {appliedFilterValues.length > FILTER_OPTIONS_LIMIT && (
                <span className="bg-color-bg-accent rounded px-1.5">
                  +{appliedFilterValues.length - FILTER_OPTIONS_LIMIT}
                </span>
              )}
            </div>
          </Button>
        }
        onClick={(value) => handleChange(identifier, value)}
      />
      <RemoveCasesFilterButton
        onClick={() => handleChange(identifier, 'remove')}
      />
    </div>
  );
};
