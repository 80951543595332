import { ProcessApiClient } from '../client';
import {
  getRiskMatricesQueryKey,
  getRiskMatrixQueryKey,
  getAllRiskMatricesTemplatesQueryKey,
  getRiskMatrixTemplateQueryKey,
} from '../queryKeys';

export function getRiskMatricesQuery() {
  return {
    queryKey: getRiskMatricesQueryKey(),
    queryFn: () => ProcessApiClient.getClient().getAllRiskMatrices(),
  };
}

export function getRiskMatrixQuery(matrixId: string) {
  return {
    queryKey: getRiskMatrixQueryKey(matrixId),
    queryFn: () =>
      ProcessApiClient.getClient().getRiskMatrixById({
        params: { id: matrixId },
      }),
  };
}

export function getAllRiskMatricesTemplatesQuery() {
  return {
    queryKey: getAllRiskMatricesTemplatesQueryKey(),
    queryFn: () => ProcessApiClient.getClient().getAllRiskMatricesTemplates(),
  };
}

export function getRiskMatrixTemplateQuery(templateId: string) {
  return {
    queryKey: getRiskMatrixTemplateQueryKey(templateId),
    queryFn: () =>
      ProcessApiClient.getClient().getRiskMatrixTemplateById({
        params: { id: templateId },
      }),
  };
}
