import { useLayoutEffect, useState } from 'react';
import { useMeasure } from 'react-use';
import {
  MarkerType,
  ReactFlowProvider,
  Viewport,
  type Edge,
  type Node,
} from 'reactflow';

import 'reactflow/dist/style.css';

import { NODE_HEIGHT, NODE_WIDTH } from '../../constants/graph';

import { ConnectionsGraph } from '../../components/ConnectionsGraph';

import { type NodeData } from '../../types/NodeData';
import { type EdgeData } from '../../types/EdgeData';

const initialNodes = [
  {
    id: '1',
    type: 'skeletonNode',
    data: {},
    position: { x: 0, y: 0 },
  },
  {
    id: '2',
    type: 'skeletonNode',
    data: {},
    position: { x: 0, y: 0 },
  },
  {
    id: '3',
    type: 'skeletonNode',
    data: {},
    position: { x: 0, y: 0 },
  },
  {
    id: '4',
    type: 'skeletonNode',
    data: {},
    position: { x: 0, y: 0 },
  },
];

const initialEdges = [
  {
    id: '1-2',
    source: '1',
    target: '2',
    type: 'skeletonEdge',
    data: {
      label: '',
    },
    animated: false,
    arrowHeadType: MarkerType.Arrow,
  },
  {
    id: '2-3',
    source: '2',
    target: '3',
    type: 'skeletonEdge',
    data: {
      label: '',
    },
    animated: false,
    arrowHeadType: MarkerType.Arrow,
  },
  {
    id: '2-4',
    source: '2',
    target: '4',
    type: 'skeletonEdge',
    data: {
      label: '',
    },
    animated: false,
    arrowHeadType: MarkerType.Arrow,
  },
];

export const SkeletonGraphWrapper = () => {
  const [canvasRef, { height, width }] = useMeasure<HTMLDivElement>();
  const [viewPort, setViewPort] = useState<Viewport | undefined>(undefined); // we cannot use `height` and `width` here on first render bc they are both 0 on first render

  useLayoutEffect(() => {
    if (!height || !width) return;

    // set initial viewport position to be center of canvas
    setViewPort((prev) => ({
      zoom: prev?.zoom ?? 1,
      x: width * 0.5 - NODE_WIDTH * 0.5,
      y: height * 0.5 - NODE_HEIGHT * 0.5,
    }));
  }, [height, width]);

  return (
    <div ref={canvasRef} className="h-[695px] w-full">
      <ReactFlowProvider>
        <ConnectionsGraph
          spektrId="2"
          defaultViewPort={viewPort}
          initialNodes={initialNodes as Node<NodeData>[]}
          initialEdges={initialEdges as Edge<EdgeData>[]}
          onMoveEnd={setViewPort}
        />
      </ReactFlowProvider>
    </div>
  );
};
