import { Suspense, lazy, useEffect, useState } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import {
  CHANGE_PASSWORD_URL,
  CONFIRM_RESET_PASSWORD_URL,
  DASHBOARD_URL,
  LOGIN_URL,
  RESET_PASSWORD_URL,
  STRATEGY_PAGE_URL,
} from '@spektr/shared/utils';
import { ErrorPage, GlobalErrorPage } from '@spektr/shared/components';
import { SpektrErrorBoundary, ToastProvider } from '@spektr/client/components';
import { DEFAULT_THEME, ThemeProvider } from '@spektr/client/providers';
import { handleAxiosError } from '@spektr/client/utils';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ReactQueryDevtoolsProduction } from './components/devtools';

import {
  RootLayout,
  RootPage,
  processBuilderRoute,
  loopsRoutes,
} from './routes';
import { AuthProvider } from './auth/auth-context';
import { AuthLayout, ProtectedRoute } from './components';

import changePasswordAction from './routes/ChangePassword.action';
import confirmResetPasswordAction from './routes/ConfirmResetPassword.action';
import resetPasswordAction from './routes/ResetPassword.action';

import rootLoader from './routes/Root.loader';
import { connectionHubRoutes } from './routes/connection-hub';

import { queryClient } from './queryClient';
import { settingsRoutes } from './routes/settings';
import LaunchDarklyConfig from './config/launchDarkly';
import { StrategyPage } from './routes/StrategyPage';

import './config/i18n';
import { casesRoutes } from './routes/cases';

const LoginPage = lazy(
  () => import(/* webpackChunkName: "LoginPage" */ './routes/Login')
);
const ChangePasswordPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ChangePasswordPage" */ './routes/ChangePassword'
    )
);
const ConfirmResetPasswordPage = lazy(
  () =>
    import(
      /* webpackChunkName: "ConfirmResetPasswordPage" */ './routes/ConfirmResetPassword'
    )
);
const ResetPasswordPage = lazy(
  () =>
    import(/* webpackChunkName: "ResetPasswordPage" */ './routes/ResetPassword')
);

const router = createBrowserRouter([
  {
    path: DASHBOARD_URL,
    element: <RootLayout />,
    loader: rootLoader,
    children: [
      {
        element: <AuthLayout />,
        children: [
          {
            path: LOGIN_URL,
            element: <LoginPage />,
            handle: {
              title: 'Sign in',
            },
          },
          {
            path: RESET_PASSWORD_URL,
            element: <ResetPasswordPage />,
            action: resetPasswordAction,
            handle: {
              title: 'Reset password',
            },
          },
          {
            path: CONFIRM_RESET_PASSWORD_URL,
            element: <ConfirmResetPasswordPage />,
            action: confirmResetPasswordAction,
            handle: {
              title: 'Reset password',
            },
          },
          {
            path: CHANGE_PASSWORD_URL,
            element: <ChangePasswordPage />,
            action: changePasswordAction,
            handle: {
              title: 'Reset password',
            },
          },
        ],
      },
      {
        element: <ProtectedRoute />,
        errorElement: <GlobalErrorPage />,
        children: [
          {
            index: true,
            element: <RootPage />,
            loader: rootLoader,
          },
          processBuilderRoute,
          connectionHubRoutes,
          loopsRoutes,
          settingsRoutes,
          casesRoutes,
          {
            path: STRATEGY_PAGE_URL,
            element: <StrategyPage />,
          },
        ],
      },
    ],
  },
]);

const App = () => {
  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    window.devtools = () => setShowDevtools((prev) => !prev);
  }, []);

  return (
    <SpektrErrorBoundary
      fallbackRender={(error) => (
        <ErrorPage
          title={handleAxiosError(
            error,
            'Something went wrong when loading the loop details.'
          )}
        />
      )}
    >
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ThemeProvider defaultTheme={DEFAULT_THEME}>
            <RouterProvider router={router} />
            <ToastProvider />
            <ReactQueryDevtools />
            {showDevtools && (
              <Suspense fallback={null}>
                <ReactQueryDevtoolsProduction initialIsOpen={true} />
              </Suspense>
            )}
          </ThemeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </SpektrErrorBoundary>
  );
};

export default withLDProvider(LaunchDarklyConfig)(App);
