import { useController, useFormContext } from 'react-hook-form';

import { Input, type InputProps } from '@spektr/client/components';

import { SlackFieldRow } from '../SlackFieldRow';

export namespace SlackInputField {
  export type Props = InputProps & {
    name: string;
    isRequired?: boolean;
    title: string;
  };
}

export const SlackInputField = ({
  name,
  isRequired = false,
  title,
  ...props
}: SlackInputField.Props) => {
  const context = useFormContext();
  const { field } = useController({
    name,
    control: context.control,
    rules: {
      required: isRequired,
    },
  });

  return (
    <SlackFieldRow isRequired={isRequired} title={title}>
      <Input {...field} {...props} dimension="small" />
    </SlackFieldRow>
  );
};
