import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

const allowedPageValues = [10, 20, 50];
const pageLimitSchema = z
  .number()
  .refine((value) => allowedPageValues.includes(value), {
    message: 'Value must be 10, 20, or 50',
  })
  .default(10);

export const usePaginationOptions = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPageNumber] = useState(
    Number(searchParams.get('page') ?? '1')
  );
  const [pageLimit, setPageLimit] = useState(
    pageLimitSchema.parse(Number(searchParams.get('limit') ?? '10'))
  );
  const [searchValue, setSearch] = useState(searchParams.get('search') ?? '');

  const setPage = (pageNumber: number) => {
    if (pageNumber !== page) {
      setPageNumber(pageNumber);
      setSearchParams((params) => ({
        ...Object.fromEntries(params.entries()),
        page: pageNumber.toString(),
      }));
    }
  };

  const setLimit = (limit: number) => {
    if (limit !== pageLimit) {
      setPageLimit(limit);
      setPage(1);
      setSearchParams((params) => ({
        ...Object.fromEntries(params.entries()),
        page: '1',
        limit: limit.toString(),
      }));
    }
  };

  const setSearchValue = (searchQuery: string) => {
    setPageNumber(1);
    setSearch(searchQuery);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      page: '1',
      search: searchQuery,
    });
  };

  useEffect(() => {
    if (!searchParams.get('page') || !searchParams.get('limit')) {
      setSearchParams((params) => ({
        ...Object.fromEntries(params.entries()),
        page: page.toString(),
        limit: pageLimit.toString(),
      }));
    }
  }, [page, pageLimit, searchParams, setSearchParams]);

  return {
    page,
    pageLimit,
    searchParams,
    searchValue,
    setPage,
    setLimit,
    setSearchValue,
  };
};
