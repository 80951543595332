import { CustomerIcon, IconWithBackground } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';
import { ClientRecordDetails } from '@spektr/shared/validators';

export type CustomerTopbarInfoProps = {
  customer: ClientRecordDetails;
};

export const CustomerTopbarInfo = ({ customer }: CustomerTopbarInfoProps) => {
  return (
    <div className="flex items-center gap-3">
      <IconWithBackground
        className={cn(
          'bg-color-bg-secondary dark:bg-color-bg-avatar',
          'text-color-text-icon-user',
          'h-9 w-9 shrink-0 rounded-full'
        )}
      >
        <CustomerIcon type={customer.customerType} className="h-4 w-4" />
      </IconWithBackground>
      <div className="flex flex-col items-start">
        <p>{customer.name}</p>
        <span className="text-color-text-subtext text-sm">
          {customer.datasetName}
        </span>
      </div>
    </div>
  );
};
