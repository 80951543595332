import { PaginationOptions } from '@spektr/shared/validators';

import { RecordFilters } from './types';

export function getConnectionStatsQueryKey() {
  return ['connection-hub', 'stats'];
}

export function getAllSpektrFieldsQueryKey() {
  return ['spektr-fields'];
}

export const DATASETS_KEYS = {
  ALL: () => ['datasets', 'list'] as const,
  BY_ID: (datasetId: string) => ['datasets', 'details', datasetId] as const,
  ALL_RECORDS: (datasetId: string, filters?: RecordFilters) => {
    const key = ['datasets', 'details', datasetId, 'records', 'list'] as const;

    return filters ? ([...key, filters] as const) : key;
  },
  RECORD_BY_ID: (datasetId: string, recordId: string) =>
    ['datasets', 'details', datasetId, 'records', 'details', recordId] as const,
  ALL_RUNS: (
    datasetId: string,
    recordId: string,
    filters?: PaginationOptions
  ) => {
    const key = [
      'datasets',
      'details',
      datasetId,
      'records',
      'details',
      recordId,
      'runs',
    ] as const;

    return filters ? ([...key, filters] as const) : key;
  },
  ALL_ALERTS: (
    datasetId: string,
    recordId: string,
    filters?: PaginationOptions
  ) => {
    const key = [
      'datasets',
      'details',
      datasetId,
      'records',
      'details',
      recordId,
      'alerts',
    ] as const;

    return filters ? ([...key, filters] as const) : key;
  },
};
