import { cn } from '@spektr/style-utils';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Input,
} from '@spektr/client/components';

import { useIncompleteRouterNode, useIncompleteRuleGroup } from '../providers';
import { RuleGroupBody, RuleGroupCard } from '../ruleGroup';

import { RuleGroupActions } from './components';

export const RoutingRule = () => {
  const { updateRuleGroup } = useIncompleteRouterNode();
  const { group } = useIncompleteRuleGroup();

  return (
    <Accordion
      key={group.clientSideOnlyId}
      collapsible
      defaultValue={`group-${group.clientSideOnlyId}`}
      type="single"
      className="w-full overflow-y-auto"
    >
      <AccordionItem
        value={`group-${group.clientSideOnlyId}`}
        className="relative border-none"
      >
        <RuleGroupCard>
          <div className="relative h-12">
            <AccordionTrigger
              className="relative hover:no-underline"
              iconClassName="text-color-text-icon-primary z-[1]"
            />
            <Input
              name="ast-node-title"
              className={cn(
                'w-80 rounded-md bg-transparent pl-1',
                'absolute left-10 top-1/2 -translate-y-1/2 transform',
                'text-color-text-input text-sm',
                'border-none hover:border focus:border',
                'hover:border-color-border-input focus:border-color-border-input--focus'
              )}
              value={group.title ?? ''}
              onChange={(event) =>
                updateRuleGroup({
                  clientSideOnlyId: group.clientSideOnlyId,
                  title: event.target.value,
                })
              }
            />
            <RuleGroupActions />
          </div>
          <AccordionContent>
            <RuleGroupBody />
          </AccordionContent>
        </RuleGroupCard>
      </AccordionItem>
    </Accordion>
  );
};
