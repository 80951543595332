import { ReactNode } from 'react';
import { cva } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

import { Skeleton } from '../Skeleton';

import { CardItemSchema } from './types';

type CardItemProps = CardItemSchema & {
  className?: string;
  endIcon?: ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  renderAction?: () => ReactNode;
};

export const CardItem = ({
  className,
  description,
  icon,
  isDisabled = false,
  isLoading = false,
  title,
  renderAction,
  onClick,
}: CardItemProps) => {
  if (isLoading) {
    return (
      <div
        className={cn(
          'flex h-[66px] w-full items-center p-4',
          'hover:bg-color-bg-card--hover',
          'border-b last:rounded-b last:border-0',
          'transition-colors',
          className
        )}
      >
        <div className="flex h-9 w-9 shrink-0">
          <Skeleton className="h-full w-full p-2" />
        </div>
        <div className="grow-1 ml-4 flex w-full flex-col">
          <Skeleton className="mb-2 h-3 w-28" />
          <Skeleton className="h-3 w-36" />
        </div>
      </div>
    );
  }

  const Comp = onClick ? 'button' : 'div';
  const buttonProps = onClick
    ? {
        disabled: isDisabled,
        onClick,
      }
    : {};

  return (
    <Comp
      className={cn(
        'gap-3',
        variants({ state: isDisabled ? 'disabled' : 'active' }),
        className
      )}
      {...buttonProps}
    >
      <div className="flex h-9 w-9 shrink-0">{icon}</div>
      <div className="grow-1 flex w-full flex-col">
        <span className="line-clamp-2 overflow-hidden text-ellipsis break-all text-xs">
          {title}
        </span>
        <span className="text-color-text-primary/40 line-clamp-1 text-xs">
          {description}
        </span>
      </div>
      {Comp === 'div' && (
        <div className="relative">{renderAction && renderAction()}</div>
      )}
    </Comp>
  );
};

const variants = cva(
  [
    'flex h-[66px] w-full items-center p-4',
    'border-b last:rounded-b last:border-0',
    'text-color-text-primary text-left',
    'transition-colors',
  ],
  {
    variants: {
      state: {
        disabled:
          'bg-color-bg-card--disabled cursor-not-allowed text-color-text-primary/40',
        active: 'bg-color-bg-card--active hover:bg-color-bg-card--hover',
      },
    },
    defaultVariants: {
      state: 'active',
    },
  }
);
