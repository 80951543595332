import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Brush, CircleCheck, Pencil, Trash } from 'lucide-react';

import { Tag as TagType, TagCreate } from '@spektr/shared/validators';

import { cn, isRgbaString } from '@spektr/style-utils';
import {
  BasicDialog,
  Button,
  ColorPicker,
  Input,
  Tag,
  Tooltip,
} from '@spektr/client/components';

import { useCreateTag, useDeleteTag } from './hooks';

type TagCreationDialogProps = {
  selectedTag?: TagType;
  tags: TagType[];
  onUpdateTag: (tagId: string) => void;
  onClose: () => void;
};

const TagCreateValidationSchema = z.object({
  label: z.string().refine((value) => value.trim().length > 0, {
    message: 'Tag label is required.',
  }),
  color: z.string().refine(isRgbaString, {
    message: 'Color should have a valid rgba value.',
  }),
});

export const TagCreationDialog = ({
  selectedTag,
  tags,
  onUpdateTag,
  onClose,
}: TagCreationDialogProps) => {
  const {
    register,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<TagCreate>({
    defaultValues: {
      label: '',
      color: 'rgba(0, 0, 0, 1)',
    },
    resolver: zodResolver(TagCreateValidationSchema),
  });
  const createTag = useCreateTag();
  const removeTag = useDeleteTag();

  const availableTags = useMemo(
    () =>
      tags
        .filter((tag) => tag.id === selectedTag?.id || !tag.isDeleted)
        .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
    [tags, selectedTag]
  );

  const submitCallback = async (tag: TagCreate) => {
    await createTag.mutateAsync(tag);
    reset();
  };

  return (
    <BasicDialog
      title="Manage tags"
      dialogHeaderClassName="mb-6"
      className="max-w-[720px] rounded-2xl border-0"
      defaultOpen
      onClose={onClose}
    >
      <form
        className="flex items-start gap-4"
        onSubmit={handleSubmit(submitCallback)}
      >
        <div className="flex flex-1 flex-col gap-2">
          <Input
            {...register('label', { required: true })}
            error={!!errors?.label}
            startIcon={
              <Pencil className="text-color-text-subtext mr-2 h-4 w-4 shrink-0" />
            }
            placeholder="Label name"
          />
          {errors?.label && (
            <span className={cn('text-color-red text-xs')}>
              {errors.label.message}
            </span>
          )}
        </div>
        <div className="flex flex-1 flex-col gap-2">
          <Controller
            control={control}
            rules={{ required: true }}
            name="color"
            render={({ field }) => (
              <ColorPicker
                {...field}
                startIcon={
                  <Brush className="text-color-text-subtext mr-2 h-4 w-4 shrink-0" />
                }
                error={!!errors?.color}
                value={field.value}
                onColorChange={field.onChange}
              />
            )}
          />
          {errors?.color && (
            <span className={cn('text-color-red text-xs')}>
              {errors.color.message}
            </span>
          )}
        </div>
        <Button type="submit">Add</Button>
      </form>

      <div className="mt-6 grid max-h-44 grid-cols-2 gap-4 overflow-y-auto">
        {availableTags.map((tag, index) => {
          const isSelected = tag.id === selectedTag?.id;

          return (
            <div
              key={index}
              className={cn(
                'group',
                'flex items-center',
                'dark:border-color-border-default rounded-md border border-transparent',
                'bg-color-bg-card-default hover:bg-color-bg-card--hover',
                'text-sm',
                'overflow-hidden',
                isSelected && '!border-color-cyan',
                tag.isDeleted ? 'opacity-50' : 'opacity-100'
              )}
            >
              <Tooltip
                content={`${isSelected ? 'Unnassign' : 'Assign'} tag ${isSelected ? 'from' : 'to'} outcome.`}
                tooltipTriggerProps={{ asChild: true }}
              >
                <button
                  type="button"
                  onClick={() => {
                    onUpdateTag(tag.id);
                    onClose();
                  }}
                  key={index}
                  className="flex flex-1 items-center gap-2 px-4 py-3"
                >
                  <Tag
                    color={tag.color}
                    label={tag.label}
                    isDeleted={tag.isDeleted}
                  />
                  {isSelected && (
                    <CircleCheck className="text-color-cyan ml-auto h-4 w-4" />
                  )}
                </button>
              </Tooltip>
              {!tag.isDeleted && (
                <div
                  className={cn(
                    'ml-auto h-full w-0',
                    'group-hover:w-11',
                    'transition-all duration-200'
                  )}
                >
                  <Button
                    type="button"
                    variant="text"
                    className={cn(
                      'h-full w-full shrink-0',
                      'rounded-l-none',
                      'text-color-red hover:text-color-red',
                      'bg-color-red/20 hover:bg-color-red/30'
                    )}
                    onClick={async (e) => {
                      e.stopPropagation();
                      await removeTag.mutateAsync(tag.id);
                    }}
                  >
                    <Trash className="h-3.5 w-3.5" />
                  </Button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </BasicDialog>
  );
};
