import { cn } from '@spektr/style-utils';

type CasesFilterLabelProps = {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
};

export const CasesFilterLabel = ({
  icon,
  label,
  onClick,
}: CasesFilterLabelProps) => {
  return (
    <div
      className={cn(
        'bg-color-bg-button-icon dark:bg-color-bg-button-icon/20',
        'text-color-text-subtext',
        'flex items-center',
        'border-color-border-primary',
        'rounded-s-md border-y border-s',
        'h-9 gap-2 pl-4 pr-2',
        'cursor-pointer'
      )}
      onClick={onClick}
    >
      <span className="[&_svg]:h-[18px] [&_svg]:w-[18px]">{icon}</span>
      <span className="text-sm font-medium">{label}:</span>
    </div>
  );
};
