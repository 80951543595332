import { useState } from 'react';
import { useSlate } from 'slate-react';
import { Link2 } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { insertLink, isLinkActive } from '../utils/link';

import { Button } from '../../Button';

import { LinkFormatDialog } from './LinkFormatDialog';

export const AddLinkButton = () => {
  const editor = useSlate();
  const [showUrlFormatDialog, setShowUrlFormatDialog] = useState(false);

  return (
    <>
      <Button
        type="button"
        variant="text"
        size="sm"
        className={cn(
          'hover:bg-color-bg-button-secondary--hover',
          isLinkActive(editor) && 'bg-color-bg-button-secondary--active'
        )}
        onMouseDown={() => setShowUrlFormatDialog(true)}
      >
        <Link2 className="h-3 w-3" />
      </Button>
      {showUrlFormatDialog && (
        <LinkFormatDialog
          onClose={() => setShowUrlFormatDialog(false)}
          onSave={(url) => insertLink(editor, url)}
        />
      )}
    </>
  );
};
