import { useRef } from 'react';

import { trim } from 'lodash';

import { BasicDialog, Button, Textarea } from '@spektr/client/components';

export type ResolveAlertDialogProps = {
  onResolve: (reason?: string) => void;
  onCancel: () => void;
  areActionsDisabled?: boolean;
};

export const ResolveAlertDialog = ({
  onResolve,
  onCancel,
  areActionsDisabled = false,
}: ResolveAlertDialogProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleOnResolve = () => {
    onResolve(trim(textareaRef.current?.value));
  };

  const handleTextareaKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter' && event.ctrlKey) {
      handleOnResolve();
    }
  };

  return (
    <BasicDialog
      title="You have unreviewed hits"
      dialogHeaderClassName="mb-2"
      className="w-[512px] rounded-2xl border-0"
      defaultOpen
      onClose={onCancel}
      showCloseButton={false}
    >
      <div className="flex flex-col gap-4">
        <p className="text-color-text-subtext text-sm font-normal leading-5">
          You want to resolve this alert but you have not review every hits or
          changes. Please add a comment to all those hits before continuing?
        </p>

        <Textarea
          ref={textareaRef}
          id="resolve-alert-textarea"
          placeholder="Add a comment to all hits that are still to be reviewed"
          onKeyDown={(event) => handleTextareaKeyDown(event)}
        />

        <div className="ml-auto flex flex-row gap-4">
          <Button
            variant="text"
            onClick={() => onCancel()}
            disabled={areActionsDisabled}
          >
            Cancel
          </Button>
          <Button
            color="white"
            onClick={() => handleOnResolve()}
            disabled={areActionsDisabled}
          >
            Continue
          </Button>
        </div>
      </div>
    </BasicDialog>
  );
};
