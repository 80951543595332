import { z } from 'zod';
import { ZodiosEndpointDefinition, makeApi, makeEndpoint } from '@zodios/core';

import {
  updateNodeSchema,
  createNodeSchema,
  processNodeSchema,
  FieldsSuggestionsInput,
} from '../node';
import { SpektrFieldWithMappings, objectIdSchema } from '../common';
import { queryResponseSchema } from '../grpc';

/**
 * TODO: Add errors: see issue #175 for more
 */

const getNodeById = makeEndpoint({
  method: 'get',
  path: '/processes/:processId/nodes/:nodeId',
  alias: 'getNodeById',
  parameters: [
    {
      name: 'processId',
      type: 'Path',
      description: 'The id of the process the node belongs to',
      schema: objectIdSchema,
    },
    {
      name: 'nodeId',
      type: 'Path',
      description: 'The id of the node to fetch',
      schema: objectIdSchema,
    },
  ],
  response: processNodeSchema,
});

const getAllowedSpektrfieldsForNode = makeEndpoint({
  method: 'get',
  path: '/processes/:processId/nodes/:nodeId/fields',
  alias: 'getAllowedSpektrfieldsForNode',
  parameters: [
    {
      name: 'processId',
      type: 'Path',
      description: 'The id of the process the node belongs to',
      schema: objectIdSchema,
    },
    {
      name: 'nodeId',
      type: 'Path',
      description: 'The id of the node to fetch',
      schema: objectIdSchema,
    },
  ],
  response: z.array(SpektrFieldWithMappings),
});

const computeNodeFieldsSuggestions = makeEndpoint({
  method: 'post',
  path: '/processes/fields-suggestions',
  alias: 'computeNodeFieldsSuggestions',
  parameters: [
    {
      name: 'fieldsSuggestionsInput',
      type: 'Body',
      schema: FieldsSuggestionsInput,
    },
  ],
  response: queryResponseSchema,
});

// TODO: find a way to avoid redundancy (see issue #478 for details)
export interface UpdateNodeByIdEndpointDefinition
  extends ZodiosEndpointDefinition {
  method: 'put';
  path: '/processes/:processId/nodes/:nodeId';
  alias: 'updateNodeById';
  parameters: [
    {
      name: 'processId';
      type: 'Path';
      description: 'The id of the process the node belongs to';
      schema: typeof objectIdSchema;
    },
    {
      name: 'nodeId';
      type: 'Path';
      description: 'The id of the node to update';
      schema: typeof objectIdSchema;
    },
    {
      name: 'nodeInput';
      type: 'Body';
      schema: typeof updateNodeSchema;
    },
  ];
  response: typeof processNodeSchema;
}

const updateNodeById: UpdateNodeByIdEndpointDefinition = makeEndpoint({
  method: 'put',
  path: '/processes/:processId/nodes/:nodeId',
  alias: 'updateNodeById',
  parameters: [
    {
      name: 'processId',
      type: 'Path',
      description: 'The id of the process the node belongs to',
      schema: objectIdSchema,
    },
    {
      name: 'nodeId',
      type: 'Path',
      description: 'The id of the node to update',
      schema: objectIdSchema,
    },
    {
      name: 'nodeInput',
      type: 'Body',
      schema: updateNodeSchema,
    },
  ],
  response: processNodeSchema,
});

export interface CreateNodeEndpointDefinition extends ZodiosEndpointDefinition {
  method: 'post';
  path: '/processes/:processId/nodes';
  alias: 'createNode';
  parameters: [
    {
      name: 'processId';
      type: 'Path';
      description: 'The id of the process the node belongs to';
      schema: typeof objectIdSchema;
    },
    {
      name: 'nodeInput';
      type: 'Body';
      schema: typeof createNodeSchema;
    },
  ];
  response: typeof processNodeSchema;
}

const createNode: CreateNodeEndpointDefinition = makeEndpoint({
  method: 'post',
  path: '/processes/:processId/nodes',
  alias: 'createNode',
  parameters: [
    {
      name: 'processId',
      type: 'Path',
      description: 'The id of the process the node belongs to',
      schema: objectIdSchema,
    },
    {
      name: 'nodeInput',
      type: 'Body',
      schema: createNodeSchema,
    },
  ],
  response: processNodeSchema,
});

const deleteNodeById = makeEndpoint({
  method: 'delete',
  path: '/processes/:processId/nodes/:nodeId',
  alias: 'deleteNodeById',
  parameters: [
    {
      name: 'processId',
      type: 'Path',
      description: 'The id of the process the node belongs to',
      schema: objectIdSchema,
    },
    {
      name: 'nodeId',
      type: 'Path',
      description: 'The id of the node to delete',
      schema: objectIdSchema,
    },
  ],
  response: z.void(),
});

export const nodesApi = makeApi([
  createNode,
  updateNodeById,
  getNodeById,
  getAllowedSpektrfieldsForNode,
  computeNodeFieldsSuggestions,
  deleteNodeById,
]);
