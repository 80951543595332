import { useCallback, useState } from 'react';
import { List, Trash2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  AlertDialog,
  DropdownOption,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@spektr/client/components';
import { cn } from '@spektr/style-utils';
import { useDeleteCustomer } from '@spektr/platform-hooks';
import { ClientRecord } from '@spektr/shared/validators';

import { CustomersTableRow } from '../CustomersTableRow';

const baseThClasses = 'text-xs text-color-text-table-header p-3';
const baseTrClasses = cn(
  'bg-color-bg-table-row dark:bg-color-bg-table-row/20',
  'hover:bg-color-bg-card--hover',
  'rounded-md border dark:border-transparent'
);
const baseTcClasses =
  'border-color-border-primary border rounded-md text-sm text-center px-6 py-4';

const ACTIONS: DropdownOption[] = [
  {
    type: 'item',
    label: 'View alerts',
    icon: <List className="h-4 w-4" />,
    value: 'viewAlerts',
    disabled: true,
  },
  {
    type: 'separator',
    value: 'separator-2',
  },
  {
    type: 'item',
    label: 'Delete',
    icon: <Trash2 className="h-4 w-4" />,
    value: 'delete',
    variant: 'danger',
  },
];

export type CustomersTableProps = {
  customers: ClientRecord[];
  columns?: string[];
  onRowClick: (spektrId: string) => void;
};

export const CustomersTable = ({
  customers,
  columns = ['customer', 'tags', 'unresolvedAlerts', 'status'],
  onRowClick,
}: CustomersTableProps) => {
  const { t } = useTranslation('cases');
  const [deleteCustomerId, setDeleteCustomerId] = useState('');
  const deleteCustomer = useDeleteCustomer(() => setDeleteCustomerId(''));

  const handleDeleteCustomer = async () => {
    await deleteCustomer.mutateAsync(deleteCustomerId);
  };

  const renderRow = useCallback(
    (row: ClientRecord, index: number) => {
      return (
        <CustomersTableRow
          actions={ACTIONS}
          customer={row}
          columns={columns}
          key={index}
          onRowClick={onRowClick}
          onDelete={() => setDeleteCustomerId(row.spektrId)}
        />
      );
    },
    [columns, onRowClick]
  );

  return (
    <>
      <Table className="border-separate border-spacing-y-2 bg-transparent">
        <TableHeader>
          <TableRow className="hover:bg-inherit">
            {columns.map((column) => (
              <TableHead key={column} className={baseThClasses}>
                {t(`customers.columns.${column}`)}
              </TableHead>
            ))}
            <TableHead className={baseThClasses} />
          </TableRow>
        </TableHeader>
        <TableBody>
          {customers.length > 0 ? (
            customers.map(renderRow)
          ) : (
            <TableRow className={baseTrClasses}>
              <TableCell colSpan={columns.length + 1} className={baseTcClasses}>
                No customers found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <AlertDialog
        open={!!deleteCustomerId}
        title="Delete customer"
        paragraph="Are you sure you want to delete this customer? You can not revert this action and it will cascade."
        onCancel={() => setDeleteCustomerId('')}
        cancel="Cancel"
        onConfirm={handleDeleteCustomer}
        confirm="Delete"
        disableButtons={deleteCustomer.isPending}
      />
    </>
  );
};
