import { AlertTriangle, BookDashed, Check, CircleDashed } from 'lucide-react';

import {
  ClientRecordStatus,
  ClientRecordStatuses,
} from '@spektr/shared/validators';

const customerStatuses = ClientRecordStatuses.Values;

export const customerStatusIcon: { [key: string]: React.ReactNode } = {
  [customerStatuses.approved]: <Check className="h-2.5 w-2.5" />,
  [customerStatuses.pending]: <BookDashed className="h-2.5 w-2.5" />,
  [customerStatuses.rejected]: <AlertTriangle className="h-2.5 w-2.5" />,
  default: <CircleDashed className="h-2.5 w-2.5" />,
};

export const getCustomerStatusIcon = (
  status?: ClientRecordStatus
): React.ReactNode =>
  status ? customerStatusIcon[status] : customerStatusIcon['default'];

export const customerStatusVariant: {
  [key: string]: 'default' | 'success' | 'warning' | 'error' | null;
} = {
  [customerStatuses.approved]: 'success',
  [customerStatuses.pending]: 'warning',
  [customerStatuses.rejected]: 'error',
  default: 'default',
};

export const getCustomerStatusVariant = (
  status?: ClientRecordStatus
): 'default' | 'success' | 'warning' | 'error' | null =>
  status ? customerStatusVariant[status] : customerStatusVariant['default'];
