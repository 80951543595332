import { CasesTableHeader } from '../CasesTable/CasesTableHeader';
import { CasesTableHeadCell } from '../CasesTable/CasesTableHeadCell';

type AlertsTableHeaderProps = {
  columns: string[];
};

export const AlertsTableHeader = ({ columns }: AlertsTableHeaderProps) => {
  return (
    <CasesTableHeader>
      {columns.includes('date') && (
        <CasesTableHeadCell className="px-0">Age</CasesTableHeadCell>
      )}
      {columns.includes('alert') && (
        <CasesTableHeadCell>Alert</CasesTableHeadCell>
      )}
      {columns.includes('customer') && (
        <CasesTableHeadCell>Customer</CasesTableHeadCell>
      )}
      {columns.includes('alertType') && (
        <CasesTableHeadCell>Type</CasesTableHeadCell>
      )}
      {columns.includes('spektrAI') && (
        <CasesTableHeadCell>Spektr AI</CasesTableHeadCell>
      )}
      {columns.includes('status') && (
        <CasesTableHeadCell>Status</CasesTableHeadCell>
      )}
      {columns.includes('actions') && <CasesTableHeadCell></CasesTableHeadCell>}
    </CasesTableHeader>
  );
};
