import { forwardRef, useMemo } from 'react';

import { cn } from '@spektr/style-utils';

import { Button } from '@spektr/client/components';

import { DropArea } from '../DropArea';
import { CanvasField } from '../CanvasField';
import { CanvasFieldFactory } from '../CanvasFieldFactory';

import type { Field } from '@spektr/moonraker-types';

export type FormViewProps = {
  canDrop?: boolean;
  isOver?: boolean;
  fields: Record<string, Field>;
  order: string[];
  selectedFieldsId: string | null;
  width: string;
  onClone: (field: string) => void;
  onDelete: (id: string) => void;
  onSelect: (id: string) => void;
  onReorder: (sourceIndex: number, destinationIndex: number) => void;
};

export const FormView = forwardRef<HTMLDivElement, FormViewProps>(
  (
    {
      canDrop,
      isOver,
      fields,
      order,
      selectedFieldsId,
      width,
      onClone,
      onDelete,
      onSelect,
      onReorder,
    },
    ref
  ) => {
    const isActive = canDrop && isOver;

    const orderedFields = useMemo(
      () => order.map((id) => fields[id]),
      [fields, order]
    );

    return (
      <div
        className={cn(
          'flex flex-col',
          'min-h-60 py-6',
          'rounded-md',
          'border dark:border-none',
          'bg-color-bg-moonraker',
          'shadow-md',
          'transition-all',
          width
        )}
      >
        {orderedFields.map((field, index) => (
          <CanvasField
            key={field.id}
            field={field}
            index={index}
            isSelected={selectedFieldsId === field.id}
            onClone={() => onClone(field.id)}
            onClick={() => onSelect(field.id)}
            onDelete={() => onDelete(field.id)}
            onReorder={onReorder}
          >
            <CanvasFieldFactory field={field} />
          </CanvasField>
        ))}
        <DropArea
          ref={ref}
          isActive={isActive}
          canDrop={canDrop}
          canvasLength={order.length}
        />
        <div className="mt-auto px-6 pt-6">
          <Button fullWidth color="secondary" size="lg">
            Submit
          </Button>
        </div>
      </div>
    );
  }
);
