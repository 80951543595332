import { cn } from '@spektr/style-utils';

export type ComboboxItemProps = {
  value?: string;
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
};

export const ComboboxItem = ({
  value,
  className,
  onClick,
  children,
}: ComboboxItemProps) => {
  return (
    <li
      className={cn(
        'p-2',
        'rounded-md',
        'overflow-hidden text-ellipsis text-xs',
        'cursor-pointer',
        'hover:bg-color-bg-accent',
        className
      )}
      data-value={value}
      onClick={onClick}
      data-cy={`combobox-item-${value}-data-cy`}
    >
      {children}
    </li>
  );
};
