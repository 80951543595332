import { cn } from '@spektr/style-utils';

import { TextTruncate } from '../TextTruncate';

import { DotIcon } from './DotIcon';

export type TagProps = {
  color?: string;
  label?: string;
  className?: string;
  showTooltip?: boolean;
  isDeleted?: boolean;
};

export const Tag = ({
  color,
  label,
  className,
  showTooltip = true,
  isDeleted = false,
}: TagProps) => {
  return (
    <div
      style={{ color: color }}
      className={cn(
        'flex cursor-pointer items-center gap-1 text-sm font-medium',
        className,
        isDeleted && 'line-through'
      )}
    >
      <DotIcon color={color} />
      <TextTruncate
        className="line-clamp-1"
        text={label ?? ''}
        showTooltip={showTooltip}
      />
    </div>
  );
};
