import { useState } from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { icons } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import type { Primitive } from '../../../../types';

export type PrimitiveItemProps = {
  field: Primitive;
};

export const PrimitiveItem = ({ field }: PrimitiveItemProps) => {
  const { t } = useTranslation('moonraker-form');
  const [isClickHolding, setIsClickHolding] = useState(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'primitive',
    item: field,
    end: () => {
      setIsClickHolding(false);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const Icon = icons[field.icon as keyof typeof icons] ?? icons['Settings'];
  return (
    <button
      ref={drag}
      className={cn(
        'flex flex-col items-center justify-center gap-2.5',
        'h-20 w-full',
        'rounded-md',
        'bg-color-bg-moonraker-item',
        'transition-colors duration-200',
        'cursor-grab',
        {
          'cursor-grabbing': isClickHolding,
          'bg-color-green': isDragging,
        }
      )}
      onMouseDown={() => setIsClickHolding(true)}
      onMouseUp={() => setIsClickHolding(false)}
    >
      <Icon className="h-4 w-4" />
      <span className="text-sm font-medium">{t(field.title)}</span>
    </button>
  );
};
