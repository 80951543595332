import { EdgeSchema, NodeType, ProcessType } from '@spektr/shared/validators';

export const hasAdditionalBranches = (
  processType: ProcessType,
  nodeType: NodeType,
  outcomes: EdgeSchema[]
) => {
  if (
    nodeType === 'form' ||
    (nodeType === 'kyckr' && processType !== 'onboarding')
  ) {
    return outcomes.some((outcome) => outcome.isFinal);
  }

  return false;
};
