import { cloneElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { cn } from '@spektr/style-utils';

import {
  BodaccSquareIcon,
  ComplyAdvantageSquareIcon,
  KyckrIcon,
  OpenCorporatesSquareIcon,
  VeriffIcon,
  VeriffIdvIcon,
  VirkIcon,
} from '@spektr/client/components';

import { type AlertField } from '@spektr/client/types';
import { type AdverseMediaAuthorityLevels } from '@spektr/shared/validators';

import { AuthorityDropdown } from '../AuthorityDropdown';

export type FieldItemProps = {
  isPendingUpdate?: boolean;
  field: AlertField;
  value?: AdverseMediaAuthorityLevels;
  onAuthorityChange: (
    field: AlertField,
    authority: AdverseMediaAuthorityLevels
  ) => void;
};

export const FieldItem = ({
  isPendingUpdate,
  field,
  value,
  onAuthorityChange,
}: FieldItemProps) => {
  const { t } = useTranslation();

  const translationKey = `fields:${field.nodeType}.${field.name}`;

  const serviceIcon = useMemo(() => {
    switch (field.nodeType) {
      case 'openCorporatesMonitoring':
      case 'openCorporatesRisk':
        return <OpenCorporatesSquareIcon />;
      case 'complyAdvantageKyc':
      case 'complyAdvantageKyb':
        return <ComplyAdvantageSquareIcon />;
      case 'bodacc':
        return <BodaccSquareIcon />;
      case 'kyckr':
        return <KyckrIcon />;
      case 'veriff':
        return <VeriffIcon />;
      case 'veriffIdv':
        return <VeriffIdvIcon />;
      case 'virk':
        return <VirkIcon />;
      default:
        return null;
    }
  }, [field.nodeType]);

  return (
    <li className={cn('flex flex-row items-center', 'w-full py-2')}>
      <div className={cn('flex grow flex-row items-center gap-2.5')}>
        {serviceIcon &&
          cloneElement(serviceIcon, { className: 'shrink-0 h-5 w-5' })}
        <span className="text-sm font-medium">{t(translationKey)}</span>
      </div>
      <div className="ml-auto">
        <AuthorityDropdown
          disabled={isPendingUpdate}
          placeholder="Select spektrAI authority"
          value={value}
          onChange={(value) => onAuthorityChange(field, value)}
        />
      </div>
    </li>
  );
};
