import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CUSTOM_DOMAIN_KEYS, UsersApiClient } from '@spektr/client/services';

import { type DomainAliasDto } from '@spektr/shared/validators';

export function useCreateAliasDomain() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (domainName: string) =>
      UsersApiClient.getClient().createDomainAlias({ domainName }),

    // Do an optimistic update to the cache by setting the domain and the domain status to pending
    onMutate: async (domainName) => {
      const previousData = queryClient.getQueryData<DomainAliasDto>(
        CUSTOM_DOMAIN_KEYS.ALL()
      );

      const updatedData: DomainAliasDto = {
        domainName,
        status: 'pending',
        resourceRecords: previousData?.resourceRecords ?? [],
      };

      queryClient.setQueryData(CUSTOM_DOMAIN_KEYS.ALL(), updatedData);

      return { previousData };
    },
    // If the mutation fails, revert the cache back to the previous state
    onError(_error, _variables, context) {
      if (context?.previousData) {
        queryClient.setQueryData(
          CUSTOM_DOMAIN_KEYS.ALL(),
          context.previousData
        );
      }
    },
    // Invalidate the cache when the mutation is successful
    onSettled() {
      queryClient.invalidateQueries({
        queryKey: CUSTOM_DOMAIN_KEYS.ALL(),
      });
    },
  });
}
