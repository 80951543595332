import { Process } from '@spektr/shared/types';

/**
 * @description Channel settings should be set if loop has at least
 * one form or mitId or veriff node.
 * @param process - loop or onboarding process
 */
export const shouldProcessHaveChannelSettings = (process: Process) => {
  if (process.type === 'onboarding' && process.rootId) {
    const rootNode = process.nodes.get(process.rootId);
    if (rootNode && rootNode.nodeType !== 'onboardingProcessSource')
      return false;
    return true;
  }

  return [...process.nodes.values()].some((node) =>
    ['form', 'mitId', 'veriff'].includes(node.nodeType)
  );
};
