import { ServiceFieldClient } from '@spektr/client/types';

import { allVendorFields } from '@spektr/shared/types';

import { GroupedServiceFields } from '../types/GroupedServiceField';

export const getGroupedServiceFields = (fields: ServiceFieldClient[]) => {
  return fields.reduce(
    (acc: GroupedServiceFields, field: ServiceFieldClient) => {
      const groupName =
        allVendorFields.find((vendorField) => vendorField.key === field.key)
          ?.group ?? 'ungroupedFields';
      const group = acc[groupName];

      if (group) {
        group.push(field);
      } else {
        acc[groupName] = [field];
      }

      return acc;
    },
    {}
  );
};
