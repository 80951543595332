import { Suspense, useState } from 'react';

import { Skeleton, SpektrErrorBoundary } from '@spektr/client/components';
import { handleAxiosError } from '@spektr/client/utils';
import { ErrorPage } from '@spektr/shared/components';

import { usePaginationOptions } from '@spektr/platform-hooks';

import { useDatasetId } from './hooks';
import { DatasetFilter, ExecutionsList } from './containers';
import { SkeletonExecutionsTable } from './components/SkeletonExecutionsTable';
import { ExecutionsTable } from './components';
import { RunsFilter } from './containers/RunsFilter/RunsFilter';

export const ProcessRuns = () => {
  const [fallbackDatasetId, updateFallbackDatasetId] = useState('');
  const { datasetId, setDatasetId } = useDatasetId(fallbackDatasetId);

  const { page, setPage, pageLimit, setLimit, searchValue, setSearchValue } =
    usePaginationOptions();

  return (
    <SpektrErrorBoundary
      fallbackRender={(error) => (
        <ErrorPage
          title={handleAxiosError(
            error,
            'An error has occurred while retrieving the insights!'
          )}
        />
      )}
    >
      <>
        <div className="flex items-center justify-between">
          <p className="text-color-text-primary text-lg font-medium leading-none">
            Process Runs
          </p>

          <Suspense fallback={<Skeleton className="h-9 w-48 rounded-md" />}>
            <div className="flex items-center gap-4">
              <RunsFilter
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
              <DatasetFilter
                selectedDatasetId={datasetId}
                updateDatasetId={setDatasetId}
                updateFallbackDatasetId={updateFallbackDatasetId}
              />
            </div>
          </Suspense>
        </div>
        {datasetId ? (
          <Suspense fallback={<SkeletonExecutionsTable />}>
            <ExecutionsList
              page={page}
              limit={pageLimit}
              searchQuery={searchValue}
              selectedDatasetId={datasetId}
              setPage={setPage}
              setLimit={setLimit}
            />
          </Suspense>
        ) : (
          <ExecutionsTable executions={[]} processes={[]} />
        )}
      </>
    </SpektrErrorBoundary>
  );
};
