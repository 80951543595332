import { ChevronRight } from 'lucide-react';

import { cn } from '@spektr/style-utils';
import { Input, TextTruncate } from '@spektr/client/components';

export type TitleProps = {
  processTitle: string;
  nodeTitle?: string;
  updateNodeTitle?: (nodeTitle: string) => void;
};

export const Title = ({
  processTitle,
  nodeTitle,
  updateNodeTitle,
}: TitleProps) => {
  return (
    <div className={cn('flex flex-1 flex-row items-center gap-2')}>
      <p
        className={cn(
          'line-clamp-1 max-w-64 shrink-0',
          'text-sm font-medium',
          'text-color-text-subtext'
        )}
      >
        {processTitle}
      </p>

      <ChevronRight className="text-color-text-icon-secondary h-3 w-3 shrink-0" />

      {updateNodeTitle ? (
        <Input
          className={cn(
            'mr-4 !max-w-none flex-1 px-2 py-0',
            'border-color-border-input max-w-sm text-ellipsis border',
            'border-transparent bg-transparent',
            'hover:border-color-border-input--hover focus:border-color-border-input--focus active:border-color-border-input--active',
            'hover:border focus:border active:border',
            'hover:bg-color-bg-primary focus:bg-color-bg-primary active:bg-color-bg-primary',
            !nodeTitle && 'border-color-red border'
          )}
          placeholder="Provide form title"
          value={nodeTitle}
          onChange={(e) => updateNodeTitle(e.target.value)}
          data-cy="form-node-name-input"
        />
      ) : (
        <TextTruncate
          className="text-color-text-primary line-clamp-1 text-sm font-medium"
          text={nodeTitle || ''}
        />
      )}
    </div>
  );
};
