import { useMutation } from '@tanstack/react-query';

import { UserSettingsCreateSchema } from '@spektr/shared/validators';
import { UsersApiClient } from '@spektr/client/services';

import { ExportMethodForm } from '../../components/ExportMethodForm';
import { UserSettings } from '../../types';

type CreateExportMethodProps = {
  workspaceId?: string;
};

export const CreateExportMethod = ({
  workspaceId,
}: CreateExportMethodProps) => {
  const createSettings = useMutation({
    mutationFn: async (data: UserSettingsCreateSchema) => {
      await UsersApiClient.getClient().createUserSettings(data);
    },
  });

  const handleCreate = async (data: UserSettings) => {
    if (!workspaceId) return;

    await createSettings.mutateAsync({
      workspaceId,
      deliveryMethod: data.deliveryMethod,
      maxRateLimit: Number(data.numberOfCalls),
      maxRateLimitUnit: data.interval,
      httpsPushSettings:
        data.deliveryMethod === 'httpsPush'
          ? {
              apiKey: data.apiKey ?? '',
              httpsEndpoint: data.httpsEndpoint ?? '',
            }
          : undefined,
      slackSettings:
        data.deliveryMethod === 'slack'
          ? {
              token: data.slackToken ?? '',
              channelId: data.slackChannelId ?? '',
            }
          : undefined,
    });
  };

  return (
    <ExportMethodForm
      isUpdate={createSettings.isSuccess}
      onSubmit={handleCreate}
      isSubmitDisabled={createSettings.isPending}
    />
  );
};
