import { icons } from 'lucide-react';
import { ButtonWithTooltip } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

type ToggleFiltersProps = {
  disabled?: boolean;
  showFilters: boolean;
  onToggle: () => void;
};

export const ToggleFilters = ({
  disabled,
  showFilters,
  onToggle,
}: ToggleFiltersProps) => {
  const Icon = icons[showFilters ? 'EyeOff' : 'Eye'];

  return (
    <ButtonWithTooltip
      disabled={disabled}
      variant="contained"
      color="icon"
      className={cn(
        'text-color-text-subtext',
        'rounded-none border-l-transparent',
        'text-sm font-medium',
        'disabled:opacity-100'
      )}
      showTooltip={true}
      tooltipProps={{
        content: showFilters ? 'Hide filters' : 'Show filters',
        sideOffset: 8,
      }}
      onClick={onToggle}
    >
      <Icon className={cn('h-4 w-4', disabled && 'opacity-20')} />
    </ButtonWithTooltip>
  );
};
