import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListPlus, ServerCrash } from 'lucide-react';

import { MATRIX_DASHBOARD_FULL_URL } from '@spektr/shared/utils';

import { cn } from '@spektr/style-utils';
import {
  BasicDialog,
  SpektrErrorBoundary,
  iconBoxVariants,
} from '@spektr/client/components';

import { TemplatesList } from './containers';
import { TemplateListSkeleton } from './components';

export const RiskMatrixTemplatesDialog = () => {
  const navigate = useNavigate();

  const handleClose = () => navigate(MATRIX_DASHBOARD_FULL_URL);

  return (
    <BasicDialog
      title="Load Existing Matrices"
      description="We are constantly updating our existing matrices with new details."
      icon={
        <ListPlus
          className={cn(
            iconBoxVariants({ variant: 'cyan' }),
            'mr-2 h-6 w-6 p-1.5'
          )}
        />
      }
      className="text-color-text-dialog-default max-w-[520px]"
      defaultOpen
      onClose={handleClose}
      dialogContentDataCy="loadRiskMatrixDialog"
    >
      <SpektrErrorBoundary
        fallbackRender={() => (
          <div className="flex h-[260px] flex-col items-center justify-center gap-2 rounded-md border p-8">
            <ServerCrash className="stroke-color-red h-8 w-8" />
            <span className="text-color-text-error-boundry">
              There was an error while loading the templates.
            </span>
          </div>
        )}
      >
        <Suspense fallback={<TemplateListSkeleton />}>
          <TemplatesList />
        </Suspense>
      </SpektrErrorBoundary>
    </BasicDialog>
  );
};
