import { Suspense } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ServerCrash } from 'lucide-react';
import { useMeasure } from 'react-use';
import { useTranslation } from 'react-i18next';

import {
  ScrollArea,
  SpektrErrorBoundary,
  Tabs,
  TabsList,
  TabsTrigger,
} from '@spektr/client/components';

import { SkeletonProcessList } from '@spektr/model-builder/components';

import { ProcessList } from './ProcessList';
import { ChooseNewProcessButton } from './ChooseNewProcessButton';

export const Processes = () => {
  const { t } = useTranslation('process');
  const [searchParams, setSearchParams] = useSearchParams();
  const allStatuses = ['all', 'live', 'not-live'];
  const status = searchParams.get('status') || 'all';
  const [ref, size] = useMeasure<HTMLDivElement>();

  const handleChangeStatus = (value: string) => {
    const update = value === 'all' ? {} : { status: value };
    setSearchParams((params) => ({ ...params, ...update }));
  };

  return (
    <>
      <div className="shrink-0 grow overflow-hidden">
        <div className="flex items-center gap-4">
          <p className="text-color-text-primary text-lg font-medium leading-none">
            Your Processes
          </p>

          <Tabs value={status} onValueChange={handleChangeStatus}>
            <TabsList utility="filters">
              {allStatuses.map((status) => (
                <TabsTrigger key={status} value={status} utility="filter">
                  {t(`statusFilters.${status}`)}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>

          <div className="ml-auto">
            <ChooseNewProcessButton />
          </div>
        </div>
      </div>

      <div ref={ref} className="relative h-full w-full overflow-hidden">
        <div className="absolute inset-0">
          <ScrollArea
            style={{
              height: size.height,
            }}
          >
            <SpektrErrorBoundary
              fallbackRender={() => (
                <div className="flex flex-col items-center justify-center gap-2 p-8">
                  <ServerCrash className="stroke-color-cyan h-8 w-8" />
                  <span className="text-color-text-error-boundry">
                    There was an error while loading the processes.
                  </span>
                </div>
              )}
            >
              <Suspense fallback={<SkeletonProcessList />}>
                <ProcessList status={status} />
              </Suspense>
            </SpektrErrorBoundary>
          </ScrollArea>
        </div>
      </div>
    </>
  );
};
