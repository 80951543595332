import { Link2 } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { Button, CalculatorIcon, Skeleton } from '@spektr/client/components';

export const FilterSkeleton = () => (
  <div className="flex h-full w-full grow flex-col gap-4">
    <div className={cn('relative', 'rounded-md border', 'p-6')}>
      <div className="flex items-center gap-3">
        <div className="bg-color-bg-accent flex h-9 w-9 shrink-0 items-center justify-center rounded">
          <CalculatorIcon className="stroke-color-cyan stroke-1.8 h-[18px] w-[18px]" />
        </div>
        <div className="flex flex-col gap-2">
          <Skeleton className="h-[14px] w-[88px]" />
          <Skeleton className="h-[12px] w-[199px]" />
        </div>
      </div>
    </div>
    <div className={cn('relative', 'rounded-md border', 'p-6')}>
      <div className="flex items-center gap-3">
        <div className="bg-color-bg-accent flex h-9 w-9 shrink-0 items-center justify-center rounded">
          <Link2 className="stroke-color-cyan h-[18px] w-[18px]" />
        </div>
        <div className="flex flex-col gap-2">
          <Skeleton className="h-[14px] w-[132px]" />
          <Skeleton className="h-[12px] w-[220px]" />
        </div>
      </div>
    </div>
    <div className="mt-auto flex justify-end">
      <Button color="cyan" className="px-10" disabled>
        Save
      </Button>
    </div>
  </div>
);
