import { Tabs, TabsList, TabsTrigger } from '@spektr/client/components';
import { useTranslation } from 'react-i18next';

export type LoopsToolbarProps = {
  allStatuses: string[];
  status: string;
  onChangeStatus: (status: string) => void;
};

export const LoopsToolbar = ({
  allStatuses,
  status,
  onChangeStatus,
}: LoopsToolbarProps) => {
  const { t } = useTranslation('process');
  return (
    <div className="shrink-0 grow overflow-hidden">
      <div className="flex items-center justify-between">
        <p className="text-color-text-primary text-lg">Your loops</p>

        <Tabs value={status} onValueChange={onChangeStatus}>
          <TabsList utility="filters">
            {allStatuses.map((status) => (
              <TabsTrigger key={status} value={status} utility="filter">
                {t(`statusFilters.${status}`)}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
      </div>
    </div>
  );
};
