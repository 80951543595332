import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Skeleton,
} from '@spektr/client/components';
import { ServerCog } from 'lucide-react';

export const OnboardingSourceSkeleton = () => (
  <Dialog defaultOpen modal={false}>
    <DialogContent
      modal={false}
      className="bg-color-bg-dialog-default absolute flex flex-col"
    >
      <DialogHeader className="flex flex-col gap-4 space-y-0">
        <div className="flex items-center gap-2">
          <ServerCog className="h-4 w-4" />
          <DialogTitle className="text-color-text-dialog-title">
            Process settings
          </DialogTitle>
          <div className="ml-auto flex flex-row gap-4">
            <DialogClose />
          </div>
        </div>
        <Skeleton className="h-10 w-full" />
        <Skeleton className="h-20 w-full" />
        <div className="flex flex-row gap-2">
          <Skeleton className="h-9 w-full" />
          <Skeleton className="h-9 w-36" />
        </div>
        <div className="flex h-96 flex-col gap-2 rounded-md border p-4">
          <Skeleton className="h-12 w-full" />
          <Skeleton className="h-12 w-full" />
          <Skeleton className="h-12 w-full" />
          <Skeleton className="h-12 w-full" />
        </div>
        <Skeleton className="h-9 w-full" />
      </DialogHeader>
    </DialogContent>
  </Dialog>
);
