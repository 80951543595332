import { Info } from 'lucide-react';
import { CheckedState } from '@radix-ui/react-checkbox';

import { cn } from '@spektr/style-utils';

import { Checkbox, Label, Tooltip } from '@spektr/client/components';

export type SelectableServiceItemProps = {
  className?: string;
  id: string;
  label: string;
  help?: string;
  checked?: CheckedState;
  disabled?: boolean;
  onChange?: (key: string, value: boolean) => void;
};

export const SelectableField = ({
  id,
  label,
  help,
  checked,
  className,
  disabled = false,
  onChange,
}: SelectableServiceItemProps) => {
  return (
    <div
      className={cn(
        'flex h-auto items-center justify-start gap-3',
        'cursor-pointer',
        className
      )}
    >
      <Checkbox
        id={id}
        checked={checked}
        disabled={disabled}
        onCheckedChange={(value) => {
          typeof value === 'boolean' && onChange?.(id, value);
        }}
      />
      <Label
        htmlFor={id}
        className={cn(
          'text-left text-sm font-light',
          'cursor-pointer',
          'whitespace-normal',
          disabled ? 'text-color-text-subtext' : 'text-color-text-primary'
        )}
      >
        {label}
      </Label>
      {help && (
        <Tooltip content={help}>
          <Info className="text-color-text-icon-secondary h-3 w-3" />
        </Tooltip>
      )}
    </div>
  );
};
