import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { useForm } from 'react-hook-form';

import { useDialogClose, useProcessTypeFromUrl } from '@spektr/shared/hooks';
import {
  getColorBasedOnProcessType,
  processBuilderUrl,
} from '@spektr/shared/utils';
import { hasErrorMessage } from '@spektr/client/utils';

import {
  ProcessApiClient,
  getProcessQueryKey,
  getProcessesQueryKey,
} from '@spektr/client/services';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Button,
  Input,
  toast,
} from '@spektr/client/components';

import { ProcessType } from '@spektr/shared/validators';

const schema = z.object({
  name: z.string(),
  type: ProcessType,
});

type FormValues = {
  name: string;
  type: ProcessType;
};

export const CreateProcessBuilderDialog = () => {
  const queryClient = useQueryClient();
  const {
    handleSubmit: handleFormSubmit,
    register,
    watch,
  } = useForm<FormValues>();
  const navigate = useNavigate();
  const [open, startExitAnimation] = useDialogClose();
  const processType = useProcessTypeFromUrl();
  const processVariant = getColorBasedOnProcessType(processType);

  const handleExit = () => {
    navigate(processBuilderUrl());
  };

  const createFilterNodeMutation = useMutation({
    mutationFn: async (processId: string) => {
      await ProcessApiClient.getClient().createNode(
        { nodeType: 'filter' },
        {
          params: { processId },
        }
      );

      await queryClient.invalidateQueries({
        queryKey: getProcessQueryKey(processId),
      });
    },
  });

  const createProcessMutation = useMutation({
    mutationFn: async (values: FormValues) => {
      const { name, type } = schema.parse(values);

      const process = await ProcessApiClient.getClient().createProcess({
        name,
        type,
      });

      if (type !== 'onboarding') {
        await createFilterNodeMutation.mutateAsync(process?.id);
      }

      navigate(processBuilderUrl(process.id));

      await queryClient.invalidateQueries({
        queryKey: getProcessesQueryKey(),
      });
    },
    onError: (error) => {
      const message = hasErrorMessage(error)
        ? error.message
        : 'Something went wrong. Please try again.';

      toast.error({
        title: 'Something went wrong!',
        description: message,
      });
    },
  });

  const handleSubmit = async (data: FormValues) => {
    await createProcessMutation.mutateAsync(data);
  };

  const processName = watch('name') ?? '';

  return (
    <Dialog open={open} modal={false}>
      <DialogContent
        modal={false}
        className="absolute flex !w-[350px] flex-col"
        requestStartExitAnimation={startExitAnimation}
        onEndExitAnimation={handleExit}
        onEscapeKeyDown={startExitAnimation}
        data-cy="add-new-process-dialog"
      >
        <form onSubmit={handleFormSubmit(handleSubmit)}>
          <DialogHeader className="justify-center space-y-0">
            <div className="flex flex-row items-center">
              <DialogTitle className="text-color-text-dialog-title">
                Add new process
              </DialogTitle>
              <div className="ml-auto flex flex-row gap-2">
                <DialogClose onClick={startExitAnimation} />
              </div>
            </div>
          </DialogHeader>
          <div className="flex flex-col gap-2 py-6">
            <p className="text-xs">
              Let's start by adding a name for your new process
            </p>
            <Input
              autoFocus
              disabled={createProcessMutation.isPending}
              className="border-color-border-tertiary"
              placeholder="Process name"
              data-cy="add-new-process-name-input"
              {...register('name')}
            />
            <Input
              defaultValue={processType}
              className="hidden"
              {...register('type')}
            />
          </div>
          <DialogFooter>
            <Button
              type="submit"
              className="w-full"
              color={processVariant}
              children="Continue"
              disabled={
                createProcessMutation.isPending || processName?.trim() === ''
              }
            />
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
