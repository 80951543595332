import { DialogFooter, Skeleton } from '@spektr/client/components';

export const SkeletonContent = () => (
  <div className="mt-4 flex flex-col gap-2">
    <span className="text-color-text-subtext text-xs">
      Select your channel and customize your message
    </span>
    <div className="flex min-w-[500px] flex-col rounded-md border">
      <div className="flex h-[45px] w-full items-center border-b px-4 py-2">
        <label className="text-color-text-subtext line-clamp-1 text-xs font-semibold tracking-tight">
          Entity name
        </label>
        <div className="ml-auto flex items-center gap-2">
          <Skeleton className="h-4 w-16" />
          <Skeleton className="h-7 w-60" />
        </div>
      </div>

      <div className="flex h-[45px] w-full items-center border-b px-4 py-2">
        <label className="text-color-text-subtext line-clamp-1 text-xs font-semibold tracking-tight">
          Channel ID
        </label>
        <div className="ml-auto flex items-center gap-2">
          <Skeleton className="h-4 w-16" />
          <Skeleton className="h-7 w-60" />
        </div>
      </div>

      <div className="flex h-[45px] w-full items-center border-b px-4 py-2">
        <label className="text-color-text-subtext line-clamp-1 text-xs font-semibold tracking-tight">
          Bot User OAuth Token
        </label>
        <div className="ml-auto flex items-center gap-2">
          <Skeleton className="h-4 w-16" />
          <Skeleton className="h-7 w-60" />
        </div>
      </div>

      <div className="flex flex-col gap-1.5 p-4">
        <label className="text-color-text-subtext line-clamp-1 text-xs font-semibold tracking-tight">
          Custom message
        </label>
        <Skeleton className="h-28 w-full" />
      </div>
    </div>
    <DialogFooter>
      <Skeleton className="h-9 w-16" />
    </DialogFooter>
  </div>
);
