import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { RecordFilters, getAllRecords } from '@spektr/client/services';

import { useRecordsContext } from '../providers';
import { DatasetRecord } from '../types';
import { RECORDS_REFETCH_INTERVAL } from '../constants';

export const useGetDatasetRecords = (
  datasetId: string,
  filters?: RecordFilters
) => {
  const { selectedDataset } = useRecordsContext();
  const { data: records } = useSuspenseQuery({
    ...getAllRecords(datasetId, filters),
    refetchInterval: (data) =>
      data.state.data?.docs.length ? false : RECORDS_REFETCH_INTERVAL,
  });

  const datasetFields = useMemo(() => {
    if (!selectedDataset) return [];

    return Object.values(selectedDataset.fields).map((field) => field.name);
  }, [selectedDataset]);

  const parsedRecords: DatasetRecord[] = useMemo(
    () =>
      records.docs.map((record) => ({
        id: record.spektrId,
        fields: Object.keys(record).reduce((acc, key) => {
          return datasetFields.includes(key)
            ? {
                ...acc,
                [key]: record[key],
              }
            : acc;
        }, {}),
      })),
    [records, datasetFields]
  );

  return useMemo(
    () => ({ records: parsedRecords, totalPages: records.totalPages }),
    [parsedRecords, records.totalPages]
  );
};
