import { useMemo } from 'react';

import {
  ProcessApiClient,
  getTriggersForProcessQueryKey,
} from '@spektr/client/services';

import {
  TriggerCreateInputSchema,
  TriggerFrequency,
} from '@spektr/shared/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useTriggerOperations = (processId: string) => {
  const queryClient = useQueryClient();
  const createTriggerMutation = useMutation({
    mutationFn: (item: TriggerCreateInputSchema) =>
      ProcessApiClient.getClient().createTrigger(item),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getTriggersForProcessQueryKey(processId),
      });
    },
  });
  const updateTriggerMutation = useMutation({
    mutationFn: (item: {
      id: string;
      frequency: TriggerFrequency;
      interval: number;
      startDate: number;
    }) =>
      ProcessApiClient.getClient().updateTriggerFrequency(
        {
          frequency: item.frequency,
          interval: item.interval,
          startDate: item.startDate,
        },
        {
          params: {
            triggerId: item.id,
          },
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getTriggersForProcessQueryKey(processId),
      });
    },
  });

  const deleteTriggerMutation = useMutation({
    mutationFn: (triggerId: string) =>
      ProcessApiClient.getClient().deleteTriggerById(undefined, {
        params: { triggerId },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getTriggersForProcessQueryKey(processId),
      });
    },
  });

  return useMemo(
    () => ({
      createTrigger: createTriggerMutation.mutateAsync,
      updateTrigger: updateTriggerMutation.mutateAsync,
      removeTrigger: deleteTriggerMutation.mutateAsync,
    }),
    [createTriggerMutation, updateTriggerMutation, deleteTriggerMutation]
  );
};
