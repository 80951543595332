import { useMutation, useQueryClient } from '@tanstack/react-query';

import { toast } from '@spektr/client/components';
import { COMMENTS_KEYS, ProcessApiClient } from '@spektr/client/services';
import { hasErrorMessage } from '@spektr/shared/utils';

import {
  type SlateDescendant,
  type SlateTextElement,
} from '@spektr/shared/slate-types';

export function useUpdateComment() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (data: { id: string; message: SlateDescendant[] }) => {
      return ProcessApiClient.getClient().updateCommentById(
        {
          message: data.message as SlateTextElement[],
        },
        {
          params: {
            commentId: data.id,
          },
        }
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: COMMENTS_KEYS.ALL(),
      });
    },
    onError: (error: Error) => {
      const message = hasErrorMessage(error)
        ? error.message
        : 'Something went wrong. Please try again.';

      toast.error({
        title: 'Something went wrong!',
        description: message,
      });
    },
  });

  return mutation;
}
