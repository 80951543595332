import * as HoverCardPrimitive from '@radix-ui/react-hover-card';

import { cn } from '@spektr/style-utils';

import { HoverCardContent } from './HoverCardContent';

export type HoverCardProps = HoverCardPrimitive.HoverCardContentProps & {
  children: React.ReactNode;
  trigger: React.ReactNode;
  triggerProps?: HoverCardPrimitive.HoverCardTriggerProps;
  onOpenChange?: (open: boolean) => void;
};

const HoverCardTrigger = HoverCardPrimitive.Trigger;

const HoverCard = ({
  className,
  children,
  trigger,
  triggerProps,
  sideOffset = 6,
  onOpenChange,
  ...props
}: HoverCardProps) => {
  return (
    <HoverCardPrimitive.Root onOpenChange={onOpenChange}>
      <HoverCardTrigger asChild {...triggerProps}>
        {trigger}
      </HoverCardTrigger>
      <HoverCardContent
        className={cn(
          'bg-color-bg-card-default',
          'text-color-text-primary',
          className
        )}
        sideOffset={sideOffset}
        {...props}
      >
        {children}
      </HoverCardContent>
    </HoverCardPrimitive.Root>
  );
};

export { HoverCard, HoverCardTrigger };
