import { useSuspenseQuery } from '@tanstack/react-query';
import { produce } from 'immer';
import { Trash2 } from 'lucide-react';
import { useState } from 'react';

import { RBAC } from '@spektr/shared/rbac';
import { UserRoles, UserSchema, isRoleValid } from '@spektr/shared/validators';

import { getTeamMembersQuery } from '@spektr/client/services';
import { cn } from '@spektr/style-utils';

import {
  Avatar,
  Select,
  Spinner,
  TableCell,
  TableRow,
} from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';

import { TeamTable } from '../TeamTable';

export type ListTeamMembersProps = {
  currentUserEmail?: string;
  onDelete: (userId: string) => void;
  onUpdate: (payload: { userId: string; role: UserRoles }) => Promise<unknown>;
};

export const ListTeamMembers = ({
  currentUserEmail,
  onDelete,
  onUpdate,
}: ListTeamMembersProps) => {
  const { hasPermission } = usePermissionsContext();
  const [pendingUpdateIds, setPendingUpdateIds] = useState<string[]>([]);
  const { data } = useSuspenseQuery({
    ...getTeamMembersQuery(['userRole']),
    retry: false,
  });

  const handleChangeRole = async (userId: string, role: string) => {
    if (isRoleValid(role)) {
      setPendingUpdateIds(
        produce((draft) => {
          draft.push(userId);
        })
      );
      await onUpdate({ userId, role });
      setPendingUpdateIds(
        produce((draft) => draft.filter((id) => id !== userId))
      );
    }
  };

  const renderTableRow = (member: UserSchema) => (
    <TableRow key={member.id}>
      <TableCell className="p-4">
        <div className="flex flex-row items-center gap-3">
          <Avatar
            firstName={member.firstName || ''}
            lastName={member.lastName || ''}
          />
          <div className="flex flex-col">
            <p>{`${member.firstName} ${member.lastName}`}</p>
            <p className="text-color-text-subtext text-xs">{member.username}</p>
          </div>
        </div>
      </TableCell>
      <TableCell className="p-4">
        <div className="grow-1 flex w-44 flex-row items-center gap-2">
          <Select
            className="w-40 shrink-0"
            disabled={
              pendingUpdateIds.includes(member.id) ||
              member.username === currentUserEmail ||
              !hasPermission(RBAC.ACTIONS.USER.UPDATE)
            }
            defaultValue={member.role}
            options={[
              { value: 'admin', label: 'Admin' },
              { value: 'editor', label: 'Editor' },
              { value: 'viewer', label: 'Viewer' },
            ]}
            onValueChange={(value) => {
              handleChangeRole(member.id, value);
            }}
          />
          <div>
            {pendingUpdateIds.includes(member.id) && <Spinner size="sm" />}
          </div>
        </div>
      </TableCell>
      <TableCell className="p-4 text-right">
        <button
          className="group"
          disabled={
            member.username === currentUserEmail ||
            !hasPermission(RBAC.ACTIONS.USER.DELETE)
          }
          onClick={() => onDelete(member.id)}
        >
          <Trash2
            className={cn(
              'h-5 w-5',
              'text-color-text-icon-tertiary hover:text-color-red',
              'group-disabled:hover:text-color-text-icon-tertiary group-disabled:opacity-50'
            )}
          />
        </button>
      </TableCell>
    </TableRow>
  );

  return <TeamTable>{data?.map((member) => renderTableRow(member))}</TeamTable>;
};
