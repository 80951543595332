import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { CalendarDays, ChevronDown } from 'lucide-react';

import { cn } from '@spektr/style-utils';
import {
  Button,
  Popover,
  Calendar,
  type CalendarProps,
} from '@spektr/client/components';

export type DatePickerProps = CalendarProps & {
  iconClassName?: string;
  className?: string;
  epochOrToday: string | number | '@today' | undefined;
  disableOpen?: boolean;
  placeholder?: string;
  onChange?: (epochOrToday: string | number | '@today' | undefined) => void;
  onOpenChange?: (state: boolean) => void;
  showToday?: boolean;
  popoverSide?: Popover.Props['side'];
  openState?: boolean;
  dropdownArrow?: boolean;
};

export const DatePicker = ({
  iconClassName,
  className,
  epochOrToday,
  disableOpen = false,
  placeholder = 'Pick a date',
  showToday = true,
  popoverSide,
  openState = false,
  dropdownArrow = false,
  onChange,
  onOpenChange,
  ...props
}: DatePickerProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => setOpen(openState), [openState]);

  return (
    <Popover
      className={cn(
        'w-auto',
        'dark:border-color-border-primary border-color-border-secondary',
        'bg-color-bg-select-content',
        'text-color-text-select-content'
      )}
      trigger={
        <Button
          disabled={disableOpen}
          variant="text"
          className={cn(
            'shrink-0 justify-start',
            'border',
            'text-left text-sm font-normal',
            'border-color-border-select rounded-md border',
            'bg-color-bg-select-trigger',
            'text-color-text-select-trigger hover:text-color-text-select-trigger',
            'ring-offset-background',
            'placeholder:text-color-text-placeholder',
            'disabled:bg-color-bg-select-trigger--disabled disabled:text-color-text-select-trigger--disabled disabled:opacity-50',
            className
          )}
        >
          <CalendarDays
            className={cn('mr-2 h-4 w-4 shrink-0', iconClassName)}
          />
          {epochOrToday === '@today' ? (
            <span className="shrink-0" children="@today" />
          ) : epochOrToday ? (
            format(new Date(Number(epochOrToday)), 'PPP')
          ) : (
            <span className="text-color-text-subtext shrink-0">
              {placeholder}
            </span>
          )}
          {dropdownArrow && (
            <ChevronDown className="stroke-color-stroke-default ml-auto h-3.5 w-3.5" />
          )}
        </Button>
      }
      side={popoverSide}
      triggerProps={{
        asChild: true,
      }}
      open={open}
      onOpenChange={(open: boolean) => {
        setOpen(open);
        onOpenChange?.(open);
      }}
    >
      <div className="flex flex-col gap-1 pt-2">
        {showToday && (
          <Button
            variant="contained"
            children="@today"
            color="secondary"
            className={cn(
              'mx-auto h-auto w-fit px-3 py-1',
              'bg-transparent',
              'text-xs font-medium',
              'hover:bg-color-bg-accent',
              epochOrToday === '@today' &&
                cn(
                  'bg-color-bg-calendar-selected-day',
                  'text-color-text-calendar-selected-day'
                )
            )}
            onClick={() => {
              onChange?.('@today');
              setOpen(false);
            }}
          />
        )}
        <Calendar
          {...props}
          mode="single"
          selected={
            epochOrToday && epochOrToday !== '@today'
              ? new Date(Number(epochOrToday))
              : undefined
          }
          onSelect={(date) => {
            onChange?.(date?.getTime()?.toString());
            setOpen(false);
          }}
          className="text-color-text-primary pt-0"
        />
      </div>
    </Popover>
  );
};
