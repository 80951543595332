import { Process } from '@spektr/shared/types';
import {
  getChannelSettingsFromRootNode,
  shouldProcessHaveChannelSettings,
} from '@spektr/shared/utils';

export const areChannelSettingsRequired = (process: Process | null) => {
  if (
    process &&
    ['loop', 'onboarding'].includes(process.type) &&
    shouldProcessHaveChannelSettings(process)
  ) {
    const settings = getChannelSettingsFromRootNode(process);

    return !settings;
  }

  return false;
};
