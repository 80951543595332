export function createReorderFieldAction(
  currentIndex: number,
  newIndex: number,
  entityId?: string
) {
  return {
    type: 'reorder_field' as const,
    payload: {
      currentIndex,
      newIndex,
      entityId,
    },
  };
}

export type ReorderFieldAction = ReturnType<typeof createReorderFieldAction>;
