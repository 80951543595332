import { Suspense } from 'react';

import { Skeleton } from '@spektr/client/components';

import { useAlertDetails } from '../AlertDetails/providers';

import { ManualReviewChecklist } from './components/ManualReviewChecklist';
import { HitsContainer } from './containers/HitsContainer';

export const AlertConfiguration = () => {
  const { alert, selectedTasks, handleTaskSelection } = useAlertDetails();

  if (!alert) return null;

  return alert?.tasks && alert.tasks.length > 0 ? (
    <ManualReviewChecklist
      tasks={alert.tasks}
      disabled={alert.status === 'resolved'}
      selectedTasks={selectedTasks}
      onTaskSelection={handleTaskSelection}
    />
  ) : (
    <Suspense fallback={<Skeleton className="h-80 w-full" />}>
      <HitsContainer alert={alert} />
    </Suspense>
  );
};
