import { Suspense } from 'react';

import { BadgeNotifications } from '@spektr/client/providers';
import { CasesTabNavigation } from '@spektr/client/components';

import { NavigationWithNotifications } from './components';

export const CasesNavigation = () => {
  return (
    <Suspense fallback={<CasesTabNavigation />}>
      <BadgeNotifications>
        <NavigationWithNotifications />
      </BadgeNotifications>
    </Suspense>
  );
};
