import { ComponentType, useMemo } from 'react';

import { useBadgeNotifications } from '@spektr/client/providers';
import { AppNavProps } from '@spektr/client/components';

export const withNotifications = <P extends AppNavProps>(
  Component: ComponentType<P>
) => {
  return (props: P) => {
    const { unresolvedAlerts } = useBadgeNotifications();

    const items = useMemo(() => {
      if (unresolvedAlerts > 0) {
        return props.items.map((item) => {
          if (item.id === 'cases') {
            return {
              ...item,
              count: unresolvedAlerts,
            };
          }

          return item;
        });
      }
      return props.items;
    }, [unresolvedAlerts, props.items]);

    return <Component {...props} items={items} />;
  };
};
