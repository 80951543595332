import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Focus, ListChecks, MessageCircle } from 'lucide-react';

import {
  casesAlertActivityUrl,
  casesAlertUrl,
  casesCustomerAlertActivityUrl,
  casesCustomerAlertDetailsUrl,
} from '@spektr/shared/utils';

import { Tabbar, TabbarItem } from '@spektr/client/components';

type AlertTabsProps = {
  alertId: string;
  isManualAlert: boolean;
};

export const AlertTabs = ({ alertId, isManualAlert }: AlertTabsProps) => {
  const { spektrId } = useParams();

  const alertConfigUrl = useMemo(
    () =>
      spektrId
        ? casesCustomerAlertDetailsUrl(spektrId, alertId)
        : casesAlertUrl(alertId),
    [alertId, spektrId]
  );

  const alertActivityUrl = useMemo(
    () =>
      spektrId
        ? casesCustomerAlertActivityUrl(spektrId, alertId)
        : casesAlertActivityUrl(alertId),
    [alertId, spektrId]
  );

  return (
    <Tabbar className="border-0">
      {isManualAlert ? (
        <TabbarItem
          icon={<ListChecks className="h-3 w-3" />}
          to={{ pathname: alertConfigUrl }}
        >
          Please complete the following tasks
        </TabbarItem>
      ) : (
        <TabbarItem
          icon={<Focus className="h-3 w-3" />}
          to={{ pathname: alertConfigUrl }}
        >
          Hits
        </TabbarItem>
      )}

      <TabbarItem
        icon={<MessageCircle className="h-3 w-3" />}
        to={{ pathname: alertActivityUrl }}
      >
        Activity
      </TabbarItem>
    </Tabbar>
  );
};
