import { CircleX } from 'lucide-react';
import { Suspense } from 'react';

import { SpektrErrorBoundary, Spinner } from '@spektr/client/components';
import { handleAxiosError } from '@spektr/client/utils';

import { FallbackComponentLayout } from './components';
import { StrategyTreeWrapper } from './containers';
import { StrategyProvider } from './providers';
import { GraphView } from './types';

type StrategyViewProps = {
  defaultSelected?: string;
  activeView?: GraphView;
  isStrategyPage?: boolean;
};

export const StrategyView = ({
  defaultSelected,
  activeView = 'map',
  isStrategyPage = false,
}: StrategyViewProps) => (
  <SpektrErrorBoundary
    fallbackRender={(error) => (
      <FallbackComponentLayout>
        <CircleX className="text-color-red h-6 w-6" />
        <p className="text-color-red">
          {handleAxiosError(
            error,
            'Something went wrong when loading the graph.'
          )}
        </p>
      </FallbackComponentLayout>
    )}
  >
    <Suspense
      fallback={
        <FallbackComponentLayout>
          <Spinner />
          Loading...
        </FallbackComponentLayout>
      }
    >
      <StrategyProvider
        defaultSelected={defaultSelected}
        activeView={activeView}
        isStrategyPage={isStrategyPage}
      >
        <StrategyTreeWrapper />
      </StrategyProvider>
    </Suspense>
  </SpektrErrorBoundary>
);
