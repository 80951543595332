import { UsersApiClient } from '../client';
import { VENDOR_SETTINGS_KEYS } from '../queryKeys';

export function getVendorSettingsByVendor(vendor: string) {
  return {
    queryKey: VENDOR_SETTINGS_KEYS.BY_VENDOR(vendor),
    queryFn: async () => {
      try {
        return await UsersApiClient.getClient().getVendorSettingsByVendor({
          params: { vendor },
        });
      } catch (error: any) {
        if (error.response?.status === 404) {
          const fallback = {
            vendor: '',
            createdBy: '',
            secret: undefined,
          };

          return fallback;
        }
        throw error;
      }
    },
  };
}

export function getVendorSettings() {
  return {
    queryKey: VENDOR_SETTINGS_KEYS.ALL(),
    queryFn: () =>
      UsersApiClient.getClient().getFilteredVendorSettings({
        queries: { filter: 'all' },
      }),
  };
}
