import isEmpty from 'lodash/isEmpty';
import { X } from 'lucide-react';

import { cn } from '@spektr/style-utils';
import { Button } from '@spektr/client/components';

import { useCustomerDetails } from '../../providers/CustomerDetailsProvider';
import { CustomerInfoRow } from '../../components';

export const CustomerSidebarDetails = () => {
  const { isSidebarVisible, toggleSidebar, customer } = useCustomerDetails();
  if (!customer.details) return null;
  const {
    address,
    birthDate,
    nationality,
    incorporationDate,
    jurisdiction,
    ...fields
  } = customer.details || {};
  const name = customer.name;

  const hasPrimaryData =
    name ||
    birthDate ||
    incorporationDate ||
    address ||
    nationality ||
    jurisdiction;

  return (
    <div
      className={cn(
        'relative',
        'h-full',
        'text-sm font-medium',
        'bg-color-bg-card-default',
        'transition-width duration-300 ease-in-out',
        'overflow-hidden',
        isSidebarVisible ? 'w-[265px]' : 'w-0'
      )}
    >
      <div className="flex h-full flex-col overflow-y-auto border-l p-6">
        <p className="text-base">Details</p>
        {hasPrimaryData && (
          <div className="bg-color-bg-accent mt-3 flex flex-col gap-3 rounded-md p-4">
            {name && (
              <CustomerInfoRow
                iconName={
                  customer.customerType === 'individual'
                    ? 'UserRound'
                    : customer.customerType === 'company'
                      ? 'Store'
                      : 'CircleHelp'
                }
                label={name}
              />
            )}
            {birthDate && (
              <CustomerInfoRow iconName="Calendar" label={birthDate} />
            )}
            {incorporationDate && (
              <CustomerInfoRow iconName="Calendar" label={incorporationDate} />
            )}
            {address && <CustomerInfoRow iconName="MapPin" label={address} />}
            {nationality && (
              <CustomerInfoRow iconName="Globe" label={nationality} />
            )}
            {jurisdiction && (
              <CustomerInfoRow iconName="Scale" label={jurisdiction} />
            )}
          </div>
        )}
        <div className="mt-5 flex flex-col gap-4">
          {Object.entries(fields).map(([key, value]) => (
            <div key={key} className="flex flex-col gap-1 text-sm font-medium">
              <p className="text-color-text-subtext block truncate">{key}</p>
              <p className="block truncate">{String(value ?? '-')}</p>
            </div>
          ))}

          {!hasPrimaryData && isEmpty(fields) && (
            <p className="text-color-text-subtext">No information available.</p>
          )}
        </div>
      </div>

      <Button
        variant="text"
        className="absolute right-2.5 top-2.5 h-4 w-4 p-0"
        onClick={() => toggleSidebar(false)}
      >
        <X />
      </Button>
    </div>
  );
};
