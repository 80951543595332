import { memo } from 'react';
import { ServerCog } from 'lucide-react';

import { IconBox } from '@spektr/client/components';

export const ProcessSettingsNode = memo(() => {
  return (
    <>
      <IconBox
        size="lg"
        color="cyan"
        renderIcon={({ className }) => <ServerCog className={className} />}
      />
      <div className="flex flex-col">
        <h3 className="text-color-text-primary text-sm font-medium">Process</h3>
        <span className="text-color-text-subtext text-xs">Settings</span>
      </div>
    </>
  );
});
