import {
  IdentifierItem,
  SectionList,
  SectionWithTitle,
} from '@spektr/model-builder/components';

import { type SpektrField } from '@spektr/shared/types';

export namespace ServiceCommonMappingForm {
  export type FormEntry = {
    key: string;
    label: string;
    type?: string;
    description: string;
  };

  export type Props = {
    form: FormEntry[];
    spektrFields: SpektrField[];
    title: string;
  };
}

export const ServiceCommonMappingForm = ({
  form,
  spektrFields,
  title,
}: ServiceCommonMappingForm.Props) => (
  <SectionWithTitle title={title}>
    <SectionList data={form}>
      {(field) => (
        <IdentifierItem
          key={field.key}
          field={field}
          spektrFields={spektrFields}
        />
      )}
    </SectionList>
  </SectionWithTitle>
);
