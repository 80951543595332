import { CircleX, Play } from 'lucide-react';
import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  processBuilderUrl,
  processDetailsParamsSchema,
} from '@spektr/shared/utils';

import { useParsedParams } from '@spektr/shared/hooks';
import { CognitoUserData } from '@spektr/shared/types';

import { BasicDialog, SpektrErrorBoundary } from '@spektr/client/components';
import { handleAxiosError } from '@spektr/client/utils';
import { cn } from '@spektr/style-utils';

import { SettingItemSkeleton } from './components/SettingItemSkeleton';
import { ProcessSchedule } from './containers/ProcessSchedule';

export type ProcessScheduleDialogProps = {
  user?: CognitoUserData;
};

export const ProcessScheduleDialog = ({ user }: ProcessScheduleDialogProps) => {
  const navigate = useNavigate();
  const { processId } = useParsedParams(processDetailsParamsSchema);

  const handleClose = () => {
    navigate(processBuilderUrl(processId));
  };

  return (
    <BasicDialog
      className="w-[620px] max-w-[620px]"
      defaultOpen
      title="Process launch"
      icon={<Play className="mr-3 h-4 w-4" />}
      description="Create a version of your process by publishing it. Version helps you save a state of your process. Set your version to live to begin its execution."
      onClose={handleClose}
    >
      <SpektrErrorBoundary
        fallbackRender={({ error }) => (
          <div
            className={cn(
              'flex items-center justify-center gap-1',
              'h-[100px]',
              'border-color-red rounded-md border'
            )}
          >
            <CircleX className="text-color-red h-4 w-4" />
            <p className="text-color-text-primary text-sm">
              {handleAxiosError(
                error,
                'Something went wrong when loading the process settings.'
              )}
            </p>
          </div>
        )}
      >
        <Suspense fallback={<SettingItemSkeleton />}>
          <ProcessSchedule processId={processId} user={user} />
        </Suspense>
      </SpektrErrorBoundary>
    </BasicDialog>
  );
};
