import { Zodios } from '@zodios/core';
import { pluginToken } from '@zodios/plugins';

import {
  ApiConfig,
  createBaseApiClient,
  getAccessToken,
} from '@spektr/client/utils';

import {
  apiKeyApi,
  domainAliasApi,
  userApi,
  userSettingsApi,
  vendorSettingsApi,
} from '@spektr/shared/validators';

function createUserApiClient(config: ApiConfig) {
  const instance = new Zodios(config.baseUrl, [
    ...userApi,
    ...domainAliasApi,
    ...vendorSettingsApi,
    ...userSettingsApi,
    ...apiKeyApi,
  ]);

  instance.use(
    pluginToken({
      getToken: () =>
        Promise.resolve(getAccessToken(config.clientId) ?? undefined),
    })
  );

  return instance;
}

export const UsersApiClient = createBaseApiClient(createUserApiClient);
