import { CalculatorIcon, IconBox } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

export type CalculationNodeProps = {
  title: string;
};

export const CalculationNode = ({ title }: CalculationNodeProps) => {
  return (
    <>
      <IconBox
        size="lg"
        color="cyan"
        renderIcon={({ className }) => (
          <CalculatorIcon className={cn('stroke-1.8', className)} />
        )}
      />
      <div className="flex flex-col">
        <h3 className="text-color-text-node line-clamp-1 text-sm font-medium">
          {title}
        </h3>
        <span className="text-color-text-node-subtext text-xs">
          Calculation
        </span>
      </div>
    </>
  );
};
