import { CustomerFilters } from '../types/Filters';

export const CUSTOMERS_KEYS = {
  ALL: (filters?: CustomerFilters) => {
    const key = ['customers', 'list'] as const;
    return filters ? ([...key, filters] as const) : key;
  },
  BY_ID: (customerId: string) => ['customers', 'details', customerId] as const,
  TIMELINE: (customerId: string, page?: number, limit?: number) => {
    const key = ['customers', 'details', customerId, 'timeline'] as const;
    return page && limit ? ([...key, page, limit] as const) : key;
  },
  DOCUMENTS: (customerId: string) =>
    ['customers', 'details', customerId, 'documents'] as const,
  OWNERS_AND_CONTROLLERS: (customerId: string) =>
    ['customers', 'details', customerId, 'owners'] as const,
  CONNECTIONS: (customerId: string) =>
    ['customers', 'details', customerId, 'connections'] as const,
};
