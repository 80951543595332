import { useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CircleX, Search } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { Button, Input, Separator } from '@spektr/client/components';

import { useColumnsContext } from '../providers';

import { ColumnsList } from './ColumnsList';

export const ColumnsContainer = () => {
  const {
    columns,
    searchColumnValue,
    columnFilter,
    canDrag,
    setSearchColumnValue,
  } = useColumnsContext();

  const filteredColumns = useMemo(
    () =>
      columns.filter((column) =>
        column.name.toLowerCase().includes(columnFilter.toLowerCase())
      ),
    [columns, columnFilter]
  );
  const visibleList = useMemo(
    () => filteredColumns.filter((column) => !column.isHidden),
    [filteredColumns]
  );
  const hiddenList = useMemo(
    () => filteredColumns.filter((column) => column.isHidden),
    [filteredColumns]
  );

  return (
    <>
      <Input
        className="border-0"
        placeholder="Search for a field from dataset"
        startIcon={<Search className="h-3 w-3" />}
        endIcon={
          <Button
            variant="text"
            className={cn('p-0', searchColumnValue.length === 0 && 'hidden')}
            onClick={() => setSearchColumnValue('')}
          >
            <CircleX className="h-3 w-3" />
          </Button>
        }
        value={searchColumnValue}
        onChange={(event) => setSearchColumnValue(event.target.value)}
      />
      <Separator />
      <DndProvider backend={HTML5Backend}>
        <div className="max-h-60 overflow-y-auto">
          <ColumnsList type="visible" items={visibleList} />
          <ColumnsList type="hidden" items={hiddenList} />
        </div>
      </DndProvider>
      {!canDrag && filteredColumns.length === 0 && (
        <span className="text-color-text-subtext flex h-9 items-center px-3 text-xs">
          No columns found
        </span>
      )}
    </>
  );
};
