import { type KyckrOutcome, kyckrOutcomes } from '@spektr/shared/types';

import { type ServiceFieldClient } from '@spektr/client/types';

import { isKyckrFieldSelected } from './isKyckrFieldSelected';

export function getSelectedKyckrFields(
  fields: ServiceFieldClient[]
): KyckrOutcome[] {
  const selectedFieldKeys = fields
    .filter((field) => field.value)
    .map((selectedField) => selectedField.key);

  return kyckrOutcomes.filter((outcome) =>
    isKyckrFieldSelected(outcome, selectedFieldKeys)
  );
}
