import { ChangeEvent } from 'react';

import { parseRange } from '@spektr/shared/ast';

import { Input } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

import { blurOnEnter } from '../../utils';

import { useIncompleteRuleGroup } from '../providers';

export const RightOperandRangeInput = (props: { ruleId: string }) => {
  const { getPredicate, setPredicate } = useIncompleteRuleGroup();

  const predicate = getPredicate(props.ruleId);
  if (!predicate) return null;

  return (
    <RangeInput
      range={typeof predicate.right === 'string' ? predicate.right : undefined}
      onChange={(range) =>
        setPredicate(props.ruleId, {
          right: range,
        })
      }
    />
  );
};

const RangeInput = (props: {
  inputClassName?: string;
  range?: string;
  disabled?: boolean;
  onChange: (range: string) => void;
}) => {
  const [min, max] = parseRange(props.range);

  const onMinChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (integersOrMinusRegex.test(value)) props.onChange(`[${value},${max}]`);
  };

  const onMaxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (integersOrMinusRegex.test(value)) props.onChange(`[${min},${value}]`);
  };

  const inputClasses = cn(
    'w-full text-color-text-input placeholder:text-color-text-placeholder',
    props.inputClassName
  );

  return (
    <div className="flex items-center gap-2">
      <Input
        placeholder={props.disabled ? '' : 'min'}
        dimension="small"
        className={inputClasses}
        value={min}
        onChange={onMinChange}
        disabled={props.disabled}
        onKeyDown={blurOnEnter}
      />
      <span className="text-color-text-primary text-xs">and</span>
      <Input
        placeholder={props.disabled ? '' : 'max'}
        dimension="small"
        className={inputClasses}
        value={max}
        onChange={onMaxChange}
        disabled={props.disabled}
        onKeyDown={blurOnEnter}
      />
    </div>
  );
};

const integersOrMinusRegex = /^-?\d*$/;
