import { AnchorHTMLAttributes, ReactNode } from 'react';
import { Link, To, useLocation } from 'react-router-dom';

import { cn } from '@spektr/style-utils';
import { cva } from 'class-variance-authority';

export type TabbarItemProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  children: ReactNode;
  disabled?: boolean;
  icon?: ReactNode;
  to: To;
};

export const TabbarItem = ({
  className,
  children,
  disabled = false,
  icon,
  role = 'link',
  to,
  ...props
}: TabbarItemProps) => {
  const location = useLocation();

  const isActive =
    typeof to === 'string'
      ? `${location.pathname}${location.search}`.startsWith(to)
      : location.pathname === to.pathname;

  return (
    <Link
      to={to}
      {...props}
      role={role}
      aria-disabled={disabled}
      aria-selected={isActive}
      className={cn(variants({ active: isActive, disabled }), className)}
    >
      {icon}
      {children}
    </Link>
  );
};

const variants = cva(
  [
    'flex flex-row gap-2 items-center shrink-0 px-4 first:pl-0 h-full',
    'text-sm font-semibold',
    'select-none',
    'transition-colors',
  ],
  {
    variants: {
      active: {
        true: 'text-color-text-primary',
        false: 'text-color-text-subtext hover:text-color-text-primary',
      },
      disabled: {
        true: 'opacity-50 cursor-not-allowed pointer-events-none',
        false: '',
      },
    },
    defaultVariants: {
      active: false,
      disabled: false,
    },
  }
);
