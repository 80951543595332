import { Link } from 'react-router-dom';
import { MessageSquareText } from 'lucide-react';

import {
  casesAlertsParamsSchema,
  casesAlertHitCommentsUrl,
} from '@spektr/shared/utils';
import { useParsedParams } from '@spektr/shared/hooks';

import { cn } from '@spektr/style-utils';

type MessageCountProps = {
  hitId: string;
  count: number;
};

export const MessageCount = ({ hitId, count }: MessageCountProps) => {
  const { alertId } = useParsedParams(casesAlertsParamsSchema);

  return (
    <Link
      to={casesAlertHitCommentsUrl(alertId, hitId)}
      className={cn(
        'flex h-9 items-center gap-1 px-2',
        'rounded-md border',
        'text-color-text-subtext text-xs'
      )}
    >
      <MessageSquareText className="h-3 w-3" />
      {count}
    </Link>
  );
};
