import { ReactNode, useState } from 'react';

import { cn } from '@spektr/style-utils';

import { Theme, ThemeProvider } from '@spektr/client/providers';

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogContentProps,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from './common';

export type BasicDialogProps = Omit<DialogContentProps, 'title'> & {
  children: ReactNode;
  className?: string;
  description?: ReactNode;
  icon?: ReactNode;
  defaultOpen?: boolean;
  title: ReactNode;
  titleClassName?: string;
  dialogHeaderClassName?: string;
  dialogContentDataCy?: string;
  renderTitleSlot?: () => ReactNode;
  theme?: Theme;
  onClose?: () => void;
  showCloseButton?: boolean;
};

export function BasicDialog({
  children,
  className,
  description,
  icon,
  defaultOpen,
  renderTitleSlot,
  size,
  showCloseButton = true,
  title,
  titleClassName,
  dialogHeaderClassName,
  dialogContentDataCy,
  theme,
  onClose,
  ...props
}: BasicDialogProps) {
  const [open, setOpen] = useState(defaultOpen);
  const startExitAnimation = () => setOpen(false);

  const stopClickPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <ThemeProvider defaultTheme={theme}>
      <Dialog {...props} open={open}>
        <DialogContent
          onClick={stopClickPropagation}
          className={cn(
            'outline-none',
            'flex flex-col',
            'max-h-[90vh] overflow-y-auto',
            className
          )}
          size={size}
          requestStartExitAnimation={startExitAnimation}
          onEndExitAnimation={onClose}
          onEscapeKeyDown={onClose}
          aria-describedby={undefined}
          data-cy={dialogContentDataCy}
        >
          <DialogHeader
            className={cn(
              'justify-center space-y-0',
              {
                'mb-4': !description,
              },
              dialogHeaderClassName
            )}
          >
            <div className="flex flex-row items-center">
              {icon}
              {/**
               * The title is duplicated to be able to replace the text with a portal
               */}
              <div className="relative">
                <DialogTitle
                  className={cn(
                    'text-color-text-dialog-title text-lg',
                    titleClassName
                  )}
                  data-cy="basicDialog-title"
                >
                  {title}
                </DialogTitle>
                <DialogTitle
                  className={cn(
                    'absolute inset-0 w-full',
                    'text-color-text-dialog-title text-lg',
                    titleClassName
                  )}
                  id="basicDialog-portal-title"
                />
              </div>
              {showCloseButton && (
                <div className="ml-auto flex flex-row gap-4">
                  {renderTitleSlot && renderTitleSlot()}
                  <DialogClose onClick={startExitAnimation} />
                </div>
              )}
            </div>
          </DialogHeader>
          {description && (
            <DialogDescription className="my-4">
              {description}
            </DialogDescription>
          )}
          {children}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
