import { ComponentType, ReactNode } from 'react';

import { cn } from '@spektr/style-utils';
import { BasicDialog, BasicDialogProps } from '@spektr/client/components';

import { commonRenderError } from '../../utils/commonRenderError';

import { useNodeIcon } from '../../hooks/useNodeIcon';
import { useNodeDescription } from '../../hooks/useNodeDescription';
import { FallbackContent } from '../../components/FallbackContent';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import type {
  AlertNode,
  CalculationNode,
  ManualReviewNode,
  ReturningProcessNode,
  MonitoringDatasetNode,
  RouterNode,
  SlackNode,
} from '@spektr/shared/types';
import type {
  AiAmlAlertNode,
  BodaccNode,
  ComplyAdvantageKybNode,
  ComplyAdvantageKycNode,
  KyckrNode,
  MitIdNode,
  OpenCorporatesMonitoringNode,
  OpenCorporatesRiskNode,
  VeriffIdvNode,
  VeriffNode,
  VirkNode,
} from '@spektr/shared/validators';

export namespace WithDialogWrapper {
  type SupportedNodeTypes =
    | CalculationNode
    | RouterNode
    | AlertNode
    | ManualReviewNode
    | ReturningProcessNode
    | MonitoringDatasetNode
    | AiAmlAlertNode
    | SlackNode
    // Services
    | OpenCorporatesMonitoringNode
    | OpenCorporatesRiskNode
    | ComplyAdvantageKybNode
    | ComplyAdvantageKycNode
    | KyckrNode
    | VeriffNode
    | VeriffIdvNode
    | BodaccNode
    | MitIdNode
    | VirkNode;

  export type Props = BaseDialogProps<SupportedNodeTypes>;
}

export const withDialogWrapper = <
  T extends WithDialogWrapper.Props = WithDialogWrapper.Props,
>(
  WrappedComponent: ComponentType<T>,
  FallbackComponent: ReactNode,
  dialogProps?: Pick<BasicDialogProps, 'size' | 'className'>
) => {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithTheme = (props: T) => {
    const Icon = useNodeIcon(props.node.nodeType);
    const description = useNodeDescription(props.node.nodeType);

    return (
      <BasicDialog
        defaultOpen
        size={dialogProps?.size ?? 'large'}
        className={cn('flex min-h-fit flex-col', dialogProps?.className)}
        description={description}
        icon={Icon}
        title={props.node.title}
        onClose={props.onClose}
      >
        <FallbackContent
          renderError={commonRenderError(
            'Something went wrong when loading this node'
          )}
          fallback={FallbackComponent}
        >
          <WrappedComponent {...props} />
        </FallbackContent>
      </BasicDialog>
    );
  };

  ComponentWithTheme.displayName = `withDialogWrapper(${displayName})`;

  return ComponentWithTheme;
};
