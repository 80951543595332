import { z } from 'zod';
import { makeApi, makeEndpoint } from '@zodios/core';

import { paginatedParameters } from '../pagination';

import {
  getAlertHitByIdResponseSchema,
  resolvedDecisionBaseSchema,
} from './hits';

import {
  alertSchemaWithBaseHits,
  AlertsListResponseSchema,
  alertUpdateSchema,
  getAlertByIdResponseSchema,
} from './alert';

const getAllAlerts = makeEndpoint({
  method: 'get',
  path: '/alerts',
  alias: 'listAllAlertsFiltered',
  parameters: [
    {
      name: 'searchQuery',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'userId',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'status',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'alertType',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'age',
      type: 'Query',
      schema: z.number().optional(),
    },
    {
      name: 'createdAtStart',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'createdAtEnd',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'updatedAtStart',
      type: 'Query',
      schema: z.string().optional(),
    },
    {
      name: 'updatedAtEnd',
      type: 'Query',
      schema: z.string().optional(),
    },
    ...paginatedParameters,
  ],
  response: AlertsListResponseSchema,
});

export const getAllAlertsForCustomer = makeEndpoint({
  method: 'get',
  path: '/alertsByClientRecordId/:clientRecordId',
  alias: 'getAllAlertsForCustomer',
  parameters: [
    {
      name: 'clientRecordId',
      schema: z.string(),
      type: 'Path',
    },
    ...paginatedParameters,
  ],
  response: AlertsListResponseSchema,
});

export const getAlertById = makeEndpoint({
  method: 'get',
  path: '/alerts/:alertId',
  alias: 'getAlertById',
  parameters: [
    {
      name: 'alertId',
      schema: z.string(),
      type: 'Path',
    },
    {
      name: 'context',
      schema: z.enum(['global', 'customer']),
      type: 'Query',
    },
  ],
  response: alertSchemaWithBaseHits,
});

export const updateAlertById = makeEndpoint({
  method: 'put',
  path: '/alerts/:alertId',
  alias: 'updateAlertById',
  parameters: [
    {
      name: 'alertId',
      schema: z.string(),
      type: 'Path',
    },
    {
      name: 'input',
      type: 'Body',
      schema: alertUpdateSchema,
    },
    {
      name: 'context',
      schema: z.enum(['global', 'customer']).optional(),
      type: 'Query',
    },
  ],
  response: getAlertByIdResponseSchema,
});

export const getAlertHitById = makeEndpoint({
  method: 'get',
  path: '/alerts/:alertId/hits/:hitId',
  alias: 'getAlertHitById',
  parameters: [
    {
      name: 'alertId',
      schema: z.string(),
      type: 'Path',
    },
    {
      name: 'hitId',
      schema: z.string(),
      type: 'Path',
    },
  ],
  response: getAlertHitByIdResponseSchema,
});

export const updateAlertHitById = makeEndpoint({
  method: 'put',
  path: '/alerts/:alertId/hits/:hitId',
  alias: 'updateAlertHitById',
  parameters: [
    {
      name: 'alertId',
      schema: z.string(),
      type: 'Path',
    },
    {
      name: 'hitId',
      schema: z.string(),
      type: 'Path',
    },
    {
      name: 'input',
      type: 'Body',
      schema: resolvedDecisionBaseSchema,
    },
  ],
  response: getAlertHitByIdResponseSchema,
});

export const alertsApi = makeApi([
  getAllAlerts,
  getAllAlertsForCustomer,
  getAlertById,
  updateAlertById,
  getAlertHitById,
  updateAlertHitById,
]);
