import { forwardRef } from 'react';

import { RBAC } from '@spektr/shared/rbac';

import { cn } from '@spektr/style-utils';

import { Input, type InputProps } from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';

export namespace TitleInput {
  export type Props = InputProps;
}

export const TitleInput = forwardRef<HTMLInputElement, TitleInput.Props>(
  (props, ref) => {
    const { hasPermission } = usePermissionsContext();
    const canEdit = hasPermission(RBAC.ACTIONS.NODE.UPDATE);

    return (
      <Input
        {...props}
        ref={ref}
        className={cn(
          'w-full min-w-60',
          'border-transparent',
          'text-lg font-medium',
          'enabled:hover:border-color-border-input--hover',
          'disabled:text-color-text-input disabled:cursor-default disabled:bg-transparent disabled:opacity-100'
        )}
        disabled={!canEdit || props.disabled}
        dimension="small"
      />
    );
  }
);
