import { ReactNode, useCallback, useMemo, useState } from 'react';
import { produce } from 'immer';

import { ServiceFieldClient, type ClientTrigger } from '@spektr/client/types';
import {
  type SpektrFieldKey,
  type TriggerFrequency,
} from '@spektr/shared/types';

import { TriggerFrequenciesContext } from './context';

export namespace TriggerFrequencies {
  export type Props = {
    children: ReactNode | ReactNode[];
    fields: ServiceFieldClient[];
    initialTriggers: ClientTrigger[];
    nodeId: string;
    processId: string;
  };
}

export const TriggerFrequencies = ({
  children,
  fields,
  initialTriggers,
  nodeId,
  processId,
}: TriggerFrequencies.Props) => {
  const [triggers, setTriggers] = useState(initialTriggers);

  const updateFrequency = useCallback(
    (
      triggers: ClientTrigger[],
      frequency: TriggerFrequency | undefined,
      fieldKey: SpektrFieldKey
    ) => {
      const triggersWithField = triggers.filter((trigger) =>
        trigger.fields?.includes(fieldKey)
      );

      if (triggersWithField.length > 0) {
        triggersWithField.forEach((currentTrigger) => {
          if (currentTrigger && currentTrigger.frequency !== frequency) {
            currentTrigger.fields = currentTrigger.fields?.filter(
              (currentField) => {
                const isFound =
                  currentField === fieldKey ||
                  currentField.startsWith(`${fieldKey}_fields.`);

                return !isFound;
              }
            );
          }
        });
      }

      if (!frequency) {
        return;
      }

      const currentFrequencyTrigger = triggers.find(
        (trigger) => trigger.frequency === frequency
      );

      const fieldsWithProperties = fields
        .map((field) => field.key)
        .filter((key) => key.startsWith(`${fieldKey}_fields.`));

      if (currentFrequencyTrigger) {
        const isFieldAlreadyAdded =
          currentFrequencyTrigger.fields?.includes(fieldKey);

        if (!isFieldAlreadyAdded) {
          currentFrequencyTrigger.fields?.push(
            ...fieldsWithProperties,
            fieldKey
          );
        }
      } else {
        const isFieldAlreadyAdded = triggers.some(
          (trigger) =>
            trigger.frequency === frequency &&
            trigger.fields?.includes(fieldKey)
        );

        if (!isFieldAlreadyAdded) {
          triggers.push({
            nodeId,
            processId,
            frequency,
            fields: [...fieldsWithProperties, fieldKey],
            interval: 1,
            startDate: Date.now(),
          });
        }
      }

      return triggers;
    },
    [fields, nodeId, processId]
  );

  const handleChangeFrequency = useCallback(
    (fieldKey: SpektrFieldKey, frequency: TriggerFrequency | undefined) => {
      setTriggers(
        produce(triggers, (draft) => {
          updateFrequency(draft, frequency, fieldKey);
        })
      );
    },
    [triggers, updateFrequency]
  );

  const getTriggerFrequency = useCallback(
    (fieldKey: SpektrFieldKey) => {
      const trigger = triggers.find((trigger) =>
        trigger.fields?.includes(fieldKey)
      );

      return trigger?.frequency;
    },
    [triggers]
  );

  const valueApi = useMemo(
    () => ({
      triggers,
      updateFrequency: handleChangeFrequency,
      getTriggerFrequency,
    }),
    [getTriggerFrequency, handleChangeFrequency, triggers]
  );

  return (
    <TriggerFrequenciesContext.Provider value={valueApi}>
      {children}
    </TriggerFrequenciesContext.Provider>
  );
};
