import { UserSchema } from '@spektr/shared/validators';

import { Avatar, TextTruncate } from '@spektr/client/components';

type UserInfoProps = {
  user?: UserSchema;
};

export const UserInfo = ({ user }: UserInfoProps) => {
  if (!user) return null;

  return (
    <div className="mt-1 flex items-center gap-2">
      <Avatar
        className="h-5 w-5"
        fallbackClass="text-[8px] bg-color-bg-tertiary dark:bg-color-bg-avatar text-color-text-subtext"
        firstName={user.firstName ?? ''}
        lastName={user.lastName ?? ''}
      />
      <TextTruncate
        text={`${user.firstName} ${user.lastName}`}
        className="line-clamp-1"
      />
    </div>
  );
};
