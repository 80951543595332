import { icons } from 'lucide-react';

import { IconBox } from '@spektr/client/components';

export type SetCustomerStatusNodeProps = {
  title: string;
  fields: Record<string, boolean>;
};

export const SetCustomerStatusNode = ({
  title,
  fields,
}: SetCustomerStatusNodeProps) => {
  const iconName = fields['customerStatus.approved']
    ? 'SquareCheck'
    : 'SquareX';
  const Icon = icons[iconName];

  const iconColor = fields['customerStatus.approved'] ? 'cyan' : 'red';
  return (
    <>
      <IconBox
        size="lg"
        color={iconColor}
        renderIcon={({ className }: { className: string }) => (
          <Icon className={className} />
        )}
      />
      <div className="flex flex-col">
        <h3 className="text-color-text-node line-clamp-1 text-sm font-medium">
          {title}
        </h3>
        <span className="text-color-text-node-subtext text-xs">Status</span>
      </div>
    </>
  );
};
