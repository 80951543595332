import { useController, useFormContext } from 'react-hook-form';

import { Label, Textarea, type TextareaProps } from '@spektr/client/components';

export namespace SlackMessageField {
  export type Props = TextareaProps & {
    name: string;
    isRequired?: boolean;
    title: string;
  };
}

export const SlackMessageField = ({
  name,
  isRequired = false,
  title,
  ...props
}: SlackMessageField.Props) => {
  const context = useFormContext();
  const { field } = useController({
    name,
    control: context.control,
    rules: {
      required: isRequired,
    },
  });

  return (
    <div className="flex flex-col gap-1.5 p-4">
      <Label>{title}</Label>
      <Textarea {...field} {...props} rows={5} cols={10} />
    </div>
  );
};
