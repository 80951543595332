import { z } from 'zod';

import { objectIdSchema } from '../common';

/**
 * EDGE SCHEMAS
 */
export const edgeSchema = z.strictObject({
  id: objectIdSchema,
  name: z.string().optional(),
  label: z.string().optional(),
  type: z
    .string()
    .optional()
    .refine((value) => value === undefined || value === 'fallback'),
  /**
   * Signals that an edge is the last one in the path, it is followed by an outcome node
   * and no intermediate node can be added on it.
   */
  isFinal: z.boolean().nullable().optional(),
});

export type EdgeSchema = z.infer<typeof edgeSchema>;

export const routerEdgeSchema = edgeSchema.merge(
  z.strictObject({
    routeId: objectIdSchema,
  })
);
