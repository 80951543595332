import { Link } from 'react-router-dom';

import { UserSchema } from '@spektr/shared/validators';
import { casesCustomerAlertDetailsUrl } from '@spektr/shared/utils';

import { TextTruncate } from '@spektr/client/components';

import { CustomerTimelineCell } from '../CustomerTimelineCell';
import { CustomerTimelineDateCell } from '../CustomerTimelineDateCell';

import { UserInfo } from './UserInfo';

export type AlertEscalatedEventProps = {
  // TODO: @Alex - update with the new type after be is ready
  event: any;
  users: UserSchema[];
};

export const AlertEscalatedEvent = ({
  event,
  users,
}: AlertEscalatedEventProps) => {
  const user = users.find((u) => u.id === event.eventData.userId);

  return (
    <>
      <CustomerTimelineCell
        iconName="CircleAlert"
        iconColor="red"
        className="col-span-4"
        label="Alert Escalated"
        value={
          <Link
            to={casesCustomerAlertDetailsUrl(
              event.spektrId,
              event.eventData.alertId
            )}
          >
            <TextTruncate
              className="line-clamp-1"
              text={event.eventData.alertName ?? '-'}
            />
          </Link>
        }
      />
      <CustomerTimelineCell
        className="col-span-4"
        label="Escalated by"
        value={<UserInfo user={user} />}
      />
      <CustomerTimelineDateCell date={event.createdAt} />
    </>
  );
};
