import { SHORT_DATE_FORMAT } from '@spektr/client/constants';
import { format, isValid } from 'date-fns';

export const formatCustomerDate = (value: unknown): string => {
  const numberValue = Number(value);

  if (isValid(numberValue)) {
    return format(Number(value), SHORT_DATE_FORMAT);
  }

  const date = new Date(value as string);

  return isValid(date) ? format(date, SHORT_DATE_FORMAT) : 'Invalid date';
};
