import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Handle, Position } from 'reactflow';
import { ServerCog } from 'lucide-react';

import { PROCESS_FILTER_URL } from '@spektr/shared/utils';

import { cn } from '@spektr/style-utils';
import { NODE } from '@spektr/shared/components';
import { IconBox } from '@spektr/client/components';

export const ProcessFilterNode = memo(() => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(PROCESS_FILTER_URL);
  };

  return (
    <div
      className={cn(
        'flex flex-col gap-4 p-3',
        'rounded-lg border',
        'bg-color-bg-process-builder-item',
        'cursor-pointer duration-200',
        'hover:border-color-blue hover:shadow-lg'
      )}
      style={{
        width: NODE.WIDTH,
        height: NODE.HEIGHT,
      }}
      onClick={handleClick}
      data-cy="filter-node"
    >
      <div className="flex h-full items-center gap-3">
        <IconBox
          size="lg"
          color="cyan"
          renderIcon={({ className }) => <ServerCog className={className} />}
        />
        <div className="flex flex-col">
          <h3 className="text-color-text-primary text-sm font-medium">
            Process
          </h3>
          <span className="text-color-text-subtext text-xs">Settings</span>
        </div>
      </div>
      <Handle
        type="source"
        id="root"
        position={Position.Right}
        isConnectable={false}
        className={cn(
          'h-2 w-2',
          'border-color-border-process-builder-item border',
          'bg-color-bg-process-builder-item'
        )}
      />
    </div>
  );
});
