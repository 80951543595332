import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import {
  getProcessOutcomesWithTitle,
  isKyckrOutcome,
} from '@spektr/shared/utils';

import { getProcessLinksByIdQuery } from '@spektr/client/services';

import { Select } from '@spektr/client/components';

import { Process } from '@spektr/shared/types';

import { SectionLabel } from '../../components/SectionLabel';

export type OutcomeSelectDropdownProps = {
  selectedProcess?: Process;
  selectedOutcomeId?: string;
  onChange: (value: string) => void;
};

export const OutcomeSelectDropdown = ({
  selectedProcess,
  selectedOutcomeId,
  onChange,
}: OutcomeSelectDropdownProps) => {
  const { t } = useTranslation();
  const selectedProcessId = selectedProcess?.id ?? '';
  const { data: links } = useQuery({
    ...getProcessLinksByIdQuery(selectedProcessId),
    enabled: !!selectedProcessId,
  });

  const possibleOutcomes = useMemo(() => {
    if (selectedProcess) {
      return getProcessOutcomesWithTitle(selectedProcess).map((outcome) => ({
        value: outcome.id,
        label: isKyckrOutcome(selectedProcess, outcome.id)
          ? t(`process:nodes.kyckr.outcome.name.${outcome.title}`)
          : outcome.title,
        disabled:
          links && links.some((link) => link.source.outcomeId === outcome.id),
      }));
    }

    return [];
  }, [links, selectedProcess]);

  return (
    <div className="col-span-5 flex flex-col gap-2">
      <SectionLabel>Choose which outcome from this process</SectionLabel>
      <Select
        required
        name="outcome"
        key={selectedProcessId}
        options={possibleOutcomes}
        placeholder="Select from dropdown..."
        disabled={!selectedProcessId}
        value={selectedOutcomeId}
        onValueChange={onChange}
      />
    </div>
  );
};
