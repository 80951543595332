import {
  AlertBaseWithHits,
  HitWithVendorDetails,
} from '@spektr/shared/validators';

export const computeExtendedHits = (
  hits: HitWithVendorDetails[],
  alert: AlertBaseWithHits
) =>
  hits.map((hit) => ({
    ...hit,
    nodeId: alert.nodeId,
    processId: alert.processId,
    spektrId: alert.spektrId,
    datasetId: alert.datasetId,
  }));
