import z from 'zod';

export const SortOrder = z.enum(['asc', 'desc']);
export type SortOrder = z.infer<typeof SortOrder>;

export const FilterOptions = z
  .object({
    sortOrder: SortOrder.optional(),
    sortField: z.string().optional(),
    searchField: z.string().optional(),
    searchValue: z.coerce.string().optional(),
  })
  .superRefine((val, ctx) => {
    if (val.searchValue && !val.searchField) {
      return ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'searchField is required when searchValue is defined',
        path: ['searchField'],
      });
    }
  });
export type FilterOptions = z.infer<typeof FilterOptions>;
export const alertPresenceEnum = z.enum(['showAlertsOnly', 'showNoAlertsOnly']);
export type alertPresenceEnum = z.infer<typeof alertPresenceEnum>;

export const ClientRecordFilter = z.object({
  status: z.string().array().optional(),
  tags: z.string().array().optional(),
  alertPresence: alertPresenceEnum.optional(),
  alertType: z.string().array().optional(),
  isUbo: z.string().optional().optional(),
  datasetIds: z.string().array().optional(),
  createdAtStart: z.string().optional(),
  createdAtEnd: z.string().optional(),
  updatedAtStart: z.string().optional(),
  updatedAtEnd: z.string().optional(),
  searchQuery: z.string().optional(),
});
export type ClientRecordFilter = z.infer<typeof ClientRecordFilter>;
