import { useMemo } from 'react';

import { indicatorTypes } from '@spektr/shared/validators';

import { useTranslation } from 'react-i18next';

import { Indicator } from '../types';
import { getIndicatorValueType } from '../constants';
import { useCustomerDetails } from '../providers/CustomerDetailsProvider';

export const useGetCustomerIndicators = () => {
  const { customer } = useCustomerDetails();
  const { t } = useTranslation('cases');

  return useMemo<Indicator[]>(
    () =>
      indicatorTypes
        .filter((indicatorType) => {
          switch (indicatorType) {
            case 'has_business_verification':
              return customer.customerType === 'company';
            case 'has_id_verification':
              return customer.customerType === 'individual';
            case 'is_pep':
              return customer.customerType === 'individual';
            default:
              return true;
          }
        })
        .map((filteredType) => {
          const type = getIndicatorValueType(filteredType);
          const isServiceIndicator = type === 'service';
          const indicator = customer.indicators[filteredType];

          return {
            ...indicator,
            type,
            label: t(`customers.indicators.${filteredType}`),
            value: !isServiceIndicator ? indicator?.value : undefined,
            state: isServiceIndicator ? indicator?.state : undefined,
          };
        }),
    [customer, t]
  );
};
