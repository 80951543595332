import { useTranslation } from 'react-i18next';
import {
  Files,
  FlagTriangleLeft,
  GitCompare,
  ListTodo,
  Locate,
  MessageCircle,
} from 'lucide-react';

import {
  casesCustomerAlertsUrl,
  casesCustomerCommentsUrl,
  casesCustomerConnectionsUrl,
  casesCustomerDetailsUrl,
  casesCustomerDocumentsUrl,
  casesCustomerOwnershipUrl,
  casesCustomerParamsSchema,
} from '@spektr/shared/utils';
import { useParsedParams } from '@spektr/shared/hooks';
import { useFeatureFlags } from '@spektr/platform-hooks';

import { Tabbar, TabbarItem } from '@spektr/client/components';

export const CasesCustomerTabNavigation = () => {
  const { uboConnections } = useFeatureFlags();
  const { t } = useTranslation('cases');
  const { spektrId: customerId } = useParsedParams(casesCustomerParamsSchema);

  return (
    <Tabbar className="border-none">
      <TabbarItem
        icon={<FlagTriangleLeft className="h-3 w-3" />}
        to={{ pathname: casesCustomerDetailsUrl(customerId) }}
      >
        {t('customerDetails.navigation.timeline', { defaultValue: 'Timeline' })}
      </TabbarItem>
      <TabbarItem
        icon={<ListTodo className="h-3 w-3" />}
        to={{ pathname: casesCustomerAlertsUrl(customerId) }}
      >
        {t('customerDetails.navigation.alerts', { defaultValue: 'Alerts' })}
      </TabbarItem>
      <TabbarItem
        icon={<Files className="h-3 w-3" />}
        to={{ pathname: casesCustomerDocumentsUrl(customerId) }}
      >
        {t('customerDetails.navigation.documents', {
          defaultValue: 'Documents',
        })}
      </TabbarItem>
      <TabbarItem
        icon={<Locate className="h-3 w-3" />}
        to={{ pathname: casesCustomerOwnershipUrl(customerId) }}
      >
        {t('customerDetails.navigation.ownership', {
          defaultValue: 'Ownership',
        })}
      </TabbarItem>
      <TabbarItem
        icon={<GitCompare className="h-3 w-3" />}
        disabled={!uboConnections}
        to={{ pathname: casesCustomerConnectionsUrl(customerId) }}
      >
        {t('customerDetails.navigation.connections', {
          defaultValue: 'Connections',
        })}
      </TabbarItem>
      <TabbarItem
        icon={<MessageCircle className="h-3 w-3" />}
        to={{ pathname: casesCustomerCommentsUrl(customerId) }}
      >
        {t('customerDetails.navigation.comments', { defaultValue: 'Comments' })}
      </TabbarItem>
    </Tabbar>
  );
};
