import { Suspense } from 'react';

import { HitData } from '../containers/HitData';
import { SkeletonTable } from '../components/SkeletonTable';

export const AdditionalHitInfo = () => {
  return (
    <Suspense fallback={<SkeletonTable />}>
      <HitData />
    </Suspense>
  );
};
