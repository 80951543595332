import { cn } from '@spektr/style-utils';

import { Skeleton } from '@spektr/client/components';

import { GridItem } from '../GridItem';

export const SkeletonAlertDescription = () => {
  return (
    <div
      className={cn(
        'min-h-[101px] py-4',
        'grid grid-cols-12 gap-9',
        'rounded-md border',
        'color-bg-card-default'
      )}
    >
      <GridItem className="col-span-2 justify-center border-r px-4">
        <Skeleton />
      </GridItem>

      <GridItem className="col-span-4">
        <div className="flex flex-col gap-2">
          <Skeleton className="w-28" />
          <Skeleton className="w-44" />
        </div>
      </GridItem>
      <GridItem className="col-span-3 justify-center">
        <div className="flex flex-col gap-2">
          <Skeleton />
          <Skeleton className="h-6 w-28" />
        </div>
      </GridItem>
      <GridItem className="col-span-3 justify-center">
        <div className="flex flex-col gap-2">
          <Skeleton />
          <Skeleton className="h-6 w-28" />
        </div>
      </GridItem>
    </div>
  );
};
