import { cn } from '@spektr/style-utils';

import { ButtonProps } from '../Button';

import { PaginationItem } from './PaginationItem';

export type PaginationLinkProps = {
  isActive?: boolean;
} & Pick<ButtonProps, 'size'> &
  React.ComponentProps<'a'>;

export const PaginationLink = ({
  className,
  isActive,
  children,
  ...props
}: PaginationLinkProps) => (
  <PaginationItem>
    <a
      aria-current={isActive ? 'page' : undefined}
      className={cn(
        'flex items-center justify-center',
        'h-8 w-8 rounded border',
        'text-color-text-primary text-xs',
        'border-color-border-primary',
        '[&:not(:aria-disabled)]:hover:bg-color-bg-pagination--hover/20 aria-disabled:opacity-50',
        'bg-color-bg-pagination hover:bg-color-bg-pagination--hover',
        isActive && 'bg-spektr-zinc-800',
        'aria-disabled:bg-color-bg-pagination aria-disabled:opacity-50',
        'cursor-pointer aria-disabled:cursor-not-allowed',
        className
      )}
      {...props}
    >
      {children}
    </a>
  </PaginationItem>
);
