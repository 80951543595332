import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { CASES_CUSTOMERS_FULL_URL, CASES_URL } from '@spektr/shared/utils';

import { HeaderWithHandler } from '../../components/HeaderWithHandler';

export const CasesIndexPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === CASES_URL) {
      navigate(CASES_CUSTOMERS_FULL_URL, { replace: true });
    }
  }, [location.pathname, navigate]);

  return (
    <>
      <HeaderWithHandler />
      <div className="relative flex-grow">
        <Outlet />
      </div>
    </>
  );
};
