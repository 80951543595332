import { useMeasure, useWindowSize } from 'react-use';

import { Button, ScrollArea } from '@spektr/client/components';

import { useServiceAlertNodeContext } from '../../providers/ServiceAlertNodeProvider';
import { getSelectedFieldValue } from '../../utils';

import { EmptyState } from '../../components/EmptyState';
import { ServiceAlert } from '../../components/ServiceAlert';

export const AlertFieldsList = () => {
  const { alerts, createAlert } = useServiceAlertNodeContext();
  const [ref, { height: contentHeight }] = useMeasure<HTMLDivElement>();
  const { height: windowHeight } = useWindowSize();

  const percentageHeight = windowHeight * 0.7;

  return (
    <ScrollArea
      className="-mr-6"
      style={{
        height:
          contentHeight > percentageHeight ? percentageHeight : contentHeight,
      }}
    >
      <div className="mr-6 flex flex-col gap-6" ref={ref}>
        {alerts.length === 0 ? (
          <EmptyState />
        ) : (
          alerts.map((alert, index) => {
            return (
              <ServiceAlert
                key={`${alert.nodeId}-${alert.name}-${index}`}
                index={index}
                name={alert.name}
                field={getSelectedFieldValue(alert)}
              />
            );
          })
        )}
        <Button className="self-center" color="secondary" onClick={createAlert}>
          Add Service Alert Trigger
        </Button>
      </div>
    </ScrollArea>
  );
};
