import { useTranslation } from 'react-i18next';

import { Input } from '@spektr/client/components';

import { useDebouncedSearch } from '@spektr/platform-hooks';

import { useEnrichedServiceFields } from '../../providers/EnrichedServiceFields';

import { CollapsibleFieldsGroup } from './components/CollapsibleFieldsGroup';

type CustomerProfileEnrichmentFieldsProps = {
  title: string;
};

export const CustomerProfileEnrichmentFields = ({
  title,
}: CustomerProfileEnrichmentFieldsProps) => {
  const { t } = useTranslation('fields');
  const {
    groupedFields,
    nodeType,
    selectedFieldsCount,
    debouncedSearchValue,
    handleFieldSelectionChange,
    handleFieldSearch,
  } = useEnrichedServiceFields();
  const { ungroupedFields, ...rest } = groupedFields;
  const { searchValue, setSearchValue } = useDebouncedSearch(
    '',
    handleFieldSearch
  );

  return (
    <div className="flex flex-col text-sm">
      <div className="flex items-center gap-2.5">
        <p className="text-color-text-subtext">{title}</p>
        <span>{`(${selectedFieldsCount} selected)`}</span>
        <Input
          value={searchValue}
          className="ml-auto w-52"
          placeholder="Search for a field..."
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <div className="flex flex-col" key={debouncedSearchValue}>
        {Object.entries(rest).map(([key, subfields]) => (
          <CollapsibleFieldsGroup
            key={key}
            id={key}
            label={t(`${nodeType}.${key}`)}
            fields={subfields}
            onFieldSelectionChange={handleFieldSelectionChange}
          />
        ))}
        {ungroupedFields && (
          <CollapsibleFieldsGroup
            id="ungroupedFields"
            label="Others"
            fields={ungroupedFields}
            onFieldSelectionChange={handleFieldSelectionChange}
          />
        )}
      </div>
    </div>
  );
};
