import { SpektrFieldWithInfo } from '../../goldenRecord';
import { commonSpektrFields } from '../commonSpektrFields/commonSpektrFields';

export enum VeriffIdvCommonFieldKey {
  verificationStatus,
  verificationReason,
  personFirstName,
  personLastName,
  personGender,
  personCitizenship,
  personDateOfBirth,
  personNationality,
  personYearOfBirth,
  personPlaceOfBirth,
  personPepSanctionMatch,
  documentType,
  documentState,
  documentNumber,
  documentCountry,
  documentValidFrom,
  documentValidUntil,
}

export const veriffIdvCommonSpektrFields: Record<
  VeriffIdvCommonFieldKey,
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = {
  [VeriffIdvCommonFieldKey.verificationStatus]: {
    key: 'veriffIdv_verification_status',
    label: 'Veriff IDV verification status',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [],
  },
  [VeriffIdvCommonFieldKey.verificationReason]: {
    key: 'veriffIdv_verification_reason',
    label: 'Veriff IDV verification reason',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [],
  },
  [VeriffIdvCommonFieldKey.personFirstName]: {
    key: 'veriffIdv_person_firstName',
    label: 'Veriff IDV first name',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [commonSpektrFields.firstName.key],
  },
  [VeriffIdvCommonFieldKey.personLastName]: {
    key: 'veriffIdv_person_lastName',
    label: 'Veriff IDV last name',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [commonSpektrFields.lastName.key],
  },
  [VeriffIdvCommonFieldKey.personGender]: {
    key: 'veriffIdv_person_gender',
    label: 'Veriff IDV gender',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [commonSpektrFields.gender.key],
  },
  [VeriffIdvCommonFieldKey.personCitizenship]: {
    key: 'veriffIdv_person_citizenship',
    label: 'Veriff IDV citizenship',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [commonSpektrFields.citizenship.key],
  },
  [VeriffIdvCommonFieldKey.personDateOfBirth]: {
    key: 'veriffIdv_person_dateOfBirth',
    label: 'Veriff IDV date of birth',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [commonSpektrFields.dateOfBirth.key],
  },
  [VeriffIdvCommonFieldKey.personNationality]: {
    key: 'veriffIdv_person_nationality',
    label: 'Veriff IDV nationality',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [commonSpektrFields.nationality.key],
  },
  [VeriffIdvCommonFieldKey.personYearOfBirth]: {
    key: 'veriffIdv_person_yearOfBirth',
    label: 'Veriff IDV year of birth',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [commonSpektrFields.yearOfBirth.key],
  },
  [VeriffIdvCommonFieldKey.personPlaceOfBirth]: {
    key: 'veriffIdv_person_placeOfBirth',
    label: 'Veriff IDV place of birth',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [commonSpektrFields.placeOfBirth.key],
  },
  [VeriffIdvCommonFieldKey.personPepSanctionMatch]: {
    key: 'veriffIdv_person_pepSanctionMatch',
    label: 'Veriff IDV PEP sanction match',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [],
  },
  [VeriffIdvCommonFieldKey.documentType]: {
    key: 'veriffIdv_document_type',
    label: 'Veriff IDV document type',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [],
  },
  [VeriffIdvCommonFieldKey.documentState]: {
    key: 'veriffIdv_document_state',
    label: 'Veriff IDV document state',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [],
  },
  [VeriffIdvCommonFieldKey.documentNumber]: {
    key: 'veriffIdv_document_number',
    label: 'Veriff IDV document number',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [],
  },
  [VeriffIdvCommonFieldKey.documentCountry]: {
    key: 'veriffIdv_document_country',
    label: 'Veriff IDV document country',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [],
  },
  [VeriffIdvCommonFieldKey.documentValidFrom]: {
    key: 'veriffIdv_document_validFrom',
    label: 'Veriff IDV document valid from',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [],
  },
  [VeriffIdvCommonFieldKey.documentValidUntil]: {
    key: 'veriffIdv_document_validUntil',
    label: 'Veriff IDV document valid until',
    type: 'string',
    source: 'veriffIdv',
    commonSpektrFieldKeys: [],
  },
};

export const veriffIdvSpektrFields = [
  ...Object.values(veriffIdvCommonSpektrFields),
];
