export function getTeamMembersQueryKey(customAttr?: string[]) {
  if (customAttr && customAttr.length) {
    return ['users', customAttr.join('-')];
  }
  return ['users'];
}

export function getSpektrAiUserQueryKey() {
  return ['users', 'spektr-ai'];
}

export const USERS_KEYS = {
  BRANDING_THEME: () => ['users', 'branding-theme'] as const,
};
