import { ReactNode, useState } from 'react';

import { cn } from '@spektr/style-utils';

import { Theme, ThemeProvider } from '@spektr/client/providers';

import { Dialog, DialogProps, DialogContent } from './common';

export type FullPageDialogProps = DialogProps & {
  children: ReactNode | ReactNode[];
  className?: string;
  defaultOpen?: boolean;
  theme?: Theme;
  onClose?: () => void;
};

export const FullPageDialog = ({
  children,
  className,
  defaultOpen = false,
  modal,
  theme,
  onClose,
  ...dialogProps
}: FullPageDialogProps) => {
  const [open, setOpen] = useState(defaultOpen);
  const startExitAnimation = () => setOpen(false);

  return (
    <ThemeProvider defaultTheme={theme}>
      <Dialog {...dialogProps} modal={modal} open={open}>
        <DialogContent
          className={cn('flex flex-col', className)}
          modal={modal}
          requestStartExitAnimation={startExitAnimation}
          size="full"
          onEndExitAnimation={onClose}
          onEscapeKeyDown={onClose}
        >
          {children}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
};
