import { useFlags } from 'launchdarkly-react-client-sdk';

export type FeatureFlags = {
  moonraker: boolean;
  monitoringDataset: boolean;
  ownershipStructure: boolean;
  fallbackNode: boolean;
  processTriggers: boolean;
  uboConnections: boolean;
  newServiceConfig: boolean;
  processRuns: boolean;
  enrichmentProcess: boolean;
  veriffIdv: boolean;
  virkCvr: boolean;
};

export function useFeatureFlags() {
  const flags = useFlags<FeatureFlags>();

  return flags;
}
