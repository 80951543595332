import { AlertStatus, SpektrAI, UserSchema } from '@spektr/shared/validators';

import { cn } from '@spektr/style-utils';
import {
  AlertAge,
  Avatar,
  CasesStatus,
  SpektrAIStatus,
} from '@spektr/client/components';

import { GridItem } from '../GridItem/GridItem';

type AlertDescriptionProps = {
  createdDate: number;
  name?: string;
  user?: UserSchema;
  aiInfo?: SpektrAI;
  status: AlertStatus;
};

export const AlertDescription = ({
  createdDate,
  name = 'Alert',
  user,
  aiInfo,
  status,
}: AlertDescriptionProps) => {
  return (
    <div
      className={cn(
        'min-h-[101px] py-4',
        'grid grid-cols-12 gap-9',
        'rounded-md border',
        'bg-color-bg-dialog-content dark:bg-color-bg-dialog-content/20'
      )}
    >
      <GridItem className="col-span-2 justify-center border-r px-4">
        <AlertAge createdAt={createdDate} isResolved={status === 'resolved'} />
      </GridItem>

      <GridItem className="col-span-4">
        <div className="flex flex-col gap-2">
          <p className="text-base font-medium">{name}</p>
          {user && (
            <p className="text-color-text-subtext flex items-center gap-1 text-sm">
              Assigned to{' '}
              <span className="flex items-center gap-1">
                <Avatar
                  className="h-5 w-5"
                  fallbackClass="text-[8px]"
                  firstName={user.firstName ?? ''}
                  lastName={user.lastName ?? ''}
                />
                <span className="text-color-text-primary">{`${user.firstName} ${user.lastName}`}</span>
              </span>
            </p>
          )}
        </div>
      </GridItem>
      <GridItem className="col-span-3 justify-center">
        {aiInfo && (
          <div className="flex flex-col gap-1 text-xs">
            <p className="text-color-text-subtext font-medium">spektrAI</p>
            <SpektrAIStatus label={aiInfo.info} type={aiInfo.status} />
          </div>
        )}
      </GridItem>
      <GridItem className="col-span-3 justify-center">
        <div className="flex flex-col gap-1 text-xs">
          <p className="text-color-text-subtext font-medium">Status</p>
          <CasesStatus status={status} />
        </div>
      </GridItem>
    </div>
  );
};
