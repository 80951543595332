import { Outlet, useNavigate } from 'react-router-dom';

import { useSuspenseQuery } from '@tanstack/react-query';

import {
  useComputeAlertsTableData,
  usePaginationOptions,
} from '@spektr/platform-hooks';

import {
  casesCustomerAlertDetailsUrl,
  casesCustomerParamsSchema,
} from '@spektr/shared/utils';
import { useParsedParams } from '@spektr/shared/hooks';
import { Alert } from '@spektr/shared/validators';

import { getAllAlertsForCustomer } from '@spektr/client/services';
import { AlertsTable, PaginationCombobox } from '@spektr/client/components';

type CustomerAlertsListProps = {
  columnsToDisplay: string[];
};

export const CustomerAlertsList = ({
  columnsToDisplay,
}: CustomerAlertsListProps) => {
  const { page, setPage, pageLimit, setLimit } = usePaginationOptions();
  const navigate = useNavigate();
  const { spektrId: customerId } = useParsedParams(casesCustomerParamsSchema);

  const { data: alerts } = useSuspenseQuery(
    getAllAlertsForCustomer(customerId, {
      page,
      limit: pageLimit,
    })
  );

  const tableAlerts = useComputeAlertsTableData(
    alerts.docs as unknown as Alert[]
  );

  const handleRowClick = (id: string) => {
    navigate(casesCustomerAlertDetailsUrl(customerId, id));
  };

  return (
    <div className="flex h-full flex-col items-end gap-4">
      <AlertsTable
        alerts={tableAlerts}
        columns={columnsToDisplay}
        onRowClick={handleRowClick}
      />

      <PaginationCombobox
        currentPage={page}
        totalPages={alerts.totalPages}
        pageLimit={pageLimit}
        alignment="end"
        onChangeLimit={setLimit}
        onChangePage={setPage}
      />

      <Outlet />
    </div>
  );
};
