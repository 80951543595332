import { useSelected } from 'slate-react';

import { cn } from '@spektr/style-utils';

import { type SlateLinkElement } from '@spektr/shared/slate-types';

import { InlineChromiumBugfix } from './InlineChromiumBugfix';

type LinkComponentProps = {
  attributes: any;
  children: any;
  element: SlateLinkElement;
};

export const LinkComponent = ({
  attributes,
  children,
  element,
}: LinkComponentProps) => {
  const selected = useSelected();

  return (
    <a
      {...attributes}
      href={element.url}
      target="_blank"
      rel="noopener noreferrer"
      className={cn('underline hover:text-opacity-80', selected && 'ring-1')}
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  );
};
