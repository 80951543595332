import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';

import {
  getServiceButtonColor,
  ServiceButton,
} from '@spektr/client/components';
import { VendorServiceName } from '@spektr/client/types';

import { ServiceCard } from '../ServiceCard/ServiceCard';
import { ServiceCardRequestedStatus } from '../ServiceCardRequestedStatus';
import { ServiceCardConfigurationStatus } from '../ServiceCardConfigurationStatus';

import { ServiceWithConfiguration } from '../../types/ServiceWithConfiguration';
import { hasSecret } from '../../helpers/vendorSecretHelpers';

export type ServiceCardWithActionProps = {
  service: ServiceWithConfiguration;
  onClick: (
    ev: MouseEvent<HTMLButtonElement>,
    serviceName: VendorServiceName
  ) => void;
};

export const ServiceCardWithAction = ({
  service,
  onClick,
}: ServiceCardWithActionProps) => {
  if (service.available) {
    if (hasSecret(service)) {
      return (
        <Link to={service.id}>
          <ServiceCard
            serviceName={service.id}
            className="bg-color-bg-primary hover:bg-color-bg-accent/20 transition-colors"
          >
            <ServiceCardConfigurationStatus
              updatedBy={service?.configuration?.updatedBy || ''}
              updatedAt={service?.configuration?.updatedAt}
            />
          </ServiceCard>
        </Link>
      );
    }

    return (
      <Link to={service.id}>
        <ServiceCard
          serviceName={service.id}
          className="hover:bg-color-bg-accent/20 transition-colors"
        >
          <div>
            <ServiceButton
              color={getServiceButtonColor(service.id)}
              onClickCapture={(ev) => onClick(ev, service.id)}
            >
              Set up service
            </ServiceButton>
          </div>
        </ServiceCard>
      </Link>
    );
  }

  if (service.requestedAt) {
    return (
      <ServiceCard serviceName={service.id} className="border-dashed">
        <ServiceCardRequestedStatus requestedAt={service.requestedAt} />
      </ServiceCard>
    );
  }

  return (
    <ServiceCard serviceName={service.id} className="border-dashed">
      <div>
        <ServiceButton
          color={getServiceButtonColor(service.id)}
          onClickCapture={(ev) => onClick(ev, service.id)}
        >
          Request access
        </ServiceButton>
      </div>
    </ServiceCard>
  );
};
