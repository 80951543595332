import { HTMLAttributes } from 'react';

export const VirkMinimalIcon = (props: HTMLAttributes<SVGElement>) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.5"
      y="0.5"
      width="35"
      height="35"
      rx="5.5"
      fill="#0059B3"
      fill-opacity="0.2"
      stroke="#0059B3"
    />
    <path
      d="M17.6838 11C18.797 11 19.6991 11.9536 19.6991 13.1303C19.6991 14.3061 18.797 15.2601 17.6838 15.2601C16.5707 15.2601 15.6685 14.3061 15.6685 13.1303C15.6685 11.9536 16.5707 11 17.6838 11Z"
      fill="#0059B3"
    />
    <path
      d="M13.0204 18.9918C13.0204 20.1657 12.1197 21.1182 11.0096 21.1182C9.89952 21.1182 9 20.1657 9 18.9918C9 17.8197 9.8999 16.8683 11.0096 16.8683C12.1201 16.8683 13.0204 17.82 13.0204 18.9918Z"
      fill="#0059B3"
    />
    <path
      d="M19.7237 18.4014C19.7237 19.6037 18.8039 20.5739 17.6683 20.5739C16.5304 20.5739 15.6104 19.6037 15.6104 18.4014C15.6104 17.2017 16.5307 16.2262 17.6683 16.2262C18.8039 16.2262 19.7237 17.2017 19.7237 18.4014Z"
      fill="#0059B3"
    />
    <path
      d="M26.3778 18.9765C26.3778 20.1811 25.4514 21.1578 24.311 21.1578C23.1725 21.1578 22.2466 20.1808 22.2466 18.9765C22.2466 17.7709 23.1725 16.7927 24.311 16.7927C25.4514 16.7927 26.3778 17.7709 26.3778 18.9765Z"
      fill="#0059B3"
    />
    <path
      d="M19.7198 23.4934C19.7198 24.6937 18.8009 25.6682 17.6644 25.6682C16.527 25.6682 15.6064 24.6937 15.6064 23.4934C15.6064 22.2932 16.5274 21.3186 17.6644 21.3186C18.8009 21.3186 19.7198 22.2932 19.7198 23.4934Z"
      fill="#0059B3"
    />
    <path
      d="M24.7329 23.7949C24.7329 25.0146 23.7983 25.9999 22.6457 25.9999C21.4964 25.9999 20.561 25.0146 20.561 23.7949C20.561 22.5779 21.4964 21.5917 22.6457 21.5917C23.7983 21.5917 24.7329 22.5783 24.7329 23.7949Z"
      fill="#0059B3"
    />
    <path
      d="M12.6904 21.6783C13.7936 21.6783 14.6883 22.6236 14.6883 23.7896C14.6883 24.9562 13.7936 25.9011 12.6904 25.9011C11.5871 25.9011 10.6924 24.9562 10.6924 23.7896C10.6924 22.6236 11.5871 21.6783 12.6904 21.6783Z"
      fill="#0059B3"
    />
  </svg>
);
