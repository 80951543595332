import { useController, useFormContext } from 'react-hook-form';

import { PasswordInput, type InputProps } from '@spektr/client/components';

import { SlackFieldRow } from '../SlackFieldRow';

export namespace SlackProtectedInputField {
  export type Props = InputProps & {
    name: string;
    isRequired?: boolean;
    title: string;
  };
}

export const SlackProtectedInputField = ({
  name,
  isRequired = false,
  title,
  ...props
}: SlackProtectedInputField.Props) => {
  const context = useFormContext();
  const { field } = useController({
    name,
    control: context.control,
    rules: { required: isRequired },
  });

  return (
    <SlackFieldRow isRequired={isRequired} title={title}>
      <PasswordInput {...field} {...props} dimension="small" />
    </SlackFieldRow>
  );
};
