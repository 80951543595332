import { toast } from '@spektr/client/components';
import { UsersApiClient, VENDOR_SETTINGS_KEYS } from '@spektr/client/services';
import { useGoToServiceCatalog } from '@spektr/shared/hooks';
import { hasErrorMessage } from '@spektr/shared/utils';
import { VendorSettingsConnUpdate } from '@spektr/shared/validators';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useUpdateVendorSettings() {
  const queryClient = useQueryClient();
  const goToServiceCatalog = useGoToServiceCatalog();

  const mutation = useMutation({
    mutationFn: async (data: {
      body: VendorSettingsConnUpdate;
      vendor: string;
    }) => {
      await UsersApiClient.getClient().updateVendorSettingsByVendor(data.body, {
        params: {
          vendor: data.vendor,
        },
      });
      return data.vendor;
    },
    onSuccess: (vendor: string) => {
      queryClient.invalidateQueries({
        queryKey: VENDOR_SETTINGS_KEYS.ALL(),
      });
      queryClient.invalidateQueries({
        queryKey: VENDOR_SETTINGS_KEYS.BY_VENDOR(vendor),
      });
      goToServiceCatalog();
    },
    onError: (error: Error) => {
      const message = hasErrorMessage(error)
        ? error.message
        : 'Something went wrong. Please try again.';

      toast.error({
        title: 'Something went wrong!',
        description: message,
      });
    },
  });

  return mutation;
}
