import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FlaskRound } from 'lucide-react';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  Button,
  buttonVariants,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Spinner,
} from '@spektr/client/components';
import { useFeatureFlags } from '@spektr/platform-hooks';
import {
  ProcessApiClient,
  getSandboxExecutionById,
} from '@spektr/client/services';
import { usePublicDomain } from '@spektr/client/providers';

export type ProcessDemoButtonProps = {
  processId: string;
};
export const ProcessDemoButton = ({ processId }: ProcessDemoButtonProps) => {
  const { moonraker } = useFeatureFlags();
  const [url, setUrl] = useState('');
  const [sandboxExecutionId, setSandboxExecutionId] = useState('');
  const [token, setToken] = useState('');

  const { getSkyhubUrl } = usePublicDomain();

  const sandboxExecutionQuery = useQuery({
    ...getSandboxExecutionById(processId, sandboxExecutionId),
    enabled: sandboxExecutionId !== '',
    retryDelay: 500,
    retry: 10,
  });

  useEffect(() => {
    if (!sandboxExecutionQuery.data?.token) return; // dont open new tab if the token is not defined
    if (sandboxExecutionQuery.data.token === token) return; // avoid duplicate tab openings
    setToken(sandboxExecutionQuery.data?.token);

    const sandboxUrl = new URL(getSkyhubUrl());

    sandboxUrl.searchParams.append('token', sandboxExecutionQuery.data.token);

    const tab = window.open(sandboxUrl, '_blank');

    if (!tab && !url) setUrl(sandboxUrl.toString());
  }, [getSkyhubUrl, sandboxExecutionQuery.data?.token, token, url]);

  const executeSandboxMutation = useMutation({
    mutationFn: () =>
      ProcessApiClient.getClient().executeSandboxedProcessById(undefined, {
        params: { id: processId },
      }),
    onSuccess: ({ sandboxId }) => setSandboxExecutionId(sandboxId),
  });

  const isLoading =
    executeSandboxMutation.isPending || sandboxExecutionQuery.isLoading;

  if (!moonraker) return null;

  return (
    <Dialog open={Boolean(url)} onOpenChange={(open) => !open && setUrl('')}>
      <Button
        variant="text"
        size="sm"
        className="gap-2"
        disabled={isLoading}
        onClick={() => executeSandboxMutation.mutate()}
      >
        {isLoading ? <Spinner size="sm" /> : <FlaskRound className="h-4 w-4" />}
        Test
      </Button>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit profile</DialogTitle>
          <DialogDescription>
            We tried to open a new tab, but it seems your browser blocked us.
            Please allow pop-ups and try again. Or click the link below to
            manually open.
          </DialogDescription>
        </DialogHeader>
        <Link
          to={url}
          className={buttonVariants({
            variant: 'outlined-primary',
            className:
              'text-color-text-button-primary hover:text-color-text-button-primary mt-4',
          })}
          target="_blank"
          rel="noreferrer"
        >
          Open Test Loop
        </Link>
      </DialogContent>
    </Dialog>
  );
};
