import { createPortal } from 'react-dom';

import { RBAC } from '@spektr/shared/rbac';
import { usePermissionsContext } from '@spektr/client/providers';

import { useDialogTitlePortal } from '../../hooks/useDialogTitlePortal';

import { TitleInput } from '../TitleInput';

export namespace DialogEditableTitle {
  export type Props = {
    initialTitle?: string;
    titleRef: React.RefObject<HTMLInputElement>;
    onBlur: (title: string) => void;
  };
}

export const DialogEditableTitle = ({
  initialTitle,
  titleRef,
  onBlur,
}: DialogEditableTitle.Props) => {
  const titlePortal = useDialogTitlePortal();

  const { hasPermission } = usePermissionsContext();
  const canEdit = hasPermission(RBAC.ACTIONS.NODE.UPDATE);

  const handleInputBlur = () => {
    const title = titleRef?.current?.value ?? '';

    if (!!title && title !== initialTitle) {
      onBlur(title);
    }
  };

  if (!titlePortal) {
    return null;
  }

  return (
    <>
      {createPortal(
        <div className="mr-[-10%] flex w-full grow">
          <TitleInput
            ref={titleRef}
            defaultValue={initialTitle}
            disabled={!canEdit}
            onBlur={handleInputBlur}
          />
        </div>,
        titlePortal
      )}
    </>
  );
};
