import { HTMLAttributes, forwardRef } from 'react';

import { cn } from '@spektr/style-utils';

export type TableCellProps = HTMLAttributes<HTMLTableCellElement> & {
  colSpan?: number;
};

export const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(
  ({ className, ...props }, ref) => (
    <td
      ref={ref}
      className={cn(
        'px-2 py-2',
        'align-middle [&:has([role=checkbox])]:pr-0 [&>[role=checkbox]]:translate-y-[2px]',
        className
      )}
      {...props}
    />
  )
);
