import { ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

type GridItemProps = {
  className?: string;
  children: ReactNode;
};

export const GridItem = ({ className, children }: GridItemProps) => {
  return (
    <div className={cn('flex h-full items-center', className)}>{children}</div>
  );
};
