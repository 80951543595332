import { toast } from 'sonner';

import { cn } from '@spektr/style-utils';

import { ToastCloseButton } from './ToastCloseButton';
import { ToastIcon, ToastTypeVariant } from './ToastIcon';

export type ToastItemProps = {
  id?: string | number;
  title: string;
  className?: string;
  description: string;
  variant?: ToastTypeVariant;
  action?: {
    label: string;
    onClick: () => void;
  };
};

export const ToastItem = ({
  id,
  title,
  description,
  className,
  variant,
}: ToastItemProps) => {
  return (
    <div
      className={cn(
        'bg-color-bg-toast',
        'max-w-screen w-full p-4 md:max-w-[480px]',
        'flex flex-col-reverse items-center justify-between space-x-2 sm:flex-col',
        'relative',
        'px-4 py-3',
        'rounded-md border',
        className
      )}
    >
      <div
        className={cn(
          'flex w-full flex-row items-center justify-center gap-4',
          'group'
        )}
      >
        {!!variant && <ToastIcon type={variant} />}
        <div className="flex grow items-center justify-between">
          <div className="grid">
            {title && (
              <span className="text-color-text-toast-title text-base font-normal">
                {title}
              </span>
            )}
            {description && (
              <span className="text-color-text-toast-description text-sm font-light">
                {description}
              </span>
            )}
          </div>
        </div>
        <ToastCloseButton onClick={() => toast.dismiss(id)} />
      </div>
    </div>
  );
};
