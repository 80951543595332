import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { produce } from 'immer';

import { LS_KEYS } from '@spektr/client/constants';
import { localStorageUtils } from '@spektr/client/utils';

import { useParsedParams } from '@spektr/shared/hooks';
import { DatasetResponse } from '@spektr/shared/types';
import { extractDatasetParamsSchema } from '@spektr/shared/utils';

import { useGetDatasets } from '@spektr/platform-hooks';

import { DatasetRecordFields } from '../types';

type RecordsContextType = {
  datasetId: string;
  selectedDataset: DatasetResponse | undefined;
  visibleColumns: string[];
  areVisibleColumnsInitialized: boolean;
  initializeVisibleColumns: (datasetFields: DatasetRecordFields) => void;
  updateColumnVisibility: (
    name: string,
    isHidden: boolean,
    newIndex?: number
  ) => void;
};

export const RecordsContext = createContext<RecordsContextType>({
  datasetId: '',
  selectedDataset: undefined,
  visibleColumns: [],
  areVisibleColumnsInitialized: false,
  initializeVisibleColumns: () => null,
  updateColumnVisibility: () => null,
});

export const useRecordsContext = () => useContext(RecordsContext);

type RecordsProviderProps = {
  children: React.ReactNode;
};

export const RecordsProvider = ({ children }: RecordsProviderProps) => {
  const { datasetId } = useParsedParams(extractDatasetParamsSchema);
  const datasets = useGetDatasets();

  const lsKey = useMemo(
    () => `${LS_KEYS.INSIGHTS_COLUMNS}_${datasetId}`,
    [datasetId]
  );
  const lsColumns = localStorageUtils.getItem(lsKey);
  const lsVisibleColumns = useMemo(
    () => JSON.parse(lsColumns ?? '[]') as string[],
    [lsColumns]
  );
  const [visibleColumns, updateVisibleColumns] =
    useState<string[]>(lsVisibleColumns);
  const updateVisibleColumnsInLocalStorage = (newVisibleColumns: string[]) =>
    localStorageUtils.setItem(lsKey, JSON.stringify(newVisibleColumns));

  const initializeVisibleColumns = (datasetFields: DatasetRecordFields) => {
    if (!lsColumns) {
      const columns = Object.keys(datasetFields).slice(0, 4);
      updateVisibleColumns(columns);
      updateVisibleColumnsInLocalStorage(columns);
    }
  };

  const updateColumnVisibility = (
    name: string,
    isHidden: boolean,
    newIndex?: number
  ) =>
    updateVisibleColumns(
      produce(visibleColumns, (draft) => {
        if (isHidden) {
          draft.splice(draft.indexOf(name), 1);
        } else {
          if (draft.includes(name)) {
            draft.splice(draft.indexOf(name), 1);
          }
          if (newIndex !== undefined) {
            draft.splice(newIndex, 0, name);
          } else {
            draft.push(name);
          }
        }
        updateVisibleColumnsInLocalStorage(draft);
      })
    );

  useEffect(() => {
    updateVisibleColumns(lsVisibleColumns);
  }, [lsVisibleColumns]);

  return (
    <RecordsContext.Provider
      value={{
        datasetId,
        selectedDataset: datasets.find(({ id }) => id === datasetId),
        visibleColumns,
        areVisibleColumnsInitialized: !!lsColumns,
        initializeVisibleColumns,
        updateColumnVisibility,
      }}
    >
      {children}
    </RecordsContext.Provider>
  );
};
