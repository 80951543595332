import { InfiniteScroll } from '@spektr/client/components';

import { getTeamMembersQuery } from '@spektr/client/services';

import { useSuspenseQuery } from '@tanstack/react-query';

import { cn } from '@spektr/style-utils';

import { Comment, CommentBox } from '../../../components';
import { useCustomerDetails } from '../../providers/CustomerDetailsProvider';
import { useGetComments } from '../../../hooks';

export const CustomerCommentsList = () => {
  const { data: users } = useSuspenseQuery(getTeamMembersQuery());
  const { customer } = useCustomerDetails();

  const {
    comments,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useGetComments(customer.spektrId, 'customer');

  return (
    <div className="flex flex-col gap-3">
      <CommentBox
        parentType="customer"
        parentId={customer.spektrId}
        variant="card"
      />

      {comments.length === 0 && (
        <div
          className={cn(
            'flex items-center justify-center',
            'bg-color-bg-card-default',
            'border-color-border-primary rounded-md border p-6'
          )}
        >
          <p className="text-color-text-subtext text-sm">
            No comments for this customer
          </p>
        </div>
      )}

      <InfiniteScroll
        className="flex flex-col gap-6"
        fetchMoreItems={fetchNextPage}
        hasMoreItems={hasNextPage}
        isFetching={isFetching || isFetchingNextPage}
        loadMoreText="Loading more comments..."
      >
        {comments.length > 0 &&
          comments.map((comment) => (
            <Comment
              key={comment.id}
              id={comment.id}
              message={comment.message}
              user={users.find((user) => user.id === comment.userId)}
              updated={comment.updatedAt}
              parentId={comment.parentId}
              variant="card"
            />
          ))}
      </InfiniteScroll>
    </div>
  );
};
