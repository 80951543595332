import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  ProcessApiClient,
  getProcessQueryKey,
  getTopologyQueryKey,
} from '@spektr/client/services';

export function useDeleteNode(processId: string, nodeId: string) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () =>
      ProcessApiClient.getClient().deleteNodeById(undefined, {
        params: {
          processId,
          nodeId,
        },
      }),
    onSuccess: async () => {
      await queryClient.resetQueries({
        queryKey: getProcessQueryKey(processId),
      });
      await queryClient.resetQueries({
        queryKey: getTopologyQueryKey(),
      });
    },
  });

  return mutation;
}
