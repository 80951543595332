import { cn } from '@spektr/style-utils';

import { cva } from 'class-variance-authority';

import { outlined, text, variants } from './variants';

export const buttonBase = cn(
  'group',
  'inline-flex items-center justify-center',
  'rounded-md',
  'text-xs font-semibold',
  'transition-colors',
  'focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring',
  'disabled:pointer-events-none disabled:opacity-50'
);

export const buttonSizeVariants = {
  default: 'h-9 rounded-md px-4 py-2',
  sm: 'h-7 rounded-md px-3',
  lg: 'h-10 rounded-md px-4 text-sm',
};

export const commonButtonVariants = {
  'contained-white': variants.whiteContained,
  'outlined-white': cn(variants.whiteOutlined, outlined),
  'text-white': cn(variants.whiteText, text),
};

export const buttonVariants = cva(buttonBase, {
  variants: {
    variant: {
      ...commonButtonVariants,

      'contained-primary': variants.primaryContained,
      'outlined-primary': cn(variants.primaryOutlined, outlined),
      'text-primary': cn(variants.primaryText, text),
      'contained-secondary': variants.secondaryContained,
      'outlined-secondary': cn(variants.secondaryOutlined, outlined),
      'text-secondary': cn(variants.secondaryText, text),
      'contained-cyan': variants.cyanContained,
      'outlined-cyan': cn(variants.cyanOutlined, outlined),
      'text-cyan': cn(variants.cyanText, text),
      'contained-success': variants.successContained,
      'outlined-success': cn(variants.successOutlined, outlined),
      'text-success': cn(variants.successText, text),
      'contained-yellow': variants.yellowContained,
      'outlined-yellow': cn(variants.yellowOutlined, outlined),
      'text-yellow': cn(variants.yellowText, text),

      'contained-blue': variants.blueContained,
      'outlined-blue': cn(variants.blueOutlined, outlined),
      'text-blue': cn(variants.blueText, text),

      'contained-red': variants.redContained,
      'outlined-red': cn(variants.redOutlined, outlined),
      'text-red': cn(variants.redText, text),

      'contained-alert': cn(variants.alertContained),
      'outlined-alert': cn(variants.alertOutlined, outlined),
      'text-alert': cn(variants.alertText, text),

      'contained-icon': cn(variants.iconContained),
      'outlined-icon': cn(variants.iconOutlined, outlined),
    },
    size: buttonSizeVariants,
  },
  defaultVariants: {
    variant: 'contained-primary',
    size: 'default',
  },
});
