import { ServerCrash } from 'lucide-react';

type CasesSectionErrorProps = {
  error: string;
};

export const CasesSectionError = ({ error }: CasesSectionErrorProps) => {
  return (
    <div className="bg-color-bg-card-default flex w-full items-center justify-center gap-2 rounded-md border py-6">
      <ServerCrash className="stroke-color-red h-4 w-4" />
      <p className="text-color-text-error-boundry">{error}</p>
    </div>
  );
};
