import { Info } from 'lucide-react';

import { SelectProps, Tooltip } from '@spektr/client/components';

import {
  type SpektrFieldKey,
  type TriggerFrequency,
} from '@spektr/shared/types';
import { type ServiceFieldClient } from '@spektr/client/types';

import { FrequencySelect } from '../FrequencySelect';

export namespace ServiceItemWithFrequency {
  export type Props = {
    field: ServiceFieldClient;
    frequency?: TriggerFrequency | undefined;
    selectProps?: Partial<SelectProps>;
    onChangeFrequency: (
      field: SpektrFieldKey,
      frequency: TriggerFrequency | undefined
    ) => void;
  };
}

export const ServiceItemWithFrequency = ({
  field,
  frequency,
  selectProps,
  onChangeFrequency,
}: ServiceItemWithFrequency.Props) => {
  return (
    <>
      <span className="text-color-text-primary flex items-center gap-2 whitespace-normal text-xs">
        {field.label}
        {field.help && (
          <Tooltip content={field.help}>
            <Info className="text-color-text-icon-secondary h-3 w-3" />
          </Tooltip>
        )}
      </span>
      <FrequencySelect
        fieldKey={field.key}
        frequency={frequency}
        selectProps={selectProps}
        onChangeFrequency={onChangeFrequency}
      />
    </>
  );
};
