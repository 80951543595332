import { TriggerFrequency } from '@spektr/shared/types';

//ToDO: do we even need this?
export const TRIGGER_FREQUENCIES: Array<{
  value: TriggerFrequency;
  label: string;
}> = [
  {
    value: 'day',
    label: 'Day',
  },
  {
    value: 'week',
    label: 'Week',
  },
  {
    value: 'month',
    label: 'Month',
  },
];
