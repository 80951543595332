import { useRef, useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  getProcessByIdQuery,
  getProcessesQuery,
} from '@spektr/client/services';

import { Label, Select } from '@spektr/client/components';

import { type ReturningProcessNode } from '@spektr/shared/types';

import { DialogEditableTitle } from '../../components/DialogEditableTitle';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { withDialogWrapper } from '../WithDialogWrapper';

import { ReturningProcessSkeleton } from './components/ReturningProcessSkeleton';

export namespace ReturningProcessContent {
  export type Props = BaseDialogProps<ReturningProcessNode>;
}

const ReturningProcessContent = ({
  isPendingUpdate,
  node,
  processId,
  onUpdate,
}: ReturningProcessContent.Props) => {
  const { data: process } = useSuspenseQuery(getProcessByIdQuery(processId));
  const { data: processList } = useSuspenseQuery(
    getProcessesQuery({
      types: ['risk', 'monitoring', 'score', 'onboarding'],
    })
  );

  const titleRef = useRef<HTMLInputElement>(null);

  const options = useMemo(
    () =>
      processList.map((p) => ({
        value: p.id,
        label: p.name,
        disabled: p.id === process.id,
      })),
    [process.id, processList]
  );

  const handleChangeValue = (processId: string) => {
    const title = titleRef?.current?.value ?? node.title;

    onUpdate({
      nodeType: node.nodeType,
      processId,
      title,
    });
  };

  const handleInputBlur = (title: string) => {
    onUpdate({
      nodeType: node.nodeType,
      processId: node.processId,
      title,
    });
  };

  return (
    <>
      <DialogEditableTitle
        titleRef={titleRef}
        initialTitle={node.title}
        onBlur={handleInputBlur}
      />
      <div className="flex grow flex-col gap-1">
        <Label>Choose which process from the dropdown:</Label>
        <Select
          className="w-full"
          required
          name="process"
          disabled={isPendingUpdate}
          placeholder="Select process"
          options={options}
          value={node.processId}
          onValueChange={handleChangeValue}
        />
      </div>
    </>
  );
};

export const ReturningProcessDialog = withDialogWrapper(
  ReturningProcessContent,
  <ReturningProcessSkeleton />
);
