import { Suspense } from 'react';

import { Skeleton } from '@spektr/client/components';

import { HitContent } from '../containers/HitContent';

export const AlertHitDetails = () => {
  return (
    <Suspense fallback={<Skeleton className="h-80 w-full" />}>
      <HitContent />
    </Suspense>
  );
};
