import { handleAxiosError } from '@spektr/client/utils';
import { ServerCrash } from 'lucide-react';

import { FallbackProps } from 'react-error-boundary';

export function commonRenderError(errorMessage: string) {
  return ({ error }: FallbackProps) => {
    return (
      <div className="flex flex-col items-center justify-center gap-2 py-4">
        <ServerCrash className="stroke-color-red h-6 w-6" />
        <span className="text-color-text-error-boundry">
          {handleAxiosError(error, errorMessage)}
        </span>
      </div>
    );
  };
}
