import { z } from 'zod';
import { makeApi, makeEndpoint } from '@zodios/core';

import { tagCreateSchema, tagSchema, TagUpdate } from './tags';

export const createWorkspaceTag = makeEndpoint({
  method: 'post',
  path: '/tags',
  alias: 'createWorkspaceTag',
  parameters: [
    {
      name: 'body',
      schema: tagCreateSchema,
      type: 'Body',
      description: 'Tag to create',
    },
  ],
  response: tagSchema,
});

export const getAllWorkspaceTags = makeEndpoint({
  method: 'get',
  path: '/tags',
  alias: 'getAllWorkspaceTags',
  response: tagSchema.array(),
});

export const deleteTagFromWorkspace = makeEndpoint({
  method: 'delete',
  path: '/tags/:tagId',
  alias: 'deleteTagById',
  response: z.void(),
});

const updateCustomerTag = makeEndpoint({
  method: 'patch',
  path: '/tags/customer/:customerId',
  alias: 'updateCustomerTag',
  parameters: [
    {
      name: 'customerId',
      type: 'Path',
      schema: z.string(),
    },
    {
      name: 'tagInput',
      type: 'Body',
      schema: TagUpdate,
    },
  ],
  response: z.union([z.void(), z.string().nullable()]),
});

export const tagsApi = makeApi([
  createWorkspaceTag,
  getAllWorkspaceTags,
  deleteTagFromWorkspace,
  updateCustomerTag,
]);
