import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AlertTriangle, Check } from 'lucide-react';

import { casesCustomerDetailsUrl } from '@spektr/shared/utils';

import { Button, StatusWithIcon } from '@spektr/client/components';

import { CaseIcon } from '../../components/CaseIcon';

import { type NodeData } from '../../types/NodeData';

export namespace NodePopover {
  export type Props = {
    data: NodeData;
    onClose?: () => void;
  };
}

export const NodePopover = ({ data, onClose }: NodePopover.Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoToCustomer = () => {
    onClose?.();
    navigate(casesCustomerDetailsUrl(data.spektrId));
  };

  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="flex flex-row justify-between gap-3">
        <div className="flex flex-row items-center gap-3">
          <CaseIcon status="pending" type={data.type} />
          <div className="flex flex-col">
            <p className="text-color-text-node relative text-sm font-medium">
              {data.name}
            </p>
            <span className="text-color-text-badge text-xs">
              {data?.subtext}
            </span>
          </div>
        </div>
        {data.status !== 'pending' && (
          <div className="flex h-fit shrink-0">
            <StatusWithIcon
              color={data.status === 'approved' ? 'success' : 'error'}
              label={t(`cases:status.${data.status}`)}
              icon={
                data.status === 'approved' ? (
                  <Check className="h-2.5 w-2.5" />
                ) : (
                  <AlertTriangle className="h-2.5 w-2.5" />
                )
              }
            />
          </div>
        )}
      </div>
      <Button color="secondary" onClick={handleGoToCustomer}>
        {t('common:actions.goToCustomer')}
      </Button>
    </div>
  );
};
