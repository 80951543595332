import { Info } from 'lucide-react';

import { Checkbox, Label, Tooltip } from '@spektr/client/components';

import { UboLabel } from './UboLabel';

type KyckrUboFieldsProps = {
  showCheckbox?: boolean;
  selectedKey: string;
  onCheckedChange: (key: string) => void;
};

export const KyckrUboFields = ({
  showCheckbox = true,
  selectedKey,
  onCheckedChange,
}: KyckrUboFieldsProps) => {
  const handleUboCheck = (value: boolean) => {
    onCheckedChange(value ? 'first_layer_of_ownership' : '');
  };

  const handleUboSwitchChange = (allLayers: boolean) => {
    onCheckedChange(
      allLayers
        ? 'all_potential_layers_of_ownership'
        : 'first_layer_of_ownership'
    );
  };

  return (
    <div className="flex flex-col gap-4">
      {showCheckbox ? (
        <Checkbox
          className="gap-3"
          id="ubo"
          label={<UboLabel />}
          checked={!!selectedKey}
          onCheckedChange={handleUboCheck}
        />
      ) : (
        <UboLabel />
      )}
      <div className="flex items-center gap-3">
        <Checkbox
          disabled={!selectedKey}
          checked={selectedKey === 'all_potential_layers_of_ownership'}
          onCheckedChange={handleUboSwitchChange}
        />
        <Label>UBO</Label>
        <Tooltip content="Get information about the whole ownership structure. Note that this research costs more than a first-layer ownership request and can take a bit longer. With this service, the returned UBO list is guaranteed to be individuals.">
          <Info className="text-color-text-icon-secondary h-3 w-3" />
        </Tooltip>
      </div>
    </div>
  );
};
