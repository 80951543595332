import { Suspense } from 'react';
import { Link } from 'react-router-dom';

import { CASES_CUSTOMERS_FULL_URL } from '@spektr/shared/utils';
import { SpektrErrorBoundary } from '@spektr/client/components';

import {
  SkeletonCustomerDetailsHeader,
  CustomerDetailsHeader,
} from '../components';

export const CustomerHeader = () => {
  return (
    <SpektrErrorBoundary
      fallback={
        <div className="text-color-text-subtext flex items-center gap-1.5 text-sm">
          <Link className="font-medium" to={CASES_CUSTOMERS_FULL_URL}>
            Cases
          </Link>
          <span>/</span>
          <span className="text-color-text-primary font-medium">unknown</span>
        </div>
      }
    >
      <Suspense fallback={<SkeletonCustomerDetailsHeader />}>
        <CustomerDetailsHeader />
      </Suspense>
    </SpektrErrorBoundary>
  );
};
