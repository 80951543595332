import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EllipsisVertical } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { casesCustomerDetailsUrl } from '@spektr/shared/utils';

import { DropdownMenuComboBox, DropdownOption } from '../../DropdownMenu';
import { Avatar } from '../../Avatar';
import { IconWithBackground } from '../../Icons';

import { CasesTableCell, CasesTableRow } from '../CasesTable';
import { AlertAge } from '../AlertAge';

import { TextTruncate } from '../../TextTruncate';

import { SpektrAIStatus } from '../SpektrAIStatus';
import { CasesStatus } from '../CasesStatus';
import { CustomerIcon } from '../CustomerIcon';

import { TableAlertData } from './TableAlertData';

export type AlertsTableRowProps = {
  alert: TableAlertData;
  columns: string[];
  actions?: DropdownOption[];
  onActionClick?: (action: string) => void;
  onRowClick: () => void;
};

export const AlertsTableRow = ({
  alert,
  columns,
  actions,
  onActionClick,
  onRowClick,
}: AlertsTableRowProps) => {
  const { t } = useTranslation('cases');
  const [firstName, lastName] = alert.assignedTo?.split(' ') || [];

  return (
    <CasesTableRow
      className="h-20"
      onClick={onRowClick}
      data-cy={`alert-${alert.name}`}
    >
      {columns.includes('date') && (
        <CasesTableCell
          className={cn('w-36 border-r')}
          isFirst={columns.indexOf('date') === 0}
          isLast={columns.indexOf('date') === columns.length - 1}
        >
          <AlertAge
            createdAt={alert.createdAt}
            isResolved={alert.status === 'resolved'}
          />
        </CasesTableCell>
      )}
      {columns.includes('alert') && (
        <CasesTableCell
          isFirst={columns.indexOf('alert') === 0}
          isLast={columns.indexOf('alert') === columns.length - 1}
        >
          <div className="flex flex-col items-start gap-0.5 px-4">
            <span className="overflow-hidden text-ellipsis whitespace-nowrap">
              {alert.name ?? 'Single alert'}
            </span>
            {alert.assignedTo && (
              <div
                className={cn(
                  'flex items-center gap-1.5',
                  'text-color-text-subtext text-xs',
                  'overflow-hidden text-ellipsis whitespace-nowrap'
                )}
              >
                Assigned to
                <Avatar
                  firstName={firstName}
                  lastName={lastName}
                  className="h-3.5 w-3.5"
                  fallbackClass="text-[8px]"
                />
              </div>
            )}
          </div>
        </CasesTableCell>
      )}
      {columns.includes('customer') && (
        <CasesTableCell
          isFirst={columns.indexOf('customer') === 0}
          isLast={columns.indexOf('customer') === columns.length - 1}
        >
          <div className="flex items-center">
            <IconWithBackground className="bg-color-bg-accent mr-3 rounded-full">
              <CustomerIcon type={alert.customerType} />
            </IconWithBackground>
            <div className="flex flex-col gap-0.5">
              <Link
                onClick={(e) => e.stopPropagation()}
                to={casesCustomerDetailsUrl(alert.customerId ?? '')}
              >
                {alert.customerName ? (
                  <TextTruncate
                    text={alert.customerName}
                    className="line-clamp-1"
                  />
                ) : (
                  '-'
                )}
              </Link>
              <span
                className={cn(
                  'text-color-text-subtext text-xs capitalize',
                  'overflow-hidden text-ellipsis whitespace-nowrap'
                )}
              >
                {alert.customerTitle}
              </span>
            </div>
          </div>
        </CasesTableCell>
      )}
      {columns.includes('alertType') && (
        <CasesTableCell
          className="w-60"
          isFirst={columns.indexOf('customer') === 0}
          isLast={columns.indexOf('customer') === columns.length - 1}
        >
          <div className="flex items-center">
            {t(`alertTypes.${alert.alertType}`)}
          </div>
        </CasesTableCell>
      )}
      {columns.includes('spektrAI') && (
        <CasesTableCell
          className="w-60"
          isFirst={columns.indexOf('spektrAI') === 0}
          isLast={columns.indexOf('spektrAI') === columns.length - 1}
        >
          {alert.spektrAI && alert.spektrAI.info && (
            <div className="flex items-center">
              <SpektrAIStatus
                label={alert.spektrAI.info}
                type={alert.spektrAI.status}
              />
            </div>
          )}
        </CasesTableCell>
      )}
      {columns.includes('status') && (
        <CasesTableCell
          className={cn('min-w-32')}
          isFirst={columns.indexOf('status') === 0}
          isLast={columns.indexOf('status') === columns.length - 1}
        >
          <div className="itemx-center flex">
            <CasesStatus
              status={alert.status}
              className="h-[22px] max-w-fit text-xs"
            />
          </div>
        </CasesTableCell>
      )}
      {actions && (
        <CasesTableCell
          className={cn('text-end')}
          isFirst={columns.indexOf('actions') === 0}
          isLast={columns.indexOf('actions') === columns.length - 1}
        >
          <DropdownMenuComboBox
            trigger={
              <EllipsisVertical
                className={cn(
                  'ml-auto w-5',
                  'stroke-color-stroke-primary',
                  'h-5 cursor-pointer',
                  'hover:stroke-color-stroke-primary--hover'
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            }
            options={actions}
            onClick={onActionClick}
          />
        </CasesTableCell>
      )}
    </CasesTableRow>
  );
};
