import { z } from 'zod';
import { makeApi } from '@zodios/core';

import { FormBranding, FormBrandingUpdate } from '../formBuilder/branding';

import {
  UserInputSchema,
  UserSchema,
  AdminUserUpdateInputSchema,
  UserOwnUpdateInputSchema,
  UserUpdateSchema,
} from './user';
import { ApiKeySchema, CreateApiKeySchema } from './apiKey';

const userApi = makeApi([
  {
    method: 'post',
    path: '/users',
    alias: 'createUser',
    description: 'Create a user in an existing workspace',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UserInputSchema,
      },
    ],
    response: UserSchema,
  },
  {
    method: 'put',
    path: '/users/profile/:id',
    alias: 'updateUserAsAdmin',
    description: 'Update user details as an admin',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        description: 'The id of the user to updated',
        schema: z.string().nonempty(),
      },
      {
        name: 'body',
        type: 'Body',
        schema: AdminUserUpdateInputSchema,
      },
    ],
    response: UserUpdateSchema,
  },
  {
    method: 'put',
    path: '/users/profile/',
    alias: 'updateUserOwnProfile',
    description: 'Update user details of logged in user',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: UserOwnUpdateInputSchema,
      },
    ],
    response: UserUpdateSchema,
  },
  {
    method: 'delete',
    path: '/users/:id',
    alias: 'deleteUser',
    description: 'Delete a user by id',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        description: 'The id of the user to delete',
        schema: z.string().nonempty(),
      },
    ],
    response: z.void(),
  },
  {
    method: 'get',
    path: '/users',
    alias: 'getUsers',
    description: 'Get all users in workspace',
    parameters: [
      {
        name: 'customAttr',
        type: 'Query',
        description:
          'The comma separated list of requested custom attributes, supported: [userRole]',
        schema: z.string().optional(),
      },
    ],
    response: z.array(UserSchema),
  },
  {
    method: 'get',
    path: '/users/:id',
    alias: 'getUserById',
    description: 'Get user by cognito id',
    response: UserSchema,
  },
  {
    method: 'get',
    path: '/users/spektr-ai',
    alias: 'getSpektrAiUser',
    description: 'get spektr ai user',
    response: UserSchema,
  },
  {
    method: 'get',
    path: '/branding/forms/default',
    alias: 'getBrandingFormsDefault',
    description: 'Get default branding forms',
    response: FormBranding,
  },
  {
    method: 'patch',
    path: '/branding/forms/:id',
    alias: 'updateBrandingFormById',
    description: 'Update branding form by id',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        description: 'The id of the branding form to update',
        schema: z.string().nonempty(),
      },
      {
        name: 'body',
        type: 'Body',
        schema: FormBrandingUpdate,
      },
    ],
    response: FormBranding,
  },
  {
    method: 'post',
    path: '/branding/forms/:id/upload',
    alias: 'uploadBrandingLogoByBrandingId',
    description: 'Upload branding logo to branding id',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        description: 'The id of the branding form to upload logo',
        schema: z.string().nonempty(),
      },
      {
        name: 'file',
        type: 'Body',
        description: 'Logo to upload',
        schema: z.instanceof(FormData),
      },
    ],
    response: FormBranding,
  },
]);

const apiKeyApi = makeApi([
  {
    method: 'post',
    path: '/api-keys',
    alias: 'createApiKey',
    description: 'Create an api key',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CreateApiKeySchema,
      },
    ],
    response: ApiKeySchema,
  },
  {
    method: 'get',
    path: '/api-keys',
    alias: 'getApiKeys',
    description: 'Get all api keys',
    response: z.array(ApiKeySchema),
  },
  {
    method: 'delete',
    path: '/api-keys/:id',
    alias: 'deleteApiKey',
    description: 'Delete an api key by id',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        description: 'The id of the api key to delete',
        schema: z.string().nonempty(),
      },
    ],
    response: z.void(),
  },
]);

export { userApi, apiKeyApi };
