import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { getCustomerDetails } from '@spektr/client/services';
import { ClientRecordDetails } from '@spektr/shared/validators';

type CustomerDetailsProviderProps = {
  customerId: string;
  children: ReactNode;
};

type CustomerDetailsContextType = {
  customer: ClientRecordDetails;
  datasetName: string;
  datasetId: string;
  isSidebarVisible: boolean;
  toggleSidebar: (visibility: boolean) => void;
};

export const CustomerDetailsContext = createContext<CustomerDetailsContextType>(
  {
    customer: {} as ClientRecordDetails,
    datasetName: '',
    datasetId: '',
    isSidebarVisible: false,
    toggleSidebar: () => null,
  }
);

export const useCustomerDetails = () => {
  return useContext(CustomerDetailsContext);
};

export const CustomerDetailsProvider = ({
  customerId,
  children,
}: CustomerDetailsProviderProps) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const { data: customer } = useSuspenseQuery(getCustomerDetails(customerId));

  const toggleSidebar = (visibility: boolean) => {
    setIsSidebarVisible(visibility);
  };

  const value = useMemo(
    () => ({
      customer,
      datasetId: customer.datasetId,
      datasetName: customer.datasetName,
      isSidebarVisible,
      toggleSidebar,
    }),
    [customer, isSidebarVisible]
  );

  return (
    <CustomerDetailsContext.Provider value={value}>
      {children}
    </CustomerDetailsContext.Provider>
  );
};
