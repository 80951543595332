import { useMemo } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Badge, ButtonWithTooltip, Spinner } from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';
import { Card } from '@spektr/shared/components';

import { RBAC } from '@spektr/shared/rbac';
import {
  LiveProcessVersion,
  ProcessVersionOverviewResponse,
} from '@spektr/shared/types';

import { cn } from '@spektr/style-utils';

import { useFeatureFlags } from '@spektr/platform-hooks';
import { LONG_DATE_TIME_FORMAT } from '@spektr/client/constants';

import { useProcessScheduleContext } from '../../providers';

export type VersionsItemProps = {
  isLiveActionPending?: boolean;
  isRunActionPending?: boolean;
  overview: ProcessVersionOverviewResponse;
  liveVersion?: LiveProcessVersion;
  onClickGoLive: (versionId: string) => void;
  onClickStopLive: () => void;
  onClickRunNow: () => void;
};

export const VersionsItem = ({
  isLiveActionPending = false,
  isRunActionPending = false,
  overview,
  liveVersion,
  onClickGoLive,
  onClickStopLive,
  onClickRunNow,
}: VersionsItemProps) => {
  const { t } = useTranslation(['common', 'process']);
  const { hasPermission } = usePermissionsContext();
  const { processTriggers } = useFeatureFlags();
  const { isMonitoringProcess, canRunNow } = useProcessScheduleContext();

  const versions = useMemo(() => {
    if (overview.versions.length === 0) {
      return [];
    }

    return overview.versions.sort((a, b) => b.versionNumber - a.versionNumber);
  }, [overview.versions]);

  const actionDisabledReason = useMemo(() => {
    if (!hasPermission(RBAC.ACTIONS.PROCESS.LAUNCH)) {
      return t('common:errors.notAllowed');
    }

    return undefined;
  }, [hasPermission, t]);

  if (versions.length === 0) {
    return (
      <Card className="flex max-h-[200px] flex-col gap-2 overflow-y-auto p-4">
        <div className="flex items-center justify-center">
          <div className="flex items-center">
            <span className="text-color-text-subtext text-sm">
              See your created version here, that you can set to live.
            </span>
          </div>
        </div>
      </Card>
    );
  }

  const isActionPending = isLiveActionPending || isRunActionPending;
  const isActionDisabled = isActionPending || !!actionDisabledReason;

  return (
    <Card className="flex max-h-[200px] flex-col gap-4 overflow-y-auto p-4">
      {versions.map((version) => (
        <div
          key={`${version.versionNumber}-${liveVersion?.versionId === version.id}`}
          className={cn(
            'flex shrink-0 items-center justify-between',
            isMonitoringProcess && 'items-start'
          )}
        >
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
              <p className="text-color-text-primary text-sm">{`Version ${version.versionNumber}`}</p>
              {liveVersion?.versionId === version.id && (
                <Badge size="sm" variant="cyan">
                  Live
                </Badge>
              )}
            </div>
            <p className="text-color-text-subtext text-xs">
              {t('process:versions.publishDate', {
                date: format(version.publishedAt, LONG_DATE_TIME_FORMAT),
              })}
            </p>
            {processTriggers && version?.startDate && (
              <p className="text-color-text-subtext text-xs">
                {t('process:versions.startDate', {
                  date: format(version.startDate, LONG_DATE_TIME_FORMAT),
                })}
              </p>
            )}
          </div>

          {liveVersion?.versionId === version.id ? (
            <div className="flex items-center gap-2">
              {canRunNow && (
                <ButtonWithTooltip
                  color="cyan"
                  variant="outlined"
                  size="sm"
                  disabled={isActionDisabled || isActionPending}
                  showTooltip={!!actionDisabledReason}
                  tooltipProps={{
                    content: actionDisabledReason,
                  }}
                  onClick={onClickRunNow}
                >
                  <div className="flex gap-2">
                    {isRunActionPending && <Spinner size="sm" />}
                    <span>Run now</span>
                  </div>
                </ButtonWithTooltip>
              )}
              <ButtonWithTooltip
                color="red"
                size="sm"
                disabled={isActionDisabled || isActionPending}
                showTooltip={!!actionDisabledReason}
                tooltipProps={{
                  content: actionDisabledReason,
                }}
                onClick={onClickStopLive}
              >
                <div className="flex gap-2">
                  {isLiveActionPending && <Spinner size="sm" />}
                  <span>Stop</span>
                </div>
              </ButtonWithTooltip>
            </div>
          ) : overview?.latestVersionId === version.id ? (
            <ButtonWithTooltip
              color="cyan"
              size="sm"
              disabled={isActionDisabled}
              showTooltip={!!actionDisabledReason}
              tooltipProps={{
                content: actionDisabledReason,
              }}
              onClick={() => onClickGoLive(version.id)}
            >
              <div className="flex gap-2">
                {isLiveActionPending && <Spinner size="sm" />}
                <span>Go Live</span>
              </div>
            </ButtonWithTooltip>
          ) : null}
        </div>
      ))}
    </Card>
  );
};
