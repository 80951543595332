import { useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { useParsedParams } from '@spektr/shared/hooks';
import { casesCustomerParamsSchema } from '@spektr/shared/utils';

import { getCustomerDocuments } from '@spektr/client/services';
import { AlertDialog } from '@spektr/client/components';

import { useDeleteDocument } from '@spektr/platform-hooks';

import { CustomerDocumentsTable } from '../../components';

type CustomerDocumentsListProps = {
  columns: string[];
};

export const CustomerDocumentsList = ({
  columns,
}: CustomerDocumentsListProps) => {
  const { spektrId: customerId } = useParsedParams(casesCustomerParamsSchema);
  const { data: documents } = useSuspenseQuery(
    getCustomerDocuments(customerId)
  );
  const [removeDocumentId, setRemoveDocumentId] = useState<string>('');
  const removeDocument = useDeleteDocument(customerId, () =>
    setRemoveDocumentId('')
  );

  return (
    <div className="flex h-full flex-col items-end gap-4">
      <CustomerDocumentsTable
        documents={documents}
        columns={columns}
        onRemoveDocument={setRemoveDocumentId}
      />
      {removeDocumentId && (
        <AlertDialog
          open={!!removeDocumentId}
          title="Delete document"
          paragraph="Are you sure you want to delete this document? This action cannot be undone."
          onCancel={() => setRemoveDocumentId('')}
          cancel="Cancel"
          onConfirm={() => removeDocument.mutate(removeDocumentId)}
          confirm="Yes, delete"
        />
      )}
    </div>
  );
  // TODO: @cristina - add pagination when endpoint supports it
};
