import { useTranslation } from 'react-i18next';

import { type NodeType } from '@spektr/shared/validators';

const nodesWithDescription: NodeType[] = [
  'router',
  'returningProcess',
  'monitoringDataset',
  'aiAmlAlert',
  'slack',
  'openCorporatesMonitoring',
  'openCorporatesRisk',
  'complyAdvantageKyb',
  'complyAdvantageKyc',
  'kyckr',
  'veriff',
  'veriffIdv',
  'bodacc',
  'mitId',
  'virk',
];

export function useNodeDescription(nodeType: NodeType) {
  const { t } = useTranslation('process');

  if (nodesWithDescription.includes(nodeType)) {
    return t(`nodes.${nodeType}.dialog.description`);
  }

  return null;
}
