import { cn } from '@spektr/style-utils';

import { Separator } from '@spektr/client/components';

import { type ServiceFieldClient } from '@spektr/client/types';
import { type SpektrFieldKey } from '@spektr/shared/types';

import { SelectableServiceItem } from '../../../../components/SelectableServiceItem';

import { ADDRESS_KEY, CPR_KEY } from '../../constants';

import { isMitIdFieldSelected } from '../../utils/isMitIdFieldSelected';

import { MitIdLoginFields } from '../../components/MitIdLoginFields';

export namespace MitIdForm {
  export type Props = {
    fields: ServiceFieldClient[];
    loginType?: string;
    selectedFields: SpektrFieldKey[];
    onChangeField: (fieldKey: SpektrFieldKey, value: boolean) => void;
    onChangeLogin: (value: string) => void;
  };
}

export const MitIdForm = ({
  loginType,
  selectedFields,
  onChangeField,
  onChangeLogin,
}: MitIdForm.Props) => {
  const isFieldSelected = (fieldKey: string) =>
    selectedFields.includes(fieldKey);

  return (
    <div
      className={cn(
        'flex flex-col',
        'max-h-[280px] overflow-y-auto',
        'rounded-md border'
      )}
    >
      <div className="flex flex-col">
        <div
          className={cn(
            'flex h-auto shrink-0 items-center justify-start px-4',
            'h-[52px]'
          )}
        >
          <SelectableServiceItem
            checked={isFieldSelected(CPR_KEY)}
            field={{
              key: CPR_KEY as SpektrFieldKey,
              label: 'CPR',
              value: isMitIdFieldSelected(CPR_KEY, selectedFields),
              help: 'Get the CPR number of the individual.',
            }}
            onChange={(value) => onChangeField(CPR_KEY, value)}
          />
        </div>
        <Separator />
      </div>

      <div className="flex flex-col">
        <div
          className={cn(
            'flex h-auto shrink-0 items-center justify-start px-4',
            'h-[52px]'
          )}
        >
          <SelectableServiceItem
            checked={isFieldSelected(ADDRESS_KEY) && isFieldSelected(CPR_KEY)}
            field={{
              key: ADDRESS_KEY as SpektrFieldKey,
              label: 'Address',
              value: isMitIdFieldSelected(ADDRESS_KEY, selectedFields),
              help: 'Get the address of the individual.',
            }}
            onChange={(value) => onChangeField(ADDRESS_KEY, value)}
            disabled={!isFieldSelected(CPR_KEY)}
          />
        </div>
        <Separator />
      </div>
      <div className="flex items-start gap-2 p-4">
        <MitIdLoginFields
          selectedKey={loginType}
          fields={[
            {
              key: 'low' as SpektrFieldKey,
              label: 'Low',
              value: isMitIdFieldSelected('low', selectedFields),
              help: 'Set the login type to low.',
            },
            {
              key: 'substantial' as SpektrFieldKey,
              label: 'Substantial',
              value: isMitIdFieldSelected('substantial', selectedFields),
              help: 'Set the login type to substantial.',
            },
            {
              key: 'high' as SpektrFieldKey,
              label: 'High',
              value: isMitIdFieldSelected('high', selectedFields),
              help: 'Set the login type to high.',
            },
          ]}
          onValueChange={onChangeLogin}
        />
      </div>
    </div>
  );
};
