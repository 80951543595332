import { useCopyToClipboard } from 'react-use';

import { type ResourceRecord } from '@spektr/shared/validators';

import { DnsRecordItem } from '../DnsRecordItem';

export namespace DnsRecords {
  export type Props = {
    records: ResourceRecord[];
  };
}

export const DnsRecords = ({ records }: DnsRecords.Props) => {
  const [state, copyToClipboard] = useCopyToClipboard();

  return (
    <div className="text-color-text-subtext grid grid-cols-5 content-center gap-4">
      <div className="col-span-1 text-xs font-semibold">Type</div>
      <div className="col-span-2 text-xs font-semibold">Record name</div>
      <div className="col-span-2 text-xs font-semibold">Value</div>

      {records.map((record) => (
        <DnsRecordItem
          key={`${record.type}=${record.value}`}
          lastCopiedValue={state?.value}
          record={record}
          onCopyToClipboard={copyToClipboard}
        />
      ))}
    </div>
  );
};
