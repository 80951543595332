import { datasetResponseSchema, SpektrData } from '@spektr/shared/validators';
import z from 'zod';

import { NodeOutput } from '../node';
import { PaginationFields } from '../pagination';
import { recordToMap, unixTimestampSchema } from '../utils';

export const executionStatuses = [
  'running', // the context has been picked up by a consumer and is now being processed.
  'waiting', // the context is waiting for a consumer to pick it up.
  'completed', // the context has been processed and the output is available.
  'pending', // the context awaits external input, e.g. from end-client or third party service.
  'failed', // Something went wrong during processing.
  'timeout', // The context has been waiting for too long and has timed out.
  'vendorError', //The 3rd party vendor returned an error on a request we made
] as const;
export type ExecutionContextStatus = (typeof executionStatuses)[number];
export const executionStatusSchema = z.enum(executionStatuses);

export const executionContextModes = [
  'sandbox', // 'sandbox' mode is when the client tests out their process. No data is sent to vendors and the context is deleted after processing.
  'live', // 'live' mode is when the client wants to run their process for real. Data is sent to vendors. The context is persisted after processing.
] as const;
export type ExecutionContextMode = (typeof executionContextModes)[number];

export const spektrIdSchema = z.string().min(1);
export const datasetIdSchema = z.string().min(1);
export const executionIdSchema = z.string().min(1);

export const vendorsExecutionSchema = z
  .record(z.string(), z.array(z.string()))
  .transform(recordToMap);
export type VendorsExecution = z.infer<typeof vendorsExecutionSchema>;

export const ProcessExecutionSchema = z.object({
  name: z.string(),
  output: z.string(),
  latestRun: unixTimestampSchema,
});

const DatasetSchema = z.object({
  name: z.string(),
  createdAt: unixTimestampSchema,
});

export const ExecutionContextSchema = z.object({
  id: z.string(),
  spektrId: z.string(),
  processId: z.string(),
  versionId: z.string().nullable(),
  datasetId: z.string(),
  previousContextId: z.union([z.string(), z.null()]),
  nextContextIds: z.union([z.array(z.string()), z.null()]),
  path: z.array(NodeOutput),
  data: SpektrData,
  status: executionStatusSchema,
  delivered: z.boolean().optional(),
  process: ProcessExecutionSchema,
  createdAt: unixTimestampSchema,
  mode: z.enum(executionContextModes),
  updatedAt: unixTimestampSchema,
  vendors: vendorsExecutionSchema.optional(),
  mappings: z.record(z.string()),
});

export const ExecutionContextWithDatasetSchema = ExecutionContextSchema.merge(
  z.object({
    datasetId: z.string(),
    dataset: datasetResponseSchema,
  })
);
export type ExecutionContextWithDatasetSchema = z.infer<
  typeof ExecutionContextWithDatasetSchema
>;

export const CompanyOwnersIndividualRepresentativeSchema = z.object({
  name: z.string().optional(),
  fullAddress: z.string().optional(),
  birthDate: z.string().optional(),
  nationality: z.string().optional(),
  role: z.string().optional(),
  isActive: z.boolean().optional(),
});
export const CompanyOwnersCorporationRepresentativeSchema = z.object({
  name: z.string().optional(),
  fullAddress: z.string().optional(),
  registrationNumber: z.string().optional(),
  registrationAuthority: z.string().optional(),
  registrationDate: z.string().optional(),
  role: z.string().optional(),
  isActive: z.boolean().optional(),
});
export const CompanyOwnersIndividualFirstLayerUboSchema = z.object({
  name: z.string().optional(),
  fullAddress: z.string().optional(),
  birthDate: z.string().optional(),
  nationality: z.string().optional(),
  percentage: z.number().optional(),
});
export const CompanyOwnersCorporationFirstLayerUboSchema = z.object({
  name: z.string().optional(),
  fullAddress: z.string().optional(),
  registrationNumber: z.string().optional(),
  registrationAuthority: z.string().optional(),
  registrationDate: z.string().optional(),
  percentage: z.number().optional(),
});
export const CompanyOwnersUboReportUboSchema = z.record(z.string(), z.number());

export const CompanyOwnersSchema = z.object({
  individualRepresentatives: z.array(
    CompanyOwnersIndividualRepresentativeSchema
  ),
  corporationRepresentatives: z.array(
    CompanyOwnersCorporationRepresentativeSchema
  ),
  individualFirstLayerUbos: z.array(CompanyOwnersIndividualFirstLayerUboSchema),
  corporationFirstLayerUbos: z.array(
    CompanyOwnersCorporationFirstLayerUboSchema
  ),
  uboReportUbo: CompanyOwnersUboReportUboSchema,
  error: z.string().optional(),
});

export type CompanyOwnersIndividualRepresentativeSchema = z.infer<
  typeof CompanyOwnersIndividualRepresentativeSchema
>;
export type CompanyOwnersCorporationRepresentativeSchema = z.infer<
  typeof CompanyOwnersCorporationRepresentativeSchema
>;
export type CompanyOwnersIndividualFirstLayerUboSchema = z.infer<
  typeof CompanyOwnersIndividualFirstLayerUboSchema
>;
export type CompanyOwnersCorporationFirstLayerUboSchema = z.infer<
  typeof CompanyOwnersCorporationFirstLayerUboSchema
>;
export type CompanyOwnersUboReportUboSchema = z.infer<
  typeof CompanyOwnersUboReportUboSchema
>;

export type CompanyOwnersSchema = z.infer<typeof CompanyOwnersSchema>;

export const ExecutionContextWithDatasetIdSchema = ExecutionContextSchema.merge(
  z.object({ datasetId: z.string() })
);
export type ExecutionContextWithDatasetId = z.infer<
  typeof ExecutionContextWithDatasetIdSchema
>;

export type ExecutionContextSchema = z.infer<typeof ExecutionContextSchema>;
export type ProcessExecutionSchema = z.infer<typeof ProcessExecutionSchema>;
export type DatasetSchema = z.infer<typeof DatasetSchema>;

export const insightsResponseSchema = PaginationFields.merge(
  z.strictObject({
    docs: z.array(ExecutionContextWithDatasetSchema),
  })
);

export const insightsResponseWithoutDatasetSchema = z.array(
  ExecutionContextSchema
);

export const insightsResponseWithoutDatasetPaginatedSchema =
  PaginationFields.merge(
    z.strictObject({
      docs: insightsResponseWithoutDatasetSchema,
    })
  );

export const insightsResponsePaginatedSchema = PaginationFields.merge(
  z.strictObject({
    docs: z.array(ExecutionContextWithDatasetIdSchema),
  })
);

export const GetExecutionContextsByIdList = z.strictObject({
  ids: z.array(z.string()),
});

export type GetExecutionContextsByIdList = z.infer<
  typeof GetExecutionContextsByIdList
>;
