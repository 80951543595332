import {
  mitIdAddressFields,
  mitIdBaseFields,
  mitIdCprFields,
  type SpektrFieldKey,
} from '@spektr/shared/types';

import { ADDRESS_KEY, CPR_KEY } from '../constants';

import { isMitIdFieldSelected } from './isMitIdFieldSelected';

export function getSelectedMitIdFields(
  selectedFields: SpektrFieldKey[]
): SpektrFieldKey[] {
  if (selectedFields.length === 0) {
    return [];
  }

  const isCprSelected = isMitIdFieldSelected(CPR_KEY, selectedFields);

  const isAddressSelected = isMitIdFieldSelected(ADDRESS_KEY, selectedFields);

  const extendedSelectedFields: SpektrFieldKey[] = [];

  if (isCprSelected) {
    extendedSelectedFields.push(
      ...mitIdCprFields.map((field) => field.key as SpektrFieldKey)
    );
  }
  if (isCprSelected && isAddressSelected) {
    extendedSelectedFields.push(
      ...mitIdAddressFields.map((field) => field.key as SpektrFieldKey)
    );
  }

  extendedSelectedFields.push(
    ...mitIdBaseFields.map((field) => field.key as SpektrFieldKey)
  );

  return extendedSelectedFields;
}
