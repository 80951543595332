import { ButtonHTMLAttributes } from 'react';
import { FileCode, CheckCircle2 } from 'lucide-react';

import { cn } from '@spektr/style-utils';

export type DatasetCardProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  description: string;
  isSelected?: boolean;
  title: string;
};

export const DatasetCard = ({
  className,
  description,
  isSelected = false,
  title,
  ...props
}: DatasetCardProps) => (
  <button
    {...props}
    className={cn(
      'flex items-center gap-2',
      'rounded-md border',
      'p-4',
      'hover:bg-scolor-bg-button-default/10',
      'transition-colors',
      className
    )}
  >
    <div
      className={cn(
        'flex shrink-0 items-center justify-center',
        'h-8 w-8',
        'rounded',
        'bg-color-bg-card-accent'
      )}
    >
      <FileCode className="text-color-yellow h-4 w-4" />
    </div>
    <div className="grow-1 flex w-full flex-col items-start">
      <span
        className={cn(
          'text-color-text-primary',
          'text-xs',
          'line-clamp-1 overflow-hidden text-ellipsis break-all'
        )}
      >
        {title}
      </span>
      <span className="text-color-text-primary/40 line-clamp-1 text-xs">
        {description}
      </span>
    </div>
    <CheckCircle2
      className={cn('h-4 w-4', 'ml-auto', 'text-color-cyan', {
        'opacity-0': !isSelected,
      })}
    />
  </button>
);
