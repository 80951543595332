import { ServiceNodeMonitor } from '@spektr/shared/validators';

export const createServiceFieldMonitor = (
  field: string,
  action: 'accept' | 'ignore' | 'escalate' = 'accept',
  assignee?: string
): ServiceNodeMonitor => {
  return {
    rule: {
      title: field,
      rule: {
        left: field,
        operator: 'equals',
        right: true,
        type: 'boolean',
      },
    },
    decision:
      action === 'escalate'
        ? { type: 'escalate', assigneeId: assignee ?? '' }
        : {
            type: action,
          },
  };
};
