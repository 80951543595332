import { queryOptions } from '@tanstack/react-query';

import { UsersApiClient } from '../client';
import { getApiKeysQueryKey } from '../queryKeys';

export function getApiKeysQuery() {
  return queryOptions({
    queryKey: getApiKeysQueryKey(),
    queryFn: () => UsersApiClient.getClient().getApiKeys(),
  });
}
