import { HoverCard } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

import { indicatorValueTypes } from '@spektr/shared/validators';

import { getIndicatorStateIcon, indicatorColorVariants } from '../constants';
import { Indicator } from '../types';

import { CustomerIndicatorDetails } from './CustomerIndicatorDetails';

export type CustomerIndicatorProps = {
  indicator: Indicator;
  className?: string;
};

export const CustomerIndicator = ({
  indicator,
  className,
}: CustomerIndicatorProps) => {
  return (
    <HoverCard
      side="top"
      sideOffset={8}
      trigger={
        <div
          className={cn(
            'flex w-32 flex-grow flex-col items-center justify-center',
            'h-full',
            'cursor-default',
            indicator.type === indicatorValueTypes[0] && 'lg:max-w-44',
            indicatorColorVariants({
              state: indicator.state,
            }),
            '!bg-inherit',
            className
          )}
        >
          {indicator.state && getIndicatorStateIcon(indicator.state)}
          <span
            className={cn(
              'text-center text-sm',
              indicator.type === indicatorValueTypes[1] && 'mt-1.5'
            )}
          >
            {indicator.label}
          </span>
          {indicator.type === indicatorValueTypes[0] && (
            <p>
              {indicator.value !== undefined
                ? parseFloat(indicator.value.toFixed(2))
                : '-'}
            </p>
          )}
        </div>
      }
      children={<CustomerIndicatorDetails indicator={indicator} />}
    />
  );
};
