import { useNavigate } from 'react-router-dom';
import { TriangleAlert, Unplug } from 'lucide-react';

import { Button, Tooltip } from '@spektr/client/components';

interface FieldMappingButtonProps {
  destination?: string;
  disabled?: boolean;
  disabledMessage?: string;
}

export const FieldMappingButton = ({
  destination,
  disabled = false,
  disabledMessage,
}: FieldMappingButtonProps) => {
  const navigate = useNavigate();

  return (
    <Tooltip
      content={disabledMessage}
      disabled={!disabled}
      contentClassName="text-center"
    >
      <Button
        variant="text"
        size="sm"
        className="gap-2"
        onClick={() => destination && navigate(destination)}
        disabled={disabled}
      >
        {disabled ? (
          <TriangleAlert className="h-4 w-4" />
        ) : (
          <Unplug className="h-4 w-4" />
        )}
        <span>Field mapping</span>
      </Button>
    </Tooltip>
  );
};
