import { Button, Skeleton } from '@spektr/client/components';

import { AlertDialogHeader } from '../AlertDialogHeader/AlertDialogHeader';
import { SkeletonAlertDescription } from '../SkeletonAlertDescription';

export const SkeletonAlertDetails = () => {
  return (
    <div className="flex flex-col gap-4">
      <AlertDialogHeader>
        <Button disabled>Save</Button>
      </AlertDialogHeader>
      <SkeletonAlertDescription />
      <div className="flex items-center gap-4">
        <Skeleton />
        <Skeleton />
      </div>
      <Skeleton className="h-80 w-full" />
    </div>
  );
};
