import { GripVertical, icons } from 'lucide-react';
import { useMemo, useRef } from 'react';
import { useDrag, useDrop, XYCoord } from 'react-dnd';

import { cn } from '@spektr/style-utils';

import { Button, TextTruncate } from '@spektr/client/components';

import { useColumnsContext } from '../providers';

import { COLUMN } from '../constants';
import { DragItem } from '../types';

type ColumnItemProps = {
  id: string;
  name: string;
  isHidden?: boolean;
};

export const ColumnItem = ({ id, name, isHidden = false }: ColumnItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { columns, canDrag, handleItemVisibilityChange, handleItemMove } =
    useColumnsContext();

  const itemIndex = useMemo(
    () => columns.findIndex((column) => column.name === name),
    [columns, name]
  );

  const [_, drop] = useDrop<DragItem, void>({
    accept: COLUMN,
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }

      const hoverIndex = itemIndex;

      if (item.index === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (item.index < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (item.index > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      handleItemMove(item, hoverIndex, isHidden);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ opacity }, drag] = useDrag({
    type: COLUMN,
    item: () => {
      return { id, name, isHidden, index: itemIndex };
    },
    collect: (monitor: any) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
    canDrag,
  });

  const VisibilityIcon = icons[isHidden ? 'EyeOff' : 'Eye'];

  drag(drop(ref));

  return (
    <div className="relative border-b last:border-0">
      <div
        className={cn(
          'flex items-center gap-2',
          'h-9 w-full',
          'px-8 py-1.5',
          'relative'
        )}
        style={{ opacity }}
        ref={ref}
      >
        <TextTruncate text={name} className="text-xs font-medium" />

        {canDrag && (
          <div
            className={cn(
              'absolute right-3 top-1/2 -translate-y-1/2',
              'cursor-move'
            )}
          >
            <GripVertical
              className={cn('text-color-text-icon-secondary h-4 w-4')}
            />
          </div>
        )}
      </div>
      <Button
        variant="text"
        className="absolute left-3 top-1/2 -translate-y-1/2 p-0"
        onClick={() => handleItemVisibilityChange(name, !isHidden)}
      >
        <VisibilityIcon className="text-color-text-icon-secondary h-4 w-4" />
      </Button>
    </div>
  );
};
