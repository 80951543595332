import { CommentParentType } from '@spektr/shared/validators';

import { GetCommentsByParentIdFilters } from '../types/Filters';

export const COMMENTS_KEYS = {
  ALL: (filters: GetCommentsByParentIdFilters = {}) =>
    ['comments', filters] as const,
  COUNT_BY_PARENT_ID: (parentId?: string, parentType?: CommentParentType) =>
    ['comments', 'count-by-parent-id', parentId, parentType] as const,
};
