import { Suspense } from 'react';

import { CustomerTimelineContainer } from '../containers/CustomerTimelineContainer';
import { SkeletonTimelineView } from '../components/SkeletonTimelineView';

export const CustomerTimeline = () => {
  return (
    <Suspense fallback={<SkeletonTimelineView />}>
      <CustomerTimelineContainer />
    </Suspense>
  );
};
