import { ReactNode, MouseEvent, ComponentPropsWithoutRef } from 'react';
import { DropdownMenuContentProps } from '@radix-ui/react-dropdown-menu';

import { cn } from '@spektr/style-utils';

import { Check } from 'lucide-react';

import { Label } from '../forms';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuItemProps,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './DropdownMenu';

export type DropdownOption = {
  type: 'item' | 'separator' | 'label';
  label?: string | ReactNode;
  disabled?: boolean;
  icon?: ReactNode;
  endIcon?: ReactNode;
  value: string;
  variant?: DropdownMenuItemProps['variant'];
  className?: string;
  selected?: boolean;
  preventClick?: boolean;
};

export type DropdownMenuComboBoxProps = ComponentPropsWithoutRef<
  typeof DropdownMenu
> &
  Pick<
    DropdownMenuContentProps,
    'align' | 'alignOffset' | 'side' | 'sideOffset'
  > & {
    contentClassName?: string;
    itemClassName?: string;
    itemProps?: DropdownMenuItemProps;
    options: DropdownOption[];
    trigger: ReactNode;
    triggerProps?: ComponentPropsWithoutRef<typeof DropdownMenuTrigger>;
    onClick?: (value: string, ev: MouseEvent) => void;
  };

export const DropdownMenuComboBox = ({
  align,
  alignOffset,
  contentClassName,
  itemClassName,
  itemProps,
  side,
  sideOffset,
  modal = false,
  options,
  trigger,
  triggerProps,
  onClick,
  onOpenChange,
}: DropdownMenuComboBoxProps) => {
  const renderOption = (option: DropdownOption, index: number) => {
    if (option.type === 'label') {
      return (
        <Label className={cn('px-2', option.className)} key={index}>
          {option.label}
        </Label>
      );
    }

    if (option.type === 'separator') {
      return <DropdownMenuSeparator key={index} className={option.className} />;
    }

    return (
      <DropdownMenuItem
        className={cn(
          'flex items-center gap-2 truncate',
          itemClassName,
          option.className
        )}
        key={index}
        variant={option?.variant}
        disabled={option?.disabled}
        onClick={(ev) => {
          if (option.preventClick) {
            ev.preventDefault();
            return;
          }
          onClick && onClick(option.value, ev);
        }}
        data-cy={'card-more-options-' + option.value}
        {...itemProps}
        aria-checked={option.selected}
      >
        {option.icon}
        <div className="w-full min-w-0 truncate">{option.label}</div>
        {option.selected && (
          <Check className="text-color-text-subtext ml-auto h-3.5 min-h-3.5 min-w-3.5" />
        )}
        {option?.endIcon}
      </DropdownMenuItem>
    );
  };
  return (
    <DropdownMenu modal={modal} onOpenChange={onOpenChange}>
      <DropdownMenuTrigger {...triggerProps}>{trigger}</DropdownMenuTrigger>
      <DropdownMenuContent
        align={align}
        alignOffset={alignOffset}
        side={side}
        sideOffset={sideOffset}
        className={cn(
          'max-h-64 w-full min-w-48 overflow-y-auto',
          contentClassName
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {options.map(renderOption)}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
