import { useCallback, useState } from 'react';
import { produce } from 'immer';

import {
  ComplyAdvantageKybNode,
  ComplyAdvantageKycNode,
  ServiceNodeMonitor,
} from '@spektr/shared/validators';

import { createServiceFieldMonitor } from '../utils/createServiceFieldMonitor';

export const useComplyAdvantageMonitors = (
  node: ComplyAdvantageKybNode | ComplyAdvantageKycNode
) => {
  const defaultActiveMonitors = node.monitors ?? [];
  const [activeMonitors, setActiveMonitors] = useState<ServiceNodeMonitor[]>(
    defaultActiveMonitors
  );

  const handleFieldSelectionChange = useCallback(
    (field: string, value: boolean) => {
      setActiveMonitors(
        produce((draft) => {
          if (value) {
            draft.push(createServiceFieldMonitor(field));
          } else {
            const index = draft.findIndex(
              (item) => item.rule.rule.left === field
            );

            if (index !== -1) {
              draft.splice(index, 1);
            }
          }
        })
      );
    },
    [setActiveMonitors]
  );

  const handleMonitorUpdate = useCallback(
    (field: string, decision: 'accept' | 'escalate', assignee = '') => {
      setActiveMonitors(
        produce((draft) => {
          const index = draft.findIndex(
            (item) => item.rule.rule.left === field
          );

          if (index !== -1) {
            draft[index].decision.type = decision;

            if (decision === 'escalate') {
              if (draft[index].decision.type === 'escalate') {
                draft[index].decision.assigneeId = assignee;
              }
            }
          }
        })
      );
    },
    [setActiveMonitors]
  );

  return {
    activeMonitors,
    handleFieldSelectionChange,
    handleMonitorUpdate,
  };
};
