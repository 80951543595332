import { useMemo, useRef } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { getTeamMembersQuery } from '@spektr/client/services';

import { type Task } from '@spektr/shared/validators';
import { type ManualReviewNode } from '@spektr/shared/types';

import { DialogEditableTitle } from '../../components/DialogEditableTitle';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { withDialogWrapper } from '../WithDialogWrapper';

import { ManualReviewDialogContent } from './containers/ManualReviewDialogContent';
import { SkeletonContent } from './components/SkeletonContent';

export namespace ManualReviewContent {
  export type Props = BaseDialogProps<ManualReviewNode>;
}

const ManualReviewContent = ({ node, onUpdate }: ManualReviewContent.Props) => {
  const { data: users } = useSuspenseQuery(getTeamMembersQuery());

  const titleRef = useRef<HTMLInputElement>(null);

  const handleSave = (tasks: Task[]) => {
    const title = titleRef?.current?.value ?? node.title;

    onUpdate({
      nodeType: node.nodeType,
      title,
      tasks,
    });
  };

  const handleInputBlur = (title: string) => {
    onUpdate({
      nodeType: node.nodeType,
      title,
      tasks: node.tasks,
    });
  };

  const userOptions = useMemo(
    () =>
      users.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })),
    [users]
  );

  return (
    <>
      <DialogEditableTitle
        titleRef={titleRef}
        initialTitle={node.title}
        onBlur={handleInputBlur}
      />
      <div className="flex flex-col gap-4">
        <ManualReviewDialogContent
          node={node}
          userOptions={userOptions}
          onSave={handleSave}
        />
      </div>
    </>
  );
};

export const ManualReviewDialog = withDialogWrapper(
  ManualReviewContent,
  <SkeletonContent />
);
