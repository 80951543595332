import { forwardRef, useState } from 'react';
import { RgbaColorPicker } from 'react-colorful';

import { fromRgbaObject, toRgbaObject } from '@spektr/style-utils';

import { Input, InputProps } from '../forms';
import { Popover } from '../Popover';

export type ColorPickerProps = InputProps & {
  value?: string;
  onColorChange: (value: string) => void;
};

export const ColorPicker = forwardRef<HTMLInputElement, ColorPickerProps>(
  ({ value = 'rgba(0, 0, 0, 1)', onColorChange, ...props }, ref) => {
    const [open, setOpen] = useState(false);

    return (
      <Input
        {...props}
        value={value}
        ref={ref}
        onChange={(ev) => onColorChange(ev.target.value)}
        endIcon={
          <Popover
            modal={false}
            className="w-fit border-0 bg-transparent"
            align="end"
            alignOffset={0}
            side="bottom"
            sideOffset={10}
            triggerProps={{
              asChild: true,
            }}
            open={open}
            trigger={
              <button
                className="border-color-border-secondary h-5 w-5 shrink-0 rounded-[4px] border"
                style={{
                  backgroundColor: value,
                }}
              />
            }
            onOpenChange={setOpen}
          >
            <RgbaColorPicker
              color={toRgbaObject(value)}
              onChange={(color) => onColorChange(fromRgbaObject(color))}
            />
          </Popover>
        }
      />
    );
  }
);
