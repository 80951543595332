import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { RBAC } from '@spektr/shared/rbac';

import { usePermissionsContext } from '@spektr/client/providers';
import { ActionButton } from '@spektr/client/components';

import { PercentageInput } from '../../components/PercentageInput';
import { EntityLink } from '../../components/EntityLink';

import { type EdgeData } from '../../types/EdgeData';
export namespace ConnectionPopover {
  export type Props = {
    connection?: EdgeData;
  };
}

export const ConnectionPopover = (_props: ConnectionPopover.Props) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissionsContext();
  const isActionAllowed = hasPermission(RBAC.ACTIONS.DATASET.UPDATE);

  const updateClientRecordsMutation = useMutation({
    mutationFn: () =>
      new Promise((resolve) => {
        setTimeout(resolve, 1000);
      }),
  });

  const handleClickSave = async () => {
    return updateClientRecordsMutation.mutateAsync();
  };

  return (
    <div className="flex flex-col p-6">
      <p className="text-color-text-primary text-sm font-medium">
        {t('cases:uboRelationshipDialog.title', {
          defaultValue: 'Relationship',
        })}
      </p>
      <div className="my-4 flex flex-col gap-6">
        <EntityLink
          parent={{
            name: 'INEOS GROUP LIMITED LLC WORLDWIDE OVERFLOW',
            subtext: 'United Kingdom',
            type: 'unknown',
          }}
          child={{
            name: 'INEOS GROUP LIMITED',
            subtext: 'United Kingdom',
            type: 'unknown',
          }}
        />

        <PercentageInput
          icon={'Newspaper'}
          label={t('cases:uboRelationshipDialog.sharesLabel', {
            defaultValue: 'Ownership of shares',
          })}
          disabled={!isActionAllowed || updateClientRecordsMutation.isPending}
          placeholder={t(
            'cases:uboRelationshipDialog.percentageOfSharesPlaceholder',
            {
              defaultValue: 'Percentage of ownership of shares',
            }
          )}
        />

        <PercentageInput
          icon={'ScrollText'}
          label={t('cases:uboRelationshipDialog.votingRightsLabel', {
            defaultValue: 'Ownership of voting rights',
          })}
          disabled={!isActionAllowed || updateClientRecordsMutation.isPending}
          placeholder={t(
            'cases:uboRelationshipDialog.percentageOfVotingPlaceholder',
            {
              defaultValue: 'Percentage of ownership of voting rights',
            }
          )}
        />
      </div>

      <ActionButton
        color="secondary"
        className="w-full"
        rbacAction={RBAC.ACTIONS.DATASET.UPDATE}
        isPending={updateClientRecordsMutation.isPaused}
        pendingLabel={t('common:savePending', { defaultValue: 'Saving...' })}
        onClick={handleClickSave}
      >
        {t('common:save', { defaultValue: 'Save' })}
      </ActionButton>
    </div>
  );
};
