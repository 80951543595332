import { Sparkles } from 'lucide-react';

import { cn } from '@spektr/style-utils';
import { IconBox } from '@spektr/client/components';

export type AiAmlAlertNodeProps = {
  title: string;
};

export const AiAmlAlertNode = ({ title }: AiAmlAlertNodeProps) => {
  return (
    <div className="gradient-border h-full grow rounded-lg p-0.5">
      <div
        className={cn(
          'h-full w-full',
          'flex flex-row items-center gap-4 p-3',
          'rounded-md',
          'bg-color-bg-process-builder-item hover:bg-color-bg-process-builder-item/90'
        )}
      >
        <IconBox
          size="lg"
          color="purple"
          renderIcon={({ className }) => <Sparkles className={className} />}
        />
        <div className="flex flex-col">
          <h3 className="text-color-text-node line-clamp-1 text-sm font-medium">
            {title}
          </h3>
          <span className="text-color-text-node-subtext text-xs">
            Alert step
          </span>
        </div>
      </div>
    </div>
  );
};
