import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSuspenseQuery } from '@tanstack/react-query';
import { ChevronRight } from 'lucide-react';

import { ProcessNode } from '@spektr/shared/types';
import { ProcessType } from '@spektr/shared/validators';

import { cn } from '@spektr/style-utils';
import {
  getProcessLinksByIdQuery,
  getProcessesQuery,
} from '@spektr/client/services';
import {
  DropdownMenuComboBox,
  DropdownOption,
} from '@spektr/client/components';

import { BranchItem } from './BranchItem';

type AdditionalBranchesProps = {
  processId: string;
  processType: ProcessType;
  node: ProcessNode;
};

export const AdditionalBranches = ({
  processId,
  processType,
  node,
}: AdditionalBranchesProps) => {
  const { data: processes } = useSuspenseQuery(getProcessesQuery());
  const { data: links } = useSuspenseQuery(getProcessLinksByIdQuery(processId));
  const { t } = useTranslation('process');

  const finalBranches = useMemo(
    () =>
      node.adj
        .filter((branch) => branch.isFinal)
        .map((branch) => {
          const linkedProcessesIds = links.find(
            (link) => link.source.outcomeId === branch.id
          )?.processes;
          const linkedProcesses = processes.filter((process) =>
            linkedProcessesIds?.includes(process.id)
          );
          return {
            ...branch,
            name:
              node.nodeType === 'kyckr'
                ? t(`nodes.kyckr.outcome.name.${branch.name}`)
                : branch.label || branch.name || 'Untitled branch',
            linkedProcesses,
          };
        }),
    [node.adj, node.nodeType, links, processes, t]
  );

  const branches = useMemo(
    () =>
      finalBranches.map((branch) => ({
        label: (
          <BranchItem
            processId={processId}
            processType={processType}
            branch={branch}
          />
        ),
        type: 'item',
        value: branch.id,
      })) as DropdownOption[],
    [finalBranches, processId, processType]
  );

  const connectedBranches = finalBranches.filter(
    (branch) => branch.linkedProcesses.length > 0
  ).length;

  return (
    <div
      style={{ width: 'calc(100% + 2px)' }}
      className={cn(
        'absolute -left-[1px] bottom-0',
        'translate-y-full transform',
        'bg-inherit hover:bg-inherit',
        'rounded-b-lg border border-inherit'
      )}
    >
      <DropdownMenuComboBox
        side="right"
        modal
        itemClassName="text-sm p-0"
        itemProps={{
          onSelect: (e) => {
            e.preventDefault();
          },
        }}
        trigger={
          <div className="text-color-text-subtext flex w-full items-center gap-2 px-3 py-1.5">
            <span className="text-xs leading-none">
              <span>{`${finalBranches.length} ${finalBranches.length === 1 ? 'branch' : 'branches'}`}</span>
              {connectedBranches > 0 && (
                <span> ({`${connectedBranches} connected`})</span>
              )}
            </span>
            <ChevronRight className="ml-auto h-4 w-4" />
          </div>
        }
        triggerProps={{
          asChild: true,
        }}
        options={branches}
        onClick={console.log}
      />
    </div>
  );
};
