import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { updateFieldsFactory } from '@spektr/client/utils';

import { toast } from '@spektr/client/components';
import { SectionList } from '@spektr/model-builder/components';

import { getAllowedSpektrFieldsQuery } from '@spektr/client/services';

import { type BodaccNode } from '@spektr/shared/validators';

import { mapSelectedFields } from '../../utils/mapSelectedFields';
import { useServiceFields } from '../../hooks/useServiceFields';
import { useSelectedFields } from '../../hooks/useSelectedFields';
import { ServiceCommonMappingForm } from '../../components/ServiceCommonMappingForm';
import { DialogFooterActions } from '../../components/DialogFooterActions';
import { SelectableServiceItem } from '../../components/SelectableServiceItem';

import { type BaseDialogProps } from '../../types/BaseDialogProps';
import { withDialogWrapper } from '../WithDialogWrapper';

import { ContentSkeleton } from './components/ContentSkeleton';

export namespace BodaccContent {
  export type Props = BaseDialogProps<BodaccNode>;
}

const BodaccContent = ({
  isPendingUpdate,
  node,
  processId,
  onUpdate,
}: BodaccContent.Props) => {
  const { t } = useTranslation();

  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, node.id)
  );

  const formInstance = useForm<Record<string, string>>({
    defaultValues: node?.mapping ?? {},
    mode: 'onChange',
  });

  const formEntries = useMemo(
    () => [
      {
        key: 'siretNumber',
        label: t('fields:bodacc.company_number'),
        description: '* Mandatory',
      },
      {
        key: 'companyName',
        label: t('fields:bodacc.company_name'),
        type: 'string',
        description: '',
      },
    ],
    [t]
  );

  const { fields, serviceFields } = useServiceFields(node);
  const { selectedFields, handleFieldChange } = useSelectedFields(
    node,
    serviceFields
  );

  const handleSave = async () => {
    const mapping = formInstance.getValues();
    const fieldsMap = mapSelectedFields(fields, selectedFields);

    await onUpdate(updateFieldsFactory(node, fieldsMap, mapping));

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });
  };

  return (
    <FormProvider {...formInstance}>
      <div className="my-2 flex flex-col gap-4 rounded-md border p-4">
        <ServiceCommonMappingForm
          spektrFields={spektrFields}
          form={formEntries}
          title={t('process:baseMapAttributesTitle')}
        />

        <SectionList data={serviceFields}>
          {(item) => (
            <SelectableServiceItem
              key={item.key}
              checked={selectedFields.includes(item.key)}
              field={item}
              onChange={(value) => handleFieldChange(item.key, value)}
            />
          )}
        </SectionList>
      </div>
      <DialogFooterActions
        isServiceNode
        btnColor="white"
        disabled={!formInstance.formState.isValid}
        isPendingUpdate={isPendingUpdate}
        onSave={handleSave}
      />
    </FormProvider>
  );
};

export const BodaccDialog = withDialogWrapper(
  BodaccContent,
  <ContentSkeleton />,
  {
    className: 'max-w-[700px]',
  }
);
