import { z } from 'zod';

import { objectIdSchema } from '../common';
import { PaginationFields } from '../pagination';

export const baseChildSchemaDto = z.object({
  text: z.string(),
  bold: z.boolean().optional(),
  italic: z.boolean().optional(),
  underline: z.boolean().optional(),
});

export const childSchemaDto = baseChildSchemaDto.extend({
  type: z.literal('link').optional(),
  url: z.string().optional(),
  target: z.enum(['_blank', '_self', '_parent', '_top']).optional(),
  children: z.array(baseChildSchemaDto).optional(),
});

export const messageBlockSchemaDto = z.object({
  type: z.enum(['paragraph']),
  children: z.array(childSchemaDto),
});
export type CommentMessageBlock = z.infer<typeof messageBlockSchemaDto>;

export const parentTypeSchema = z.enum(['action', 'hit', 'alert', 'customer']);
export type CommentParentType = z.infer<typeof parentTypeSchema>;

export const CommentCreateSchema = z.object({
  parentId: objectIdSchema,
  parentType: parentTypeSchema,
  message: z.array(messageBlockSchemaDto),
});
export type CommentCreate = z.infer<typeof CommentCreateSchema>;

export const CommentUpdateSchema = z.object({
  message: z.array(messageBlockSchemaDto),
});
export type CommentUpdate = z.infer<typeof CommentUpdateSchema>;

export const commentSchemaDto = z.object({
  id: objectIdSchema,
  parentId: objectIdSchema,
  parentType: parentTypeSchema,
  userId: z.string().min(1),
  message: z.array(messageBlockSchemaDto),
  workspaceId: z.string().min(1),
  createdAt: z.number(),
  updatedAt: z.number(),
});

export const commentListResponsePaginatedSchemaDto = PaginationFields.merge(
  z.object({
    docs: z.array(commentSchemaDto),
  })
);
export type CommentList = z.infer<typeof commentListResponsePaginatedSchemaDto>;
