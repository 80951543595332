import { SpektrField } from '@spektr/shared/types';
import { ServiceNodeMonitor } from '@spektr/shared/validators';

export const getMonitorErrors = (
  monitor: ServiceNodeMonitor,
  monitors: ServiceNodeMonitor[],
  spektrFields: SpektrField[]
) => {
  const { left, right } = monitor.rule.rule;

  if (left && right) {
    if (
      left !== right &&
      spektrFields.findIndex((field) => field.key === right) === -1
    ) {
      return {
        message: 'Please enter a valid upstream field.',
        field: 'right',
      };
    }

    const sameFieldMonitors = monitors.filter(
      (m) => m.rule.rule.left === left && m.rule.rule.right === right
    );

    if (sameFieldMonitors.length > 1) {
      return {
        message: `There is already a monitor that tracks if ${left === right ? 'this field has changed.' : 'these fields are different.'}`,
        field: 'left',
      };
    }
  }

  return null;
};
