import { Skeleton } from '@spektr/client/components';

export const SkeletonContent = () => (
  <div className="flex flex-col gap-6">
    <div className="flex flex-col gap-4">
      <p className="text-color-text-subtext flex items-center gap-1 text-xs font-medium">
        Select categories and frequency
      </p>
      <div className="flex max-h-[280px] flex-col rounded-md border">
        {new Array(4).fill(null).map(() => (
          <div className="flex h-[52px] shrink-0 items-center justify-start border-b px-4 text-left transition-colors last:border-b-0">
            <Skeleton className="h-4 w-20" />
            <div className="ml-auto flex items-center gap-2">
              <Skeleton className="h-4 w-40" />
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
