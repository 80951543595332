import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';

import { cn } from '@spektr/style-utils';

export type DialogDescriptionProps = ComponentPropsWithoutRef<
  typeof DialogPrimitive.Description
>;

export const DialogDescription = forwardRef<
  ElementRef<typeof DialogPrimitive.Description>,
  DialogDescriptionProps
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn(
      'bg-color-bg-card-accent text-color-text-dialog-description rounded-radius p-3 text-sm',
      className
    )}
    {...props}
  />
));
