import { z } from 'zod';
import {
  SpektrFieldType,
  spektrFieldDetailsSchema,
  spektrFieldKeySchema,
  spektrFieldTypeSchema,
} from '@spektr/shared/validators';

import { openCorporatesRiskSpektrFields } from './goldenRecord/vendorSpektrFields/openCorporatesRiskFields';
import { openCorporatesMonitoringSpektrFields } from './goldenRecord/vendorSpektrFields/openCorporatesMonitoringFields';
import { complyAdvantageKycSpektrFields } from './goldenRecord/vendorSpektrFields/complyAdvantageKycFields';
import { complyAdvantageKybSpektrFields } from './goldenRecord/vendorSpektrFields/complyAdvantageKybFields';
import { bodaccSpektrFields } from './goldenRecord/vendorSpektrFields/bodaccFields';
import {
  kyckrSpektrFields,
  parentProcessFields,
  representativesCorporationsFields,
  representativesIndividualsFields,
  uboAllPossibleLayersFields,
  uboFirstLayerFields,
} from './goldenRecord/vendorSpektrFields/kyckrFields';
import { veriffSpektrFields } from './goldenRecord/vendorSpektrFields/veriffFields';
import { veriffIdvSpektrFields } from './goldenRecord/vendorSpektrFields/veriffIdvFields';

import {
  customerStatusSpektrFields,
  customerStatusSpektrFieldsMapping,
  monitoringDatasetSpektrFields,
  returningProcessSpektrFields,
} from './goldenRecord/otherSpektrFields/otherFields';
import {
  mitIdSpektrFields,
  mitIdCprNumberFields,
  mitIdForCitizenBaseFields,
  mitIdForCitizenAddressFields,
  otherCalculatedFields as mitIdOtherCalculatedFields,
} from './goldenRecord/vendorSpektrFields/mitIdFields';
import { commonSpektrFields } from './goldenRecord/commonSpektrFields/commonSpektrFields';
import { virkSpektrFields } from './goldenRecord/vendorSpektrFields/virkFields';

export type SpektrField = z.infer<typeof spektrFieldDetailsSchema>;
export type SpektrFieldWithInfo = SpektrField & {
  help?: string;
};

export function isValidSpektrFieldType(
  value: string
): value is SpektrFieldType {
  return spektrFieldTypeSchema.safeParse(value).success;
}

export type SpektrFieldKey = z.infer<typeof spektrFieldKeySchema>;
export type SpektrFieldValueType = string | number | boolean | null;
export type SpektrDataDeprecated = Partial<
  Record<SpektrFieldKey, SpektrFieldValueType>
>;

export type SpektrFieldOperator = {
  key: string;
  label: string;
  type: string;
  right?: null | string;
};

export const openCorporatesRiskFields = openCorporatesRiskSpektrFields;

export const openCorporatesMonitoringFields =
  openCorporatesMonitoringSpektrFields;

export const complyAdvantageKycFields = complyAdvantageKycSpektrFields;

export const complyAdvantageKybFields = complyAdvantageKybSpektrFields;

export const kyckrParentProcessFields = parentProcessFields;
export const kyckrUboAllPossibleLayersFields = uboAllPossibleLayersFields;
export const kyckrUboFirstLayerFields = uboFirstLayerFields;
export const kyckrRepresentativesCorporationsFields =
  representativesCorporationsFields;
export const kyckrRepresentativesIndividualFields =
  representativesIndividualsFields;
export const kyckrFields = kyckrSpektrFields;

export const monitoringDatasetFields = monitoringDatasetSpektrFields;
export const returningProcessFields = returningProcessSpektrFields;

export const bodaccFields = bodaccSpektrFields;

export const commonFields = commonSpektrFields;

export const mitIdFields = mitIdSpektrFields;
export const mitIdCprFields = mitIdCprNumberFields;
export const mitIdBaseFields = [
  ...mitIdForCitizenBaseFields,
  ...mitIdOtherCalculatedFields,
];
export const mitIdAddressFields = mitIdForCitizenAddressFields;

export const veriffFields = veriffSpektrFields;

export const veriffIdvFields = veriffIdvSpektrFields;

export const customerStatusFields = customerStatusSpektrFields;
export const customerStatusFieldToStatusMap = customerStatusSpektrFieldsMapping;

export const virkFields = virkSpektrFields;

export const allVendorFields = [
  ...openCorporatesRiskFields,
  ...openCorporatesMonitoringFields,
  ...complyAdvantageKycFields,
  ...complyAdvantageKybFields,
  ...bodaccFields,
  ...kyckrFields,
  ...returningProcessFields,
  ...mitIdFields,
  ...veriffFields,
  ...virkFields,
];

export const spektrOperators: SpektrFieldOperator[] = [
  { key: 'equals', label: 'is text', type: 'string' },
  { key: 'not_equals', label: 'is not text', type: 'string' },
  { key: 'is_empty', label: 'is empty', type: 'string' },
  { key: 'is_not_empty', label: 'is not empty', type: 'string' },
  { key: 'equals', label: '=', type: 'number' },
  { key: 'not_equals', label: '≠', type: 'number' },
  { key: 'greater_than', label: '>', type: 'number' },
  { key: 'less_than', label: '<', type: 'number' },
  { key: 'between', label: 'between', type: 'number' },
  { key: 'outside', label: 'outside of', type: 'number' },
  { key: 'equals', label: 'is true', type: 'boolean', right: 'true' },
  { key: 'not_equals', label: 'is false', type: 'boolean', right: 'true' },
  { key: 'equals', label: 'is on', type: 'date' },
  { key: 'not_equals', label: 'is not on', type: 'date' },
  { key: 'is_after', label: 'is after', type: 'date' },
  { key: 'is_before', label: 'is before', type: 'date' },
  { key: 'is_in', label: 'is in', type: 'matrix' },
  { key: 'is_not_in', label: 'is not in', type: 'matrix' },
];
