import { RBAC } from '@spektr/shared/rbac';

import { usePermissionsContext } from '@spektr/client/providers';
import {
  AsyncButton,
  BaseVariantColor,
  ButtonProps,
  DialogFooter,
  ServiceButton,
  ServiceButtonProps,
  ServiceVariantColor,
} from '@spektr/client/components';

export namespace DialogFooterActions {
  export type Props = {
    isServiceNode?: boolean;
    btnColor?: BaseVariantColor | ServiceVariantColor;
    disabled?: boolean;
    isPendingUpdate?: boolean;
    saveText?: string;
    pendingSaveText?: string;
    onSave: () => void;
  };
}

export const DialogFooterActions = ({
  isServiceNode = false,
  btnColor = 'primary',
  disabled,
  isPendingUpdate,
  pendingSaveText = 'Saving...',
  saveText = 'Save',
  onSave,
}: DialogFooterActions.Props) => {
  const { hasPermission } = usePermissionsContext();
  const canEdit = hasPermission(RBAC.ACTIONS.NODE.UPDATE);

  return (
    <DialogFooter className="pt-6">
      {isServiceNode ? (
        <ServiceButton
          color={btnColor as ServiceButtonProps['color']}
          disabled={disabled || !canEdit}
          isPending={isPendingUpdate}
          label={saveText}
          pendingLabel={pendingSaveText}
          onClickCapture={onSave}
        />
      ) : (
        <AsyncButton
          color={btnColor as ButtonProps['color']}
          disabled={disabled || !canEdit}
          isPending={isPendingUpdate}
          label={saveText}
          pendingLabel={pendingSaveText}
          onClick={onSave}
        />
      )}
    </DialogFooter>
  );
};
