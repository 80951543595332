import type { Primitive } from '../types';

export const DEFAULT_WRITABLE_PRIMITIVES: Primitive[] = [
  {
    id: 'text',
    category: 'inputs',
    title: 'fields.text',
    icon: 'TextCursorInput',
    type: 'input' as const,
    isStructured: false,
  },
  {
    id: 'number',
    category: 'inputs',
    title: 'fields.number',
    icon: 'Binary',
    type: 'input' as const,
    isStructured: false,
  },
  {
    id: 'currency',
    category: 'inputs',
    title: 'fields.currency',
    icon: 'DollarSign',
    type: 'input' as const,
    isStructured: false,
  },
  {
    id: 'percentage',
    category: 'inputs',
    title: 'fields.percentage',
    icon: 'Percent',
    type: 'input' as const,
    isStructured: false,
  },
  {
    id: 'email',
    category: 'inputs',
    title: 'fields.email',
    icon: 'Mail',
    type: 'input' as const,
    isStructured: false,
  },
  {
    id: 'phone',
    category: 'inputs',
    title: 'fields.phone',
    icon: 'Phone',
    type: 'input' as const,
    isStructured: false,
  },
  {
    id: 'date',
    category: 'inputs',
    title: 'fields.date',
    icon: 'CalendarPlus2',
    type: 'input' as const,
    isStructured: false,
  },
  {
    id: 'checkbox',
    category: 'inputs',
    title: 'fields.checkbox',
    icon: 'ListChecks',
    type: 'checkbox' as const,
    isStructured: false,
  },
  {
    id: 'radio',
    category: 'inputs',
    title: 'fields.radio',
    icon: 'Circle',
    type: 'radio' as const,
    isStructured: false,
  },
  {
    id: 'dropdown',
    category: 'inputs',
    icon: 'List',
    title: 'fields.dropdown',
    type: 'dropdown' as const,
    isStructured: false,
  },
  {
    id: 'country',
    category: 'inputs',
    icon: 'MapPinned',
    title: 'fields.country',
    type: 'input' as const,
    isStructured: false,
  },

  {
    id: 'entity',
    category: 'entities',
    title: 'fields.entity',
    icon: 'ListTree',
    type: 'entity' as const,
    isStructured: false,
  },

  //
  {
    id: 'file',
    category: 'file',
    title: 'fields.file',
    icon: 'FilePlus',
    type: 'file' as const,
    isStructured: false,
  },
  {
    id: 'image',
    category: 'file',
    title: 'fields.image',
    icon: 'ImagePlus',
    type: 'file' as const,
    isStructured: false,
  },
];

export const DEFAULT_READONLY_PRIMITIVES: Primitive[] = [
  {
    id: 'title',
    category: 'texts',
    title: 'fields.title',
    icon: 'Type',
    type: 'title' as const,
    isStructured: false,
  },
  {
    id: 'paragraph',
    category: 'texts',
    title: 'fields.paragraph',
    icon: 'WrapText',
    type: 'paragraph' as const,
    isStructured: false,
  },
  {
    id: 'divider',
    category: 'layout',
    title: 'fields.divider',
    icon: 'SquareSplitVertical',
    type: 'divider' as const,
    isStructured: false,
  },
];

export const DATE_FORMATS = [
  'dd/MM/yyyy',
  'MM/dd/yyyy',
  'yyyy-MM-dd',
  'dd-MMM-yyyy',
  'dd/MMM/yyyy',
];

export const CURRENCIES = [
  { value: 'USD', label: 'United States Dollar ($)', symbol: '$' },
  { value: 'EUR', label: 'Euro (€)', symbol: '€' },
  { value: 'GBP', label: 'British Pound (£)', symbol: '£' },
  { value: 'JPY', label: 'Japanese Yen (¥)', symbol: '¥' },
  { value: 'DKK', label: 'Danish Krone (kr)', symbol: 'DKK' },
  { value: 'RON', label: 'Romanian Leu (lei)', symbol: 'RON' },
];
