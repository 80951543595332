import { useMemo } from 'react';

import { casesAlertHitsParamsSchema } from '@spektr/shared/utils';

import { useParsedParams } from '@spektr/shared/hooks';

export function useAlertAndHitIdParam() {
  const { alertId, hitId } = useParsedParams(casesAlertHitsParamsSchema);

  return useMemo(() => {
    return {
      alertId,
      hitId,
    };
  }, [alertId, hitId]);
}
