import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@spektr/client/components';

type AlertDialogHeaderProps = {
  children: React.ReactNode;
};

export const AlertDialogHeader = ({ children }: AlertDialogHeaderProps) => {
  return (
    <DialogHeader className="flex-row items-center justify-between gap-6 space-y-0">
      <DialogTitle className="text-sm font-medium">Single alert</DialogTitle>

      <VisuallyHidden asChild>
        <DialogDescription />
      </VisuallyHidden>

      {children}
    </DialogHeader>
  );
};
