import { Suspense } from 'react';
import { PlusSquare, ServerCrash } from 'lucide-react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useMeasure } from 'react-use';

import { LOOPS_NEW_FULL_URL } from '@spektr/shared/utils';
import { RBAC } from '@spektr/shared/rbac';

import {
  Button,
  PageHeadline,
  ScrollArea,
  SpektrErrorBoundary,
} from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';

import { SkeletonLoopList } from '@spektr/model-builder/components';

import { LoopsToolbar } from './components/LoopsToolbar';
import { LoopsList } from './components/LoopsList';

export const LoopsDashboard = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const allStatuses = ['all', 'live', 'notLive'];
  const status = searchParams.get('status') || 'all';
  const [ref, size] = useMeasure<HTMLDivElement>();
  const { hasPermission } = usePermissionsContext();

  return (
    <div className="flex h-full flex-col gap-6 px-4 pt-5">
      <div className="flex shrink-0 flex-row items-center">
        <PageHeadline
          headline="Loop Engine"
          paragraph="  Create actionable feedback loops on your processes"
        />
        <div className="ml-auto">
          <Button
            className="font-semibold"
            color="red"
            startIcon={<PlusSquare className="h-4 w-4 stroke-2" />}
            onClick={() => navigate(LOOPS_NEW_FULL_URL)}
            disabled={!hasPermission(RBAC.ACTIONS.PROCESS.CREATE)}
          >
            Add new loop
          </Button>
        </div>
      </div>
      <LoopsToolbar
        allStatuses={allStatuses}
        status={status}
        onChangeStatus={(value) => {
          const update = value === 'all' ? {} : { status: value };
          setSearchParams((params) => ({ ...params, ...update }));
        }}
      />
      <div ref={ref} className="relative h-full w-full overflow-hidden">
        <div className="absolute inset-0">
          <SpektrErrorBoundary
            fallback={
              <div className="flex h-full flex-col items-center justify-center gap-2 p-8">
                <ServerCrash className="stroke-color-red h-8 w-8" />
                <span className="text-color-text-error-boundry">
                  There was an error while loading the loops.
                </span>
              </div>
            }
          >
            <Suspense fallback={<SkeletonLoopList />}>
              <ScrollArea
                style={{
                  height: size.height,
                }}
              >
                <LoopsList status={status} />
              </ScrollArea>
            </Suspense>
          </SpektrErrorBoundary>
        </div>
      </div>
    </div>
  );
};
