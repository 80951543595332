import isEqual from 'lodash/isEqual';

import { DEFAULT_STATE } from '../../../constants/engine';

export const getDefaultFormValues = (
  target: any,
  source: any = DEFAULT_STATE,
  defaultState = DEFAULT_STATE
): any => {
  if (typeof target !== 'object' || target === null) {
    return target;
  }

  if (typeof source !== 'object' || source === null) {
    return target;
  }

  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      if (key === '__proto__' || key === 'constructor') {
        continue;
      }

      if (
        key === 'description' &&
        isEqual(defaultState.assistingContent.description, source.description)
      ) {
        if (!target.description) {
          target.description = source.description;
        }
        continue;
      }

      if (
        key === 'content' &&
        isEqual(defaultState.assistingContent.support.content, source.content)
      ) {
        if (!target.content) {
          target.content = source.content;
        }
        continue;
      }

      if (typeof source[key] === 'object' && source[key] !== null) {
        if (!target[key]) {
          target[key] = Array.isArray(source[key]) ? [] : {};
        }
        getDefaultFormValues(target[key], source[key]);
      } else if (target[key] === undefined) {
        target[key] = source[key];
      }
    }
  }

  return target;
};
