import { useFormContext, useFormState } from 'react-hook-form';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { useTriggerFrequencies } from '../../../../hooks/useTriggerFrequencies';
import { useMonitorsContext } from '../../../../providers/Monitors';

import { DialogFooterActions } from '../../../../components/DialogFooterActions';

export namespace DialogFooter {
  export type Props = {
    isPendingUpdate?: boolean;
    // TODO: @Alex - ST-2688 - update this after feature flag is removed
    // onSave: (monitors: ServiceNodeMonitor[]) => void;
    onSave: (data: any) => void;
  };
}

export const DialogFooter = ({
  isPendingUpdate,
  onSave,
}: DialogFooter.Props) => {
  const { control } = useFormContext();
  const { isValid } = useFormState({
    control,
  });
  const { newServiceConfig } = useFeatureFlags();
  const { triggers } = useTriggerFrequencies();
  const { monitors, areMonitorsValid } = useMonitorsContext();

  return (
    <DialogFooterActions
      isServiceNode
      btnColor="openCorporates"
      disabled={(newServiceConfig && !areMonitorsValid) || !isValid}
      isPendingUpdate={isPendingUpdate}
      onSave={() => onSave(newServiceConfig ? monitors : triggers)}
    />
  );
};
