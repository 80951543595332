import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { cn } from '@spektr/style-utils';

export type RadioGroupItemProps = ComponentPropsWithoutRef<
  typeof RadioGroupPrimitive.Item
>;

export const RadioGroupItem = forwardRef<
  ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemProps
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        'h-4 w-4 shrink-0',
        'rounded-full border',
        'aspect-square',
        'focus:outline-none',
        'focus-visible:ring-ring focus-visible:ring-1',
        'disabled:cursor-not-allowed disabled:opacity-50',
        'border-color-border-input',
        'text-color-text-radio',
        className
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="border-color-border-input flex items-center justify-center">
        <div
          className={cn('h-2 w-2', 'rounded-full', 'bg-color-bg-secondary')}
        />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;
