import { useState } from 'react';
import { format } from 'date-fns';
import { cva, VariantProps } from 'class-variance-authority';

import { CognitoUserData } from '@spektr/shared/types';

import { useUserInformationContext } from '@spektr/client/providers';
import { cn } from '@spektr/style-utils';
import { Avatar, Button, RichTextEditor } from '@spektr/client/components';
import { type SlateDescendant } from '@spektr/shared/slate-types';

import { FULL_DATE_TIME_FORMAT } from '@spektr/client/constants';

import { CommentMessageBlock } from '@spektr/shared/validators';

import { isLongComment } from '../../utils';
import { CommentAlerts } from '../CommentAlerts';

const commentVariants = cva('flex flex-wrap 2xl:flex-nowrap', {
  variants: {
    variant: {
      inline: 'bg-color-transparent',
      card: cn(
        'bg-color-bg-card-default p-6',
        'border-color-border-primary rounded-md border'
      ),
    },
  },
  defaultVariants: {
    variant: 'inline',
  },
});

type CommentProps = VariantProps<typeof commentVariants> & {
  id: string;
  message: SlateDescendant[];
  user: CognitoUserData | undefined;
  updated: number;
  parentId?: string;
  onDeleteComment?: () => void;
};

export const Comment = ({
  variant,
  id,
  message,
  user,
  updated,
  parentId,
  onDeleteComment,
}: CommentProps) => {
  const currentUserInfo = useUserInformationContext();
  const [showMoreOfComment, setShowMoreOfComment] = useState<boolean>(false);
  const needToHidePartOfComment = isLongComment(
    message as CommentMessageBlock[]
  );

  const isCurrentUsersComment = user?.username === currentUserInfo?.email;

  return (
    <div className={commentVariants({ variant })}>
      <div className="relative order-1 flex w-1/2 items-center gap-3.5 self-start 2xl:w-1/5">
        <Avatar
          firstName={user?.firstName ?? ''}
          lastName={user?.lastName ?? ''}
        />
        <div className="flex min-w-0 flex-col">
          <p className="text-color-text-primary text-sm">
            {user?.firstName} {user?.lastName}
          </p>
          <p className="text-color-text-subtext w-full overflow-hidden text-ellipsis text-xs">
            {user?.username}
          </p>
        </div>
      </div>

      <div
        className={cn(
          'flex flex-col',
          'w-full 2xl:w-3/5',
          'order-3 2xl:order-2',
          'px-0 py-4 2xl:px-4 2xl:py-0'
        )}
      >
        <RichTextEditor
          className={cn(
            needToHidePartOfComment &&
              !showMoreOfComment &&
              'line-clamp-6 overflow-y-hidden'
          )}
          initialValue={message}
          resetOnUpdate
          isReadOnly
        />
        {needToHidePartOfComment && (
          <Button
            size="sm"
            variant="text"
            className="text-color-text-subtext w-min p-0"
            onClick={() => setShowMoreOfComment(!showMoreOfComment)}
          >
            {showMoreOfComment ? 'Read less' : 'Read more'}
          </Button>
        )}
      </div>

      <div
        className={cn(
          'flex items-center justify-end gap-4 self-start',
          'w-1/2 2xl:w-1/5',
          'shrink-0',
          'order-2 2xl:order-3'
        )}
      >
        <p className="text-color-text-subtext text-xs">
          {format(updated, FULL_DATE_TIME_FORMAT)}
        </p>
        {isCurrentUsersComment && (
          <CommentAlerts
            commentId={id}
            commentParentId={parentId}
            commentMessage={message}
            onDeleteComment={onDeleteComment}
          />
        )}
      </div>
    </div>
  );
};
