import { ReactNode } from 'react';

import { cn } from '@spektr/style-utils';
import { VariantProps, cva } from 'class-variance-authority';

export type BoxIconProps = VariantProps<typeof boxVariants> & {
  className?: string;
  renderIcon: (props: { className: string }) => ReactNode;
};

export const BoxIcon = ({ className, renderIcon, size }: BoxIconProps) => {
  return (
    <div className={cn(boxVariants({ size, className }))}>
      {renderIcon({
        className: cn(iconSizeVariants({ size })),
      })}
    </div>
  );
};

const boxVariants = cva(
  ['flex items-center justify-center', 'h-5 w-5', 'rounded-md'],
  {
    variants: {
      size: {
        sm: 'h-4 w-4',
        md: 'h-5 w-5',
        lg: 'h-6 w-6',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
);

const iconSizeVariants = cva('', {
  variants: {
    size: {
      sm: 'h-2 w-2',
      md: 'h-2.5 w-2.5',
      lg: 'h-3 w-3',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});
