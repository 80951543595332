import { cn } from '@spektr/style-utils';

import { BasicDialog, Tag, TagInfo } from '@spektr/client/components';
import { ClientRecordTagModel } from '@spektr/shared/validators';

export type TagsDialogProps = {
  customerName?: string;
  tags: ClientRecordTagModel[];
  open: boolean;
  onClose: () => void;
};

export const TagsDialog = ({
  customerName,
  tags,
  open,
  onClose,
}: TagsDialogProps) => {
  return (
    <BasicDialog
      className="rounded-2xl border-0"
      key={'customer-tags-' + open}
      defaultOpen={open}
      title={'Tags for ' + customerName}
      onClose={onClose}
    >
      <div className="flex flex-col gap-4">
        {tags.map((tag, index) => (
          <div
            key={index}
            className={cn(
              'flex items-center gap-4 pb-4',
              index !== tags.length - 1 && 'border-b'
            )}
          >
            <Tag
              isDeleted={tag.isDeleted}
              color={tag.color}
              label={tag.label}
              className={cn(
                'cursor-default',
                'bg-color-bg-dialog-content p-4 pr-7',
                'border-color-border-default/60 rounded-md',
                'border dark:border-transparent',
                'w-40 justify-center'
              )}
            />

            <TagInfo
              createdAt={tag.createdAt}
              sourceProcessId={tag.sourceProcessId}
              sourceProcessName={tag.sourceProcessName}
            />
          </div>
        ))}
      </div>
    </BasicDialog>
  );
};
