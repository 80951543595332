import { Skeleton } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

export const SkeletonDatasetList = () => {
  const rows = new Array(4).fill(null).map((_, index) => ({
    key: 'row-' + index,
  }));

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      {rows.map((row) => (
        <div
          key={row.key}
          className={cn(
            'flex flex-col',
            'h-[188px] w-full',
            'rounded-md border',
            'overflow-hidden transition-colors'
          )}
        >
          <div className="flex w-full px-4 pt-4">
            <Skeleton className="h-6 w-6" />
          </div>
          <div className="grow p-4">
            <div className="flex h-full flex-col gap-3">
              <Skeleton className="h-6 w-48" />

              <div className="flex h-full w-full flex-col gap-3">
                <div className="mt-auto flex grow gap-7">
                  <div className="flex grow flex-col gap-2">
                    <Skeleton className="h-5 w-5" />
                    <Skeleton className="h-4 w-24" />
                  </div>
                  <div className="flex grow flex-col gap-2">
                    <Skeleton className="h-5 w-5" />
                    <Skeleton className="h-4 w-24" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={cn('border-t', 'px-4 py-3', 'text-[10px] font-medium')}
          >
            <Skeleton className="h-3 w-40" />
          </div>
        </div>
      ))}
    </div>
  );
};
