import { z } from 'zod';

export const spektrAIStatusSchema = z.enum(['disabled', 'pending', 'enabled']);
export type SpektrAIStatus = z.infer<typeof spektrAIStatusSchema>;

export const SpektrAIDetailsNotEnabled = z.object({
  status: z.union([
    z.literal('disabled'), // 'disabled' indicates that spektr-ai has not been initiated to resolve the hit.
    z.literal('pending'), // 'pending' indicates that spektr-ai is in progress to resolve the hit.
    z.literal('failed'), // 'failed' indicates that spektr-ai has failed to resolve the hit.
  ]),
});
export const spektrAiDetailsEnabled = z.object({
  status: z.literal('enabled'), // 'enabled' indicates that spektr-ai has resolved the hit.
  mediaCategory: z.string(),
  relatedToTarget: z.boolean(),
  relatedToTargetReason: z.string(),
  isAdverseMedia: z.boolean(),
  isAdverseMediaReason: z.string(),
});

export const SpektrAiDetails = z.union([
  SpektrAIDetailsNotEnabled,
  spektrAiDetailsEnabled,
]);

export type SpektrAIDetailsNotEnabled = z.infer<
  typeof SpektrAIDetailsNotEnabled
>;
export type SpektrAIDetailsEnabled = z.infer<typeof spektrAiDetailsEnabled>;
export type SpektrAiDetails = z.infer<typeof SpektrAiDetails>;
