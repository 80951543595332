import { ChevronLeft, ChevronRight } from 'lucide-react';

import { IconButton } from '@spektr/client/components';

import { useNavigateToAlert, useNavigateToHit } from '../../hooks';

type AlertNavigationProps = {
  prevId?: string;
  nextId?: string;
  alertId?: string;
};

export const AlertNavigation = ({
  prevId,
  nextId,
  alertId,
}: AlertNavigationProps) => {
  const navigateToHit = useNavigateToHit();
  const navigateToAlert = useNavigateToAlert();

  const navigateToEntity = (id: string) => {
    if (alertId) {
      navigateToHit(alertId, id);
    } else {
      navigateToAlert(id);
    }
  };

  return (
    <div className="flex items-center gap-2">
      <IconButton
        color="secondary"
        variant="outlined"
        className="h-9 w-9 p-2.5"
        disabled={!prevId}
        onClick={prevId ? () => navigateToEntity(prevId) : undefined}
      >
        <ChevronLeft />
      </IconButton>
      <IconButton
        color="secondary"
        variant="outlined"
        className="h-9 w-9 p-2.5"
        disabled={!nextId}
        onClick={nextId ? () => navigateToEntity(nextId) : undefined}
      >
        <ChevronRight />
      </IconButton>
    </div>
  );
};
