import { useMemo } from 'react';
import { Inbox } from 'lucide-react';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  getAlertFieldsQuery,
  getProcessesQuery,
} from '@spektr/client/services';
import {
  flattenAlerts,
  getFieldsFromAlerts,
  getFieldsWithoutProperties,
} from '@spektr/client/utils';

import {
  type AdverseMediaAuthorityLevels,
  type AiAmlAlertNode,
} from '@spektr/shared/validators';
import { type AlertField } from '@spektr/client/types';
import { type Process } from '@spektr/shared/types';

import { FieldItem } from '../ProcessConfigItem/components/FieldItem';

export type AiAmlAlertContentProps = {
  isPendingUpdate?: boolean;
  process: Process;
  node: AiAmlAlertNode;
  value?: AdverseMediaAuthorityLevels;
  onAuthorityChange: (
    field: AlertField,
    authority: AdverseMediaAuthorityLevels
  ) => void;
};

export const AiAmlAlertContent = ({
  isPendingUpdate,
  process,
  node,
  value,
  onAuthorityChange,
}: AiAmlAlertContentProps) => {
  const { data: processes } = useSuspenseQuery(getProcessesQuery());
  const { data: alertFields } = useSuspenseQuery(
    getAlertFieldsQuery(process.id, node.id)
  );
  const fields = useMemo(() => {
    const alerts = flattenAlerts(alertFields);

    const fields = getFieldsFromAlerts(alerts, processes);
    const fieldsWithoutProperties = getFieldsWithoutProperties(fields);

    return fieldsWithoutProperties.reduce(
      (acc, field) => {
        if (Array.isArray(acc[field.processId])) {
          return {
            ...acc,
            [field.processId]: [...acc[field.processId], field],
          };
        }
        return {
          ...acc,
          [field.processId]: [field],
        };
      },
      {} as Record<string, AlertField[]>
    );
  }, [alertFields, processes]);

  const flattenFields = useMemo(() => {
    return Object.values(fields).flat();
  }, [fields]);

  const complyAdvantageFields = flattenFields.filter(
    (field) =>
      field.nodeType === 'complyAdvantageKyb' ||
      field.nodeType === 'complyAdvantageKyc'
  );

  if (Object.keys(fields).length === 0) {
    return (
      <div className="flex flex-col items-center justify-center gap-2">
        <Inbox className="stroke-color-stroke-primary h-8 w-8" />
        <p>You need to select some sources before configuring them.</p>
      </div>
    );
  }

  if (complyAdvantageFields.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center gap-2">
        <Inbox className="stroke-color-stroke-primary h-8 w-8" />
        <p>
          You need to add a ComplyAdvantage node before configuring this node.
        </p>
      </div>
    );
  }

  const adverseMediaField = complyAdvantageFields.find(
    (field) => field.name === 'has_adverse_media'
  );

  if (!adverseMediaField) {
    return (
      <div className="flex flex-col items-center justify-center gap-2">
        <Inbox className="stroke-color-stroke-primary h-8 w-8" />
        <p>You don't have any check for adverse media.</p>
      </div>
    );
  }

  return (
    <FieldItem
      isPendingUpdate={isPendingUpdate}
      field={adverseMediaField}
      value={value}
      onAuthorityChange={onAuthorityChange}
    />
  );

  // TODO: This is for when we support different settings based on process/node
  // const defaultOpen = Object.keys(fields)[0];

  // return (
  //   <Accordion
  //     className="flex flex-col gap-2"
  //     defaultValue={[defaultOpen]}
  //     type="multiple"
  //   >
  //     {Object.entries(fields).map(([processId, fields]) => (
  //       <ProcessConfigItem
  //         key={processId}
  //         processId={processId}
  //         fields={fields}
  //         onAuthorityChange={onAuthorityChange}
  //       />
  //     ))}
  //   </Accordion>
  // );
};
