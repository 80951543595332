import { Skeleton } from '@spektr/client/components';

export const SkeletonCustomerDetailsHeader = () => {
  return (
    <div className="text-color-text-subtext flex items-center gap-1.5 text-sm">
      <Skeleton className="w-14" />
      <span>/</span>
      <Skeleton className="w-20" />
      <span>/</span>
      <Skeleton className="w-20" />
    </div>
  );
};
