import {
  SectionList,
  SectionWithTitle,
  ServiceItemWithFrequency,
} from '@spektr/model-builder/components';

import { type ServiceFieldClient } from '@spektr/client/types';
import {
  type SpektrFieldKey,
  type TriggerFrequency,
} from '@spektr/shared/types';

import { useTriggerFrequencies } from '../../../../hooks/useTriggerFrequencies';

export namespace FieldsWithFrequencyList {
  export type Props = {
    fields: ServiceFieldClient[];
    onUpdateFrequency: (
      fieldKey: SpektrFieldKey,
      frequency: TriggerFrequency | undefined
    ) => void;
  };
}

export const FieldsWithFrequencyList = ({
  fields,
  onUpdateFrequency,
}: FieldsWithFrequencyList.Props) => {
  const { updateFrequency, getTriggerFrequency } = useTriggerFrequencies();

  const handleUpdateFrequency = (
    fieldKey: SpektrFieldKey,
    frequency: TriggerFrequency | undefined
  ) => {
    updateFrequency(fieldKey, frequency);
    onUpdateFrequency(fieldKey, frequency);
  };

  return (
    <SectionWithTitle title="Select categories and frequency">
      {fields.length > 0 ? (
        <SectionList data={fields}>
          {(field) => (
            <ServiceItemWithFrequency
              key={field.key}
              field={field}
              frequency={getTriggerFrequency(field.key)}
              onChangeFrequency={handleUpdateFrequency}
            />
          )}
        </SectionList>
      ) : (
        <span className="text-color-text-primary">No dataset fields</span>
      )}
    </SectionWithTitle>
  );
};
