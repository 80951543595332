import { ReactNode, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getDomainSettingsQuery } from '@spektr/client/services';
import { getDomainAliasFormUrl } from '@spektr/shared/utils';

import { PublicDomainContext } from './context';

export namespace PublicDomain {
  export type Props = {
    children: ReactNode;
    publicPageUrl: string;
  };
}

export const PublicDomainProvider = ({
  children,
  publicPageUrl,
}: PublicDomain.Props) => {
  const { data } = useQuery(getDomainSettingsQuery(false));

  let publicDomain = publicPageUrl;

  if (data?.domainName && data?.status === 'active') {
    publicDomain = data.domainName;
  }

  const api = useMemo(() => {
    return {
      publicDomain,
      getSkyhubUrl: () => getDomainAliasFormUrl(publicDomain).toString(),
    };
  }, [publicDomain]);

  return (
    <PublicDomainContext.Provider value={api}>
      {children}
    </PublicDomainContext.Provider>
  );
};
