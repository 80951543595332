import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import {
  MappingApiClient,
  IngestApiClient,
  InsightsApiClient,
  ProcessApiClient,
  UsersApiClient,
} from '@spektr/client/services';

import App from './app/app';

import {
  CLIENT_ID,
  INGEST_API_URL,
  INSIGHTS_API_URL,
  MAPPING_API_URL,
  PROCESS_API_URL,
  USER_API_URL,
} from './app/config/network';

import './app/services/datadog';

ProcessApiClient.setConfig(PROCESS_API_URL, CLIENT_ID);
MappingApiClient.setConfig(MAPPING_API_URL, CLIENT_ID);
IngestApiClient.setConfig(INGEST_API_URL, CLIENT_ID);
UsersApiClient.setConfig(USER_API_URL, CLIENT_ID);
InsightsApiClient.setConfig(INSIGHTS_API_URL, CLIENT_ID);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
