import { ColumnsDropdown } from './ColumnsDropdown';
import { DatasetFilters } from './DatasetFilters';

export const TableToolbar = () => {
  return (
    <div className="mb-4 flex items-center gap-4">
      <DatasetFilters />
      <ColumnsDropdown />
    </div>
  );
};
