import { HTMLAttributes } from 'react';

export const KyckrMinimalIcon = (props: HTMLAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      fill="none"
      viewBox="0 0 20 18"
      {...props}
    >
      <path
        fill="#0CF191"
        d="M13.39 17.02l-3.974-4.21h5.255l3.981 4.21h-5.261z"
      ></path>
      <path
        fill="#022A37"
        d="M13.83 0L3.755 10.79l.013-3.956L.02 2.875 0 14.588a2.264 2.264 0 001.422 2.124c.266.106.543.16.825.16.609 0 1.2-.259 1.622-.71L19.015 0h-5.186z"
      ></path>
    </svg>
  );
};
