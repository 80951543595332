import { useMemo } from 'react';
import { Unplug } from 'lucide-react';

import { Process } from '@spektr/shared/types';

import { DropdownOption } from '@spektr/client/components';

import { ProcessIcon } from '../components/ProcessIcon';

const OUTCOME_ACTIONS: DropdownOption[] = [
  {
    label: 'Actions',
    type: 'label',
    value: 'actions-label',
  },
  {
    label: 'Connect a Process',
    type: 'item',
    value: 'connect',
    icon: <Unplug className="h-3.5 w-3.5" />,
  },
  {
    label: 'Create a Risk Process',
    type: 'item',
    value: 'create-risk',
    icon: <ProcessIcon processType="risk" />,
  },
  {
    label: 'Create a Monitoring Process',
    type: 'item',
    value: 'create-monitoring',
    icon: <ProcessIcon processType="monitoring" />,
  },
  {
    label: 'Create an Onboarding Process',
    type: 'item',
    value: 'create-onboarding',
    icon: <ProcessIcon processType="onboarding" />,
  },
  {
    label: 'Create a Score Process',
    type: 'item',
    value: 'create-score',
    icon: <ProcessIcon processType="score" />,
  },
  {
    label: 'Create a Loop',
    type: 'item',
    value: 'create-loop',
    icon: <ProcessIcon processType="loop" />,
  },
];

const actionsNotAllowedOnLinkedOutcome = [
  'create-risk',
  'create-monitoring',
  'create-onboarding',
  'create-score',
];

export const useGetAllowedProcessActions = (
  linkedProcesses: Process[],
  isOnboardingProcess = false
) => {
  const allActions = OUTCOME_ACTIONS;

  const actions: DropdownOption[] = useMemo(() => {
    const actionsBasedOnProcessType = isOnboardingProcess
      ? allActions
      : allActions.filter((action) => action.value !== 'create-onboarding');

    if (linkedProcesses.length === 0) {
      return actionsBasedOnProcessType;
    }

    const processActions = linkedProcesses.map((process) => ({
      label: process.name,
      type: 'item',
      value: process.id,
      icon: <ProcessIcon processType={process.type} />,
    }));
    const processOptions = [
      {
        type: 'separator',
        value: 'separator',
      },
      {
        label: 'Linked Processes',
        type: 'label',
        value: 'linked-processes-label',
      },
      ...processActions,
    ];

    const areAllProcessesLoops = linkedProcesses.every(
      (process) => process.type === 'loop'
    );

    if (areAllProcessesLoops) {
      return [
        ...actionsBasedOnProcessType,
        ...processOptions,
      ] as DropdownOption[];
    }

    const allowedActions = actionsBasedOnProcessType.filter(
      (action) => !actionsNotAllowedOnLinkedOutcome.includes(action.value)
    );

    return [...allowedActions, ...processOptions] as DropdownOption[];
  }, [allActions, linkedProcesses, isOnboardingProcess]);

  return actions;
};
