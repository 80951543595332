import { Route, Settings } from 'lucide-react';

import { cn } from '@spektr/style-utils';
import { SETTINGS_URL, STRATEGY_PAGE_URL } from '@spektr/shared/utils';

import { AppNavItem } from '@spektr/client/types';

import { Logo } from '../Icons';

import { ThemeToggle } from '../ThemeToggle';

import { AppNavLink } from './AppNavLink';

export type AppNavProps = {
  items: AppNavItem[];
};

export const AppNav = ({ items }: AppNavProps) => {
  return (
    <aside
      className={cn(
        'border-r',
        'grow-1 w-navbar flex shrink-0 flex-col',
        'text-color-text-primary',
        'transition',
        'dark:bg-color-bg-primary bg-color-bg-white'
      )}
    >
      <div
        className={cn('h-header', 'flex shrink-0 items-center justify-center')}
      >
        <Logo className="h-[28px] w-[26px]" />
      </div>
      <nav
        aria-label="Main navigation"
        className={cn('grow-1 flex flex-col gap-4', 'h-full px-4 pb-5 pt-1')}
      >
        {items.map((item) => (
          <AppNavLink key={item.link} isCollapsed={true} {...item} />
        ))}

        <div className="flex flex-col gap-4">
          <AppNavLink
            key={STRATEGY_PAGE_URL}
            isCollapsed={true}
            label="Strategy"
            icon={<Route className="h-4 w-4" />}
            link={STRATEGY_PAGE_URL}
            color="strategy"
          />
          <AppNavLink
            key={SETTINGS_URL}
            isCollapsed={true}
            label="Settings"
            icon={<Settings className="h-4 w-4" />}
            link={SETTINGS_URL}
            color="settings"
          />
        </div>

        <ThemeToggle className="-ml-2 mb-5 mt-auto -rotate-90" />
      </nav>
    </aside>
  );
};
