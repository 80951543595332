import {
  AlertResolvedEventData,
  ClientRecordStatusChangeEventData,
  ClientRecordTagAddEventData,
  ClientRecordTagRemoveEventData,
  ProcessRunEventData,
  TimelineEvent,
  UserSchema,
} from '@spektr/shared/validators';
import { Process } from '@spektr/shared/types';

import { CustomerStatusChangeEvent } from './CustomerStatusChangeEvent';
import { ProcessRunEvent } from './ProcessRunEvent';
import { AlertResolvedEvent } from './AlertResolvedEvent';
import { TagAddEvent } from './TagAddEvent';
import { AlertEscalatedEvent } from './AlertEscalatedEvent';
import { TagRemoveEvent } from './TagRemoveEvent';

type TimelineEventFactoryProps = {
  event: TimelineEvent;
  users: UserSchema[];
  processes: Process[];
};

export const TimelineEventFactory = ({
  event,
  users,
  processes,
}: TimelineEventFactoryProps) => {
  if (event.type === 'process_run') {
    return <ProcessRunEvent event={event as ProcessRunEventData} />;
  }

  if (event.type === 'customer_tag_add') {
    return (
      <TagAddEvent
        event={event as ClientRecordTagAddEventData}
        users={users}
        processes={processes}
      />
    );
  }

  if (event.type === 'customer_tag_remove') {
    return (
      <TagRemoveEvent
        event={event as ClientRecordTagRemoveEventData}
        users={users}
        processes={processes}
      />
    );
  }

  if (event.type === 'alert_resolved') {
    return (
      <AlertResolvedEvent
        event={event as AlertResolvedEventData}
        users={users}
      />
    );
  }

  if (event.type === 'alert_escalated') {
    return <AlertEscalatedEvent event={event as any} users={users} />;
  }

  if (event.type === 'new_document_added') {
    return null;
  }

  if (event.type === 'new_owner_created') {
    return null;
  }

  if (event.type === 'new_controller_created') {
    return null;
  }

  if (event.type === 'indicator_change') {
    return null;
  }

  if (event.type === 'customer_status_change') {
    return (
      <CustomerStatusChangeEvent
        event={event as ClientRecordStatusChangeEventData}
        users={users}
        processes={processes}
      />
    );
  }

  return null;
};
