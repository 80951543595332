import { Suspense } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  CASES_ALERTS_FULL_URL,
  casesCustomerAlertsUrl,
} from '@spektr/shared/utils';
import { useDialogClose } from '@spektr/shared/hooks';
import { ErrorPage } from '@spektr/shared/components';

import {
  Dialog,
  DialogContent,
  SpektrErrorBoundary,
} from '@spektr/client/components';
import { handleAxiosError } from '@spektr/client/utils';

import { AlertDetailsProvider } from './providers';
import { useAlertIdParam } from './hooks';

import { AlertContent } from './containers/AlertContent';
import { SkeletonAlertDetails } from './components/SkeletonAlertDetails';

export const AlertDetails = () => {
  const { spektrId } = useParams();
  const [open, startExitAnimation] = useDialogClose();
  const navigate = useNavigate();
  const alertId = useAlertIdParam();
  const [_, setSearchParams] = useSearchParams();

  const handleClose = () => {
    startExitAnimation();
    setSearchParams({});
    navigate(
      spektrId ? casesCustomerAlertsUrl(spektrId) : CASES_ALERTS_FULL_URL
    );
  };

  return (
    <Dialog open={open}>
      <DialogContent
        className="absolute flex max-h-[90%] max-w-[1065px] flex-col"
        requestStartExitAnimation={handleClose}
        onEndExitAnimation={handleClose}
        onEscapeKeyDown={handleClose}
      >
        <SpektrErrorBoundary
          fallbackRender={({ error }) => (
            <ErrorPage
              title={handleAxiosError(
                error,
                'An error has occurred while retrieving the alert!'
              )}
            />
          )}
        >
          <Suspense fallback={<SkeletonAlertDetails />}>
            <AlertDetailsProvider
              alertId={alertId}
              context={spektrId ? 'customer' : 'global'}
            >
              <AlertContent onClose={handleClose} />
            </AlertDetailsProvider>
          </Suspense>
        </SpektrErrorBoundary>
      </DialogContent>
    </Dialog>
  );
};
