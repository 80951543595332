import { useNavigate } from 'react-router-dom';
import { Rocket, Route } from 'lucide-react';

import {
  processDetailsParamsSchema,
  loopScheduleUrl,
  loopStrategyFullUrl,
  loopFieldMappingFullUrl,
} from '@spektr/shared/utils';
import { useParsedParams } from '@spektr/shared/hooks';

import {
  Button,
  Skeleton,
  SpektrErrorBoundary,
} from '@spektr/client/components';

import {
  ProcessDemoButton,
  FieldMappingButtonWithBadge,
  FieldMappingButton,
} from '@spektr/model-builder/containers';
import { Suspense } from 'react';
import { handleAxiosError } from '@spektr/client/utils';

export const LoopActionsHeader = () => {
  const { processId } = useParsedParams(processDetailsParamsSchema);
  const navigate = useNavigate();

  const handleClickSchedule = () => {
    navigate(loopScheduleUrl(processId));
  };

  return (
    <div className="flex items-center gap-4">
      <ProcessDemoButton processId={processId} />
      <Button
        variant="text"
        size="sm"
        className="gap-2"
        onClick={() => navigate(loopStrategyFullUrl(processId))}
      >
        <Route className="h-4 w-4" />
        <span>Strategy</span>
      </Button>
      <SpektrErrorBoundary
        fallbackRender={(error) => (
          <FieldMappingButton
            disabled={true}
            disabledMessage={handleAxiosError(
              error,
              'Something went wrong when loading the custom fields.'
            )}
          />
        )}
      >
        <Suspense
          fallback={
            <div className="flex flex-grow items-center gap-2">
              <Skeleton className="h-4 w-4" />
              <Skeleton className="h-4 w-24" />
            </div>
          }
        >
          <FieldMappingButtonWithBadge
            processId={processId}
            destination={loopFieldMappingFullUrl(processId)}
          />
        </Suspense>
      </SpektrErrorBoundary>
      <Button
        startIcon={<Rocket className="h-4 w-4" />}
        color="red"
        onClick={handleClickSchedule}
      >
        Launch loop
      </Button>
    </div>
  );
};
