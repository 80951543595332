import { cn } from '@spektr/style-utils';

type FixedToolbarProps = {
  children: React.ReactNode;
};

export const FixedToolbar = ({ children }: FixedToolbarProps) => {
  return (
    <div
      className={cn(
        'mb-2 flex w-full items-center gap-1 border-b pb-1',
        'border-color-border-input'
      )}
    >
      {children}
    </div>
  );
};
