import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import * as Accordion from '@radix-ui/react-accordion';

import { cn } from '@spektr/style-utils';

export type AccordionItemProps = ComponentPropsWithoutRef<
  typeof Accordion.Item
>;

export const AccordionItem = forwardRef<
  ElementRef<typeof Accordion.Item>,
  AccordionItemProps
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Item
    className={cn(
      'overflow-hidden',
      'first:mt-0 first:rounded-t',
      'last:rounded-b',
      'focus-within:relative focus-within:z-10',
      className
    )}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </Accordion.Item>
));
