import { useContext, useMemo } from 'react';

import { getExistingSource } from '@spektr/shared/utils';

import { areChannelSettingsRequired } from '../utils/areChannelSettingsRequired';

import { ProcessScheduleContext } from './ProcessScheduleContext';

export const useProcessScheduleContext = () => {
  const {
    process,
    triggers,
    startDate,
    setStartDate,
    updateProcessTrigger,
    canRunNow,
    hasLiveVersion,
  } = useContext(ProcessScheduleContext);

  return useMemo(
    () => ({
      process,
      trigger: triggers[0],
      hasSource: process && !!getExistingSource(process),
      isRiskProcess:
        process && (process.type === 'risk' || process.type === 'score'),
      isLoopProcess: process && process.type === 'loop',
      isOnboardingProcess: process && process.type === 'onboarding',
      isMonitoringProcess: process && process.type === 'monitoring',
      areChannelSettingsRequired: areChannelSettingsRequired(process),
      startDate,
      setStartDate,
      updateProcessTrigger,
      canRunNow,
      hasLiveVersion,
    }),
    [
      canRunNow,
      hasLiveVersion,
      process,
      setStartDate,
      startDate,
      triggers,
      updateProcessTrigger,
    ]
  );
};
