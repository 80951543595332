import { icons } from 'lucide-react';

import { TextTruncate } from '@spektr/client/components';

type CustomerInfoRowProps = {
  iconName: keyof typeof icons;
  label: string;
};

export const CustomerInfoRow = ({ iconName, label }: CustomerInfoRowProps) => {
  const Icon = icons[iconName];
  return (
    <div className="flex items-center gap-2">
      <Icon className="text-color-text-subtext h-3 w-3" />
      <TextTruncate className="line-clamp-1 text-sm font-medium" text={label} />
    </div>
  );
};
