import { Skeleton } from '@spektr/client/components';

import { SkeletonCustomerOwnershipRow } from './SkeletonCustomerOwnershipRow';

export const SkeletonCustomerOwnershipList = () => {
  return (
    <div className="flex flex-col gap-2">
      <Skeleton className="w-28" />
      {new Array(4).fill(null).map((_, index) => (
        <SkeletonCustomerOwnershipRow key={index} />
      ))}
    </div>
  );
};
