import { Banner, SpektrErrorBoundary } from '@spektr/client/components';
import axios from 'axios';
import { Suspense } from 'react';

import { SkeletonCommentsList } from '../../components';
import { CustomerCommentsList } from '../containers/CustomerCommentsList';

export const CustomerComments = () => {
  return (
    <SpektrErrorBoundary
      fallbackRender={({ error }) => {
        let errorMsg = 'An error occurred while retrieving the comments.';
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            errorMsg = 'There are no comments at the moment.';
          } else if (error.message) {
            errorMsg = error.message;
          }
        }

        return (
          <Banner type="error" iconName="TriangleAlert" message={errorMsg} />
        );
      }}
    >
      <Suspense fallback={<SkeletonCommentsList size={4} />}>
        <CustomerCommentsList />
      </Suspense>
    </SpektrErrorBoundary>
  );
};
