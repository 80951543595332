import { Clock } from 'lucide-react';

import { Label } from '@spektr/client/components';

import { format } from 'date-fns';

import { useProcessScheduleContext } from '../../providers';

export const NextRunBanner = () => {
  const { isMonitoringProcess, trigger, hasLiveVersion } =
    useProcessScheduleContext();

  if (!hasLiveVersion || !isMonitoringProcess || !trigger) {
    return null;
  }

  return (
    <div className="mb-3.5 flex items-center gap-6 rounded-md border px-7 py-5">
      <Clock className="h-6 w-6" />
      <div className="flex flex-col gap-1">
        <Label className="text-sm">Next run</Label>
        <p className="text-sm font-medium">
          {format(trigger.nextTriggerTimestamp, 'do MMMM yyyy, HH:mm')}
        </p>
      </div>
    </div>
  );
};
