import { SpektrField } from '../../goldenRecord';
import { commonSpektrFields } from '../commonSpektrFields/commonSpektrFields';

//TODO: label and help are just here for backwards compatibility, they should be removed when the label and help are moved to a localization file and FE retrieves them by the field key
export const openCorporatesRiskSpektrFields: Array<
  Omit<SpektrField, 'sourceId'>
> = [
  {
    key: 'company_name',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Company name',
    commonSpektrFieldKeys: [commonSpektrFields.companyName.key],
    group: 'company',
  },
  {
    key: 'company_number',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Company number',
    commonSpektrFieldKeys: [commonSpektrFields.companyNumber.key],
    group: 'company',
  },
  {
    key: 'jurisdiction_code',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Company jurisdiction code',
    commonSpektrFieldKeys: [commonSpektrFields.jurisdictionCode.key],
    group: 'company',
  },
  {
    key: 'incorporation_date',
    type: 'date',
    source: 'openCorporatesRisk',
    label: 'Company incorporation date',
    commonSpektrFieldKeys: [commonSpektrFields.incorporationDate.key],
    group: 'company',
  },
  {
    key: 'dissolution_date',
    type: 'date',
    source: 'openCorporatesRisk',
    label: 'Company dissolution date',
    commonSpektrFieldKeys: [commonSpektrFields.dissolutionDate.key],
    group: 'company',
  },
  {
    key: 'company_type',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Company type',
    commonSpektrFieldKeys: [commonSpektrFields.companyType.key],
    group: 'company',
  },
  {
    key: 'current_status',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Current status',
    commonSpektrFieldKeys: [commonSpektrFields.companyStatus.key],
    group: 'company',
  },

  //Address
  {
    key: 'registered_address.street_address',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Address street',
    commonSpektrFieldKeys: [commonSpektrFields.companyStreetName.key],
    group: 'address',
  },
  {
    key: 'registered_address.locality',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Address locality',
    commonSpektrFieldKeys: [commonSpektrFields.companyCity.key],
    group: 'address',
  },
  {
    key: 'registered_address.region',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Address region',
    commonSpektrFieldKeys: [commonSpektrFields.companyRegion.key],
    group: 'address',
  },
  {
    key: 'registered_address.postal_code',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Address postcode',
    commonSpektrFieldKeys: [commonSpektrFields.companyZipCode.key],
    group: 'address',
  },
  {
    key: 'registered_address.country',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Address country',
    commonSpektrFieldKeys: [commonSpektrFields.country.key],
    group: 'address',
  },
  {
    key: 'registered_address_in_full',
    type: 'string',
    source: 'openCorporatesRisk',
    label: 'Complete address',
    commonSpektrFieldKeys: [commonSpektrFields.companyFullAddress.key],
    group: 'address',
  },
  {
    key: 'openCorporatesRisk_service_failure',
    type: 'boolean',
    source: 'openCorporatesRisk',
    label: 'Open corporates risk service call failed',
  },
];
