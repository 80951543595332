import { ClientRecordTagModel } from '@spektr/shared/validators';

import { cn } from '@spektr/style-utils';
import { HoverCard, Tag, TagInfo } from '@spektr/client/components';

export type TagWithHoverCardProps = {
  tag: ClientRecordTagModel;
  className?: string;
  cardClassName?: string;
};

export const TagWithHoverCard = ({
  tag,
  className,
  cardClassName,
}: TagWithHoverCardProps) => {
  return (
    <HoverCard
      className={cn(
        'min-w-[281px] p-4',
        'flex flex-col gap-3',
        'border-color-border-secondary/40 rounded-md border dark:border-transparent',
        'bg-color-bg-tooltip',
        'cursor-default',
        cardClassName
      )}
      side="top"
      trigger={
        <div className="flex cursor-pointer items-center">
          <Tag
            isDeleted={tag.isDeleted}
            className={className}
            color={tag.color}
            label={tag.label}
            showTooltip={false}
          />
        </div>
      }
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={cn(
          'flex items-center',
          'bg-color-bg-tooltip-content p-4',
          'border-color-border-default/60 rounded-md'
        )}
      >
        <Tag
          isDeleted={tag.isDeleted}
          className={className}
          color={tag.color}
          label={tag.label}
        />
      </div>

      <TagInfo
        createdAt={tag.createdAt}
        updatedAt={tag.updatedAt}
        sourceProcessId={tag.sourceProcessId}
        sourceProcessName={tag.sourceProcessName}
      />
    </HoverCard>
  );
};
