import { cn } from '@spektr/style-utils';
import { CloudUpload } from 'lucide-react';

export namespace Content {
  export type Props = {
    className?: string;
    text: string;
  };
}

export const Content = ({ className, text }: Content.Props) => (
  <div
    className={cn(
      'flex flex-col items-center justify-center gap-2',
      'max-w-[144px]',
      'text-color-text-form-viewer-paragraph text-center text-xs',
      'transition-all',
      className
    )}
  >
    <CloudUpload className="h-4 w-4" />
    <span>{text}</span>
  </div>
);
