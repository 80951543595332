import { ListX } from 'lucide-react';

import { cn } from '@spektr/style-utils';

export const ErrorList = () => (
  <div
    className={cn(
      'flex grow flex-col items-center justify-center gap-4 py-8',
      'h-full',
      'rounded-md border',
      'text-color-red'
    )}
  >
    <ListX className="h-8 w-8" />
    <span className="font-light">We could not complete this request!</span>
  </div>
);
