import { useMemo } from 'react';
import { RefreshCw, LayoutGrid, FolderClock } from 'lucide-react';

import {
  CASES_URL,
  CONNECTION_HUB_URL,
  DASHBOARD_URL,
  LOOPS_URL,
  PROCESS_DASHBOARD_URL,
} from '@spektr/shared/utils';

import { SpektrDatasetIcon, SpektrHomeIcon } from '@spektr/client/components';

import { AppNavItem } from '@spektr/client/types';

export const useGetNavigationItems = () => {
  return useMemo(
    () =>
      [
        {
          id: 'homepage',
          label: 'Home',
          icon: <SpektrHomeIcon className="h-4 w-4" />,
          link: DASHBOARD_URL,
          color: 'home',
          released: true,
        },
        {
          id: 'connection-hub',
          label: 'Connection Hub',
          icon: <SpektrDatasetIcon />,
          link: CONNECTION_HUB_URL,
          color: 'connectionHub',
          released: true,
        },
        {
          id: 'processes',
          label: 'Processes',
          icon: <LayoutGrid className="h-4 w-4" />,
          link: PROCESS_DASHBOARD_URL,
          color: 'processes',
          released: true,
        },
        {
          id: 'loops',
          label: 'Loops',
          icon: <RefreshCw className="h-4 w-4" />,
          link: LOOPS_URL,
          color: 'loops',
          released: true,
        },
        {
          id: 'cases',
          label: 'Cases',
          icon: <FolderClock className="h-4 w-4" />,
          link: CASES_URL,
          color: 'cases',
          released: true,
        },
      ] as AppNavItem[],
    []
  );
};
