import { createContext, ReactNode, useContext, useState } from 'react';
import { produce } from 'immer';
import { v4 as uuid } from 'uuid';

import { ServiceFieldClient } from '@spektr/client/types';

import { ServiceNodeMonitor } from '@spektr/shared/validators';
import { SpektrField } from '@spektr/shared/types';

import { getMonitorErrors } from '../../utils/getMonitorErrors';
import { ServiceMonitor } from '../../types/ServiceMonitor';

type MonitorsContextApi = {
  monitors: ServiceNodeMonitor[];
  spektrFields: SpektrField[];
  serviceFields: ServiceFieldClient[];
  areMonitorsValid: boolean;
  updateMonitor: (id: string, left: string, right?: string) => void;
  updateDecision: (
    id: string,
    decision: 'accept' | 'escalate' | 'ignore',
    assignee?: string
  ) => void;
  createMonitor: () => void;
  removeMonitor: (id: string) => void;
};

export const MonitorsContext = createContext<MonitorsContextApi>({
  monitors: [],
  spektrFields: [],
  serviceFields: [],
  areMonitorsValid: true,
  updateMonitor: () => null,
  createMonitor: () => null,
  removeMonitor: () => null,
  updateDecision: () => null,
});

export const useMonitorsContext = () => useContext(MonitorsContext);

type MonitorsProviderProps = {
  node: any;
  spektrFields: SpektrField[];
  serviceFields: ServiceFieldClient[];
  children: ReactNode;
};

export const MonitorsProvider = ({
  node,
  spektrFields,
  serviceFields,
  children,
}: MonitorsProviderProps) => {
  const defaultMonitors = node.monitors ?? [];
  const [monitors, setMonitors] = useState<ServiceMonitor[]>(defaultMonitors);

  const areAllMonitorsValid = monitors.every((monitor) => {
    return monitor.rule.rule.left && monitor.rule.rule.right && !monitor.error;
  });

  const updateMonitor = (id: string, left: string, right?: string) => {
    setMonitors(
      produce((draft) => {
        const index = draft.findIndex((monitor) => monitor.rule.id === id);
        if (index !== -1) {
          draft[index].rule.title = left;
          draft[index].rule.rule.left = left;

          if (right !== undefined) {
            draft[index].rule.rule.right = right;
          }

          draft[index].error = getMonitorErrors(
            draft[index],
            draft,
            spektrFields
          );
        }
      })
    );
  };

  const updateDecision = (
    id: string,
    decision: 'accept' | 'escalate' | 'ignore',
    assignee?: string
  ) => {
    setMonitors(
      produce((draft) => {
        const index = draft.findIndex((monitor) => monitor.rule.id === id);
        if (index !== -1) {
          draft[index].decision.type = decision;

          if (draft[index].decision.type === 'escalate') {
            draft[index].decision.assigneeId = assignee ?? '';
          }
        }
      })
    );
  };

  const createMonitor = () => {
    setMonitors(
      produce((draft) => {
        draft.push({
          rule: {
            id: uuid(),
            title: '',
            rule: {
              left: '',
              right: false,
              operator: 'not_equals',
              type: 'string',
            },
          },
          decision: {
            type: 'ignore',
          },
        });
      })
    );
  };

  const removeMonitor = (id: string) => {
    setMonitors(
      produce((draft) => {
        const index = draft.findIndex((monitor) => monitor.rule.id === id);
        if (index !== -1) {
          draft.splice(index, 1);
        }
      })
    );
  };

  return (
    <MonitorsContext.Provider
      value={{
        monitors,
        spektrFields,
        serviceFields,
        areMonitorsValid: areAllMonitorsValid,
        updateMonitor,
        updateDecision,
        createMonitor,
        removeMonitor,
      }}
    >
      {children}
    </MonitorsContext.Provider>
  );
};
