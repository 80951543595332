export function getRootFields<T extends { key: string }>(fields: T[]): T[] {
  //"main" spektrFields (e.g has_sanctions) are visible and available for the user tu select but their subfields (e.g. has_sanctions_fields.*) are not
  return fields.filter((field) => {
    const fieldKeys = field.key.split('_fields.');

    if (fieldKeys.length > 1) {
      return !fields.some((parentField) => parentField.key === fieldKeys[0]);
    }

    return true;
  });
}
