import { CircleX, FileUp, icons, X } from 'lucide-react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import { formatBytes } from '@spektr/client/utils';
import { cn } from '@spektr/style-utils';

import { iconBoxVariants } from '@spektr/client/components';
import { cva, VariantProps } from 'class-variance-authority';

export type FileUploaderProps = VariantProps<typeof fileUploaderVariants> &
  DropzoneOptions & {
    iconName?: keyof typeof icons;
    className?: string;
    currentFile?: File;
    onRemove: () => void;
  };

export const FileUploader = ({
  variant,
  iconName = 'CircleArrowUp',
  className,
  currentFile,
  onRemove,
  ...dropZoneProps
}: FileUploaderProps) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    ...dropZoneProps,
    maxFiles: 1,
  });

  const Icon = icons[iconName];

  if (currentFile) {
    return (
      <div
        className={cn(
          'rounded-md',
          'flex flex-row items-start p-4',
          fileUploaderVariants({ acceptedFileBackground: variant })
        )}
      >
        <FileUp
          className={cn(
            'mr-3 h-10 w-10 min-w-10',
            iconBoxVariants({ variant: variant })
          )}
        />
        <div className={cn('flex h-full flex-col justify-center')}>
          <span className={cn('text-color-text-primary text-sm')}>
            {currentFile.name}
          </span>
          <span className="text-color-text-error-boundry text-xs">
            {formatBytes(currentFile.size)}
          </span>
        </div>
        <X
          className={cn(
            'text-color-text-icon-primary',
            'ml-auto h-5 w-5 min-w-5',
            'cursor-pointer'
          )}
          onClick={onRemove}
        />
      </div>
    );
  }

  if (isDragReject) {
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          className={cn(
            'border-color-border-file rounded border border-dashed',
            'flex h-28 w-full flex-col items-center justify-center',
            'cursor-pointer',
            className
          )}
        >
          <CircleX className="stroke-spektr-red mb-1 h-9 w-9" />
          <span className="text-color-text-error-boundry text-sm">
            We cannot accept this file!
          </span>
        </div>
      </div>
    );
  }

  if (isDragActive && isDragAccept) {
    return (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          className={cn(
            'border-color-border-file rounded border border-dashed',
            'flex h-28 w-full flex-col items-center justify-center',
            'cursor-pointer',
            className
          )}
        >
          <Icon
            className={cn('mb-1 h-9 w-9', fileUploaderVariants({ variant }))}
          />
          <span className="text-color-text-error-boundry text-sm">
            Almost there, drop it here!
          </span>
        </div>
      </div>
    );
  }

  return (
    <div {...getRootProps()} className="ontline-none">
      <input {...getInputProps()} />
      <div
        className={cn(
          'border-color-border-file rounded border border-dashed',
          'flex h-28 w-full flex-col items-center justify-center',
          'cursor-pointer',
          className
        )}
      >
        <Icon
          className={cn('mb-1 h-9 w-9', fileUploaderVariants({ variant }))}
        />
        <span className="text-color-text-error-boundry text-sm">
          Drop your file here, or{' '}
          <span className={fileUploaderVariants({ variant })}>Browse</span>
        </span>
      </div>
    </div>
  );
};

const fileUploaderVariants = cva('', {
  variants: {
    variant: {
      yellow: 'stroke-2 stroke-color-yellow text-color-yellow',
      cyan: 'stroke-1 stroke-color-black text-color-black',
    },
    acceptedFileBackground: {
      yellow: 'bg-color-yellow/20',
      cyan: 'bg-color-cyan/20',
    },
    defaultVariants: {
      variant: 'yellow',
      accentBackgroundColor: 'yellow',
      dropZone: 'yellow',
    },
  },
});
