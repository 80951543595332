import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDistanceToNow } from 'date-fns';

import { cn } from '@spektr/style-utils';
import { ProcessIcon } from '@spektr/client/components';

import { loopDetailsUrl, processBuilderUrl } from '@spektr/shared/utils';

import { Indicator } from '../types';
import { getIndicatorStateIcon, indicatorColorVariants } from '../constants';

type CustomerIndicatorDetailsProps = {
  indicator: Indicator;
};

export const CustomerIndicatorDetails = ({
  indicator,
}: CustomerIndicatorDetailsProps) => {
  const { t } = useTranslation('cases');
  const daysPassed = indicator.createdAt
    ? formatDistanceToNow(indicator.createdAt, { addSuffix: true })
    : undefined;

  return (
    <div
      className={cn(
        'flex w-72 flex-col gap-3',
        'p-4',
        'rounded-md',
        'text-sm',
        'bg-color-bg-dialog-default'
      )}
    >
      <div
        className={cn(
          'flex items-center gap-1.5',
          'px-4 py-3.5',
          'rounded-md',
          indicatorColorVariants({
            state: indicator.state,
          })
        )}
      >
        {indicator.state && getIndicatorStateIcon(indicator.state)}
        <span>{indicator.label}</span>
        {indicator.type === 'score' && (
          <p>
            {indicator.value !== undefined
              ? parseFloat(indicator.value.toFixed(2))
              : '- N/A'}
          </p>
        )}
      </div>
      <p className="text-color-text-subtext">
        {daysPassed !== undefined
          ? daysPassed
          : t(`customers.indicators.unknown_${indicator.type}_message`, {
              indicator: indicator.label,
            })}
      </p>
      {indicator.processName &&
        indicator.processType &&
        indicator.processId && (
          <Link
            to={
              indicator.processType === 'loop'
                ? loopDetailsUrl(indicator.processId)
                : processBuilderUrl(indicator.processId)
            }
            className="flex items-center gap-1.5"
          >
            <ProcessIcon boxIcon className="h-5 w-5" processType={undefined} />
            <span>{indicator.processName}</span>
          </Link>
        )}
    </div>
  );
};
