import { useCallback } from 'react';
import _isEqual from 'lodash/isEqual';

import { useTriggersOperations } from '@spektr/platform-hooks';

import { type ClientTrigger } from '@spektr/client/types';

type HandleSaveChangesFn = (nextTriggers: ClientTrigger[]) => Promise<void>;

export function useUpdateTriggers(triggers: ClientTrigger[]) {
  const { createTrigger, updateTrigger, deleteTrigger } =
    useTriggersOperations();

  return useCallback<HandleSaveChangesFn>(
    async (nextTriggers) => {
      const changedTriggers = nextTriggers.filter((trigger) => {
        if (!trigger.id) {
          return true;
        }

        const currentTrigger = triggers?.find(
          (currentTrigger) => currentTrigger.id === trigger.id
        );

        if (!currentTrigger) {
          return true;
        }

        return (
          currentTrigger.frequency !== trigger.frequency ||
          !_isEqual(currentTrigger.fields, trigger.fields)
        );
      });

      const deleteTriggers = changedTriggers.filter(
        (trigger) => trigger.id && !trigger.fields?.length
      );

      const updateTriggers = changedTriggers.filter(
        (trigger) =>
          trigger.id &&
          trigger.fields?.length &&
          !deleteTriggers.find(
            (deleteTrigger) => deleteTrigger.id === trigger.id
          )
      );
      const createTriggers = changedTriggers.filter(
        (trigger) => !trigger.id && trigger.fields?.length
      );

      const promiseQueue = [
        ...deleteTriggers.map((trigger) => deleteTrigger(trigger.id as string)),
        ...updateTriggers.map((trigger) =>
          updateTrigger({
            triggerId: trigger.id as string,
            fields: trigger.fields,
            frequency: trigger.frequency,
            interval: trigger.interval,
            startDate: trigger.startDate,
          })
        ),
        ...createTriggers.map((trigger) => createTrigger(trigger)),
      ];

      await Promise.all(promiseQueue);
    },
    [createTrigger, deleteTrigger, triggers, updateTrigger]
  );
}
