import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getSmoothStepPath,
  EdgeLabelRenderer,
  BaseEdge,
  type EdgeProps,
} from 'reactflow';
import { Newspaper } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { Popover } from '@spektr/client/components';

import { ConnectionPopover } from '../../containers/ConnectionPopover';

import { type EdgeData } from '../../types/EdgeData';

export namespace EdgeWithLabel {
  export type Props = EdgeProps<EdgeData>;
}

export const EdgeWithLabel = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  ...props
}: EdgeWithLabel.Props) => {
  const { t } = useTranslation('cases');

  const [open, setOpen] = useState(false);

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const edgeStyle = {
    ...props.style,
    stroke: 'rgb(var(--twc-color-border-graph))',
  };

  if (props.selected) {
    edgeStyle.stroke = 'rgb(var(--twc-color-border-graph--active))';
  }

  return (
    <>
      <BaseEdge {...props} path={edgePath} style={edgeStyle} />
      <EdgeLabelRenderer>
        <Popover
          align="center"
          alignOffset={0}
          side="right"
          sideOffset={80}
          triggerProps={{
            asChild: true,
          }}
          open={open}
          onOpenChange={setOpen}
          trigger={
            <div
              className={cn(
                'absolute',
                'flex items-center justify-center gap-2',
                'px-3 py-2',
                'border-color-border-graph rounded-md border',
                'bg-color-bg-process-builder-item',
                'text-color-text-badge',
                'cursor-pointer',
                'nodrag nopan',
                {
                  'border-color-border-graph--active': props.selected,
                }
              )}
              style={{
                pointerEvents: 'all',
                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              }}
            >
              <Newspaper className="h-3 w-3" />
              <span className="text-xxs font-medium">
                {data?.label ?? t('uboGraph.edges.unknown')}
              </span>
            </div>
          }
        >
          <ConnectionPopover connection={data} />
        </Popover>
      </EdgeLabelRenderer>
    </>
  );
};
