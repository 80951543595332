import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { produce } from 'immer';

import { ServiceNode } from '@spektr/shared/types';
import { NodeType } from '@spektr/shared/validators';

import { GroupedServiceFields } from '../../types/GroupedServiceField';
import { useServiceFields } from '../../hooks/useServiceFields';
import { getGroupedServiceFields } from '../../utils/getGroupedServiceFields';

type EnrichedServiceFieldsContextApi = {
  groupedFields: GroupedServiceFields;
  nodeType: NodeType;
  selectedFieldsCount: number;
  debouncedSearchValue: string;
  handleFieldSelectionChange: (
    isGroup: boolean
  ) => (key: string, value: boolean) => void;
  handleFieldSearch: (value: string) => void;
  getServiceFields: () => Record<string, boolean>;
};

const EnrichedServiceFieldsContext =
  createContext<EnrichedServiceFieldsContextApi>({
    groupedFields: {},
    nodeType: 'openCorporatesRisk',
    selectedFieldsCount: 0,
    debouncedSearchValue: '',
    handleFieldSelectionChange: () => () => null,
    handleFieldSearch: () => null,
    getServiceFields: () => ({}),
  });

export const useEnrichedServiceFields = () =>
  useContext(EnrichedServiceFieldsContext);

type EnrichedServiceFieldsProviderProps = {
  node: ServiceNode;
  children: ReactNode;
};

export const EnrichedServiceFieldsProvider = ({
  node,
  children,
}: EnrichedServiceFieldsProviderProps) => {
  const { fields: translatedFields } = useServiceFields(node);
  const [serviceFields, updateServiceFields] = useState(translatedFields);
  const [searchValue, setSearchValue] = useState('');

  const filteredFields = useMemo(
    () =>
      searchValue
        ? serviceFields.filter((field) => {
            const searchTerm = searchValue.toLowerCase();
            return (
              field.label?.toLowerCase().includes(searchTerm) ||
              field.key.includes(searchTerm)
            );
          })
        : serviceFields,
    [serviceFields, searchValue]
  );
  const groupedFields = useMemo(
    () => getGroupedServiceFields(filteredFields),
    [filteredFields]
  );
  const selectedFieldsCount = useMemo(
    () => serviceFields.filter((field) => field.value).length,
    [serviceFields]
  );

  const handleFieldSelectionChange = useCallback(
    (isGroup: boolean) => (key: string, value: boolean) => {
      updateServiceFields(
        produce(serviceFields, (draft) => {
          if (isGroup) {
            const group = groupedFields[key];

            group.forEach((field) => {
              const fieldToUpdate = draft.find(
                (draftField) => draftField.key === field.key
              );
              if (fieldToUpdate) {
                fieldToUpdate.value = value;
              }
            });
          } else {
            const field = draft.find((field) => field.key === key);
            if (field) {
              field.value = value;
            }
          }
        })
      );
    },
    [serviceFields, groupedFields]
  );

  const getServiceFields = useCallback(() => {
    return serviceFields.reduce(
      (acc, field) => ({
        ...acc,
        [field.key]: field.value,
      }),
      {}
    );
  }, [serviceFields]);

  return (
    <EnrichedServiceFieldsContext.Provider
      value={{
        groupedFields,
        nodeType: node.nodeType,
        selectedFieldsCount,
        debouncedSearchValue: searchValue,
        handleFieldSelectionChange,
        handleFieldSearch: (value: string) => setSearchValue(value),
        getServiceFields,
      }}
    >
      {children}
    </EnrichedServiceFieldsContext.Provider>
  );
};
