import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Trash } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { Spinner } from '@spektr/client/components';

import { Content } from './components/Content';

export namespace LogoField {
  export type OnDropAcceptedFn = DropzoneOptions['onDropAccepted'];

  export type Props = {
    label: string;
    src?: string;
    isUploadPending?: boolean;
    onDropAccepted: OnDropAcceptedFn;
    onDelete: () => void;
  };
}

export const LogoField = ({
  label,
  isUploadPending = false,
  src,
  onDropAccepted,
  onDelete,
}: LogoField.Props) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    maxFiles: 1,
    disabled: isUploadPending,
    accept: {
      'image/png': [],
      'image/jpeg': [],
      'image/jpg': [],
    },
    onDropAccepted,
  });

  if (src) {
    return (
      <div className="flex flex-col gap-2 px-3">
        <div className="flex flex-row justify-between gap-2">
          <label className="text-color-text-subtext text-xs font-medium">
            {label}
          </label>
          <button
            className="hover:text-color-red"
            type="button"
            name="delete_logo"
            onClick={onDelete}
          >
            <Trash className="h-3 w-3" />
          </button>
        </div>
        <div
          className={cn(
            'flex flex-col items-center justify-center',
            'h-[120px]',
            'rounded-md border border-dashed'
          )}
        >
          <div className="h-auto max-w-[150px] overflow-hidden">
            <img alt="logo" src={src} className="object-scale-down" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 px-3">
      <div className="flex flex-row gap-2">
        <label className="text-color-text-subtext text-xs font-medium">
          {label}
        </label>
      </div>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div
          className={cn(
            'flex flex-col items-center justify-center',
            'h-[120px]',
            'rounded-md border border-dashed',
            'cursor-pointer',
            isDragActive && isDragAccept && 'border-color-cyan',
            isDragReject && 'border-color-red',
            isUploadPending && 'bg-color-bg-card--disabled'
          )}
        >
          {isDragActive ? (
            <>
              {isDragAccept && (
                <Content
                  className="text-color-cyan text-opacity-80"
                  text="Drag and drop or press to upload logo - max 5mb"
                />
              )}
              {isDragReject && (
                <Content
                  className="text-color-red"
                  text="Invalid file type, we only support png, jpeg and jpg"
                />
              )}
            </>
          ) : isUploadPending ? (
            <Spinner />
          ) : (
            <Content text="Drag and drop or press to upload logo - max 5mb" />
          )}
        </div>
      </div>
    </div>
  );
};
