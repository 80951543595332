import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cva, VariantProps } from 'class-variance-authority';

import {
  CommentMessageBlock,
  CommentParentType,
} from '@spektr/shared/validators';
import { RBAC } from '@spektr/shared/rbac';
import { type SlateDescendant } from '@spektr/shared/slate-types';

import { usePermissionsContext } from '@spektr/client/providers';
import { cn } from '@spektr/style-utils';
import {
  ButtonWithTooltip,
  RichTextEditor,
  Spinner,
} from '@spektr/client/components';

import { useAddComment } from '../../hooks';
import { isEmptyComment } from '../../utils';

const commentBoxVariants = cva('flex flex-col gap-3', {
  variants: {
    variant: {
      inline: 'bg-color-transparent',
      card: cn(
        'bg-color-bg-card-default p-6',
        'border-color-border-primary rounded-md border'
      ),
    },
  },
  defaultVariants: {
    variant: 'inline',
  },
});

type CommentBoxProps = VariantProps<typeof commentBoxVariants> & {
  parentType: CommentParentType;
  parentId: string;
  onAddComment?: () => void;
};

export const CommentBox = ({
  variant,
  parentType,
  parentId,
  onAddComment,
}: CommentBoxProps) => {
  const { t } = useTranslation();
  const { hasPermission } = usePermissionsContext();
  const [resetKey, setResetKey] = useState(0);
  const [comment, updateComment] = useState<CommentMessageBlock[]>();
  const handleResetComment = () => {
    updateComment([]);
    setResetKey((prev) => prev + 1);
  };
  const addComment = useAddComment(parentType, parentId, handleResetComment);

  const handleAddComment = async () => {
    if (!comment || isEmptyComment(comment)) {
      return;
    }

    await addComment.mutateAsync(comment);
    onAddComment?.();
  };

  const canComment = hasPermission(RBAC.ACTIONS.COMMENTS.CREATE);
  const isDisabled =
    !comment || isEmptyComment(comment) || addComment.isPending || !canComment;

  return (
    <div className={commentBoxVariants({ variant })}>
      <RichTextEditor
        floatingToolbar={false}
        key={resetKey}
        className="max-h-28 !min-h-14"
        placeholder={`Leave a comment on this ${parentType}`}
        onChange={(value: SlateDescendant[]) =>
          updateComment(value as CommentMessageBlock[])
        }
      />
      <ButtonWithTooltip
        className="ml-auto"
        onClick={handleAddComment}
        disabled={isDisabled}
        showTooltip={!canComment}
        tooltipProps={{
          content: t('errors.notAllowed'),
        }}
      >
        {addComment.isPending ? (
          <div className="flex items-center gap-1">
            <Spinner className="h-4 w-4" />
            Adding comment...
          </div>
        ) : (
          'Add comment'
        )}
      </ButtonWithTooltip>
    </div>
  );
};
