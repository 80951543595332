import { SpektrField } from '../../goldenRecord';

import { commonSpektrFields } from '../commonSpektrFields/commonSpektrFields';

//TODO:  [ST-1237] Label and help are just here for backwards compatibility, they should be removed when the label and help are moved to a localization file and retrieved by the field key

export const hasSanctionsFields: Array<Omit<SpektrField, 'sourceId'>> = [
  {
    key: 'has_sanctions_fields.last_updated_utc',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_sanctions_fields.last_updated_utc',
    commonSpektrFieldKeys: [commonSpektrFields.sanctionsPublicationDate.key],
  },
  {
    key: 'has_sanctions_fields.entity_type',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_sanctions_fields.entity_type',
    commonSpektrFieldKeys: [commonSpektrFields.sanctionsEntityType.key],
  },
  {
    key: 'has_sanctions_fields.name',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_sanctions_fields.name',
    commonSpektrFieldKeys: [commonSpektrFields.sanctionsCompanyName.key],
  },
  {
    key: 'has_sanctions_fields.types',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_sanctions_fields.types',
  },
];

export const hasWarningsFields: Array<Omit<SpektrField, 'sourceId'>> = [
  {
    key: 'has_warnings_fields.last_updated_utc',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_warnings_fields.last_updated_utc',
    commonSpektrFieldKeys: [commonSpektrFields.warningsPublicationDate.key],
  },
  {
    key: 'has_warnings_fields.entity_type',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_warnings_fields.entity_type',
    commonSpektrFieldKeys: [commonSpektrFields.warningsEntityType.key],
  },
  {
    key: 'has_warnings_fields.name',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_warnings_fields.name',
    commonSpektrFieldKeys: [commonSpektrFields.warningsCompanyName.key],
  },
  {
    key: 'has_warnings_fields.types',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_warnings_fields.types',
  },
];

export const hasFitnessAndProbityFields: Array<Omit<SpektrField, 'sourceId'>> =
  [];

export const hasAdverseMediaFields: Array<Omit<SpektrField, 'sourceId'>> = [
  {
    key: 'has_adverse_media_fields.last_updated_utc',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_adverse_media_fields.last_updated_utc',
    commonSpektrFieldKeys: [commonSpektrFields.adverseMediaPublicationDate.key],
  },
  {
    key: 'has_adverse_media_fields.entity_type',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_adverse_media_fields.entity_type',
    commonSpektrFieldKeys: [commonSpektrFields.adverseMediaEntityType.key],
  },
  {
    key: 'has_adverse_media_fields.name',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_adverse_media_fields.name',
    commonSpektrFieldKeys: [commonSpektrFields.adverseMediaCompanyName.key],
  },
  {
    key: 'has_adverse_media_fields.types',
    type: 'string',
    source: 'complyAdvantageKyb',
    label: 'has_adverse_media_fields.types',
  },
];

export const complyAdvantageKybCalculatedFields: Array<
  Omit<SpektrField, 'sourceId'>
> = [
  {
    key: 'has_sanctions',
    type: 'boolean',
    source: 'complyAdvantageKyb',
    label: 'Company is on sanctions lists',
  },
  {
    key: 'has_warnings',
    type: 'boolean',
    source: 'complyAdvantageKyb',
    label: 'Company is on warning lists',
  },
  {
    key: 'has_fitness_and_probity',
    type: 'boolean',
    source: 'complyAdvantageKyb',
    label: 'Company is on exclusion lists',
  },
  {
    key: 'has_adverse_media',
    type: 'boolean',
    source: 'complyAdvantageKyb',
    label: 'Company has adverse media mentions',
  },
  {
    key: 'complyAdvantageKyb_service_failure',
    type: 'boolean',
    source: 'complyAdvantageKyb',
    label: 'Comply advantage KYB service call failed',
  },
];

export const complyAdvantageKybSpektrFields: Array<
  Omit<SpektrField, 'sourceId'>
> = [
  ...complyAdvantageKybCalculatedFields,
  ...hasSanctionsFields,
  ...hasWarningsFields,
  ...hasFitnessAndProbityFields,
  ...hasAdverseMediaFields,
];
