import { ClientRecordType } from '@spektr/shared/validators';

import { NameWithTooltip } from '../NameWithTooltip';
import { CaseIcon } from '../CaseIcon';

export namespace EntityLink {
  export type Props = {
    parent: Entity;
    child: Entity;
  };

  type Entity = {
    name: string;
    subtext: string;
    type: ClientRecordType;
  };
}

export const EntityLink = ({ parent, child }: EntityLink.Props) => {
  return (
    <div className="relative flex flex-col gap-6">
      <div className="bg-color-border-process-builder-item absolute bottom-9 left-[18px] top-9 w-[1px] translate-x-1/2" />
      <div className="flex flex-row gap-3">
        <CaseIcon status="pending" type={parent.type} />
        <div className="flex flex-col">
          <NameWithTooltip name={parent.name} maxWidth={190} />
          <span className="text-color-text-badge text-xs">
            {parent.subtext}
          </span>
        </div>
      </div>
      <div className="flex flex-row gap-3">
        <CaseIcon status="pending" type={child.type} />
        <div className="flex flex-col">
          <NameWithTooltip name={child.name} maxWidth={190} />
          <span className="text-color-text-badge text-xs">{child.subtext}</span>
        </div>
      </div>
    </div>
  );
};
