import { generatePath } from 'react-router-dom';
import { z } from 'zod';

export * from './cases';
export * from './settings';

// Public
export const LOGIN_URL = '/login';

export const REGISTER_URL = '/sign-up';
export const SET_UP_ACCOUNT_URL = '/set-up-account';
export const CHOOSE_DEMO_OPTION_URL = '/choose-demo-option';

export const RESET_PASSWORD_URL = '/reset-password';
export const CONFIRM_RESET_PASSWORD_URL = '/confirm-reset-password';
export const CHANGE_PASSWORD_URL = '/change-password';

// Protected
export const DASHBOARD_URL = '/';

export const PROCESS_DETAILS_PARAM = `:processId` as const;
export const PROCESS_NODE_PARAM = `:nodeId`;

export const NEW_PROCESS_URL = 'new';
export const PROCESS_NODE_URL = 'node';
export const SHEET_URL = `sheet`;
export const PROCESS_FILTER_URL = `filter`;
export const STRATEGY_URL = 'strategy';
export const FIELD_MAPPING_URL = 'field-mapping';

export const PROCESS_DASHBOARD_URL = 'processes';
export const PROCESS_SCHEDULE_URL = 'schedule';
export const PROCESS_RUNS_URL = 'process-runs';

export const LOOPS_URL = '/loops';
export const LOOPS_NEW_URL = 'new';
export const LOOPS_SOURCE_URL = 'sources';
export const LOOPS_SCHEDULE_URL = 'schedule';
export const LOOPS_NODE_DETAILS_URL = 'node/:nodeId';

export const CONNECTION_HUB_URL = '/connection-hub';
export const CONNECTION_HUB_DATA_SOURCES = 'data-sources';
export const CONNECTION_HUB_SERVICE_CATALOG = 'service-catalog';
export const CONNECTION_SOURCE_UPLOAD_URL = 'source/file-upload';
export const CONNECTION_SOURCE_INTEGRATION_URL = 'source/integration';
export const CONNECTION_NEW_DATASET_URL = 'dataset/new';
export const CONNECTION_DATASET_URL = 'dataset/:datasetId';
export const CONNECTION_DATASET_PROCESSES_URL = 'dataset/:datasetId/processes';
export const CONNECTION_DATASET_RECORDS_URL = 'dataset/:datasetId/records';
export const CONNECTION_LABELS_URL = 'labels/:datasetId';
export const CONNECTION_SERVICE_CATALOG_DETAILS_URL = ':serviceName';

export const PROCESS_FULL_URL = `/${PROCESS_DASHBOARD_URL}`;
const NEW_PROCESS_FULL_URL = `${PROCESS_FULL_URL}/${NEW_PROCESS_URL}`;
const PROCESS_DETAILS_FULL_URL = `${PROCESS_FULL_URL}/${PROCESS_DETAILS_PARAM}`;
const NODE_DETAILS_FULL_URL = `${PROCESS_DETAILS_FULL_URL}/${PROCESS_NODE_URL}/${PROCESS_NODE_PARAM}`;
const PROCESS_FILTER_FULL_URL = `${PROCESS_DETAILS_FULL_URL}/${PROCESS_FILTER_URL}`;
const PROCESS_SHEET_FULL_URL = `${PROCESS_DETAILS_FULL_URL}/${SHEET_URL}`;
const PROCESS_SCHEDULE_FULL_URL = `${PROCESS_DETAILS_FULL_URL}/${PROCESS_SCHEDULE_URL}`;
const PROCESS_STRATEGY_FULL_URL = `${PROCESS_DETAILS_FULL_URL}/${STRATEGY_URL}`;
const PROCESS_FIELD_MAPPING_FULL_URL = `${PROCESS_DETAILS_FULL_URL}/${FIELD_MAPPING_URL}`;

export const PROCESSES_RUNS_FULL_URL = `${PROCESS_FULL_URL}/${PROCESS_RUNS_URL}`;

export const MATRIX_DASHBOARD_URL = 'matrices';
export const MATRIX_DASHBOARD_FULL_URL = `/${PROCESS_DASHBOARD_URL}/${MATRIX_DASHBOARD_URL}`;
export const MATRIX_NEW_URL = 'new';
export const MATRIX_NEW_FULL_URL = `/${PROCESS_DASHBOARD_URL}/${MATRIX_DASHBOARD_URL}/${MATRIX_NEW_URL}`;
export const MATRIX_URL = ':matrixId';
export const MATRIX_FULL_URL = `/${PROCESS_DASHBOARD_URL}/${MATRIX_DASHBOARD_URL}/${MATRIX_URL}`;
export const MATRIX_TEMPLATES_URL = 'templates';

export const CONNECTION_HUB_DATA_SOURCES_FULL_URL = `${CONNECTION_HUB_URL}/${CONNECTION_HUB_DATA_SOURCES}`;
export const CONNECTION_HUB_SERVICE_CATALOG_FULL_URL = `${CONNECTION_HUB_URL}/${CONNECTION_HUB_SERVICE_CATALOG}`;

const CONNECTION_DATASET_FULL_URL = `${CONNECTION_HUB_URL}/${CONNECTION_DATASET_URL}`;
const CONNECTION_DATA_SOURCES_DATASET_FULL_URL = `${CONNECTION_HUB_DATA_SOURCES_FULL_URL}/${CONNECTION_DATASET_URL}`;

const CONNECTION_DATASET_PROCESSES_FULL_URL = `${CONNECTION_HUB_URL}/${CONNECTION_DATASET_PROCESSES_URL}`;
const CONNECTION_DATA_SOURCES_DATASET_PROCESSES_FULL_URL = `${CONNECTION_HUB_DATA_SOURCES_FULL_URL}/${CONNECTION_DATASET_PROCESSES_URL}`;
const CONNECTION_DATA_SOURCES_DATASET_RECORDS_FULL_URL = `${CONNECTION_HUB_DATA_SOURCES_FULL_URL}/${CONNECTION_DATASET_RECORDS_URL}`;

export const CONNECTION_SOURCES_UPLOAD_FULL_URL = `${CONNECTION_HUB_URL}/${CONNECTION_SOURCE_UPLOAD_URL}`;

export const CONNECTION_HUB_SERVICE_CATALOG_DIALOG_FULL_URL = `${CONNECTION_HUB_SERVICE_CATALOG_FULL_URL}/${CONNECTION_SERVICE_CATALOG_DETAILS_URL}`;

export const LOOPS_NEW_FULL_URL = `${LOOPS_URL}/${LOOPS_NEW_URL}`;
export const LOOPS_DETAILS_FULL_URL = `${LOOPS_URL}/${PROCESS_DETAILS_PARAM}`;
export const LOOPS_SOURCE_FULL_URL = `${LOOPS_DETAILS_FULL_URL}/${LOOPS_SOURCE_URL}`;
export const LOOPS_SHEET_FULL_URL = `${LOOPS_DETAILS_FULL_URL}/${SHEET_URL}`;
export const LOOPS_NODE_DETAILS_FULL_URL = `${LOOPS_DETAILS_FULL_URL}/${LOOPS_NODE_DETAILS_URL}`;
export const LOOPS_SCHEDULE_FULL_URL = `${LOOPS_DETAILS_FULL_URL}/${LOOPS_SCHEDULE_URL}`;
export const LOOPS_STRATEGY_FULL_URL = `${LOOPS_DETAILS_FULL_URL}/${STRATEGY_URL}`;
export const LOOPS_FIELD_MAPPING_FULL_URL = `${LOOPS_DETAILS_FULL_URL}/${FIELD_MAPPING_URL}`;

export const STRATEGY_PAGE_URL = '/strategy';

export function rootUrl() {
  return DASHBOARD_URL;
}

export function processBuilderNewUrl() {
  return NEW_PROCESS_FULL_URL;
}

export function connectionHubDataSourcesDatasetUrl(datasetId: string) {
  return generatePath(CONNECTION_DATA_SOURCES_DATASET_FULL_URL, { datasetId });
}

export function connectionHubDatasetUrl(datasetId: string) {
  return generatePath(CONNECTION_DATASET_FULL_URL, { datasetId });
}

export function connectionHubLabelingUrl(datasetId: string) {
  return generatePath(CONNECTION_LABELS_URL, { datasetId });
}

export function connectionHubDataSourcesProcessesUrl(datasetId: string) {
  return generatePath(CONNECTION_DATA_SOURCES_DATASET_PROCESSES_FULL_URL, {
    datasetId,
  });
}

export function connectionHubDataSourcesRecordsUrl(datasetId: string) {
  return generatePath(CONNECTION_DATA_SOURCES_DATASET_RECORDS_FULL_URL, {
    datasetId,
  });
}

export function datasetProcessesUrl(datasetId: string) {
  return generatePath(CONNECTION_DATASET_PROCESSES_FULL_URL, { datasetId });
}

export function processBuilderUrl(processId?: string, nodeId?: string) {
  if (processId && nodeId) {
    return generatePath(NODE_DETAILS_FULL_URL, { processId, nodeId });
  }
  if (processId) {
    return generatePath(PROCESS_DETAILS_FULL_URL, { processId });
  }
  return PROCESS_FULL_URL;
}

export function processFilterUrl(processId: string) {
  return generatePath(PROCESS_FILTER_FULL_URL, { processId });
}

export function processScheduleUrl(processId: string) {
  return generatePath(PROCESS_SCHEDULE_FULL_URL, { processId });
}

export function processBuilderSheetUrl(processId: string) {
  return generatePath(PROCESS_SHEET_FULL_URL, { processId });
}

export function processStrategyFullUrl(processId: string) {
  return generatePath(PROCESS_STRATEGY_FULL_URL, { processId });
}

export function processFieldMappingFullUrl(processId: string) {
  return generatePath(PROCESS_FIELD_MAPPING_FULL_URL, { processId });
}

export function processRunsFullUrl(datasetId: string) {
  return generatePath(`${PROCESSES_RUNS_FULL_URL}?datasetId=${datasetId}`);
}

export function serviceDialogFullUrl(serviceName: string) {
  return generatePath(CONNECTION_HUB_SERVICE_CATALOG_DIALOG_FULL_URL, {
    serviceName,
  });
}

export function loopStrategyFullUrl(processId: string) {
  return generatePath(LOOPS_STRATEGY_FULL_URL, { processId });
}

export function loopFieldMappingFullUrl(processId: string) {
  return generatePath(LOOPS_FIELD_MAPPING_FULL_URL, { processId });
}

export function loopScheduleUrl(processId: string) {
  return generatePath(LOOPS_SCHEDULE_FULL_URL, { processId });
}

export function loopSourceUrl(processId: string) {
  return generatePath(LOOPS_SOURCE_FULL_URL, { processId });
}

export function loopDetailsUrl(processId: string) {
  return generatePath(LOOPS_DETAILS_FULL_URL, { processId });
}

export function loopSheetUrl(processId: string) {
  return generatePath(LOOPS_SHEET_FULL_URL, { processId });
}

export function loopNodeDetails(processId: string, nodeId: string) {
  return generatePath(LOOPS_NODE_DETAILS_FULL_URL, { processId, nodeId });
}

export function matrixViewUrl(matrixId: string) {
  return generatePath(MATRIX_FULL_URL, { matrixId });
}

// Schemas

export const processDetailsParamsSchema = z.object({
  processId: z.string(),
});

export const processNodeParamsSchema = z.object({
  processId: z.string(),
  nodeId: z.string(),
});

export const extractDatasetParamsSchema = z.object({
  datasetId: z.string(),
});

export const serviceDialogParamsSchema = z.object({
  serviceName: z.string(),
});

export const matrixDetailsParamsSchema = z.object({
  matrixId: z.string(),
});
