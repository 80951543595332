import { VariantProps, cva } from 'class-variance-authority';
import { FileText, Share, X } from 'lucide-react';
import { ChangeEventHandler, FocusEventHandler } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';

import { formatBytes } from '@spektr/client/utils';
import { cn } from '@spektr/style-utils';

export type FileFieldProps = {
  currentFile?: File;
  description?: string;
  errorMessage?: string;
  label: string;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onDrop: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  onClear: () => void;
};

export const FileField = ({
  currentFile,
  description,
  errorMessage,
  label,
  onBlur,
  onChange,
  onDrop,
  onClear,
}: FileFieldProps) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    maxFiles: 1,
    onDrop,
  });

  if (currentFile) {
    return (
      <div
        className={cn(
          'flex flex-row items-center gap-2',
          'p-2',
          'border-color-border-file rounded-md border'
        )}
      >
        <FileText className="text-color-text-icon-tertiary h-6 w-6 shrink-0" />
        <div className="flex flex-col">
          <span className="text-color-text-form-viewer-file-name text-sm">
            {currentFile.name}
          </span>
          <span className="text-color-text-form-viewer-file-format text-xs">
            {formatBytes(currentFile.size)}
          </span>
        </div>
        <X
          className="text-color-text-icon-tertiary ml-auto h-4 w-4 shrink-0 cursor-pointer"
          onClick={onClear}
        />
      </div>
    );
  }

  let dropState: VariantProps<typeof dropZoneVariants>['dropState'] = 'default';
  let backgroundState: VariantProps<typeof dropZoneVariants>['background'] =
    'default';

  if (isDragActive && isDragAccept) {
    dropState = 'accept';
    backgroundState = 'accept';
  } else if (isDragReject || !!errorMessage) {
    dropState = 'error';

    if (isDragReject) {
      backgroundState = 'error';
    }
  }

  return (
    <div {...getRootProps()}>
      <input {...getInputProps({ onBlur, onChange })} />
      <div
        className={cn(
          dropZoneVariants({
            dropState,
            background: backgroundState,
          })
        )}
      >
        <Share className="text-color-text-icon-tertiary h-8 w-8" />
        <h3 className="text-color-text-form-viewer-file-label mt-4 font-medium">
          {label}
        </h3>
        <p className="text-color-text-form-viewer-file-description mt-2 text-center text-xs">
          {description}
        </p>
      </div>
      {errorMessage && (
        <span className="text-color-red text-sm">{errorMessage}</span>
      )}
    </div>
  );
};
FileField.displayName = 'FileField';

const dropZoneVariants = cva(
  [
    'flex flex-col items-center justify-center',
    'p-6',
    'rounded-lg border border-dashed',
    'cursor-pointer',
  ],
  {
    variants: {
      dropState: {
        default: 'border-color-border-file',
        accept: 'border-color-cyan',
        error: 'border-color-red',
      },
      background: {
        default: 'bg-color-bg-primary',
        accept: 'bg-color-cyan/10',
        error: 'bg-color-red/10',
      },
    },
    defaultVariants: {
      dropState: 'default',
      background: 'default',
    },
  }
);
