import { useEffect, useRef, useState } from 'react';
import _isEqual from 'lodash/isEqual';

import { type SelectOption } from '@spektr/client/components';
import { type Task } from '@spektr/shared/validators';
import { type ManualReviewNode } from '@spektr/shared/types';

import { TeamMemberAssignment } from '../components/TeamMemberAssignment';
import { TaskCreationBlock } from '../components/TaskCreationBlock';
import { Checklist } from '../components/Checklist';

export namespace ManualReviewDialogContent {
  export type Props = {
    node: ManualReviewNode;
    userOptions: SelectOption[];
    onSave: (tasks: Task[]) => void;
  };
}
export const ManualReviewDialogContent = ({
  node,
  userOptions,
  onSave,
}: ManualReviewDialogContent.Props) => {
  const [tasks, updateTasks] = useState(node.tasks ?? []);
  const [assignedUser, setAssignedUser] = useState<string | undefined>();
  const prevTasksRef = useRef(tasks);

  const handleAddTask = (task: Task) => {
    updateTasks((prevTasks) => [task, ...prevTasks]);
  };

  const handleRemoveTask = (taskId: string) => {
    updateTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
  };

  useEffect(() => {
    if (!_isEqual(prevTasksRef.current, tasks)) {
      prevTasksRef.current = tasks;
      onSave(tasks);
    }
  }, [tasks, onSave]);

  return (
    <div className="flex flex-col gap-4">
      <TeamMemberAssignment
        users={userOptions}
        assignedUser={assignedUser}
        onChangeAssignedUser={setAssignedUser}
      />
      <TaskCreationBlock onAddTask={handleAddTask} />
      <Checklist tasks={tasks} onRemoveTask={handleRemoveTask} />
    </div>
  );
};
