import { useCallback } from 'react';

import {
  CasesEmptyTableState,
  CasesTable,
  TableBody,
} from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

import { Document } from '@spektr/shared/validators';

import { CustomerDocumentsTableHeader } from './CustomerDocumentsTableHeader';
import { CustomerDocumentsTableRow } from './CustomerDocumentsTableRow';

const tableRowClassName = cn(
  'grid',
  'grid-cols-[repeat(14,minmax(30px,1fr))]',
  'lg:grid-cols-[repeat(14,minmax(60px,1fr))]',
  '!border-0'
);

type CustomerDocumentsTableProps = {
  documents: Document[];
  columns?: string[];
  onRemoveDocument: (docKey: string) => void;
};

export const CustomerDocumentsTable = ({
  documents,
  columns = ['description', 'size', 'creationDate', 'expiryDate', 'actions'],
  onRemoveDocument,
}: CustomerDocumentsTableProps) => {
  const renderRow = useCallback(
    (row: Document, index: number) => {
      return (
        <CustomerDocumentsTableRow
          className={tableRowClassName}
          columns={columns}
          document={row}
          onRemoveDocument={onRemoveDocument}
          key={index}
        />
      );
    },
    [columns, onRemoveDocument]
  );

  return (
    <CasesTable>
      <CustomerDocumentsTableHeader
        className={tableRowClassName}
        columns={columns}
      />
      <TableBody>
        {documents.length > 0 ? (
          <div className="flex flex-col gap-2">{documents.map(renderRow)}</div>
        ) : (
          <CasesEmptyTableState
            columnCount={columns.length}
            message="No documents found"
          />
        )}
      </TableBody>
    </CasesTable>
  );
};
