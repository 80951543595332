export namespace ContentSection {
  export type Props = {
    title: string;
    description: string;
    children: React.ReactNode;
  };
}

export const ContentSection = ({
  title,
  description,
  children,
}: ContentSection.Props) => (
  <div className="mb-5 flex flex-col gap-6 p-5">
    <div className="flex flex-col">
      <h1 className="text-color-text-primary text-lg font-medium">{title}</h1>
      <p className="text-sm">{description}</p>
    </div>
    {children}
  </div>
);
