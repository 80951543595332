import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { InboxIcon, CopyPlus, Trash } from 'lucide-react';

import {
  AlertDialog,
  CardWithContextMenuProps,
} from '@spektr/client/components';
import {
  ProcessApiClient,
  getRiskMatricesQuery,
  getRiskMatricesQueryKey,
} from '@spektr/client/services';
import { usePermissionsContext } from '@spektr/client/providers';

import { RBAC } from '@spektr/shared/rbac';
import { matrixViewUrl } from '@spektr/shared/utils';

import { RiskMatrixCard } from '../components/RiskMatrixCard';

export const RiskMatrixList = () => {
  const { data: fetchedMatrices = [] } = useSuspenseQuery(
    getRiskMatricesQuery()
  );
  const [matrixToDeleteId, setMatrixToDeleteId] = useState<
    string | undefined
  >();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { hasPermission } = usePermissionsContext();
  const actions = useMemo<CardWithContextMenuProps['actions']>(
    () => [
      {
        type: 'item',
        label: 'Duplicate',
        icon: <CopyPlus className="h-3 w-3" />,
        value: 'duplicate',
        variant: 'default',
        disabled: !hasPermission(RBAC.ACTIONS.RISK_MATRIX.CREATE),
      },
      {
        type: 'separator',
        value: 'separator',
      },
      {
        type: 'item',
        label: 'Delete',
        icon: <Trash className="h-3 w-3" />,
        value: 'delete',
        variant: 'danger',
        disabled: !hasPermission(RBAC.ACTIONS.RISK_MATRIX.DELETE),
      },
    ],
    [hasPermission]
  );

  const matrices = useMemo(
    () => fetchedMatrices.sort((a, b) => b.createdAt - a.createdAt),
    [fetchedMatrices]
  );

  const deleteRiskMatrix = useMutation({
    mutationFn: (id: string) =>
      ProcessApiClient.getClient().deleteRiskMatrixById(undefined, {
        params: {
          id,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getRiskMatricesQueryKey() });
      setMatrixToDeleteId(undefined);
    },
  });

  const duplicateRiskMatrix = useMutation({
    mutationFn: (id: string) =>
      ProcessApiClient.getClient().duplicateRiskMatrix(undefined, {
        params: {
          id,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getRiskMatricesQueryKey() });
      setMatrixToDeleteId(undefined);
    },
  });

  if (matrices?.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center gap-2 p-8">
        <InboxIcon className="stroke-color-cyan h-8 w-8" />
        <span className="text-color-text-error-boundry">
          No matrices found. Create a new matrix to get started.
        </span>
      </div>
    );
  }

  const handleActionClick = (matrixId: string) => async (value: string) => {
    switch (value) {
      case 'duplicate':
        await duplicateRiskMatrix.mutateAsync(matrixId);
        break;
      case 'delete':
        setMatrixToDeleteId(matrixId);
        break;
    }
  };

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
      {matrices.map((matrix) => (
        <RiskMatrixCard
          key={matrix.id}
          matrix={matrix}
          actions={actions}
          onActionClick={handleActionClick(matrix.id)}
          disabled={!hasPermission(RBAC.ACTIONS.RISK_MATRIX.UPDATE)}
          onClick={() => navigate(matrixViewUrl(matrix.id))}
        />
      ))}

      <AlertDialog
        open={matrixToDeleteId !== undefined}
        title="Are you sure?"
        paragraph="Deleting this matrix will impact associated processes."
        onCancel={() => setMatrixToDeleteId(undefined)}
        cancel="Cancel"
        onConfirm={async () =>
          matrixToDeleteId && (await deleteRiskMatrix.mutate(matrixToDeleteId))
        }
        confirm="Yes, delete"
        disableButtons={deleteRiskMatrix.isPending}
        confirmDataCy="riskMatrix-confirmDelete-button"
      />
    </div>
  );
};
