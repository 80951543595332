import { DashboardWrapper } from './DashboardWrapper';
import { Processes } from './Processes';

export const ProcessDashboard = () => {
  return (
    <DashboardWrapper>
      <Processes />
    </DashboardWrapper>
  );
};
