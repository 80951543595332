import { useRef } from 'react';

import { useSuspenseQuery } from '@tanstack/react-query';

import { toast } from '@spektr/client/components';

import { getAllowedSpektrFieldsQuery } from '@spektr/client/services';

import { updateSlackNodeSchema } from '@spektr/shared/validators';
import { type SlackNode } from '@spektr/shared/types';

import { type BaseDialogProps } from '../../../../types/BaseDialogProps';

import { DialogEditableTitle } from '../../../../components/DialogEditableTitle';

import { type FormValues } from '../../types/FormValues';

import { SlackForm } from '../SlackForm';

export namespace SlackContent {
  export type Props = BaseDialogProps<SlackNode>;
}

export const SlackContent = ({
  isPendingUpdate,
  node,
  nodeId,
  processId,
  onUpdate,
}: SlackContent.Props) => {
  const { data: allowedSpektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, nodeId)
  );

  const titleRef = useRef<HTMLInputElement>(null);

  const handleSave = async (data: FormValues) => {
    const title = titleRef?.current?.value ?? node.title;
    const parsedData = updateSlackNodeSchema.parse({
      title,
      nodeType: node.nodeType,
      ...data,
    });

    await onUpdate(parsedData);

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });
  };

  const handleInputBlur = (title: string) => {
    onUpdate({
      ...node,
      nodeType: node.nodeType,
      title,
    });
  };

  return (
    <>
      <DialogEditableTitle
        titleRef={titleRef}
        initialTitle={node.title}
        onBlur={handleInputBlur}
      />
      <SlackForm
        allowedSpektrFields={allowedSpektrFields}
        isPendingUpdate={isPendingUpdate}
        node={node}
        onSave={handleSave}
      />
    </>
  );
};
