import { CirclePlus, Flag } from 'lucide-react';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Handle, Position } from 'reactflow';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { RBAC } from '@spektr/shared/rbac';
import { ProcessNode } from '@spektr/shared/types';
import { loopSheetUrl, processBuilderSheetUrl } from '@spektr/shared/utils';

import { ProcessApiClient, getProcessQueryKey } from '@spektr/client/services';

import { Tooltip } from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';
import { cn } from '@spektr/style-utils';
import { NODE } from '@spektr/shared/components';

import { GraphNodeProps } from './types';

type NewNodeLeafProps = GraphNodeProps<ProcessNode>;

export const NewNodeLeaf = memo(({ data }: NewNodeLeafProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { hasPermission } = usePermissionsContext();

  const canEdit = hasPermission(RBAC.ACTIONS.NODE.UPDATE);

  const handleClick = () => {
    const href =
      data.process.type === 'loop'
        ? loopSheetUrl(data.process.id)
        : processBuilderSheetUrl(data.process.id);

    navigate(href, {
      state: {
        predecessorNode: data?.predecessorNode,
      },
    });
  };

  const addOutcomeNodeMutation = useMutation({
    mutationFn: () =>
      ProcessApiClient.getClient().createNode(
        {
          nodeType: 'outcome',
          predecessorId: data.predecessorNode?.id,
        },
        {
          params: {
            processId: data.process.id,
          },
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getProcessQueryKey(data.process.id),
      });
    },
  });

  return (
    <div
      className={`relative flex h-[${NODE.HEIGHT}px] items-center justify-center`}
    >
      <button
        disabled={!canEdit}
        className="h-7 w-7 disabled:opacity-50"
        onClick={handleClick}
      >
        <Handle
          type="target"
          id="leaf"
          position={Position.Left}
          className="opacity-0"
        />
        <CirclePlus className="stroke-color-stroke-default bg-color-bg-primary hover:stroke-color-stroke-default--hover mx-auto h-4 w-4 rounded-full" />
      </button>
      <button
        className="group absolute left-8 h-7 w-7 disabled:opacity-50"
        onClick={() => addOutcomeNodeMutation.mutate()}
        disabled={
          addOutcomeNodeMutation.isPending || !data.predecessorNode || !canEdit
        }
      >
        <Tooltip side="right" content="Add an outcome to this process">
          <div
            className={cn(
              'm-0.5 h-6 w-6',
              'rounded-md border-2 border-lime-500 group-hover:border-lime-600',
              'group-disabled:cursor-not-allowed group-disabled:group-hover:border-lime-500'
            )}
          >
            <Flag
              className={cn(
                'm-1 h-3 w-3',
                'stroke-lime-500 stroke-2 group-hover:stroke-lime-600',
                'group-disabled:group-hover:stroke-lime-500'
              )}
              data-cy="add-outcome"
            />
          </div>
        </Tooltip>
      </button>
    </div>
  );
});
