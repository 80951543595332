import { useLayoutEffect, useMemo, useState } from 'react';
import { useMeasure } from 'react-use';
import ReactFlow, {
  Background,
  Node,
  Edge,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  FitViewOptions,
  Viewport,
} from 'reactflow';

import 'reactflow/dist/style.css';

import {
  BasicNode,
  EdgeToSegment,
  EdgeIntermediary,
  NewNodeLeaf,
  ProcessFilterNode,
  OutcomeNode,
  EdgeLeaf,
  LoopSourceNode,
  NODE,
  VirtualRootNode,
} from '@spektr/shared/components';
import { generateGraph } from '@spektr/shared/utils';

import { Process, ProcessLink } from '@spektr/shared/types';

import { useLayout } from '../hooks';

const fitViewOptions: FitViewOptions = {
  padding: 0.95,
  // duration: 500,
};

const nodeTypes = {
  virtual: VirtualRootNode,
  processFilter: ProcessFilterNode,
  loopSource: LoopSourceNode,
  addNew: NewNodeLeaf,
  basicNode: BasicNode,
  outcomeNode: OutcomeNode,
};

const edgeTypes = {
  default: EdgeLeaf,
  edgeIntermediary: EdgeIntermediary,
  edgeSegment: EdgeToSegment,
};

const options = { account: 'paid-pro', hideAttribution: true };

interface ProcessBuilderInnerProps {
  initialNodes: Node[];
  initialEdges: Edge[];
  defaultViewPort: Viewport | undefined;
  onMoveEnd: (viewport: Viewport) => void;
}

const ProcessBuilderInner = ({
  initialNodes,
  initialEdges,
  defaultViewPort,
  onMoveEnd,
}: ProcessBuilderInnerProps) => {
  const [nodes, _setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, _setEdges, onEdgesChange] = useEdgesState(initialEdges);

  useLayout();

  if (!defaultViewPort) return null; // dont render until we have a default viewport

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      proOptions={options}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      fitViewOptions={fitViewOptions}
      defaultViewport={defaultViewPort}
      minZoom={0.2}
      nodesDraggable={false}
      nodesConnectable={false}
      zoomOnDoubleClick={false}
      deleteKeyCode={null}
      onMoveEnd={(_event, viewPort) => onMoveEnd(viewPort)}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
    >
      <Background className="bg-color-bg-primary" />
    </ReactFlow>
  );
};

interface ProcessBuilderProps {
  process: Process;
  links?: ProcessLink[];
}

export const ProcessBuilder = ({
  process,
  links = [],
}: ProcessBuilderProps) => {
  const [canvasRef, { height, width }] = useMeasure<HTMLDivElement>();
  const [viewPort, setViewPort] = useState<Viewport | undefined>(undefined); // we cannot use `height` and `width` here on first render bc they are both 0 on first render

  useLayoutEffect(() => {
    if (!height || !width) return;

    // set initial viewport position to be center of canvas
    setViewPort((prev) => ({
      zoom: prev?.zoom ?? 1,
      x: width * 0.5 - NODE.WIDTH * 0.5,
      y: height * 0.5 - NODE.HEIGHT * 0.5,
    }));
  }, [height, width]);

  const { nodes, edges } = useMemo(() => {
    return generateGraph(process, links);
  }, [process, links]);

  return (
    <div ref={canvasRef} className="h-full w-full">
      <ReactFlowProvider>
        <ProcessBuilderInner
          key={process.updatedAt}
          initialEdges={edges}
          initialNodes={nodes}
          defaultViewPort={viewPort}
          onMoveEnd={setViewPort}
        />
      </ReactFlowProvider>
    </div>
  );
};
