import { InsightsApiClient } from '../client';
import { EXECUTIONS_KEYS } from '../queryKeys';
import { Filters } from '../types/Filters';

export function getAllExecutionsQuery(datasetId: string, filters?: Filters) {
  return {
    queryKey: EXECUTIONS_KEYS.ALL(datasetId, filters),
    queryFn: () =>
      InsightsApiClient.getClient().getAllExecutionsForDataset({
        params: {
          datasetId,
        },
        queries: {
          page: filters?.page ?? 1,
          limit: filters?.limit ?? 10,
          searchQuery: filters?.searchQuery,
        },
      }),
  };
}

export function getMostRecentExecutionsQuery(filters?: Filters) {
  return {
    queryKey: EXECUTIONS_KEYS.MOST_RECENT,
    queryFn: () =>
      InsightsApiClient.getClient().getMostRecentExecutions({
        queries: {
          page: filters?.page ?? 0,
          limit: filters?.limit ?? 10,
        },
      }),
  };
}

export function getExecutionById(executionId: string) {
  return {
    queryKey: EXECUTIONS_KEYS.DETAILS(executionId),
    queryFn: () =>
      InsightsApiClient.getClient().getExecutionById({
        params: {
          id: executionId,
        },
      }),
  };
}
