import { SkeletonTable } from './SkeletonTable';
import { SkeletonToolbar } from './SkeletonToolbar';

export const SkeletonRecords = () => {
  return (
    <div className="flex flex-col">
      <SkeletonToolbar />
      <SkeletonTable />
    </div>
  );
};
