import { Label } from '@spektr/client/components';

import {
  ClientRecordOwner,
  ClientRecordRepAndController,
} from '@spektr/shared/validators';

import { CustomerOwnershipRow } from './CustomerOwnershipRow';

type CustomerOwnershipBlockProps = {
  label: string;
  ownershipList: (ClientRecordOwner | ClientRecordRepAndController)[];
};

export const CustomerOwnershipBlock = ({
  label,
  ownershipList,
}: CustomerOwnershipBlockProps) => {
  return (
    <div className="flex flex-col gap-2">
      <Label>{label}</Label>
      {ownershipList.map((data, index) => (
        <CustomerOwnershipRow key={`${data.name}-${index}`} data={data} />
      ))}
    </div>
  );
};
