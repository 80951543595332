import { CasesTable, TableBody } from '@spektr/client/components';

import { cn } from '@spektr/style-utils';

import { SkeletonCustomerDocumentRow } from './SkeletonCustomerDocumentRow';
import { CustomerDocumentsTableHeader } from './CustomerDocumentsTableHeader';

const tableRowClassName = cn(
  'grid',
  'grid-cols-[repeat(14,minmax(30px,1fr))]',
  'lg:grid-cols-[repeat(14,minmax(60px,1fr))]',
  '!border-0'
);

type SkeletonDocumentsTableProps = {
  columns: string[];
};

export const SkeletonCustomerDocumentsList = ({
  columns,
}: SkeletonDocumentsTableProps) => {
  return (
    <CasesTable>
      <CustomerDocumentsTableHeader
        className={tableRowClassName}
        columns={columns}
      />
      <TableBody className="gap-2">
        {new Array(4).fill(null).map((_, index) => (
          <SkeletonCustomerDocumentRow
            className={tableRowClassName}
            key={index}
          />
        ))}
      </TableBody>
    </CasesTable>
  );
};
