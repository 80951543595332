import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CUSTOMERS_KEYS, ProcessApiClient } from '@spektr/client/services';
import { invalidateMultipleQueries } from '@spektr/client/utils';

import { TagUpdate } from '@spektr/shared/validators';

export const useUpdateCustomerTag = (customerId: string) => {
  const queryClient = useQueryClient();

  const tagUpdate = useMutation({
    mutationFn: async (updateInput: TagUpdate) => {
      await ProcessApiClient.getClient().updateCustomerTag(updateInput, {
        params: { customerId },
      });

      await invalidateMultipleQueries(queryClient, [
        {
          queryKey: CUSTOMERS_KEYS.BY_ID(customerId),
        },
        {
          queryKey: CUSTOMERS_KEYS.ALL(),
        },
      ]);
    },
  });

  return tagUpdate;
};
