import { SpektrFieldWithInfo } from '../../goldenRecord';
import { commonSpektrFields } from '../commonSpektrFields/commonSpektrFields';

export enum VirkCommonFieldKey {
  companyName,
  cvrNumber,
  address,
  incorporationDate,
  industryCode,
  industryDescription,
  status,
  legalForm,
  ownersType,
  ownersName,
  ownersAddress,
  ownersShareType,
  ownersPercentageOfOwnership,
  ownersVotingShare,
  ownersIndirectOwnership,
  ownersCategeory,
  representativesType,
  representativesName,
  representativesAddress,
  representativesRole,
  representativesCategory,
  numberOfRepresentatives,
  numberOfOwners,
  virkServiceFailure,
}

export const virkCommonSpektrFields: Record<
  VirkCommonFieldKey,
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = {
  [VirkCommonFieldKey.companyName]: {
    key: 'virk_company_name',
    type: 'string',
    source: 'virk',
    label: 'Company name',
    commonSpektrFieldKeys: [commonSpektrFields.companyName.key],
    group: 'company',
  },
  [VirkCommonFieldKey.cvrNumber]: {
    key: 'virk_cvrNumber',
    label: 'CVR Number',
    type: 'string',
    source: 'virk',
    commonSpektrFieldKeys: [commonSpektrFields.companyNumber.key],
    group: 'company',
  },
  [VirkCommonFieldKey.address]: {
    key: 'virk_address',
    type: 'string',
    source: 'virk',
    label: 'Address',
    commonSpektrFieldKeys: [commonSpektrFields.companyFullAddress.key],
    group: 'company',
  },
  [VirkCommonFieldKey.incorporationDate]: {
    key: 'virk_incorporation_date',
    type: 'string',
    source: 'virk',
    label: 'Incorporation date',
    commonSpektrFieldKeys: [commonSpektrFields.incorporationDate.key],
    group: 'company',
  },
  [VirkCommonFieldKey.industryCode]: {
    key: 'virk_industry_code',
    type: 'string',
    source: 'virk',
    label: 'Industry code',
    commonSpektrFieldKeys: [],
    group: 'company',
  },
  [VirkCommonFieldKey.industryDescription]: {
    key: 'virk_industry_description',
    type: 'string',
    source: 'virk',
    label: 'Industry description',
    commonSpektrFieldKeys: [],
    group: 'company',
  },
  [VirkCommonFieldKey.status]: {
    key: 'virk_status',
    type: 'string',
    source: 'virk',
    label: 'Status',
    commonSpektrFieldKeys: [commonSpektrFields.companyStatus.key],
    group: 'company',
  },
  [VirkCommonFieldKey.legalForm]: {
    key: 'virk_legal_form',
    type: 'string',
    source: 'virk',
    label: 'Legal form',
    commonSpektrFieldKeys: [commonSpektrFields.companyType.key],
    group: 'company',
  },
  [VirkCommonFieldKey.ownersType]: {
    key: 'virk_owners_type',
    type: 'string',
    source: 'virk',
    label: 'Type',
    commonSpektrFieldKeys: [],
    group: 'owners',
  },
  [VirkCommonFieldKey.ownersName]: {
    key: 'virk_owners_name',
    type: 'string',
    source: 'virk',
    label: 'Name',
    commonSpektrFieldKeys: [commonSpektrFields.fullName.key],
    group: 'owners',
  },
  [VirkCommonFieldKey.ownersAddress]: {
    key: 'virk_owners_address',
    type: 'string',
    source: 'virk',
    label: 'Address',
    commonSpektrFieldKeys: [commonSpektrFields.fullAddress.key],
    group: 'owners',
  },
  [VirkCommonFieldKey.ownersShareType]: {
    key: 'virk_owners_share_type',
    type: 'string',
    source: 'virk',
    label: 'Share type',
    commonSpektrFieldKeys: [],
    group: 'owners',
  },
  [VirkCommonFieldKey.ownersPercentageOfOwnership]: {
    key: 'virk_owners_percentage_of_ownership',
    type: 'number',
    source: 'virk',
    label: 'Ownership percentage',
    commonSpektrFieldKeys: [commonSpektrFields.shareholdingPercentage.key],
    group: 'owners',
  },
  [VirkCommonFieldKey.ownersVotingShare]: {
    key: 'virk_owners_voting_share',
    type: 'string',
    source: 'virk',
    label: 'Voting share',
    commonSpektrFieldKeys: [],
    group: 'owners',
  },
  [VirkCommonFieldKey.ownersIndirectOwnership]: {
    key: 'virk_owners_indirect_ownership',
    type: 'string',
    source: 'virk',
    label: 'Indirect ownership',
    commonSpektrFieldKeys: [],
    group: 'owners',
  },
  [VirkCommonFieldKey.ownersCategeory]: {
    key: 'virk_owners_category',
    type: 'string',
    source: 'virk',
    label: 'Entry category',
    group: 'owners',
  },
  [VirkCommonFieldKey.representativesType]: {
    key: 'virk_representatives_type',
    type: 'string',
    source: 'virk',
    label: 'Type',
    commonSpektrFieldKeys: [],
    group: 'representatives',
  },
  [VirkCommonFieldKey.representativesName]: {
    key: 'virk_representatives_name',
    type: 'string',
    source: 'virk',
    label: 'Name',
    commonSpektrFieldKeys: [commonSpektrFields.fullName.key],
    group: 'representatives',
  },
  [VirkCommonFieldKey.representativesAddress]: {
    key: 'virk_representatives_address',
    type: 'string',
    source: 'virk',
    label: 'Address',
    commonSpektrFieldKeys: [commonSpektrFields.fullAddress.key],
    group: 'representatives',
  },
  [VirkCommonFieldKey.representativesRole]: {
    key: 'virk_representatives_role',
    type: 'string',
    source: 'virk',
    label: 'Role',
    commonSpektrFieldKeys: [commonSpektrFields.occupation.key],
    group: 'representatives',
  },
  [VirkCommonFieldKey.numberOfRepresentatives]: {
    key: 'virk_number_of_representatives',
    type: 'string',
    source: 'virk',
    label: 'Number of representatives',
    commonSpektrFieldKeys: [],
  },
  [VirkCommonFieldKey.representativesCategory]: {
    key: 'virk_representatives_category',
    type: 'string',
    source: 'virk',
    label: 'Entry category',
    group: 'representatives',
  },
  [VirkCommonFieldKey.numberOfOwners]: {
    key: 'virk_number_of_owners',
    type: 'string',
    source: 'virk',
    label: 'Number of owners',
    commonSpektrFieldKeys: [],
  },
  [VirkCommonFieldKey.virkServiceFailure]: {
    key: 'virk_service_failure',
    type: 'boolean',
    source: 'virk',
    label: 'Virk service call failed',
  },
};

export const virkSpektrFields = [...Object.values(virkCommonSpektrFields)];
