import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import { cn } from '@spektr/style-utils';

import {
  DialogDescription,
  DialogTitle,
  FullPageDialog,
} from '@spektr/client/components';

import { MoonrakerForm as MoonrakerFormType } from '@spektr/moonraker-types';
import { Process, SpektrField } from '@spektr/shared/types';
import { FormBranding, FormNode } from '@spektr/shared/validators';

import { HEADER_HEIGHT } from './constants/visuals';

import { useHandleSerializedSave } from './hooks/useHandleSerializedSave';
import { useDeserializedForm } from './hooks/useDeserializedForm';

import { FormRouter } from './providers/FormRouter';
import { FormEngine } from './providers/FormEngine';
import { ViewpointsProvider } from './providers/ViewpointsProvider';

import { FormDialogHeader } from './containers/FormDialogHeader';
import { FormNavigation } from './containers/FormNavigation';
import { FormSidebar } from './containers/FormSidebar';

import { FormDialogContent } from './components/FormDialogContent';
import { FormCanvas } from './containers/FormCanvas';
import { FormHelpSidebar } from './containers/FormHelpSidebar';

export type MoonrakerFormProps = {
  isSavePending?: boolean;
  process: Process;
  node: FormNode;
  defaultBranding?: FormBranding;
  spektrFields?: SpektrField[];
  onClose: () => void;
  onSave: (data: MoonrakerFormType) => void;
  onUpdateNodeTitle: (nodeTitle: string) => void;
};

const GRID_ROWS = `grid-rows-[${HEADER_HEIGHT}px_auto]`;

export const MoonrakerForm = ({
  isSavePending = false,
  process,
  node,
  defaultBranding,
  spektrFields = [],
  onClose,
  onSave,
  onUpdateNodeTitle,
}: MoonrakerFormProps) => {
  const handleSerializedSave = useHandleSerializedSave(onSave);
  const deserializedForm = useDeserializedForm(
    node?.moonrakerForm,
    defaultBranding
  );

  return (
    <FullPageDialog modal={false} className="h-full p-0" defaultOpen>
      <VisuallyHidden asChild>
        <DialogTitle>{`${node.title} title`}</DialogTitle>
      </VisuallyHidden>
      <VisuallyHidden asChild>
        <DialogDescription>{`${node.title} description`}</DialogDescription>
      </VisuallyHidden>
      <FormEngine
        initialForm={deserializedForm}
        spektrFields={spektrFields}
        onSave={handleSerializedSave}
      >
        <ViewpointsProvider>
          <DndProvider backend={HTML5Backend}>
            <FormRouter>
              <div className={cn('grid', 'h-full w-full', GRID_ROWS)}>
                <FormDialogHeader
                  isSavePending={isSavePending}
                  processTitle={process.name}
                  nodeTitle={node.title}
                  onClickBack={onClose}
                  onUpdateNodeTitle={onUpdateNodeTitle}
                />
                <FormDialogContent>
                  <FormNavigation />
                  <FormSidebar />
                  <FormHelpSidebar />
                  <FormCanvas />
                </FormDialogContent>
              </div>
            </FormRouter>
          </DndProvider>
        </ViewpointsProvider>
      </FormEngine>
    </FullPageDialog>
  );
};
