import { type AlertField } from '@spektr/client/types';

export const getFieldsWithoutProperties = (fields: AlertField[]) => {
  return fields.filter((field) => {
    const fieldKeys = field.name.split('_fields.');

    if (fieldKeys.length > 1) {
      const fieldIndex = fields.findIndex(
        (f) => f.name === fieldKeys[0] && f.nodeType === field.nodeType
      );

      return fieldIndex === -1;
    }

    return true;
  });
};
