import { ChangeEvent, MouseEvent, ReactNode, useRef, useState } from 'react';
import { PopoverTriggerProps } from '@radix-ui/react-popover';

import { cn } from '@spektr/style-utils';

import { useDebouncedSearch } from '@spektr/platform-hooks';

import { Popover } from '../../Popover';

import { Input, InputProps } from '../Input';

export type DropdownProps = {
  className?: string;
  contentClassName?: string;
  defaultSearchValue?: string;
  hasSearch?: boolean;
  inputProps?: InputProps;
  renderContent: (setOpen: (value: boolean) => void) => ReactNode;
  renderTrigger: (
    isOpen: boolean,
    setOpen: (value: boolean) => void
  ) => ReactNode;
  triggerProps?: PopoverTriggerProps;
  onSearch?: (value: string) => void;
};

export const Dropdown = ({
  className,
  contentClassName,
  defaultSearchValue,
  hasSearch = false,
  inputProps,
  renderContent,
  renderTrigger,
  triggerProps,
  onSearch,
}: DropdownProps) => {
  const [isOpen, setOpen] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { searchValue, setSearchValue } = useDebouncedSearch(
    defaultSearchValue,
    onSearch
  );

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    onSearch && onSearch(event.target.value);
  };

  const handleClickInside = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };

  const handleSetOpen = (value: boolean) => {
    setSearchValue('');
    setOpen(value);

    if (hasSearch) {
      searchInputRef.current?.focus();
    }
  };

  return (
    <Popover
      className={cn(
        'w-80 overflow-hidden',
        'dark:border-color-border-primary border-color-border-secondary',
        'bg-color-bg-select-content',
        'text-color-text-select-content',
        className
      )}
      open={isOpen}
      onOpenChange={handleSetOpen}
      onOpenAutoFocus={(e) => {
        e.preventDefault();
      }}
      triggerProps={{
        ...triggerProps,
        className: cn('w-full', triggerProps?.className),
      }}
      trigger={renderTrigger(isOpen, handleSetOpen)}
    >
      <div
        className={cn(
          'max-h-64 w-full px-2 py-1',
          'list-none',
          'overflow-y-auto',
          contentClassName
        )}
        onClick={handleClickInside}
      >
        {hasSearch && (
          <Input
            {...inputProps}
            ref={searchInputRef}
            value={searchValue}
            onChange={handleSearchChange}
          />
        )}
        <div>{renderContent(handleSetOpen)}</div>
      </div>
    </Popover>
  );
};
