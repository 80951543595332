import { SpektrField } from '@spektr/shared/types';

import { RuleMenuComboBox } from '@spektr/shared/components';

import { Button } from '@spektr/client/components';

import { RuleGroupProvider, useIncompleteRouterNode } from './providers';
import { RoutingRule } from './router';

export type RouterNodeFormDialogContentProps = {
  spektrFields: SpektrField[];
};

export const RouterNodeFormDialogContent = ({
  spektrFields,
}: RouterNodeFormDialogContentProps) => {
  const { incomplete, updateRuleGroup, removeRuleGroup, addRuleGroup } =
    useIncompleteRouterNode();

  return (
    <div className="-mr-3 flex flex-col gap-4 overflow-y-auto">
      {incomplete.groups.map((group) => (
        <RuleGroupProvider
          key={group.clientSideOnlyId}
          spektrFields={spektrFields}
          group={group}
          icon={(ruleId, innerGroupId) => (
            <RuleMenuComboBox
              className="mr-1"
              ruleId={ruleId}
              innerGroupId={innerGroupId}
            />
          )}
          onChange={(updated) => {
            if (updated) updateRuleGroup(updated);
            else removeRuleGroup(group.clientSideOnlyId);
          }}
        >
          <div className="mr-3">
            <RoutingRule />
          </div>
        </RuleGroupProvider>
      ))}
      <div className="mr-3">
        <div className="rounded-radius flex w-full justify-center border border-dashed py-5">
          <Button
            className="bg-color-bg-button-secondary hover:bg-color-bg-button-secondary active:bg-color-bg-button-secondary--hover text-color-text-button-primary"
            size="lg"
            onClick={addRuleGroup}
            children="Add Rule Group"
          />
        </div>
      </div>
    </div>
  );
};
