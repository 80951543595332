import { ProcessScheduleDialog } from '@spektr/model-builder/containers';
import { useContext } from 'react';

import { AuthContext } from '../../../auth/auth-context';

export const ProcessSchedulePage = () => {
  const { user } = useContext(AuthContext);

  return <ProcessScheduleDialog user={user} />;
};
