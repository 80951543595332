import { EllipsisVertical, EqualSquare, Trash2 } from 'lucide-react';

import {
  DropdownMenuComboBox,
  DropdownOption,
} from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

import { useIncompleteRuleGroup } from '../providers';

export type RuleMenuComboBoxProps = {
  ruleId: string;
  innerGroupId: string;
  className?: string;
};
export const RuleMenuComboBox = ({
  ruleId,
  innerGroupId,
  className,
}: RuleMenuComboBoxProps) => {
  const { removePredicate, addCondition } = useIncompleteRuleGroup();

  const options: DropdownOption[] = [
    {
      label: 'Add Rule',
      type: 'item',
      value: 'add-rule',
      icon: <EqualSquare className="h-4 w-4" />,
      variant: 'default',
    },
    {
      type: 'separator',
      value: 'separator',
    },
    {
      label: 'Delete',
      type: 'item',
      value: 'delete',
      icon: <Trash2 className="h-4 w-4" />,
      variant: 'danger',
    },
  ];

  return (
    <DropdownMenuComboBox
      modal
      trigger={
        <EllipsisVertical
          className={cn(
            'ml-auto h-4 w-4',
            'cursor-pointer',
            'stroke-color-stroke-primary hover:stroke-color-stroke-primary--hover',
            className
          )}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      }
      options={options}
      onClick={(value) => {
        if (value === 'add-rule') addCondition(innerGroupId);
        else if (value === 'delete') removePredicate(ruleId);
      }}
    />
  );
};
