import { ReactNode } from 'react';

type CustomerTimelineListProps = {
  children: ReactNode;
};

export const CustomerTimelineList = ({
  children,
}: CustomerTimelineListProps) => {
  return (
    <div className="bg-color-bg-card-default flex flex-col overflow-hidden rounded-md border">
      {children}
    </div>
  );
};
