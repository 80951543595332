import {
  CasesFilterConfiguration,
  CasesFilters,
  CasesFiltersConfigurations,
  FilterSelectionType,
  pageLimitSchema,
} from '../types';

export const hasItems = (array: string[]) => {
  return !!array?.length && array.length > 0;
};

export const getFilterValueFromObject = (
  object: object | undefined,
  objectKey: string
) => {
  if (!object) {
    return undefined;
  }

  const entry = Object.entries(object).find(([key, _]) => key === objectKey);
  return entry ? entry[1] : undefined;
};

export const getMultipleFilterValues = (
  newValue: string,
  previousValue?: string
): string[] => {
  let parsedValues: string[] = [];
  const previousValues = previousValue?.split(',');

  if (!previousValues || previousValues.length === 0 || !previousValues[0]) {
    parsedValues = [newValue];
  } else {
    if (previousValues.includes(newValue)) {
      parsedValues = previousValues.filter((value) => value !== newValue);
    } else {
      parsedValues = previousValues.concat(newValue);
    }
  }

  return parsedValues;
};

export const isFilterWithSingleSelection = (
  configuration: CasesFilterConfiguration
) => {
  return configuration.selectionType === FilterSelectionType.Values.single;
};

export const getParamFilters = (
  configurations: CasesFiltersConfigurations,
  searchParams: URLSearchParams
) => {
  const params: { [key: string]: string | string[] } = {};

  if (searchParams.has('page')) {
    params['page'] = searchParams.get('page') ?? '1';
  }
  if (searchParams.has('limit')) {
    params['limit'] = pageLimitSchema
      .parse(Number(searchParams.get('limit') ?? '10'))
      .toString();
  }
  if (searchParams.has('searchQuery')) {
    params['searchQuery'] = searchParams.get('searchQuery') ?? '';
  }

  Object.entries(configurations).forEach(([filterIdentifier, config]) => {
    if (!searchParams.has(filterIdentifier)) {
      return;
    }
    params[filterIdentifier] = isFilterWithSingleSelection(config)
      ? (searchParams.get(filterIdentifier) ?? '')
      : (searchParams.getAll(filterIdentifier) ?? []);
  });

  return params;
};

export const getFilters = (
  configurations: CasesFiltersConfigurations,
  defaultFilters: { [key: string]: number | string | string[] },
  paramFilters: { [key: string]: string | string[] },
  updateFromUrl: boolean
): CasesFilters => {
  const commonFilters = {
    page: +(paramFilters['page'] || defaultFilters.page || ''),
    limit: +(paramFilters['limit'] || defaultFilters.limit || ''),
    searchQuery: (
      paramFilters['searchQuery'] ||
      (updateFromUrl ? '' : defaultFilters.searchQuery || '')
    ).toString(),
  };
  const customFilters: { [key: string]: string | string[] } = {};

  Object.entries(configurations).forEach(([filterIdentifier, config]) => {
    const customFilterValue =
      paramFilters[filterIdentifier] ||
      (updateFromUrl
        ? isFilterWithSingleSelection(config)
          ? ''
          : []
        : getFilterValueFromObject(defaultFilters, filterIdentifier));

    if (customFilterValue) {
      customFilters[filterIdentifier] = isFilterWithSingleSelection(config)
        ? customFilterValue.toString()
        : (customFilterValue as string[]);
    }
  });

  return { ...commonFilters, ...customFilters };
};
