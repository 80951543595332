import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HitDecisionStatus } from '@spektr/shared/validators';

import { cn } from '@spektr/style-utils';
import { Button, Select, Spinner, Textarea } from '@spektr/client/components';

import { useHitsProviderContext } from '../../providers';

export const MultipleHitsResolver = () => {
  const { t } = useTranslation('cases');
  const {
    selectedHitsCount,
    makeDecisionForHits,
    decisionStatus,
    reason,
    updateDecisionStatus,
    updateReason,
    resetSelectedHits,
    isPendingUpdate,
    alertType,
  } = useHitsProviderContext();
  const [decisionKey, setDecisionKey] = useState(0);

  const handleActionUpdate = (value: HitDecisionStatus) => {
    updateDecisionStatus(value);
  };

  const isVisible = selectedHitsCount > 0;

  const isDisabledForOc = alertType === 'kyb' && selectedHitsCount > 1;
  const isApplyDisabled =
    !decisionStatus ||
    (isDisabledForOc && decisionStatus === 'true-positive') ||
    isPendingUpdate;

  useEffect(() => {
    if (!isVisible) {
      resetSelectedHits();
    }
  }, [isVisible, resetSelectedHits]);

  return (
    <div
      className={cn(
        'flex items-center gap-2 p-3',
        'bg-color-bg-primary shadow-xl',
        'rounded-md border',
        selectedHitsCount === 0 && 'hidden'
      )}
    >
      <span className="whitespace-nowrap text-xs font-semibold">
        Apply to all selected
      </span>
      <Select
        key={decisionKey}
        className="w-36 text-xs"
        value={decisionStatus}
        options={[
          {
            label: t(`alerts.hit.action.${alertType}.false-positive`),
            value: alertType === 'aml' ? 'true-positive' : 'false-positive',
          },
          {
            label: t(`alerts.hit.action.${alertType}.true-positive`),
            value: alertType === 'aml' ? 'false-positive' : 'true-positive',
            disabled: isDisabledForOc,
          },
        ]}
        placeholder="Action"
        onValueChange={handleActionUpdate}
        disabled={isPendingUpdate}
      />
      <Textarea
        className="h-9 min-h-0 min-w-72 flex-1 py-2"
        placeholder="Add a comment"
        value={reason}
        onChange={(e) => updateReason(e.target.value)}
        disabled={isPendingUpdate}
      />
      <Button
        disabled={isApplyDisabled}
        onClick={
          decisionStatus
            ? () => {
                setDecisionKey((prev) => prev + 1);
                makeDecisionForHits();
              }
            : undefined
        }
      >
        {isPendingUpdate ? (
          <>
            <Spinner className="mr-1 h-3 w-3" /> Applying
          </>
        ) : (
          'Apply'
        )}
      </Button>
    </div>
  );
};
