import {
  KyckrNode,
  OpenCorporatesRiskNode,
  VirkNode,
} from '@spektr/shared/validators';

import { EnrichedServiceFieldsProvider } from '../../providers/EnrichedServiceFields';
import { BaseDialogProps } from '../../types/BaseDialogProps';

export namespace WithFieldsEnrichment {
  type SupportedNodeTypes = OpenCorporatesRiskNode | KyckrNode | VirkNode;

  export type Props = BaseDialogProps<SupportedNodeTypes>;
}

export const withFieldsEnrichment = <
  T extends WithFieldsEnrichment.Props = WithFieldsEnrichment.Props,
>(
  WrappedComponent: React.ComponentType<T>
) => {
  return (props: T) => {
    return (
      <EnrichedServiceFieldsProvider node={props.node}>
        <WrappedComponent {...props} />
      </EnrichedServiceFieldsProvider>
    );
  };
};
