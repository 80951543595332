import { ProcessApiClient } from '../client';
import { getTriggersForProcessQueryKey } from '../queryKeys';

export function getTriggersForProcessQuery(processId: string) {
  return {
    queryKey: getTriggersForProcessQueryKey(processId),
    queryFn: () =>
      ProcessApiClient.getClient().getTriggersForProcess({
        params: { processId },
      }),
  };
}
