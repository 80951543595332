import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  ProcessApiClient,
  getProcessesQueryKey,
} from '@spektr/client/services';

import { loopDetailsUrl, processBuilderUrl } from '@spektr/shared/utils';

export const useCreateProcessWithSource = (
  sourceProcessId: string,
  sourceOutcomeId: string
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const name = 'Untitled';
  const source = {
    outcomeId: sourceOutcomeId,
    processId: sourceProcessId,
  };

  const createFilterNodeMutation = useMutation({
    mutationFn: async (id: string) => {
      await ProcessApiClient.getClient().createNode(
        {
          nodeType: 'filter',
          source: {
            ...source,
            type: 'process',
          },
        },
        {
          params: { processId: id },
        }
      );

      await queryClient.invalidateQueries({
        queryKey: getProcessesQueryKey(),
      });

      navigate(processBuilderUrl(id));
    },
  });

  const createProcessMutation = useMutation({
    mutationFn: async (type: 'risk' | 'monitoring' | 'score' | 'loop') => {
      const process = await ProcessApiClient.getClient().createProcess({
        name,
        type,
      });

      await createFilterNodeMutation.mutateAsync(process?.id);
    },
  });

  const createOnboardingMutation = useMutation({
    mutationFn: async () => {
      const process = await ProcessApiClient.getClient().createProcess({
        name,
        type: 'onboarding',
      });

      await ProcessApiClient.getClient().createNode(
        {
          nodeType: 'onboardingProcessSource',
          sources: [source],
          channelSettings: [{ type: 'email', mapping: {}, messageBody: [] }],
        },
        {
          params: { processId: process.id },
        }
      );

      return process;
    },
    async onSuccess(data) {
      await queryClient.invalidateQueries({
        queryKey: getProcessesQueryKey(),
      });

      navigate(processBuilderUrl(data.id));
    },
  });

  const createLoopMutation = useMutation({
    mutationFn: async () => {
      const process = await ProcessApiClient.getClient().createProcess({
        type: 'loop',
        name,
      });

      await ProcessApiClient.getClient().createNode(
        {
          nodeType: 'loopSource',
          sources: [source],
          channelSettings: [{ type: 'email', mapping: {}, messageBody: [] }],
        },
        {
          params: { processId: process.id },
        }
      );

      return process;
    },
    onSuccess: async (data) => {
      await queryClient.resetQueries({ queryKey: getProcessesQueryKey() });
      navigate(loopDetailsUrl(data.id));
    },
  });

  const createProcess = useCallback(
    async (type: 'risk' | 'monitoring' | 'score' | 'loop' | 'onboarding') => {
      if (type === 'loop') {
        return createLoopMutation.mutateAsync();
      }

      if (type === 'onboarding') {
        return createOnboardingMutation.mutateAsync();
      }

      return createProcessMutation.mutateAsync(type);
    },
    [createLoopMutation, createOnboardingMutation, createProcessMutation]
  );

  return createProcess;
};
