import { PlusSquare } from 'lucide-react';

import { Button } from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';
import { RBAC } from '@spektr/shared/rbac';
import { useState } from 'react';

import { ChooseNewProcessDialog } from './ChooseNewProcessDialog';

export const ChooseNewProcessButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { hasPermission } = usePermissionsContext();

  return (
    <>
      <Button
        className="whitespace-nowrap p-3.5 font-semibold"
        color="cyan"
        startIcon={<PlusSquare className="h-4 w-4 stroke-2" />}
        disabled={!hasPermission(RBAC.ACTIONS.PROCESS.CREATE)}
        data-cy="create-process-button"
        onClick={() => setIsOpen(true)}
      >
        Add new process
      </Button>
      <ChooseNewProcessDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
