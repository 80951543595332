import { cn } from '@spektr/style-utils';

export const virkContained = cn(
  'bg-color-virk',
  'text-color-text-button-contained-vendor text-color-white',
  'hover:bg-color-virk',
  'active:bg-color-virk'
);
export const virkOutlined = cn(
  virkContained,
  'border-color-virk',
  'text-color-virk',
  'hover:bg-color-virk/10',
  'active:bg-color-virk/20',
  'disabled:border-color-virk/50'
);
export const virkText = cn('text-color-virk');
