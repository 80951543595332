import { processNodeParamsSchema } from '@spektr/shared/utils';
import { useParsedParams } from '@spektr/shared/hooks';

import { NodeDetailsFactoryDialog } from '@spektr/model-builder/containers';

export const LoopNodeDetails = ({ onClose }: { onClose: () => void }) => {
  const { processId, nodeId } = useParsedParams(processNodeParamsSchema);

  return (
    <NodeDetailsFactoryDialog
      nodeId={nodeId}
      processId={processId}
      onClose={onClose}
    />
  );
};
