import { useMemo, useRef } from 'react';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';

import {
  getAllowedSpektrFieldsQuery,
  getTriggersForProcessQuery,
  getTriggersForProcessQueryKey,
} from '@spektr/client/services';

import { useTriggersOperations } from '@spektr/platform-hooks';

import { ServiceFieldClient } from '@spektr/client/types';
import {
  SpektrFieldKey,
  TriggerFrequency,
  type MonitoringDatasetNode,
} from '@spektr/shared/types';

import { TriggerFrequencies } from '../../providers/TriggerFrequencies';
import { DialogEditableTitle } from '../../components/DialogEditableTitle';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { withDialogWrapper } from '../WithDialogWrapper';

import { FieldsWithFrequencyList } from './containers/FieldsWithFrequencyList';
import { SkeletonContent } from './components/SkeletonContent';

export namespace MonitoringDatasetContent {
  export type Props = BaseDialogProps<MonitoringDatasetNode>;
}

const MonitoringDatasetContent = ({
  node,
  nodeId,
  processId,
  onUpdate,
}: MonitoringDatasetContent.Props) => {
  const queryClient = useQueryClient();

  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, nodeId)
  );
  const { data: triggers } = useSuspenseQuery(
    getTriggersForProcessQuery(processId)
  );

  const titleRef = useRef<HTMLInputElement>(null);

  const formInstance = useForm<Record<string, unknown>>({
    defaultValues: {},
    mode: 'onChange',
  });

  const { createTrigger, deleteTrigger, updateTrigger } =
    useTriggersOperations();

  const handleInputBlur = (title: string) => {
    onUpdate({
      nodeType: node.nodeType,
      title,
    });
  };

  const handleUpdateFrequency = async (
    fieldKey: SpektrFieldKey,
    frequency: TriggerFrequency | undefined
  ) => {
    const nextFields = { ...node.fields };

    if (frequency) {
      const currentFrequencyTrigger = triggers.find((trigger) =>
        trigger.fields?.includes(fieldKey)
      );

      if (currentFrequencyTrigger?.frequency) {
        if (currentFrequencyTrigger.frequency === frequency) {
          return;
        }

        nextFields[fieldKey] = true;

        await updateTrigger({
          ...currentFrequencyTrigger,
          triggerId: currentFrequencyTrigger.id as string,
          frequency,
        });
      } else {
        nextFields[fieldKey] = true;

        await createTrigger({
          nodeId,
          processId,
          frequency,
          fields: [fieldKey],
          interval: 1,
          startDate: Date.now(),
        });
      }
    } else {
      nextFields[fieldKey] = false;

      const currentFrequencyTrigger = triggers.find((trigger) =>
        trigger.fields?.includes(fieldKey)
      );

      if (currentFrequencyTrigger) {
        await deleteTrigger(currentFrequencyTrigger.id as string);
      }
    }

    await onUpdate({
      nodeType: node.nodeType,
      title: node.title,
    });

    queryClient.invalidateQueries({
      queryKey: getTriggersForProcessQueryKey(processId),
    });
  };

  const currentNodeTriggers = useMemo(
    () => triggers?.filter((trigger) => trigger.nodeId === nodeId) ?? [],
    [nodeId, triggers]
  );

  const fields = spektrFields.map((i) => {
    return {
      key: i.key,
      help: '',
      label: i.label,
    } as ServiceFieldClient;
  });

  return (
    <>
      <DialogEditableTitle
        titleRef={titleRef}
        initialTitle={node.title}
        onBlur={handleInputBlur}
      />
      <div className="flex flex-col gap-6">
        <FormProvider {...formInstance}>
          <TriggerFrequencies
            fields={fields}
            initialTriggers={currentNodeTriggers}
            nodeId={node.id}
            processId={processId}
          >
            <FieldsWithFrequencyList
              fields={fields}
              onUpdateFrequency={handleUpdateFrequency}
            />
          </TriggerFrequencies>
        </FormProvider>
      </div>
    </>
  );
};

export const MonitoringDatasetDialog = withDialogWrapper(
  MonitoringDatasetContent,
  <SkeletonContent />
);
