import { Aperture } from 'lucide-react';

import {
  BodaccSquareIcon,
  ComplyAdvantageSquareIcon,
  OpenCorporatesSquareIcon,
} from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

export type ServiceIconProps = {
  service: string;
  className?: string;
};

export const ServiceIcon = ({ service, className }: ServiceIconProps) => {
  if (service === 'complyAdvantageKyb' || service === 'complyAdvantageKyc') {
    return <ComplyAdvantageSquareIcon className={className} />;
  }

  if (
    service === 'openCorporatesMonitoring' ||
    service === 'openCorporatesRisk'
  ) {
    return <OpenCorporatesSquareIcon className={className} />;
  }

  if (service === 'bodacc') {
    return <BodaccSquareIcon />;
  }

  return <Aperture className={cn('h-4 w-4', className)} />;
};
