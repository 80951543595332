import { Tag, Tooltip } from '@spektr/client/components';
import { ClientRecordTagModel } from '@spektr/shared/validators';

type CustomerTagsListProps = {
  tags: ClientRecordTagModel[];
  limit?: number;
};

export const CustomerTagsList = ({
  tags,
  limit = 2,
}: CustomerTagsListProps) => {
  const remainingTasks = tags.length - limit;

  return (
    <div className="flex items-center gap-2">
      {tags.slice(0, limit).map((tag, index) => (
        <Tag
          key={index}
          isDeleted={tag.isDeleted}
          color={tag.color}
          label={tag.label}
        />
      ))}
      {remainingTasks > 0 && (
        <Tooltip
          content={
            <div className="flex flex-col gap-1">
              {tags.slice(limit).map((tag, index) => (
                <Tag
                  key={index}
                  isDeleted={tag.isDeleted}
                  color={tag.color}
                  label={tag.label}
                />
              ))}
            </div>
          }
        >
          <span className="text-xs">{`+ ${remainingTasks}`}</span>
        </Tooltip>
      )}
    </div>
  );
};
