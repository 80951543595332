import { Skeleton } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

export const SkeletonServiceList = () => {
  const rows = new Array(3).fill(null).map((_, index) => ({
    key: 'row-' + index,
  }));

  return (
    <div className="flex flex-wrap gap-4">
      {rows.map(({ key }) => (
        <div
          key={key}
          className={cn(
            'rounded-lg border',
            'flex flex-col justify-between',
            'overflow-hidden',
            'min-h-[282px] w-[283px] p-4'
          )}
        >
          <div>
            <Skeleton className="bg-color-bg-skeleton mb-4 h-[100px] w-full" />
            <Skeleton className="bg-color-bg-skeleton mb-2 h-5 w-24" />
            <Skeleton className="bg-color-bg-skeleton h-10 w-full" />
          </div>

          <Skeleton className="bg-color-bg-skeleton h-9 w-32" />
        </div>
      ))}
    </div>
  );
};
