import { FormProvider, useForm } from 'react-hook-form';

import { type SpektrField, type SlackNode } from '@spektr/shared/types';

import { type FormValues } from '../../types/FormValues';
import { SlackComboboxField } from '../../components/SlackComboboxField';
import { SlackInputField } from '../../components/SlackInputField';
import { SlackProtectedInputField } from '../../components/SlackProtectedInputField';
import { SlackMessageField } from '../../components/SlackMessageField';
import { DialogFooterActions } from '../../../../components/DialogFooterActions';

export namespace SlackForm {
  export type Props = {
    allowedSpektrFields: SpektrField[];
    isPendingUpdate?: boolean;
    node: SlackNode;
    onSave: (data: FormValues) => void;
  };
}

export const SlackForm = ({
  allowedSpektrFields,
  isPendingUpdate,
  node,
  onSave,
}: SlackForm.Props) => {
  const formInstance = useForm<FormValues>({
    defaultValues: {
      entityName: node?.entityName,
      channelId: node?.channelId,
      customMessage: node?.customMessage,
      botUserOauthToken: node?.botUserOauthToken,
    },
  });

  const handleSave = () => {
    onSave(formInstance.getValues());
  };

  return (
    <FormProvider {...formInstance}>
      <div className="container">
        {/* TODO: @Alex Remove as part of ST-658. Issue https://issues.chromium.org/issues/41239842 */}
        <input
          name="fake-field"
          type="password"
          className="hidden"
          autoComplete="new-password"
        />
        <div className="mt-4 flex flex-col gap-2 overflow-y-auto">
          <span className="text-color-text-subtext text-xs">
            Select your channel and customize your message
          </span>
          <section className="flex min-w-[500px] flex-col rounded-md border">
            <SlackComboboxField
              isRequired
              name="entityName"
              fields={allowedSpektrFields}
              title="Entity name"
              placeholder="Select entity name"
            />
            <SlackInputField
              isRequired
              name="channelId"
              data-testid="spektr-field-input"
              title="Channel ID"
              placeholder="Type Slack Channel Id Value"
              autoComplete="slack-channel-id"
            />
            <SlackProtectedInputField
              isRequired
              name="botUserOauthToken"
              title="Bot User OAuth Token"
              placeholder="Type Bot User OAuth Token"
              autoComplete="slack-bot-user-oauth-token"
            />
            <SlackMessageField
              name="customMessage"
              className="bg-transparent"
              placeholder="Write your message here, use {{outputField}} for fields from dataset or from process output."
              data-testid="slack-field-custom-message"
              title="Custom message"
            />
          </section>
        </div>
        <DialogFooterActions
          btnColor="cyan"
          disabled={!formInstance.formState.isValid}
          isPendingUpdate={isPendingUpdate}
          onSave={handleSave}
        />
      </div>
    </FormProvider>
  );
};
