import { HTMLAttributes } from 'react';

export const VeriffIdvMinimalIcon = (props: HTMLAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="16"
      fill="none"
      viewBox="0 0 11 16"
      {...props}
    >
      <g fill="#14E6C5">
        <path d="M5.946 6.463v5.623h.02c.376-2.831 2.04-4.693 4.534-5.564V.8c-2.87.495-4.554 2.336-4.554 5.663z"></path>
        <path d="M.6 11.274l4.554 3.782V7.552L.6 3.77v7.504z"></path>
      </g>
    </svg>
  );
};
