import { useFeatureFlags } from '@spektr/platform-hooks';

import { BasicDialog } from '@spektr/client/components';

import { useTranslation } from 'react-i18next';

import { ProcessCard } from './ProcessCard';

export const ChooseNewProcessDialog = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const { enrichmentProcess } = useFeatureFlags();
  const { t } = useTranslation('process');

  return (
    <BasicDialog
      title={t('process:chooseNewProcess.dialog.title', {
        defaultValue: 'Create new process',
      })}
      description={t('process:chooseNewProcess.dialog.description', {
        defaultValue:
          'Processes will be your proudest creations. Take a risk approach to automatically calculate scores and assess your customers risk groups, or monitor a wide range of factors to stay informed about events requiring special attention.',
      })}
      onClose={() => setIsOpen(false)}
      defaultOpen={isOpen}
      key={'choose-new-process-' + isOpen}
      titleClassName="text-color-text-dialog-title font-normal"
      className="lg:max-h-[500px] lg:max-w-[780px] lg:flex-shrink-0"
    >
      <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">
        <ProcessCard variant="onboarding" />
        <ProcessCard variant="monitoring" />
        <ProcessCard variant="score" />
        <ProcessCard variant="risk" />
        {enrichmentProcess && <ProcessCard variant="enrichment" />}
      </div>
    </BasicDialog>
  );
};
