import { ComponentProps } from 'react';

import * as SheetPrimitive from '@radix-ui/react-dialog';
import { XIcon } from 'lucide-react';

import { cn } from '@spektr/style-utils';

export type SheetCloseProps = ComponentProps<typeof SheetPrimitive.Close>;

export const SheetClose = ({ className, ...props }: SheetCloseProps) => {
  return (
    <SheetPrimitive.Close
      {...props}
      className={cn(
        'rounded-md',
        'text-color-text-subtext',
        'transition-colors',
        'hover:text-color-text-primary',
        'dark:focus:ring-ring focus:ring-color-border-secondary',
        'focus:outline-none focus:ring-0 focus:ring-offset-2 dark:focus:ring-2',
        className
      )}
    >
      <XIcon className="h-4 w-4" />
      <span className="sr-only">Close</span>
    </SheetPrimitive.Close>
  );
};
