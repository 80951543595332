import { useRef } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { getProcessByIdQuery } from '@spektr/client/services';

import { type AlertFieldSchema } from '@spektr/shared/validators';
import { type AlertNode } from '@spektr/shared/types';

import { DialogEditableTitle } from '../../components/DialogEditableTitle';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { withDialogWrapper } from '../WithDialogWrapper';

import { ServiceAlertNodeProvider } from './providers/ServiceAlertNodeProvider';
import { AlertFieldsList } from './containers/AlertFieldsList';
import { SkeletonServiceAlertList } from './components/SkeletonServiceAlertList';

export namespace ServiceAlertContent {
  export type Props = BaseDialogProps<AlertNode>;
}

const ServiceAlertContent = ({
  node,
  processId,
  onUpdate,
}: ServiceAlertContent.Props) => {
  const { data: process } = useSuspenseQuery(getProcessByIdQuery(processId));
  const titleRef = useRef<HTMLInputElement>(null);

  const handleSave = (alerts: AlertFieldSchema[]) => {
    const title = titleRef?.current?.value ?? '';
    onUpdate({
      nodeType: 'alert',
      title,
      alerts: alerts,
    });
  };

  const handleInputBlur = (title: string) => {
    onUpdate({
      nodeType: node.nodeType,
      title,
      alerts: node.alerts,
    });
  };

  return (
    <ServiceAlertNodeProvider process={process} node={node} onSave={handleSave}>
      <DialogEditableTitle
        titleRef={titleRef}
        initialTitle={node.title}
        onBlur={handleInputBlur}
      />
      <AlertFieldsList />
    </ServiceAlertNodeProvider>
  );
};

export const ServiceAlertDialog = withDialogWrapper(
  ServiceAlertContent,
  <SkeletonServiceAlertList />
);
