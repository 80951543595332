import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  ProcessApiClient,
  getRiskMatricesQueryKey,
} from '@spektr/client/services';

import { MATRIX_DASHBOARD_FULL_URL } from '@spektr/shared/utils';
import { RiskMatrixCreate } from '@spektr/shared/validators';

import { RiskMatrixDialogContent } from '../../components/RiskMatrixDialogContent';
import { RiskMatrixDialog } from '../../components/RiskMatrixDialog';
import { RiskMatrixData } from '../../../RiskMatrix';

type CreateRiskMatrixProps = {
  data?: RiskMatrixData;
};

export const CreateRiskMatrixDialog = ({ data }: CreateRiskMatrixProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleClose = () => navigate(MATRIX_DASHBOARD_FULL_URL);

  const createRiskMatrix = useMutation({
    mutationFn: (riskMatrix: RiskMatrixCreate) =>
      ProcessApiClient.getClient().createRiskMatrix(riskMatrix),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: getRiskMatricesQueryKey() });
    },
  });

  const handleCreate = async (name: string, data: RiskMatrixData) => {
    await createRiskMatrix.mutateAsync({
      title: name,
      columns: [...data.headers, 'Score'],
      rows: data.values.map((row, index) => [...row, data.scores[index]]),
    });
    handleClose();
  };

  return (
    <RiskMatrixDialog onClose={handleClose}>
      <RiskMatrixDialogContent
        data={data}
        onSubmit={handleCreate}
        isSubmitting={createRiskMatrix.isPending}
      />
    </RiskMatrixDialog>
  );
};
