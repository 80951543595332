import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

const navigationDataStateActive = cn(
  'data-[state=active]:bg-color-bg-tab--active',
  'data-[state=active]:text-color-text-tab--active'
);
const navigationDataStateInactive = cn(
  'data-[state=inactive]:bg-color-bg-tab--inactive',
  'data-[state=inactive]:text-color-text-tab--inactive'
);

const disabled = cn('disabled:cursor-not-allowed', 'disabled:opacity-50');
const focus = cn(
  'focus-visible:ring-ring',
  'focus-visible:outline-none',
  'focus-visible:ring-2',
  'focus-visible:ring-offset-2'
);

export const TabsTrigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> &
    VariantProps<typeof tabsTriggerVariants>
>(({ className, utility, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(tabsTriggerVariants({ utility }), className)}
    {...props}
  />
));

export const tabsTriggerVariants = cva(
  [
    'inline-flex items-center justify-center',
    'h-full w-full px-3 py-1.5',
    'text-color-text-tab-trigger',
    'whitespace-nowrap transition-colors',
  ],
  {
    variants: {
      utility: {
        navigation: cn(
          'text-sm font-medium',
          'bg-color-bg-tab-trigger',
          'rounded',
          navigationDataStateActive,
          navigationDataStateInactive,
          disabled,
          focus
        ),
        filter: cn(
          'text-xs font-semibold',
          'data-[state=inactive]:text-color-text-subtext',
          'bg-color-bg-button-default',
          'hover:bg-color-bg-button-default--hover',
          'active:bg-color-bg-button-default--active',
          'first:rounded-s last:rounded-e',
          'border-r last:border-r-0',
          disabled,
          focus
        ),
      },
    },
    defaultVariants: {
      utility: 'navigation',
    },
  }
);
