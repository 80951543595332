import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  useState,
} from 'react';
import { type VariantProps } from 'class-variance-authority';

import * as SheetPrimitive from '@radix-ui/react-dialog';

import { cn } from '@spektr/style-utils';

import { NonModalSheetOverlay, SheetOverlay } from './SheetOverlay';
import { sheetVariants } from './variants';

export type SheetContentProps = ComponentPropsWithoutRef<
  typeof SheetPrimitive.Content
> &
  VariantProps<typeof sheetVariants> & {
    modal?: boolean;
    requestStartExitAnimation?: () => void;
    onEndExitAnimation?: () => void;
  };

export const SheetContent = forwardRef<
  ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(
  (
    {
      side = 'right',
      className,
      onAnimationStartCapture,
      onAnimationEndCapture,
      children,
      modal = true,
      requestStartExitAnimation,
      onEndExitAnimation,
      ...props
    },
    ref
  ) => {
    const [dataState, setDataState] = useState('open');

    return (
      <>
        {modal ? (
          <SheetOverlay onClick={requestStartExitAnimation} />
        ) : (
          <NonModalSheetOverlay
            data-state={dataState}
            onClick={requestStartExitAnimation}
          />
        )}
        <SheetPrimitive.Content
          ref={ref}
          className={cn(sheetVariants({ side }), className)}
          onAnimationStartCapture={(event) => {
            setDataState(event.animationName === 'exit' ? 'closed' : 'open');
            onAnimationStartCapture?.(event);
          }}
          onAnimationEndCapture={(event) => {
            if (event.animationName === 'exit') onEndExitAnimation?.();
            onAnimationEndCapture?.(event);
          }}
          {...props}
        >
          {children}
        </SheetPrimitive.Content>
      </>
    );
  }
);
