import { useIncompleteRouterNode } from '@spektr/shared/components';

import { type NodeUpdateInput } from '@spektr/shared/types';

import { DialogFooterActions } from '../../../../components/DialogFooterActions';

export namespace RouterFooter {
  export type Props = {
    isPendingUpdate?: boolean;
    onUpdate: (data: NodeUpdateInput) => void;
  };
}

export const RouterFooter = ({
  isPendingUpdate,
  onUpdate,
}: RouterFooter.Props) => {
  const { routerNode } = useIncompleteRouterNode();

  const handleSave = () => {
    if (!routerNode.success) return;

    onUpdate(routerNode.data);
  };

  const disabled = !routerNode.success;

  return (
    <DialogFooterActions
      btnColor="cyan"
      disabled={disabled}
      isPendingUpdate={isPendingUpdate}
      onSave={handleSave}
    />
  );
};
