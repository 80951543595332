import { Skeleton } from '../../Skeleton';
import { TableBody } from '../../Table';
import { CasesTable, CasesTableCell, CasesTableRow } from '../CasesTable';
import { CasesTableHeadCell } from '../CasesTable/CasesTableHeadCell';
import { CasesTableHeader } from '../CasesTable/CasesTableHeader';

export type CustomersSkeletonTableProps = {
  columns: string[];
};

export const CustomersSkeletonTable = ({
  columns,
}: CustomersSkeletonTableProps) => {
  return (
    <CasesTable>
      <CasesTableHeader>
        {columns.includes('customer') && (
          <CasesTableHeadCell>Customer</CasesTableHeadCell>
        )}
        {columns.includes('tags') && (
          <CasesTableHeadCell>Tags</CasesTableHeadCell>
        )}
        {columns.includes('unresolvedAlerts') && (
          <CasesTableHeadCell>Unresolved alerts</CasesTableHeadCell>
        )}
        {columns.includes('status') && (
          <CasesTableHeadCell>Status</CasesTableHeadCell>
        )}
        {columns.includes('actions') && (
          <CasesTableHeadCell></CasesTableHeadCell>
        )}
      </CasesTableHeader>
      <TableBody>
        {[...Array(7)].map((_, index) => (
          <CasesTableRow key={index}>
            {columns.includes('customer') && (
              <CasesTableCell className="rounded-l-md border-l">
                <Skeleton className="w-20" />
              </CasesTableCell>
            )}
            {columns.includes('tags') && (
              <CasesTableCell>
                <div className="flex items-center gap-2">
                  <Skeleton className="w-12" />
                  <Skeleton className="w-20" />
                </div>
              </CasesTableCell>
            )}
            {columns.includes('unresolvedAlerts') && (
              <CasesTableCell>
                <Skeleton className="w-12" />
              </CasesTableCell>
            )}
            {columns.includes('status') && (
              <CasesTableCell className="rounded-r-md border-r">
                <Skeleton className="w-20" />
              </CasesTableCell>
            )}
          </CasesTableRow>
        ))}
      </TableBody>
    </CasesTable>
  );
};
