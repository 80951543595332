import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateNodeSchema } from '@spektr/shared/validators';

import {
  ProcessApiClient,
  getProcessLinksByIdQueryKey,
  getProcessQueryKey,
  getProcessesQueryKey,
  getTopologyQueryKey,
} from '@spektr/client/services';
import { toast } from '@spektr/client/components';

import {
  Process,
  NodeUpdateInput,
  isFilterNodeType,
} from '@spektr/shared/types';

export type UpdateNodeMutationParams = {
  node: NodeUpdateInput;
  nodeId: string;
};

export function useUpdateNode(processId: string, handleSuccess?: () => void) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    meta: { skipGlobalErrorHandling: true },
    mutationFn: async ({ nodeId, node }: UpdateNodeMutationParams) => {
      const parsedBody = updateNodeSchema.parse(node);
      const currentProcess = queryClient.getQueryData<Process>(
        getProcessQueryKey(processId)
      );

      const response = await ProcessApiClient.getClient().updateNodeById(
        parsedBody,
        {
          params: { processId, nodeId },
        }
      );

      if (node.nodeType === 'filter' && currentProcess) {
        const currentFilterNode = currentProcess.nodes.get(nodeId);
        if (currentFilterNode && isFilterNodeType(currentFilterNode)) {
          if (
            currentFilterNode.source?.type === 'process' &&
            currentFilterNode.source?.processId
          ) {
            queryClient.invalidateQueries({
              queryKey: getProcessLinksByIdQueryKey(
                currentFilterNode.source.processId
              ),
            });
          }
        }
      }

      return response;
    },
    onSuccess: async (_, variables) => {
      // For some types of nodes, we don't want to close the dialog
      if (!['manualReview', 'action'].includes(variables.node.nodeType)) {
        handleSuccess?.();
      }

      if (
        variables.node.nodeType === 'router' ||
        (variables.node.nodeType === 'filter' &&
          variables.node.source.type === 'process')
      ) {
        await queryClient.invalidateQueries({
          queryKey: getProcessesQueryKey(),
        });
        await queryClient.resetQueries({
          queryKey: getTopologyQueryKey(),
        });
        return;
      }

      await queryClient.invalidateQueries({
        queryKey: getProcessQueryKey(processId),
      });

      if (variables?.node.nodeType === 'loopSource') {
        await queryClient.resetQueries({
          queryKey: getTopologyQueryKey(),
        });
        await queryClient.resetQueries({ queryKey: getProcessesQueryKey() });
      }
    },
    onError: (
      error: AxiosError<{ message: string }>,
      variables: UpdateNodeMutationParams
    ) => {
      if (variables.node.nodeType === 'router' && error.status === 422) {
        toast.error({
          title: 'An error occured while updating the Router step',
          description: 'You cannot delete a rule which other steps depend on',
        });
      } else {
        toast.error({
          title: 'Something went wrong!',
          description: error.response?.data?.message ?? 'Something went wrong!',
        });
      }
    },
  });

  return mutation;
}
