import { useSuspenseQuery } from '@tanstack/react-query';
import { CirclePlus, Link2 } from 'lucide-react';
import { KeyboardEvent, MouseEvent, useEffect, useMemo, useState } from 'react';

import {
  isKyckrShareholdingOutcome,
  isProcessSource,
} from '@spektr/shared/utils';

import { getProcessesQuery } from '@spektr/client/services';

import { Select, SpektrErrorBoundary } from '@spektr/client/components';

import { Process } from '@spektr/shared/types';

import { useGeneratePossibleSources } from '../../hooks/useGeneratePossibleSources';

import { useSourceProvider } from '../../providers/SourceProvider';

import { MethodItem } from '../../components/MethodItem';
import { KyckrFiltersList } from '../../components/KyckrFiltersList';
import { SectionLabel } from '../../components/SectionLabel';

import { OutcomeSelectDropdown } from '../OutcomeSelectDropdown';

export type AnotherProcessMethodProps = {
  process: Process;
};

export const AnotherProcessMethod = ({
  process,
}: AnotherProcessMethodProps) => {
  const {
    getSelectedMethod,
    setSelectedMethod,
    getFilterProcess,
    setProcessSource,
  } = useSourceProvider();

  const source = getFilterProcess();
  const isSelected = isProcessSource(getSelectedMethod());

  const [selectedProcessId, setSelectedProcessId] = useState(source?.processId);
  const [selectedOutcomeId, setSelectedOutcomeId] = useState(source?.outcomeId);

  const { data: processList } = useSuspenseQuery(
    getProcessesQuery({
      types: ['risk', 'score', 'monitoring', 'onboarding', 'enrichment'],
    })
  );

  const possibleSources = useGeneratePossibleSources(
    process,
    processList ?? []
  );

  const selectedProcess = useMemo(
    () => processList.find((process) => process.id === selectedProcessId),
    [processList, selectedProcessId]
  );

  useEffect(() => {
    if (source) {
      setSelectedProcessId(source?.processId);
      setSelectedOutcomeId(source?.outcomeId);
    }
  }, [source]);

  const handleSelectMethod = (
    ev: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>
  ) => {
    ev.stopPropagation();

    if (isSelected) {
      return;
    }

    setSelectedMethod('process');
  };

  const handleChangeProcess = (processId: string) => {
    setSelectedProcessId(processId);
    setSelectedOutcomeId(undefined);
    setProcessSource(processId);
  };

  const handleChangeOutcome = (outcomeId: string) => {
    setSelectedOutcomeId(outcomeId);
    setProcessSource(selectedProcessId!, outcomeId);
  };

  const isKyckrOutcomeSelected = isKyckrShareholdingOutcome(
    selectedProcess,
    selectedOutcomeId
  );

  return (
    <SpektrErrorBoundary
      fallbackRender={(error) => {
        console.error(error);
        return null;
      }}
    >
      <MethodItem
        description="Choose an outcome from another process"
        icon={<Link2 className="stroke-color-cyan h-[18px] w-[18px]" />}
        isOpen={isSelected}
        isSelected={isSelected}
        disabled={possibleSources.length === 0}
        title="Link existing process"
        onClick={handleSelectMethod}
      >
        <div className="text-color-text-dialog-default grid grid-cols-11">
          <div className="col-span-5 flex w-full flex-col gap-2">
            <SectionLabel>Choose which process from the dropdown</SectionLabel>
            <Select
              required
              name="process"
              placeholder="Select from dropdown..."
              options={possibleSources.map((process) => ({
                value: process.id,
                label: process.name,
              }))}
              value={selectedProcessId}
              onValueChange={handleChangeProcess}
            />
          </div>
          <div className="flex shrink-0 flex-col place-content-end items-center">
            <CirclePlus className="stroke-color-stroke-icon h-9 w-9 p-1.5" />
          </div>
          <OutcomeSelectDropdown
            selectedOutcomeId={selectedOutcomeId}
            selectedProcess={selectedProcess}
            onChange={handleChangeOutcome}
          />
        </div>
        {isKyckrOutcomeSelected && <KyckrFiltersList />}
      </MethodItem>
    </SpektrErrorBoundary>
  );
};
