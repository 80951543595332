import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';

import { RBAC } from '@spektr/shared/rbac';
import {
  processDetailsParamsSchema,
  loopDetailsUrl,
  getChannelSettingsFromRootNode,
  getRootNode,
} from '@spektr/shared/utils';

import {
  getAllowedSpektrFieldsQuery,
  getApiKeysQuery,
  getProcessByIdQuery,
  getProcessesQuery,
} from '@spektr/client/services';

import {
  useDialogClose,
  useParsedParams,
  useUpdateNode,
} from '@spektr/shared/hooks';

import { Dialog, DialogContent } from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';

import { type ProcessSource } from '@spektr/shared/types';
import { type ChannelSettings } from '@spektr/shared/validators';

import { SourceSettings } from '../processes/SourceSettings';

export const UpdateLoopSourceDialog = () => {
  const navigate = useNavigate();
  const { hasPermission } = usePermissionsContext();
  const [open, startExitAnimation] = useDialogClose();
  const { processId } = useParsedParams(processDetailsParamsSchema);

  const { data: apiKeys } = useSuspenseQuery(getApiKeysQuery());
  const { data: processes } = useSuspenseQuery(
    getProcessesQuery({
      types: ['risk', 'score', 'monitoring', 'onboarding', 'enrichment'],
    })
  );
  const { data: loop } = useSuspenseQuery(getProcessByIdQuery(processId));
  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, loop.rootId ?? '')
  );

  const channelSettings = useMemo(() => {
    return getChannelSettingsFromRootNode(loop)?.[0];
  }, [loop]);

  const updateNodeMutation = useUpdateNode(processId, () =>
    navigate(loopDetailsUrl(processId))
  );

  const handleClose = () => {
    navigate(loopDetailsUrl(processId));
  };

  const handleUpdateProcess = (
    sources: ProcessSource[],
    settings?: ChannelSettings | null
  ) => {
    const rootNode = loop ? getRootNode(loop) : undefined;
    if (!rootNode) {
      throw new Error('Root node not found');
    }

    updateNodeMutation.mutate({
      nodeId: rootNode.id,
      node: {
        nodeType: 'loopSource',
        sources,
        channelSettings: settings ? [settings] : [],
      },
    });
  };

  return (
    <Dialog open={open} modal={false}>
      <DialogContent
        modal={false}
        className="bg-color-bg-dialog-default absolute flex flex-col"
        requestStartExitAnimation={startExitAnimation}
        onEndExitAnimation={handleClose}
        onEscapeKeyDown={startExitAnimation}
        data-cy="add-new-process-dialog"
      >
        <SourceSettings
          apiKeys={apiKeys}
          channelSettings={channelSettings}
          isUpdatePending={updateNodeMutation.isPending}
          hasUpdatePermission={hasPermission(RBAC.ACTIONS.PROCESS.UPDATE)}
          nodeTitle="Loop settings"
          nodeIcon="ServerCog"
          process={loop}
          processes={processes}
          spektrFields={spektrFields}
          onClickUpdate={handleUpdateProcess}
          onClose={handleClose}
        />
      </DialogContent>
    </Dialog>
  );
};
