import { useLayoutEffect, useState } from 'react';

export function useDialogTitlePortal() {
  const [title, setTitle] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    const titlePortal = document.getElementById('basicDialog-portal-title');

    if (titlePortal) {
      setTitle(titlePortal);
    }
  });

  return title;
}
