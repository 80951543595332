import { Info } from 'lucide-react';

import { Label, Tooltip } from '@spektr/client/components';

export const UboLabel = () => {
  return (
    <div className="flex items-center gap-2">
      <Label>First layer of ownership</Label>
      <Tooltip content="Get the first layer of ownership, i.e. the direct owners of the company and the shareholding amount (%). Note that this list of owners can contain individuals and/or companies.">
        <Info className="text-color-text-icon-secondary h-3 w-3" />
      </Tooltip>
    </div>
  );
};
