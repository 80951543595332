import { ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

type ExpandedStepCardProps = {
  className?: string;
  description: string;
  icon: ReactNode;
  title: string;
  children: ReactNode;
};

export const ExpandedStepCard = ({
  className,
  description,
  icon,
  title,
  children,
}: ExpandedStepCardProps) => {
  return (
    <div
      className={cn(
        'text-color-text-primary',
        'rounded-md border',
        'p-4',
        className
      )}
    >
      <div
        className={cn('bg-color-bg-accent', 'rounded border', 'h-9 w-9 p-1.5')}
      >
        {icon}
      </div>
      <div className="flex flex-col gap-1 py-4">
        <h3 className="text-lg">{title}</h3>
        <p className="text-color-text-primary/60 line-clamp-3 text-sm">
          {description}
        </p>
      </div>
      <div>{children}</div>
    </div>
  );
};
