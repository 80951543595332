import { Input } from '@spektr/client/components';
import { Search } from 'lucide-react';
import { useRef, useState } from 'react';
import { useDebounce } from 'react-use';

export type SearchBarProps = {
  defaultValue?: string;
  onSearch: (value: string) => void;
};

export const SearchBar = ({ defaultValue, onSearch }: SearchBarProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string | undefined>(
    defaultValue
  );

  useDebounce(
    () => defaultValue !== inputValue && onSearch(inputValue || ''),
    500,
    [inputValue]
  );

  return (
    <Input
      className="w-60"
      startIcon={
        <Search className="text-color-text-subtext mr-1.5 h-3.5 w-3.5" />
      }
      colorVariant="secondary"
      placeholder="Search for ..."
      ref={inputRef}
      defaultValue={defaultValue}
      onChange={(event) => setInputValue(event.target.value)}
    />
  );
};
