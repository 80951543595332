import { memo } from 'react';
import { ArrowDown, BoxIcon, Trash2 } from 'lucide-react';

import {
  Button,
  Input,
  ScrollArea,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
  iconBoxVariants,
} from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

import { RiskMatrixTableBodyRow } from './RiskMatrixTableBodyRow';

type RiskMatrixTableProps = {
  headers: string[];
  rows: string[][];
  scores: string[];
  onAddRowBelow: () => void;
  onColumnNameChange: (index: number, value: string) => void;
  onColumnRemove: () => void;
  updateRows: (rows: string[][]) => void;
  updateScores: (scores: string[]) => void;
};

export const RiskMatrixTable = memo(
  ({
    headers,
    rows,
    scores,
    onAddRowBelow,
    onColumnNameChange,
    onColumnRemove,
    updateRows,
    updateScores,
  }: RiskMatrixTableProps) => {
    const thClasses = 'text-color-text-table-header text-xs p-2';

    return (
      <ScrollArea className="borders h-[378px]">
        <Table className="border-collapse">
          <TableHeader className="bg-color-bg-table-header sticky top-0 h-10">
            <TableRow className="border-b">
              <TableHead className={cn(thClasses, 'pl-5')}>
                <Input
                  className="h-7 text-ellipsis bg-transparent px-2"
                  value={headers[0]}
                  placeholder="Enter header name.."
                  onChange={(e) => onColumnNameChange(0, e.target.value)}
                  data-cy="riskMatrixDialog-headerName-input"
                />
              </TableHead>
              {headers.length === 2 && (
                <TableHead className={thClasses}>
                  <div className="group relative flex items-center">
                    <Input
                      className="h-7 text-ellipsis bg-transparent pr-6"
                      value={headers[1]}
                      placeholder="Enter header name.."
                      onChange={(e) => onColumnNameChange(1, e.target.value)}
                      data-cy="riskMatrixDialog-headerName2-input"
                    />
                    <Tooltip
                      content="Delete this column and all data within it"
                      side="top"
                      sideOffset={6}
                    >
                      <Trash2
                        className={cn(
                          'opacity-0 group-hover:opacity-100',
                          'h-3 w-3',
                          'text-color-text-icon-secondary hover:text-color-red cursor-pointer transition-colors',
                          'disabled:text-color-text-icon-secondary disabled:cursor-not-allowed disabled:opacity-50',
                          'absolute right-2 top-1/2 translate-y-[-50%]'
                        )}
                        onClick={onColumnRemove}
                      />
                    </Tooltip>
                  </div>
                </TableHead>
              )}
              <TableHead className=""></TableHead>
              <TableHead className={cn(thClasses)}>
                <div className="w-[55px]">Score</div>
              </TableHead>
              <TableHead className={cn(thClasses, 'px-5')}></TableHead>
            </TableRow>
          </TableHeader>
          {rows.length ? (
            <TableBody className="overflow-x-hidden">
              {rows.map((row, index) => (
                <RiskMatrixTableBodyRow
                  key={index}
                  row={row}
                  score={scores[index]}
                  onRowUpdate={(cellIndex, value) => {
                    const newRows = rows.map((row, i) =>
                      i === index
                        ? row.map((cell, j) => (j === cellIndex ? value : cell))
                        : row
                    );
                    updateRows(newRows);
                  }}
                  onScoreUpdate={(value) => {
                    const newScores = [...scores];
                    newScores[index] = value;
                    updateScores(newScores);
                  }}
                  onRowDelete={() => {
                    const newRows = rows.filter((_, i) => i !== index);
                    const newScores = scores.filter((_, i) => i !== index);
                    updateRows(newRows);
                    updateScores(newScores);
                  }}
                  onUpdateAllScores={(value) => {
                    const newScores = scores.map(() => value);
                    updateScores(newScores);
                  }}
                />
              ))}
            </TableBody>
          ) : (
            <div className="absolute left-1/2 top-1/2 flex translate-x-[-50%] translate-y-[-50%] flex-col items-center">
              <BoxIcon
                className={cn(
                  iconBoxVariants({ variant: 'cyan' }),
                  'h-11 w-11 p-2.5'
                )}
              />
              <p className="mt-4 text-base font-semibold">
                Create your own matrix
              </p>
              <span className="text-color-text-subtext mt-2 w-[250px] text-center text-xs">
                Add header names to the table at the top and fill in your table
                rows below with your data.
              </span>

              <Button
                color="cyan"
                variant="text"
                className="mt-5 h-auto p-0"
                onClick={onAddRowBelow}
              >
                Add a row below
                <ArrowDown className="ml-1 h-3.5 w-3.5" />
              </Button>
            </div>
          )}
        </Table>
      </ScrollArea>
    );
  }
);
