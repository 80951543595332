import { useState } from 'react';

import { cn } from '@spektr/style-utils';

import { casesAlertsParamsSchema } from '@spektr/shared/utils';

import { useParsedParams } from '@spektr/shared/hooks';

import {
  AnyHitData,
  HitDecisionStatus,
  HitWithVendorDetails,
} from '@spektr/shared/validators';

import { useHitsProviderContext } from '../../providers';
import { getAlertTypeBasedOnService } from '../../utils';
import { UpdateHitMutationArg } from '../../types';
import { useNavigateToHit } from '../../hooks';

import { HitCard } from '../HitCard';

export type HitsListProps = {
  data: HitWithVendorDetails[];
  isMutationPending?: boolean;
  onUpdateHit: (payload: UpdateHitMutationArg) => void;
};

export const HitsList = ({
  data,
  isMutationPending,
  onUpdateHit,
}: HitsListProps) => {
  const navigateToHit = useNavigateToHit();
  const { alertId } = useParsedParams(casesAlertsParamsSchema);
  const { selectHit, deselectHit, isHitSelected, isPendingUpdate } =
    useHitsProviderContext();

  const [pendingAlertsIds, setPendingAlertsIds] = useState<string[]>([]);

  const handleUpdateHit =
    (decision: HitDecisionStatus) =>
    (reason: string) =>
    async (hitId: string) => {
      if (decision === 'pending') return;

      setPendingAlertsIds((prev) => [...prev, hitId]);

      await onUpdateHit({
        alertId,
        hitId: hitId,
        status: decision,
        reason,
      });

      setPendingAlertsIds((prev) => prev.filter((id) => id !== alertId));
    };

  const handleClickHit = (hitId: string) => {
    navigateToHit(alertId, hitId);
  };

  const handleCheckedChange = (hitId: string, isChecked: boolean) => {
    if (isChecked) {
      selectHit(hitId);
    } else {
      deselectHit(hitId);
    }
  };

  return (
    <div className={cn('grid grid-cols-1 gap-6 2xl:grid-cols-2', 'w-full')}>
      {data.map((hit) => {
        return (
          <HitCard
            key={hit.id}
            id={hit.id}
            alertId={alertId}
            className="w-full"
            data={hit as AnyHitData}
            commentCount={hit.commentCount}
            decision={hit.decision}
            vendorDetails={hit.vendorDetails}
            hitType={getAlertTypeBasedOnService(hit.vendorDetails.service)}
            isDisabled={
              hit.decision.status !== 'pending' ||
              (pendingAlertsIds.includes(hit.id) && isMutationPending) ||
              isPendingUpdate
            }
            spektrAI={hit.spektrAIDetails}
            onClickHit={handleClickHit}
            isChecked={isHitSelected(hit.id)}
            onCheckedChange={handleCheckedChange}
            onFalsePositiveClick={handleUpdateHit('false-positive')}
            onTruePositiveClick={handleUpdateHit('true-positive')}
          />
        );
      })}
    </div>
  );
};
