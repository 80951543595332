import { z } from 'zod';

export const serviceVariantColors = [
  'white',
  'bodacc',
  'companiesHouse',
  'complyAdvantage',
  'creditSafe',
  'dowJones',
  'dunBradstreet',
  'kyckr',
  'lexisNexis',
  'openCorporates',
  'transUnion',
  'veriff',
  'veriffIdv',
  'mitId',
  'slack',
  'virk',
] as const;
export const ServiceVariantColors = z.enum(serviceVariantColors);
export type ServiceVariantColor = z.infer<typeof ServiceVariantColors>;

export type ServiceVariantProps = {
  color?: ServiceVariantColor;
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'default' | 'sm' | 'lg';
};
