import { ErrorPage } from '@spektr/shared/components';

import { ContentHeader } from '../ContentHeader';

export namespace DomainApiError {
  export type Props = {
    errorMsg: string;
  };
}

export const DomainApiError = ({ errorMsg }: DomainApiError.Props) => {
  return (
    <div className="flex flex-col gap-4 p-6">
      <ContentHeader
        title="Use my own custom domain for forms"
        subtitle="You can set up your own domain below"
      />
      <ErrorPage title={errorMsg} />
    </div>
  );
};
