import React from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

export type StatusWithIconProps = VariantProps<typeof variants> & {
  label: string;
  icon: React.ReactNode;
  endIcon?: React.ReactNode;
  className?: string;
};

export const StatusWithIcon = ({
  color,
  label,
  icon,
  endIcon,
  className,
}: StatusWithIconProps) => (
  <span
    className={cn(
      'flex items-center gap-2',
      'text-xs font-medium capitalize leading-3',
      variants({ color }),
      className
    )}
  >
    {icon}
    {label}
    {endIcon && <div className="ml-auto">{endIcon}</div>}
  </span>
);

const variants = cva(['w-fit px-2 py-1', 'rounded-md'], {
  variants: {
    color: {
      success:
        'dark:bg-color-secondary-green/10 bg-color-secondary-green/20 dark:text-color-secondary-green text-color-green',
      error: 'dark:bg-color-red/10 bg-color-red/20 text-color-red',
      warning: 'dark:bg-color-yellow/10 bg-color-yellow/20 text-color-yellow',
      default:
        'dark:bg-color-bg-accent bg-color-bg-tertiary text-color-text-error-boundry',
    },
  },
  defaultVariants: {
    color: 'default',
  },
});
