import { Checkbox, Skeleton } from '@spektr/client/components';

export const SkeletonContent = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="space-between bg-color-bg-card-default dark:bg-color-bg-card-accent flex items-center justify-between rounded-md p-4">
        <span className="text-color-text-subtext text-sm font-medium">
          Assign team members
        </span>
        <Skeleton className="h-9 w-72" />
      </div>
      <div className="bg-color-bg-card-default dark:bg-color-bg-card-accent flex flex-col gap-4 rounded-md p-4">
        <p className="text-sm font-medium">
          Add items to your checklist that will show up in case management
        </p>
        <Skeleton className="h-9 w-full" />
        <Skeleton className="h-12 w-full" />
        <Skeleton className="ml-auto h-9 w-28" />
      </div>
      <div className="flex flex-col gap-4">
        <p className="text-sm font-medium">Checklist</p>
        <div className="flex h-44 flex-col gap-2">
          <div className="bg-color-bg-card-default dark:bg-color-bg-card-accent flex items-start gap-4 rounded-md p-5">
            <Checkbox disabled />
            <div className="flex flex-col gap-1.5">
              <Skeleton className="h-4 w-20" />
              <Skeleton className="h-4 w-40" />
            </div>
          </div>
          <div className="bg-color-bg-card-default dark:bg-color-bg-card-accent flex items-start gap-4 rounded-md p-5">
            <Checkbox disabled />
            <div className="flex flex-col gap-1.5">
              <Skeleton className="h-4 w-20" />
              <Skeleton className="h-4 w-40" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
