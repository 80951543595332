import { PlayCircle } from 'lucide-react';

import { cn } from '@spektr/style-utils';
import { useUpdateCurrentRoute } from '@spektr/client/providers';

export const ProductVideoContainer = () => {
  const updateState = useUpdateCurrentRoute();

  return (
    <div className="bg-color-bg-card-default border-color-border-primary flex h-[404px] w-full flex-col gap-6 rounded-md border p-4 pb-8 xl:w-[62%]">
      <div
        className="group relative cursor-pointer"
        onClick={() =>
          updateState({
            state: {
              show_product_video: true,
            },
          })
        }
      >
        <div className="bg-color-bg-accent h-[260px] rounded-md px-6 pt-6">
          <div
            className={cn(
              'overflow-hidden rounded-t-md',
              'h-full',
              'bg-product-video bg-cover',
              'transition-opacity duration-300 ease-in-out group-hover:opacity-80'
            )}
          ></div>
          <PlayCircle
            className={cn(
              'h-16 w-16 stroke-1',
              'text-color-text-icon-secondary',
              'absolute left-1/2 top-[85px] -translate-x-1/2 transform'
            )}
          />
          <div
            className={cn(
              'absolute bottom-8 right-8',
              'rounded-full',
              'h-14 w-14',
              'bg-avatar-jeremy bg-contain'
            )}
          ></div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-color-text-primary max-w-xs text-lg font-medium">
            Take a walkthrough product
            <br />
            tour with our CPO Jeremy
          </h3>
        </div>
        <div className="basis-[54%]">
          <p className="text-color-text-subtext max-w-sm text-sm">
            Let Jeremy give you a guided video tour around our product and how
            you can maximize the value you can get our of the spektr platform.
          </p>
        </div>
      </div>
    </div>
  );
};
