import { createContext } from 'react';

type PublicDomainContextType = {
  publicDomain: string;
  getSkyhubUrl: () => string;
};

export const PublicDomainContext = createContext<PublicDomainContextType>({
  publicDomain: '',
  getSkyhubUrl: () => {
    throw new Error('getSkyhubUrl not implemented');
  },
});
