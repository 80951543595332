import { Suspense } from 'react';
import axios from 'axios';

import { Skeleton, SpektrErrorBoundary } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

import { CreateExportMethod } from '../CreateExportMethod';
import { UpdateExportMethod } from '../UpdateExportMethod/UpdateExportMethod';
import { ExportError } from '../../components/ExportError';

type ExportMethodProps = {
  className?: string;
  workspaceId?: string;
};

export const ExportMethod = ({ className, workspaceId }: ExportMethodProps) => {
  return (
    <div className={cn(className, 'flex flex-col gap-5 rounded-md border p-4')}>
      <p className="text-color-text-primary text-base font-medium">
        Export Method
      </p>
      <SpektrErrorBoundary
        fallbackRender={({ error }) => {
          const genericErrorMessage =
            'Something went wrong when fetching export settings. Please try again.';
          if (axios.isAxiosError(error)) {
            if (error.response?.status === 404) {
              return <CreateExportMethod workspaceId={workspaceId} />;
            }

            return (
              <ExportError>
                {error.response?.data?.message || genericErrorMessage}
              </ExportError>
            );
          }
          return <ExportError>{genericErrorMessage}</ExportError>;
        }}
      >
        <Suspense
          fallback={
            <div className="flex flex-col gap-5">
              <div className="flex items-center gap-2">
                <Skeleton className="h-9 basis-1/2" />
                <Skeleton className="h-9 basis-1/2" />
              </div>
              <Skeleton className="h-9 w-full" />
            </div>
          }
        >
          <UpdateExportMethod workspaceId={workspaceId} />
        </Suspense>
      </SpektrErrorBoundary>
    </div>
  );
};
