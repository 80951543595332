import { cn } from '@spektr/style-utils';
import { Skeleton } from '@spektr/client/components';

import { indicatorValueTypes } from '@spektr/shared/validators';

import { SkeletonIndicator } from './SkeletonIndicator';

export const SkeletonTopbar = () => {
  return (
    <div className="container mx-auto flex h-full flex-1 flex-col p-6">
      <div
        className={cn(
          'bg-color-bg-card-default',
          'border-color-border-primary rounded-md border'
        )}
      >
        <div className="border-color-border-primary flex flex-col gap-4 border-b p-6 lg:flex-row lg:justify-between">
          <div className="flex justify-between gap-2">
            <div className="flex items-center gap-3">
              <Skeleton className="h-9 w-9 shrink-0 rounded-full" />
              <div className="flex flex-col items-start gap-1">
                <Skeleton className="h-5 w-28" />
                <Skeleton className="h-4 w-20" />
              </div>
            </div>

            <div className="flex items-center gap-2">
              <Skeleton className="h-2.5 w-2.5 rounded-full" />
              <Skeleton className="h-4 w-20" />
              <Skeleton className="h-2.5 w-2.5 rounded-full" />
              <Skeleton className="h-4 w-20" />
            </div>
          </div>

          <div className="flex items-center justify-between gap-6">
            <Skeleton className="h-9 w-36" />
            <div className="flex items-center gap-2">
              <Skeleton className="h-9 w-36" />
              <Skeleton className="h-9 w-9" />
              <Skeleton className="h-9 w-9" />
              <Skeleton className="h-9 w-9" />
            </div>
          </div>
        </div>

        <div className="flex h-[172px] flex-col items-center lg:h-[86px] lg:flex-row">
          <div className="flex h-1/2 w-full flex-row lg:h-full lg:w-auto">
            <SkeletonIndicator label="Value" />
            <SkeletonIndicator label="Fraud" />
            <SkeletonIndicator label="Risk" isLast={indicatorValueTypes[1]} />
          </div>
          <div className="flex h-1/2 w-full flex-row lg:h-full">
            <SkeletonIndicator label="ID Verification" isService={true} />
            <SkeletonIndicator label="PEP" isService={true} />
            <SkeletonIndicator label="Sanction" isService={true} />
            <SkeletonIndicator
              label="Adverse Media"
              isService={true}
              isLast={indicatorValueTypes[0]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
