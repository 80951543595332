import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import _isEqual from 'lodash/isEqual';
import { produce } from 'immer';

import { AlertNode, Process } from '@spektr/shared/types';
import { AlertFieldSchema, UserSchema } from '@spektr/shared/validators';

import {
  getAlertFieldsQuery,
  getProcessesQuery,
  getTeamMembersQuery,
} from '@spektr/client/services';

import {
  flattenAlerts,
  getFieldsFromAlerts,
  getFieldsWithoutProperties,
} from '@spektr/client/utils';

import { type AlertField } from '@spektr/client/types';

type ServiceAlertNodeContextType = {
  alerts: AlertFieldSchema[];
  users: UserSchema[];
  allFields: AlertField[];
  fieldsWithoutProperties: AlertField[];
  createAlert: () => void;
  updateAlert: (index: number, alert: Partial<AlertFieldSchema>) => void;
  removeAlert: (index: number) => void;
};

export const ServiceAlertNodeContext =
  createContext<ServiceAlertNodeContextType>({
    alerts: [],
    users: [],
    allFields: [],
    fieldsWithoutProperties: [],
    createAlert: () => null,
    updateAlert: () => null,
    removeAlert: () => null,
  });

export const useServiceAlertNodeContext = () => {
  return useContext(ServiceAlertNodeContext);
};

export type ServiceAlertNodeProviderProps = {
  process: Process;
  node: AlertNode;
  children: React.ReactNode;
  onSave: (alerts: AlertFieldSchema[]) => void;
};

export const ServiceAlertNodeProvider = ({
  process,
  node,
  children,
  onSave,
}: ServiceAlertNodeProviderProps) => {
  const [alerts, updateAlerts] = useState(node.alerts);
  const { data: alertFields } = useSuspenseQuery(
    getAlertFieldsQuery(process.id, node.id)
  );
  const { data: users } = useSuspenseQuery(getTeamMembersQuery());
  const { data: processes } = useSuspenseQuery(getProcessesQuery());
  const prevAlertsRef = useRef(alerts);

  const fields = useMemo(() => {
    const alerts = flattenAlerts(alertFields);

    return getFieldsFromAlerts(alerts, processes);
  }, [alertFields, processes]);

  const fieldsWithoutProperties = useMemo(
    () => getFieldsWithoutProperties(fields),
    [fields]
  );

  const createAlert = useCallback(() => {
    updateAlerts(
      produce((draft) => {
        draft.push({
          processId: '',
          nodeId: '',
          fields: {},
          name: 'New Alert',
        });
      })
    );
  }, []);

  const removeAlert = useCallback((index: number) => {
    updateAlerts(
      produce((draft) => {
        draft.splice(index, 1);
      })
    );
  }, []);

  const updateAlert = useCallback(
    (index: number, alert: Partial<AlertFieldSchema>) => {
      updateAlerts(
        produce((draft) => {
          draft[index] = { ...draft[index], ...alert };
        })
      );
    },
    []
  );

  useEffect(() => {
    if (!_isEqual(prevAlertsRef.current, alerts)) {
      const areAllAlertsValid = alerts.every((alert) => {
        return (
          Object.keys(alert.fields).length > 0 &&
          alert.processId &&
          alert.nodeId
        );
      });

      if (areAllAlertsValid) {
        prevAlertsRef.current = alerts;
        onSave(alerts);
      }
    }
  }, [alerts, onSave]);

  const values = useMemo(() => {
    return {
      alerts: alerts,
      users,
      allFields: fields,
      fieldsWithoutProperties,
      createAlert,
      updateAlert,
      removeAlert,
    };
  }, [
    alerts,
    users,
    fields,
    fieldsWithoutProperties,
    createAlert,
    updateAlert,
    removeAlert,
  ]);

  return (
    <ServiceAlertNodeContext.Provider value={values}>
      {children}
    </ServiceAlertNodeContext.Provider>
  );
};
