import { createContext } from 'react';

import { type ClientTrigger } from '@spektr/client/types';
import {
  type TriggerFrequency,
  type SpektrFieldKey,
} from '@spektr/shared/types';

type TriggerFrequenciesContextApi = {
  triggers: ClientTrigger[];
  updateFrequency: (
    fieldKey: SpektrFieldKey,
    frequency: TriggerFrequency | undefined
  ) => void;
  getTriggerFrequency: (
    fieldKey: SpektrFieldKey
  ) => TriggerFrequency | undefined;
};

export const TriggerFrequenciesContext =
  createContext<TriggerFrequenciesContextApi>({
    triggers: [],
    updateFrequency: () =>
      new Error('updateFrequency not working outside the provider'),
    getTriggerFrequency: () => undefined,
  });
