import { z } from 'zod';

export const AdverseMediaLlmResponse = z.object({
  mediaCategory: z.string(),
  relatedToTarget: z.boolean(),
  relatedToTargetReason: z.string(),
  isAdverseMedia: z.boolean(),
  isAdverseMediaReason: z.string(),
});

export type AdverseMediaLlmResponse = z.infer<typeof AdverseMediaLlmResponse>;
