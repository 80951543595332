import { SpektrFieldWithInfo } from '../../goldenRecord';
import { KyckrNodeOutcomes } from '../../node';

import { commonSpektrFields } from '../commonSpektrFields/commonSpektrFields';

//TODO:  [ST-1237] Label and help are just here for backwards compatibility, they should be removed when the label and help are moved to a localization file and retrieved by the field key

export const representativesIndividualsFields: Array<
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = [
  {
    key: 'is_representative_a_company',
    type: 'boolean',
    source: 'kyckr',
    label: 'is_representative_a_company',
    help: 'Get if the representative is a company.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    group: 'individual_representatives',
  },
  {
    key: 'individuals.name',
    type: 'string',
    source: 'kyckr',
    label: 'individuals.name',
    help: 'Get the name of the individual.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    commonSpektrFieldKeys: [commonSpektrFields.fullName.key],
    group: 'individual_representatives',
  },
  {
    key: 'individuals.role.normalized',
    type: 'string',
    source: 'kyckr',
    label: 'individuals.role.normalized',
    help: 'Get the role of the individual.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    commonSpektrFieldKeys: [commonSpektrFields.occupation.key],
    group: 'individual_representatives',
  },
  {
    key: 'individuals.startDate.normalized',
    type: 'string',
    source: 'kyckr',
    label: 'individuals.startDate.normalized',
    help: 'Get the start date of the individual.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    commonSpektrFieldKeys: [commonSpektrFields.occupationStartDate.key],
    group: 'individual_representatives',
  },
  {
    key: 'individuals.isActive',
    type: 'boolean',
    source: 'kyckr',
    label: 'individuals.isActive',
    help: 'Get if the individual is active.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    commonSpektrFieldKeys: [commonSpektrFields.occupationIsActive.key],
    group: 'individual_representatives',
  },
  {
    key: 'individuals.powers',
    type: 'string',
    source: 'kyckr',
    label: 'individual.powers',
    help: 'Get the powers of the individual.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    group: 'individual_representatives',
  },
  {
    key: 'individuals.birthdate.normalized',
    type: 'string',
    source: 'kyckr',
    label: 'individuals.birthdate.normalized',
    help: 'Get the birthdate of the individual.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    commonSpektrFieldKeys: [commonSpektrFields.dateOfBirth.key],
    group: 'individual_representatives',
  },
  {
    key: 'individuals.nationality',
    type: 'string',
    source: 'kyckr',
    label: 'individuals.nationality',
    help: 'Get the nationality of the individual',
    availableIn: [KyckrNodeOutcomes.Representatives],
    commonSpektrFieldKeys: [commonSpektrFields.nationality.key],
    group: 'individual_representatives',
  },
  {
    key: 'individuals.placeOfResidence.fullAddress',
    type: 'string',
    source: 'kyckr',
    label: 'individuals.placeOfResidence.fullAddress',
    help: 'Get the full address of the place of residence of the individual.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    commonSpektrFieldKeys: [commonSpektrFields.fullAddress.key],
    group: 'individual_representatives',
  },
];

export const representativesCorporationsFields: Array<
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = [
  {
    key: 'corporations.name',
    type: 'string',
    source: 'kyckr',
    label: 'corporations.name',
    help: 'Get the name of the corporation.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    commonSpektrFieldKeys: [commonSpektrFields.companyName.key],
    group: 'corporate_representatives',
  },
  {
    key: 'corporations.role.normalized',
    type: 'string',
    source: 'kyckr',
    label: 'corporations.role.normalized',
    help: 'Get the role of the corporation.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    commonSpektrFieldKeys: [commonSpektrFields.position.key],
    group: 'corporate_representatives',
  },
  {
    key: 'corporations.startDate.normalized',
    type: 'string',
    source: 'kyckr',
    label: 'corporations.startDate.normalized',
    help: 'Get the start date of the corporation.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    commonSpektrFieldKeys: [commonSpektrFields.positionStartDate.key],
    group: 'corporate_representatives',
  },
  {
    key: 'corporations.isActive',
    type: 'boolean',
    source: 'kyckr',
    label: 'corporations.isActive',
    help: 'Get if the corporation is active.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    commonSpektrFieldKeys: [commonSpektrFields.positionIsActive.key],
    group: 'corporate_representatives',
  },
  {
    key: 'corporations.powers',
    type: 'string',
    source: 'kyckr',
    label: 'corporations.powers',
    help: 'Get the powers of the corporation.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    group: 'corporate_representatives',
  },
  {
    key: 'corporations.address.fullAddress',
    type: 'string',
    source: 'kyckr',
    label: 'corporations.address.fullAddress',
    help: 'Get the full address of the corporation.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    commonSpektrFieldKeys: [commonSpektrFields.fullAddress.key],
    group: 'corporate_representatives',
  },
  {
    key: 'corporations.registrationNumber',
    type: 'string',
    source: 'kyckr',
    label: 'corporations.registrationNumber',
    help: 'Get the registration number of the corporation.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    commonSpektrFieldKeys: [commonSpektrFields.companyNumber.key],
    group: 'corporate_representatives',
  },
  {
    key: 'corporations.registrationAuthority',
    type: 'string',
    source: 'kyckr',
    label: 'corporations.registrationAuthority',
    help: 'Get the registration authority of the corporation.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    commonSpektrFieldKeys: [commonSpektrFields.registrationAuthority.key],
    group: 'corporate_representatives',
  },
  {
    key: 'corporations.registeredAddress.fullAddress',
    type: 'string',
    source: 'kyckr',
    label: 'corporations.registeredAddress.fullAddress',
    help: 'Get the full address of the registered address of the corporation.',
    availableIn: [KyckrNodeOutcomes.Representatives],
    commonSpektrFieldKeys: [commonSpektrFields.registrationFullAddress.key],
    group: 'corporate_representatives',
  },
];

export const uboFirstLayerFields: Array<Omit<SpektrFieldWithInfo, 'sourceId'>> =
  [
    {
      key: 'is_owner_a_company',
      type: 'boolean',
      source: 'kyckr',
      label: 'is_owner_a_company',
      help: 'Get if the owner is a company.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.percentage',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.percentage',
      help: 'Get the shareholding percentage of this UBO',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.shareholdingPercentage.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.type',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.shareholders.individuals.type',
      help: 'Get the type of the individual UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.entityType.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.name',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.shareholders.individuals.name',
      help: 'Get the name of the individual UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.fullName.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.placeOfResidence.rawAddressLines',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.individuals.placeOfResidence.rawAddressLines',
      help: 'Get the raw address lines for the place of residence of the individual UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.placeOfResidence.fullAddress',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.individuals.placeOfResidence.fullAddress',
      help: 'Get the full address for the place of residence of the individual UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.fullAddress.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.placeOfResidence.type',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.shareholders.individuals.placeOfResidence.type',
      help: 'Get the type for the place of residence of the individual UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.placeOfResidence.buildingName',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.individuals.placeOfResidence.buildingName',
      help: 'Get the buildingName for the place of residence of the individual UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.buildingName.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.placeOfResidence.streetNumber',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.individuals.placeOfResidence.streetNumber',
      help: 'Get the street number for the place of residence of the individual UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.streetNumber.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.placeOfResidence.streetName',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.individuals.placeOfResidence.streetName',
      help: 'Get the street name for the place of residence of the individual UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.streetName.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.placeOfResidence.city',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.shareholders.individuals.placeOfResidence.city',
      help: 'Get the city for the place of residence of the individual UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.city.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.placeOfResidence.postcode',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.shareholders.individuals.placeOfResidence.postcode',
      help: 'Get the postcode for the place of residence of the individual UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.postcode.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.placeOfResidence.municipality',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.individuals.placeOfResidence.municipality',
      help: 'Get the municipality for the place of residence of the individual UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.municipality.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.placeOfResidence.region',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.shareholders.individuals.placeOfResidence.region',
      help: 'Get the region for the place of residence of the individual UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.region.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.placeOfResidence.country',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.shareholders.individuals.placeOfResidence.country',
      help: 'Get the country for the place of residence of the individual UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.country.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.placeOfResidence.secondaryPostalCode',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.individuals.placeOfResidence.secondaryPostalCode',
      help: 'Get the secondary postal code for the place of residence of the individual UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.birthdate',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.shareholders.individuals.birthdate',
      help: 'Get the birthdate of the individual UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.dateOfBirth.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.individuals.nationality',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.shareholders.individuals.nationality',
      help: 'Get the nationality of the individual UBO',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.nationality.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.corporations.type',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.shareholders.corporations.type',
      help: 'Get the type of the corporation UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.entityType.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.corporations.name',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.shareholders.corporations.name',
      help: 'Get the name of the corporation UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.companyName.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.corporations.registeredAddress.rawAddressLines',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.corporations.registeredAddress.rawAddressLines',
      help: 'Get the raw address lines for the place of residence of the corporation UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.corporations.registeredAddress.fullAddress',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.corporations.registeredAddress.fullAddress',
      help: 'Get the full address for the place of residence of the corporation UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.fullAddress.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.corporations.registeredAddress.type',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.shareholders.corporations.registeredAddress.type',
      help: 'Get the type for the place of residence of the corporation UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.corporations.registeredAddress.buildingName',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.corporations.registeredAddress.buildingName',
      help: 'Get the buildingName for the place of residence of the corporation UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.buildingName.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.corporations.registeredAddress.streetNumber',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.corporations.registeredAddress.streetNumber',
      help: 'Get the street number for the place of residence of the corporation UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.streetNumber.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.corporations.registeredAddress.streetName',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.corporations.registeredAddress.streetName',
      help: 'Get the street name for the place of residence of the corporation UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.streetName.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.corporations.registeredAddress.city',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.shareholders.corporations.registeredAddress.city',
      help: 'Get the city for the place of residence of the corporation UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.city.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.corporations.registeredAddress.postcode',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.corporations.registeredAddress.postcode',
      help: 'Get the postcode for the place of residence of the corporation UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.postcode.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.corporations.registeredAddress.municipality',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.corporations.registeredAddress.municipality',
      help: 'Get the municipality for the place of residence of the corporation UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.municipality.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.corporations.registeredAddress.region',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.shareholders.corporations.registeredAddress.region',
      help: 'Get the region for the place of residence of the corporation UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.region.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.corporations.registeredAddress.country',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.corporations.registeredAddress.country',
      help: 'Get the country for the place of residence of the corporation UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.country.key],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.corporations.registeredAddress.secondaryPostalCode',
      type: 'string',
      source: 'kyckr',
      label:
        'shareholdings.shareholders.corporations.registeredAddress.secondaryPostalCode',
      help: 'Get the secondary postal code for the place of residence of the corporation UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      group: 'ubo_first_layer_of_ownership',
    },
    {
      key: 'shareholdings.shareholders.corporations.registrationNumber',
      type: 'string',
      source: 'kyckr',
      label: 'shareholdings.shareholders.corporations.registrationNumber',
      help: 'Get the registration number of the corporation UBO.',
      availableIn: [KyckrNodeOutcomes.FirstLayerOfOwnership],
      commonSpektrFieldKeys: [commonSpektrFields.companyNumber.key],
      group: 'ubo_first_layer_of_ownership',
    },
  ];
export const parentProcessFields: Array<Omit<SpektrFieldWithInfo, 'sourceId'>> =
  [
    {
      key: 'hasCorporateOwners',
      type: 'boolean',
      source: 'kyckr',
      label: 'hasCorporateOwners',
      help: 'Get if the company has corporate owners.',
    },
    {
      key: 'hasIndividualOwnersOnly',
      type: 'boolean',
      source: 'kyckr',
      label: 'hasIndividualOwnersOnly',
      help: 'Get if the company has individual owners only.',
    },
    {
      key: 'hasCorporateRepresentatives',
      type: 'boolean',
      source: 'kyckr',
      label: 'hasCorporateRepresentatives',
      help: 'Get if the company has corporate representatives.',
    },
    {
      key: 'hasIndividualRepresentatives',
      type: 'boolean',
      source: 'kyckr',
      label: 'hasIndividualRepresentatives',
      help: 'Get if the company has individual representatives.',
    },
    {
      key: 'number_of_UBOs',
      type: 'number',
      source: 'kyckr',
      label: 'number_of_UBOs',
      help: 'Get the number of UBOs of the company.',
    },
    {
      key: 'activities.code',
      type: 'string',
      source: 'kyckr',
      label: 'activities.code',
      help: 'Get the code of the activity.',
    },
    {
      key: 'activities.description',
      type: 'string',
      source: 'kyckr',
      label: 'activities.description',
      help: 'Get the description of the activity.',
    },
    {
      key: 'addresses.fullAddress',
      type: 'string',
      source: 'kyckr',
      label: 'addresses.fullAddress',
      help: 'Get the full address of the company.',
      commonSpektrFieldKeys: [commonSpektrFields.fullAddress.key],
    },
    {
      key: 'addresses.country',
      type: 'string',
      source: 'kyckr',
      label: 'addresses.country',
      help: 'Get the country of the address.',
      commonSpektrFieldKeys: [commonSpektrFields.country.key],
    },
    {
      key: 'contactDetails.email',
      type: 'string',
      source: 'kyckr',
      label: 'contactDetails.email',
      help: 'Get the email of the company.',
      commonSpektrFieldKeys: [commonSpektrFields.email.key],
    },
    {
      key: 'contactDetails.telNumber',
      type: 'string',
      source: 'kyckr',
      label: 'contactDetails.telNumber',
      help: 'Get the telephone number of the company.',
      commonSpektrFieldKeys: [commonSpektrFields.phoneNumber.key],
    },
    {
      key: 'contactDetails.website',
      type: 'string',
      source: 'kyckr',
      label: 'contactDetails.website',
      help: 'Get the website of the company.',
      commonSpektrFieldKeys: [commonSpektrFields.website.key],
    },
    {
      key: 'identifiers.primaryRegistrationNumber',
      type: 'string',
      source: 'kyckr',
      label: 'identifiers.primaryRegistrationNumber',
      help: 'Get the primary registration number of the company.',
      commonSpektrFieldKeys: [commonSpektrFields.companyNumber.key],
    },
    {
      key: 'identifiers.taxNumber',
      type: 'string',
      source: 'kyckr',
      label: 'identifiers.taxNumber',
      help: 'Get the tax number of the company.',
      commonSpektrFieldKeys: [commonSpektrFields.taxNumber.key],
    },
    {
      key: 'companyName',
      type: 'string',
      source: 'kyckr',
      label: 'companyName',
      help: 'Get the name of the company.',
      commonSpektrFieldKeys: [commonSpektrFields.companyName.key],
    },
    {
      key: 'tradingName',
      type: 'string',
      source: 'kyckr',
      label: 'tradingName',
      help: 'Get the trading name of the company.',
    },
    {
      key: 'englishName',
      type: 'string',
      source: 'kyckr',
      label: 'englishName',
      help: 'Get the English name of the company.',
    },
    {
      key: 'registrationAuthority',
      type: 'string',
      source: 'kyckr',
      label: 'registrationAuthority',
      help: 'Get the registration authority of the company.',
      commonSpektrFieldKeys: [commonSpektrFields.registrationAuthority.key],
    },
    {
      key: 'foundationDate.normalized',
      type: 'string',
      source: 'kyckr',
      label: 'foundationDate.normalized',
      help: 'Get the foundation date of the company.',
      commonSpektrFieldKeys: [commonSpektrFields.foundationDate.key],
    },
    {
      key: 'registrationDate.normalized',
      type: 'string',
      source: 'kyckr',
      label: 'registrationDate.normalized',
      help: 'Get the registration date of the company.',
      commonSpektrFieldKeys: [commonSpektrFields.registrationDate.key],
    },
    // {
    //   key: 'incorporationDate.normalized',
    //   type: 'string',
    //   source: 'kyckr',
    //   label: 'incorporationDate.normalized',
    //   help: 'Get the normalized incorporation date of the company.',
    //   commonSpektrFieldKeys: [commonSpektrFields.incorporationDate.key],
    // },
    {
      key: 'dissolutionDate.normalized',
      type: 'string',
      source: 'kyckr',
      label: 'dissolutionDate.normalized',
      help: 'Get the dissolution date of the company.',
      commonSpektrFieldKeys: [commonSpektrFields.dissolutionDate.key],
    },
    {
      key: 'status.normalized',
      type: 'string',
      source: 'kyckr',
      label: 'status.normalized',
      help: 'Get the status of the company.',
      commonSpektrFieldKeys: [commonSpektrFields.companyStatus.key],
    },
  ];

export const uboAllPossibleLayersFields: Array<
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = [
  {
    key: 'name',
    type: 'string',
    source: 'kyckr',
    label: 'name',
    help: 'Gets the name of the owner',
    availableIn: [KyckrNodeOutcomes.AllPotentialLayersOfOwnership],
    commonSpektrFieldKeys: [commonSpektrFields.fullName.key],
    group: 'ubo_all_layers_of_ownership',
  },
  {
    key: 'percentage',
    type: 'number',
    source: 'kyckr',
    label: 'percentage',
    help: 'Gets the percentage of ownership for the owner',
    availableIn: [KyckrNodeOutcomes.AllPotentialLayersOfOwnership],
    commonSpektrFieldKeys: [commonSpektrFields.shareholdingPercentage.key],
    group: 'ubo_all_layers_of_ownership',
  },
];

export const otherCalculatedFields: Array<
  Omit<SpektrFieldWithInfo, 'sourceId'>
> = [
  {
    key: 'kyckr_service_failure',
    type: 'boolean',
    source: 'kyckr',
    label: 'Kyckr service call failed',
  },
];

export const kyckrSpektrFields = [
  ...parentProcessFields,
  ...representativesIndividualsFields,
  ...representativesCorporationsFields,
  ...uboFirstLayerFields,
  ...uboAllPossibleLayersFields,
  ...otherCalculatedFields,
];
