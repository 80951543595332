import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { type ServiceFieldClient } from '@spektr/client/types';
import { type ServiceNode, type SpektrFieldKey } from '@spektr/shared/types';

import { getRootFields } from '../utils/getRootFields';

export function useServiceFields(node: ServiceNode) {
  const { t, i18n } = useTranslation();

  const translatedFields = useMemo(
    () =>
      Object.entries(node.fields)
        .map<ServiceFieldClient>(([key, value]) => {
          const helpTranslationKey = `fields:${node.nodeType}.${key}.help`;
          const help = i18n.exists(helpTranslationKey)
            ? t(helpTranslationKey)
            : '';
          return {
            key: key as SpektrFieldKey,
            label: t(`fields:${node.nodeType}.${key}`),
            value,
            help,
          };
        })
        .filter((field) => !!field.label),
    [node.fields, node.nodeType, t, i18n]
  );
  const rootServiceFields = useMemo(
    () => getRootFields(translatedFields),
    [translatedFields]
  );

  return useMemo(
    () => ({ fields: translatedFields, serviceFields: rootServiceFields }),
    [rootServiceFields, translatedFields]
  );
}
