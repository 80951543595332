import { Skeleton } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';
import {
  IndicatorValueType,
  indicatorValueTypes,
} from '@spektr/shared/validators';

type SkeletonIndicatorProps = {
  label: string;
  isService?: boolean;
  isLast?: IndicatorValueType | null;
};

export const SkeletonIndicator = ({
  label,
  isService = false,
  isLast = null,
}: SkeletonIndicatorProps) => {
  return (
    <div
      className={cn(
        'flex w-32 flex-grow flex-col items-center justify-center',
        'border-color-border-primary h-full border-r',
        !isService && 'border-b lg:border-b-transparent',
        isLast &&
          isLast === indicatorValueTypes[1] &&
          'lg:border-r-color-border-primary border-r-transparent',
        isLast && isLast === indicatorValueTypes[0] && 'border-r-transparent'
      )}
    >
      {isService && <Skeleton className="mb-1.5 h-3.5 w-3.5 rounded-full" />}
      <span className="text-color-text-subtext text-sm">{label}</span>
      {!isService && <Skeleton className="h-5 w-20" />}
    </div>
  );
};
