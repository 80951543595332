import { cn } from '@spektr/style-utils';

export type SectionListProps<T> = {
  className?: string;
  data: T[];
  children: (item: T) => React.ReactNode;
};

export const SectionList = <T extends Record<string, unknown>>({
  className,
  data,
  children,
}: SectionListProps<T>) => (
  <div className={cn('flex flex-col', 'rounded-md border', className)}>
    {data.map((item) => (
      <div
        key={JSON.stringify(item)}
        className={cn(
          'flex h-auto shrink-0 items-center justify-start p-4',
          'border-b',
          'text-left',
          'transition-colors',
          'last:border-b-0'
        )}
      >
        {children(item)}
      </div>
    ))}
  </div>
);
