import { useParams } from 'react-router-dom';
import { QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';

import {
  ProcessApiClient,
  ALERTS_KEYS,
  CUSTOMERS_KEYS,
  DATASETS_KEYS,
} from '@spektr/client/services';
import { invalidateMultipleQueries } from '@spektr/client/utils';

import { UpdateHitMutationArg } from '../types';

export function useUpdateHit(datasetId: string, spektrId: string) {
  const queryClient = useQueryClient();
  const { spektrId: customerId } = useParams();

  return useMutation({
    mutationFn: async ({
      alertId,
      hitId,
      resetList,
      ...body
    }: UpdateHitMutationArg) => {
      await ProcessApiClient.getClient().updateAlertHitById(body, {
        params: {
          alertId,
          hitId: hitId,
        },
      });

      const queriesToInvalidate: { queryKey: QueryKey }[] = [
        {
          queryKey: ALERTS_KEYS.BY_ID(alertId),
        },
        { queryKey: DATASETS_KEYS.ALL_ALERTS(datasetId, spektrId) },
      ];

      if (resetList) {
        queriesToInvalidate.push({
          queryKey: customerId
            ? ALERTS_KEYS.ALL_BY_CUSTOMER(customerId)
            : ALERTS_KEYS.ALL(),
        });

        await queryClient.resetQueries({
          queryKey: CUSTOMERS_KEYS.BY_ID(spektrId),
        });
      }

      await invalidateMultipleQueries(queryClient, queriesToInvalidate);
    },
  });
}
