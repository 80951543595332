import { FileText } from 'lucide-react';

import { IconBox } from '@spektr/client/components';

export type FormActionNodeProps = {
  nodeTitle: string;
};

export const FormActionNode = ({ nodeTitle }: FormActionNodeProps) => {
  return (
    <>
      <IconBox
        size="lg"
        color="cyan"
        renderIcon={({ className }) => <FileText className={className} />}
      />
      <div className="flex flex-col">
        <h3 className="text-color-text-node line-clamp-1 text-sm font-medium">
          {nodeTitle}
        </h3>
        <span className="text-color-text-node-subtext text-xs">Form</span>
      </div>
    </>
  );
};
