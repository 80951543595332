import { Suspense } from 'react';
import { FilePlus } from 'lucide-react';

import { useGoToConnectionHub } from '@spektr/shared/hooks';

import { BasicDialog, SpektrErrorBoundary } from '@spektr/client/components';

import { RecordsProvider } from './providers';
import { RecordsTable, SkeletonRecords, TableToolbar } from './components';

export const ConnectionHubDatasetRecordsDialog = () => {
  const goToConnectionHub = useGoToConnectionHub();

  const handleClose = () => goToConnectionHub();

  return (
    <BasicDialog
      className="h-full max-h-[80%] max-w-[90%]"
      icon={<FilePlus className="stroke-color-yellow mr-3 h-5 w-5" />}
      defaultOpen
      title="Dataset records"
      onClose={handleClose}
    >
      <SpektrErrorBoundary fallbackRender={() => <div />}>
        <RecordsProvider>
          <Suspense fallback={<SkeletonRecords />}>
            <TableToolbar />
            <RecordsTable />
          </Suspense>
        </RecordsProvider>
      </SpektrErrorBoundary>
    </BasicDialog>
  );
};
