import { cn } from '@spektr/style-utils';

import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@spektr/client/components';

export const SkeletonExecutionsTable = () => (
  <Table className="border-collapse">
    <TableHeader>
      <TableRow className="hover:bg-inherit">
        <TableHead
          className={cn(
            'rounded-tl-lg border',
            'px-4',
            'text-color-text-subtext text-xs'
          )}
        >
          Customer
        </TableHead>
        <TableHead className="text-color-text-subtext border px-4 text-xs">
          Latest run
        </TableHead>
        <TableHead className="text-color-text-subtext border px-4 text-xs">
          Run started at
        </TableHead>
        <TableHead className="text-color-text-subtext border px-4 text-xs">
          Run finished at
        </TableHead>
        <TableHead className="text-color-text-subtext border px-4 text-xs">
          Score
        </TableHead>
        <TableHead className="text-color-text-subtext border px-4 text-xs">
          Output
        </TableHead>
        <TableHead
          className={cn(
            'rounded-tr-lg border border-l-0',
            'px-4',
            'text-color-text-subtext text-xs'
          )}
        >
          Status
        </TableHead>
      </TableRow>
    </TableHeader>
    <TableBody>
      <TableRow className="border-b">
        <TableCell className={cn('border-x', 'p-4')}>
          <div className="flex items-center gap-3">
            <Skeleton className="h-8 w-8 rounded-full" />
            <div className="flex flex-col gap-2">
              <Skeleton className="h-3 w-24" />
              <Skeleton className="h-2 w-32" />
            </div>
          </div>
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-3 w-48" />
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-3 w-32" />
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-3 w-32" />
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-3 w-24" />
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-3 w-24" />
        </TableCell>
        <TableCell className={cn('border-r', 'p-4')}>
          <Skeleton className="h-3 w-16 rounded-full" />
        </TableCell>
      </TableRow>
      <TableRow className="border-b">
        <TableCell className={cn('border-x', 'p-4')}>
          <div className="flex items-center gap-3">
            <Skeleton className="h-8 w-8 rounded-full" />
            <div className="flex flex-col gap-2">
              <Skeleton className="h-3 w-24" />
              <Skeleton className="h-2 w-32" />
            </div>
          </div>
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-3 w-48" />
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-3 w-32" />
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-3 w-32" />
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-3 w-24" />
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-3 w-24" />
        </TableCell>
        <TableCell className="border-r p-4">
          <Skeleton className="h-3 w-16 rounded-full" />
        </TableCell>
      </TableRow>
      <TableRow className="border-b">
        <TableCell className={cn('rounded-bl-lg border-x', 'p-4')}>
          <div className="flex items-center gap-3">
            <Skeleton className="h-8 w-8 rounded-full" />
            <div className="flex flex-col gap-2">
              <Skeleton className="h-3 w-24" />
              <Skeleton className="h-2 w-32" />
            </div>
          </div>
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-3 w-48" />
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-3 w-32" />
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-3 w-32" />
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-3 w-24" />
        </TableCell>
        <TableCell className="p-4">
          <Skeleton className="h-3 w-24" />
        </TableCell>
        <TableCell className={cn('rounded-br-lg border-r', 'p-4')}>
          <Skeleton className="h-3 w-16 rounded-full" />
        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);
