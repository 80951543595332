import { RouteObject } from 'react-router-dom';

import {
  MATRIX_DASHBOARD_URL,
  MATRIX_NEW_URL,
  NEW_PROCESS_URL,
  PROCESS_DASHBOARD_URL,
  MATRIX_URL,
  MATRIX_TEMPLATES_URL,
  PROCESS_RUNS_URL,
} from '@spektr/shared/utils';

import {
  RiskMatrixDashboardPage,
  CreateRiskMatrix,
  UpdateRiskMatrixDialog,
  RiskMatrixTemplates,
} from '../risk-matrix';

import { ProcessRunsDashboardPage } from '../runs';

import { ProcessesIndexPage } from './IndexPage';
import { ProcessDashboardPage } from './ProcessDashboard';
import { CreateProcessBuilderDialog } from './new';
import { processBuilderIndexRoute } from './:id';

export const processBuilderRoute: RouteObject = {
  path: PROCESS_DASHBOARD_URL,
  element: <ProcessesIndexPage />,
  handle: {
    title: 'Processes',
  },
  children: [
    {
      index: true,
      id: 'process-dashboard',
      element: <ProcessDashboardPage />,
    },
    {
      path: NEW_PROCESS_URL,
      element: <CreateProcessBuilderDialog />,
    },
    {
      path: MATRIX_DASHBOARD_URL,
      element: <RiskMatrixDashboardPage />,
      children: [
        {
          path: MATRIX_NEW_URL,
          element: <CreateRiskMatrix />,
        },
        {
          path: MATRIX_URL,
          element: <UpdateRiskMatrixDialog />,
        },
        {
          path: MATRIX_TEMPLATES_URL,
          element: <RiskMatrixTemplates />,
        },
      ],
    },
    {
      path: PROCESS_RUNS_URL,
      element: <ProcessRunsDashboardPage />,
    },

    processBuilderIndexRoute,
  ],
};
