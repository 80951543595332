import { z } from 'zod';

import { objectIdSchema } from '../common';
import { NodeType } from '../node';

export const VendorConsumerStartDownstreamCallMessage = z.strictObject({
  type: z.literal('new'),
  vendor: NodeType,
  executionContextId: objectIdSchema,
  vendorSearchId: objectIdSchema,
  workspaceId: z.string().min(1),
  processRunId: z.string().optional(),
  versionId: z.string().min(1).nullable(),
  processId: z.string().min(1),
  pollingId: z.string().optional(),
  mode: z.enum(['sandbox', 'live']),
  retries: z.union([z.number(), z.literal('none')]), // number of retries already made for this message. Should start at 0. If "none" then no retries will be made.
});

export type VendorConsumerStartDownstreamCallMessage = z.infer<
  typeof VendorConsumerStartDownstreamCallMessage
>;

const preprocessRawMessageValue = (data: unknown) => {
  const stringified = data instanceof Buffer ? data.toString() : data;

  if (typeof stringified !== 'string') return stringified;

  try {
    return JSON.parse(stringified);
  } catch {
    return data;
  }
};

export const VendorConsumerRawMessage = z.preprocess(
  preprocessRawMessageValue,
  VendorConsumerStartDownstreamCallMessage
);
