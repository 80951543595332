import { cva } from 'class-variance-authority';

export const selectVariants = cva('', {
  variants: {
    size: {
      default: 'px-3 py-2 h-9 text-sm',
      small: 'px-2 py-1 h-7 text-xs',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});
