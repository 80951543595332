import { useMemo } from 'react';

import { AlertBaseWithHits } from '@spektr/shared/validators';

import { PaginationCombobox } from '@spektr/client/components';

import { usePaginationOptions } from '@spektr/platform-hooks';

import { HitsProvider } from '../../AlertDetails/providers';
import { useUpdateHit } from '../../AlertDetails/hooks';
import {
  computeExtendedHits,
  getAlertTypeBasedOnService,
} from '../../AlertDetails/utils';
import { UpdateHitMutationArg } from '../../AlertDetails/types';

import { HitsList } from '../../AlertDetails/components/HitsList';
import { HitsLayout } from '../../AlertDetails/components/HitsLayout';
import { MultipleHitsResolver } from '../../AlertDetails/components/MultipleHitsResolver';

type HitsContainerProps = {
  alert: AlertBaseWithHits;
};

export const HitsContainer = ({ alert }: HitsContainerProps) => {
  const { searchParams, page, setPage, pageLimit, setLimit } =
    usePaginationOptions();

  const hitsWithExtendedInfo = useMemo(
    () => computeExtendedHits(alert.hits, alert),
    [alert]
  );

  const updateHitMutation = useUpdateHit(alert.datasetId, alert.spektrId);

  const handleUpdateHit = async (hitUpdateInput: UpdateHitMutationArg) => {
    const unresolvedHitsCount = alert.hits.filter(
      (hit) => hit.decision.status === 'pending'
    ).length;

    await updateHitMutation.mutateAsync({
      ...hitUpdateInput,
      resetList: unresolvedHitsCount === 1 || alert.status === 'open',
    });
  };

  const service = searchParams.get('service');
  const field = searchParams.get('field');
  const totalPages = Math.ceil(alert.hits.length / pageLimit);

  const paginatedData = useMemo(() => {
    const start = (page - 1) * pageLimit;
    const end = start + pageLimit;
    let filteredData = [...alert.hits];

    if (service) {
      if (field) {
        filteredData = filteredData.filter(
          (hit) =>
            hit.vendorDetails.service === service &&
            hit.vendorDetails.field === field
        );
      } else {
        filteredData = filteredData.filter(
          (hit) => hit.vendorDetails.service === service
        );
      }
    }

    return filteredData.slice(start, end);
  }, [alert.hits, field, page, pageLimit, service]);

  return (
    <HitsProvider
      key={field}
      hits={hitsWithExtendedInfo}
      alertStatus={alert.status}
      datasetId={alert.datasetId}
      spektrId={alert.spektrId}
      alertType={getAlertTypeBasedOnService(
        alert.hits[0].vendorDetails.service
      )}
    >
      <HitsLayout>
        <HitsList
          data={paginatedData}
          isMutationPending={updateHitMutation.isPending}
          onUpdateHit={handleUpdateHit}
        />
        <PaginationCombobox
          currentPage={page}
          totalPages={totalPages}
          pageLimit={pageLimit}
          onChangeLimit={setLimit}
          onChangePage={setPage}
        />
      </HitsLayout>
      <MultipleHitsResolver />
    </HitsProvider>
  );
};
