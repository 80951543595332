import { z } from 'zod';

import { FieldConfig, FieldUiConfig } from './FieldConfig';

export const BaseField = z.object({
  id: z.string(),
  config: FieldConfig,
  isStructured: z.boolean().default(false),
  ui: FieldUiConfig.optional(),
});

export type BaseField = z.infer<typeof BaseField>;
