import { Suspense } from 'react';
import { useMeasure } from 'react-use';
import { ServerCrash } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import {
  ScrollArea,
  SpektrErrorBoundary,
  Tabs,
  TabsList,
  TabsTrigger,
} from '@spektr/client/components';

import { useServiceCatalogFilters } from './hooks/useServiceCatalogFilters';
import { ServiceList } from './containers/ServiceList';
import { SkeletonServiceList } from './components/SkeletonServiceList';

export const ConnectionHubServiceCatalog = () => {
  const { t } = useTranslation('vendors');
  const { states, stateFilter, setStateFilter } = useServiceCatalogFilters();

  const [ref, size] = useMeasure<HTMLDivElement>();

  return (
    <>
      <div className="mb-6 flex justify-between">
        <span className="text-lg">Services</span>

        <Tabs value={stateFilter} onValueChange={setStateFilter}>
          <TabsList utility="filters">
            {states.map((state) => (
              <TabsTrigger key={state} value={state} utility="filter">
                {t(`stateFilters.${state}`)}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
      </div>

      <div ref={ref} className="relative h-full w-full overflow-hidden">
        <div className="absolute inset-0">
          <ScrollArea
            style={{
              height: size.height,
            }}
          >
            <SpektrErrorBoundary
              fallbackRender={() => (
                <div className="flex flex-col items-center justify-center gap-2 p-8">
                  <ServerCrash className="stroke-color-cyan h-8 w-8" />
                  <span className="text-color-text-error-boundry">
                    There was an error while loading the services.
                  </span>
                </div>
              )}
            >
              <Suspense fallback={<SkeletonServiceList />}>
                <ServiceList stateFilter={stateFilter} />
              </Suspense>
            </SpektrErrorBoundary>
          </ScrollArea>
        </div>
      </div>
    </>
  );
};
