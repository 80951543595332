import { useMutation, useQueryClient } from '@tanstack/react-query';

import { InsightsApiClient, CUSTOMERS_KEYS } from '@spektr/client/services';

export const useDeleteCustomer = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  const deleteCustomerMutation = useMutation({
    mutationFn: async (customerId: string) => {
      await InsightsApiClient.getClient().deleteClientRecordById(undefined, {
        params: {
          clientRecordId: customerId,
        },
      });
    },
    onSuccess: async () => {
      onSuccess?.();

      await queryClient.invalidateQueries({
        queryKey: CUSTOMERS_KEYS.ALL(),
      });
    },
  });

  return deleteCustomerMutation;
};
