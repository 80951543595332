import { cn } from '@spektr/style-utils';

export const EmptyContent = () => {
  return (
    <div
      className={cn(
        'flex items-center justify-center',
        'h-full w-full',
        'text-color-text-subtext text-sm font-medium'
      )}
    >
      Select a field to configure
    </div>
  );
};
