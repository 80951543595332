import { cva } from 'class-variance-authority';
import { CircleDashed, Check, X, ScanSearch } from 'lucide-react';

import {
  IndicatorState,
  IndicatorStates,
  IndicatorType,
  IndicatorValueTypes,
  scoreIndicatorTypes,
} from '@spektr/shared/validators';

const indicatorStates = IndicatorStates.Values;

export const getIndicatorValueType = (indicatorType: IndicatorType) =>
  scoreIndicatorTypes.includes(indicatorType)
    ? IndicatorValueTypes.Values.score
    : IndicatorValueTypes.Values.service;

const indicatorStateIcon = {
  default: <CircleDashed className="h-3.5 w-3.5" />,
  [indicatorStates.unknown]: <CircleDashed className="h-3.5 w-3.5" />,
  [indicatorStates.cleared]: <Check className="h-3.5 w-3.5" />,
  [indicatorStates.notCleared]: <X className="h-3.5 w-3.5" />,
  [indicatorStates.unclear]: <ScanSearch className="h-3.5 w-3.5" />,
};

export const getIndicatorStateIcon = (indicatorState?: IndicatorState) =>
  indicatorState
    ? indicatorStateIcon[indicatorState]
    : indicatorStateIcon['default'];

export const indicatorColorVariants = cva('', {
  variants: {
    state: {
      unknown:
        'bg-color-bg-card-default dark:bg-color-bg-card-accent text-color-text-subtext',
      cleared: 'bg-color-cyan/10 text-color-cyan',
      notCleared: 'bg-color-red/10 text-color-red',
      unclear: 'bg-color-yellow/10 text-color-yellow',
    },
  },
  defaultVariants: {
    state: 'unknown',
  },
});
