import { ProcessApiClient } from '../client';
import { ALERTS_KEYS } from '../queryKeys';
import { AlertFilters } from '../types/Filters';

export function getAllAlerts(filters: AlertFilters) {
  const arrayQueries: { [key: string]: string } = {};

  if (filters.status && filters.status.length > 0) {
    arrayQueries['status'] = filters.status.toString();
  }

  if (filters.alertType && filters.alertType.length > 0) {
    arrayQueries['alertType'] = filters.alertType.toString();
  }

  return {
    queryKey: ALERTS_KEYS.ALL(filters),
    queryFn: () =>
      ProcessApiClient.getClient().listAllAlertsFiltered({
        queries: {
          page: filters.page ?? 1,
          limit: filters.limit ?? 10,
          searchQuery: filters?.searchQuery,
          userId: filters?.userId,
          age: filters?.age ? +filters.age : undefined,
          createdAtStart: filters.createdAtStart,
          createdAtEnd: filters.createdAtEnd,
          updatedAtStart: filters.updatedAtStart,
          updatedAtEnd: filters.updatedAtEnd,
          ...arrayQueries,
        },
      }),
  };
}

export function getAllAlertsForCustomer(
  customerId: string,
  filters: AlertFilters
) {
  return {
    queryKey: ALERTS_KEYS.ALL_BY_CUSTOMER(customerId, filters),
    queryFn: () =>
      ProcessApiClient.getClient().getAllAlertsForCustomer({
        params: {
          clientRecordId: customerId,
        },
        queries: {
          page: filters?.page ?? 1,
          limit: filters?.limit ?? 10,
        },
      }),
  };
}

export function getAlertById(alertId: string, context?: 'global' | 'customer') {
  return {
    queryKey: ALERTS_KEYS.BY_ID(alertId),
    queryFn: () =>
      ProcessApiClient.getClient().getAlertById({
        params: {
          alertId,
        },
        queries: {
          context: context ?? 'global',
        },
      }),
  };
}

export function getAlertHitById(alertId: string, hitId: string) {
  return {
    queryKey: ALERTS_KEYS.HIT_BY_ID(alertId, hitId),
    queryFn: () =>
      ProcessApiClient.getClient().getAlertHitById({
        params: {
          alertId,
          hitId,
        },
      }),
  };
}
