import { ChevronRight, CircleCheck, CircleDashed } from 'lucide-react';

import { Process } from '@spektr/shared/types';
import { ProcessType } from '@spektr/shared/validators';

import { DropdownMenuComboBox, Tooltip } from '@spektr/client/components';

import { useGetAllowedProcessActions } from '../hooks/useGetAllowedProcessActions';

import { ProcessIcon } from './ProcessIcon';

type OutcomeNodeProcessLinkProps = {
  processes: Process[];
  processType: ProcessType;
  onActionClick: (value: string) => void;
};

export const OutcomeNodeProcessLink = ({
  processes,
  processType,
  onActionClick,
}: OutcomeNodeProcessLinkProps) => {
  const menuOptions = useGetAllowedProcessActions(
    processes,
    processType === 'onboarding'
  );

  return (
    <DropdownMenuComboBox
      triggerProps={{
        className: 'w-full p-3 hover:bg-color-bg-accent leading-none',
      }}
      modal
      itemClassName="text-sm max-w-72"
      trigger={
        processes.length === 0 ? (
          <Tooltip
            tooltipTriggerProps={{
              className: 'w-full',
            }}
            content="This outcome node is not linked to any process"
          >
            <div className="text-color-text-subtext flex items-center gap-2">
              <CircleDashed className="h-3 w-3" />
              <span className="text-sm">Unlinked</span>
              <ChevronRight className="ml-auto h-3 w-3 shrink-0" />
            </div>
          </Tooltip>
        ) : processes.length > 1 ? (
          <Tooltip
            tooltipTriggerProps={{
              className: 'w-full',
            }}
            content="This outcome node is linked to multiple processes"
          >
            <div className="text-color-text-primary flex items-center gap-2">
              <CircleCheck className="stroke-color-green h-3 w-3" />
              <span className="text-sm">{`${processes.length} linked`}</span>
              <ChevronRight className="ml-auto h-3 w-3 shrink-0" />
            </div>
          </Tooltip>
        ) : (
          <div className="text-color-text-primary flex items-center gap-2">
            <ProcessIcon processType={processes[0].type} className="h-3 w-3" />
            <span className="line-clamp-1 text-sm">{processes[0].name}</span>
            <ChevronRight className="ml-auto h-3 w-3 shrink-0" />
          </div>
        )
      }
      options={menuOptions}
      onClick={onActionClick}
    />
  );
};
