import { TextareaHTMLAttributes, forwardRef } from 'react';

import { cn } from '@spektr/style-utils';

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  resizable?: boolean;
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, resizable = false, ...props }, ref) => (
    <textarea
      className={cn(
        'flex min-h-[60px] w-full p-3',
        'rounded-md border',
        'text-xs font-medium',
        'focus-visible:outline-none focus-visible:ring-0',
        'disabled:cursor-not-allowed disabled:opacity-50',
        'resize-none',
        'border-color-border-textarea',
        'bg-color-bg-primary',
        'text-color-text-textarea',
        'placeholder-color-text-placeholder',
        'disabled:text-color-text-textarea--disabled disabled:opacity-50',
        'focus-visible:ring-current',
        {
          resize: resizable,
        },
        className
      )}
      ref={ref}
      {...props}
    />
  )
);
