import { ReactNode } from 'react';
import { Info } from 'lucide-react';

import { Tooltip } from '@spektr/client/components';

export type SectionWithTitleProps = {
  children: ReactNode;
  title: string;
  additionalInfo?: string;
};

export const SectionWithTitle = ({
  children,
  title,
  additionalInfo,
}: SectionWithTitleProps) => (
  <div className="flex flex-col gap-4">
    <p className="text-color-text-subtext flex items-center gap-1 text-sm font-medium">
      {title}
      {additionalInfo && (
        <Tooltip content={additionalInfo} side="right" align="end">
          <Info className="h-3 w-3" />
        </Tooltip>
      )}
    </p>
    {children}
  </div>
);
