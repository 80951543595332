import { CasesTableCell } from './CasesTableCell';
import { CasesTableRow } from './CasesTableRow';

type CasesEmptyTableStateProps = {
  message: string;
  columnCount: number;
};

export const CasesEmptyTableState = ({
  message,
  columnCount,
}: CasesEmptyTableStateProps) => {
  return (
    <CasesTableRow className="cursor-default">
      <CasesTableCell
        colSpan={columnCount}
        className="rounded-md border-x py-7 text-center text-sm"
      >
        {message}
      </CasesTableCell>
    </CasesTableRow>
  );
};
