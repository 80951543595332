import { FindAllProcessesTypesQuery } from '@spektr/shared/validators';

export function getProcessesQueryKey(
  types: FindAllProcessesTypesQuery = undefined
) {
  if (types) {
    const sorted = types?.sort().join('-');
    return ['process', sorted];
  } else {
    return ['process'];
  }
}

/**
 * @example ['process', '123']
 */
export function getProcessQueryKey(processId: string) {
  return ['process', processId];
}

/**
 * @example ['process', '123', 'links']
 */
export function getProcessLinksByIdQueryKey(processId: string) {
  return [...getProcessQueryKey(processId), 'links'];
}

/**
 * @example ['process', '123', 'nodes', '456']
 */
export function getNodeQueryKey(processId: string, nodeId: string) {
  return [...getProcessQueryKey(processId), 'nodes', nodeId];
}

/**
 * @example ['process', '123', 'nodes', '456', 'fields']
 */
export function getAllowedSpektrFieldsQueryKey(
  processId: string,
  nodeId: string
) {
  return [...getNodeQueryKey(processId, nodeId), 'fields'];
}

export function getLiveProcessVersionsQueryKey() {
  return ['process', 'versions', 'live'];
}

export function getLiveVersionByProcessIdQueryKey(processId: string) {
  return ['process', processId, 'versions', 'live'];
}

export function getVersionOverviewByProcessIdQueryKey(processId: string) {
  return ['process', processId, 'overview'];
}

export function getTopologyQueryKey() {
  return ['process', 'topology'];
}

export function getDependenciesQueryKey(processId: string) {
  return ['process', processId, 'dependencies'];
}

export function getNodeAlertFieldsQueryKey(processId: string, nodeId: string) {
  return [...getNodeQueryKey(processId, nodeId), 'alertFields'];
}

export function getProcessSandboxExecutionByIdQueryKey(
  processId: string,
  sandboxId: string
) {
  return [...getProcessQueryKey(processId), 'sandboxExecution', sandboxId];
}
