import { Siren } from 'lucide-react';

import { IconBox } from '@spektr/client/components';

export type AlertNodeProps = {
  title: string;
};

export const AlertNode = ({ title }: AlertNodeProps) => {
  return (
    <>
      <IconBox
        size="lg"
        color="pink"
        renderIcon={({ className }) => <Siren className={className} />}
      />
      <div className="flex flex-col">
        <h3 className="text-color-text-node line-clamp-1 text-sm font-medium">
          {title}
        </h3>
        <span className="text-color-text-node-subtext text-xs">Alert step</span>
      </div>
    </>
  );
};
