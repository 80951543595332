import { cn } from '@spektr/style-utils';

import { TableHeader, TableHeaderProps, TableRow } from '../../Table';

type CasesTableHeaderProps = TableHeaderProps & {
  rowClassName?: string;
};

export const CasesTableHeader = ({
  children,
  rowClassName,
  ...props
}: CasesTableHeaderProps) => {
  return (
    <TableHeader {...props}>
      <TableRow className={cn('hover:bg-inherit', rowClassName)}>
        {children}
      </TableRow>
    </TableHeader>
  );
};
