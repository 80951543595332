import { EllipsisVertical, Trash2, Ungroup } from 'lucide-react';

import { cn } from '@spektr/style-utils';
import {
  DropdownMenuComboBox,
  DropdownOption,
} from '@spektr/client/components';

import {
  useIncompleteRouterNode,
  useIncompleteRuleGroup,
} from '../../providers';

const options: DropdownOption[] = [
  {
    label: 'Add Inner Rule Group',
    type: 'item',
    value: 'add-inner-group',
    icon: <Ungroup className="h-4 w-4" />,
    variant: 'default',
  },
  {
    type: 'separator',
    value: 'separator',
  },
  {
    label: 'Delete',
    type: 'item',
    value: 'delete',
    icon: <Trash2 className="h-4 w-4" />,
    variant: 'danger',
  },
];

export const RuleGroupActions = () => {
  const { removeRuleGroup } = useIncompleteRouterNode();
  const { group, addInnerGroup } = useIncompleteRuleGroup();

  return (
    <DropdownMenuComboBox
      modal
      trigger={
        <EllipsisVertical
          className={cn(
            'h-5 w-6',
            'stroke-color-stroke-default',
            'cursor-pointer',
            'hover:stroke-color-stroke-default--hover'
          )}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        />
      }
      triggerProps={{
        className: 'absolute right-3 top-1/2 -translate-y-1/2 transform',
      }}
      options={options}
      onClick={(value) => {
        if (value === 'delete') removeRuleGroup(group.clientSideOnlyId);
        else if (value === 'add-inner-group') addInnerGroup();
      }}
    />
  );
};
