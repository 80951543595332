import { Suspense } from 'react';
import axios from 'axios';

import { Banner, SpektrErrorBoundary } from '@spektr/client/components';

import { OwnershipList } from '../containers/OwnershipList';

import { SkeletonCustomerOwnershipList } from '../components';

export const CustomerOwnership = () => {
  return (
    <SpektrErrorBoundary
      fallbackRender={({ error }) => {
        let errorMsg =
          'An error occurred while retrieving the ownership records.';
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            errorMsg = 'There are no ownership records at the moment.';
          } else if (error.message) {
            errorMsg = error.message;
          }
        }

        return (
          <Banner type="error" iconName="TriangleAlert" message={errorMsg} />
        );
      }}
    >
      <Suspense fallback={<SkeletonCustomerOwnershipList />}>
        <OwnershipList />
      </Suspense>
    </SpektrErrorBoundary>
  );
};
