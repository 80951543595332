import { LS_KEYS } from '@spektr/client/constants';
import { ALERTS_KEYS } from '@spektr/client/services';

import { CasesFiltersProvider } from '../providers';
import { CasesFiltersList } from '../containers';

import { AlertsList } from './containers';
import {
  useGetAlertsDropdownFiltersOptions,
  useGetAlertsFiltersConfigurations,
} from './hooks';

export type AlertsDashboardProps = {
  userId: string;
  columnsToDisplay: string[];
};

export const AlertsDashboard = ({
  userId,
  columnsToDisplay,
}: AlertsDashboardProps) => {
  const filtersWithNoDivider = ['status'];
  const configurations = useGetAlertsFiltersConfigurations();

  return (
    <CasesFiltersProvider
      getFiltersDropdownOptions={useGetAlertsDropdownFiltersOptions}
      configurations={configurations}
      storageKey={LS_KEYS.ALERTS_FILTERS + '.' + userId}
      queryKey={ALERTS_KEYS.ALL()}
      filtersWithNoDivider={filtersWithNoDivider}
      columnsToDisplay={columnsToDisplay}
    >
      <CasesFiltersList />
      <AlertsList />
    </CasesFiltersProvider>
  );
};
