import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { updateFieldsFactory } from '@spektr/client/utils';
import { ScrollArea, toast } from '@spektr/client/components';
import { getAllowedSpektrFieldsQuery } from '@spektr/client/services';

import { type OpenCorporatesRiskNode } from '@spektr/shared/validators';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { useEnrichedServiceFields } from '../../providers/EnrichedServiceFields';
import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { ServiceCommonMappingForm } from '../../components/ServiceCommonMappingForm';

import { withDialogWrapper } from '../WithDialogWrapper';
import { withFieldsEnrichment } from '../WithFieldsEnrichment';
import { CustomerProfileEnrichmentFields } from '../CustomerProfileEnrichmentFields';

import { DialogFooter } from './containers/DialogFooter';
import { ContentSkeleton } from './components/ContentSkeleton';

export namespace OpenCorporatesRiskContent {
  export type Props = BaseDialogProps<OpenCorporatesRiskNode>;
}

const OpenCorporatesRiskContent = ({
  isPendingUpdate,
  node,
  processId,
  onUpdate,
}: OpenCorporatesRiskContent.Props) => {
  const { t } = useTranslation();
  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, node.id)
  );
  const { newServiceConfig } = useFeatureFlags();
  const { getServiceFields } = useEnrichedServiceFields();

  const formInstance = useForm<Record<string, string>>({
    defaultValues: node?.mapping ?? {},
    mode: 'onChange',
  });

  const handleSave = async () => {
    const mapping = formInstance.getValues();
    const fields = newServiceConfig ? getServiceFields() : node.fields;

    await onUpdate(updateFieldsFactory(node, fields, mapping));

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });
  };

  const formEntries = useMemo(
    () => [
      {
        key: 'companyNumber',
        label: t('fields:openCorporatesRisk.company_number'),
        type: 'string',
        description: '',
      },
      {
        key: 'countryCode',
        label: t('fields:openCorporatesRisk.jurisdiction_code'),
        type: 'string',
        description: '* Mandatory',
      },
      {
        key: 'companyName',
        label: t('fields:openCorporatesRisk.company_name'),
        type: 'string',
        description: '',
      },
    ],
    [t]
  );

  return (
    <FormProvider {...formInstance}>
      <ScrollArea className="-mr-3 max-h-[70vh] overflow-y-auto">
        <div className="mr-3 flex flex-col gap-6">
          <ServiceCommonMappingForm
            spektrFields={spektrFields}
            form={formEntries}
            title={t('process:baseMapAttributesTitle')}
          />
          {newServiceConfig && (
            <CustomerProfileEnrichmentFields
              title={t('process:customerProfileEnrichment.sectionTitle')}
            />
          )}
        </div>
      </ScrollArea>
      <DialogFooter isPendingUpdate={isPendingUpdate} onSave={handleSave} />
    </FormProvider>
  );
};

const EnhancedOpenCorporatesRiskContent = withFieldsEnrichment(
  OpenCorporatesRiskContent
);

export const OpenCorporatesRiskDialog = withDialogWrapper(
  EnhancedOpenCorporatesRiskContent,
  <ContentSkeleton />,
  {
    className: 'max-w-[700px]',
  }
);
