import { ReactElement, useContext, useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import { LOGIN_URL } from '@spektr/shared/utils';
import { UserRoles } from '@spektr/shared/validators';

import {
  BannerNotifications,
  PermissionsProvider,
  PublicDomainProvider,
  UserInformationProvider,
} from '@spektr/client/providers';
import { DashboardLayout } from '@spektr/client/components';
import { AppSidebar } from '@spektr/model-builder/containers';

import { useGetNavigationItems } from '../config/navigation';

import { AuthContext } from '../auth/auth-context';

import { RefreshSessionDialog, useSessionExpiration } from './RefreshSession';

type ProtectedRouteProps = {
  redirectPath?: string;
  children?: ReactElement;
};

export const ProtectedRoute = ({
  redirectPath = LOGIN_URL,
  children,
}: ProtectedRouteProps) => {
  const { user, isLoading, isPasswordRequired } = useContext(AuthContext);
  const { state, pathname } = useLocation();
  const { handleRefresh, onDeclineRefresh } = useSessionExpiration();
  const navigationItems = useGetNavigationItems();

  const defaultNotifications = useMemo(() => {
    return [];
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  if (!user) {
    return (
      <Navigate
        to={redirectPath}
        state={{ redirectedFrom: pathname }}
        replace
      />
    );
  }

  if (isPasswordRequired) {
    return <Navigate to={redirectPath} replace />;
  }

  datadogRum.startSessionReplayRecording();

  return children ? (
    children
  ) : (
    <UserInformationProvider user={user}>
      <PermissionsProvider userRole={user?.role as UserRoles}>
        <PublicDomainProvider publicPageUrl={window._env.PUBLIC_PAGE_URL}>
          <BannerNotifications defaultNotifications={defaultNotifications}>
            <DashboardLayout
              sidebar={<AppSidebar navItems={navigationItems} />}
            >
              <Outlet />
              <RefreshSessionDialog
                open={state?.show_refresh_session}
                handleRefresh={handleRefresh}
                onDeclineRefresh={onDeclineRefresh}
              />
            </DashboardLayout>
          </BannerNotifications>
        </PublicDomainProvider>
      </PermissionsProvider>
    </UserInformationProvider>
  );
};
