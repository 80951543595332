import { File } from 'lucide-react';

import { Skeleton } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

export const SkeletonCustomerDocumentRow = ({
  className,
}: {
  className?: string;
}) => {
  return (
    <div className="mb-2 flex flex-col">
      <div
        className={cn(
          'min-w-full items-center gap-4',
          'px-6 py-3',
          'rounded-md border',
          'bg-color-bg-card-default',
          className
        )}
      >
        <div className="col-span-4 flex items-center gap-5">
          <File className="text-color-text-subtext h-5 w-5 min-w-5" />
          <div className="flex w-full flex-col gap-1">
            <Skeleton className="w-3/4" />
            <Skeleton className="w-1/2" />
          </div>
        </div>
        <div className="col-span-2 flex flex-col gap-2">
          <Skeleton className="w-3/4" />
        </div>
        <div className="col-span-2 flex flex-col gap-2">
          <Skeleton className="w-3/4" />
        </div>
        <div className="col-span-2 flex flex-col gap-2">
          <Skeleton className="w-3/4" />
        </div>
      </div>
    </div>
  );
};
