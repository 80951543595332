import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { QueryKey, useQueryClient } from '@tanstack/react-query';

import { sessionStorageUtils } from '@spektr/client/utils';
import { DropdownOption } from '@spektr/client/components';

import {
  CASES_ALERTS_FULL_URL,
  CASES_CUSTOMERS_FULL_URL,
} from '@spektr/shared/utils';

import { CasesFilters, CasesFiltersConfigurations } from '../types';
import {
  getMultipleFilterValues,
  getFilterValueFromObject,
  getFilters,
  getParamFilters,
} from '../utils';

const REMOVE_VALUE = 'remove';

type CasesFiltersProviderProps = {
  getFiltersDropdownOptions: (filters: CasesFilters) => {
    [key: string]: DropdownOption[];
  };
  configurations: CasesFiltersConfigurations;
  storageKey: string;
  queryKey: QueryKey;
  filtersWithNoDivider: string[];
  columnsToDisplay: string[];
  children: ReactNode;
};

type CasesFiltersContextType = {
  configurations: CasesFiltersConfigurations;
  removeValue: string;
  filters: CasesFilters;
  filtersWithNoDivider: string[];
  columnsToDisplay: string[];
  setFilter: (filterIdentifier: string, filterValue: string | number) => void;
  clearFilters: () => void;
  getAppliedValue: (filterIdentifier: string) => string;
  getAppliedDropdownFilterValues: (filterIdentifier: string) => ReactNode[];
  getFiltersDropdownOptions: (filters: CasesFilters) => {
    [key: string]: DropdownOption[];
  };
};

export const CasesFiltersContext = createContext<CasesFiltersContextType>({
  configurations: {},
  removeValue: REMOVE_VALUE,
  filters: { page: 1, limit: 10 },
  filtersWithNoDivider: [],
  columnsToDisplay: [],
  setFilter: () => null,
  clearFilters: () => null,
  getAppliedValue: () => '',
  getAppliedDropdownFilterValues: () => [],
  getFiltersDropdownOptions: () => ({}),
});

export const useCasesFilters = () => {
  return useContext(CasesFiltersContext);
};

export const CasesFiltersProvider = ({
  getFiltersDropdownOptions,
  configurations,
  storageKey,
  queryKey,
  filtersWithNoDivider,
  columnsToDisplay,
  children,
}: CasesFiltersProviderProps) => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [savedParamFilters, setSavedParamFilters] = useState<{
    [key: string]: string | string[];
  }>({});

  const isCasesPage = useCallback(() => {
    return [CASES_CUSTOMERS_FULL_URL, CASES_ALERTS_FULL_URL].includes(
      location.pathname
    );
  }, [location.pathname]);

  const lsFilters = JSON.parse(
    sessionStorageUtils.getItem(storageKey) || '{}'
  ) as CasesFilters;

  const defaultFilters = useMemo<{ [key: string]: number | string | string[] }>(
    () =>
      Object.keys(lsFilters).length > 0
        ? lsFilters
        : { page: 1, limit: 10, searchQuery: '' },
    [lsFilters]
  );

  const paramFilters = useMemo<{ [key: string]: string | string[] }>(() => {
    if (isCasesPage()) {
      const params = getParamFilters(configurations, searchParams);
      if (JSON.stringify(params) !== JSON.stringify(savedParamFilters)) {
        setSavedParamFilters(params);
      }
      return params;
    }

    return savedParamFilters;
  }, [configurations, isCasesPage, savedParamFilters, searchParams]);

  const filters = useMemo<CasesFilters>(() => {
    const updateFromUrl = Object.entries(paramFilters).length > 0;
    const casesFilters = getFilters(
      configurations,
      defaultFilters,
      paramFilters,
      updateFromUrl
    );

    if (updateFromUrl) {
      sessionStorageUtils.setItem(storageKey, JSON.stringify(casesFilters));
    }

    return casesFilters;
  }, [configurations, defaultFilters, paramFilters, storageKey]);

  const setCommonFilter = useCallback(
    (filterIdentifier: string, newFilterValue?: string | number) => {
      if (!newFilterValue) {
        searchParams.delete(filterIdentifier);
      } else {
        searchParams.set(filterIdentifier, newFilterValue.toString());
      }

      setSearchParams(searchParams);
      sessionStorageUtils.setItem(
        storageKey,
        JSON.stringify(Object.fromEntries(searchParams.entries()))
      );
    },
    [searchParams, setSearchParams, storageKey]
  );

  const setCustomFilter = useCallback(
    (filterIdentifier: string, newFilterValue?: string | number) => {
      if (newFilterValue === REMOVE_VALUE || !newFilterValue) {
        searchParams.delete(filterIdentifier);
      } else {
        switch (configurations[filterIdentifier].selectionType) {
          case 'multiple': {
            const previousValues = getFilterValueFromObject(
              filters,
              filterIdentifier
            );

            const parsedValues = getMultipleFilterValues(
              newFilterValue.toString(),
              previousValues?.toString()
            );

            if (parsedValues.length === 0) {
              searchParams.delete(filterIdentifier);
            } else {
              searchParams.set(filterIdentifier, parsedValues.toString());
            }
            break;
          }

          case 'single': {
            searchParams.set(filterIdentifier, newFilterValue.toString());
            break;
          }
        }

        if (
          filters.page !== 1 &&
          JSON.stringify(Object.fromEntries(searchParams.entries())) !==
            JSON.stringify(filters)
        ) {
          queryClient
            .invalidateQueries({ queryKey: queryKey })
            .then(() => setCommonFilter('page', 1));
        }
      }

      setSearchParams(searchParams);
      sessionStorageUtils.setItem(
        storageKey,
        JSON.stringify(Object.fromEntries(searchParams.entries()))
      );
    },
    [
      configurations,
      filters,
      queryClient,
      queryKey,
      searchParams,
      setCommonFilter,
      setSearchParams,
      storageKey,
    ]
  );

  const setFilter = useCallback(
    (filterIdentifier: string, newFilterValue: string | number) => {
      if (['page', 'limit', 'searchQuery'].includes(filterIdentifier)) {
        setCommonFilter(filterIdentifier, newFilterValue);
      } else {
        setCustomFilter(filterIdentifier, newFilterValue);
      }
    },
    [setCommonFilter, setCustomFilter]
  );

  const clearFilters = useCallback(() => {
    sessionStorageUtils.setItem(storageKey, '{}');
    setSearchParams({ page: '1', limit: '10' });
  }, [setSearchParams, storageKey]);

  const getAppliedDropdownFilterValues = useCallback(
    (identifier: string): ReactNode[] => {
      const filterValue = getFilterValueFromObject(filters, identifier);

      if (!filterValue) {
        return [];
      }

      switch (configurations[identifier].selectionType) {
        case 'multiple': {
          if (!configurations[identifier].values) {
            return [];
          }

          const currentValues = filterValue.toString().split(',');
          return configurations[identifier].values
            .filter((value) => currentValues.includes(value.key))
            .map((value) => value.labelNode);
        }
        case 'single': {
          return filterValue ? [filterValue.toString()] : [];
        }
      }
    },
    [configurations, filters]
  );

  const getAppliedValue = useCallback(
    (identifier: string): string => {
      const filterValue = getFilterValueFromObject(filters, identifier);
      return filterValue ? filterValue.toString() : '';
    },
    [filters]
  );

  useEffect(() => {
    if (isCasesPage()) {
      const url = new URL(window.location.href);
      Object.entries(filters)
        .filter(([paramKey, paramValue]) =>
          configurations[paramKey] ? paramValue?.toString() : true
        )
        .forEach(([key, value]) => {
          searchParams.set(key, value.toString());
          url.searchParams.set(key, value.toString());
        });
      window.history.replaceState(null, '', url.toString());
    }
  }, [configurations, filters, searchParams, isCasesPage]);

  const value = useMemo(
    () => ({
      configurations,
      removeValue: REMOVE_VALUE,
      filters: filters,
      filtersWithNoDivider,
      columnsToDisplay,
      setFilter,
      clearFilters,
      getAppliedValue,
      getAppliedDropdownFilterValues,
      getFiltersDropdownOptions,
    }),
    [
      configurations,
      filters,
      filtersWithNoDivider,
      columnsToDisplay,
      getFiltersDropdownOptions,
      setFilter,
      clearFilters,
      getAppliedValue,
      getAppliedDropdownFilterValues,
    ]
  );

  return (
    <CasesFiltersContext.Provider value={value}>
      <div className="flex flex-col">{children}</div>
    </CasesFiltersContext.Provider>
  );
};
