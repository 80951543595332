import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { usePermissionsContext } from '@spektr/client/providers';

import { Tooltip, TooltipProps } from '../Tooltip';

import {
  BaseVariantProps,
  serviceVariantColors,
  ServiceVariantProps,
} from './types';
import { AsyncButton } from './AsyncButton';
import { Button } from './Button';
import { ServiceButton, ServiceButtonProps } from './ServiceButton';

export type ActionButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  (BaseVariantProps | ServiceVariantProps) & {
    rbacAction: string;
    tooltipProps?: Omit<TooltipProps, 'children'>;
    isPending?: boolean;
    pendingLabel?: ReactNode;
    getDisabledReason?: (disabled?: boolean) => string;
  };

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  (
    {
      children,
      tooltipProps,
      rbacAction,
      disabled,
      getDisabledReason,
      isPending,
      pendingLabel,
      color = 'primary',
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation('common');
    const { hasPermission } = usePermissionsContext();
    const isActionAllowed = hasPermission(rbacAction);
    const isDisabled = disabled ?? !isActionAllowed;
    const isServiceButton = serviceVariantColors.includes(color);

    const renderTooltipContent = (): string => {
      if (!isDisabled) {
        return '';
      }

      const disabledReason = getDisabledReason?.(isDisabled);
      if (disabledReason) {
        return disabledReason;
      }

      if (!isActionAllowed) {
        return t('errors.notAllowed');
      }

      return '';
    };

    const renderButton = () => {
      if (!isServiceButton) {
        if (!pendingLabel) {
          return (
            <Button
              {...props}
              ref={ref}
              color={color as BaseVariantProps['color']}
              disabled={isDisabled}
            >
              {children}
            </Button>
          );
        }

        return (
          <AsyncButton
            {...props}
            ref={ref}
            color={color as BaseVariantProps['color']}
            disabled={isDisabled}
            isPending={isPending}
            pendingLabel={pendingLabel}
            label={children}
          />
        );
      }

      return (
        <ServiceButton
          {...props}
          ref={ref}
          color={color as ServiceButtonProps['color']}
          disabled={isDisabled}
          isPending={isPending}
          label={children}
          pendingLabel={pendingLabel}
        >
          {children}
        </ServiceButton>
      );
    };

    if (!renderTooltipContent()) {
      return renderButton();
    }

    return (
      <Tooltip
        {...tooltipProps}
        tooltipTriggerProps={{
          ...tooltipProps?.tooltipTriggerProps,
          asChild: false,
          className: 'cursor-not-allowed',
        }}
        content={renderTooltipContent()}
      >
        {renderButton()}
      </Tooltip>
    );
  }
);
