import { MoreVertical } from 'lucide-react';

import {
  CasesCustomerActions,
  CustomerIcon,
  DropdownOption,
  IconWithBackground,
  StatusWithIcon,
  TableCell,
  TableRow,
  TagsGroup,
} from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

import { ClientRecord } from '@spektr/shared/validators';

import {
  getCustomerStatusIcon,
  getCustomerStatusVariant,
} from '../../constants';

const baseTrClasses = cn(
  'bg-color-bg-table-row dark:bg-color-bg-table-row/20',
  'hover:bg-color-bg-card-accent/60',
  'rounded-md border dark:border-transparent',
  'cursor-pointer'
);
const baseTcClasses =
  'border-color-border-primary border-y text-sm px-3 py-[18px]';

export type CustomersTableRowProps = {
  actions?: DropdownOption[];
  customer: ClientRecord;
  columns: string[];
  onRowClick: (spektrId: string) => void;
  onDelete: () => void;
  onUpdate?: () => void;
};

export const CustomersTableRow = ({
  customer,
  columns,
  onRowClick,
  onDelete,
  onUpdate,
}: CustomersTableRowProps) => {
  return (
    <TableRow
      className={baseTrClasses}
      onClick={() => onRowClick(customer.spektrId)}
      data-cy={`customer-row-${customer.name}`}
    >
      {columns.includes('customer') && (
        <TableCell
          className={cn('w-3/12 rounded-s-md border-l !pl-6', baseTcClasses)}
        >
          <div className="flex items-center">
            <IconWithBackground className="bg-color-bg-accent mr-3 rounded-full">
              <CustomerIcon type={customer.customerType} />
            </IconWithBackground>
            <div className="flex flex-col gap-0.5">
              <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                {customer.name}
              </span>
              {customer.uboDetails &&
                customer.uboDetails?.customerLevel === 'secondary' && (
                  <span
                    className={cn(
                      'text-color-text-subtext text-xs capitalize',
                      'overflow-hidden text-ellipsis whitespace-nowrap'
                    )}
                  >
                    {customer.uboDetails.role} -{' '}
                    {customer.uboDetails.companyName}
                  </span>
                )}
            </div>
          </div>
        </TableCell>
      )}
      {columns.includes('tags') && (
        <TableCell className={cn('w-5/12', baseTcClasses)}>
          <TagsGroup
            customerName={customer.name}
            limit={3}
            customerTags={customer.tags}
          />
        </TableCell>
      )}
      {columns.includes('unresolvedAlerts') && (
        <TableCell className={baseTcClasses}>
          {customer.unresolvedAlertsCount}
          {customer.unresolvedAlertsCount === 1 ? ' alert' : ' alerts'}
        </TableCell>
      )}
      {columns.includes('status') && (
        <TableCell className={cn('w-32', baseTcClasses)}>
          <StatusWithIcon
            color={getCustomerStatusVariant(customer.status)}
            icon={getCustomerStatusIcon(customer.status)}
            label={customer.status || 'No Status'}
          />
        </TableCell>
      )}
      <TableCell className={cn('rounded-e-md border-r', baseTcClasses, 'pr-6')}>
        <CasesCustomerActions
          onDelete={onDelete}
          onUpdate={onUpdate}
          trigger={
            <button
              className="hover:text-color-text-subtext ml-auto flex h-full items-center"
              onClick={(e) => e.stopPropagation()}
            >
              <MoreVertical className="h-4 w-4" />
            </button>
          }
        />
      </TableCell>
    </TableRow>
  );
};
