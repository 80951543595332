import { cn } from '@spektr/style-utils';
import {
  CustomerIcon,
  IconWithBackground,
  TextTruncate,
} from '@spektr/client/components';

import {
  ClientRecordOwner,
  ClientRecordRepAndController,
} from '@spektr/shared/validators';

import { formatCustomerDate } from '../utils';

import { CustomerOwnershipCenteredCell } from './CustomerOwnershipCenteredCell';

type CustomerOwnershipRowProps = {
  data: ClientRecordOwner | ClientRecordRepAndController;
};

export const CustomerOwnershipRow = ({ data }: CustomerOwnershipRowProps) => {
  const isCompany = data.type === 'company';
  const date = isCompany ? data.registrationDate : data.birthDate;

  return (
    <div
      className={cn(
        'grid grid-cols-10 items-center gap-4',
        'px-6 py-3',
        'rounded-md border',
        'text-sm font-medium leading-none',
        'bg-color-bg-card-default'
      )}
    >
      <div className="col-span-2 flex items-center gap-5">
        <IconWithBackground className="bg-color-bg-foreground h-9 w-9 shrink-0 rounded-full">
          <CustomerIcon type={data.type} />
        </IconWithBackground>
        <div className="flex flex-col gap-1">
          <TextTruncate className="line-clamp-1" text={data.name} />
        </div>
      </div>
      <div className="col-span-3 flex flex-col gap-2">
        <p className="text-color-text-subtext text-xs">Address</p>
        <TextTruncate className="line-clamp-1" text={data.address || '-'} />
      </div>
      <CustomerOwnershipCenteredCell>
        <p className="text-color-text-subtext text-xs">
          {isCompany ? 'Incorporation date' : 'Date of Birth'}
        </p>
        <p>{formatCustomerDate(date) || '-'}</p>
      </CustomerOwnershipCenteredCell>
      <CustomerOwnershipCenteredCell>
        {(data as ClientRecordOwner).percentage !== undefined && (
          <>
            <p className="text-color-text-subtext text-xs">Ownership</p>
            <p>{`${(data as ClientRecordOwner).percentage.toFixed(2)} %`}</p>
          </>
        )}
        {(data as ClientRecordRepAndController).role !== undefined && (
          <>
            <p className="text-color-text-subtext text-xs">Position</p>
            <p>{(data as ClientRecordRepAndController).role}</p>
          </>
        )}
      </CustomerOwnershipCenteredCell>
      {/* TODO: @Alex - add actions */}
    </div>
  );
};
