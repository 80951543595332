import { ArrowRight } from 'lucide-react';
import { MouseEvent, ReactNode } from 'react';

import { formatBasicNumber } from '@spektr/client/utils';
import { cn } from '@spektr/style-utils';

import { Skeleton } from '../Skeleton';

type StatsCardProps = {
  className?: string;
  description: string;
  icon: ReactNode;
  isLoading?: boolean;
  value: number;
  title: string;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
};

export const StatsCard = ({
  className,
  description,
  icon,
  isLoading,
  value,
  title,
  onClick,
}: StatsCardProps) => {
  return (
    <div
      tabIndex={onClick ? 0 : undefined}
      className={cn(
        'bg-color-bg-card-default',
        'rounded border',
        'p-4',
        'focus:ring-ring focus:outline-none focus:ring-1',
        'transition-colors',
        onClick && 'hover:bg-color-bg-card--hover cursor-pointer',
        className
      )}
      onClick={onClick}
    >
      <div className={cn('flex flex-grow flex-row items-center', 'mb-4 h-12')}>
        <div
          className={cn(
            'bg-color-bg-primary',
            'mr-4 h-9 w-9',
            'flex shrink-0',
            'rounded'
          )}
        >
          {icon}
        </div>
        <span
          className={cn('flex grow', 'text-color-text-primary line-clamp-2')}
        >
          {title}
        </span>
        <ArrowRight
          className={cn(
            'stroke-color-stroke-tertiary',
            'ml-4 flex w-6 shrink-0'
          )}
        />
      </div>
      <div className={cn('flex flex-col items-start', 'border-t', 'pt-4')}>
        {isLoading ? (
          <Skeleton className="mb-2 h-5 w-14" />
        ) : (
          <span className={cn('text-color-text-primary', 'text-xl')}>
            {formatBasicNumber(value)}
          </span>
        )}
        <span
          className={cn(
            'text-color-text-subtext',
            'line-clamp-1 text-left text-xs'
          )}
        >
          {description}
        </span>
      </div>
    </div>
  );
};
