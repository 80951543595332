import { ZodType } from 'zod';

function getNestedItem<T>(key: string): T | null {
  const raw = window.sessionStorage.getItem(key);

  if (!raw) return null;

  try {
    return JSON.parse(raw) as T;
  } catch (err) {
    console.error(err, raw);
    return null;
  }
}

function getItem(key: string) {
  return window.sessionStorage.getItem(key);
}

function getValidatedItem<T>(key: string, validator: ZodType<T>) {
  const value = getItem(key);

  return validator.safeParse(value);
}

function setItem(key: string, value: string) {
  return window.sessionStorage.setItem(key, value);
}

export const sessionStorageUtils = {
  getItem,
  setItem,
  getNestedItem,
  getValidatedItem,
};
