import { cn } from '@spektr/style-utils';

import { TableRow, TableRowProps } from '../../Table';

export const CasesTableRow = ({
  className,
  children,
  ...props
}: TableRowProps) => {
  return (
    <TableRow
      className={cn(
        'bg-color-bg-table-row dark:bg-color-bg-table-row/20 !min-h-[69px]',
        'hover:bg-color-bg-card-accent/60',
        'border-color-border-primary border',
        'rounded-md border dark:border-transparent',
        'cursor-pointer',
        className
      )}
      {...props}
    >
      {children}
    </TableRow>
  );
};
