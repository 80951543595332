import { useMemo, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  Badge,
  RadioCheckbox,
  RadioGroup,
  TextTruncate,
  processStateLabelVariant,
  processTypeBadgeVariant,
} from '@spektr/client/components';
import {
  getProcessLinksByIdQuery,
  getProcessesQuery,
} from '@spektr/client/services';
import { cn } from '@spektr/style-utils';

import { getAvailableProcessesForConnection } from '../utils/getAvailableProcessesForConnection';

import { FooterActions } from './FooterActions';
import { FooterWithSelectedProcess } from './FooterWithSelectedProcess';

type ProcessesListProps = {
  sourceProcessId: string;
  sourceOutcomeId: string;
  closeDialog: () => void;
};

export const ProcessesList = ({
  sourceProcessId,
  sourceOutcomeId,
  closeDialog,
}: ProcessesListProps) => {
  const { data: processes } = useSuspenseQuery(getProcessesQuery());
  const { data: links } = useSuspenseQuery(
    getProcessLinksByIdQuery(sourceProcessId)
  );
  const linkedProcesses = useMemo(
    () =>
      links
        .filter((link) => link.source.outcomeId === sourceOutcomeId)
        .map((link) => link.processes)
        .flat(),
    [links, sourceOutcomeId]
  );
  const [selectedProcessId, setSelectedProcessId] = useState('');

  const sourceProcess = useMemo(
    () => processes.find((process) => process.id === sourceProcessId),
    [processes, sourceProcessId]
  );

  const availableProcesses = useMemo(
    () =>
      getAvailableProcessesForConnection(
        processes,
        sourceProcess,
        linkedProcesses
      ),
    [processes, sourceProcess, linkedProcesses]
  );

  const selectedProcess = useMemo(
    () =>
      availableProcesses.find((process) => process.id === selectedProcessId),
    [availableProcesses, selectedProcessId]
  );

  return (
    <div className="mt-6 flex flex-col gap-6">
      {availableProcesses.length > 0 ? (
        <RadioGroup
          value={selectedProcessId}
          onValueChange={setSelectedProcessId}
          className="max-h-[300px] overflow-y-auto"
        >
          {availableProcesses.map((process) => (
            <div
              key={process.id}
              className={cn(
                'flex items-center gap-3',
                'px-4 py-2',
                'rounded-md border',
                'hover:bg-color-bg-accent/20 cursor-pointer'
              )}
              onClick={() => setSelectedProcessId(process.id)}
            >
              <RadioCheckbox id={process.id} value={process.id} />
              <TextTruncate className="cursor-pointer" text={process.name} />
              <Badge
                variant={processTypeBadgeVariant[process.type]}
                className="ml-auto"
              >
                {processStateLabelVariant[process.type]}
              </Badge>
            </div>
          ))}
        </RadioGroup>
      ) : (
        <div className="text-color-text-subtext rounded-md border p-4 text-sm">
          No processes to connect to.
        </div>
      )}
      {selectedProcess ? (
        <FooterWithSelectedProcess
          selectedProcess={selectedProcess}
          sourceProcessId={sourceProcessId}
          sourceOutcomeId={sourceOutcomeId}
          isConnectDisabled={processes.length === 0 || !selectedProcessId}
          onClose={closeDialog}
        />
      ) : (
        <FooterActions
          isConnectDisabled={processes.length === 0 || !selectedProcessId}
          onClose={closeDialog}
        />
      )}
    </div>
  );
};
