import { HTMLAttributes, forwardRef } from 'react';

import { cn } from '@spektr/style-utils';

export type TableProps = HTMLAttributes<HTMLTableElement>;

export const Table = forwardRef<HTMLTableElement, TableProps>(
  ({ className, ...props }, ref) => (
    <table
      ref={ref}
      className={cn(
        'w-full',
        'border-separate border-spacing-0',
        'bg-color-bg-card-default caption-bottom text-sm',
        className
      )}
      {...props}
    />
  )
);
