import { queryOptions } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

import { DomainAliasDto } from '@spektr/shared/validators';

import { CUSTOM_DOMAIN_KEYS } from '../queryKeys';
import { UsersApiClient } from '../client';

export function getDomainSettingsQuery(
  shouldRefetch: boolean,
  interval?: number
) {
  return queryOptions({
    queryKey: CUSTOM_DOMAIN_KEYS.ALL(),
    /**
     * @description
     *      We start refetching when the verification is triggered by the user.
     *   We stop refetching when the domain status is active or deleted
     *   OR the `shouldRefetch` will timeout and become false.
     */
    refetchInterval(query) {
      const status = query.state?.data?.status;
      const records = query.state?.data?.resourceRecords ?? [];

      if (shouldRefetch) {
        return interval;
      }

      if (records.length === 0 && status && status !== 'deleted') {
        return interval ?? false;
      }

      return false;
    },
    queryFn: async (): Promise<DomainAliasDto | null> => {
      try {
        const response = await UsersApiClient.getClient().getDomainAlias();

        return response;
      } catch (error) {
        if (isAxiosError(error) && error.response?.status === 404) {
          return null;
        }

        throw error;
      }
    },
  });
}
