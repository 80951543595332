import { Suspense } from 'react';
import { Unplug } from 'lucide-react';

import {
  BasicDialog,
  IconWithBackground,
  SpektrErrorBoundary,
} from '@spektr/client/components';
import { handleAxiosError } from '@spektr/client/utils';

import { ProcessesList } from './components/ProcessesList';
import { ProcessesListSkeleton } from './components/ProcessesListSkeleton';
import { ProcessesListError } from './components/ProcessesListError';

type ProcessConnectDialogProps = {
  sourceProcessId: string;
  sourceOutcomeId: string;
  handleClose: () => void;
};

export const ProcessConnectDialog = ({
  sourceProcessId,
  sourceOutcomeId,
  handleClose,
}: ProcessConnectDialogProps) => {
  return (
    <BasicDialog
      title="Connect a Process"
      icon={
        <IconWithBackground className="bg-color-bg-accent mr-2 h-6 w-6">
          <Unplug className="text-color-text-icon-primary h-3 w-3" />
        </IconWithBackground>
      }
      dialogHeaderClassName="mb-2"
      className="w-[512px] rounded-2xl border-0"
      defaultOpen
      onClose={handleClose}
    >
      <SpektrErrorBoundary
        fallbackRender={({ error }) => (
          <ProcessesListError
            message={handleAxiosError(
              error,
              'Something went wrong when loading the available processes.'
            )}
          />
        )}
      >
        <Suspense fallback={<ProcessesListSkeleton />}>
          <ProcessesList
            sourceProcessId={sourceProcessId}
            sourceOutcomeId={sourceOutcomeId}
            closeDialog={handleClose}
          />
        </Suspense>
      </SpektrErrorBoundary>
    </BasicDialog>
  );
};
