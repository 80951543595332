import { z } from 'zod';

import { PaginationFields } from '../pagination';
import { objectIdSchema } from '../common';
import { clientRecordDetailsBaseSchema } from '../clientRecord';
import { NodeType } from '../node';

import {
  decisionSchema,
  hitDataSchema,
  hitListSchema,
  hitListSchemaResponse,
  hitSchemaResponse,
  vendorHitSchemaBaseWithId,
} from './hits';

export const alertStatuses = [
  'open',
  'escalated',
  'in_progress',
  'resolved',
] as const;
export const AlertStatuses = z.enum(alertStatuses);
export type AlertStatus = z.infer<typeof AlertStatuses>;

export const alertTypes = [
  'multiple_KYB_hit',
  'manual_review',
  'has_sanctions',
  'has_warnings',
  'has_fitness_and_probity',
  'has_adverse_media',
  'is_PEP',
  'is_sold',
  'is_registered',
  'has_branch',
  'has_changes',
  'is_deregistered',
  'in_collective_proceedings',
  'in_conciliation_proceedings',
  'form',
  'unknown', // for backwards compatibility
  'open_corporates_monitor_alert',
] as const;
export const AlertType = z.enum(alertTypes);
export type AlertType = z.infer<typeof AlertType>;

export const spektrAIStatuses = ['success', 'failure'] as const;
export const SpektrAIStatuses = z.enum(spektrAIStatuses);
export type SpektrAIStatuses = z.infer<typeof SpektrAIStatuses>;
export const SpektrAI = z.object({
  info: z.string().optional(),
  status: SpektrAIStatuses,
});
export type SpektrAI = z.infer<typeof SpektrAI>;

export const CustomerAlertFilter = z.object({
  userId: z.string().optional(),
  status: z.string().array().optional(),
  alertType: z.string().array().optional(),
  createdAtStart: z.string().optional(),
  createdAtEnd: z.string().optional(),
  updatedAtStart: z.string().optional(),
  updatedAtEnd: z.string().optional(),
  age: z.string().optional(),
  searchQuery: z.string().optional(),
});
export type CustomerAlertFilter = z.infer<typeof CustomerAlertFilter>;

export const AlertBaseModel = z.object({
  name: z.string(),
  status: z.string(),
  createdAt: z.number(),
  updatedAt: z.number(),
  type: AlertType,
  processId: objectIdSchema.optional(),
  spektrAi: z
    .object({
      info: z.string().optional(),
      status: z.string(),
    })
    .optional(),
  customer: z
    .object({ id: z.string() })
    .merge(clientRecordDetailsBaseSchema)
    .optional(),
});

// Service Response
export const AlertBaseModelWithHits = AlertBaseModel.merge(
  z.object({
    _id: objectIdSchema,
    hits: z.array(hitSchemaResponse),
  })
);
export type AlertBaseModelWithHits = z.infer<typeof AlertBaseModelWithHits>;
export const AlertBaseModelPaginatedResponse = PaginationFields.merge(
  z.object({
    docs: z.array(AlertBaseModelWithHits),
  })
);
export type AlertBaseModelPaginatedResponse = z.infer<
  typeof AlertBaseModelPaginatedResponse
>;

export const AlertWithCustomerAndHits = AlertBaseModelWithHits.merge(
  z.object({
    spektrId: z
      .object({ id: z.string() })
      .merge(clientRecordDetailsBaseSchema)
      .optional(),
  })
);
export type AlertWithCustomerAndHits = z.infer<typeof AlertWithCustomerAndHits>;
export const AlertWithCustomerAndHitsListResponse = PaginationFields.merge(
  z.object({
    docs: z.array(AlertWithCustomerAndHits),
  })
);
export type AlertWithCustomerAndHitsListResponse = z.infer<
  typeof AlertWithCustomerAndHitsListResponse
>;

// API schema response
export const Alert = AlertBaseModel.merge(
  z.object({
    id: objectIdSchema,
  })
);
export type Alert = z.infer<typeof Alert>;
export const AlertsListResponseSchema = PaginationFields.merge(
  z.strictObject({
    docs: z.array(Alert),
  })
);
export type AlertsListResponse = z.infer<typeof AlertsListResponseSchema>;

const taskSchema = z.object({
  id: objectIdSchema,
  title: z.string(),
  description: z.string().optional(),
  isCompleted: z.boolean().default(false),
});

export const alertSchemaBase = z.object({
  processId: objectIdSchema,
  contextId: objectIdSchema,
  workspaceId: z.string().min(1),
  nodeId: objectIdSchema,
  spektrId: objectIdSchema,
  datasetId: objectIdSchema,
  status: AlertStatuses,
  resolvedAt: z.number().optional(),
  resolvedBy: z.string().optional(),
  name: z.string().optional(),
  nextId: objectIdSchema.optional(),
  prevId: objectIdSchema.optional(),
  prevCustomerAlertId: objectIdSchema.optional(),
  nextCustomerAlertId: objectIdSchema.optional(),
});

export type AlertBase = z.infer<typeof alertSchemaBase>;
export const alertSchemaWithBaseHits = alertSchemaBase.merge(
  z.object({
    id: objectIdSchema,
    hits: z.array(vendorHitSchemaBaseWithId),
    tasks: z.array(taskSchema).optional(),
    createdAt: z.number(),
    updatedAt: z.number(),
  })
);

export type AlertBaseWithHits = z.infer<typeof alertSchemaWithBaseHits>;

export const alertSchemaResponse = alertSchemaBase.merge(
  z.object({
    id: objectIdSchema,
    hits: hitListSchemaResponse,
    tasks: z.array(taskSchema).optional(),
    createdAt: z.number(),
    updatedAt: z.number(),
    schemaVersion: z.number(),
    type: AlertType,
  })
);
export type AlertResponse = z.infer<typeof alertSchemaResponse>;
export const alertListResponseSchema = z.array(alertSchemaResponse);
export const alertListResponsePaginatedSchema = PaginationFields.merge(
  z.strictObject({
    docs: alertListResponseSchema,
  })
);
export type AlertListResponsePaginated = z.infer<
  typeof alertListResponsePaginatedSchema
>;

export const alertListSchema = z.array(alertSchemaWithBaseHits);
export type AlertList = z.infer<typeof alertListSchema>;

export const alertUpdateSchema = z.strictObject({
  status: AlertStatuses.optional(),
  hits: hitListSchema.optional(),
  tasks: z.array(taskSchema).optional(),
  reason: z.string().optional(),
});
export type AlertUpdate = z.infer<typeof alertUpdateSchema>;

const HitModelCreateSchema = z.object({
  id: z.string(),
  nodeType: NodeType,
  nodeId: z.string(),
  processId: z.string(),
  spektrId: z.string(),
  datasetId: z.string(),
  data: hitDataSchema,
  decision: decisionSchema.default({ status: 'pending' }),
});

export const AlertModelCreateSchema = z.object({
  processId: z.string(),
  contextId: z.string(),
  workspaceId: z.string().min(1),
  nodeId: z.string(),
  spektrId: z.string(),
  datasetId: z.string(),
  status: AlertStatuses,
  hits: z.array(HitModelCreateSchema),
  name: z.string().optional(),
  type: z.enum(alertTypes),
});
export type AlertModelCreate = z.infer<typeof AlertModelCreateSchema>;
export const getAlertByIdResponseSchema = alertSchemaWithBaseHits;
export type GetAlertByIdResponse = z.infer<typeof getAlertByIdResponseSchema>;

export const getAlertsResponseSchema = PaginationFields.merge(
  z.strictObject({
    docs: alertListSchema,
  })
);
export type GetAlertsResponse = z.infer<typeof getAlertsResponseSchema>;
