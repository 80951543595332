import { Link } from 'react-router-dom';

import {
  CASES_CUSTOMERS_FULL_URL,
  casesCustomerParamsSchema,
} from '@spektr/shared/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { getCustomerDetails } from '@spektr/client/services';
import { useParsedParams } from '@spektr/shared/hooks';

export const CustomerDetailsHeader = () => {
  const { spektrId: customerId } = useParsedParams(casesCustomerParamsSchema);
  const { data: customer } = useSuspenseQuery(getCustomerDetails(customerId));

  return (
    <div className="text-color-text-subtext flex items-center gap-1.5 text-sm">
      <Link className="font-medium" to={CASES_CUSTOMERS_FULL_URL}>
        Cases
      </Link>

      {customer?.uboDetails &&
        customer?.uboDetails.customerLevel === 'secondary' && (
          <>
            <span>/</span>
            <span className="text-color-text-primary font-medium">
              {customer.uboDetails.companyName}
            </span>
          </>
        )}

      <span>/</span>
      <span className="text-color-text-primary font-medium">
        {customer.name ?? customerId}
      </span>
    </div>
  );
};
