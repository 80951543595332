import { Info } from 'lucide-react';

import {
  DialogFooter,
  Label,
  Skeleton,
  Tooltip,
} from '@spektr/client/components';

export const ContentSkeleton = () => (
  <div className="flex flex-col gap-4">
    <div className="my-2 flex flex-col gap-4 rounded-md border p-4">
      <div className="flex flex-col gap-4">
        <p className="text-color-text-subtext flex items-center gap-1 text-xs font-medium">
          Map attributes from your dataset
        </p>

        <div className="flex max-h-[280px] flex-col overflow-y-auto rounded-md border">
          <div className="flex h-[52px] shrink-0 items-center justify-start border-b px-4 text-left">
            <div className="flex h-auto items-center justify-start gap-3">
              <Skeleton className="h-5 w-5" />
              <Label className="text-color-text-primary text-left text-xs font-light">
                CPR
              </Label>
              <Tooltip content="Get the CPR number of the individual.">
                <Info className="text-color-text-icon-secondary h-3 w-3" />
              </Tooltip>
            </div>
          </div>

          <div className="flex h-[52px] shrink-0 items-center justify-start border-b px-4 text-left">
            <div className="flex h-auto items-center justify-start gap-3">
              <Skeleton className="h-5 w-5" />
              <Label className="text-color-text-primary text-left text-xs font-light">
                Address
              </Label>
              <Tooltip content="Get the address of the individual.">
                <Info className="text-color-text-icon-secondary h-3 w-3" />
              </Tooltip>
            </div>
          </div>

          <div className="flex shrink-0 flex-col gap-2 border-b-0 p-4">
            <div>
              <Label>Login Type</Label>
            </div>
            <div className="ml-5 flex flex-col gap-2">
              <Skeleton className="h-4 w-12" />
              <Skeleton className="h-4 w-20" />
              <Skeleton className="h-4 w-14" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <DialogFooter>
      <Skeleton className="h-9 w-36" />
    </DialogFooter>
  </div>
);
