import { Skeleton } from '../../Skeleton';
import { TableBody } from '../../Table';

import { CasesTable, CasesTableCell, CasesTableRow } from '../CasesTable';

import { AlertsTableHeader } from './AlertsTableHeader';

type SkeletonAlertsTableProps = {
  columns: string[];
};

export const SkeletonAlertsTable = ({ columns }: SkeletonAlertsTableProps) => {
  return (
    <CasesTable>
      <AlertsTableHeader columns={columns} />
      <TableBody>
        {[...Array(7)].map((_, index) => (
          <CasesTableRow key={index}>
            {columns.includes('date') && (
              <CasesTableCell className="w-36 rounded-l-md border-l">
                <div className="flex items-center justify-center">
                  <Skeleton className="w-12" />
                </div>
              </CasesTableCell>
            )}
            {columns.includes('alert') && (
              <CasesTableCell>
                <Skeleton className="w-20" />
              </CasesTableCell>
            )}
            {columns.includes('customer') && (
              <CasesTableCell>
                <div className="flex flex-col gap-1">
                  <Skeleton className="h-4 w-12" />
                  <Skeleton className="h-3 w-20" />
                </div>
              </CasesTableCell>
            )}
            {columns.includes('alertType') && (
              <CasesTableCell>
                <Skeleton className="w-12" />
              </CasesTableCell>
            )}
            {columns.includes('spektrAI') && (
              <CasesTableCell>
                <Skeleton className="w-12" />
              </CasesTableCell>
            )}
            {columns.includes('status') && (
              <CasesTableCell className="rounded-r-md border-r">
                <Skeleton className="w-12" />
              </CasesTableCell>
            )}
            {columns.includes('actions') && (
              <CasesTableCell className="rounded-r-md border-r"></CasesTableCell>
            )}
          </CasesTableRow>
        ))}
      </TableBody>
    </CasesTable>
  );
};
