import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SpektrField } from '@spektr/shared/types';

import { ServiceCommonMappingForm } from '../../../../components/ServiceCommonMappingForm';

type FieldMappingProps = {
  spektrFields: SpektrField[];
};

export const FieldMapping = ({ spektrFields }: FieldMappingProps) => {
  const { t } = useTranslation();

  const formEntries = useMemo(
    () => [
      {
        key: 'companyNumber',
        label: t('fields:openCorporatesMonitoring.company_number'),
        type: 'string',
        description: '',
      },
      {
        key: 'countryCode',
        label: t('fields:openCorporatesMonitoring.jurisdiction_code'),
        type: 'string',
        description: '* Mandatory',
      },
      {
        key: 'companyName',
        label: t('fields:openCorporatesMonitoring.company_name'),
        type: 'string',
        description: '',
      },
    ],
    [t]
  );

  return (
    <ServiceCommonMappingForm
      spektrFields={spektrFields}
      form={formEntries}
      title={t('process:baseMapAttributesTitle')}
    />
  );
};
