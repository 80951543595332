import { produce } from 'immer';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getPublicStaticUrl, hasErrorMessage } from '@spektr/client/utils';
import { cn } from '@spektr/style-utils';

import { USERS_KEYS, UsersApiClient } from '@spektr/client/services';

import { ScrollArea, toast } from '@spektr/client/components';

import { type FormBranding } from '@spektr/shared/validators';

import { useContentHeight } from '../../hooks/useContentHeight';
import { useFormEngine } from '../../hooks/useFormEngine';
import { LogoField } from '../../components/LogoField';

import { ColorField } from '../FieldConfigSidebar/components/fields';

type BrandingKeys =
  | 'primaryColor'
  | 'backgroundColor'
  | 'borderColor'
  | 'textColor'
  | 'subTextColor';

type LayoutStylingSidebarProps = {
  theme: FormBranding;
};

export const LayoutStylingSidebar = ({ theme }: LayoutStylingSidebarProps) => {
  const { state, updateBranding } = useFormEngine();
  const queryClient = useQueryClient();
  const height = useContentHeight();

  const updateBrandingMutation = useMutation({
    mutationFn: (payload: FormData) =>
      UsersApiClient.getClient().uploadBrandingLogoByBrandingId(payload, {
        params: {
          id: theme.id,
        },
      }),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: USERS_KEYS.BRANDING_THEME(),
      });
    },
    onError: (error) => {
      const message = hasErrorMessage(error)
        ? error.message
        : 'An error occured, please try again.';

      toast.error({
        title: "We couldn't upload the file!",
        description: message,
      });
      throw error;
    },
  });

  const handleChangeBranding = (field: BrandingKeys, value: string) => {
    updateBranding(
      produce(state.branding, (draft) => {
        if (field === 'primaryColor') {
          draft.primaryColor = value;
        }
        if (field === 'backgroundColor') {
          draft.backgroundColor = value;
        }
        if (field === 'borderColor') {
          draft.borderColor = value;
        }
        if (field === 'textColor') {
          draft.textColor = value;
        }
        if (field === 'subTextColor') {
          draft.subTextColor = value;
        }
      })
    );
  };

  const handleDropAccepted: LogoField.OnDropAcceptedFn = async ([
    acceptedFile,
  ]) => {
    const formData = new FormData();

    formData.append('file', acceptedFile);

    const response = await updateBrandingMutation.mutateAsync(formData);

    updateBranding(
      produce(state.branding, (draft) => {
        draft.logoSrc = response.logoSrc;
      })
    );
  };

  const handleDeleteLogo = () => {
    updateBranding(
      produce(state.branding, (draft) => {
        draft.logoSrc = '';
      })
    );
  };

  const logoSrc = state.branding.logoSrc
    ? new URL(state.branding.logoSrc, getPublicStaticUrl()).toString()
    : undefined;

  return (
    <div className={cn('h-full w-full border-r', 'bg-color-bg-moonraker')}>
      <ScrollArea
        style={{
          height,
        }}
      >
        <div className={cn('p-3')}>
          <label className="text-color-text-primary text-sm font-semibold capitalize">
            Default styling
          </label>
        </div>
        <div className="flex flex-col gap-4 pb-2">
          <LogoField
            label="Logo"
            isUploadPending={updateBrandingMutation.isPending}
            src={logoSrc}
            onDropAccepted={handleDropAccepted}
            onDelete={handleDeleteLogo}
          />
          <ColorField
            label="Primary color"
            value={state.branding.primaryColor}
            onChange={(value) =>
              handleChangeBranding('primaryColor', value as string)
            }
          />
          <ColorField
            label="Background color"
            value={state.branding.backgroundColor}
            onChange={(value) =>
              handleChangeBranding('backgroundColor', value as string)
            }
          />
          <ColorField
            label="Border color"
            value={state.branding.borderColor}
            onChange={(value) =>
              handleChangeBranding('borderColor', value as string)
            }
          />
          <ColorField
            label="Text color"
            value={state.branding.textColor}
            onChange={(value) =>
              handleChangeBranding('textColor', value as string)
            }
          />
          <ColorField
            label="Subtext color"
            value={state.branding.subTextColor}
            onChange={(value) =>
              handleChangeBranding('subTextColor', value as string)
            }
          />
        </div>
      </ScrollArea>
    </div>
  );
};
