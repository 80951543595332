import { DialogFooter, Skeleton } from '@spektr/client/components';

export const RouterSkeleton = () => (
  <div className="flex flex-col gap-4 px-2">
    <section className="rounded-radius flex flex-col border">
      <div className="flex h-12 items-center px-4">
        <Skeleton className="h-7 w-80" />
      </div>
      <div className="flex flex-col gap-3 px-3 py-4">
        <Skeleton className="h-7 w-full" />
        <Skeleton className="h-7 w-full" />
      </div>
    </section>
    <div className="rounded-radius flex w-full justify-center border border-dashed py-5">
      <Skeleton className="h-10 w-28" />
    </div>
    <DialogFooter className="pt-6">
      <Skeleton className="h-9 w-16" />
    </DialogFooter>
  </div>
);
