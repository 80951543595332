import { ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

import { Label } from '@spektr/client/components';

export namespace SlackFieldRow {
  export type Props = {
    className?: string;
    children: ReactNode;
    isRequired?: boolean;
    title: string;
  };
}

export const SlackFieldRow = ({
  className,
  children,
  isRequired,
  title,
}: SlackFieldRow.Props) => {
  return (
    <div
      className={cn('flex w-full items-center border-b px-4 py-2', className)}
    >
      <Label className="line-clamp-1">{title}</Label>
      <div className="ml-auto flex items-center gap-2">
        {isRequired && <p className="text-xs">* Mandatory</p>}
        <div className="w-60">{children}</div>
      </div>
    </div>
  );
};
