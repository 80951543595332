import { z } from 'zod';

import { objectIdSchema } from '../common';

export const secretKeys = [
  'apiKey',
  'apiSecret',
  'clientSecret',
  'apiKeyV1',
  'apiKeyV2',
];

const openCorporatesSecretSchema = z.object({
  apiKey: z.string().min(1),
});
const complyAdvantageSecretSchema = z.object({
  apiKey: z.string().min(1),
});
const kyckrSecretSchema = z.object({
  apiKeyV1: z.string().min(1),
  apiKeyV2: z.string().min(1),
});
const veriffSecretSchema = z.object({
  apiKey: z.string().min(1),
  apiSecret: z.string().min(1),
});
const veriffIdvSecretSchema = z.object({
  apiKey: z.string().min(1),
  apiSecret: z.string().min(1),
});
const mitIdSecretSchema = z.object({
  clientId: z.string().min(1),
  clientSecret: z.string().min(1),
  domain: z.string().min(1),
});
const virkSecretSchema = z.object({
  username: z.string().min(1),
  password: z.string().min(1),
});

const secretSchema = z
  .union([
    openCorporatesSecretSchema,
    complyAdvantageSecretSchema,
    kyckrSecretSchema,
    veriffSecretSchema,
    veriffIdvSecretSchema,
    mitIdSecretSchema,
    virkSecretSchema,
    z.object({}),
  ])
  .optional();

export const vendorSecretSchemaMapping = {
  openCorporates: openCorporatesSecretSchema,
  complyAdvantage: complyAdvantageSecretSchema,
  kyckr: kyckrSecretSchema,
  veriff: veriffSecretSchema,
  veriffIdv: veriffIdvSecretSchema,
  mitId: mitIdSecretSchema,
  virk: virkSecretSchema,
};

type VendorSecretSchemaMapping = typeof vendorSecretSchemaMapping;
export type VendorName = keyof VendorSecretSchemaMapping;

export const vendorSettingsCreateSchema = z.object({
  vendor: z.string().min(1),
  secret: secretSchema,
});
export type VendorSettingsCreate = z.infer<typeof vendorSettingsCreateSchema>;

export const vendorSettingsBaseSchema = z.object({
  vendor: z.string().min(1),
  secret: secretSchema.optional(),
  createdBy: z.string(),
  updatedBy: z.string(),
});

export type VendorSettingsBase = z.infer<typeof vendorSettingsBaseSchema>;

export const vendorSettingsSchema = vendorSettingsBaseSchema.merge(
  z.strictObject({
    id: objectIdSchema.optional(),
    createdAt: z.number().optional(),
    updatedAt: z.number().optional(),
    workspaceId: z.string(),
  })
);
export type VendorSettings = z.infer<typeof vendorSettingsSchema>;

export const VendorSettingsConnUpdateSchema = z.object({
  secret: secretSchema.optional(),
});
export type VendorSettingsConnUpdate = z.infer<
  typeof VendorSettingsConnUpdateSchema
>;

export const VendorSettingsFilterBodySchema = z.union([
  z.literal('all'),
  z.literal('configured'),
  z.literal('notConfigured'),
]);
export type VendorSettingsFilterBody = z.infer<
  typeof VendorSettingsFilterBodySchema
>;
