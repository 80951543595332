import { useMemo } from 'react';

import {
  ClientRecordStatus,
  clientRecordStatuses,
} from '@spektr/shared/validators';
import { DropdownOption } from '@spektr/client/components';

export const useGetStatusOptions = (
  selectedStatus: ClientRecordStatus | undefined,
  onStatusSelect: (status: ClientRecordStatus) => void
) => {
  const options = useMemo(
    () =>
      clientRecordStatuses.reduce(
        (acc: DropdownOption[], status: ClientRecordStatus) => {
          if (acc.length > 0) {
            acc.push({
              value: `divider-${acc.length}`,
              type: 'separator',
            });
          }

          acc.push({
            value: status,
            label: status.charAt(0).toUpperCase() + status.slice(1),
            type: 'item',
            selected: status === selectedStatus,
          });

          return acc as DropdownOption[];
        },
        []
      ),
    [selectedStatus]
  );

  const onOptionClick = (status: string) => {
    if (status !== selectedStatus) {
      onStatusSelect(status as ClientRecordStatus);
    }
  };

  return [options, onOptionClick] as const;
};
