import { useCallback, useMemo, useState } from 'react';
import { produce } from 'immer';

import { type ServiceFieldClient } from '@spektr/client/types';
import { type KyckrOutcome } from '@spektr/shared/types';

import { getSelectedKyckrFields } from '../utils/getSelectedKyckrFields';

export function useKyckrSelectedFields(serviceFields: ServiceFieldClient[]) {
  const [selectedFields, setSelectedFields] = useState<KyckrOutcome[]>(
    getSelectedKyckrFields(serviceFields)
  );

  const handleFieldChange = useCallback(
    (fieldKey: KyckrOutcome, value: boolean) => {
      setSelectedFields(
        produce((draft) => {
          if (value) {
            draft.push(fieldKey);
          } else {
            const index = draft.findIndex((item) => item === fieldKey);

            if (index !== -1) {
              draft.splice(index, 1);
            }
          }
        })
      );
    },
    [setSelectedFields]
  );

  return useMemo(
    () => ({
      selectedFields,
      handleFieldChange,
    }),
    [selectedFields, handleFieldChange]
  );
}
