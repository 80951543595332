import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Accordion } from '@spektr/client/components';

import { cn } from '@spektr/style-utils';

import { CategoryList } from '../CategoryList';
import { EmptyList } from '../EmptyList';

import type { DraggableItem } from '../../types';

export type DraggableItemsListProps = {
  className?: string;
  data: DraggableItem[];
  renderItem: (item: DraggableItem) => JSX.Element;
};

export const DraggableItemsList = ({
  className,
  data,
  renderItem,
}: DraggableItemsListProps) => {
  const { t } = useTranslation('moonraker-form');

  const categories = useMemo(() => {
    return data.reduce(
      (acc, block) => {
        if (!acc[block.category]) {
          acc[block.category] = [];
        }
        acc[block.category].push(block);
        return acc;
      },
      {} as Record<string, DraggableItem[]>
    );
  }, [data]);

  if (!data.length) {
    return <EmptyList />;
  }

  return (
    <Accordion
      className="w-full"
      defaultValue={Object.keys(categories)}
      type="multiple"
    >
      {Object.entries(categories).map(([category, items]) => (
        <CategoryList
          key={category}
          name={category}
          title={t(`categories.${category}`)}
        >
          <ul className={cn('flex flex-col gap-2', className)}>
            {items.map((item) => (
              <li key={item.id}>{renderItem(item)}</li>
            ))}
          </ul>
        </CategoryList>
      ))}
    </Accordion>
  );
};
