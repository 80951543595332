import { HTMLAttributes } from 'react';

import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

export const badgeVariants = cva(
  cn(
    'inline-flex items-center px-3 py-1',
    'rounded-full transition-colors',
    'font-normal leading-none'
  ),
  {
    variants: {
      variant: {
        default: 'text-color-text-badge border-color-border-card border',
        cyan: 'bg-color-cyan/20 text-color-cyan',
        yellow: 'bg-color-yellow/20 text-color-yellow',
        red: 'bg-color-red/20 text-color-red',
        blue: 'bg-color-blue/20 text-color-blue',
        success: 'bg-color-secondary-green/20 text-color-secondary-green',
        danger: 'bg-color-secondary-red/20 text-color-secondary-red',
        black: 'bg-spektr-zinc-600/20 bg-spektr-zinc-600',
      },
      size: {
        default: 'h-[22px] text-xs font-medium',
        sm: 'h-[20px] text-[10px] font-medium',
        xs: 'h-[18px] text-[8px] font-medium',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export type BadgeVariants = VariantProps<typeof badgeVariants>['variant'];

export type BadgeProps = HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof badgeVariants>;

export function Badge({ className, variant, size, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, size }), className)}
      {...props}
    />
  );
}
