import { WithRequired, OneOrMany } from '@spektr/shared/types';

export function isError(error: unknown): error is Error {
  return error instanceof Error;
}

export const formDataToRecord = (formData: FormData) => {
  const result: Record<string, string> = {};
  formData.forEach((value, key) => {
    if (typeof value === 'string') result[key] = value;
  });

  return result;
};

export const getTailWithMaxLength = <T>(array: T[], maxLength: number) =>
  array.slice(array.length - maxLength, array.length);

export const removeLastItem = <T>(array: T[]) =>
  array.slice(0, array.length - 1);

export const insertAtIndex = <T>(array: T[], index: number, element: T) => [
  ...array.slice(0, index),
  element,
  ...array.slice(index, array.length),
];

export function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(' ');
}

export function asArray<T>(input: OneOrMany<T> | undefined): T[] {
  return Array.isArray(input) ? input : input !== undefined ? [input] : [];
}

export const getFileExtension = (fileOrName: string | File) => {
  const name = typeof fileOrName === 'string' ? fileOrName : fileOrName.name;
  return name.split('.').pop();
};

export const count = <T>(array: T[], predicate: (item: T) => boolean) =>
  array.reduce((acc, item) => (predicate(item) ? acc + 1 : acc), 0);

/**
 * Assertion function that narrows down the type of an object to include a specific property.
 * @param object the object to narrow down
 * @param property the property to check for
 * @returns
 */
export function hasProperty<T extends object, TProperty extends keyof T>(
  object: T,
  property: TProperty
): object is WithRequired<T, TProperty> {
  return property in object;
}

export function includes<T extends U, U>(
  array: ReadonlyArray<T>,
  element: U
): element is T {
  return array.includes(element as T);
}

export function assertUnreachable(x: never): never {
  throw new Error(
    'assertUnreachable received an unexpected value: ' + JSON.stringify(x)
  );
}

export function hasErrorMessage(error: unknown): error is { message: string } {
  return typeof error === 'object' && error !== null && 'message' in error;
}

export function verifyIsSubset(args: { set: string[]; subset: string[] }) {
  const { set, subset } = args;

  for (const element of subset) {
    if (!set.includes(element)) return false;
  }

  return true;
}

export function findFirstDuplicateByProperty<
  TElement extends object,
  TProperty extends keyof TElement,
>(fields: TElement[], property: TProperty) {
  for (let i = 0; i < fields.length; i++) {
    for (let j = i + 1; j < fields.length; j++) {
      const first = fields.at(i);
      const second = fields.at(j);

      if (first && second && first[property] === second[property]) {
        return first[property];
      }
    }
  }

  return undefined;
}

export function uniqueByProperty<TObject extends object>(
  objects: TObject[],
  property: keyof TObject
): TObject[] {
  const seen = new Set();
  const uniques = objects.filter((obj) => {
    const value = obj[property];
    return seen.has(value) ? false : seen.add(value);
  });

  return uniques;
}

export function isFullfilled<T>(
  result: PromiseSettledResult<T>
): result is PromiseFulfilledResult<T> {
  return result.status === 'fulfilled';
}

export function isRejected<T>(
  result: PromiseSettledResult<T>
): result is PromiseRejectedResult {
  return result.status === 'rejected';
}

export function unwrapPromiseResults<T>(results: PromiseSettledResult<T>[]) {
  const rejections = results.filter(isRejected);
  const fulfilleds = results.filter(isFullfilled);

  return {
    rejections: rejections.map((r) => r.reason),
    fulfilleds: fulfilleds.map((r) => r.value),
  };
}

export function isKeyDefined<
  TObject extends object,
  TKey extends keyof TObject,
>(obj: TObject, key: TKey): obj is WithRequired<TObject, TKey> {
  return !!obj[key];
}

export const objectKeys = <T extends object>(obj: T) => {
  return Object.keys(obj) as Array<keyof T>;
};

export function groupBy<T extends object, K extends keyof T>(
  array: T[],
  key: K
): Partial<Record<string, T[]>> {
  const groups: Partial<Record<string, T[]>> = {};

  for (const item of array) {
    const value = String(item[key]);
    const group = groups[value] ?? [];
    group.push(item);
    groups[value] = group;
  }

  return groups;
}
