import { DatePicker, Label } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

import { useProcessScheduleContext } from '../../providers';

export const ProcessTrigerStartDate = () => {
  const { startDate, setStartDate } = useProcessScheduleContext();

  const setStartDateTime = (startDate: number) => {
    const dateAtMidday = new Date(startDate).setUTCHours(12, 0, 0, 0);
    return new Date(dateAtMidday).setUTCDate(
      new Date(dateAtMidday).getDate() + 1
    );
  };

  const handleOnChange = (date?: number | string) => {
    if (date) {
      setStartDate(setStartDateTime(+date));
    }
  };

  const tomorrow = () => {
    const today = new Date();
    const tomorrow = today.setUTCDate(today.getUTCDate() + 1);
    return new Date(tomorrow);
  };

  return (
    <div className="flex flex-col gap-1.5">
      <Label>Start date</Label>
      <DatePicker
        epochOrToday={startDate || new Date().getTime()}
        className={cn(
          'h-9 w-[248px]',
          'text-color-text-primary hover:text-color-text-primary',
          'bg-color-bg-dialog-content dark:bg-transparent'
        )}
        showToday={false}
        iconClassName="hidden"
        disabled={{ before: tomorrow() }}
        dropdownArrow
        onChange={handleOnChange}
      />
    </div>
  );
};
