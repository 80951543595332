import { RgbaColor } from '@spektr/branding-types';

import { cn } from '@spektr/style-utils';

type DotIconProps = {
  className?: string;
  color?: RgbaColor;
};

export const DotIcon = ({ className, color }: DotIconProps) => {
  return (
    <div
      className={cn(
        'flex h-3 w-3 shrink-0 items-center justify-center p-0.5',
        className
      )}
    >
      <span
        className={cn(
          'h-full w-full',
          'rounded-full',
          'bg-color-text-subtext/20'
        )}
        style={{
          backgroundColor: color,
        }}
      />
    </div>
  );
};
