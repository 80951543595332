import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { objectIdSchema, spektrFieldDetailsSchema } from '../common';
import { paginatedParameters } from '../pagination';
import { insightsResponsePaginatedSchema } from '../executionContext';

import { filterParameters } from '../filters';

import { getAlertsResponseSchema } from '../alerts';

import {
  DatasetCreate,
  datasetResponseSchema,
  datasetWithMetaExtendedResponseSchema,
  datasetFieldsUpdateSchema,
  datasetNameUpdateSchema,
} from './dataset';
import {
  datasetRecordResponsePaginatedSchema,
  datasetRecordSchema,
} from './record';

export const datasetApi = makeApi([
  {
    method: 'get',
    path: '/datasets/:id',
    alias: 'getDatasetById',
    description: 'Get the dataset by id',
    response: datasetWithMetaExtendedResponseSchema,
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: objectIdSchema,
      },
    ],
  },
  {
    method: 'get',
    path: '/datasets',
    alias: 'getAllDatasets',
    description: 'Get the list of all datasets',
    response: z.array(datasetResponseSchema),
  },
  {
    method: 'post',
    path: '/datasets',
    alias: 'createDataset',
    description: 'Create a dataset',
    response: datasetResponseSchema,
    parameters: [
      {
        name: 'input',
        type: 'Body',
        schema: DatasetCreate,
      },
    ],
  },
  {
    method: 'put',
    path: '/datasets/:id/name',
    alias: 'updateDatasetName',
    description: 'Update the dataset name by dataset id',
    response: datasetResponseSchema,
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: objectIdSchema,
      },
      {
        name: 'input',
        type: 'Body',
        schema: datasetNameUpdateSchema,
      },
    ],
  },
  {
    method: 'put',
    path: '/datasets/:id/fields',
    alias: 'updateDatasetFields',
    description: 'Update the dataset fields by dataset id',
    response: datasetResponseSchema,
    parameters: [
      {
        name: 'input',
        type: 'Body',
        schema: datasetFieldsUpdateSchema,
      },
      {
        name: 'id',
        type: 'Path',
        schema: objectIdSchema,
      },
    ],
  },
  {
    method: 'delete',
    path: '/datasets/:id',
    alias: 'deleteDatasetById',
    description: 'Deletes the dataset by id',
    response: z.union([z.void(), z.string().nullable()]),
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: objectIdSchema,
      },
    ],
  },
  {
    method: 'get',
    path: '/spektr-fields',
    alias: 'getAllSpektrFields',
    description: 'Get the list of all spektr fields',
    response: z.array(spektrFieldDetailsSchema),
  },
  {
    method: 'get',
    path: '/datasets/:id/records',
    alias: 'getRecordsByDatasetId',
    description: 'Get the records by dataset id',
    parameters: [
      ...paginatedParameters,
      ...filterParameters,
      {
        name: 'sortOrder',
        schema: z.enum(['asc', 'desc']).optional(),
        type: 'Query',
      },
      {
        name: 'sortField',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: datasetRecordResponsePaginatedSchema,
  },
  {
    method: 'get',
    path: '/datasets/:id/records/:recordId',
    alias: 'getDatasetRecordById',
    description: 'Get the record by id',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: objectIdSchema,
      },
      {
        name: 'recordId',
        type: 'Path',
        schema: objectIdSchema,
      },
    ],
    response: datasetRecordSchema,
  },
  {
    method: 'get',
    path: '/datasets/:id/records/:recordId/runs',
    alias: 'getExecutionsByRecordId',
    description: 'Get the runs by spektr id',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: objectIdSchema,
      },
      {
        name: 'recordId',
        type: 'Path',
        schema: objectIdSchema,
      },
      ...paginatedParameters,
    ],
    response: insightsResponsePaginatedSchema,
  },
  {
    method: 'get',
    path: '/datasets/:id/records/:recordId/alerts',
    alias: 'getAlertsByRecordId',
    description: 'Get the alerts by spektr id',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: objectIdSchema,
      },
      {
        name: 'recordId',
        type: 'Path',
        schema: objectIdSchema,
      },
      ...paginatedParameters,
    ],
    response: getAlertsResponseSchema,
  },
]);
