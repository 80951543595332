import { type FormNode } from '@spektr/shared/validators';

import { commonRenderError } from '../../utils/commonRenderError';

import { FallbackContent } from '../../components/FallbackContent';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { FormDialog } from './FormDialog';

export namespace FormDialogWrapper {
  export type Props = BaseDialogProps<FormNode>;
}
export const FormDialogWrapper = (props: FormDialogWrapper.Props) => {
  return (
    <FallbackContent
      renderError={commonRenderError(
        'Something went wrong when loading this node'
      )}
      fallback={<div />}
    >
      <FormDialog {...props} />
    </FallbackContent>
  );
};
