import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircleCheck, CircleDashed, GitBranchPlus } from 'lucide-react';

import { EdgeSchema, ProcessType } from '@spektr/shared/validators';
import { useCreateProcessWithSource } from '@spektr/shared/hooks';
import { loopDetailsUrl, processBuilderUrl } from '@spektr/shared/utils';
import { Process } from '@spektr/shared/types';

import { DropdownMenuComboBox, IconBox } from '@spektr/client/components';

import { ProcessConnectDialog } from '../../ProcessConnectDialog';
import { useGetAllowedProcessActions } from '../OutcomeNode/hooks/useGetAllowedProcessActions';
import { ProcessIcon } from '../OutcomeNode/components/ProcessIcon';

type BranchWithProcess = EdgeSchema & {
  linkedProcesses?: Process[];
};

type BranchItemProps = {
  branch: BranchWithProcess;
  processId: string;
  processType: ProcessType;
};

export const BranchItem = ({
  processId,
  processType,
  branch,
}: BranchItemProps) => {
  const linkedProcesses = branch.linkedProcesses ?? [];
  const navigate = useNavigate();
  const createProcess = useCreateProcessWithSource(processId, branch.id);
  const options = useGetAllowedProcessActions(
    linkedProcesses,
    processType === 'onboarding'
  );
  const [showConnectionDialog, setShowConnectionDialog] = useState(false);

  const onOptionClick = (option: string) => {
    const linkedProcess = linkedProcesses.find(
      (process) => process.id === option
    );

    switch (option) {
      case 'create-risk':
        createProcess('risk');
        break;
      case 'create-monitoring':
        createProcess('monitoring');
        break;
      case 'create-loop':
        createProcess('loop');
        break;
      case 'create-onboarding':
        createProcess('onboarding');
        break;
      case 'create-score':
        createProcess('score');
        break;
      case 'connect':
        setShowConnectionDialog(true);
        break;
      default:
        // Check if the action is a process id and navigate to the process
        if (linkedProcess) {
          const processUrl =
            linkedProcess.type === 'loop'
              ? loopDetailsUrl(option)
              : processBuilderUrl(option);

          navigate(processUrl);
        }
        break;
    }
  };

  return (
    <>
      <DropdownMenuComboBox
        side="right"
        modal
        itemClassName="text-sm p-2 w-full max-w-72"
        itemProps={{
          onSelect: (e) => {
            e.preventDefault();
          },
        }}
        trigger={
          <div className={'flex w-full items-center gap-2 p-2'}>
            <IconBox
              color="red"
              renderIcon={({ className }) => (
                <GitBranchPlus className={className} />
              )}
            />
            <span className="line-clamp-1 font-medium leading-none">
              {branch.name}
            </span>
            {linkedProcesses.length === 1 ? (
              <ProcessIcon
                processType={linkedProcesses[0].type}
                className="ml-auto h-3 w-3"
              />
            ) : linkedProcesses.length === 0 ? (
              <CircleDashed className="stroke-color-text-subtext ml-auto h-3 w-3" />
            ) : (
              <CircleCheck className="stroke-color-green ml-auto h-3 w-3" />
            )}
          </div>
        }
        triggerProps={{
          asChild: true,
        }}
        options={options}
        onClick={onOptionClick}
      />
      {showConnectionDialog && (
        <ProcessConnectDialog
          sourceProcessId={processId}
          sourceOutcomeId={branch.id}
          handleClose={() => setShowConnectionDialog(false)}
        />
      )}
    </>
  );
};
