import { useMeasure } from 'react-use';

import { Tooltip } from '@spektr/client/components';

export namespace NameWithTooltip {
  export type Props = {
    name: string;
    maxWidth?: number;
  };
}

export const NameWithTooltip = ({
  name,
  maxWidth = 160,
}: NameWithTooltip.Props) => {
  const [ref, { width }] = useMeasure<HTMLParagraphElement>();

  const needsTooltip = Math.ceil(width) > maxWidth;

  return (
    <div className="text-color-text-node relative text-sm font-medium">
      <p
        ref={ref}
        aria-hidden="true"
        className="-z-1 absolute -top-full h-0 opacity-0"
      >
        {name}
      </p>
      {needsTooltip ? (
        <Tooltip content={name} side="top">
          <p className="line-clamp-1">{name}</p>
        </Tooltip>
      ) : (
        <p className="line-clamp-1">{name}</p>
      )}
    </div>
  );
};
