export const RESPONSE_STALE_TIME = 1000 * 30;
export const RESPONSE_CACHE_TIME = 1000 * 60 * 5;
export const REQUEST_RETRY_COUNT = 1;

export const PROCESS_API_URL = `${window._env.API_URL}/v1`;
export const INGEST_API_URL = `${window._env.INGEST_API_URL}/v1`;
export const MAPPING_API_URL = `${window._env.MAPPING_API_URL}/v1`;
export const USER_API_URL = `${window._env.USER_API_URL}/v1`;
export const INSIGHTS_API_URL = `${window._env.INSIGHTS_API_URL}/v1`;

export const CLIENT_ID = window._env.CLIENT_ID;

export const LAUNCH_DARKLY_CLIENT_SIDE_ID =
  window._env.LAUNCH_DARKLY_CLIENT_SIDE_ID;
export const LAUNCH_DARKLY_LOG_LEVEL = window._env.LAUNCH_DARKLY_LOG_LEVEL;
