import { Zodios } from '@zodios/core';
import { pluginToken } from '@zodios/plugins';

import {
  ApiConfig,
  createBaseApiClient,
  getAccessToken,
} from '@spektr/client/utils';

import {
  alertsApi,
  commentsApi,
  nodesApi,
  processApi,
  riskMatrixApi,
  tagsApi,
  triggerApi,
} from '@spektr/shared/validators';

function createProcessApiClient(config: ApiConfig) {
  const instance = new Zodios(config.baseUrl, [
    ...alertsApi,
    ...commentsApi,
    ...nodesApi,
    ...processApi,
    ...riskMatrixApi,
    ...tagsApi,
    ...triggerApi,
  ]);

  instance.use(
    pluginToken({
      getToken: () =>
        Promise.resolve(getAccessToken(config.clientId) ?? undefined),
    })
  );

  return instance;
}

export const ProcessApiClient = createBaseApiClient(createProcessApiClient);
