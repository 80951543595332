import { Select, SelectOption } from '@spektr/client/components';

type TeamMemberAssignmentProps = {
  users: SelectOption[];
  assignedUser?: string;
  onChangeAssignedUser: (value: string) => void;
};

export const TeamMemberAssignment = ({
  users,
  assignedUser,
  onChangeAssignedUser,
}: TeamMemberAssignmentProps) => {
  return (
    <div className="space-between bg-color-bg-card-default dark:bg-color-bg-card-accent flex items-center justify-between rounded-md p-4">
      <span className="text-color-text-subtext text-sm font-medium">
        Assign team members
      </span>
      <Select
        className="w-72"
        value={assignedUser}
        options={users}
        onValueChange={(value) => onChangeAssignedUser(value)}
        placeholder="Assign team members"
        // TODO: @Alex - enable after api supports user assignment
        disabled
      />
    </div>
  );
};
