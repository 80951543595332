import { getPublicStaticUrl } from '@spektr/client/utils';
import { cn } from '@spektr/style-utils';

import { useFormRouter } from '../../hooks/useFormRouter';
import { useFormEngine } from '../../hooks/useFormEngine';

import { PageTitle } from './components/PageTitle';
import { PageDescription } from './components/PageDescription';
import { PageHelpPopup } from './components/PageHelpPopup';

export const FormHelpSidebar = () => {
  const { currentRoute } = useFormRouter();
  const { state } = useFormEngine();

  if (currentRoute === 'design' || currentRoute === 'layout') {
    const logoSrc = state.branding.logoSrc
      ? new URL(state.branding.logoSrc, getPublicStaticUrl()).toString()
      : undefined;
    return (
      <div
        className={cn(
          'flex grow flex-col',
          'p-6',
          'm-3',
          'box-border',
          'shrink-0',
          'rounded-3xl',
          'shadow-sm transition-all',
          state.branding.backgroundColor
        )}
        style={{
          backgroundColor: state.branding.backgroundColor,
        }}
      >
        <div className="flex flex-col p-6">
          {state.branding.logoSrc && (
            <div className="h-auto max-w-[150px]">
              <img alt="logo" src={logoSrc} className="object-scale-down" />
            </div>
          )}
          <div className="mt-10">
            <PageTitle
              style={{
                color: state.branding.textColor,
              }}
            >
              {state.assistingContent.title}
            </PageTitle>
          </div>
          <div className="mt-5 w-[307px]">
            <PageDescription
              description={state.assistingContent.description}
              style={{ color: state.branding.subTextColor }}
            />
          </div>
        </div>

        {state.assistingContent.support.display && (
          <div className="mt-auto">
            <PageHelpPopup
              title={state.assistingContent.support.title}
              text={state.assistingContent.support.content}
              style={{
                color: state.assistingContent.support.textColor,
                backgroundColor: state.assistingContent.support.backgroundColor,
              }}
            />
          </div>
        )}
      </div>
    );
  }

  return null;
};
