import { memo, useMemo } from 'react';

import { cn } from '@spektr/style-utils';

import { NodeServiceType } from '@spektr/shared/validators';
import { useServices } from 'libs/model-builder/containers/src/lib/ProcessSheet/config';

import {
  BodaccIcon,
  ComplyAdvantageIcon,
  IconBox,
  IconBoxVariants,
  KyckrMinimalIcon,
  MitIdMinimalIcon,
  OpenCorporatesIcon,
  VeriffMinimalIcon,
  VeriffIdvMinimalIcon,
  VirkMinimalIcon,
} from '@spektr/client/components';

export type ServiceNodeProps = {
  serviceType: NodeServiceType;
};

export const ServiceNode = memo(({ serviceType }: ServiceNodeProps) => {
  const listOfServices = [
    ...useServices('score'),
    ...useServices('risk'),
    ...useServices('monitoring'),
    ...useServices('loop'),
  ];
  const serviceItem = listOfServices.find((item) => item.type === serviceType);

  const serviceIcon = useMemo(() => {
    return (
      <IconBox
        size="lg"
        color={serviceItem?.variant as IconBoxVariants['color']}
        renderIcon={({ className }) => {
          switch (serviceType) {
            case 'bodacc':
              return <BodaccIcon className={cn(className, 'h-auto')} />;
            case 'complyAdvantageKyb':
            case 'complyAdvantageKyc':
              return <ComplyAdvantageIcon className={className} />;
            case 'kyckr':
              return <KyckrMinimalIcon className={className} />;
            case 'mitId':
              return <MitIdMinimalIcon className={className} />;
            case 'openCorporatesMonitoring':
            case 'openCorporatesRisk':
              return <OpenCorporatesIcon className={className} />;
            case 'veriff':
              return <VeriffMinimalIcon className={className} />;
            case 'veriffIdv':
              return <VeriffIdvMinimalIcon className={className} />;
            case 'virk':
              return <VirkMinimalIcon className={className} />;
            default:
              return null;
          }
        }}
      />
    );
  }, [serviceType, serviceItem?.variant]);

  return (
    <>
      {serviceIcon}
      <div className="flex flex-col">
        <h3 className="text-color-text-node line-clamp-1 text-sm font-medium">
          {serviceItem?.title}
        </h3>
        <span className="text-color-text-node-subtext text-xs">Service</span>
      </div>
      {serviceItem?.label && (
        <div
          className={cn(
            'absolute bottom-1 right-1',
            'bg-color-bg-accent rounded px-2 py-0.5',
            'text-color-text-node text-[10px] font-medium uppercase tracking-wide'
          )}
        >
          {serviceItem?.label}
        </div>
      )}
    </>
  );
});
