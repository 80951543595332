import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
} from '@spektr/client/components';
import { useDialogClose } from '@spektr/shared/hooks';

export const FallbackDialog = ({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose: () => void;
}) => {
  const [open, startExitAnimation] = useDialogClose();

  return (
    <Dialog open={open}>
      <DialogContent
        aria-describedby={undefined}
        size="large"
        className="text-color-text-dialog-default flex min-h-[200px] flex-col"
        requestStartExitAnimation={startExitAnimation}
        onEndExitAnimation={onClose}
        onEscapeKeyDown={onClose}
      >
        <VisuallyHidden asChild>
          <DialogTitle>Loading title</DialogTitle>
        </VisuallyHidden>
        <DialogClose className="self-end" onClick={startExitAnimation} />
        <div className="flex grow flex-col items-center justify-center">
          <div className="flex items-center gap-2">{children}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
