import { ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

type TabbarProps = {
  className?: string;
  children: ReactNode;
};

export const Tabbar = ({ children, className }: TabbarProps) => {
  return (
    <div
      className={cn(
        'flex h-10 w-full shrink-0 overflow-x-hidden',
        'border-b',
        className
      )}
    >
      <div
        className={cn(
          'relative',
          'inline-block h-full flex-auto flex-row',
          'no-scrollbar overflow-x-auto overflow-y-hidden',
          'whitespace-nowrap'
        )}
      >
        <nav className={cn('flex', 'h-full')} role="tablist">
          {children}
        </nav>
      </div>
    </div>
  );
};
