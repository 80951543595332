import { useMemo } from 'react';

import { Alert, AlertStatus } from '@spektr/shared/validators';

import { TableAlertData } from '@spektr/client/components';

export const useComputeAlertsTableData = (alerts: Alert[]) => {
  return useMemo(() => {
    return alerts.map((alert) => {
      return {
        id: alert.id,
        createdAt: alert.createdAt,
        name: alert.name,
        status: alert.status as AlertStatus,
        alertType: alert.type,
        customerId: alert.customer?.id,
        customerName: alert.customer?.name,
        customerType: alert.customer?.customerType,
        customerTitle: alert.customer?.uboDetails?.role,
        spektrAI: {
          label: alert.spektrAi?.info,
          status: alert.spektrAi?.status,
        },
      } as TableAlertData;
    });
  }, [alerts]);
};
