import { useEffect, useRef, useState } from 'react';
import { Range, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { useClickAway } from 'react-use';

import { cn } from '@spektr/style-utils';

import { insertMention } from '../utils';

import { Portal } from './Portal';

type MentionDropdownProps = {
  mentions: string[];
  selectedMentionIndex: number;
  target?: Range | null;
  onUpdateTarget: (target: Range | null) => void;
};

const defaultPlacement = {
  top: '-9999px',
  left: '-9999px',
};

export const MentionDropdown = ({
  mentions,
  selectedMentionIndex,
  target,
  onUpdateTarget,
}: MentionDropdownProps) => {
  const editor = useSlate();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  useClickAway(dropdownRef, () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  });

  useEffect(() => {
    if (target && mentions.length > 0) {
      const el = dropdownRef.current;
      const domRange = ReactEditor.toDOMRange(editor as ReactEditor, target);
      const rect = domRange.getBoundingClientRect();

      if (!rect || !el) return;

      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      const dropdownHeight = el.offsetHeight;
      const dropdownWidth = el.offsetWidth;
      const spaceBelow = viewportHeight - rect.bottom;
      const spaceRight = viewportWidth - rect.right;

      // Vertical Positioning
      if (spaceBelow < dropdownHeight) {
        // Position above if not enough space below
        el.style.top = `${rect.top + window.scrollY - dropdownHeight - 5}px`; // 5px for a little offset
      } else {
        // Position below
        el.style.top = `${rect.top + window.scrollY + 20}px`; // 20px for a little offset
      }

      // Horizontal Positioning
      if (spaceRight < dropdownWidth) {
        // Position to the left if not enough space to the right
        el.style.left = `${rect.left + window.scrollX - dropdownWidth + rect.width}px`;
      } else {
        // Position to the right
        el.style.left = `${rect.left + window.scrollX}px`;
      }

      setShowDropdown(true);
    } else {
      setShowDropdown(false);

      const el = dropdownRef.current;
      if (el) {
        el.style.top = defaultPlacement.top;
        el.style.left = defaultPlacement.left;
      }
    }
  }, [mentions.length, editor, selectedMentionIndex, target]);

  return (
    <Portal>
      <div
        data-cy="mention-trigger"
        className={cn(
          'z-50 max-h-64 overflow-y-auto',
          'bg-color-bg-primary text-color-text-editor rounded-md border'
        )}
        style={{
          position: 'absolute',
          opacity: showDropdown ? 1 : 0,
          ...defaultPlacement,
        }}
        ref={dropdownRef}
      >
        <ul className="flex flex-col p-2">
          {mentions.map((mention, index) => (
            <li
              key={`${mention}-${index}`}
              onClick={() => {
                target && Transforms.select(editor, target);
                insertMention(editor, mention);
                onUpdateTarget(null);
              }}
              className={cn(
                'w-72 px-3 py-1.5',
                'rounded-sm',
                '',
                'hover:bg-color-bg-accent/80',
                index === selectedMentionIndex && 'bg-color-bg-accent',
                'cursor-pointer'
              )}
            >
              <span className="line-clamp-1 text-sm">{mention}</span>
            </li>
          ))}
        </ul>
      </div>
    </Portal>
  );
};
