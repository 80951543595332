import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { cn } from '@spektr/style-utils';
import {
  CasesCustomerTabNavigation,
  SpektrErrorBoundary,
} from '@spektr/client/components';
import { handleAxiosError } from '@spektr/client/utils';
import { useParsedParams } from '@spektr/shared/hooks';
import { casesCustomerParamsSchema } from '@spektr/shared/utils';
import { ErrorPage } from '@spektr/shared/components';

import { CustomerDetailsProvider } from './providers/CustomerDetailsProvider';
import { CustomerDetailsTopbar } from './containers/CustomerDetailsTopbar';
import { CustomerSidebarDetails } from './containers/CustomerSidebarDetails';
import { SkeletonTopbar } from './components';

export const CasesCustomerDetails = () => {
  const { spektrId: customerId } = useParsedParams(casesCustomerParamsSchema);
  return (
    <div className="absolute bottom-0 top-0 flex w-full">
      <SpektrErrorBoundary
        fallbackRender={(error) => (
          <ErrorPage
            title={handleAxiosError(
              error,
              'Something went wrong when loading the customer details.'
            )}
          />
        )}
      >
        <Suspense fallback={<SkeletonTopbar />}>
          <CustomerDetailsProvider customerId={customerId}>
            <div
              className={cn(
                'flex flex-1 flex-col gap-6',
                'container mx-auto h-full p-6',
                'overflow-hidden overflow-y-auto'
              )}
            >
              <CustomerDetailsTopbar />
              <CasesCustomerTabNavigation />
              <Outlet />
            </div>
            <CustomerSidebarDetails />
          </CustomerDetailsProvider>
        </Suspense>
      </SpektrErrorBoundary>
    </div>
  );
};
