import { ReactNode } from 'react';

import { useCountUnresolvedAlerts } from '@spektr/platform-hooks';

import { BadgeNotificationsContext } from './context';

export type BadgeNotificationsProps = {
  children: ReactNode | ReactNode[];
};

export const BadgeNotifications = ({ children }: BadgeNotificationsProps) => {
  const unresolvedAlerts = useCountUnresolvedAlerts();

  return (
    <BadgeNotificationsContext.Provider value={{ unresolvedAlerts }}>
      {children}
    </BadgeNotificationsContext.Provider>
  );
};
