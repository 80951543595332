import { useFormContext, useFormState } from 'react-hook-form';

import { DialogFooterActions } from '../../../../components/DialogFooterActions';

export namespace DialogFooter {
  export type Props = {
    isPendingUpdate?: boolean;
    onSave: () => void;
  };
}

export const DialogFooter = ({
  isPendingUpdate,
  onSave,
}: DialogFooter.Props) => {
  const { control } = useFormContext();
  const { isValid } = useFormState({
    control,
  });

  return (
    <DialogFooterActions
      isServiceNode
      btnColor="openCorporates"
      disabled={!isValid}
      isPendingUpdate={isPendingUpdate}
      onSave={onSave}
    />
  );
};
