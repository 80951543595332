import { Filter } from 'lucide-react';

import { cn } from '@spektr/style-utils';
import {
  Button,
  DropdownMenuComboBox,
  DropdownOption,
} from '@spektr/client/components';
import { useState } from 'react';

const dropdownContentClassName = cn(
  'w-full min-w-60 shadow-md max-h-[500px]',
  'bg-color-bg-dropdown-secondary-content group'
);

const dropdownItemClassName = cn(
  'relative my-1 flex items-center justify-start gap-2 p-2.5 text-sm',
  'hover:bg-color-bg-dropdown-sub-trigger--focus/60 hover:no-underline',
  'focus:bg-color-bg-dropdown-sub-trigger--focus/80',
  'data-[state=open]:bg-color-bg-dropdown-sub-trigger--focus/80',
  'dark:focus:bg-color-bg-dropdown-sub-trigger--focus/40',
  'dark:data-[state=open]:bg-color-bg-dropdown-sub-trigger--focus/40',
  '[&_svg]:text-color-text-subtext',
  '[&_svg]:h-[18px] [&_svg]:w-[18px]'
);

export type AddCustomersFilterProps = {
  filtersOptions: DropdownOption[];
  handleClick: (optionValue: string) => void;
};

export const AddFilter = ({
  filtersOptions,
  handleClick,
}: AddCustomersFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <DropdownMenuComboBox
      align="center"
      side="bottom"
      sideOffset={8}
      options={filtersOptions}
      contentClassName={dropdownContentClassName}
      itemClassName={dropdownItemClassName}
      trigger={
        <Button
          variant="contained"
          color="icon"
          className={cn(
            'rounded-e-none',
            'text-color-text-subtext text-sm font-medium',
            isOpen && '!bg-color-bg-button-default--hover'
          )}
          startIcon={<Filter className="text-color-text-subtext h-3.5 w-3.5" />}
        >
          Add filter
        </Button>
      }
      onClick={(value) => handleClick(value)}
      onOpenChange={(open) => setIsOpen(open)}
    />
  );
};
