import { cn } from '@spektr/style-utils';

export const lexisNexisContained = cn(
  'bg-color-lexisNexis',
  'text-color-text-button-contained-vendor',
  'hover:bg-color-lexisNexis',
  'active:bg-color-lexisNexis'
);
export const lexisNexisOutlined = cn(
  lexisNexisContained,
  'border-color-lexisNexis',
  'text-color-lexisNexis',
  'hover:bg-color-lexisNexis/10',
  'active:bg-color-lexisNexis/20',
  'disabled:border-color-lexisNexis/50'
);
export const lexisNexisText = cn('text-color-lexisNexis');
