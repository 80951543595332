import { z } from 'zod';

export const roles = ['admin', 'editor', 'viewer'] as const;
export const jobTitles = [
  'C-suite',
  'Compliance',
  'Customer Success',
  'Engineering',
  'Finance',
  'Marketing',
  'Operations',
  'Product',
  'Risk',
  'Sales',
  'Other',
] as const;

export type UserRoles = (typeof roles)[number];
export type UserJobTitles = (typeof jobTitles)[number];

export const UserStatus = ['verified', 'unverified'] as const;
export type UserStatus = (typeof UserStatus)[number];

export function isRoleValid(role: string): role is UserRoles {
  return roles.includes(role as UserRoles);
}

const UserDetailsSchema = z.strictObject({
  username: z.string().min(1),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  jobTitle: z.enum(jobTitles).optional(),
});

export const UserInputSchema = UserDetailsSchema.merge(
  z.strictObject({
    role: z.enum(roles),
  })
);
export type UserInputSchema = z.infer<typeof UserInputSchema>;

export const UserSchema = UserDetailsSchema.merge(
  z.strictObject({
    id: z.string().min(1),
    role: z.enum(roles).optional(),
    status: z.enum(UserStatus),
  })
);
export type UserSchema = z.infer<typeof UserSchema>;

export const UserSignUpDisabledAdminSchema = z.strictObject({
  username: z.string().min(1),
  password: z.string().min(1),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
});

export type UserSignUpDisabledAdminSchema = z.infer<
  typeof UserSignUpDisabledAdminSchema
>;

export const AdminUserUpdateInputSchema = z.strictObject({
  role: z.enum(roles).optional(),
  firstName: z.string().min(1).optional(),
  lastName: z.string().min(1).optional(),
});
export type AdminUserUpdateInputSchema = z.infer<
  typeof AdminUserUpdateInputSchema
>;

export const UserOwnUpdateInputSchema = z.strictObject({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  jobTitle: z.enum(jobTitles).optional(),
});
export type UserOwnUpdateInputSchema = z.infer<typeof UserOwnUpdateInputSchema>;

export const UserUpdateSchema = UserDetailsSchema.merge(
  z.strictObject({
    id: z.string().min(1),
    role: z.enum(roles),
    status: z.enum(UserStatus),
  })
);

export type UserUpdateSchema = z.infer<typeof UserUpdateSchema>;

export const UserCognitoId = z.string().uuid();

export type UserCognitoId = z.infer<typeof UserCognitoId>;
