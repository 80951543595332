import { type OnboardingProcessSourceNode } from '@spektr/shared/types';

import { commonRenderError } from '../../utils/commonRenderError';

import { FallbackContent } from '../../components/FallbackContent';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { OnboardingSourceSkeleton } from './components/OnboardingSourceSkeleton';

import { OnboardingSourceDialog } from './OnboardingSourceDialog';

export namespace OnboardingSourceWrapper {
  export type Props = BaseDialogProps<OnboardingProcessSourceNode>;
}
export const OnboardingSourceWrapper = (
  props: OnboardingSourceWrapper.Props
) => {
  return (
    <FallbackContent
      renderError={commonRenderError(
        'Something went wrong when loading this node'
      )}
      fallback={<OnboardingSourceSkeleton />}
    >
      <OnboardingSourceDialog {...props} />
    </FallbackContent>
  );
};
