import { SpektrFieldWithInfo } from '../../goldenRecord';
import { commonSpektrFields } from '../commonSpektrFields/commonSpektrFields';

export const veriffSpektrFields: Array<Omit<SpektrFieldWithInfo, 'sourceId'>> =
  [
    {
      key: 'veriff.verification.status',
      type: 'string',
      source: 'veriff',
      label: 'Status',
    },
    {
      key: 'veriff.verification.reason',
      type: 'string',
      source: 'veriff',
      label: 'Reason',
    },
    {
      key: 'veriff.person.firstName',
      type: 'string',
      source: 'veriff',
      label: 'First name',
      commonSpektrFieldKeys: [commonSpektrFields.firstName.key],
    },
    {
      key: 'veriff.person.lastName',
      type: 'string',
      source: 'veriff',
      label: 'Last name',
      commonSpektrFieldKeys: [commonSpektrFields.lastName.key],
    },
    {
      key: 'veriff.person.dateOfBirth',
      type: 'date',
      source: 'veriff',
      label: 'Date of birth',
      commonSpektrFieldKeys: [commonSpektrFields.dateOfBirth.key],
    },
    {
      key: 'veriff.person.gender',
      type: 'string',
      source: 'veriff',
      label: 'Gender',
      commonSpektrFieldKeys: [commonSpektrFields.gender.key],
    },
    {
      key: 'veriff.person.nationality',
      type: 'string',
      source: 'veriff',
      label: 'Nationality',
      commonSpektrFieldKeys: [commonSpektrFields.nationality.key],
    },
    {
      key: 'veriff.person.idNumber',
      type: 'string',
      source: 'veriff',
      label: 'ID Number',
      commonSpektrFieldKeys: [commonSpektrFields.idNumber.key],
    },
    {
      key: 'veriff.person.yearOfBirth',
      type: 'string',
      source: 'veriff',
      label: 'Year of Birth',
      commonSpektrFieldKeys: [commonSpektrFields.yearOfBirth.key],
    },
    {
      key: 'veriff.person.fullName',
      type: 'string',
      source: 'veriff',
      label: 'Full Name',
      commonSpektrFieldKeys: [commonSpektrFields.fullName.key],
    },
    {
      key: 'veriff.person.occupation',
      type: 'string',
      source: 'veriff',
      label: 'Occupation',
      commonSpektrFieldKeys: [commonSpektrFields.occupation.key],
    },
    {
      key: 'veriff.person.employer',
      type: 'string',
      source: 'veriff',
      label: 'Employer',
      commonSpektrFieldKeys: [commonSpektrFields.employer.key],
    },
    {
      key: 'veriff.document.number',
      type: 'string',
      source: 'veriff',
      label: 'Document Number',
      commonSpektrFieldKeys: [commonSpektrFields.documentNumber.key],
    },
    {
      key: 'veriff.document.validFrom',
      type: 'date',
      source: 'veriff',
      label: 'Valid From',
      commonSpektrFieldKeys: [commonSpektrFields.documentValidFrom.key],
    },
    {
      key: 'veriff.document.validUntil',
      type: 'date',
      source: 'veriff',
      label: 'Valid Until',
      commonSpektrFieldKeys: [commonSpektrFields.documentValidUntil.key],
    },
    {
      key: 'veriff.document.type',
      type: 'string',
      source: 'veriff',
      label: 'Document Type',
      commonSpektrFieldKeys: [commonSpektrFields.documentType.key],
    },
    {
      key: 'veriff.document.country',
      type: 'string',
      source: 'veriff',
      label: 'Document Country',
      commonSpektrFieldKeys: [commonSpektrFields.documentCountry.key],
    },
    {
      key: 'veriff.document.firstIssue',
      type: 'date',
      source: 'veriff',
      label: 'First Issue Date',
      commonSpektrFieldKeys: [commonSpektrFields.documentFirstIssue.key],
    },
    {
      key: 'veriff.document.nfcValidated',
      type: 'boolean',
      source: 'veriff',
      label: 'NFC Validated',
      commonSpektrFieldKeys: [commonSpektrFields.documentNfcValidated.key],
    },
    {
      key: 'veriff.additionalVerifiedData.driverLicenseNumber',
      type: 'string',
      source: 'veriff',
      label: 'Driver License Number',
      commonSpektrFieldKeys: [commonSpektrFields.driverLicenceNumber.key],
    },
    {
      key: 'veriff.additionalVerifiedData.driversLicenseCategoryUntil.B',
      type: 'date',
      source: 'veriff',
      label: 'Drivers License Category Until B',
    },
    {
      key: 'veriff_service_failure',
      type: 'boolean',
      source: 'veriff',
      label: 'Veriff service call failed',
    },
  ];
