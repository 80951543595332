import { HTMLAttributes } from 'react';

export const MitIdMinimalIcon = (props: HTMLAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="13"
      fill="none"
      viewBox="0 0 22 13"
      {...props}
    >
      <g fill="#275EDF">
        <path d="M14.226.165c4.976 0 7.075 2.88 7.075 6.417 0 3.538-2.1 6.418-7.075 6.418h-2.02V.165h2.02z"></path>
        <path d="M5.287 0C3.654 0 2.332 1.399 2.332 3.127c0 1.727 1.322 3.126 2.955 3.126 1.632 0 2.954-1.399 2.954-3.126C8.24 1.399 6.919 0 5.287 0zm0 7.405C2.566 7.405.31 9.051 0 13h10.65c-.31-3.95-2.565-5.595-5.363-5.595z"></path>
      </g>
    </svg>
  );
};
