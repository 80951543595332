import { ClientAlertType } from '../types';

/**
 * @description
 *  Hits are divided into two categories: AML and KYB.
 *
 * @param service - service name
 * @returns Alert type in the client
 */
export function getAlertTypeBasedOnService(
  service: string | null
): ClientAlertType {
  if (service === 'complyAdvantageKyb' || service === 'complyAdvantageKyc') {
    return 'aml';
  }

  return 'kyb';
}
