import { Info } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { Checkbox, Label, Tooltip } from '@spektr/client/components';

import { type ServiceFieldClient } from '@spektr/client/types';

export type SelectableServiceItemProps = {
  field: ServiceFieldClient;
  checked?: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
};

export const SelectableServiceItem = ({
  field,
  checked,
  disabled,
  onChange,
}: SelectableServiceItemProps) => {
  const id = `${field.key}-checkbox`;

  return (
    <div
      className={cn(
        'flex h-auto items-center justify-start gap-3',
        'cursor-pointer'
      )}
    >
      <Checkbox
        id={id}
        checked={checked}
        disabled={disabled}
        onCheckedChange={(value) => {
          typeof value === 'boolean' && onChange(value);
        }}
      />
      <Label
        htmlFor={id}
        className={cn(
          'text-color-text-primary text-left text-sm font-light',
          'cursor-pointer',
          'whitespace-normal'
        )}
      >
        {field.label}
      </Label>
      {field.help && (
        <Tooltip content={field.help}>
          <Info className="text-color-text-icon-secondary h-3 w-3" />
        </Tooltip>
      )}
    </div>
  );
};
