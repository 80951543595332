import { CommentList, CommentParentType } from '@spektr/shared/validators';

import { ProcessApiClient } from '../client';
import { COMMENTS_KEYS } from '../queryKeys';

export function getCommentsByParentId(
  parentId: string,
  parentType: CommentParentType,
  limit = 10,
  page = 1
) {
  return {
    queryKey: COMMENTS_KEYS.ALL({ parentId, parentType, page, limit }),
    queryFn: ({ pageParam }: { pageParam: number }) =>
      ProcessApiClient.getClient().getCommentsByParentId({
        params: { parentId },
        queries: { parentType, page: pageParam, limit },
      }),
    initialPageParam: page,
    getNextPageParam: (lastPage: CommentList) => lastPage.nextPage,
  };
}

export function countCommentsByParentId(
  parentId: string,
  parentType: CommentParentType
) {
  return {
    queryKey: COMMENTS_KEYS.COUNT_BY_PARENT_ID(parentId, parentType),
    queryFn: () =>
      ProcessApiClient.getClient().countCommentsByParentId({
        params: { parentId },
        queries: { parentType },
      }),
  };
}
