import { cn } from '@spektr/style-utils';

export const bodaccContained = cn(
  'bg-color-bodacc',
  'text-color-white',
  'hover:bg-color-bodacc',
  'active:bg-color-bodacc',
  'border'
);
export const bodaccOutlined = cn(
  bodaccContained,
  'border-color-bodacc',
  'text-color-bodacc',
  'hover:bg-color-bodacc/10',
  'active:bg-color-bodacc/20',
  'disabled:border-color-bodacc/50'
);
export const bodaccText = cn('text-color-bodacc');
