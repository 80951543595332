import { z } from 'zod';

import { objectIdSchema } from '../common';

export const DataImportInput = z.array(z.record(z.unknown()));
export type DataImportInput = z.infer<typeof DataImportInput>;

export const DataImportFilesInput = z.array(z.string()).optional();
export type DataImportFilesInput = z.infer<typeof DataImportFilesInput>;

export const DataUpdateInputSchema = z.strictObject({
  data: DataImportInput,
});
export type DataUpdateInputSchema = z.infer<typeof DataUpdateInputSchema>;

export const DataImportInputSchema = z.strictObject({
  name: z.string().min(1),
  dataType: z.record(z.enum(['number', 'string', 'date', 'file'])),
});
export type DataImportInputSchema = z.infer<typeof DataImportInputSchema>;

export const DataImportResponseSchema = z.strictObject({
  datasetId: z.string().uuid(),
});
export type DataImportResponseSchema = z.infer<typeof DataImportResponseSchema>;

export const DataUpdateResponseDataFileItemSchema = z.strictObject({
  file: z.string(),
  url: z.string(),
  fields: z.record(z.string()),
});
export type DataUpdateResponseDataFileItemSchema = z.infer<
  typeof DataUpdateResponseDataFileItemSchema
>;

export const DataUpdateResponseDataItemSchema = z.strictObject({
  spektrId: objectIdSchema,
  files: z.array(DataUpdateResponseDataFileItemSchema),
});
export type DataUpdateResponseDataItemSchema = z.infer<
  typeof DataUpdateResponseDataItemSchema
>;

export const DataUpdateResponseSchema = z.strictObject({
  data: z.array(DataUpdateResponseDataItemSchema),
});
export type DataUpdateResponseSchema = z.infer<typeof DataUpdateResponseSchema>;
