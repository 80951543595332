import { forwardRef } from 'react';
import { cva } from 'class-variance-authority';
import { cn } from '@spektr/style-utils';

import { Description } from './Description';
import { Label } from './Label';

export type TextareaFieldProps = {
  description?: string;
  errorMessage?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  defaultValue?: string;
};

export const TextareaField = forwardRef<
  HTMLTextAreaElement,
  TextareaFieldProps
>(
  (
    {
      description,
      errorMessage,
      label,
      name,
      placeholder,
      defaultValue,
      ...props
    },
    ref
  ) => {
    return (
      <div className="flex flex-col gap-1">
        <Label htmlFor={name}>{label}</Label>
        {description && <Description>{description}</Description>}
        <textarea
          ref={ref}
          id={name}
          className={cn(
            inputVariants({ color: errorMessage ? 'error' : 'default' })
          )}
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          {...props}
        />
        {errorMessage && (
          <span className="text-color-red text-sm">{errorMessage}</span>
        )}
      </div>
    );
  }
);
TextareaField.displayName = 'TextareaField';

const inputVariants = cva(
  [
    'rounded-md border',
    'bg-color-bg-primary',
    'placeholder-color-text-placeholder',
    'text-sm text-color-text-form-viewer-textarea',
  ],
  {
    variants: {
      color: {
        default: 'border-color-border-form-viewer-primary',
        error: 'border-color-red',
      },
    },
    defaultVariants: {
      color: 'default',
    },
  }
);
