import { useTranslation } from 'react-i18next';

import { cn } from '@spektr/style-utils';

import { ServiceVendorLogo } from '@spektr/client/components';

import { VendorServiceName } from '@spektr/client/types';

export type ServiceCardProps = {
  className?: string;
  children: React.ReactNode;
  serviceName: VendorServiceName;
};

export const ServiceCard = ({
  className,
  children,
  serviceName,
}: ServiceCardProps) => {
  const { t } = useTranslation('vendors');

  return (
    <div
      className={cn(
        'flex flex-col justify-between',
        'min-h-[282px] w-[283px] p-4',
        'rounded-lg border',
        className
      )}
    >
      <div>
        <ServiceVendorLogo
          className={cn(
            'mb-4 h-[100px] w-full rounded-md',
            'flex items-center justify-center'
          )}
          serviceName={serviceName}
        />

        <h3 className="text-color-text-primary font-medium">
          {t(`${serviceName}.name`)}
        </h3>
        <p className="text-color-text-subtext line-clamp-3 text-xs font-medium leading-4">
          {t(`${serviceName}.catalogDescription`)}
        </p>
      </div>

      {children}
    </div>
  );
};
