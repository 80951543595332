import { ServiceWithConfiguration } from '../types/ServiceWithConfiguration';

export const sortFirstWithSecret = (
  a: ServiceWithConfiguration,
  b: ServiceWithConfiguration
) => {
  return hasSecret(a) && !hasSecret(b);
};

export const sortFirstWithoutSecret = (
  a: ServiceWithConfiguration,
  b: ServiceWithConfiguration
) => {
  return !hasSecret(a) && hasSecret(b);
};

export const hasSecret = (service: ServiceWithConfiguration) => {
  return (
    service?.configuration?.secret &&
    Object.keys(service?.configuration?.secret).length
  );
};
