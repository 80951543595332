import { useQueryClient } from '@tanstack/react-query';

import { ALERTS_KEYS } from '@spektr/client/services';

import { useAlertAndHitIdParam } from '../hooks/useAlertAndHitIdParam';

import { CommentBox } from '../../components';

import { HitCommentList } from './HitCommentList';

export const HitComments = () => {
  const { alertId, hitId } = useAlertAndHitIdParam();
  const queryClient = useQueryClient();

  const invalidateAlertsByIdQueryKey = async () =>
    await queryClient.invalidateQueries({
      queryKey: ALERTS_KEYS.BY_ID(alertId),
    });

  return (
    <div className="flex flex-col gap-6">
      <CommentBox
        parentType="hit"
        parentId={hitId}
        onAddComment={invalidateAlertsByIdQueryKey}
      />
      <HitCommentList onDeleteComment={invalidateAlertsByIdQueryKey} />
    </div>
  );
};
