import { useMutation, useQueryClient } from '@tanstack/react-query';

import { CUSTOMERS_KEYS, InsightsApiClient } from '@spektr/client/services';

export const useDeleteDocument = (
  customerId: string,
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();
  const deleteDocument = useMutation({
    mutationFn: async (docKey: string) => {
      await InsightsApiClient.getClient().deleteClientRecordDocument(
        undefined,
        {
          params: {
            clientRecordId: customerId,
            docKey: encodeURIComponent(docKey),
          },
        }
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: CUSTOMERS_KEYS.BY_ID(customerId),
      });

      onSuccess?.();
    },
  });

  return deleteDocument;
};
