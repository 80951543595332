import { processNodeParamsSchema } from '@spektr/shared/utils';

import { useParsedParams } from '@spektr/shared/hooks';

import { NodeDetailsFactoryDialog } from '@spektr/model-builder/containers';

export const NodeDetails = ({ onClose }: { onClose: () => void }) => {
  const { processId, nodeId } = useParsedParams(processNodeParamsSchema);

  return (
    <NodeDetailsFactoryDialog
      processId={processId}
      nodeId={nodeId}
      onClose={onClose}
    />
  );
};
