import { HTMLAttributes, forwardRef } from 'react';

import { cn } from '@spektr/style-utils';

export type TableHeaderProps = HTMLAttributes<HTMLTableSectionElement>;

export const TableHeader = forwardRef<
  HTMLTableSectionElement,
  TableHeaderProps
>(({ className, ...props }, ref) => (
  <thead ref={ref} className={cn('[&_tr]:border', className)} {...props} />
));
