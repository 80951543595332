import { CircleX } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { IconButton, IconButtonProps } from '../Button';

export const ToastCloseButton = ({ className, ...props }: IconButtonProps) => (
  <IconButton
    variant="text"
    className={cn(
      'text-color-text-icon-primary/50 rounded-md p-1 opacity-0 transition-opacity',
      'hover:color-text-icon-primary',
      'focus:opacity-100 focus:outline-none focus:ring-1',
      'group-hover:opacity-100',
      className
    )}
    {...props}
  >
    <CircleX className="h-6 w-6" />
  </IconButton>
);
