import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AlertUpdate } from '@spektr/shared/validators';

import {
  ProcessApiClient,
  ALERTS_KEYS,
  CUSTOMERS_KEYS,
  DATASETS_KEYS,
} from '@spektr/client/services';
import { invalidateMultipleQueries } from '@spektr/client/utils';

export const useUpdateAlert = (
  alertId: string,
  datasetId: string,
  spektrId: string
) => {
  const { spektrId: customerId } = useParams();
  const queryClient = useQueryClient();

  const updateAlertMutation = useMutation({
    mutationFn: async (body: AlertUpdate) => {
      await ProcessApiClient.getClient().updateAlertById(body, {
        params: { alertId },
      });

      await invalidateMultipleQueries(queryClient, [
        {
          queryKey: customerId
            ? ALERTS_KEYS.ALL_BY_CUSTOMER(customerId)
            : ALERTS_KEYS.ALL(),
        },
        { queryKey: ALERTS_KEYS.BY_ID(alertId) },
        { queryKey: DATASETS_KEYS.ALL_ALERTS(datasetId, spektrId) },
      ]);

      await queryClient.resetQueries({
        queryKey: CUSTOMERS_KEYS.BY_ID(spektrId),
      });
    },
  });

  return updateAlertMutation;
};
