import { Suspense } from 'react';

import { usePublicDomain } from '@spektr/client/providers';

import { CognitoUserData } from '@spektr/shared/types';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { ProcessScheduleProvider } from '../../providers';

import { SettingItemSkeleton } from '../../components/SettingItemSkeleton';
import { VersionsSkeleton } from '../../components/VersionsSkeleton';
import { PublishVersion } from '../PublishVersion';
import { ProcessVersions } from '../ProcessVersions';
import { ProcessLaunchOnboarding } from '../ProcessLaunchOnboarding';
import { NextRunBanner } from '../../components/NextRunBanner';

export type ProcessScheduleProps = {
  processId: string;
  user?: CognitoUserData;
};

export const ProcessSchedule = ({ processId, user }: ProcessScheduleProps) => {
  const { getSkyhubUrl } = usePublicDomain();
  const { processTriggers } = useFeatureFlags();

  return (
    <ProcessScheduleProvider processId={processId}>
      <ProcessLaunchOnboarding
        processId={processId}
        publicPageUrl={getSkyhubUrl()}
        workspaceId={user?.['custom:workspaceId']}
      />
      {processTriggers && <NextRunBanner />}
      <div className="divide-color-border-primary flex flex-col">
        <Suspense fallback={<SettingItemSkeleton />}>
          <PublishVersion
            processId={processId}
            title="Publish a version"
            description="Make a new live version of the current process"
          />
        </Suspense>
      </div>

      <Suspense fallback={<VersionsSkeleton />}>
        <ProcessVersions processId={processId} />
      </Suspense>
    </ProcessScheduleProvider>
  );
};
