import { useSuspenseQuery } from '@tanstack/react-query';

import { getAllAlerts } from '@spektr/client/services';

export function useCountUnresolvedAlerts() {
  const { data } = useSuspenseQuery({
    ...getAllAlerts({
      page: 1,
      limit: 1,
      status: ['open', 'in_progress', 'escalated'],
    }),
    refetchInterval: 10000,
  });

  return data.totalDocs ?? 0;
}
