import { Trash2 } from 'lucide-react';

import { cn } from '@spektr/style-utils';
import { ButtonWithTooltip } from '@spektr/client/components';

type CasesClearFilterProps = {
  disabled?: boolean;
  onClick: () => void;
};

export const ClearFilters = ({ disabled, onClick }: CasesClearFilterProps) => {
  return (
    <ButtonWithTooltip
      disabled={disabled}
      variant="contained"
      color="icon"
      className={cn(
        'text-color-text-subtext',
        'rounded-s-none border-l-0',
        'text-sm font-medium',
        'disabled:opacity-100'
      )}
      showTooltip={true}
      tooltipProps={{
        align: 'end',
        content: 'Clear all filters',
        sideOffset: 8,
      }}
      onClick={onClick}
    >
      <Trash2 className={cn('h-4 w-4', disabled && 'opacity-20')} />
    </ButtonWithTooltip>
  );
};
