import { ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

type CustomerTimelineRowProps = {
  isLast?: boolean;
  children: ReactNode;
};

export const CustomerTimelineRow = ({
  isLast = false,
  children,
}: CustomerTimelineRowProps) => {
  return (
    <div
      className={cn(
        'grid grid-cols-8 gap-4 p-6 sm:grid-cols-12',
        !isLast && 'border-b border-dashed'
      )}
    >
      {children}
    </div>
  );
};
