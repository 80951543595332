import { z } from 'zod';

import { tagSchema } from '../tags';
import { PaginationFields } from '../pagination';

import { IndicatorsSchema } from './indicator';

export const clientRecordStatuses = [
  'approved',
  'pending',
  'rejected',
] as const;
export const ClientRecordStatuses = z.enum(clientRecordStatuses);
export type ClientRecordStatus = z.infer<typeof ClientRecordStatuses>;

export const clientRecordTypes = ['company', 'individual', 'unknown'] as const;
export const ClientRecordTypes = z.enum(clientRecordTypes);
export type ClientRecordType = z.infer<typeof ClientRecordTypes>;

export const clientRecordRoles = [
  'owner',
  'controller',
  'representative',
  'unknown',
] as const;
export const ClientRecordRoles = z.enum(clientRecordRoles);
export type ClientRecordRole = z.infer<typeof ClientRecordRoles>;

export const clientRecordLevels = ['primary', 'secondary'] as const;
export const ClientRecordLevels = z.enum(clientRecordLevels);
export type ClientRecordLevel = z.infer<typeof ClientRecordLevels>;

export const ClientRecordTags = z.enum([
  'approved',
  'tested',
  'verified',
  'highRisk',
]);
export type ClientRecordTags = z.infer<typeof ClientRecordTags>;

export const alertPresence = ['showAlertsOnly', 'showNoAlertsOnly'] as const;
export const AlertPresenceFilters = z.enum(alertPresence);
export type AlertPresenceFilters = z.infer<typeof AlertPresenceFilters>;

export const ClientRecordTagModel = tagSchema.merge(
  z.object({
    sourceProcessId: z.string().optional(), //tags that are set manually do not have a source
    sourceProcessName: z.string().optional(),
  })
);
export type ClientRecordTagModel = z.infer<typeof ClientRecordTagModel>;

export const ClientRecordDocumentModel = z.object({
  key: z.string(),
  source: z.enum(['manual', 'process']),
  processName: z.string().optional(),
  isDeleted: z.boolean(),
  fileName: z.string().optional(),
  createdAt: z.number().optional(),
});
export type ClientRecordDocumentModel = z.infer<
  typeof ClientRecordDocumentModel
>;

export const Document = ClientRecordDocumentModel.merge(
  z.object({
    previewPresignedUrl: z.string().optional(),
    downloadPresignedUrl: z.string().optional(),
    size: z.number().optional(),
    updatedAt: z.number().optional(),
  })
);
export type Document = z.infer<typeof Document>;

export const Documents = z.array(Document);
export type Documents = z.infer<typeof Documents>;

export const clientRecordBaseSchema = z.strictObject({
  datasetId: z.string(),
  tags: z.array(ClientRecordTagModel),
  status: ClientRecordStatuses.optional(),
  createdAt: z.number().optional(),
  updatedAt: z.number().optional(),
});

export const clientRecordDetailsBaseSchema = z.strictObject({
  name: z.string(),
  details: z.record(z.string(), z.any()).optional(),
  customerType: ClientRecordTypes,
  uboDetails: z
    .object({
      customerLevel: ClientRecordLevels,
      role: ClientRecordRoles,
      companyName: z.string(),
    })
    .optional(),
});

export const clientRecordInfoSchema = clientRecordBaseSchema.merge(
  clientRecordDetailsBaseSchema
);

export const ClientRecord = clientRecordInfoSchema.merge(
  z.object({
    spektrId: z.string(),
    unresolvedAlertsCount: z.number(),
  })
);
export type ClientRecord = z.infer<typeof ClientRecord>;

export const clientRecordsListResponseSchema = PaginationFields.merge(
  z.strictObject({
    docs: z.array(ClientRecord),
  })
);

export const ClientRecordUpdate = z.object({
  status: ClientRecordStatuses,
});
export type ClientRecordUpdate = z.infer<typeof ClientRecordUpdate>;

export const clientRecordDetailsSchema = ClientRecord.omit({
  unresolvedAlertsCount: true,
}).merge(
  z.object({
    datasetName: z.string(),
    indicators: IndicatorsSchema,
  })
);

export type ClientRecordDetails = z.infer<typeof clientRecordDetailsSchema>;

export const ClientRecordOwner = z.object({
  name: z.string(),
  address: z.string().optional(),
  birthDate: z.string().optional(),
  registrationDate: z.string().optional(),
  percentage: z.number(),
  type: ClientRecordTypes.optional(),
});

export type ClientRecordOwner = z.infer<typeof ClientRecordOwner>;

export const ClientRecordRepAndController = z.object({
  name: z.string(),
  address: z.string().optional(),
  birthDate: z.string().optional(),
  registrationDate: z.string().optional(),
  role: z.string(),
  type: ClientRecordTypes.optional(),
});

export type ClientRecordRepAndController = z.infer<
  typeof ClientRecordRepAndController
>;

export const ClientRecordOwnersAndControllers = z.object({
  owners: z.array(ClientRecordOwner),
  repsAndControllers: z.array(ClientRecordRepAndController),
});
export type ClientRecordOwnersAndControllers = z.infer<
  typeof ClientRecordOwnersAndControllers
>;

export const ClientRecordUploadResponse = z.object({
  url: z.string(),
  fields: z.record(z.string(), z.string()),
});
