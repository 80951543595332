import { useMemo } from 'react';
import {
  Box,
  Download,
  List,
  MoreVertical,
  Pen,
  PlaySquareIcon,
  Trash,
  UploadCloud,
} from 'lucide-react';

import { IconWithBackground } from '@spektr/client/components';

import {
  DropdownMenuComboBox,
  DropdownMenuComboBoxProps,
  DropdownOption,
} from '../../DropdownMenu';
import { IconButton } from '../../Button';

type CasesCustomerActionsProps = Partial<DropdownMenuComboBoxProps> & {
  onList?: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
};

export const CasesCustomerActions = ({
  onList,
  onDelete,
  onUpdate,
  ...props
}: CasesCustomerActionsProps) => {
  const onActionClick = (value: string) => {
    switch (value) {
      case 'details':
        onList?.();
        break;
      case 'delete':
        onDelete?.();
        break;
      case 'edit':
        onUpdate?.();
        break;
    }
  };

  const customerActions = useMemo(() => {
    const actions = [];

    if (onList) {
      actions.push({
        type: 'item',
        label: 'See details',
        icon: <List className="h-3 w-3" />,
        value: 'details',
      });
    }

    if (onUpdate) {
      if (actions.length > 0) {
        actions.push({
          type: 'separator',

          value: `separator-${actions.length}`,
        });
      }

      actions.push({
        type: 'item',
        label: 'Edit customer',
        icon: <Pen className="h-3 w-3" />,
        value: 'edit',
        disabled: true,
      });
    }

    //Condition should be added here when we have the action for it
    // TODO: When enabling the option, change className to 'sm:hidden'
    if (actions.length > 0) {
      actions.push({
        type: 'separator',
        value: `separator-${actions.length}`,
        className: 'hidden',
      });
    }

    actions.push({
      type: 'item',
      label: 'Fetch PII',
      icon: (
        <IconWithBackground className="bg-color-cyan/20 text-color-cyan ml-0 h-3 w-3 p-0">
          <Box className="h-2.5 w-2.5" />
        </IconWithBackground>
      ),
      endIcon: <PlaySquareIcon className="h-4 w-4" />,
      value: 'fetchPii',
      className: 'hidden',
    });

    //Condition should be added here when we have the action for it
    // TODO: When enabling the option, change className to 'sm:hidden'
    if (actions.length > 0) {
      actions.push({
        type: 'separator',
        value: `separator-${actions.length}`,
        className: 'sm:hidden',
      });
    }

    actions.push({
      type: 'item',
      label: 'Download case',
      icon: <Download className="h-3 w-3" />,
      value: 'download',
      className: 'hidden',
    });

    //Condition should be added here when we have the action for it
    // TODO: When enabling the option, change className to 'sm:hidden'
    if (actions.length > 0) {
      actions.push({
        type: 'separator',
        value: `separator-${actions.length}`,
        className: 'hidden',
      });
    }

    actions.push({
      type: 'item',
      label: 'Upload to case',
      icon: <UploadCloud className="h-3 w-3" />,
      value: 'upload',
      className: 'sm:hidden',
    });

    if (onDelete) {
      if (actions.length > 0) {
        actions.push({
          type: 'separator',
          value: `separator-${actions.length}`,
        });
      }

      actions.push({
        type: 'item',
        label: 'Delete customer',
        icon: <Trash className="h-3 w-3" />,
        value: 'delete',
        variant: 'danger',
      });
    }
    return actions as DropdownOption[];
  }, [onList, onDelete, onUpdate]);

  return (
    <DropdownMenuComboBox
      align="start"
      alignOffset={10}
      side="right"
      trigger={
        <IconButton
          color="icon"
          className="h-9 w-9 !px-2"
          data-cy="customer-actions"
        >
          <MoreVertical className="h-4 w-4" />
        </IconButton>
      }
      triggerProps={{ asChild: true }}
      {...props}
      options={customerActions}
      onClick={onActionClick}
    />
  );
};
