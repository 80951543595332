import { Process } from '@spektr/shared/types';

import { isProcessAvailableForConnection } from './isProcessAvailableForConnection';

export const getAvailableProcessesForConnection = (
  processes: Process[],
  sourceProcess?: Process,
  linkedProcesses?: string[]
) => {
  const hasOnlyLoopLinks = linkedProcesses?.every((processId) => {
    const process = processes.find((process) => process.id === processId);
    return process?.type === 'loop';
  });

  return processes.filter((process) =>
    isProcessAvailableForConnection(process, sourceProcess, !hasOnlyLoopLinks)
  );
};
