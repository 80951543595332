import { useSlate } from 'slate-react';
import { Link2Off } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import { isLinkActive, unwrapLink } from '../utils/link';

import { Button } from '../../Button';

export const RemoveLinkButton = () => {
  const editor = useSlate();

  return (
    <Button
      type="button"
      variant="text"
      size="sm"
      className={cn(
        'hover:bg-color-bg-button-secondary--hover',
        isLinkActive(editor) && 'bg-color-bg-button-secondary--active'
      )}
      onMouseDown={() => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      }}
    >
      <Link2Off className="h-3 w-3" />
    </Button>
  );
};
