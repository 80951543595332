import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { updateFieldsFactory } from '@spektr/client/utils';

import { ScrollArea, toast } from '@spektr/client/components';
import {
  SectionList,
  SectionWithTitle,
} from '@spektr/model-builder/components';

import {
  getAllowedSpektrFieldsQuery,
  getTeamMembersQuery,
} from '@spektr/client/services';

import { type ComplyAdvantageKybNode } from '@spektr/shared/validators';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { mapSelectedFields } from '../../utils/mapSelectedFields';

import { type BaseDialogProps } from '../../types/BaseDialogProps';

import { useServiceFields } from '../../hooks/useServiceFields';
import { useSelectedFields } from '../../hooks/useSelectedFields';
import { useComplyAdvantageMonitors } from '../../hooks/useComplyAdvantageMonitors';

import { ServiceCommonMappingForm } from '../../components/ServiceCommonMappingForm';
import { DialogFooterActions } from '../../components/DialogFooterActions';
import { SelectableServiceItem } from '../../components/SelectableServiceItem';
import { ComplyAdvantageItem } from '../../components/ComplyAdvantageItem';

import { withDialogWrapper } from '../WithDialogWrapper';

import { ContentSkeleton } from './components/ContentSkeleton';

export namespace ComplyAdvantageKybContent {
  export type Props = BaseDialogProps<ComplyAdvantageKybNode>;
}

const ComplyAdvantageKybContent = ({
  isPendingUpdate,
  node,
  processId,
  onUpdate,
}: ComplyAdvantageKybContent.Props) => {
  const { t } = useTranslation();
  const { newServiceConfig } = useFeatureFlags();

  const { data: spektrFields } = useSuspenseQuery(
    getAllowedSpektrFieldsQuery(processId, node.id)
  );
  const { data: users } = useSuspenseQuery(getTeamMembersQuery());

  const userOptions = useMemo(
    () =>
      users.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })) ?? [],
    [users]
  );

  const formInstance = useForm<Record<string, string>>({
    defaultValues: node?.mapping ?? {},
    mode: 'onChange',
  });

  const formEntries = useMemo(
    () => [
      {
        key: 'companyName',
        label: t('fields:complyAdvantageKyb.company_name'),
        type: 'string',
        description: '* Mandatory',
      },
      {
        key: 'companyNumber',
        label: t('fields:complyAdvantageKyb.company_number'),
        type: 'string',
        description: '',
      },
      {
        key: 'countryCode',
        label: t('fields:complyAdvantageKyb.jurisdiction_code'),
        type: 'string',
        description: '',
      },
    ],
    [t]
  );

  const { fields, serviceFields } = useServiceFields(node);
  const { selectedFields, handleFieldChange } = useSelectedFields(
    node,
    serviceFields
  );
  const serviceFieldsWithoutFitnessAndProbity = useMemo(
    () =>
      serviceFields.filter(
        (field) =>
          (field.key as string) !== 'has_fitness_and_probity' &&
          (field.key as string) !== 'complyAdvantageKyb_service_failure'
      ),
    [serviceFields]
  );
  const { activeMonitors, handleFieldSelectionChange, handleMonitorUpdate } =
    useComplyAdvantageMonitors(node);

  const handleSave = async () => {
    const mapping = formInstance.getValues();
    const fieldsMap = mapSelectedFields(fields, selectedFields);

    await onUpdate(
      updateFieldsFactory(node, fieldsMap, mapping, activeMonitors)
    );

    toast.success({
      title: 'Successfully updated!',
      description: 'The node has been updated successfully.',
    });
  };

  return (
    <FormProvider {...formInstance}>
      <ScrollArea className="max-h-[70vh] overflow-y-auto">
        <div className="flex flex-col gap-6">
          <ServiceCommonMappingForm
            spektrFields={spektrFields}
            form={formEntries}
            title={t('process:baseMapAttributesTitle')}
          />
          <SectionWithTitle
            title={
              newServiceConfig
                ? t(`process:nodes.${node.nodeType}.dialog.fieldChecksTitle`)
                : 'Select categories'
            }
            additionalInfo={
              newServiceConfig
                ? t(`process:nodes.${node.nodeType}.dialog.fieldChecksInfo`)
                : ''
            }
          >
            <SectionList data={serviceFieldsWithoutFitnessAndProbity}>
              {(item) =>
                newServiceConfig ? (
                  <ComplyAdvantageItem
                    key={item.key}
                    activeMonitors={activeMonitors}
                    field={item}
                    userOptions={userOptions}
                    onSelectionChange={(value) =>
                      handleFieldSelectionChange(item.key, value)
                    }
                    onMonitorUpdate={(decision, assignee) =>
                      handleMonitorUpdate(item.key, decision, assignee)
                    }
                  />
                ) : (
                  <SelectableServiceItem
                    key={item.key}
                    checked={selectedFields.includes(item.key)}
                    field={item}
                    onChange={(value) => handleFieldChange(item.key, value)}
                  />
                )
              }
            </SectionList>
          </SectionWithTitle>
        </div>
      </ScrollArea>
      <DialogFooterActions
        isServiceNode
        btnColor="complyAdvantage"
        disabled={!formInstance.formState.isValid}
        isPendingUpdate={isPendingUpdate}
        onSave={handleSave}
      />
    </FormProvider>
  );
};

export const ComplyAdvantageKybDialog = withDialogWrapper(
  ComplyAdvantageKybContent,
  <ContentSkeleton />,
  {
    className: 'max-w-[700px]',
  }
);
