import { produce } from 'immer';
import { v4 as uuid } from 'uuid';

import { isEntitiesField } from '@spektr/moonraker-types';

import { FormAction } from './actions';

import { FormState } from './types';

export function reducer(state: FormState, action: FormAction) {
  if (action.type === 'select_field') {
    return produce(state, (draft) => {
      draft.selectedFieldsId = action.payload;
    });
  }

  if (action.type === 'drop_field') {
    return produce(state, (draft) => {
      const { field, rootFieldId } = action.payload;

      if (rootFieldId) {
        const rootField = draft.fields[rootFieldId];
        if (
          rootField &&
          isEntitiesField(rootField) &&
          !isEntitiesField(field)
        ) {
          draft.selectedFieldsId = [rootFieldId, field.id];
          rootField.form.order.push(field.id);
          rootField.form.fields[field.id] = field;
        }
      } else {
        draft.selectedFieldsId = [field.id];
        draft.order.push(field.id);
        draft.fields[field.id] = field;
      }
    });
  }

  if (action.type === 'delete_field') {
    return produce(state, (draft) => {
      const [rootFieldId, fieldId] = action.payload;

      if (fieldId) {
        const rootField = draft.fields[rootFieldId];

        if (rootField && isEntitiesField(rootField)) {
          delete rootField.form.fields[fieldId];
          draft.selectedFieldsId = [rootFieldId];
          rootField.form.order = rootField.form.order.filter(
            (id) => id !== fieldId
          );
        }
      } else {
        delete draft.fields[rootFieldId];
        draft.selectedFieldsId = null;
        draft.order = draft.order.filter((id) => id !== rootFieldId);
      }
    });
  }

  if (action.type === 'update_field') {
    return produce(state, (draft) => {
      draft.fields[action.payload.id] = action.payload;
    });
  }

  if (action.type === 'clone_field') {
    const [rootFieldId, fieldId] = action.payload;

    if (fieldId) {
      return produce(state, (draft) => {
        const rootField = draft.fields[rootFieldId];

        if (rootField && isEntitiesField(rootField)) {
          const currentField = rootField.form.fields[fieldId];

          if (!currentField) {
            return draft;
          }

          const nextField = {
            ...currentField,
            id: uuid(),
          };

          draft.selectedFieldsId = [rootFieldId, nextField.id];
          rootField.form.fields[nextField.id] = nextField;
          rootField.form.order.push(nextField.id);
        }
      });
    } else {
      const currentField = state.fields[rootFieldId];

      if (!currentField) {
        return state;
      }

      const nextField = {
        ...currentField,
        id: uuid(),
      };

      return produce(state, (draft) => {
        draft.selectedFieldsId = [nextField.id];
        draft.order.push(nextField.id);
        draft.fields[nextField.id] = nextField;
      });
    }
  }

  if (action.type === 'reorder_field') {
    const { currentIndex, newIndex, entityId } = action.payload;

    return produce(state, (draft) => {
      let order = draft.order;

      if (entityId) {
        const entityField = draft.fields[entityId];

        if (isEntitiesField(entityField)) {
          order = entityField.form.order;
        }
      }

      const [fieldId] = order.splice(currentIndex, 1);

      order.splice(newIndex, 0, fieldId);
    });
  }

  if (action.type === 'update_assistingContent') {
    return produce(state, (draft) => {
      draft.assistingContent = action.payload;
    });
  }

  if (action.type === 'update_branding') {
    return produce(state, (draft) => {
      draft.branding = action.payload;
    });
  }

  throw Error('Unknown action.');
}
