import { useMemo } from 'react';

import {
  NodeServiceType,
  SpektrFieldTypedKey,
} from '@spektr/shared/validators';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  BodaccSquareIcon,
  ComplyAdvantageSquareIcon,
  IconWithBackground,
  KyckrIcon,
  OpenCorporatesSquareIcon,
  VeriffIcon,
} from '@spektr/client/components';

import { cn } from '@spektr/style-utils';

import { ServiceField } from './ServiceField';

type ServiceGroupProps = {
  name: string;
  fields: SpektrFieldTypedKey[];
  serviceName: NodeServiceType;
};

export const ServiceGroup = ({
  name,
  fields,
  serviceName,
}: ServiceGroupProps) => {
  const iconClassName = 'w-5 h-5 ml-auto rounded-md';
  const service = useMemo(() => {
    switch (name) {
      case 'openCorporatesMonitoring':
      case 'openCorporatesRisk':
        return {
          name: 'OpenCorporates',
          icon: <OpenCorporatesSquareIcon className={iconClassName} />,
        };
      case 'complyAdvantageKyc':
        return {
          name: 'ComplyAdvantage - individual',
          icon: <ComplyAdvantageSquareIcon className={iconClassName} />,
        };
      case 'complyAdvantageKyb':
        return {
          name: 'ComplyAdvantage - corporation',
          icon: <ComplyAdvantageSquareIcon className={iconClassName} />,
        };
      case 'bodacc':
        return {
          name: 'Bodacc',
          icon: <BodaccSquareIcon className={iconClassName} />,
        };
      case 'kyckr':
        return {
          name: 'Kyckr',
          icon: (
            <IconWithBackground
              className={cn('bg-color-bg-white h-5 w-5 p-1', iconClassName)}
            >
              <KyckrIcon />
            </IconWithBackground>
          ),
        };
      case 'veriff':
        return {
          name: 'Veriff',
          icon: (
            <IconWithBackground
              className={cn('bg-color-bg-white h-5 w-5 p-1', iconClassName)}
            >
              <VeriffIcon />
            </IconWithBackground>
          ),
        };
      default:
        return { name, icon: null };
    }
  }, [name]);

  return (
    <Accordion
      key={name}
      collapsible
      defaultValue={`group-${name}`}
      type="single"
      className="w-full"
    >
      <AccordionItem value={`group-${name}`} className="relative border-none">
        <AccordionTrigger className="justify-start gap-2 px-3 py-2 text-xs font-medium">
          {service.name}
          {service.icon}
        </AccordionTrigger>
        <AccordionContent className="pl-6">
          {fields.map(({ key }) => (
            <ServiceField key={key} value={key} service={serviceName} />
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
