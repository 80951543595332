import { LS_KEYS } from '@spektr/client/constants';
import { CUSTOMERS_KEYS } from '@spektr/client/services';

import { CasesFiltersProvider } from '../providers';
import { CasesFiltersList } from '../containers';

import { CustomersList } from './containers';
import {
  useGetCustomersDropdownFiltersOptions,
  useGetCustomersFiltersConfigurations,
} from './hooks';

export type CasesCustomersDashboardProps = {
  userId: string;
  columnsToDisplay: string[];
};

export const CasesCustomersDashboard = ({
  userId,
  columnsToDisplay,
}: CasesCustomersDashboardProps) => {
  const filtersWithNoDivider = ['status', 'tags'];
  const configurations = useGetCustomersFiltersConfigurations();

  return (
    <CasesFiltersProvider
      getFiltersDropdownOptions={useGetCustomersDropdownFiltersOptions}
      configurations={configurations}
      storageKey={LS_KEYS.CUSTOMERS_FILTERS + '.' + userId}
      queryKey={CUSTOMERS_KEYS.ALL()}
      filtersWithNoDivider={filtersWithNoDivider}
      columnsToDisplay={columnsToDisplay}
    >
      <CasesFiltersList />
      <CustomersList />
    </CasesFiltersProvider>
  );
};
