import { Suspense } from 'react';

import { SkeletonAlertsTable } from '@spektr/client/components';

import { CustomerAlertsList } from '../containers/CustomerAlertsList';

export const CustomerAlerts = () => {
  const columnsToDisplay = ['date', 'alert', 'spektrAI', 'status'];

  return (
    <Suspense fallback={<SkeletonAlertsTable columns={columnsToDisplay} />}>
      <CustomerAlertsList columnsToDisplay={columnsToDisplay} />
    </Suspense>
  );
};
