import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { cn } from '@spektr/style-utils';

import {
  connectionDataSetByIdQuery,
  getProcessesQuery,
} from '@spektr/client/services';

import { Process } from '@spektr/shared/types';

import { EmptyList } from '../../components/EmptyList';
import { ProcessListItem } from '../../components/ProcessListItem';

export type ProcessListProps = {
  datasetId: string;
};

export const ProcessList = ({ datasetId }: ProcessListProps) => {
  const { data: dataset } = useSuspenseQuery(
    connectionDataSetByIdQuery(datasetId)
  );
  const { data: processes } = useSuspenseQuery(
    getProcessesQuery({
      types: ['risk', 'score', 'monitoring', 'onboarding', 'enrichment'],
    })
  );

  const sourcedProcesses = useMemo(() => {
    return dataset.meta.linkedProcessIds.reduce<Process[]>((acc, processId) => {
      const process = processes.find((item) => item.id === processId);

      if (process) {
        acc.push(process);
      }

      return acc;
    }, []);
  }, [dataset?.meta.linkedProcessIds, processes]);

  if (sourcedProcesses.length === 0) {
    return <EmptyList />;
  }

  return (
    <div
      className={cn(
        'flex max-h-[280px] flex-col',
        'rounded-md border',
        'bg-color-bg-card-default',
        'overflow-y-auto'
      )}
    >
      {sourcedProcesses.map((process) => (
        <ProcessListItem
          key={process.id}
          id={process.id}
          name={process.name}
          type={process.type}
        />
      ))}
    </div>
  );
};
