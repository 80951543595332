import { z } from 'zod';

export const addKeysRequestSchema = z.object({
  workspaceId: z.string(),
  keys: z.array(z.string()),
});
export const addKeysResponseSchema = z.void();

export const deleteKeysRequestSchema = z.object({
  workspaceId: z.string(),
  keys: z.array(z.string()),
});
export const deleteKeysResponseSchema = z.void();

export const queryRequestSchema = z.object({
  workspaceId: z.string(),
  subset: z.array(z.string()),
  topK: z.number().positive().optional(),
  includeClassification: z.boolean().optional(),
  queries: z.array(
    z.object({
      id: z.string(),
      queryText: z.string(),
      topK: z.number().positive().optional(),
    })
  ),
});
const classificationCategorySchema = z.union([
  z.literal('individual'),
  z.literal('company'),
]);
export const queryResponseSchema = z.object({
  classification: z
    .object({
      category: classificationCategorySchema,
      scores: z.array(
        z.object({
          name: z.string(),
          score: z.number().nonnegative(),
        })
      ),
    })
    .optional(),
  results: z.array(
    z.object({
      id: z.string(),
      scores: z
        .array(
          z.object({
            name: z.string(),
            score: z.number().nonnegative(),
          })
        )
        .nonempty(),
    })
  ),
});
