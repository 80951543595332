import { Calendar } from 'lucide-react';

import { cn } from '@spektr/style-utils';

import type { InputField } from '@spektr/moonraker-types';

export type DateInputContentProps = {
  field: InputField;
};

export const DateInputContent = ({ field }: DateInputContentProps) => {
  return (
    <div className="flex w-full flex-col gap-2">
      <div
        className={cn(
          'flex items-center gap-2',
          'h-9 w-full px-3',
          'rounded-md border'
        )}
      >
        {field.attributes?.placeholder && (
          <span
            className={cn('text-color-text-subtext', 'text-xs font-medium')}
          >
            {field.attributes.placeholder}
          </span>
        )}
        <Calendar className="text-color-text-icon-secondary dark:color-text-icon-tertiary ml-auto h-4 w-4 shrink-0" />
      </div>
      {field.attributes?.helperText && (
        <span
          className={cn(
            'h-4',
            'text-color-text-subtext',
            'text-xs font-medium',
            'transition-all'
          )}
        >
          {field.attributes?.helperText}
        </span>
      )}
    </div>
  );
};
