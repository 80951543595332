import { TextCursorInput } from 'lucide-react';

import { cn } from '@spektr/style-utils';
import { NodeType } from '@spektr/shared/validators';

import {
  BodaccSquareIcon,
  BoxIcon,
  ComplyAdvantageSquareIcon,
} from '@spektr/client/components';

export const ServiceIcon = ({ nodeType }: { nodeType: NodeType }) => {
  const iconClassName = 'w-5 h-5 rounded-md shrink-0';
  switch (nodeType) {
    case 'bodacc': {
      return <BodaccSquareIcon className={iconClassName} />;
    }

    case 'complyAdvantageKyb':
    case 'complyAdvantageKyc': {
      return <ComplyAdvantageSquareIcon className={iconClassName} />;
    }

    default: {
      return (
        <BoxIcon
          className={cn(iconClassName, 'bg-color-red/20')}
          renderIcon={({ className }) => (
            <TextCursorInput className={cn('stroke-color-red', className)} />
          )}
        />
      );
    }
  }
};
