import { ReactNode } from 'react';

import {
  Provider,
  Root,
  TooltipTriggerProps,
  Trigger,
  Portal,
} from '@radix-ui/react-tooltip';

import { cn } from '@spektr/style-utils';

import {
  TooltipContent,
  TooltipContentProps,
} from './components/TooltipContent';

export type TooltipProps = {
  children: ReactNode;
  content: ReactNode;
  sideOffset?: TooltipContentProps['sideOffset'];
  side?: TooltipContentProps['side'];
  align?: TooltipContentProps['align'];
  alignOffset?: TooltipContentProps['alignOffset'];
  delayDuration?: number;
  disabled?: boolean;
  tooltipTriggerProps?: TooltipTriggerProps;
  contentClassName?: string;
};

export const Tooltip = ({
  children,
  content,
  delayDuration,
  tooltipTriggerProps,
  contentClassName,
  disabled = false,
  ...props
}: TooltipProps) => {
  return (
    <Provider>
      <Root delayDuration={delayDuration}>
        <Trigger
          type="button"
          {...tooltipTriggerProps}
          className={cn(
            tooltipTriggerProps?.disabled && 'cursor-not-allowed',
            tooltipTriggerProps?.className
          )}
        >
          {children}
        </Trigger>
        {!disabled && (
          <Portal>
            <TooltipContent {...props} className={contentClassName}>
              {content}
            </TooltipContent>
          </Portal>
        )}
      </Root>
    </Provider>
  );
};
