import { createConfig } from '../utils/creators';

import type { Widget } from '../types';

export const DEFAULT_WIDGETS: Widget[] = [
  {
    id: 'companyName',
    category: 'companyInformation',
    title: 'fields.companyName',
    icon: 'TextCursorInput',
    component: {
      id: 'companyName',
      type: 'input',
      config: createConfig('company_name', true),
      isStructured: true,
      attributes: {
        label: 'Company name',
        type: 'text',
        placeholder: 'Acme Inc.',
      },
      validation: [],
    },
  },
  {
    id: 'companyNumber',
    category: 'companyInformation',
    title: 'fields.companyNumber',
    icon: 'Binary',
    component: {
      id: 'companyNumber',
      type: 'input',
      config: createConfig('company_number', true),
      isStructured: true,
      attributes: {
        label: 'Company number',
        type: 'string',
        placeholder: '',
      },
      validation: [
        {
          type: 'required',
          value: true,
          message: 'Company number is required',
        },
      ],
    },
  },
  {
    id: 'countryOfIncorporation',
    category: 'companyInformation',
    title: 'fields.countryOfIncorporation',
    icon: 'MapPinned',
    component: {
      id: 'countryOfIncorporation',
      type: 'input',
      config: createConfig('country_of_incorporation', true),
      isStructured: true,
      attributes: {
        label: 'Country of Incorporation',
        type: 'country',
        placeholder: '',
      },
      validation: [],
    },
  },
  {
    id: 'vatNumber',
    category: 'companyInformation',
    title: 'fields.vatNumber',
    icon: 'Binary',
    component: {
      id: 'vatNumber',
      type: 'input',
      config: createConfig('vat_number', true),
      isStructured: true,
      attributes: {
        label: 'VAT Number',
        type: 'text',
        placeholder: '',
      },
      validation: [],
    },
  },
  {
    id: 'incorporationDate',
    category: 'companyInformation',
    title: 'fields.incorporationDate',
    icon: 'CalendarPlus2',
    component: {
      id: 'incorporationDate',
      type: 'input',
      config: createConfig('incorporation_date', true),
      isStructured: true,
      attributes: {
        label: 'Date of incorporation',
        type: 'date',
        placeholder: '01/01/2000',
      },
      validation: [],
    },
  },
  {
    id: 'companyStatus',
    category: 'companyInformation',
    title: 'fields.companyStatus',
    icon: 'TextCursorInput',
    component: {
      id: 'companyStatus',
      type: 'input',
      config: createConfig('company_status', true),
      isStructured: true,
      attributes: {
        label: 'Company status',
        type: 'text',
        placeholder: '',
      },
      validation: [],
    },
  },
  {
    id: 'naceCode',
    category: 'companyInformation',
    title: 'fields.naceCode',
    icon: 'List',
    component: {
      id: 'naceCode',
      type: 'select',
      config: createConfig('nace_code', true),
      isStructured: true,
      attributes: {
        label: 'NACE code',
        placeholder: 'Select NACE code',
        options: [], // these will be overwritten lazily by createFieldFromWidget
      },
      validation: [],
    },
  },
  {
    id: 'websiteURL',
    category: 'companyInformation',
    title: 'fields.websiteURL',
    icon: 'TextCursorInput',
    component: {
      id: 'websiteURL',
      type: 'input',
      config: createConfig('website_url', true),
      isStructured: true,
      attributes: {
        label: 'Website URL',
        type: 'url',
        placeholder: '',
      },
      validation: [],
    },
  },
  {
    id: 'companyFullAddress',
    category: 'companyInformation',
    title: 'fields.fullAddress',
    icon: 'MapPinned',
    component: {
      id: 'companyFullAddress',
      type: 'input',
      config: createConfig('company_full_address', true),
      isStructured: true,
      attributes: {
        label: 'Full address',
        type: 'text',
        placeholder: '',
      },
      validation: [],
    },
  },
  {
    id: 'state',
    category: 'companyInformation',
    title: 'fields.state',
    icon: 'MapPinned',
    component: {
      id: 'state',
      type: 'input',
      config: createConfig('company_state', true),
      isStructured: true,
      attributes: {
        label: 'State/Region',
        type: 'text',
        placeholder: '',
      },
      validation: [],
    },
  },
  {
    id: 'city',
    category: 'companyInformation',
    title: 'fields.city',
    icon: 'MapPinned',
    component: {
      id: 'city',
      type: 'input',
      config: createConfig('company_city', true),
      isStructured: true,
      attributes: {
        label: 'City',
        type: 'text',
        placeholder: 'San Francisco',
      },
      validation: [],
    },
  },
  {
    id: 'zipCode',
    category: 'companyInformation',
    title: 'fields.zipCode',
    icon: 'MapPinned',
    component: {
      id: 'zipCode',
      type: 'input',
      config: createConfig('company_zip_code', true),
      isStructured: true,
      attributes: {
        label: 'Zip code',
        type: 'number',
        placeholder: '',
      },
      validation: [
        {
          type: 'required',
          value: true,
          message: 'Zip code is required',
        },
      ],
    },
  },
  {
    id: 'street',
    category: 'companyInformation',
    title: 'fields.street',
    icon: 'MapPinned',
    component: {
      id: 'street',
      type: 'input',
      config: createConfig('company_street_name', true),
      isStructured: true,
      attributes: {
        label: 'Street name',
        type: 'text',
        placeholder: '',
      },
      validation: [
        {
          type: 'required',
          value: true,
          message: 'Street is required',
        },
      ],
    },
  },
  {
    id: 'unit',
    category: 'companyInformation',
    title: 'fields.unit',
    icon: 'MapPinned',
    component: {
      id: 'unit',
      type: 'input',
      config: createConfig('company_unit', true),
      isStructured: true,
      attributes: {
        label: 'Unit / Floor',
        type: 'text',
        placeholder: '',
      },
      validation: [],
    },
  },
  {
    id: 'countryOfHq',
    category: 'companyInformation',
    title: 'fields.countryOfHq',
    icon: 'MapPinned',
    component: {
      id: 'countryOfHq',
      type: 'input',
      config: createConfig('country_of_hq', true),
      isStructured: true,
      attributes: {
        label: 'Country of Headquarter',
        type: 'country',
        placeholder: '',
      },
      validation: [],
    },
  },
  {
    id: 'title',
    category: 'pii',
    title: 'fields.title',
    icon: 'TextCursorInput',
    component: {
      id: 'title',
      type: 'input',
      config: createConfig('title', true),
      isStructured: true,
      attributes: {
        label: 'Title',
        type: 'text',
        placeholder: 'Mr./Mrs./Ms./Miss./Dr.',
      },
      validation: [],
    },
  },
  {
    id: 'firstName',
    category: 'pii',
    title: 'fields.firstName',
    icon: 'TextCursorInput',
    component: {
      id: 'firstName',
      type: 'input',
      config: createConfig('first_name', true),
      isStructured: true,
      attributes: {
        label: 'First name',
        type: 'text',
        placeholder: 'John',
      },
      validation: [],
    },
  },
  {
    id: 'middleName',
    category: 'pii',
    title: 'fields.middleName',
    icon: 'TextCursorInput',
    component: {
      id: 'middleName',
      type: 'input',
      config: createConfig('middle_name', true),
      isStructured: true,
      attributes: {
        label: 'Middle name',
        type: 'text',
        placeholder: 'Middle',
      },
      validation: [],
    },
  },
  {
    id: 'lastName',
    category: 'pii',
    title: 'fields.lastName',
    icon: 'TextCursorInput',
    component: {
      id: 'lastName',
      type: 'input',
      config: createConfig('last_name', true),
      isStructured: true,
      attributes: {
        label: 'Last name',
        type: 'text',
        placeholder: 'Doe',
      },
      validation: [],
    },
  },
  {
    id: 'fullName',
    category: 'pii',
    title: 'fields.fullName',
    icon: 'TextCursorInput',
    component: {
      id: 'fullName',
      type: 'input',
      config: createConfig('full_name', true),
      isStructured: true,
      attributes: {
        label: 'Full name',
        type: 'text',
        placeholder: 'John Doe',
      },
      validation: [],
    },
  },

  {
    id: 'dateOfBirth',
    category: 'pii',
    title: 'fields.dateOfBirth',
    icon: 'CalendarPlus2',
    component: {
      id: 'dateOfBirth',
      type: 'input',
      config: createConfig('date_of_birth', true),
      isStructured: true,
      attributes: {
        label: 'Date Of birth',
        type: 'date',
        placeholder: '01/01/2000',
      },
      validation: [],
    },
  },
  {
    id: 'nationality',
    category: 'pii',
    title: 'fields.nationality',
    icon: 'MapPinned',
    component: {
      id: 'nationality',
      type: 'input',
      config: createConfig('nationality', true),
      isStructured: true,
      attributes: {
        label: 'Nationality',
        type: 'country',
        placeholder: 'Denmark',
      },
      validation: [],
    },
  },
  {
    id: 'tin',
    category: 'pii',
    title: 'fields.tin',
    icon: 'Binary',
    component: {
      id: 'tin',
      type: 'input',
      config: createConfig('tin', true),
      isStructured: true,
      attributes: {
        label: 'TIN number',
        type: 'number',
        placeholder: '',
      },
      validation: [],
    },
  },
  {
    id: 'email',
    category: 'pii',
    title: 'fields.email',
    icon: 'Mail',
    component: {
      id: 'email',
      type: 'input',
      config: createConfig('email', true),
      isStructured: true,
      attributes: {
        label: 'Email',
        type: 'email',
        placeholder: '',
      },
      validation: [],
    },
  },
  {
    id: 'phone',
    category: 'pii',
    title: 'fields.phone',
    icon: 'Phone',
    component: {
      id: 'phone',
      type: 'input',
      config: createConfig('phone', true),
      isStructured: true,
      attributes: {
        label: 'Phone number',
        type: 'phone',
        placeholder: '',
      },
      validation: [],
    },
  },
  {
    id: 'ownership',
    category: 'pii',
    title: 'fields.ownership',
    icon: 'Percent',
    component: {
      id: 'ownership',
      type: 'input',
      config: createConfig('ownership', true),
      isStructured: true,
      attributes: {
        label: 'Ownership percentage',
        type: 'percentage',
        placeholder: '',
      },
      validation: [],
    },
  },
  {
    id: 'occupation',
    category: 'pii',
    title: 'fields.occupation',
    icon: 'BriefcaseBusiness',
    component: {
      id: 'occupation',
      type: 'input',
      config: createConfig('occupation', true),
      isStructured: true,
      attributes: {
        label: 'Occupation',
        type: 'text',
        placeholder: '',
      },
      validation: [],
    },
  },
  {
    id: 'countryOfResidence',
    category: 'pii',
    title: 'fields.countryOfResidence',
    icon: 'MapPinned',
    component: {
      id: 'countryOfResidence',
      type: 'input',
      config: createConfig('country_of_residence', true),
      isStructured: true,
      attributes: {
        label: 'Country of residence',
        type: 'country',
        placeholder: '',
      },
      validation: [
        {
          type: 'required',
          value: true,
          message: 'Country is required',
        },
      ],
    },
  },
  {
    id: 'fullAddress',
    category: 'pii',
    title: 'fields.fullAddress',
    icon: 'MapPinned',
    component: {
      id: 'fullAddress',
      type: 'input',
      config: createConfig('full_address', true),
      isStructured: true,
      attributes: {
        label: 'Full address',
        type: 'text',
        placeholder: '',
      },
      validation: [],
    },
  },
  {
    id: 'state',
    category: 'pii',
    title: 'fields.state',
    icon: 'MapPinned',
    component: {
      id: 'state',
      type: 'input',
      config: createConfig('state', true),
      isStructured: true,
      attributes: {
        label: 'State/Region',
        type: 'text',
        placeholder: '',
      },
      validation: [],
    },
  },

  {
    id: 'city',
    category: 'pii',
    title: 'fields.city',
    icon: 'MapPinned',
    component: {
      id: 'city',
      type: 'input',
      config: createConfig('city', true),
      isStructured: true,
      attributes: {
        label: 'City',
        type: 'text',
        placeholder: 'San Francisco',
      },
      validation: [],
    },
  },
  {
    id: 'zipCode',
    category: 'pii',
    title: 'fields.zipCode',
    icon: 'MapPinned',
    component: {
      id: 'zipCode',
      type: 'input',
      config: createConfig('zip_code', true),
      isStructured: true,
      attributes: {
        label: 'Zip code',
        type: 'number',
        placeholder: '12345',
      },
      validation: [
        {
          type: 'required',
          value: true,
          message: 'Zip code is required',
        },
      ],
    },
  },
  {
    id: 'street',
    category: 'pii',
    title: 'fields.street',
    icon: 'MapPinned',
    component: {
      id: 'street',
      type: 'input',
      config: createConfig('street', true),
      isStructured: true,
      attributes: {
        label: 'Street name',
        type: 'text',
        placeholder: '123 Main St',
      },
      validation: [
        {
          type: 'required',
          value: true,
          message: 'Street is required',
        },
      ],
    },
  },
  {
    id: 'unit',
    category: 'pii',
    title: 'fields.unit',
    icon: 'MapPinned',
    component: {
      id: 'unit',
      type: 'input',
      config: createConfig('unit', true),
      isStructured: true,
      attributes: {
        label: 'Floor / Unit',
        type: 'text',
        placeholder: '3rd',
      },
      validation: [],
    },
  },
];
