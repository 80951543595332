import { Skeleton } from '@spektr/client/components';

export const SkeletonContent = () => (
  <div className="flex w-full flex-row items-center py-2">
    <Skeleton className="h-7 w-80" />
    <div className="ml-auto">
      <Skeleton className="h-7 w-60" />
    </div>
  </div>
);
