import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import * as SheetPrimitive from '@radix-ui/react-dialog';

import { cn } from '@spektr/style-utils';

export const SheetTitle = forwardRef<
  ElementRef<typeof SheetPrimitive.Title>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={cn('text-color-text-primary text-sm font-semibold', className)}
    {...props}
  />
));
