import { useLayoutEffect, useState } from 'react';
import { useMeasure } from 'react-use';
import {
  MarkerType,
  ReactFlowProvider,
  Viewport,
  type Edge,
  type Node,
} from 'reactflow';
import { useSuspenseQuery } from '@tanstack/react-query';

import 'reactflow/dist/style.css';

import { casesCustomerParamsSchema } from '@spektr/shared/utils';

import { CUSTOMERS_KEYS } from '@spektr/client/services';

import { useParsedParams } from '@spektr/shared/hooks';

import { NODE_HEIGHT, NODE_WIDTH } from '../../constants/graph';

import { type EdgeData } from '../../types/EdgeData';
import { type NodeData } from '../../types/NodeData';

import { ConnectionsGraph } from '../../components/ConnectionsGraph';

const initialNodes: Node<NodeData>[] = [
  {
    id: '67175dc29ada53cff499f704',
    type: 'basicNode',
    data: {
      spektrId: '67175dc29ada53cff499f704',
      name: 'James Arthur Ratcliffe',
      role: 'owner',
      type: 'individual',
      subtext: '15th May 1985',
      status: 'approved',
    },
    position: { x: 0, y: 0 },
  },
  {
    id: '671a4907856dc07b15616946',
    type: 'basicNode',
    data: {
      spektrId: '671a4907856dc07b15616946',
      name: 'Ineos 2010 Limited',
      type: 'company',
      role: 'owner',
      subtext: 'United Kingdom',
      status: 'pending',
    },
    position: { x: 0, y: 100 },
  },
  {
    id: '671a1c4ef2a5a6d62a2bcdba',
    type: 'basicNode',
    data: {
      spektrId: '671a1c4ef2a5a6d62a2bcdba',
      name: 'INEOS GROUP LIMITED',
      type: 'company',
      subtext: 'United Kingdom',
      status: 'pending',
    },
    position: { x: 0, y: 200 },
  },
  {
    id: '6718bc0e05ff1cd15726ccbe',
    type: 'basicNode',
    data: {
      spektrId: '6718bc0e05ff1cd15726ccbe',
      name: 'INEOS GROUP HOLDINGS LTD',
      type: 'company',
      subtext: 'United Kingdom',
      status: 'pending',
      role: 'controller',
    },
    position: { x: 0, y: 300 },
  },
  {
    id: '5',
    type: 'basicNode',
    data: {
      spektrId: '5',
      name: 'INEOS TRUSTEES LIMITED',
      type: 'company',
      subtext: 'United Kingdom',
      status: 'pending',
      role: 'controller',
    },
    position: { x: 0, y: 400 },
  },
];

const initialEdges: Edge<EdgeData>[] = [
  {
    id: 'e12',
    source: '67175dc29ada53cff499f704',
    target: '671a4907856dc07b15616946',
    markerEnd: {
      type: MarkerType.Arrow,
    },
    data: {
      label: '75% to 100%',
    },
    type: 'edgeWithLabel',
  },
  {
    id: 'e13',
    source: '671a4907856dc07b15616946',
    target: '671a1c4ef2a5a6d62a2bcdba',
    markerEnd: {
      type: MarkerType.Arrow,
    },
    type: 'edgeWithLabel',
  },
  {
    id: 'e22a',
    source: '671a1c4ef2a5a6d62a2bcdba',
    target: '6718bc0e05ff1cd15726ccbe',
    markerEnd: {
      type: MarkerType.Arrow,
    },
    type: 'edgeWithLabel',
  },
  {
    id: 'e22b',
    source: '671a1c4ef2a5a6d62a2bcdba',
    target: '5',
    markerEnd: {
      type: MarkerType.Arrow,
    },
    type: 'edgeWithLabel',
  },
];

export const ConnectionsGraphWrapper = () => {
  const { spektrId } = useParsedParams(casesCustomerParamsSchema);
  const [canvasRef, { height, width }] = useMeasure<HTMLDivElement>();
  const [viewPort, setViewPort] = useState<Viewport | undefined>(undefined); // we cannot use `height` and `width` here on first render bc they are both 0 on first render

  useSuspenseQuery({
    queryKey: CUSTOMERS_KEYS.CONNECTIONS(spektrId),
    queryFn: () =>
      new Promise((resolve) => {
        setTimeout(() => resolve({ data: [] }), 1000);
      }),
  });

  useLayoutEffect(() => {
    if (!height || !width) return;

    // set initial viewport position to be center of canvas
    setViewPort((prev) => ({
      zoom: prev?.zoom ?? 1,
      x: width * 0.5 - NODE_WIDTH * 0.5,
      y: height * 0.5 - NODE_HEIGHT * 0.5,
    }));
  }, [height, width]);

  return (
    <div ref={canvasRef} className="h-[695px] w-full">
      <ReactFlowProvider>
        <ConnectionsGraph
          spektrId={spektrId}
          defaultViewPort={viewPort}
          initialNodes={initialNodes}
          initialEdges={initialEdges}
          onMoveEnd={setViewPort}
        />
      </ReactFlowProvider>
    </div>
  );
};
