import { FolderClock, LayoutGrid, RefreshCw } from 'lucide-react';

import { cn } from '@spektr/style-utils';
import { SpektrDatasetIcon, iconBoxVariants } from '@spektr/client/components';

import {
  CASES_URL,
  CONNECTION_HUB_URL,
  LOOPS_URL,
  PROCESS_DASHBOARD_URL,
} from '@spektr/shared/utils';

import { PageCard } from './PageCard';

export const PagesList = () => {
  return (
    <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-4">
      <PageCard
        pageName="Connections"
        title="Connect your data"
        description="Bring your data together to build processes and govern your datasets in a simple manner."
        icon={
          <SpektrDatasetIcon
            className={cn(
              iconBoxVariants({ variant: 'yellow' }),
              'h-11 w-11 p-3'
            )}
          />
        }
        url={CONNECTION_HUB_URL}
      />
      <PageCard
        pageName="Processes"
        title="Build Processes"
        description="Automate some of your operational tasks with processes that calculate risk and monitor changes."
        icon={
          <LayoutGrid
            className={cn(
              iconBoxVariants({ variant: 'cyan' }),
              'h-11 w-11 p-3'
            )}
          />
        }
        url={PROCESS_DASHBOARD_URL}
      />
      <PageCard
        pageName="Loops"
        title="Create Loops"
        description="Give meaning to your processes by defining resolution or getting what is missing for it."
        icon={
          <RefreshCw
            className={cn(iconBoxVariants({ variant: 'red' }), 'h-11 w-11 p-3')}
          />
        }
        url={LOOPS_URL}
      />
      <PageCard
        pageName="Cases"
        title="Case Management"
        description="Manage your cases and customers in a single place, ensuring your team has control over their execution."
        icon={
          <FolderClock
            className={cn(
              iconBoxVariants({ variant: 'blue' }),
              'h-11 w-11 p-3'
            )}
          />
        }
        url={CASES_URL}
      />
    </div>
  );
};
