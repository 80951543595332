import { forwardRef, ReactNode, useState } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { icons, X } from 'lucide-react';

type BannerProps = VariantProps<typeof bannerVariants> & {
  className?: string;
  message: ReactNode;
  iconName?: keyof typeof icons;
  dismissible?: boolean;
};

export const Banner = forwardRef<HTMLDivElement, BannerProps>(
  (
    { className, message, iconName, size = 'base', type, dismissible = false },
    ref
  ) => {
    const [isVisible, setIsVisible] = useState(true);

    const Icon = iconName ? icons[iconName] : null;

    if (!isVisible) return null;

    return (
      <div ref={ref} className={bannerVariants({ className, size, type })}>
        {Icon && <Icon className="h-5 w-5" />}
        {message}
        {dismissible && (
          <button className="ml-auto" onClick={() => setIsVisible(false)}>
            <X className="h-4 w-4" />
          </button>
        )}
      </div>
    );
  }
);

const bannerVariants = cva(
  'flex items-center w-full text-sm rounded-md font-medium',
  {
    variants: {
      type: {
        error: 'bg-color-red/5 text-color-red',
        warning: 'bg-color-yellow/5 text-color-yellow',
        info: 'bg-color-blue/5 text-color-blue',
        success: 'bg-color-green/5 text-color-green',
      },
      size: {
        small: 'py-4 px-5 gap-5',
        base: 'p-6 gap-4',
      },
      defaultVariants: {
        type: 'info',
        size: 'base',
      },
    },
  }
);
