import { useNavigate } from 'react-router-dom';
import { useSuspenseQuery } from '@tanstack/react-query';

import { PaginationCombobox } from '@spektr/client/components';
import { getAllCustomers } from '@spektr/client/services';
import { casesCustomerDetailsUrl } from '@spektr/shared/utils';

import { CustomersListFilters } from '../types';
import { CustomersTable } from '../components';
import { useCasesFilters } from '../../providers';

export const CustomersList = () => {
  const navigate = useNavigate();
  const { columnsToDisplay, filters, setFilter } = useCasesFilters();

  const { data: customers } = useSuspenseQuery({
    ...getAllCustomers({
      page: filters.page,
      pageLimit: filters.limit,
      searchQuery: filters.searchQuery,
      ...(filters as CustomersListFilters),
    }),
    refetchInterval: 10000,
  });

  const goToCustomerDetailsPage = (customerId: string) => {
    navigate(casesCustomerDetailsUrl(customerId));
  };

  return (
    <div className="flex h-full flex-col items-end gap-4">
      <CustomersTable
        customers={customers.docs}
        columns={columnsToDisplay}
        onRowClick={goToCustomerDetailsPage}
      />

      <PaginationCombobox
        currentPage={filters.page}
        totalPages={customers.totalPages}
        pageLimit={filters.limit}
        alignment="end"
        onChangeLimit={(value) => setFilter('limit', value)}
        onChangePage={(value) => setFilter('page', value)}
      />
    </div>
  );
};
