import { useState } from 'react';

import { DatePicker } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

import { CasesFilterConfiguration } from '../../types';

import { RemoveCasesFilterButton } from './RemoveCasesFilterButton';
import { CasesFilterLabel } from './CasesFilterLabel';

type DateCasesFilterProps = {
  identifier: string;
  configuration: CasesFilterConfiguration;
  appliedValue: string;
  handleChange: (filterIdentifier: string, filterValue: string) => void;
};
export const DateCasesFilter = ({
  identifier,
  configuration,
  appliedValue,
  handleChange,
}: DateCasesFilterProps) => {
  const [dateValue, setDateValue] = useState<string | number | undefined>(
    appliedValue
  );
  const [open, setOpen] = useState(false);

  return (
    <div className="flex items-center">
      <CasesFilterLabel
        onClick={() => setOpen(true)}
        icon={configuration.icon}
        label={configuration.label}
      />

      <DatePicker
        id={`dateInput + ${identifier}`}
        iconClassName="hidden"
        mode="single"
        epochOrToday={dateValue}
        className={cn(
          'bg-color-bg-button-icon dark:bg-color-bg-button-icon/20',
          'border-color-border-primary border-x-0',
          'rounded-none pl-0 pr-4'
        )}
        openState={open}
        showToday={false}
        onChange={(date) => {
          setDateValue(date);
          handleChange(identifier, (date || '').toString());
          setOpen(false);
        }}
        onOpenChange={setOpen}
      />

      <RemoveCasesFilterButton
        onClick={() => handleChange(identifier, 'remove')}
      />
    </div>
  );
};
