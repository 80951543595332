import { format } from 'date-fns';
import { Download, Eye, File, Trash } from 'lucide-react';
import { Link } from 'react-router-dom';

import {
  CasesTableCell,
  CasesTableRow,
  IconButton,
  IconWithBackground,
} from '@spektr/client/components';
import { LONG_DATE_FORMAT } from '@spektr/client/constants';
import { formatBytes } from '@spektr/client/utils';
import { cn } from '@spektr/style-utils';

import { Document } from '@spektr/shared/validators';

type DocumentsTableRowProps = {
  className?: string;
  columns: string[];
  document: Document;
  onRemoveDocument: (docKey: string) => void;
};

export const CustomerDocumentsTableRow = ({
  className,
  columns,
  document,
  onRemoveDocument,
}: DocumentsTableRowProps) => {
  return (
    <CasesTableRow className={cn('!min-h-[80px]', className)}>
      {columns.includes('description') && (
        <CasesTableCell
          className="col-span-4 flex items-center gap-4 px-4"
          isFirst={columns.indexOf('description') === 0}
          isLast={columns.indexOf('description') === columns.length - 1}
        >
          <IconWithBackground className="bg-color-bg-accent rounded-md">
            <File className="text-color-text-subtext h-4 w-4 min-w-4" />
          </IconWithBackground>

          <div className="flex min-w-0 flex-col items-start">
            <p className="w-full truncate text-base font-medium">
              {document.fileName}
            </p>
            <span className="text-color-text-subtext w-full truncate text-sm font-medium">
              {document.source === 'process'
                ? document.processName
                : document.source}
            </span>
          </div>
        </CasesTableCell>
      )}

      {columns.includes('size') && document.size && (
        <CasesTableCell
          className="col-span-2 flex items-center"
          isFirst={columns.indexOf('size') === 0}
          isLast={columns.indexOf('size') === columns.length - 1}
        >
          <p>{formatBytes(document.size).toLowerCase()}</p>
        </CasesTableCell>
      )}

      {columns.includes('creationDate') && document.createdAt && (
        <CasesTableCell
          className="col-span-2 flex items-center"
          isFirst={columns.indexOf('creationDate') === 0}
          isLast={columns.indexOf('creationDate') === columns.length - 1}
        >
          <p>{format(document.createdAt, LONG_DATE_FORMAT)}</p>
        </CasesTableCell>
      )}

      {columns.includes('expiryDate') && (
        <CasesTableCell
          className="col-span-2 flex items-center"
          isFirst={columns.indexOf('expiryDate') === 0}
          isLast={columns.indexOf('expiryDate') === columns.length - 1}
        ></CasesTableCell>
      )}

      {columns.includes('actions') &&
        document.previewPresignedUrl &&
        document.downloadPresignedUrl && (
          <CasesTableCell
            className="col-span-4 flex items-center justify-end gap-2 px-6"
            isFirst={columns.indexOf('actions') === 0}
            isLast={columns.indexOf('actions') === columns.length - 1}
          >
            <Link to={document.previewPresignedUrl} target="_blank">
              <IconButton color="icon" className="h-9 w-9 min-w-9 p-0">
                <Eye className="h-4 w-4" />
              </IconButton>
            </Link>
            <Link to={document.downloadPresignedUrl}>
              <IconButton color="icon" className="h-9 w-9 min-w-9 p-0">
                <Download className="h-4 w-4" />
              </IconButton>
            </Link>
            <IconButton
              color="icon"
              className="h-9 w-9 min-w-9 p-0"
              onClick={() => onRemoveDocument(document.key)}
            >
              <Trash className="h-4 w-4" />
            </IconButton>
          </CasesTableCell>
        )}
    </CasesTableRow>
  );
};
