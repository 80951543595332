import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

export type InputProps = InputHTMLAttributes<HTMLInputElement> &
  VariantProps<typeof rootVariants> &
  VariantProps<typeof sizeVariants> &
  VariantProps<typeof colorVariants> & {
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    error?: boolean;
  } & {
    'data-testid'?: string;
  };

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className: classNameProps,
      endIcon,
      startIcon,
      dimension,
      inline = false,
      colorVariant,
      error = false,
      type = 'text',
      ...props
    },
    ref
  ) => {
    const className = cn(
      'flex items-center w-full',
      'rounded-md border',
      'transition-colors',
      rootVariants({ inline }),
      colorVariants({ colorVariant }),
      sizeVariants({ dimension }),
      error && '!border-color-red',
      classNameProps
    );

    const inputClassName = cn(
      'disabled:cursor-not-allowed',
      'focus-visible:outline-none focus-visible:ring-0',
      'file:border-0 file:bg-transparent',
      'placeholder-color-text-placeholder',
      'disabled:text-color-text-input--disabled disabled:opacity-50',
      type === 'number' &&
        '[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
      sizeVariants({ dimension })
    );

    if (startIcon || endIcon) {
      return (
        <div
          className={cn(
            'flex items-center gap-1',
            props.disabled &&
              'cursor-not-allowed opacity-50' &&
              'text-color-text-subtext bg-color-bg-accent',
            className
          )}
        >
          {startIcon}
          <input
            {...props}
            type={type}
            ref={ref}
            className={cn(
              inputClassName,
              'h-auto w-full p-0',
              'border-none bg-transparent shadow-none outline-none'
            )}
          />
          {endIcon}
        </div>
      );
    }

    return (
      <input
        {...props}
        type={type}
        ref={ref}
        className={cn(
          'disabled:text-color-text-input--disabled disabled:bg-color-bg-accent',
          inputClassName,
          className
        )}
      />
    );
  }
);

const rootVariants = cva('', {
  variants: {
    inline: {
      true: 'border-0',
      false: 'border',
    },
  },
  defaultVariants: {
    inline: false,
  },
});

const sizeVariants = cva('font-medium leading-4', {
  variants: {
    dimension: {
      default: 'px-3 py-1 h-9 text-sm',
      small: 'px-2 py-1 h-7 text-xs',
    },
  },
  defaultVariants: {
    dimension: 'default',
  },
});

const colorVariants = cva(
  [
    'text-color-text-input placeholder-color-text-placeholder',
    'focus:outline-none focus:ring-0',
  ],
  {
    variants: {
      colorVariant: {
        primary: 'border-color-border-input bg-color-bg-primary',
        secondary:
          'border-color-border-input-secondary bg-color-bg-input-secondary dark:bg-color-bg-input-secondary/20',
      },
    },
    defaultVariants: {
      colorVariant: 'primary',
    },
  }
);
