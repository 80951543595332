import { useMutation, useQueryClient } from '@tanstack/react-query';

import { TAGS_KEYS, ProcessApiClient } from '@spektr/client/services';

export const useDeleteTag = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (tagId: string) => {
      return ProcessApiClient.getClient().deleteTagById(undefined, {
        params: {
          tagId,
        },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: TAGS_KEYS.ALL,
      });
    },
  });

  return mutation;
};
