import { ArrowLeft } from 'lucide-react';

import { cn, isRgbaString } from '@spektr/style-utils';
import { RBAC } from '@spektr/shared/rbac';

import { usePermissionsContext } from '@spektr/client/providers';
import { ButtonWithTooltip, IconButton } from '@spektr/client/components';

import { useFormEngine } from '../../hooks/useFormEngine';
import { areCustomDataFieldsSet } from '../../utils/areCustomDataFieldsSet';

import { Title } from '../../components/Title';

export type FormDialogHeaderProps = {
  defaultStylingMode?: boolean;
  isSavePending?: boolean;
  processTitle: string;
  nodeTitle?: string;
  onClick?: () => void;
  onClickBack?: () => void;
  onUpdateNodeTitle?: (nodeTitle: string) => void;
};

export const FormDialogHeader = ({
  defaultStylingMode = false,
  isSavePending = false,
  processTitle,
  nodeTitle,
  onClickBack,
  onUpdateNodeTitle,
}: FormDialogHeaderProps) => {
  const { saveForm, state } = useFormEngine();
  const { hasPermission } = usePermissionsContext();
  const canEdit = hasPermission(RBAC.ACTIONS.NODE.UPDATE);

  const areCustomFieldsSet = areCustomDataFieldsSet(
    Object.values(state.fields)
  );

  const isFormEmpty = Object.values(state.fields).length === 0;

  const hasValidStyling = [
    state.branding.primaryColor,
    state.branding.backgroundColor,
    state.branding.borderColor,
    state.branding.textColor,
    state.branding.subTextColor,
    state.assistingContent.support.textColor,
    state.assistingContent.support.backgroundColor,
  ].every((style) => isRgbaString(style));

  const isFormDisabled = defaultStylingMode
    ? !hasValidStyling || !canEdit
    : !hasValidStyling ||
      !canEdit ||
      !nodeTitle ||
      !areCustomFieldsSet ||
      isFormEmpty;

  return (
    <div className={cn('border-b p-1.5', 'bg-color-bg-moonraker')}>
      <div className="flex flex-row justify-between">
        <div className={cn('flex flex-1 flex-row items-center gap-3')}>
          <IconButton
            color="secondary"
            className="h-9 w-9 rounded-md p-0"
            onClick={onClickBack}
            data-cy="form-builder-back-button"
          >
            <ArrowLeft className="h-4 w-4 shrink-0" />
            <span className="sr-only">Close</span>
          </IconButton>
          {!defaultStylingMode ? (
            <Title
              processTitle={processTitle}
              nodeTitle={nodeTitle}
              updateNodeTitle={onUpdateNodeTitle}
            />
          ) : (
            <p className={cn('text-sm font-medium', 'text-color-text-subtext')}>
              {processTitle}
            </p>
          )}
        </div>
        <div className={cn('mt-0 flex flex-row items-center gap-6')}>
          <ButtonWithTooltip
            color="secondary"
            className="h-full transition-colors"
            disabled={isSavePending || isFormDisabled}
            showTooltip={isFormDisabled && !areCustomFieldsSet}
            tooltipProps={{
              content: 'Please fill out all required fields before saving.',
            }}
            onClick={saveForm}
          >
            {isSavePending ? 'Saving...' : 'Save form'}
            <span className="sr-only">Save</span>
          </ButtonWithTooltip>
        </div>
      </div>
    </div>
  );
};
