import { useEffect, useState } from 'react';

import {
  AddFilter,
  CasesFilter,
  SearchBar,
  ToggleFilters,
  ClearFilters,
} from '../components';
import { useCasesFilters } from '../providers';
import { useGetCasesFiltersOptions } from '../hooks';

export const CasesFiltersList = () => {
  const { configurations, removeValue, filters, setFilter, clearFilters } =
    useCasesFilters();
  const { filtersOptions } = useGetCasesFiltersOptions(filters, configurations);

  const [manualChange, setManualChange] = useState<boolean>(false);
  const [showFiltersList, setShowFiltersList] = useState<string[]>([]);
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const onFilterChange = (filterIdentifier: string, filterValue: string) => {
    setFilter(filterIdentifier, filterValue);
    handleShowFiltersListChange(filterIdentifier, filterValue);
  };

  useEffect(() => {
    if (!manualChange) {
      setShowFiltersList(
        Object.entries(filters)
          .filter(
            ([filterIdentifier, filterValue]) =>
              configurations[filterIdentifier] &&
              !!filterValue &&
              (filterValue as string[]).length > 0
          )
          .map(([filterIdentifier]) => filterIdentifier)
      );
      setShowFilters(showFiltersList.length > 0);
    }
  }, [configurations, filters, manualChange, showFiltersList.length]);

  const handleShowFiltersListChange = (
    filterIdentifier: string,
    filterValue?: string
  ) => {
    if (filterValue === undefined) {
      if (showFiltersList.includes(filterIdentifier)) {
        return;
      }

      setManualChange(true);
      setShowFiltersList([...showFiltersList, filterIdentifier]);
      setShowFilters(true);
    }

    if (filterValue === removeValue) {
      setShowFiltersList(
        showFiltersList.filter((filter) => filter !== filterIdentifier)
      );
      setManualChange(true);
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex w-full items-center justify-between">
        <SearchBar
          defaultValue={filters.searchQuery}
          onSearch={(value: string) => setFilter('searchQuery', value)}
        />

        <div className="flex items-center">
          <AddFilter
            filtersOptions={filtersOptions}
            handleClick={handleShowFiltersListChange}
          />
          <ToggleFilters
            disabled={showFiltersList.length === 0}
            showFilters={showFilters}
            onToggle={() => setShowFilters(!showFilters)}
          />
          <ClearFilters
            disabled={showFiltersList.length === 0}
            onClick={() => {
              setManualChange(false);
              clearFilters();
            }}
          />
        </div>
      </div>

      {showFilters && showFiltersList.length > 0 && (
        <div className="flex flex-wrap items-start gap-6 gap-y-3">
          {showFiltersList.map((identifier) => (
            <CasesFilter
              key={'filter' + identifier}
              identifier={identifier}
              onFilterChange={onFilterChange}
            />
          ))}
        </div>
      )}
    </div>
  );
};
