import { ProcessRuns } from '@spektr/model-builder/containers';

import { DashboardWrapper } from './DashboardWrapper';

export const ProcessRunsDashboard = () => {
  return (
    <DashboardWrapper>
      <ProcessRuns />
    </DashboardWrapper>
  );
};
