import { forwardRef } from 'react';
import { cva } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

export type DropAreaProps = {
  isActive?: boolean;
  canDrop?: boolean;
  canvasLength?: number;
};

export const DropArea = forwardRef<HTMLDivElement, DropAreaProps>(
  ({ isActive = false, canDrop = false, canvasLength = 0 }, ref) => {
    const colors = {
      'bg-color-green/[.02]': !isActive && canDrop,
      'bg-color-green/10': isActive,
    };
    const message = isActive
      ? 'Release to drop'
      : 'Drag your pre-built block or field into this form to get started';

    if (canvasLength === 0 && !isActive) {
      return (
        <div
          ref={ref}
          className={cn(
            'flex items-center justify-center',
            'h-20 px-6',
            'dark:border-color-border-white border-color-border-tertiary border-y border-dashed',
            'transition-colors duration-200',
            colors
          )}
        >
          <span className="text-color-text-subtext text-center text-sm font-medium">
            {message}
          </span>
        </div>
      );
    }

    return (
      <div
        ref={ref}
        className={cn(
          'h-0',
          'overflow-hidden opacity-0',
          { ...colors, 'opacity-100': canDrop, 'h-20': canDrop },
          boxVariants()
        )}
      >
        <span>{message}</span>
      </div>
    );
  }
);

const boxVariants = cva([
  'flex w-full items-center justify-center',
  'border-y border-dashed border-color-green',
  'text-center text-sm font-medium text-color-green',
  'transition-all duration-200',
]);
