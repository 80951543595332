import { cn } from '@spektr/style-utils';

import { Table, TableProps } from '../../Table';

export const CasesTable = ({ className, children, ...props }: TableProps) => {
  return (
    <Table
      className={cn(
        'border-separate border-spacing-y-2',
        'bg-transparent',
        className
      )}
      {...props}
    >
      {children}
    </Table>
  );
};
