import { useRef, useState } from 'react';
import { useDebounce } from 'react-use';

import { Input } from '@spektr/client/components';
import { cn } from '@spektr/style-utils';

import { CasesFilterConfiguration } from '../../types';

import { RemoveCasesFilterButton } from './RemoveCasesFilterButton';
import { CasesFilterLabel } from './CasesFilterLabel';

type InputCasesFilterProps = {
  identifier: string;
  configuration: CasesFilterConfiguration;
  appliedValue: string;
  handleChange: (filterIdentifier: string, filterValue: string) => void;
};

export const InputCasesFilter = ({
  identifier,
  configuration,
  appliedValue,
  handleChange,
}: InputCasesFilterProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<number | undefined>(
    appliedValue ? +appliedValue : undefined
  );

  useDebounce(
    () =>
      inputValue !== undefined &&
      inputValue?.toString() !== appliedValue &&
      handleChange(
        identifier,
        inputValue && inputValue !== 0 ? inputValue?.toString() : ''
      ),
    400,
    [inputValue]
  );

  return (
    <div className="flex items-center">
      <CasesFilterLabel
        onClick={() => inputRef.current?.focus()}
        icon={configuration.icon}
        label={configuration.label}
      />

      <Input
        type="number"
        ref={inputRef}
        colorVariant="secondary"
        className={cn(
          'w-16 max-w-16 pl-0',
          'rounded-none border-x-0',
          'focus:border-color-border-input--focus'
        )}
        defaultValue={appliedValue}
        placeholder="0"
        onChange={(e) => setInputValue(+e.target.value)}
      />

      <RemoveCasesFilterButton
        onClick={() => handleChange(identifier, 'remove')}
      />
    </div>
  );
};
