import { useNavigate } from 'react-router-dom';
import { Rocket, Route } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';

import {
  processDetailsParamsSchema,
  processFieldMappingFullUrl,
  processScheduleUrl,
  processStrategyFullUrl,
} from '@spektr/shared/utils';
import { useParsedParams } from '@spektr/shared/hooks';

import {
  Button,
  Skeleton,
  SpektrErrorBoundary,
} from '@spektr/client/components';
import { getProcessByIdQuery } from '@spektr/client/services';

import {
  ProcessDemoButton,
  FieldMappingButtonWithBadge,
  FieldMappingButton,
} from '@spektr/model-builder/containers';
import { Suspense } from 'react';
import { handleAxiosError } from '@spektr/client/utils';

export const ProcessActionsHeader = () => {
  const { processId } = useParsedParams(processDetailsParamsSchema);
  const { data: process } = useQuery(getProcessByIdQuery(processId));

  const navigate = useNavigate();

  const handleClickSchedule = () => {
    navigate(processScheduleUrl(processId));
  };

  return (
    <div className="flex items-center gap-4">
      {process?.type === 'onboarding' && (
        <ProcessDemoButton processId={processId} />
      )}
      <Button
        variant="text"
        size="sm"
        className="gap-2"
        onClick={() => navigate(processStrategyFullUrl(processId))}
      >
        <Route className="h-4 w-4" />
        <span>Strategy</span>
      </Button>
      <SpektrErrorBoundary
        fallbackRender={(error) => (
          <FieldMappingButton
            disabled={true}
            disabledMessage={handleAxiosError(
              error,
              'Something went wrong when loading the custom fields.'
            )}
          />
        )}
      >
        <Suspense
          fallback={
            <div className="flex flex-grow items-center gap-2">
              <Skeleton className="h-4 w-4" />
              <Skeleton className="h-4 w-28" />
            </div>
          }
        >
          <FieldMappingButtonWithBadge
            processId={processId}
            destination={processFieldMappingFullUrl(processId)}
          />
        </Suspense>
      </SpektrErrorBoundary>
      <Button
        startIcon={<Rocket className="h-4 w-4" />}
        color="cyan"
        onClick={handleClickSchedule}
      >
        Launch processs
      </Button>
    </div>
  );
};
