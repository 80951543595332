import { useMemo, useState } from 'react';

import { cn } from '@spektr/style-utils';

import { SpektrFieldKey, type KyckrOutcome } from '@spektr/shared/types';

import { SelectableServiceItem } from '../../../../components/SelectableServiceItem';

import { isKyckrFieldSelected } from '../../utils/isKyckrFieldSelected';
import { KyckrUboFields } from '../../components/KyckrUboFields';

export namespace PersonOfInterestForm {
  export type Props = {
    selectedFields: KyckrOutcome[];
    onFieldChange: (key: KyckrOutcome, value: boolean) => void;
  };
}

const UBO_FIELDS = [
  'first_layer_of_ownership',
  'all_potential_layers_of_ownership',
];

export const PersonOfInterestForm = ({
  selectedFields,
  onFieldChange,
}: PersonOfInterestForm.Props) => {
  const defaultSelectedUboField = useMemo(
    () =>
      UBO_FIELDS.find(
        (fieldKey) =>
          selectedFields.includes(fieldKey) ||
          isKyckrFieldSelected(fieldKey, selectedFields)
      ) ?? '',
    [selectedFields]
  );
  const [uboKey, updateUboKey] = useState<string>(defaultSelectedUboField);

  const handleUBOChange = (key: string) => {
    updateUboKey((prev) => {
      if (prev) {
        onFieldChange(prev as KyckrOutcome, false);
      }
      onFieldChange(key as KyckrOutcome, true);
      return key;
    });
  };

  const handleChangeRepresentativesCheck = (value: boolean) => {
    onFieldChange('representatives' as KyckrOutcome, value);
  };

  return (
    <div className={cn('flex', 'rounded-md border')}>
      <div className={cn('w-1/2 shrink-0 border-r px-4 py-5')}>
        <SelectableServiceItem
          checked={
            selectedFields.includes('representatives') ||
            isKyckrFieldSelected('representatives', selectedFields)
          }
          field={{
            key: 'representatives' as SpektrFieldKey,
            label: 'Representatives',
            value: isKyckrFieldSelected('representatives', selectedFields),
            help: 'Get information about representatives and their role in the company.',
          }}
          onChange={handleChangeRepresentativesCheck}
        />
      </div>
      <div className="flex flex-1 items-start gap-2 px-4 py-5">
        <KyckrUboFields
          selectedKey={uboKey}
          onCheckedChange={handleUBOChange}
        />
      </div>
    </div>
  );
};
