import { useNavigate, useParams } from 'react-router-dom';

import {
  casesAlertHitDetailsUrl,
  casesCustomerAlertHitUrl,
} from '@spektr/shared/utils';

export const useNavigateToHit = () => {
  const { spektrId } = useParams();
  const navigate = useNavigate();

  return (alertId: string, hitId: string) => {
    if (spektrId) {
      navigate(casesCustomerAlertHitUrl(spektrId, alertId, hitId));
    } else {
      navigate(casesAlertHitDetailsUrl(alertId, hitId));
    }
  };
};
