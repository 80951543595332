import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'lucide-react';

import { openLinkInNewTab } from '@spektr/client/utils';
import { cn } from '@spektr/style-utils';
import { usePermissionsContext } from '@spektr/client/providers';
import {
  PageIntro,
  PageIntroCard,
  iconBoxVariants,
} from '@spektr/client/components';

import { RBAC } from '@spektr/shared/rbac';

import { getIntegrationSources } from '../../constants/integrationSources';

export const Connections = () => {
  const navigate = useNavigate();
  const { hasPermission } = usePermissionsContext();

  const handleClick = (url?: string) => {
    if (url) {
      if (url.startsWith('http')) {
        openLinkInNewTab(url);
        return;
      }

      navigate(url);
    }
  };

  const INTEGRATION_SOURCES = useMemo(
    () => getIntegrationSources(hasPermission(RBAC.ACTIONS.DATASET.UPDATE)),
    [hasPermission]
  );

  return (
    <PageIntro
      title="Connect data sources"
      description="Select different options for importing your data sources on spektr"
      renderIcon={(iconClassName) => (
        <Link
          className={cn(
            iconBoxVariants({
              variant: 'yellow',
            }),
            iconClassName
          )}
        />
      )}
    >
      <div
        className={cn('flex flex-row flex-wrap items-center gap-4', 'ml-auto')}
      >
        {INTEGRATION_SOURCES.map((item) => (
          <PageIntroCard
            key={item.title}
            title={item.title}
            description={item.description}
            renderIcon={item.icon}
            actionName={item.actionName}
            actionButtonProps={{
              color: 'yellow',
              disabled: item.disabled,
              'data-cy': item.dataCy,
              onClick: () => handleClick(item.link),
            }}
          />
        ))}
      </div>
    </PageIntro>
  );
};
