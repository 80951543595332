import { cn } from '@spektr/style-utils';

import { Checkbox } from '../../../forms/Checkbox';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../../Accordion';

import { ProcessItem } from '../../types/ProcessItem';

export type CollapseItemProps = ProcessItem & {
  isSelected?: boolean;
  onCheck: (itemId: string, value: boolean) => void;
};

export const CollapseItem = ({
  children,
  id,
  label,
  isSelected,
  type,
  onCheck,
}: CollapseItemProps) => {
  return (
    <AccordionItem
      className={cn(
        'border-color-border-primary rounded-md border',
        'hover:bg-color-bg-accordion-item--hover',
        'transition-colors'
      )}
      value={id}
    >
      <AccordionTrigger className="px-4 py-2" iconPosition="right">
        <div className={cn('flex flex-row items-center gap-4')}>
          <Checkbox disabled defaultChecked={isSelected} />
          <div className="flex flex-col items-start">
            <span className="text-color-text-primary text-sm">{label}</span>
            <span className="text-color-text-subtext text-xs capitalize tracking-wide">
              {type}
            </span>
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <div className={cn('ml-8 flex flex-col gap-3 p-4 pt-2')}>
          {children.map((child) => (
            <Checkbox
              key={child.id}
              id={child.id}
              className="text-color-text-primary items-start"
              label={child.label}
              defaultChecked={child.isSelected}
              onCheckedChange={(value) => {
                typeof value === 'boolean' && onCheck(child.id, value);
              }}
            />
          ))}
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};
