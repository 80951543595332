import { useCallback, useMemo, useState } from 'react';
import { produce } from 'immer';

import { type ServiceFieldClient } from '@spektr/client/types';
import { type SpektrFieldKey, type ServiceNode } from '@spektr/shared/types';

export function useSelectedFields(
  node: ServiceNode,
  serviceFields: ServiceFieldClient[]
) {
  const defaultSelectedFields = serviceFields
    .filter((field) => node.fields[field.key] ?? false)
    .map((field) => field.key);

  const [selectedFields, setSelectedFields] = useState<SpektrFieldKey[]>(
    defaultSelectedFields as SpektrFieldKey[]
  );

  const handleFieldChange = useCallback(
    (fieldKey: SpektrFieldKey, value: boolean) => {
      setSelectedFields(
        produce((draft) => {
          if (value) {
            draft.push(fieldKey);
          } else {
            const index = draft.findIndex((item) => item === fieldKey);

            if (index !== -1) {
              draft.splice(index, 1);
            }
          }
        })
      );
    },
    [setSelectedFields]
  );

  return useMemo(
    () => ({
      selectedFields,
      handleFieldChange,
    }),
    [selectedFields, handleFieldChange]
  );
}
