import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Sparkles } from 'lucide-react';

import { HitDecisionStatus } from '@spektr/shared/validators';

import { cn } from '@spektr/style-utils';
import { getAlertHitById, getTeamMembersQuery } from '@spektr/client/services';

import { Spinner, Tooltip } from '@spektr/client/components';

import { useAlertDetails } from '../providers';

import {
  useAlertAndHitIdParam,
  useHitResolutionReason,
  useRenderDetailsTable,
  useUpdateHit,
} from '../hooks';

import { computeHitCardDetails, getAlertTypeBasedOnService } from '../utils';
import { formatSpektrAIDetails } from '../utils/computeSpektrAIDetails';

import { HitStatus } from '../components/HitStatus';
import { BasicDetailsTable } from '../components/BasicDetailsTable';
import { HitResolutionComment } from '../components/HitResolutionComment';
import { HitResolutionButtons } from '../components/HitResolutionButtons';
import { HitDetailsTabNavigation } from '../components/HitDetailsTabNavigation';
import { MessageCount } from '../components/MessageCount';
import { AlertNavigation } from '../components/AlertNavigation';

export const HitContent = () => {
  const { t } = useTranslation();
  const { alert, customerName } = useAlertDetails();
  const { alertId, hitId } = useAlertAndHitIdParam();
  const { data: hit } = useSuspenseQuery(getAlertHitById(alertId, hitId));
  const { data: users } = useSuspenseQuery(getTeamMembersQuery());

  const updateHitMutation = useUpdateHit(
    alert?.datasetId ?? '',
    alert?.spektrId ?? ''
  );
  const { reason, updateReason, user, resolvedAt } = useHitResolutionReason(
    hit.decision,
    users
  );
  const { renderColumn, renderValue } = useRenderDetailsTable();
  const alertType = getAlertTypeBasedOnService(hit.vendorDetails.service);

  const handleUpdateHit = async (decision: HitDecisionStatus) => {
    if (decision === 'pending') return;

    const unresolvedHitsCount = alert?.hits.filter(
      (hit) => hit.decision.status === 'pending'
    ).length;

    return updateHitMutation.mutateAsync({
      alertId: alertId,
      hitId,
      status: decision,
      reason,
      resetList: unresolvedHitsCount === 1 || alert?.status === 'open',
    });
  };

  const alertHit = useMemo(
    () => alert?.hits.find((hit) => hit.id === hitId),
    [alert?.hits, hitId]
  );
  const simplifiedData = useMemo(
    () =>
      alertHit ? computeHitCardDetails(alertHit.vendorDetails, alertHit) : {},
    [alertHit]
  );

  return (
    <div
      className={cn(
        'flex max-h-[500px] flex-col gap-6 overflow-y-auto p-6',
        'rounded-md border',
        'bg-color-bg-dialog-content dark:bg-color-bg-dialog-content/20'
      )}
    >
      <div className={cn('flex items-center')}>
        <p className="text-base font-semibold">{customerName}</p>
        <div className={cn('flex items-center gap-4', 'ml-auto')}>
          {hit.spektrAIDetails?.status === 'failed' && (
            <Tooltip
              content="spektr-ai failed to solve the hit"
              delayDuration={0}
            >
              <Sparkles className="stroke-color-red h-4 w-4 p-0.5" />
            </Tooltip>
          )}
          {hit.spektrAIDetails?.status === 'pending' &&
          hit.decision.status === 'pending' ? (
            <Tooltip content="spektr-ai is resolving..." delayDuration={0}>
              <Spinner className="opacity-30" size="md" />
            </Tooltip>
          ) : (
            <HitStatus hitType={alertType} decision={hit.decision.status} />
          )}
          <AlertNavigation
            prevId={hit.prevHitId}
            nextId={hit.nextHitId}
            alertId={alertId}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {simplifiedData && (
          <BasicDetailsTable
            data={simplifiedData}
            columnClassName="w-[320px]"
            renderColumn={renderColumn}
            renderValue={renderValue}
          />
        )}
        {hit.spektrAIDetails && hit.spektrAIDetails.status === 'enabled' && (
          <div className="flex flex-col gap-4">
            <p className="text-color-text-primary text-base font-medium">
              spektrAI details
            </p>
            <BasicDetailsTable
              data={formatSpektrAIDetails(hit.spektrAIDetails, t, customerName)}
              columnClassName="w-[320px]"
              renderValue={renderValue}
            />
          </div>
        )}
        <div className={cn('flex flex-row flex-wrap gap-4 lg:flex-nowrap')}>
          <MessageCount hitId={hitId} count={alertHit?.commentCount ?? 0} />
          <HitResolutionComment
            user={user}
            resolvedAt={resolvedAt}
            value={reason}
            onUpdateComment={updateReason}
          />
          <HitResolutionButtons
            id={hitId}
            hitType={alertType}
            spektrAiDetails={hit.spektrAIDetails}
            decision={hit.decision}
            isDisabled={
              updateHitMutation.isPending || hit.decision.status !== 'pending'
            }
            onFalsePositiveClick={() => handleUpdateHit('false-positive')}
            onTruePositiveClick={() => handleUpdateHit('true-positive')}
          />
        </div>
      </div>
      <HitDetailsTabNavigation />
      <Outlet />
    </div>
  );
};
