import { PenLine } from 'lucide-react';

import { Tag as TagType } from '@spektr/shared/validators';

import {
  DropdownMenuComboBox,
  DropdownOption,
  Tag,
  Tooltip,
} from '@spektr/client/components';

type OutcomeNodeTagProps = {
  tag?: TagType;
  actions: DropdownOption[];
  onActionClick: (value: string) => void;
};

export const OutcomeNodeTag = ({
  tag,
  actions,
  onActionClick,
}: OutcomeNodeTagProps) => {
  return (
    <DropdownMenuComboBox
      triggerProps={{
        className:
          'w-full p-3 hover:bg-color-bg-accent leading-none !text-left',
      }}
      modal
      itemClassName="text-sm w-full max-w-72"
      trigger={
        !tag ? (
          <Tooltip
            tooltipTriggerProps={{
              className: 'w-full flex items-center gap-1.5',
            }}
            content="No tag added to your customers yet"
          >
            <Tag label="Choose a tag" />
            <PenLine className="stroke-color-text-subtext ml-auto h-3 w-3" />
          </Tooltip>
        ) : tag.isDeleted ? (
          <Tooltip
            tooltipTriggerProps={{
              className: 'w-full',
            }}
            content="This tag has been deleted"
          >
            <Tag
              label={tag.label}
              color={tag.color}
              isDeleted={tag.isDeleted}
            />
          </Tooltip>
        ) : (
          <Tag label={tag.label} color={tag.color} />
        )
      }
      options={actions}
      onClick={onActionClick}
    />
  );
};
