import { useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import {
  getAllExecutionsQuery,
  getProcessesQuery,
} from '@spektr/client/services';
import { PaginationCombobox } from '@spektr/client/components';

import { ExecutionsTable } from '../../components';

type ExecutionsListProps = {
  page: number;
  limit: number;
  searchQuery: string;
  selectedDatasetId: string;
  setPage: (page: number) => void;
  setLimit: (limit: number) => void;
};

export const ExecutionsList = ({
  page,
  limit,
  searchQuery,
  selectedDatasetId = '',
  setPage,
  setLimit,
}: ExecutionsListProps) => {
  const { data: processes } = useSuspenseQuery(getProcessesQuery());
  const { data: executions } = useSuspenseQuery({
    ...getAllExecutionsQuery(selectedDatasetId, {
      searchQuery,
      limit,
      page,
    }),
    refetchInterval: 10000,
  });

  const processAndLoopsList = useMemo(
    () => [...(processes ?? [])],
    [processes]
  );

  return (
    <div className="flex h-full flex-col items-end gap-4">
      <ExecutionsTable
        executions={executions.docs}
        processes={processAndLoopsList}
      />
      <PaginationCombobox
        currentPage={page}
        totalPages={executions.totalPages}
        pageLimit={limit}
        alignment="end"
        onChangeLimit={setLimit}
        onChangePage={setPage}
      />
    </div>
  );
};
