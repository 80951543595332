import { ReactNode } from 'react';

export type HitsLayoutProps = {
  children: ReactNode;
};

export const HitsLayout = ({ children }: HitsLayoutProps) => {
  return (
    <div className="relative grid max-h-96 w-full flex-col gap-2 overflow-y-auto">
      {children}
    </div>
  );
};
