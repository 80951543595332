import { ReactNode } from 'react';

import { cn } from '@spektr/style-utils';

import { useFormRouter } from '../../hooks/useFormRouter';

export type FormDialogContentProps = {
  children: ReactNode | ReactNode[];
};

export const FormDialogContent = ({ children }: FormDialogContentProps) => {
  const { isActiveRoute } = useFormRouter();

  let gridCols =
    'grid-cols-[64px_256px_auto] xl:grid-cols-[64px_320px_auto] 2xl:grid-cols-[64px_384px_auto]';

  if (isActiveRoute('design')) {
    gridCols = 'grid-cols-[64px_420px_auto]';
  } else if (isActiveRoute('layout')) {
    gridCols = 'grid-cols-[232px_420px_auto]';
  }

  return (
    <div className={cn('grid', 'h-full', 'bg-color-bg-foreground', gridCols)}>
      {children}
    </div>
  );
};
