import { NodeType } from '@spektr/shared/validators';

export type SupportedNodes = Exclude<
  NodeType,
  'filter' | 'loopSource' | 'customerStatus' | 'outcome'
>;

const UNSUPPORTED_NODES = ['filter', 'loopSource', 'customerStatus', 'outcome'];

/**
 * @description
 *    Some nodes do not have a corresponding dialog (e.g. customerStatus, outcome)
 *  or the dialog is handled as specific route (e.g. filter, loopSource).
 *
 * @param node - node type
 * @returns {node is SupportedNodes}
 */
export function isSupportedNode(node: NodeType): node is SupportedNodes {
  return !UNSUPPORTED_NODES.includes(node);
}
