import { cn } from '@spektr/style-utils';
import { formatDistanceToNow } from 'date-fns';
import { Clock } from 'lucide-react';

type CustomerTimelineDateCellProps = {
  date: number;
};

export const CustomerTimelineDateCell = ({
  date,
}: CustomerTimelineDateCellProps) => {
  const age = formatDistanceToNow(date, { addSuffix: true });

  return (
    <div
      className={cn(
        'flex items-center gap-2',
        'col-span-2 col-start-11 ml-auto',
        'text-color-text-subtext text-xs'
      )}
    >
      <Clock className="h-4 w-4" />
      <span>{age}</span>
    </div>
  );
};
