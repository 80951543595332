import { ListMinus } from 'lucide-react';

import { Task } from '@spektr/shared/validators';

import { cn } from '@spektr/style-utils';

import { TaskItem } from './TaskItem';

type ChecklistProps = {
  tasks: Task[];
  onRemoveTask: (taskId: string) => void;
};

export const Checklist = ({ tasks, onRemoveTask }: ChecklistProps) => {
  return (
    <div className="flex flex-col gap-4">
      <p className="text-sm font-medium">Checklist</p>
      <div
        className={cn(
          'flex flex-col gap-2',
          '-mr-3 h-44 overflow-y-auto',
          tasks.length === 0 && 'justify-center'
        )}
      >
        {tasks.length > 0 ? (
          tasks.map((task) => (
            <TaskItem key={task.id} task={task} deleteTask={onRemoveTask} />
          ))
        ) : (
          <div className="text-color-text-subtext mr-3 flex flex-col items-center gap-5">
            <ListMinus className="h-4 w-4" />
            <span className="text-sm font-medium">
              No items added to checklist yet
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
