import { useMemo } from 'react';
import { cva } from 'class-variance-authority';
import { icons } from 'lucide-react';

import { AlertStatus, ClientRecordStatus } from '@spektr/shared/validators';
import { cn } from '@spektr/style-utils';

type CasesStatusProps = {
  status: ClientRecordStatus | AlertStatus;
  className?: string;
};

export const CasesStatus = ({ status, className }: CasesStatusProps) => {
  const {
    iconName,
    statusText,
  }: {
    iconName: keyof typeof icons;
    statusText: string;
  } = useMemo(() => {
    switch (status) {
      case 'in_progress':
        return {
          iconName: 'BookDashed',
          statusText: 'In Progress',
        };
      case 'pending':
        return {
          iconName: 'BookDashed',
          statusText: 'Pending',
        };
      case 'resolved':
        return {
          iconName: 'Stamp',
          statusText: 'Resolved',
        };
      case 'approved':
        return {
          iconName: 'Check',
          statusText: 'Approved',
        };
      case 'escalated':
        return {
          iconName: 'TriangleAlert',
          statusText: 'Escalated',
        };
      case 'rejected':
        return {
          iconName: 'TriangleAlert',
          statusText: 'Rejected',
        };
      case 'open':
        return {
          iconName: 'ListTodo',
          statusText: 'Open',
        };
      default:
        return {
          iconName: 'ListTodo',
          statusText: 'Open',
        };
    }
  }, [status]);

  const StatusIcon = icons[iconName];

  return (
    <div className={cn(statusVariants({ status }), className)}>
      <StatusIcon className="h-2.5 w-2.5" />
      {statusText}
    </div>
  );
};

const statusVariants = cva(
  'flex items-center gap-2 rounded-md px-2 py-1 text-color-blue bg-color-blue/20',
  {
    variants: {
      status: {
        open: 'text-color-blue bg-color-blue/20',
        in_progress: 'text-color-yellow bg-color-yellow/20',
        pending: 'text-color-yellow bg-color-yellow/20',
        resolved: 'text-color-green bg-color-green/20',
        approved: 'text-color-green bg-color-green/20',
        escalated: 'text-color-red bg-color-red/20',
        rejected: 'text-color-red bg-color-red/20',
      },
    },
  }
);
