import { BotMessageSquare, CheckCheck, X } from 'lucide-react';

import { cn } from '@spektr/style-utils';
import { SpektrAIStatuses } from '@spektr/shared/validators';

type SpektrAIStatusProps = {
  label?: string;
  type?: SpektrAIStatuses;
};

export const SpektrAIStatus = ({ label, type }: SpektrAIStatusProps) => {
  return (
    <div
      className={cn(
        'flex items-center gap-2',
        'px-2 py-1',
        'rounded-md',
        'text-color-purple',
        'bg-color-purple/20'
      )}
    >
      <BotMessageSquare className="h-2.5 w-2.5 shrink-0" />
      <p className="text-xs font-medium">{label}</p>
      {type === 'success' && (
        <CheckCheck className="stroke-color-green h-2.5 w-2.5" />
      )}
      {type === 'failure' && <X className="stroke-color-red h-2.5 w-2.5" />}
    </div>
  );
};
