import { ZodRawShape, ZodType, z } from 'zod';

import { unixTimestampSchema } from './utils';
import { SpektrFieldType, SpektrFieldTypedKey } from './spektrfield';

export const objectIdSchema = z.string().regex(/^[0-9a-f]{24}$/);
export const responseBaseSchema = z.object({
  id: objectIdSchema,
  createdAt: unixTimestampSchema,
  updatedAt: unixTimestampSchema,
});

export const spektrFieldDates = [
  'date_of_birth',
  'representative_dob',
  'ubo_dob',
] as const;

const spektrFieldStringSchema = z.enum([
  'id',
  'first_name',
  'middle_name',
  'last_name',
  'social_security_number',
  'passport_number',
  'national_id_number',
  'TIN',
  'email',
  'occupation',
  'company_website',
  'company_physical_goods',
  'company_dba',
  'pep_corporate',
  'iip_status',
  'nace_code',
  'business_license_type',
  'business_relationship_purpose',
  'business_relationship_duration',
  'prior_account_refusal',
  'delivery_channel',
  'prior_appearence_internal_watchlist',
  'representative_title',
  'representative_first_name',
  'representative_last_name',
  'representative_position',
  'representative_selfie_id',
  'representative_por',
  'ubo_title',
  'ubo_first_name',
  'ubo_last_name',
  'ubo_shareholding',
  'ubo_direct_indirect',
]);
const spektrFieldNumberSchema = z.enum([
  'phone_number',
  'score',
  'virtual_cards_number',
  'fast_payments_amount_eur',
  'amount_sepa_payments_eur',
  'amount_nonsepa_payments_eur',
  'weekly_inbound_payments_number',
  'weekly_outbound_payments_number',
  'weekly_avg_amount_inbound_payments_eur',
  'weekly_avg_amount_outbound_payments_eur',
  'intend_deposit_annual_eur',
  'annual_avg_income_vs_deposit_perc',
  'company_transaction_size',
  'company_annual_gmv',
  'is_sold',
]);
const spektrFieldDateSchema = z.enum(spektrFieldDates);

export const spektrfieldCountries = [
  'place_of_birth',
  'nationality',
  'transacting_countries',
  'incorporation_country',
  'principal_operating_country',
  'representative_nationality',
  'ubo_residence',
  'ubo_nationality',
] as const;

export const spektrFieldCountrySchema = z.enum(spektrfieldCountries);

const spektrFieldFileSchema = z.enum([
  'proof_of_address',
  'passport',
  'driver_license',
  'proof_of_residency',
  'source_of_fund',
  'nature_of_business',
  'proof_of_incorporation',
  'source_of_wealth',
  'articles_of_incorporation',
  'business_certification',
  'regulatory_license',
]);

const spektrFieldInternalSchema = z.enum(['score', 'vendor_search_id']);

export const spektrFieldKeySchema = z.union([
  spektrFieldStringSchema,
  spektrFieldNumberSchema,
  spektrFieldCountrySchema,
  spektrFieldDateSchema,
  spektrFieldInternalSchema,
  spektrFieldFileSchema,
]);

export const spektrFieldTypeSchema = z.enum([
  'string',
  'number',
  'date',
  'country',
  'boolean',
  'file',
  'matrix',
]);

export const spektrFieldValueSchema = z.union([
  z.string(),
  z.number(),
  z.boolean(),
  z.null(),
]);

export const spektrDataSchema = z.record(z.string(), spektrFieldValueSchema);

export const spektrFieldDetailsSchema = z.object({
  key: z.string(),
  label: z.string(),
  type: spektrFieldTypeSchema,
  sourceId: z.string(),
  source: z
    .union([
      z.literal('dataset'),
      z.literal('openCorporatesMonitoring'),
      z.literal('openCorporatesRisk'),
      z.literal('complyAdvantageKyb'),
      z.literal('complyAdvantageKyc'),
      z.literal('bodacc'),
      z.literal('kyckr'),
      z.literal('veriff'),
      z.literal('veriffIdv'),
      z.literal('mitId'),
      z.literal('form'),
      z.literal('returningProcess'),
      z.literal('monitoringDataset'),
      z.literal('custom'),
      z.literal('customerStatus'),
      z.literal('calculation'),
      z.literal('virk'),
    ])
    .optional(),
  //availableIn is currently used in the Kyckr node to mark which fields should be available in which kind of
  //kyckr process (representative child process, first_layer_of_ownership child process, all_potential_layers_of_ownership child process as defined by the KyckrNodeOutcomes enum)
  //should a field be available in. Fields that don't have this property are available only in the parent(topmost process with a kyckr node)
  availableIn: z
    .array(
      z.union([
        z.literal('representatives'),
        z.literal('first_layer_of_ownership'),
        z.literal('all_potential_layers_of_ownership'),
      ])
    )
    .optional(),
  commonSpektrFieldKeys: z.array(z.string()).optional(),
  group: z.string().optional(),
});

const SpektrFieldKey = z.string();
export const SpektrFieldWithMappings = spektrFieldDetailsSchema.extend({
  mappings: z.array(SpektrFieldKey),
});
export type SpektrFieldWithMappings = z.infer<typeof SpektrFieldWithMappings>;

export const SpektrFieldTypedKeyWithMapping = SpektrFieldTypedKey.extend({
  mapping: z.string().nullable(),
});
export type SpektrFieldTypedKeyWithMapping = z.infer<
  typeof SpektrFieldTypedKeyWithMapping
>;

export const existencePredicateSchema = z.object({
  operator: z.enum(['is_empty', 'is_not_empty']),
  type: spektrFieldTypeSchema,
  groupRoot: z.boolean().optional(),
  left: z.string(),
  right: z.undefined().optional(),
});

export const matrixPredicateSchema = z.object({
  operator: z.enum(['is_in', 'is_not_in']),
  type: z.literal('matrix'),
  groupRoot: z.boolean().optional(),
  left: z.string(),
  right: z.array(z.array(z.string())),
});

export const dateComparisonPredicateSchema = z.object({
  operator: z.enum(['is_after', 'is_before']),
  type: spektrFieldTypeSchema,
  groupRoot: z.boolean().optional(),
  left: z.string(),
  right: z.string().or(z.number()),
});

export const rangeRegex = /^\[-?\d+,-?\d+\]$/;
export const rangePredicateSchema = z.object({
  operator: z.enum(['between', 'outside']),
  type: spektrFieldTypeSchema,
  groupRoot: z.boolean().optional(),
  left: z.string(),
  right: z.string().regex(rangeRegex),
});

export const inequalityPredicateSchema = z.object({
  operator: z.enum(['greater_than', 'less_than']),
  type: spektrFieldTypeSchema,
  groupRoot: z.boolean().optional(),
  left: z.string(),
  right: z.preprocess(
    (val) => (val !== '' ? val : undefined),
    z.coerce.number()
  ),
});

export const equalityPredicateSchema = z.object({
  operator: z.enum(['equals', 'not_equals']),
  type: spektrFieldTypeSchema,
  groupRoot: z.boolean().optional(),
  left: z.string(),
  right: z.union([z.string(), z.number(), z.boolean()]),
});

// We need to explicitly define the boolean to help zod resolve the circular dependency between 'predicateSchema' and 'booleanPredicateSchema'.
// Otherwise zod cannot infer the type.
export const booleanPredicateSchema: z.ZodObject<
  ZodRawShape,
  'strip',
  ZodType<{
    operator: 'and' | 'or';
    groupRoot?: boolean;
    type: SpektrFieldType;
    left: z.infer<typeof predicateSchema>;
    right: z.infer<typeof predicateSchema>;
  }>,
  {
    operator: 'and' | 'or';
    groupRoot?: boolean;
    type: SpektrFieldType;
    left: z.infer<typeof predicateSchema>;
    right: z.infer<typeof predicateSchema>;
  }
> = z.object({
  operator: z.enum(['and', 'or']),
  groupRoot: z.boolean().optional(),
  type: spektrFieldTypeSchema,
  left: z.lazy(() => predicateSchema),
  right: z.lazy(() => predicateSchema),
});

export const predicateSchema = z.union([
  booleanPredicateSchema,
  equalityPredicateSchema,
  inequalityPredicateSchema,
  rangePredicateSchema,
  dateComparisonPredicateSchema,
  existencePredicateSchema,
  matrixPredicateSchema,
]);
