import { Input, Label } from '@spektr/client/components';

import { usePercentageInput } from '@spektr/platform-hooks';

import { useIncompleteSegment } from '../providers';

export const SegmentWeightInput = () => {
  const { segment, updateSegment } = useIncompleteSegment();
  const updateWeight = (weight: string) => updateSegment({ weight });
  const { percentageValue, handlePercentageChange } = usePercentageInput(
    segment?.weight ?? '100',
    updateWeight
  );

  return (
    <div className="flex items-center gap-3">
      <Label htmlFor="weight">Weight</Label>
      <Input
        className="w-14 [&>input]:text-right"
        id="weight"
        name="weight"
        type="number"
        max={100}
        min={0}
        dimension="small"
        value={percentageValue}
        onChange={handlePercentageChange}
        endIcon="%"
      />
    </div>
  );
};
