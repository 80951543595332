import { Suspense } from 'react';
import { useMeasure } from 'react-use';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Box,
  CloudUpload,
  ListPlus,
  PlusCircle,
  ServerCrash,
} from 'lucide-react';

import {
  PageIntro,
  PageIntroCard,
  ScrollArea,
  SpektrErrorBoundary,
  iconBoxVariants,
} from '@spektr/client/components';
import { cn } from '@spektr/style-utils';
import { usePermissionsContext } from '@spektr/client/providers';

import { RBAC } from '@spektr/shared/rbac';
import {
  MATRIX_NEW_FULL_URL,
  MATRIX_TEMPLATES_URL,
} from '@spektr/shared/utils';

import { RiskMatrixList } from './containers/RiskMatrixList';
import { RiskMatrixListSkeleton } from './components/RiskMatrixListSkeleton';

export const RiskMatrix = () => {
  const navigate = useNavigate();
  const { hasPermission } = usePermissionsContext();
  const [ref, size] = useMeasure<HTMLDivElement>();

  return (
    <div ref={ref} className="flex h-full flex-col gap-6 overflow-hidden">
      <ScrollArea
        style={{
          height: size.height,
        }}
      >
        <div className="flex flex-col gap-6">
          <PageIntro
            title="Create Risk Matrices"
            description="Add pre-defined matrices to your dataset for easier and faster creation of processes"
            renderIcon={(iconClassName) => (
              <Box
                className={cn(
                  iconBoxVariants({
                    variant: 'cyan',
                  }),
                  iconClassName
                )}
              />
            )}
          >
            <PageIntroCard
              title="Load existing Matrices"
              description="Find one of our many pre-defined Matrices"
              actionName="Load existing Matrix"
              renderIcon={(iconClassName: string) => (
                <ListPlus
                  className={cn(
                    iconBoxVariants({ variant: 'cyan' }),
                    iconClassName
                  )}
                />
              )}
              actionButtonProps={{
                color: 'cyan',
                disabled: !hasPermission(
                  RBAC.ACTIONS.RISK_MATRIX_TEMPLATE.CREATE
                ),
                onClick: () => navigate(MATRIX_TEMPLATES_URL),
                'data-cy': 'riskMatrix-load-button',
              }}
            />
            <PageIntroCard
              title="Upload a CSV"
              description="Create matrix from a CSV file"
              actionName="Upload CSV"
              renderIcon={(iconClassName: string) => (
                <CloudUpload
                  className={cn(
                    iconBoxVariants({ variant: 'cyan' }),
                    iconClassName
                  )}
                />
              )}
              actionButtonProps={{
                color: 'cyan',
                onClick: () =>
                  navigate(MATRIX_NEW_FULL_URL, {
                    state: {
                      fromCSV: true,
                    },
                  }),
                disabled: !hasPermission(RBAC.ACTIONS.RISK_MATRIX.CREATE),
                'data-cy': 'riskMatrix-upload-button',
              }}
            />
            <PageIntroCard
              title="Create Matrix"
              description="Start a new matrix from scratch"
              actionName="Create a new Matrix"
              renderIcon={(iconClassName: string) => (
                <PlusCircle
                  className={cn(
                    iconBoxVariants({ variant: 'cyan' }),
                    iconClassName
                  )}
                />
              )}
              actionButtonProps={{
                color: 'cyan',
                onClick: () => navigate(MATRIX_NEW_FULL_URL),
                disabled: !hasPermission(RBAC.ACTIONS.RISK_MATRIX.CREATE),
                'data-cy': 'riskMatrix-create-button',
              }}
            />
          </PageIntro>
          <div className="flex items-center gap-4">
            <p className="text-color-text-primary text-lg font-medium">
              Your Risk Matrices
            </p>
          </div>

          <SpektrErrorBoundary
            fallbackRender={() => (
              <div className="flex flex-col items-center justify-center gap-2 p-8">
                <ServerCrash className="stroke-color-cyan h-8 w-8" />
                <span className="text-color-text-error-boundry">
                  There was an error while loading the risk matrices.
                </span>
              </div>
            )}
          >
            <Suspense fallback={<RiskMatrixListSkeleton />}>
              <RiskMatrixList />
            </Suspense>
          </SpektrErrorBoundary>

          <Outlet />
        </div>
      </ScrollArea>
    </div>
  );
};
