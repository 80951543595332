import { CircleX } from 'lucide-react';
import { Suspense } from 'react';

import { SpektrErrorBoundary, Spinner } from '@spektr/client/components';

import { handleAxiosError } from '@spektr/client/utils';

import { FallbackDialog } from './FallbackDialog';

export const NodeDetailsPreloader = ({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose: () => void;
}) => {
  return (
    <SpektrErrorBoundary
      fallbackRender={({ error }) => (
        <FallbackDialog onClose={onClose}>
          <CircleX className="text-color-red h-6 w-6" />
          {handleAxiosError(
            error,
            'Something went wrong when loading the node details.'
          )}
        </FallbackDialog>
      )}
    >
      <Suspense fallback={<Spinner />}>{children}</Suspense>
    </SpektrErrorBoundary>
  );
};
