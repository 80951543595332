import { HTMLAttributes } from 'react';

import { cn } from '@spektr/style-utils';

export const SpektrHomeIcon = ({
  className,
  ...props
}: HTMLAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={cn('stroke-color-stroke-icon', className)}
      {...props}
    >
      <path
        d="M11.9004 2.5336C11.5784 2.28312 11.4174 2.15788 11.2396 2.10974C11.0827 2.06726 10.9173 2.06726 10.7604 2.10974C10.5826 2.15788 10.4216 2.28312 10.0996 2.5336L3.88244 7.36914C3.46685 7.69237 3.25906 7.85399 3.10936 8.05639C2.97675 8.23568 2.87797 8.43766 2.81786 8.6524C2.75 8.89483 2.75 9.15808 2.75 9.68457V16.3166C2.75 17.3434 2.75 17.8567 2.94982 18.2489C3.12559 18.5939 3.40605 18.8743 3.75102 19.0501C4.14319 19.2499 4.65657 19.2499 5.68333 19.2499H7.51667C7.77336 19.2499 7.9017 19.2499 7.99975 19.2C8.08599 19.156 8.1561 19.0859 8.20005 18.9997C8.25 18.9016 8.25 18.7733 8.25 18.5166V12.4666C8.25 11.9532 8.25 11.6965 8.34991 11.5004C8.4378 11.328 8.57803 11.1877 8.75051 11.0998C8.9466 10.9999 9.20329 10.9999 9.71667 10.9999H12.2833C12.7967 10.9999 13.0534 10.9999 13.2495 11.0998C13.422 11.1877 13.5622 11.328 13.6501 11.5004C13.75 11.6965 13.75 11.9532 13.75 12.4666V18.5166C13.75 18.7733 13.75 18.9016 13.8 18.9997C13.8439 19.0859 13.914 19.156 14.0003 19.2C14.0983 19.2499 14.2266 19.2499 14.4833 19.2499H16.3167C17.3434 19.2499 17.8568 19.2499 18.249 19.0501C18.594 18.8743 18.8744 18.5939 19.0502 18.2489C19.25 17.8567 19.25 17.3434 19.25 16.3166V9.68457C19.25 9.15808 19.25 8.89483 19.1821 8.6524C19.122 8.43766 19.0233 8.23568 18.8906 8.05639C18.7409 7.85399 18.5332 7.69237 18.1176 7.36914L11.9004 2.5336Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
