import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

import * as Accordion from '@radix-ui/react-accordion';

import { cn } from '@spektr/style-utils';

export type AccordionContentProps = ComponentPropsWithoutRef<
  typeof Accordion.Content
>;

export const AccordionContent = forwardRef<
  ElementRef<typeof Accordion.Content>,
  AccordionContentProps
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className={cn(
      'h-full w-full',
      'data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp',
      'overflow-hidden'
    )}
    {...props}
    ref={forwardedRef}
  >
    <div className={className}>{children}</div>
  </Accordion.Content>
));
