import { ReactNode, Suspense } from 'react';

import { SpektrErrorBoundary } from '@spektr/client/components';
import { ErrorBoundaryPropsWithRender } from 'react-error-boundary';

export namespace FallbackContent {
  export type Props = {
    children: ReactNode;
    renderError: ErrorBoundaryPropsWithRender['fallbackRender'];
    fallback: ReactNode;
  };
}

export const FallbackContent = ({
  children,
  fallback,
  renderError,
}: FallbackContent.Props) => (
  <SpektrErrorBoundary fallbackRender={renderError}>
    <Suspense fallback={fallback}>{children}</Suspense>
  </SpektrErrorBoundary>
);
