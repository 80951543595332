import { cn } from '@spektr/style-utils';

import type { CheckboxField } from '@spektr/moonraker-types';

export type CheckboxContentProps = {
  field: CheckboxField;
  isSingleSelect?: boolean;
};

const baseClassName = cn('h-3.5 w-3.5', 'border');

export const CheckboxContent = ({
  field,
  isSingleSelect,
}: CheckboxContentProps) => {
  return (
    <div className="flex w-full flex-col gap-4">
      <span
        className={cn(
          'h-0',
          'text-color-text-subtext',
          'text-xs font-medium',
          'transition-all',
          { 'h-4': !!field.attributes?.helperText }
        )}
      >
        {field.attributes?.helperText}
      </span>
      <div className={cn('flex flex-row flex-wrap gap-4')}>
        {field.attributes.options.map((checkbox) => (
          <div key={checkbox} className={cn('flex items-center gap-2')}>
            {isSingleSelect ? (
              <div className={cn(baseClassName, 'rounded-full')} />
            ) : (
              <div className={cn(baseClassName, 'rounded-sm')} />
            )}
            <span className={cn('text-color-text-subtext text-xs font-medium')}>
              {checkbox}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
