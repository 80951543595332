import { Link } from 'react-router-dom';

import { loopDetailsUrl, processBuilderUrl } from '@spektr/shared/utils';
import { UserSchema } from '@spektr/shared/validators';
import { Process } from '@spektr/shared/types';

import { TextTruncate } from '@spektr/client/components';

import { CustomerTimelineCell } from '../CustomerTimelineCell';

import { UserInfo } from './UserInfo';

type ChangedDataCellProps = {
  user?: UserSchema;
  process?: Process;
  className?: string;
};

export const ChangedDataCell = ({
  user,
  process,
  className,
}: ChangedDataCellProps) => {
  const isTriggeredByProcess = !!process;

  return (
    <CustomerTimelineCell
      className={className}
      iconName={isTriggeredByProcess ? 'LayoutGrid' : undefined}
      iconColor={isTriggeredByProcess ? 'cyan' : undefined}
      label={`${isTriggeredByProcess ? 'Triggered' : 'Changed'} by`}
      value={
        isTriggeredByProcess ? (
          <Link
            to={
              process.type === 'loop'
                ? loopDetailsUrl(process.id)
                : processBuilderUrl(process.id)
            }
          >
            <TextTruncate text={process.name} className="line-clamp-1" />
          </Link>
        ) : (
          <UserInfo user={user} />
        )
      }
    />
  );
};
