import { ChangeEventHandler, useRef } from 'react';
import { Trash } from 'lucide-react';

import { Button, IconButton, Input, Label } from '@spektr/client/components';

export namespace SetUpDomain {
  export type Props = {
    domain?: string;
    error?: string;
    isPending?: boolean;
    onChangeDomain?: ChangeEventHandler<HTMLInputElement>;
    onSetDomain: (value: string) => void;
    onRemoveDomain: () => void;
  };
}

export const SetUpDomain = ({
  domain,
  error,
  isPending,
  onChangeDomain,
  onSetDomain,
  onRemoveDomain,
}: SetUpDomain.Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleSetDomain = () => {
    const value = inputRef.current?.value;

    if (value) {
      onSetDomain(value ?? '');
    }
  };

  const isSetUpDisabled = isPending || domain !== undefined || !!error;

  const isRemoveDisabled = !domain;

  return (
    <div className="flex gap-3">
      <div className="flex grow flex-col gap-2">
        <Label htmlFor="input-domain">Custom domain name</Label>
        <Input
          ref={inputRef}
          error={!!error}
          disabled={!!domain || isPending}
          id="input-domain"
          value={domain}
          placeholder="Like onboarding.acme.com..."
          onChange={onChangeDomain}
        />
        {error && <span className="text-color-red text-xs">{error}</span>}
      </div>
      <Button
        aria-label="Set up domain"
        color="cyan"
        className="mt-6"
        disabled={isSetUpDisabled}
        onClick={handleSetDomain}
      >
        Set up domain
      </Button>
      <IconButton
        aria-label="Remove domain"
        color="red"
        className="mt-6"
        disabled={isRemoveDisabled}
        variant="text"
        onClick={onRemoveDomain}
      >
        <Trash className="h-3 w-3" />
      </IconButton>
    </div>
  );
};
