import { useMemo } from 'react';
import {
  ArrowUpDown,
  FileText,
  SearchCode,
  Sparkles,
  SquareCheck,
  SquareX,
} from 'lucide-react';

import { NodeType, ProcessType } from '@spektr/shared/validators';

import {
  CalculatorIcon,
  IconWithBackground,
  RouterIcon,
} from '@spektr/client/components';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { DrawerItemType } from '../types/DrawerItemType';
import { getAvailableItemsForProcess } from '../utils/getAvailableItemsForProcess';

export const useSteps = (processType?: ProcessType): DrawerItemType[] => {
  const { monitoringDataset } = useFeatureFlags();

  return useMemo(() => {
    const steps: DrawerItemType[] = [
      {
        type: NodeType.enum.form,
        title: 'Form step',
        variant: 'cyan',
        icon: (
          <IconWithBackground className="bg-color-cyan/10">
            <FileText className="stroke-color-cyan h-6 w-6" />
          </IconWithBackground>
        ),
        description:
          'Create a shareable form and get the details you need to be compliant',
      },
      {
        type: NodeType.enum.calculation,
        icon: (
          <IconWithBackground className="bg-color-cyan/10">
            <CalculatorIcon className="stroke-color-cyan stroke-1.8 h-6 w-6" />
          </IconWithBackground>
        ),
        title: 'Calculation step',
        variant: 'cyan' as const,
        description:
          'Create conditional rules and segment information into groups for later routing.',
      },
      {
        type: NodeType.enum.router,
        icon: <RouterIcon iconClassName="h-6 w-6" />,
        title: 'Routing step',
        variant: 'red' as const,
        description:
          'Create rules that take previous steps and routes to outcomes or branches.',
      },
      {
        type: NodeType.enum.returningProcess,
        icon: (
          <IconWithBackground className="bg-color-cyan/10">
            <ArrowUpDown className="stroke-color-cyan h-6 w-6" />
          </IconWithBackground>
        ),
        title: 'Returning process step',
        variant: 'cyan' as const,
        description:
          'Calls another process to obtain its outcome and integrates the result into the current process.',
      },
      {
        type: NodeType.enum.monitoringDataset,
        icon: (
          <IconWithBackground className="bg-color-cyan/10">
            <SearchCode className="stroke-color-cyan h-6 w-6" />
          </IconWithBackground>
        ),
        title: 'Field watch',
        variant: 'cyan' as const,
        description:
          'This step monitors a specific field in the dataset and triggers the process if any changes occur.',
        hidden: !monitoringDataset,
      },
      {
        type: NodeType.enum.aiAmlAlert,
        icon: (
          <IconWithBackground className="bg-color-purple/10">
            <Sparkles className="stroke-color-purple h-6 w-6" />
          </IconWithBackground>
        ),
        title: 'spektrAI step',
        variant: 'purple' as const,
        description:
          'Use spektrAI on adverse media alerts to get suggestions or automate resolution.',
      },
      {
        type: NodeType.enum.customerStatus,
        icon: (
          <IconWithBackground className="bg-color-green/10">
            <SquareCheck className="stroke-color-green h-6 w-6" />
          </IconWithBackground>
        ),
        title: 'Approve customer',
        variant: 'cyan' as const,
        description: 'Automatically set the status of a customer to approved.',
        metadata: {
          'customerStatus.approved': true,
          customKey: 'customerStatusApproved',
        },
      },
      {
        type: NodeType.enum.customerStatus,
        icon: (
          <IconWithBackground className="bg-color-red/10">
            <SquareX className="stroke-color-red h-6 w-6" />
          </IconWithBackground>
        ),
        title: 'Reject customer',
        variant: 'red' as const,
        description: 'Automatically set the status of a customer to rejected.',
        metadata: {
          'customerStatus.rejected': true,
          customKey: 'customerStatusRejected',
        },
      },
    ];

    return getAvailableItemsForProcess(steps, processType);
  }, [monitoringDataset, processType]);
};
