import { ReactNode } from 'react';
import { isEmpty } from 'lodash';

import { cn } from '@spektr/style-utils';

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@spektr/client/components';

export type BasicDetailsTableProps<T extends object, K extends keyof T> = {
  data: T;
  columnClassName?: string;
  valueClassName?: string;
  renderColumn?: (key: string) => string | ReactNode;
  renderValue: (columnKey: string, value: T[K]) => string | ReactNode;
};

export const BasicDetailsTable = <T extends object, K extends keyof T>({
  data,
  columnClassName,
  valueClassName,
  renderColumn,
  renderValue,
}: BasicDetailsTableProps<T, K>) => {
  if (isEmpty(data)) {
    return null;
  }

  return (
    <Table className="rounded-md border">
      <TableBody>
        {Object.entries(data).map(([key, value], index) => (
          <TableRow key={key} className={cn('hover:bg-inherit')}>
            <TableCell
              className={cn(
                'border-r p-4',
                'text-sm font-medium',
                'text-color-text-subtext',
                {
                  'border-b': index !== Object.keys(data).length - 1,
                },
                columnClassName
              )}
            >
              {renderColumn ? renderColumn(key) : key}
            </TableCell>
            <TableCell
              className={cn(
                'px-4',
                'text-sm font-medium',
                {
                  'border-b': index !== Object.keys(data).length - 1,
                },
                valueClassName
              )}
            >
              {renderValue(key, value)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
