import { kyckrFields } from '@spektr/shared/types';

export function isKyckrFieldSelected<T extends string>(
  fieldKey: string,
  fields: T[]
) {
  return fields.some((field) => {
    const kyckrField = kyckrFields.find(
      (spektrField) => spektrField.key === field
    );

    return !!kyckrField?.availableIn?.includes(fieldKey);
  });
}
