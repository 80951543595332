import { Info } from 'lucide-react';

import {
  Label,
  RadioCheckbox,
  RadioGroup,
  Tooltip,
} from '@spektr/client/components';

import { type ServiceFieldClient } from '@spektr/client/types';

export namespace MitIdLoginFields {
  export type Props = {
    selectedKey?: string;
    fields: ServiceFieldClient[];
    onValueChange: (key: string) => void;
  };
}
export const MitIdLoginFields = ({
  selectedKey,
  fields,
  onValueChange,
}: MitIdLoginFields.Props) => {
  return (
    <div className="flex flex-col gap-2">
      <Label>Login Type</Label>

      <RadioGroup
        value={selectedKey}
        onValueChange={onValueChange}
        className="flex flex-col gap-2 pl-6"
      >
        {fields.map((field) => (
          <RadioCheckbox
            key={field.key}
            id={field.key}
            value={field.key}
            label={
              <span className="flex items-center gap-2 whitespace-normal">
                {field.label}
                {field.help && (
                  <Tooltip content={field.help}>
                    <Info className="text-color-text-icon-secondary h-3 w-3" />
                  </Tooltip>
                )}
              </span>
            }
          />
        ))}
      </RadioGroup>
    </div>
  );
};
