import { z } from 'zod';

import { makeApi } from '@zodios/core';

import { DomainAliasCreateSchema, DomainAliasDto } from './domainAlias';

export const domainAliasApi = makeApi([
  {
    method: 'get',
    path: '/domain-alias/',
    alias: 'getDomainAlias',
    description: 'Get domain alias',
    response: DomainAliasDto,
  },
  {
    method: 'post',
    path: '/domain-alias/',
    alias: 'createDomainAlias',
    description: 'Creates the domain alias record',
    response: DomainAliasDto,
    parameters: [
      {
        name: 'input',
        type: 'Body',
        schema: DomainAliasCreateSchema,
      },
    ],
  },
  {
    method: 'post',
    path: '/domain-alias/verify',
    alias: 'verifyDomainAlias',
    description: 'Verifies the domain alias records',
    response: DomainAliasDto,
  },
  {
    method: 'delete',
    path: '/domain-alias/',
    alias: 'deleteDomainAlias',
    description: 'Delete domain alias',
    status: 204,
    response: z.void(),
  },
]);
