import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import {
  ProcessApiClient,
  getLiveVersionByProcessIdQueryKey,
  getProcessesQueryKey,
  getVersionOverviewByProcessId,
  getVersionOverviewByProcessIdQueryKey,
} from '@spektr/client/services';

import { useFeatureFlags } from '@spektr/platform-hooks';

import { PublishVersionItem } from '../../components/PublishVersionItem';

import { useProcessScheduleContext } from '../../providers';

import { useGetPublishDisabledReason } from './useGetPublishDisabledReason';

export type PublishVersionProps = {
  processId: string;
  title: string;
  description: string;
};

export const PublishVersion = ({
  processId,
  title,
  description,
}: PublishVersionProps) => {
  const queryClient = useQueryClient();
  const { isMonitoringProcess, process, startDate, updateProcessTrigger } =
    useProcessScheduleContext();
  const { processTriggers } = useFeatureFlags();

  const { data: overview } = useSuspenseQuery(
    getVersionOverviewByProcessId(processId)
  );

  const disabledReason = useGetPublishDisabledReason(
    overview.hasUnpublishedChanges
  );

  const makeLiveVersionMutation = useMutation({
    mutationFn: ({ versionId }: { versionId: string }) =>
      ProcessApiClient.getClient().launchVersion(undefined, {
        params: { processId, versionId },
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: getLiveVersionByProcessIdQueryKey(processId),
      }),
  });
  const createProcessVersionMutation = useMutation({
    mutationFn: () =>
      ProcessApiClient.getClient().createProcessVersion(undefined, {
        params: {
          processId: processId,
        },
      }),
    onSuccess: (version) => {
      queryClient.invalidateQueries({
        queryKey: getVersionOverviewByProcessIdQueryKey(processId),
      });
      return makeLiveVersionMutation.mutate({ versionId: version.id });
    },
  });

  const updateProcessStartDate = useMutation({
    mutationFn: (startDate?: number) =>
      ProcessApiClient.getClient().updateProcessById(
        {
          startDate,
        },
        {
          params: {
            id: processId,
          },
        }
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: getProcessesQueryKey() });
    },
  });

  const onPublishClick = async () => {
    if (isMonitoringProcess && processTriggers) {
      if (process?.startDate !== startDate) {
        await updateProcessStartDate.mutateAsync(startDate);
      }
      await createProcessVersionMutation.mutateAsync();
      updateProcessTrigger();
    } else {
      await createProcessVersionMutation.mutateAsync();
    }
  };

  return (
    <PublishVersionItem
      title={title}
      description={description}
      hasUnreleasedChanges={overview?.hasUnpublishedChanges}
      isDisabled={
        createProcessVersionMutation.isPending ||
        makeLiveVersionMutation.isPending
      }
      disabledReason={disabledReason}
      onClick={onPublishClick}
    />
  );
};
