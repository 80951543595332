import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const useRenderDetailsTable = () => {
  const { t } = useTranslation('fields');

  const renderColumn = (key: string) => {
    return t(key);
  };

  const renderValue = (key: string, value: unknown) => {
    if (Array.isArray(value)) {
      return value.join(', ');
    }

    if (typeof value === 'string') {
      if (key.includes('url')) {
        return (
          <Link
            to={value}
            target="_blank"
            className="break-all transition-opacity hover:opacity-80"
          >
            {value}
          </Link>
        );
      }

      return <span>{value}</span>;
    }

    return JSON.stringify(value);
  };

  return useMemo(() => ({ renderColumn, renderValue }), []);
};
