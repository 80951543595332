import { z } from 'zod';
import {
  calculationNodeSchema,
  createOutcomeNodeSchema,
  edgeSchema,
  filterNodeSchema,
  filterNodeSourceDataInputSchema,
  filterNodeSourceInputSchema,
  filterNodeSourceProcessInputSchema,
  filterNodeSourceTypeSchema,
  KyckrNode,
  MonitoringDatasetNode,
  monitoringDatasetNodeSchema,
  OpenCorporatesMonitoringNode,
  OpenCorporatesRiskNode,
  outcomeNodeSchema,
  routerEdgeSchema,
  routerNodeSchema,
  updateRouterNodeSchema,
  processNodeSchema,
  createNodeSchema,
  updateNodeSchema,
  loopSourceExtendedSchema,
  onboardingProcessSourceExtendedSchema,
  processLinkSourceSchema,
  loopSourceNodeSchema,
  onboardingProcessSourceNodeSchema,
  NodeServiceType,
  BodaccNode,
  ComplyAdvantageKybNode,
  ComplyAdvantageKycNode,
  VeriffNode,
  mitIdNodeSchema,
  processSourceSchema,
  FormNode,
  CustomerStatusNode,
  AiAmlAlertNode,
  VeriffIdvNode,
  VirkNode,
} from '@spektr/shared/validators';

import {
  AlertNode,
  ManualReviewNode,
  ReturningProcessNode,
  SlackNode,
} from './domain';

export type EdgeType = z.infer<typeof edgeSchema>;
export type RouterEdgeType = z.infer<typeof routerEdgeSchema>;

export const kyckrOutcomes = [
  'representatives',
  'first_layer_of_ownership',
  'all_potential_layers_of_ownership',
] as const;

export type KyckrOutcome = (typeof kyckrOutcomes)[number];

export enum KyckrNodeOutcomes {
  Representatives = 'representatives',
  FirstLayerOfOwnership = 'first_layer_of_ownership',
  AllPotentialLayersOfOwnership = 'all_potential_layers_of_ownership',
}

export interface FormNodeOutcomes {
  name: string;
  label: string;
}

export type LoopSourceBase = z.infer<typeof processLinkSourceSchema>;
export type ProcessSource = z.infer<typeof processSourceSchema>;
export type ProcessNode = z.infer<typeof processNodeSchema>;

export type FilterNodeSourceTypeSchema = z.infer<
  typeof filterNodeSourceTypeSchema
>;

export type MonitoringDatasetNodeSchema = z.infer<
  typeof monitoringDatasetNodeSchema
>;
export type FilterNodeSourceInputSchema = z.infer<
  typeof filterNodeSourceInputSchema
>;
export type FilterNodeSourceDataInputSchema = z.infer<
  typeof filterNodeSourceDataInputSchema
>;
export type FilterNodeProcessSourceInputSchema = z.infer<
  typeof filterNodeSourceProcessInputSchema
>;

export type FilterNodeSchema = z.infer<typeof filterNodeSchema>;
export type RouterNodeSchema = z.infer<typeof routerNodeSchema>;
export type CalculationNodeSchema = z.infer<typeof calculationNodeSchema>;
export type OutcomeNodeSchema = z.infer<typeof outcomeNodeSchema>;
export type LoopSourceNodeSchema = z.infer<typeof loopSourceNodeSchema>;
export type OnboardingProcessSourceNodeSchema = z.infer<
  typeof onboardingProcessSourceNodeSchema
>;
export type MitIdNodeSchema = z.infer<typeof mitIdNodeSchema>;

export type ServiceNode =
  | OpenCorporatesMonitoringNode
  | OpenCorporatesRiskNode
  | ComplyAdvantageKybNode
  | ComplyAdvantageKycNode
  | KyckrNode
  | BodaccNode
  | VeriffNode
  | VeriffIdvNode
  | MitIdNodeSchema
  | VirkNode;

export type LoopSourceExtended = z.infer<typeof loopSourceExtendedSchema>;
export type OnboardingProcessSourceExtended = z.infer<
  typeof onboardingProcessSourceExtendedSchema
>;

export type NodeCreateInput = z.infer<typeof createNodeSchema>;
export type NodeUpdateInput = z.infer<typeof updateNodeSchema>;

export type NodeUpdateRouterInput = z.infer<typeof updateRouterNodeSchema>;
export type OutcomeNodeCreateInput = z.infer<typeof createOutcomeNodeSchema>;

export function isRouterNodeType(node: ProcessNode): node is RouterNodeSchema {
  return node.nodeType === 'router';
}

export function isKyckrNodeType(node: ProcessNode): node is KyckrNode {
  return node.nodeType === 'kyckr';
}

export function isReturningProcessNodeType(
  node: ProcessNode
): node is ReturningProcessNode {
  return node.nodeType === 'returningProcess';
}

export function isMonitoringDatasetNodeType(
  node: ProcessNode
): node is MonitoringDatasetNode {
  return node.nodeType === 'monitoringDataset';
}

export function isSlackNodeType(node: ProcessNode): node is SlackNode {
  return node.nodeType === 'slack';
}

export function isAlertNodeType(node: ProcessNode): node is AlertNode {
  return node.nodeType === 'alert';
}

export function isManualReviewNodeType(
  node: ProcessNode
): node is ManualReviewNode {
  return node.nodeType === 'manualReview';
}

export function isFilterNodeType(node: ProcessNode): node is FilterNodeSchema {
  return node.nodeType === 'filter';
}

export function isAiAmlAlert(node: ProcessNode): node is AiAmlAlertNode {
  return node.nodeType === 'aiAmlAlert';
}

export function isLoopSourceNodeType(
  node: ProcessNode
): node is LoopSourceNodeSchema {
  return node.nodeType === 'loopSource';
}

export function isOnboardingProcessSourceNodeType(
  node: ProcessNode
): node is OnboardingProcessSourceNodeSchema {
  return node.nodeType === 'onboardingProcessSource';
}

export function isCalculationNodeType(
  node: ProcessNode
): node is CalculationNodeSchema {
  return node.nodeType === 'calculation';
}

export function isOutcomeNodeType(
  node: ProcessNode
): node is OutcomeNodeSchema {
  return node.nodeType === 'outcome';
}

export function isFormNodeType(node: ProcessNode): node is FormNode {
  return node.nodeType === 'form';
}

export function isServiceNodeType(node: ProcessNode): node is ServiceNode {
  return NodeServiceType.safeParse(node.nodeType).success;
}

export function isCustomerStatusNodeType(
  node: ProcessNode
): node is CustomerStatusNode {
  return node.nodeType === 'customerStatus';
}

export function isRouterEdgeType(edge: EdgeType): edge is RouterEdgeType {
  return Object.keys(edge).includes('routeId');
}

export function nodeHasFields(
  node: ProcessNode
): node is Extract<ProcessNode, { fields: Record<string, boolean> }> {
  return 'fields' in node;
}

export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

export function assertsIsOutcomeNodeType(
  node: ProcessNode
): asserts node is OutcomeNodeSchema {
  if (!isOutcomeNodeType(node)) {
    throw new Error(`Node with id ${node.id} is not an outcome node`);
  }
}

export function assertsIsRouterNodeType(
  node: ProcessNode
): asserts node is RouterNodeSchema {
  if (!isRouterNodeType(node)) {
    throw new Error(`Node with id ${node.id} is not a router node`);
  }
}

export function assertsIsFilterNodeType(
  node: ProcessNode
): asserts node is FilterNodeSchema {
  if (!isFilterNodeType(node)) {
    throw new Error(`Node with id ${node.id} is not a filter node`);
  }
}

export function assertsIsCalculationNodeType(
  node: ProcessNode
): asserts node is CalculationNodeSchema {
  if (!isCalculationNodeType(node)) {
    throw new Error(`Node with id ${node.id} is not a calculation node`);
  }
}
