import { useController, useFormContext } from 'react-hook-form';

import { SpektrfieldCombobox } from '@spektr/client/components';

import { SpektrField } from '@spektr/shared/types';
import { SpektrFieldTypedKey } from '@spektr/shared/validators';

import { SlackFieldRow } from '../SlackFieldRow';

export namespace SlackComboboxField {
  export type Props = {
    fields: Array<SpektrField | SpektrFieldTypedKey>;
    name: string;
    isRequired?: boolean;
    title: string;
    placeholder: string;
  };
}

export const SlackComboboxField = ({
  fields,
  name,
  isRequired = false,
  placeholder = 'Select field',
  title,
}: SlackComboboxField.Props) => {
  const context = useFormContext();
  const { field } = useController({
    name,
    control: context.control,
    rules: { required: isRequired },
  });

  return (
    <SlackFieldRow isRequired={true} title="Entity name">
      <SpektrfieldCombobox
        fields={fields}
        defaultValue={field.value}
        inputProps={{ required: isRequired, title, placeholder }}
        onChange={(value) => context.setValue(name, value)}
      />
    </SlackFieldRow>
  );
};
