import { FormNavigationItem, FormRoutes } from '../types';

export const HEADER_HEIGHT = 48;

export const DEFAULT_ROUTE: FormRoutes = 'fields';

export const NAVIGATION_ITEMS: FormNavigationItem[] = [
  {
    icon: 'FilePlus',
    title: 'Fields',
    description:
      'Select predefined fields such as name, identification number, nationality, and more.',
    route: 'fields',
  },
  {
    icon: 'FileCog',
    title: 'Custom fields',
    description: 'Select and build new fields.',
    route: 'primitives',
  },
  {
    icon: 'PencilRuler',
    title: 'Visuals elements',
    description:
      'Select visual elements like titles, dividers, and paragraphs.',
    route: 'visuals',
  },
  {
    icon: 'Palette',
    title: 'Layout',
    description: 'Customize the layout of your form.',
    route: 'design',
  },
];
