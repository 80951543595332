import { useState } from 'react';
import { NodeProps, Position, useEdges } from 'reactflow';
import { cva } from 'class-variance-authority';

import { cn } from '@spektr/style-utils';

import { Popover } from '@spektr/client/components';

import { NodePopover } from '../../containers/NodePopover';

import { CaseIcon } from '../CaseIcon';
import { NameWithTooltip } from '../NameWithTooltip';

import { StatefulHandle } from './components/StatefulHandle';

export const BasicNode = ({ id, data }: NodeProps) => {
  const edges = useEdges();
  const [open, setOpen] = useState(false);

  const hasTargetEdge = edges.some((edge) => edge.target === id);
  const hasSourceEdge = edges.some((edge) => edge.source === id);

  return (
    <Popover
      align="center"
      alignOffset={0}
      className="bg-color-bg-white dark:bg-color-bg-primary w-full min-w-80 max-w-96"
      side="right"
      sideOffset={10}
      triggerProps={{
        asChild: true,
      }}
      open={open}
      trigger={
        <div className={cn(variants({ status: data.status }))}>
          <StatefulHandle
            className={!hasTargetEdge ? 'opacity-0' : undefined}
            id={id}
            position={Position.Top}
            status={data.status}
            type="target"
          />
          <CaseIcon status={data.status} type={data.type} />
          <div className="flex flex-col">
            <NameWithTooltip name={data.name} />
            <span className="text-color-text-badge text-xs">
              {data?.subtext}
            </span>
          </div>
          <StatefulHandle
            className={!hasSourceEdge ? 'opacity-0' : undefined}
            id={id}
            position={Position.Bottom}
            status={data.status}
            type="source"
          />
        </div>
      }
      onOpenChange={setOpen}
    >
      <NodePopover data={data} onClose={() => setOpen(false)} />
    </Popover>
  );
};

const variants = cva(
  [
    'relative',
    'flex flex-row items-center gap-3',
    'h-16 w-64 p-3',
    'rounded-lg border',
    'cursor-pointer duration-200',
    'hover:shadow-lg',
  ],
  {
    variants: {
      status: {
        pending: [
          'border-color-border-process-builder-item',
          'bg-color-bg-process-builder-item',
          'hover:border-color-blue',
        ],
        approved: ['border-color-border-success', 'bg-color-secondary-green/5'],
        rejected: ['border-color-border-error', 'bg-color-secondary-red/5'],
      },
    },
    defaultVariants: {
      status: 'pending',
    },
  }
);
