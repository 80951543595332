import { Suspense } from 'react';

import { Connections } from './containers/Connections';
import { ExtractDatasetsFromSource } from './containers/ExtractDatasetsFromSource';

import { SkeletonDatasetList } from './components/SkeletonDatasetList';

export const ConnectionHubDataSources = () => {
  return (
    <div className="mt-3 flex flex-col gap-6 overflow-y-auto">
      <Connections />

      <div className="flex flex-col gap-4">
        <p className="text-lg font-medium leading-none">Your datasets</p>
        <Suspense fallback={<SkeletonDatasetList />}>
          <ExtractDatasetsFromSource />
        </Suspense>
      </div>
    </div>
  );
};
