import { X } from 'lucide-react';

import { IconButton } from '@spektr/client/components';

type RemoveCasesFilterButtonProps = {
  onClick: () => void;
};

export const RemoveCasesFilterButton = ({
  onClick,
}: RemoveCasesFilterButtonProps) => {
  return (
    <IconButton
      variant="contained"
      color="icon"
      className="text-color-text-subtext hover:text-color-red rounded-s-none p-2"
      onClick={onClick}
    >
      <X className="h-4 w-4" />
    </IconButton>
  );
};
