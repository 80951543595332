import { Predicate, StrictOmit } from '@spektr/shared/types';
import {
  CalculationNodeInputSchema,
  MonitoringDatasetNodeInputSchema,
  RiskMatrix,
  RouterNodeInput,
  RuleGroup,
  SegmentInputSchema,
} from '@spektr/shared/validators';

export type IncompleteCalculation = {
  nodeType: 'calculation';
  title: string;
  segments: IncompleteSegment[];
};

export type IncompleteReturningProcess = {
  nodeType: 'returningProcess';
  title: string;
  processId?: string;
};

export type IncompleteMonitoringDataset = {
  nodeType: 'monitoringDataset';
  title: string;
};

export type IncompleteRouter = {
  nodeType: 'router';
  title: string;
  groups: IncompleteRuleGroup[];
};

export type IncompleteSegment = {
  id: string | undefined;
  clientSideOnlyId: string;
  title: string;
  weight: string;
  groups: IncompleteRuleGroup[];
};

export type RiskMatrixSegment = IncompleteSegment & {
  riskMatrixId: string;
};

export type IncompleteRuleGroup = {
  id: string | undefined;
  clientSideOnlyId: string;
  title: string;
  rule: IncompletePredicate;
  score: string | undefined;
};

export type IncompletePredicate = {
  id: string;
  type?: string;
  isIntermediate: boolean;
  groupRoot?: boolean;
  operator?: string;
  left?: string | IncompletePredicate;
  right?: number | string | boolean | string[][] | IncompletePredicate;
};

export type ScoredRows = Record<string, string[][]> | null;

export const parseCalculation = (
  input: CalculationNodeInputSchema
): IncompleteCalculation => {
  return {
    nodeType: input.nodeType,
    title: input.title,
    segments: input.segments.map(parseSegment),
  };
};

export const parseMonitoringDataset = (
  input: MonitoringDatasetNodeInputSchema
): IncompleteMonitoringDataset => {
  return {
    nodeType: input.nodeType,
    title: input.title,
  };
};

export const parseRouter = (input: RouterNodeInput): IncompleteRouter => ({
  nodeType: input.nodeType,
  title: input.title,
  groups: input.groups.map(parseRuleGroup),
});

export const parseSegment = (input: SegmentInputSchema): IncompleteSegment => ({
  id: input.id,
  clientSideOnlyId: input.id ?? makeSegmentId(),
  groups: input.groups.map(parseRuleGroup),
  title: input.title,
  weight: input.weight.toString(),
});

export const parseRuleGroup = (input: RuleGroup): IncompleteRuleGroup => ({
  clientSideOnlyId: input.id ?? makeRuleGroupId(),
  id: input.id,
  title: input.title,
  rule: parsePredicate(input.rule),
  score: input.score?.toString(),
});

const parsePredicate = ({
  id,
  operator,
  groupRoot,
  left,
  type,
  right,
}: Predicate & { id?: string }): IncompletePredicate => {
  const isIntermediate = operator === 'and' || operator === 'or';

  const transformedLeft = isPredicate(left) ? parsePredicate(left) : left;
  const transformedRight = isPredicate(right) ? parsePredicate(right) : right;

  return {
    id: id ?? makePredicateId(),
    left: transformedLeft,
    right: transformedRight,
    type,
    isIntermediate,
    operator,
    groupRoot,
  };
};

function isPredicate(input: unknown): input is Predicate {
  return typeof input === 'object' && input !== null && !Array.isArray(input);
}

export const getPredicateById = (
  root: IncompletePredicate,
  id: string
): IncompletePredicate | undefined => {
  if (root.id === id) return root;

  if (isIncompletePredicate(root.left)) {
    const left = getPredicateById(root.left, id);
    if (left) return left;
  }

  if (isIncompletePredicate(root.right)) {
    const right = getPredicateById(root.right, id);
    if (right) return right;
  }

  return undefined;
};

export const deleteInPredicateById = (
  root: IncompletePredicate,
  id: string
): IncompletePredicate | undefined => {
  if (root.id === id) return undefined;
  if (!isIncompletePredicate(root.right) || !isIncompletePredicate(root.left)) {
    return root;
  }

  if (root.left.id === id) {
    if (root.groupRoot) root.right.groupRoot = true;
    return root.right; // equivalent to deleting root.left it's parent (root)
  }

  const updatedRight = deleteInPredicateById(root.right, id);
  if (updatedRight === undefined) {
    if (root.groupRoot) root.left.groupRoot = true;
    return root.left;
  } else {
    root.right = updatedRight;
  }

  const updatedLeft = deleteInPredicateById(root.left, id);
  if (updatedLeft === undefined) {
    if (root.groupRoot) root.right.groupRoot = true;
    return root.right;
  } else {
    root.left = updatedLeft;
  }

  return root;
};

export const updateBooleanOperatorsBetweenGroups = (
  predicate: IncompletePredicate,
  operator: string
): IncompletePredicate => {
  const root: IncompletePredicate = { ...predicate };
  if (root.groupRoot) return root;

  root.operator = operator;
  if (isIncompletePredicate(root.right)) {
    root.right = updateBooleanOperatorsBetweenGroups(root.right, operator);
  }

  return root;
};
export const updateBooleanOperatorsInInnerGroup = (
  predicate: IncompletePredicate,
  innerGroupId: string,
  operator: string
): IncompletePredicate => {
  const root: IncompletePredicate = { ...predicate };

  if (root.id === innerGroupId) {
    const updatedRoot = updateBooleanOperatorsInPredicate(root, operator);
    return updatedRoot;
  }
  if (isIncompletePredicate(root.left)) {
    root.left = updateBooleanOperatorsInInnerGroup(
      root.left,
      innerGroupId,
      operator
    );
  }
  if (isIncompletePredicate(root.right)) {
    root.right = updateBooleanOperatorsInInnerGroup(
      root.right,
      innerGroupId,
      operator
    );
  }

  return root;
};

export const updateInPredicateById = (
  predicate: IncompletePredicate,
  id: string,
  props: Partial<StrictOmit<IncompletePredicate, 'id'>>
): IncompletePredicate => {
  if (predicate.id === id) return { ...predicate, ...props };

  if (isIncompletePredicate(predicate.left)) {
    predicate.left = updateInPredicateById(predicate.left, id, props);
  }

  if (isIncompletePredicate(predicate.right)) {
    predicate.right = updateInPredicateById(predicate.right, id, props);
  }

  return predicate;
};

export const makeIncompleteMatrixRuleGroups = (
  scoredRows: ScoredRows,
  field: string
) => {
  if (scoredRows) {
    return Object.entries(scoredRows).map(([score, rowValues]) =>
      makeMatrixRuleGroup(score, field, rowValues)
    );
  }

  return [];
};

export const makeMatrixRuleGroup = (
  score = '0',
  left = '',
  right = [[]] as string[][]
): IncompleteRuleGroup => {
  const id = makeRuleGroupId();
  const ruleGroup: IncompleteRuleGroup = {
    id: undefined,
    clientSideOnlyId: id,
    score,
    title: `Rule group #${id}`,
    rule: makeIncompletePredicate({
      left,
      right,
      type: 'matrix',
      operator: 'is_in',
      isIntermediate: false,
      groupRoot: true,
    }),
  };

  return ruleGroup;
};

export const updateLeftFieldsInMatrixRule = (
  rule: IncompletePredicate,
  fieldKey: string
) => {
  if (rule.left && typeof rule.left === 'string') {
    rule.left = fieldKey;
  }

  if (rule.left && isIncompletePredicate(rule.left)) {
    updateLeftFieldsInMatrixRule(rule.left, fieldKey);
  }
  if (rule.right && isIncompletePredicate(rule.right)) {
    updateLeftFieldsInMatrixRule(rule.right, fieldKey);
  }
};

export const getSelectedFieldInMatrixRule = (
  rule: IncompletePredicate
): string => {
  if (rule.left) {
    if (typeof rule.left === 'string') {
      return rule.left;
    }
    if (isIncompletePredicate(rule.left)) {
      return getSelectedFieldInMatrixRule(rule.left);
    }
  }

  return '';
};

export const getUpdatedMatrixRuleGroups = (
  groups: IncompleteRuleGroup[],
  fieldKey: string
) => {
  const ruleGroups = [...groups];
  ruleGroups.forEach((ruleGroup) => {
    if (ruleGroup.rule) {
      updateLeftFieldsInMatrixRule(ruleGroup.rule, fieldKey);
    }
  });

  return ruleGroups;
};

export const makeIncompleteRuleGroup = (): IncompleteRuleGroup => {
  const id = makeRuleGroupId();
  const ruleGroup: IncompleteRuleGroup = {
    id: undefined,
    clientSideOnlyId: id,
    score: '0',
    title: `Rule group #${id}`,
    rule: makeIncompletePredicate({
      type: 'rule',
      isIntermediate: false,
      groupRoot: true,
    }),
  };

  return ruleGroup;
};

export const makeIncompletePredicate = (
  props: StrictOmit<IncompletePredicate, 'id'>
): IncompletePredicate => {
  const predicate: IncompletePredicate = {
    id: makePredicateId(),
    ...props,
  };

  return predicate;
};

export const getLowestCommonAncestor = (
  root: IncompletePredicate,
  p: string | undefined,
  q: string | undefined
): IncompletePredicate | undefined => {
  if (!p || !q) return undefined;
  const lowestCommonAncestorId = getLowestCommonAncestorId(root, p, q);
  return getPredicateById(root, lowestCommonAncestorId) as IncompletePredicate;
};

export const addParentToRightmostRuleInInnerGroup = (
  predicate: IncompletePredicate,
  groupId: string
): IncompletePredicate => {
  const root: IncompletePredicate = { ...predicate };
  const connectingOperator = getBooleanOperatorWithinGroup(root) ?? 'and';
  if (root.id === groupId) {
    const updatedRoot = addParentToRightmostRule(root, connectingOperator);
    if (isIncompletePredicate(updatedRoot.left))
      delete updatedRoot.left.groupRoot;
    return updatedRoot;
  }

  if (isIncompletePredicate(root.left) && root.left.id === groupId) {
    const operator = root.left?.operator ?? 'and';
    const updatedLeft = addParentToRightmostRule(root.left, operator);
    root.left = updatedLeft;
    return root;
  }

  if (isIncompletePredicate(root.right)) {
    const updatedRight = addParentToRightmostRuleInInnerGroup(
      root.right,
      groupId
    );
    root.right = updatedRight;
    return root;
  }

  return root;
};

export const addParentToRightmostInnerGroup = (
  root: IncompletePredicate
): IncompletePredicate => {
  const rightmost = getRightmostInnerGroup(root);
  const connectingOperator = getBooleanOperatorBetweenGroups(root) ?? 'or';

  if (root.id === rightmost.id) {
    const newRoot = makeIncompletePredicate({
      isIntermediate: true,
      type: 'boolean',
      operator: connectingOperator,
    });
    const sibling = makeIncompletePredicate({
      groupRoot: true,
      isIntermediate: false,
      operator: undefined,
    });
    newRoot.left = root;
    newRoot.right = sibling;
    return newRoot;
  } else if (isIncompletePredicate(root.right)) {
    if (root.right.id === rightmost.id) {
      const intermediate = makeIncompletePredicate({
        isIntermediate: true,
        type: 'boolean',
        operator: connectingOperator,
      });

      const sibling = makeIncompletePredicate({
        groupRoot: true,
        isIntermediate: false,
        operator: undefined,
      });

      intermediate.left = root.right;
      intermediate.right = sibling;
      root.right = intermediate;
      return root;
    } else {
      const updatedRight = addParentToRightmostInnerGroup(root.right);
      root.right = updatedRight;
      return root;
    }
  } else {
    return root;
  }
};

const preorderTraversalOfInnerGroups = (
  predicate: IncompletePredicate
): string[] => {
  if (predicate.groupRoot) return [predicate.id];

  const sortedChildren: string[] = [];

  if (isIncompletePredicate(predicate.left)) {
    const lefts = preorderTraversalOfInnerGroups(predicate.left);
    sortedChildren.push(...lefts);
  }

  if (isIncompletePredicate(predicate.right)) {
    const rights = preorderTraversalOfInnerGroups(predicate.right);
    sortedChildren.push(...rights);
  }

  return sortedChildren;
};

const preorderRules = (predicate: IncompletePredicate): string[] => {
  if (!predicate.isIntermediate) return [predicate.id];

  const sortedChildren: string[] = [];

  if (isIncompletePredicate(predicate.left)) {
    const lefts = preorderRules(predicate.left);
    sortedChildren.push(...lefts);
  }

  if (isIncompletePredicate(predicate.right)) {
    const rights = preorderRules(predicate.right);
    sortedChildren.push(...rights);
  }

  return sortedChildren;
};

export const preorderSortGroups = (
  predicate: IncompletePredicate
): IncompletePredicate[] => {
  const sortedInnerGroupIds = preorderTraversalOfInnerGroups(predicate);
  const sortedInnerGroups = sortedInnerGroupIds
    .map((predicateId) => getPredicateById(predicate, predicateId))
    .filter(Boolean);

  return sortedInnerGroups;
};

export const preorderRulesInGroup = (
  root: IncompletePredicate
): IncompletePredicate[] => {
  const sortedRuleIds = preorderRules(root);

  const sortedRules = sortedRuleIds
    .map((ruleId) => getPredicateById(root, ruleId))
    .filter(Boolean);

  return sortedRules;
};

const getBooleanOperatorBetweenGroups = (
  root: IncompletePredicate
): string | undefined => {
  if (root.groupRoot) return undefined;
  else if (root.operator === 'and' || root.operator === 'or')
    return root.operator;
  else return undefined;
};

const getBooleanOperatorWithinGroup = (
  root: IncompletePredicate
): string | undefined => {
  if (root.groupRoot && (root.operator === 'and' || root.operator === 'or'))
    return root.operator;
  else return undefined;
};

const addParentToRightmostRule = (
  predicate: IncompletePredicate,
  operator: string | undefined
): IncompletePredicate => {
  const root: IncompletePredicate = { ...predicate };
  const rightmost = getRightmostRule(root);

  if (isIncompletePredicate(root.right)) {
    if (root.right.id === rightmost.id) {
      const intermediate = makeIncompletePredicate({
        isIntermediate: true,
        type: 'boolean',
        operator,
      });
      const sibling = makeIncompletePredicate({
        isIntermediate: false,
        operator,
      });
      intermediate.left = root.right;
      intermediate.right = sibling;
      root.right = intermediate;
      return root;
    } else {
      const updatedRight = addParentToRightmostRule(root.right, operator);
      root.right = updatedRight;
      return root;
    }
  } else {
    const newRoot = makeIncompletePredicate({
      groupRoot: true,
      isIntermediate: true,
      type: 'boolean',
      operator,
    });
    const sibling = makeIncompletePredicate({
      isIntermediate: false,
      operator,
    });
    newRoot.left = root;
    newRoot.right = sibling;
    delete predicate.groupRoot;
    return newRoot;
  }
};

export const getLowestCommonAncestorId = (
  root: IncompletePredicate,
  p: string,
  q: string
): string => {
  const pathToP = pathToPredicateWithId(root, p);
  const pathToQ = pathToPredicateWithId(root, q);

  for (let step = 0; step < pathToP.length && step < pathToQ.length; step++) {
    if (pathToP[step] !== pathToQ[step]) return pathToP[step - 1] ?? '';
  }

  return root.id;
};

const getRightmostRule = (
  predicate: IncompletePredicate
): IncompletePredicate => {
  if (isIncompletePredicate(predicate.right))
    return getRightmostRule(predicate.right);
  else return predicate;
};

const getRightmostInnerGroup = (
  root: IncompletePredicate
): IncompletePredicate => {
  if (root.groupRoot) return root;
  else return getRightmostInnerGroup(root.right as IncompletePredicate);
};

const pathToPredicateWithId = (
  root: IncompletePredicate,
  id: string
): string[] => {
  const path: string[] = [];

  if (root.id === id) return [root.id];

  if (isIncompletePredicate(root.left)) {
    const left = pathToPredicateWithId(root.left, id);
    if (left.length > 0) {
      path.push(root.id);
      path.push(...left);
      return path;
    }
  }

  if (isIncompletePredicate(root.right)) {
    const right = pathToPredicateWithId(root.right, id);
    if (right.length > 0) {
      path.push(root.id);
      path.push(...right);
      return path;
    }
  }

  return path;
};

const updateBooleanOperatorsInPredicate = (
  predicate: IncompletePredicate,
  operator: string
): IncompletePredicate => {
  const root: IncompletePredicate = { ...predicate };
  if (root.isIntermediate) root.operator = operator;
  if (isIncompletePredicate(root.left)) {
    const left = updateBooleanOperatorsInPredicate(root.left, operator);
    root.left = left;
  }
  if (isIncompletePredicate(root.right)) {
    const right = updateBooleanOperatorsInPredicate(root.right, operator);
    root.right = right;
  }

  return root;
};

function isIncompletePredicate(
  input: IncompletePredicate['left'] | IncompletePredicate['right']
): input is IncompletePredicate {
  return typeof input === 'object';
}

export const getMatrixColumnsAsRecord = (
  row: string[]
): {
  firstColumn: string;
  secondColumn?: string;
  score: string;
} => {
  const [first, second, third] = row;
  if (first === undefined || second === undefined) {
    throw new Error('One or more column has undefined values');
  }
  if (third) {
    return { firstColumn: first, secondColumn: second, score: third };
  }

  return { firstColumn: first, score: second };
};

export const isRiskMatrixSegment = (
  segment: IncompleteSegment | RiskMatrixSegment | undefined
): boolean =>
  Boolean(segment?.groups.some((group) => group?.rule?.type === 'matrix'));

export const extractRowsByScore = (riskMatrix: RiskMatrix): ScoredRows => {
  const scoreColumnIndex = riskMatrix.columns.findIndex(
    (column) => column.toLowerCase() === 'score'
  );

  if (scoreColumnIndex === -1) return null;

  const groupedRows = riskMatrix.rows.reduce(
    (acc, row) => {
      const { firstColumn, secondColumn, score } =
        getMatrixColumnsAsRecord(row);

      // If score does not exist, initialize it
      if (!acc.get(score)) {
        acc.set(score, [[]]);
      }

      // If the first column does not exist, initialize it
      if (!acc.get(score)?.[0]) {
        acc.get(score)?.push([]);
      }

      // Push the first column
      acc.get(score)?.[0]?.push(firstColumn);

      if (secondColumn) {
        // If the second column does not exist, initialize it
        if (!acc.get(score)?.[1]) {
          acc.get(score)?.push([]);
        }

        // Push the second column
        acc.get(score)?.[1]?.push(secondColumn);
      }

      return acc;
    },
    new Map() as Map<string, string[][]>
  );

  return Object.fromEntries(groupedRows);
};

let predicateIdCounter = 1;
let segmentIdCounter = 1;
let ruleGroupIdCounter = 1;
const makePredicateId = () => String(predicateIdCounter++);
const makeSegmentId = () => String(segmentIdCounter++);
const makeRuleGroupId = () => String(ruleGroupIdCounter++);
