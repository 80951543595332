import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { cn } from '@spektr/style-utils';

import {
  UsersApiClient,
  getTeamMembersQueryKey,
} from '@spektr/client/services';
import {
  Button,
  Input,
  Label,
  Select,
  Spinner,
  toast,
} from '@spektr/client/components';
import { usePermissionsContext } from '@spektr/client/providers';

import { UserInputSchema, UserSchema } from '@spektr/shared/validators';
import { Card } from '@spektr/shared/components';
import { CognitoUserData } from '@spektr/shared/types';
import { RBAC } from '@spektr/shared/rbac';

export type InviteTeamMembersProps = {
  className?: string;
  user?: CognitoUserData;
};

type FormValues = {
  firstName: string;
  lastName: string;
  username: string;
  role: string;
};

export const InviteTeamMembers = ({
  user,
  className,
}: InviteTeamMembersProps) => {
  const queryClient = useQueryClient();
  const { hasPermission } = usePermissionsContext();
  const {
    control,
    register,
    setValue,
    reset,
    handleSubmit: handleFormSubmit,
    setError,
    formState: { errors, isDirty, isValid },
  } = useForm<FormValues>({
    defaultValues: {
      role: 'viewer',
    },
  });

  const inviteUser = useMutation({
    mutationFn: async (data: FormValues) => {
      const parsed = UserInputSchema.safeParse(data);

      if (!parsed.success) {
        throw parsed.error;
      }

      const newUser = await UsersApiClient.getClient().createUser(parsed.data);

      queryClient.setQueryData(
        getTeamMembersQueryKey(['userRole']),
        (members: UserSchema[]) => {
          return [newUser, ...members];
        }
      );
      return;
    },
    onError: () => {
      toast.error({
        title: "Your team member hasn't been created!",
        description: 'An error occurred, please try again.',
      });
    },
    onSuccess: () => {
      toast.success({
        title: 'Team member successfully invited!',
        description: 'The new team member has been invited to your workspace.',
      });
      reset();
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getTeamMembersQueryKey(['userRole']),
      });
    },
  });

  const handleSubmit: SubmitHandler<FormValues> = async (data) => {
    const usernameError =
      user?.email === data.username ? 'You cannot invite yourself' : '';

    if (usernameError) {
      setError('username', {
        type: 'manual',
        message: usernameError,
      });
      return;
    }

    inviteUser.mutateAsync(data);
  };

  return (
    <Card className={cn('h-[330px]', 'border', className)}>
      <form
        className="flex w-full max-w-sm flex-col gap-4 p-4"
        onSubmit={handleFormSubmit(handleSubmit)}
      >
        <p className="text-color-text-primary text-base font-medium">
          Invite a team member
        </p>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col gap-2">
            <Label htmlFor="firstName">First name</Label>
            <Input
              required
              id="firstName"
              placeholder="First name"
              {...register('firstName', { required: true })}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label htmlFor="lastName">Last name</Label>
            <Input
              required
              id="lastName"
              placeholder="Last name"
              {...register('lastName', { required: true })}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="username">Email</Label>
          <Input
            required
            id="username"
            type="email"
            placeholder="Email"
            {...register('username', { required: true })}
          />
          {errors.username && (
            <p className="text-color-red text-xs">{errors.username.message}</p>
          )}
        </div>
        <Controller
          name="role"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { value } }) => (
            <div className="flex flex-col gap-2">
              <Label htmlFor="role">Role</Label>
              <Select
                className="w-full"
                value={value}
                name="role"
                options={[
                  { value: 'admin', label: 'Admin' },
                  { value: 'editor', label: 'Editor' },
                  { value: 'viewer', label: 'Viewer' },
                ]}
                onValueChange={(value) => {
                  setValue('role', value);
                }}
              />
            </div>
          )}
        />
        <div className="inline">
          {inviteUser.isPending ? (
            <Button color="primary" disabled type="submit">
              <div className="flex gap-2">
                <Spinner size="sm" />
                <span>Inviting...</span>
              </div>
            </Button>
          ) : (
            <Button
              color="primary"
              disabled={
                !isDirty || !isValid || !hasPermission(RBAC.ACTIONS.USER.INVITE)
              }
            >
              Send invitation link
            </Button>
          )}
        </div>
      </form>
    </Card>
  );
};
