import { ProcessNode, isOutcomeNodeType } from '@spektr/shared/types';

export function hasVisibleChildNodes(node: ProcessNode) {
  const notFinalOutcomeNodes = node.adj.filter((child) => !child.isFinal);

  return notFinalOutcomeNodes.length > 0;
}

export function isLeaf(node: ProcessNode) {
  return !hasVisibleChildNodes(node);
}

export function isFinalOutcomeNode(nodes: ProcessNode[], node: ProcessNode) {
  if (isOutcomeNodeType(node)) {
    const parentNode = nodes.find((n) =>
      n.adj.some((child) => child.id === node.id)
    );

    if (parentNode && ['kyckr', 'form'].includes(parentNode.nodeType)) {
      return parentNode.adj.find((child) => child.id === node.id)?.isFinal;
    }
  }

  return false;
}
