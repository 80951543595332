import { useState } from 'react';
import { z } from 'zod';
import { Ellipsis, Pencil } from 'lucide-react';

import { cn } from '@spektr/style-utils';
import {
  TagsDialog,
  IconButton,
  TagWithHoverCard,
  DropdownMenuComboBox,
  DropdownOption,
  Tooltip,
} from '@spektr/client/components';

import { ClientRecordTagModel } from '@spektr/shared/validators';

const tagsButtonsPosition = ['start', 'end'] as const;
export const TagsButtonsPosition = z.enum(tagsButtonsPosition);

const iconButtonClassName =
  'bg-color-bg-accent text-color-text-subtext h-[22px] w-[22px] p-0';

export type TagsGroupProps = {
  customerName?: string;
  onEdit?: (tagId: string) => void;
  limit?: number;
  customerTags: ClientRecordTagModel[];
  tagActions?: DropdownOption[];
  buttonsPosition?: z.infer<typeof TagsButtonsPosition>;
};

export const TagsGroup = ({
  customerName,
  onEdit,
  limit = 3,
  customerTags,
  tagActions = [],
  buttonsPosition = 'end',
}: TagsGroupProps) => {
  const [showAllTagsDialog, setShowAllTagsDialog] = useState(false);

  const renderButtons = () => {
    return (
      <div
        className={cn(
          'flex items-center gap-2',
          buttonsPosition === tagsButtonsPosition[0] ? 'mr-2' : 'ml-2'
        )}
      >
        {customerTags.length > limit && (
          <IconButton
            color="icon"
            className={iconButtonClassName}
            onClick={(e) => {
              e.stopPropagation();
              setShowAllTagsDialog(true);
            }}
          >
            <Ellipsis className="h-2.5 w-2.5" />
          </IconButton>
        )}
        {!!onEdit && tagActions.length > 0 && (
          <DropdownMenuComboBox
            triggerProps={{
              asChild: true,
            }}
            modal
            itemClassName="text-sm w-full max-w-72"
            trigger={
              <IconButton
                color="icon"
                className={iconButtonClassName}
                data-cy="update-tags"
              >
                <Tooltip
                  content="Assign/unassign tags to customer"
                  tooltipTriggerProps={{
                    className: 'w-full h-full flex items-center justify-center',
                  }}
                >
                  <Pencil className="h-2.5 w-2.5" />
                </Tooltip>
              </IconButton>
            }
            options={tagActions}
            onClick={onEdit}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div className="flex items-center gap-2">
        {buttonsPosition === tagsButtonsPosition[0] && renderButtons()}

        <div className="hidden items-center gap-3 sm:flex">
          {customerTags.slice(0, limit).map((tag, index) => (
            <TagWithHoverCard key={index} tag={tag} />
          ))}
        </div>

        {buttonsPosition === tagsButtonsPosition[1] && renderButtons()}
      </div>

      <TagsDialog
        customerName={customerName}
        tags={customerTags}
        open={!!showAllTagsDialog}
        onClose={() => setShowAllTagsDialog(false)}
      />
    </>
  );
};
